import '../style.css'
import leftAroow from '../../../images/arrow-left.png'
import logo from '../../../images/Union.png'
import Form from 'react-bootstrap/Form'
import {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {City, Country, State} from "country-state-city";
import {getDataFromLocalStorage, getOrgLevel, isUnauthorized, validateSignUpFormData} from "../../../helpers/utils";
import {signUp} from "../../../reduxStore/usersSlices/userSlice";
import {TailSpinLoader} from "../../layout/Loader";
import { Roles} from "../../../helpers/accessControl";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {toast} from "react-toastify";
import {createSubOrg} from "../../../reduxStore/orgSlices/orgSlices";
import {setShowCreateSubOrganizationBtn} from "../../../reduxStore/globalKeysSlice/globalKeysSlice";
import {orgLevels} from "../../../helpers/constants";
import jwtDecode from "jwt-decode";

const SignUp = ({reference=null,parentOrgId=null}) => {

    const navigate = useNavigate();
    const [allCountries, setAllCountries] = useState(Country.getAllCountries);
    const [allStates, setAllStates] = useState([]);
    let isSystemLevel = null
    if(reference !== null){
         isSystemLevel = (jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === "SYSTEM")
    }
    const [allCities, setAllCities] = useState([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState("")
    const user = useSelector(state => state.users)
    const org = useSelector(state => state.org)
    const [formData, setFormData] = useState({

        user: {
            firstName: "",
            lastName: "",
            email: "",
            contactNumber: "",
            passwordHash: "",
            enable: false,
            anonymousUser: false,
            createdDate: "",
            lastLogin: "",
            roles: [reference === "CREATE_SUB_ORG"?Roles.SUB_ORG_ADMIN:Roles.ORG_ADMIN],
            mentees: []
        },
        org: {
            name: "",
            address: "",
            parentOrgId:parentOrgId,
            orgLevel: getOrgLevel(reference),
            city: "",
            state: "",
            country: "",
            postalCode: ""

        }
    })

    const dispatch = useDispatch()
    const dataSubmit = (e) => {
        e.preventDefault();

        if (validateSignUpFormData(formData, allCities)) {
            const signUpData = {
                ...formData,
                org: {
                    ...formData.org,
                    address: formData.org.address + "," + formData.org.city + "," + formData.org.state + "," + formData.org.country + "," + formData.org.postalCode
                },
                user: {
                    ...formData.user,
                    contactNumber: "+" + formData.user.contactNumber
                }
            };
            if(reference==="CREATE_SUB_ORG"){
                dispatch(createSubOrg(signUpData))
                    .then((res) => {
                        if(isUnauthorized(res)){
                            navigate("/")
                        }
                        else if (res.meta.requestStatus === "fulfilled") {
                            dispatch(setShowCreateSubOrganizationBtn(null))
                            if(jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === "SYSTEM"){
                                navigate("/organizations")
                            }else{
                                navigate("/subOrganizations")
                            }

                        }
                    });
            }else{
                dispatch(signUp(signUpData))
                    .then((res) => {
                        if (res.meta.requestStatus === "fulfilled") {
                            toast.dismiss();
                            reference==="CREATE_ORG"?navigate("/organizations"):navigate("/signUp-success")

                        }
                    });
            }


        }
    }
    const handleOnChange = (e) => {
        const [firstLevelKey, secondLevelKey] = e.target.name.split('.');
        let updatedCity = formData.org.city;
        let updatedState = formData.org.state;
        if (e.target.name === 'org.country' || e.target.name === 'org.state') {
            updatedCity = ""; // Set city to an empty string
            e.target.name === 'org.country' ? updatedState = "" : updatedState = e.target.value
            formData.user.contactNumber = ""
            setFormData({
                ...formData,
                [firstLevelKey]: {
                    ...formData[firstLevelKey],
                    [secondLevelKey]: e.target.value,
                    city: updatedCity,
                    state: updatedState
                }
            });
        } else {
            setFormData({
                ...formData,
                [firstLevelKey]: {
                    ...formData[firstLevelKey],
                    [secondLevelKey]: e.target.value
                }
            })
        }

    };


    const getCodeByName = (data, name) => {
        const item = data.find(obj => obj.name === name);
        return item.isoCode
    }

    return (
        <>

            <div className={" pt-3 pb-3 "+(reference!==null?" sub-org-container-box":" container_box")}>
                <div className={reference!==null?" sub-org-cmn-outer":"cmn_outer m-auto"}>
                    <Form onSubmit={dataSubmit}>
                        <div className='row'>
                            {
                                reference===null && <div className="col-12">
                                    <img className={"cursor"} src={leftAroow} height="20px" width="20px" alt={""}
                                         onClick={() => {
                                             navigate("/")
                                         }}/>
                                    <img src={logo} width="100px" height="24px" className='ms-2 pulse_img' alt={""}/>
                                </div>
                            }

                            <div className="col-md-12">
                                <div className="">
                                    <h5 className={" mediumFontSize mb-2 "+(reference!==null?" sub-org-cmn-heading mt-1":" cmn_heading")}>Person Information</h5>
                                </div>
                                <div className='cmn_form_outer pt-1'>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label className='regularFontSize'>First Name*</Form.Label>
                                                <Form.Control required={true} type="text" name={"user.firstName"}
                                                              value={formData.user.firstName}
                                                              onChange={handleOnChange}/>
                                            </Form.Group>

                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label className='regularFontSize'>Last Name</Form.Label>
                                                <Form.Control type="text" name={"user.lastName"}
                                                              value={formData.user.lastName}
                                                              onChange={handleOnChange}/>
                                            </Form.Group>

                                        </div>

                                    </div>

                                    <div className={"row"}>
                                        <div className='col-md-6'>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label className='regularFontSize'>Email*</Form.Label>
                                                <Form.Control required={true} type="email" value={formData.user.email}
                                                              name={"user.email"}
                                                              onChange={handleOnChange}/>
                                            </Form.Group>
                                        </div>
                                        <div className='col-md-6'>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label className='regularFontSize'>Contact Number*</Form.Label>
                                                <PhoneInput inputProps={{required: true}}
                                                            name={"user.contactNumber"}
                                                            placeholder={""}
                                                            country={selectedCountryCode?.toLowerCase()}
                                                            value={formData.user.contactNumber} onChange={(val) => {
                                                    setFormData({
                                                        ...formData,
                                                        user: {...formData.user, contactNumber: val}
                                                    })
                                                }}/>
                                            </Form.Group>
                                        </div>
                                    </div>


                                </div>


                            </div>
                            <div className="col-md-12">
                                <div className="">
                                    <h5 className={" mediumFontSize mb-2 "+(reference!==null?" sub-org-cmn-heading mt-1":" cmn_heading")}>Organization Information</h5>
                                </div>
                                <div className='cmn_form_outer pt-1'>
                                    <div className="row">
                                        <div className={ isSystemLevel != null && isSystemLevel  && reference != null && reference === "CREATE_ORG"  ? "col-md-6" :"col-md-12"}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label className='regularFontSize'>Name*</Form.Label>
                                                <Form.Control required={true} type="text" name={"org.name"}
                                                              value={formData.org.name}
                                                              onChange={handleOnChange}/>

                                            </Form.Group>

                                        </div>
                                        {
                                            isSystemLevel != null && isSystemLevel  && reference != null && reference === "CREATE_ORG" &&

                                            <div className="col-md-6">

                                                <Form.Label className='regularFontSize'>Org Level*</Form.Label>

                                                <select onChange={handleOnChange} name={"org.orgLevel"} value={formData.org.orgLevel}
                                                        className={"form-select reflection-type-dropdown"}>
                                                    <option value={""}>Select Plan Level</option>
                                                    {
                                                        Object.keys(orgLevels).map((level, index) => {
                                                            return (
                                                                <option key={index}
                                                                        value={orgLevels[level].name}>{orgLevels[level].value}</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                            </div>

                                        }


                                    </div>
                                    <div className={"row"}>
                                        <div className="col-md-12">
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label className='regularFontSize'>Address</Form.Label>
                                                <Form.Control type="text" name={"org.address"}
                                                              value={formData.org.address}
                                                              onChange={handleOnChange}/>

                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label className='regularFontSize'>Country</Form.Label>
                                                <Form.Control  className={"truncate-select"} as="select"
                                                              value={formData.org.country} name={"org.country"}
                                                              onChange={(e) => {
                                                                  handleOnChange(e);
                                                                  if(e.target.value){
                                                                      const selectedCountryCode = getCodeByName(allCountries, e.target.value)
                                                                      setSelectedCountryCode(selectedCountryCode)
                                                                      setAllStates(State.getStatesOfCountry(selectedCountryCode))
                                                                  }else{
                                                                      setAllStates([])
                                                                  }

                                                              }}>
                                                    <option value="">Select Country...
                                                    </option>
                                                    {
                                                        allCountries && allCountries.length > 0 && allCountries?.map((country, index) => {
                                                            return (<option key={index}
                                                                            value={country.name}>{country.name}</option>)
                                                        })
                                                    }
                                                </Form.Control>

                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label className='regularFontSize'>State</Form.Label>
                                                <Form.Control  className={"truncate-select"} as="select"
                                                              value={formData.org.state} name={"org.state"}
                                                              onChange={(e) => {
                                                                  handleOnChange(e)
                                                                  if(e.target.value){
                                                                      const selectedStateCode = getCodeByName(allStates, e.target.value)
                                                                      setAllCities(City.getCitiesOfState(selectedCountryCode, selectedStateCode))
                                                                  }else{
                                                                      setAllCities([])
                                                                  }

                                                              }}>
                                                    <option value="">Select State...
                                                    </option>
                                                    {
                                                        allStates && allStates.length > 0 && allStates?.map((state, index) => {
                                                            return (<option key={index}
                                                                            value={state.name}>{state.name}</option>)
                                                        })
                                                    }
                                                </Form.Control>
                                            </Form.Group>

                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label
                                                    className='regularFontSize'>City{allCities.length === 0 ? "" : ""}</Form.Label>
                                                <Form.Control required={allCities.length !== 0}
                                                              className={"truncate-select"} as="select"
                                                              value={formData.org.city} name={"org.city"}
                                                              onChange={handleOnChange}>
                                                    <option value="">Select City...
                                                    </option>
                                                    {
                                                        allCities && allCities.length > 0 && allCities?.map((city, index) => {
                                                            return (<option key={index}
                                                                            value={city.name}>{city.name}</option>)
                                                        })
                                                    }
                                                </Form.Control>

                                            </Form.Group>
                                        </div>


                                        <div className="col-md-6">
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label className='regularFontSize'>Zip/Postal Code</Form.Label>
                                                <Form.Control  type="text" name={"org.postalCode"}
                                                              value={formData.org.postalCode}
                                                              onChange={(e) => {
                                                                  if (!isNaN(e.target.value) && e.target.value.length <= 10) {
                                                                      handleOnChange(e)
                                                                  }
                                                              }}/>

                                            </Form.Group>
                                        </div>
                                    </div>


                                </div>


                            </div>

                            <div className="col-12">
                                <div className='btn_outer'>
                                    <button style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""} } type={"submit"} disabled={user?.signUpReducer?.loading || org.createSubOrgReducer?.loading}
                                            className="cmn_btn cmn_background_color mediumFontSize"> { reference!==null?"Create":"Sign up"}
                                        {/*Showing Loading When Sign Up Button Is Clicked*/}
                                        {(user?.signUpReducer?.loading|| org.createSubOrgReducer?.loading) && (
                                            <TailSpinLoader/>)}
                                    </button>
                                    {
                                        reference===null && <Link to="/">
                                            <button className="cmn_form_btn mediumFontSize">Log in</button>
                                        </Link>
                                    }

                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    )
}
export default SignUp