import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getAxiosConfig, serialize, showToast} from "../../helpers/utils";
import {toast} from "react-toastify";
import { ThoughtCreatedSuccessfully} from "../../helpers/constants";

export const createNote = createAsyncThunk('notes/createNote', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/note`, data, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    })
})
export const getNotes = createAsyncThunk('notes/getNotes', async (data,thunkAPI) => {
    const serializedData = serialize(data)
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/note/search?${serializedData}`, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    })
})
export const searchNotes = createAsyncThunk('notes/searchNotes', async (data, thunkAPI) => {
    const serializedData = serialize(data)
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/note/search?${serializedData}`, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    })
})
export const getNoteById = createAsyncThunk('notes/getNoteById', async (noteId, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/note/${noteId}`, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    })
})

export const updateNoteById = createAsyncThunk('notes/updateNoteById', async (data, thunkAPI) => {
    return await axios.put(`${process.env.REACT_APP_BASE_URL}/note/${data.noteId}`, {
        userId: data.userId,
        orgId: data.orgId,
        note: data.note,
        memberId:data.memberId
    }, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    })
})
export const deleteNoteById = createAsyncThunk('notes/deleteNoteById', async ({
                                                                                  noteId,
                                                                                  setNoteId,
                                                                                  setShowConfirmationModal
                                                                              }, thunkAPI) => {
    return await axios.delete(`${process.env.REACT_APP_BASE_URL}/note/${noteId}`, getAxiosConfig()).then(res => {
        setShowConfirmationModal(false);
        setNoteId(null);
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    })
})


const notesSlice = createSlice({
    name: "notes",
    initialState: {
        createNoteReducer: {loading:false},
        getNotesDataReducer: {loading:false},
        deleteNoteByIdDataReducer: {loading:false},
        updateNoteByIdDataReducer: {loading:false},
        getNoteByIdDataReducer: {loading:false},
        getSearchNotesDataReducer: {loading:false},
    },
    extraReducers: {


        // Get All Notes
        [getNotes.pending]: (state) => {
            state.getNotesDataReducer = {loading:true}
        },
        [getNotes.fulfilled]: (state, action) => {
            state.getNotesDataReducer = {loading:false,data:action?.payload?.data}
        },
        [getNotes.rejected]: (state, action) => {
            state.getNotesDataReducer = {loading:false}
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText,"error")
        },


        // Create Note
        [createNote.pending]: (state) => {
            state.createNoteReducer = {loading:true};
        },
        [createNote.fulfilled]: (state, action) => {
            state.createNoteReducer = {loading:false , data:action.payload.data };
            showToast(ThoughtCreatedSuccessfully,"success")
        },
        [createNote.rejected]: (state, action) => {
            state.createNoteReducer = {loading:false};
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText,"error")
        },


        // Delete Note By Id
        [deleteNoteById.pending]: (state) => {
            state.deleteNoteByIdDataReducer={loading:true}
        },
        [deleteNoteById.fulfilled]: (state, action) => {
            state.deleteNoteByIdDataReducer={loading:false,data:action.payload.data}
            showToast(action.payload.data,"success")
        },
        [deleteNoteById.rejected]: (state, action) => {
            state.deleteNoteByIdDataReducer={loading:false}
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText,"error")
        },

        // Update Note By I'd
        [updateNoteById.pending]: (state) => {
            state.updateNoteByIdDataReducer = {loading:true};

        },
        [updateNoteById.fulfilled]: (state, action) => {
            state.updateNoteByIdDataReducer = {loading:false,data:action.payload.data};
            showToast(action.payload.data,"success")
        },
        [updateNoteById.rejected]: (state, action) => {
            state.updateNoteByIdDataReducer = {loading:false};
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText,"error")
        },

        // Get Note By I'd
        [getNoteById.pending]: (state) => {
            state.getNoteByIdDataReducer = {loading:true}
        },
        [getNoteById.fulfilled]: (state, action) => {
            state.getNoteByIdDataReducer = {loading:false,data:action.payload.data}
            showToast(action.payload.data,"success")
        },
        [getNoteById.rejected]: (state, action) => {
            state.getNoteByIdDataReducer = {loading:false,data:action.payload.data}
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText,"error")
        },

        // Search Note
        [searchNotes.pending]: (state) => {
            state.getNotesDataReducer = {loading:true}
            state.getSearchNotesDataReducer = {loading:true}

        },
        [searchNotes.fulfilled]: (state, action) => {
            state.getNotesDataReducer = {loading:false,data:action?.payload?.data}
            state.getSearchNotesDataReducer = {loading:false,data:action.payload.data}
        },
        [searchNotes.rejected]: (state, action) => {
            state.getNotesDataReducer = {loading:false}
            state.getSearchNotesDataReducer = {loading:true}
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText,"error")
        }

    }

})
export const {} = notesSlice.actions
export default notesSlice.reducer;