import Modal from "react-bootstrap/Modal";
import {useEffect, useState} from "react";
import {hasPermission, Permissions, roleHasAccess, Roles} from "../../../helpers/accessControl";
import {getAllUsersWithoutPagination, updateUserById} from "../../../reduxStore/usersSlices/userSlice";
import {
    createOptionListForSelectTagWithRole,
    getDataFromLocalStorage, isArrayEmpty,
    isNullOrEmpty,
    isUnauthorized, showToast,
    trim
} from "../../../helpers/utils";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import {
    FirstNameRequired,
    FirstNameTooLong,
    LastNameTooLong
} from "../../../helpers/constants";
import {TailSpinLoader} from "../../layout/Loader";
import {getAllRoles} from "../../../reduxStore/rolesSlices/rolesSlices";
import jwtDecode from "jwt-decode";
import Select from "react-select";
import {IoIosClose} from "react-icons/io";

const UpdateUserModal = ({show, setShow, userDetails, setUserDetails, data,isSubOrg ,subOrgId}) => {


    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [newFirstName, setNewFirstName] = useState(userDetails?.firstName);
    const [newLastName, setNewLastName] = useState(userDetails?.lastName);
    const [newRoleAssigned, setNewRoleAssigned] = useState(userDetails?.roles[0]?.name);

    const [assignMember, setAssignMember] = useState([])

    const [userTags, setUserTags] = useState("");

    const allUsersWithoutPaginationData = useSelector(state => state.users.getAllUsersWithoutPaginationReducer);
    const userUpdated = useSelector(state => state.users.updateUserByIdReducer);

    const handleClose = () => {
        setShow(false);

        if(isSubOrg !== undefined){
            setNewFirstName("")
            setNewLastName("")
            setUserDetails(null)
        }


    }

    const [roles, setRoles] = useState(null);

    useEffect(() => {
        if (hasPermission([Permissions.USER_READ], true) && userDetails !== null) {

            if (userDetails.roles[0].name === "MENTEE") {
                setRoles([Roles.MENTOR, Roles.MANAGER, Roles.ORG_ADMIN, Roles.SUB_ORG_ADMIN])
            } else if (userDetails.roles[0].name === "MENTOR") {
                setRoles([Roles.MANAGER, Roles.ORG_ADMIN, Roles.SUB_ORG_ADMIN])
            } else if (userDetails.roles[0].name === "REPORT_WRITER") {
                setRoles([Roles.MENTOR, Roles.MANAGER, Roles.ORG_ADMIN, Roles.REFLECTION_WRITER, Roles.SUB_ORG_ADMIN])
            } else if (userDetails.roles[0].name === "REFLECTION_WRITER") {
                setRoles([Roles.MENTOR, Roles.MANAGER, Roles.ORG_ADMIN, Roles.SUB_ORG_ADMIN])
            } else if (userDetails.roles[0].name === "MANAGER") {
                setRoles([Roles.ORG_ADMIN, Roles.SUB_ORG_ADMIN])
            }

        }

    }, [])

    useEffect(() => {

        if (roles !== null) {
            dispatch(getAllUsersWithoutPagination({
                roles: roles,
                subOrgId:subOrgId,
                orgId:jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === "SYSTEM" && !isSubOrg ? userDetails?.orgId:null
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }

    }, [roles])

    useEffect( ()=>{
        if(allUsersWithoutPaginationData?.data !== null &&  allUsersWithoutPaginationData?.data !== undefined ){
            const data =  allUsersWithoutPaginationData?.data?.filter(member => userDetails?.mentorIds?.map(c=> c).includes(member.uid))
            console.log("data ", data)
            if(data.length > 0){
                setAssignMember(createOptionListForSelectTagWithRole(data, "firstName", "uid"))
            }
        }
    },[allUsersWithoutPaginationData])
    const UpdateSubmit = (e) => {

        e.preventDefault()
        let message;
        if (isNullOrEmpty(userDetails?.firstName)) {
            message = FirstNameRequired
        } else if (userDetails?.firstName.length > 25) {
            message = FirstNameTooLong
        } else if (!isNullOrEmpty(userDetails?.lastName) && userDetails?.firstName.length > 25) {
            message = LastNameTooLong
        }
        if (message) {
            showToast(message,"error")

        } else {

            dispatch((updateUserById({
                userId: userDetails?.userId,
                firstName: userDetails?.firstName,
                lastName: userDetails.lastName,
                orgId: userDetails?.orgId,
                enable: userDetails?.enable,
                roles: [newRoleAssigned],
                mentorIds: assignMember?.length === 0 ? userDetails?.mentorIds: assignMember?.map( data => data?.value),
                tags: userDetails?.tags
            }))).then((res) => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (res.meta.requestStatus === "fulfilled") {
                    data()
                    setShow(false)
                }
            })

        }

    };
    const handleKeyDown = (e, index) => {
        if (e.key === "Enter") {
            if (e.key === "Enter") {
                if (index === -1) {
                    if (!isNullOrEmpty(e.target.value.trim()) && !userDetails[e.target.name].includes(e.target.value.trim())) {
                        setUserDetails({
                            ...userDetails,
                            [e.target.name]: [...userDetails[e.target.name], e.target.value.trim()]
                        });
                    }
                    setUserTags("")
                }

            }


        }
    };
    const removeSurveyTags = (index) => {
        const updatedArray = [...userDetails.tags]
        updatedArray.splice(index, 1);
        setUserDetails({...userDetails, tags: updatedArray})
    }
    const getAllRolesData = useSelector(state => state.roles.getAllRolesDataReducer)
    useEffect(() => {
        if (roleHasAccess([Roles.ORG_ADMIN,Roles.SUB_ORG_ADMIN,Roles.MANAGER]) && hasPermission([Permissions.ROLE_READ, Permissions.ROLE_WRITE], true)) {
            dispatch(getAllRoles());
        }
    }, [])

    return (
        <>

            <Modal className='mt-4' show={show} centered>
                <Modal.Header className={"d-flex"}>
                    <Modal.Title className='add_member_heading mediumFontSize'>Update Member</Modal.Title>
                    <IoIosClose className={"close_btn"} onClick={handleClose} size={24}/>


                </Modal.Header>

                <form>

                    <Modal.Body className="modal_outer">


                        <div className="reset_form">

                            <div className="form-group mt-1">
                                <label className='userInfo_label mediumFontSize' form="inputAddress">First
                                    Name*</label>
                                <input required={true} type="text" value={newFirstName} onChange={(e) => {
                                    setNewFirstName(e.target.value)
                                    setUserDetails(prevUser => ({
                                        ...prevUser,
                                        firstName: e.target.value
                                    }));
                                }
                                } className="form-control userInfo_input" id="inputAddress"/>
                            </div>
                            <div className="form-group mt-1">
                                <label className='userInfo_label mediumFontSize' form="inputAddress">Last
                                    Name</label>
                                <input type="text" value={newLastName} onChange={(e) => {
                                    setNewLastName(e.target.value)
                                    setUserDetails(prevUser => ({
                                        ...prevUser,
                                        lastName: e.target.value
                                    }));

                                }
                                } className="form-control userInfo_input" id="inputAddress"/>
                            </div>
                            <div className={"form-group mt-1"}>
                                <label className='userInfo_label mediumFontSize' form="inputAddress">Tags</label>


                                <div className={"survey-tag-outer mt-1"}>


                                    {
                                        userDetails?.tags?.map((tag, index) => {
                                            return (
                                                <div key={index} className={"survey-tags"}>
                                                    <div className={"tag-text"}
                                                         title={tag}>{trim(tag, 12)}
                                                    </div>
                                                    <div className={"tag-cross"} name={"tags"}
                                                         onClick={() => {
                                                             removeSurveyTags(index)

                                                         }}>
                                                        <i className={"fa fa-times remove-tag-icon"}/>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                    <div className={"add-tag-outer mt-1"}>
                                        <i className={"fa fa-plus add-tag-icon"}/>
                                        <input name={"tags"}
                                               value={userTags}
                                               onChange={(e) => {
                                                   setUserTags(e.target.value)

                                               }}
                                               onKeyDown={(e) => {

                                                   handleKeyDown(e, -1)

                                               }}

                                               className={"tags-input-field "}
                                               type={"text"}/>
                                    </div>

                                </div>

                            </div>
                            {
                             ( newRoleAssigned !== Roles.MENTEE && newRoleAssigned !== Roles.ORG_ADMIN && newRoleAssigned !== Roles.SUB_ORG_ADMIN) && roleHasAccess([Roles.ORG_ADMIN,Roles.SUB_ORG_ADMIN,Roles.MANAGER,Roles.SYS_ADMIN])  && hasPermission([Permissions.ROLE_READ, Permissions.ROLE_WRITE], true) &&


                                <div className={"form-group mt-1"}>
                                    <label className='userInfo_label mediumFontSize' form="inputAddress">Role</label>
                                    {
                                        getAllRolesData?.loading ? <Skeleton height={40}/> :
                                            <select className={"form-select"} value={newRoleAssigned}
                                                    onChange={e => {
                                                        setNewRoleAssigned(e.target.value);
                                                    }}
                                            >
                                                {
                                                    getAllRolesData?.data
                                                        ?.filter((role) => ['MENTOR', 'MANAGER', 'REFLECTION_WRITER'].includes(role.name))
                                                        .map((role, index) => (
                                                            <option key={index} value={role.name}>
                                                                {role?.name}
                                                            </option>
                                                        ))
                                                }
                                            </select>
                                    }
                                </div>
                            }


                            <div className={"form-group mt-1"}>
                                {roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER, Roles.SUB_ORG_ADMIN]) &&
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1"
                                               className="userInfo_label mediumFontSize">Mentor/Manager</label>
                                        {/*{*/}
                                        {/*    (allUsersWithoutPaginationData?.loading) ?*/}
                                        {/*        <div className={"roles-loading"}>*/}
                                        {/*            <Skeleton height={40}/>*/}
                                        {/*        </div> :*/}
                                        {/*        <select name={"mentorId"} required={true} value={assignMenteeTo}*/}
                                        {/*                onChange={(e) => {*/}
                                        {/*                    if (hasPermission([Permissions.USER_WRITE], true)) {*/}

                                        {/*                        setAssignMenteeTo(e.target.value)*/}
                                        {/*                        setUserDetails(prevUser => ({*/}
                                        {/*                            ...prevUser,*/}
                                        {/*                            mentorId: e.target.value*/}
                                        {/*                        }));*/}

                                        {/*                    }*/}

                                        {/*                }}*/}
                                        {/*                disabled={!hasPermission([Permissions.USER_WRITE], true)}*/}
                                        {/*                className="form-control select_role mt-2"*/}
                                        {/*                id="exampleFormControlSelect1">*/}

                                        {/*            {*/}
                                        {/*                assignMemberList?.map(member => {*/}
                                        {/*                        return (*/}
                                        {/*                            <option selected={member.uid === userDetails?.mentorId}*/}
                                        {/*                                    id={member.uid} value={member.uid}>*/}

                                        {/*                                {member.firstName + " " + member.lastName + " (" + member.roleObjects?.map(role => role.name) + ")"}*/}

                                        {/*                            </option>)*/}
                                        {/*                    }*/}
                                        {/*                )*/}
                                        {/*            }*/}
                                        {/*        </select>*/}
                                        {/*}*/}

                                        {
                                            ( allUsersWithoutPaginationData?.loading) ?
                                                <div className={"roles-loading"}>
                                                    <Skeleton height={40}/>
                                                </div> :

                                                <>

                                                    <Select
                                                        className="members-filter-role react-select-container mt-2"
                                                        classNamePrefix="react-select"
                                                        isDisabled={allUsersWithoutPaginationData?.loading}
                                                        isMulti
                                                        value={assignMember}
                                                        options={createOptionListForSelectTagWithRole(allUsersWithoutPaginationData?.data, "firstName", "uid")}
                                                        onChange={(selectData) => {
                                                            setAssignMember(Array.isArray(selectData) ? selectData : [selectData])

                                                        }}
                                                        placeholder={"Mentor/Manager..."}/>

                                                </>

                                        }
                                    </div>
                                }

                            </div>
                        </div>

                    </Modal.Body>

                    <Modal.Footer className="modal_footer">
                        <div className={"add-member-modal-btns"}>
                            <button style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""} } type="button" onClick={UpdateSubmit} disabled={userUpdated.loading === true}
                                    className={allUsersWithoutPaginationData.loading === true ? "disable-btn ms-2 modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize " : "ms-2 modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize "}>Update
                                {userUpdated?.loading && (
                                    <TailSpinLoader/>)}


                            </button>
                            <button  type={"button"} className="modal_cancel_btn cmn_modal_btn mediumFontSize"
                                    onClick={handleClose}>Cancel
                            </button>

                        </div>

                    </Modal.Footer>
                </form>
            </Modal>

        </>
    )
}

export default UpdateUserModal;