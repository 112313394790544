import Modal from "react-bootstrap/Modal";
import {roleHasAccess, Roles} from "../../../helpers/accessControl";
import Skeleton from "react-loading-skeleton";
import React, {useEffect, useState} from "react"
import {
    createOptionListForSelectTag, getAxiosConfig,
    getDataFromLocalStorage,
    isNullOrEmpty,
    isUnauthorized, showToast
} from "../../../helpers/utils";
import {getAllUsers, getUserById, updateUserOrganization} from "../../../reduxStore/usersSlices/userSlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import jwtDecode from "jwt-decode";
import {TailSpinLoader} from "../../layout/Loader";
import Select from "react-select";
import {
    getAllCustomerOrgAsList,
    getAllSubOrgByOrgId,
    resetGetAllSubOrgByOrgIdReducer
} from "../../../reduxStore/orgSlices/orgSlices";
import {IoIosClose} from "react-icons/io";
import {moveSurveyOrganization, searchSurveys} from "../../../reduxStore/surveySlices/surveySlices";
import ConfirmationModel from "../../commons/ConfirmationModel";


const MoveMemberAndOrgModal = ({
                                   show,
                                   setShow,
                                   rowId,
                                   userData,
                                   setUserData,
                                   query,
                                   callApiFunction = null,
                                   setQuery,
                                   subOrgId = null,
                                   isSubOrg = false,
                                   title = null,

                               }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [selectParentOrg, setSelectParentOrg] = useState(null);
    const [selectChildOrg, setSelectChildOrg] = useState(null);
    const [showConfirmationModalForMoveUser, setShowConfirmationModalForMoveUser] = useState(false);
    const subOrgList = useSelector(state => state.org.getAllSubOrgByOrgIdReducer);
    const orgList = useSelector(state => state.org.getAllCustomerOrgAsListReducer);
    const updateUserOrg = useSelector(state => state.users.updateUserOrganizationReducer);
    const moveReflection = useSelector(state => state.survey.moveSurveyOrganizationReducer);

    const handleClose = () => setShow(false);

    useEffect(() => {
        if (roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) && (jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'CUSTOMER' || jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'SYSTEM')) {
            !isSubOrg && jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'SYSTEM' ? dispatch(getAllCustomerOrgAsList({orgLevel: "CUSTOMER"})) : dispatch(getAllSubOrgByOrgId({orgId: jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'SYSTEM' ? subOrgId.trim() : jwtDecode(getDataFromLocalStorage("token"))?.orgId.trim()}));
        }
    }, []);


    useEffect(() => {
        if (selectParentOrg && !isSubOrg && jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'SYSTEM') {
            dispatch(getAllSubOrgByOrgId({orgId: selectParentOrg.value}))
        }
    }, [selectParentOrg]);


    const handleMoveUser=()=>{
        if (roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) && (jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'CUSTOMER' || jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'SYSTEM') && rowId && (selectChildOrg || selectParentOrg)) {
            dispatch(updateUserOrganization({
                userId: rowId,
                orgId: isSubOrg ? selectChildOrg?.value : selectChildOrg?.value || selectParentOrg?.value,
            })).then((res) => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (res.meta.requestStatus === "fulfilled") {
                    setShow(false)
                    setShowConfirmationModalForMoveUser(false)

                    if (query !== null) {
                        const searchQueryToBeSubmitted = {
                            ...query,
                            orgId: query?.orgId?.value === undefined ? null : query?.orgId?.value, ...(query?.mentorId ? {mentorId: query?.mentorId.value} : {}), ...(query?.assignTo ? {mentorId: query?.assignTo.value} : {})
                        }
                        dispatch(getAllUsers({
                            ...searchQueryToBeSubmitted,
                            sortOrder: "desc",
                            sortBy: "createdAt",
                            page: 1,
                            subOrgId: subOrgId !== null ? subOrgId : "",
                            pageSize: 10,
                        })).then(res => {
                            if (isUnauthorized(res)) {
                                navigate("/")
                            }
                        });
                    } else {
                        dispatch(getUserById(rowId))
                            .then((response) => {
                                if (isUnauthorized(response)) {
                                    navigate("/")
                                }
                                if (response.meta.requestStatus === "fulfilled") {
                                    setUserData(response.payload.data)
                                } else if (response.meta.requestStatus === "rejected") {
                                    if (response?.payload?.errorCode?.value === 400) {
                                        showToast(response.payload.detailDesc,"error")
                                    }

                                }
                            })
                    }

                }
            })

        }
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if (title !== null) {
            if (roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) && (jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'CUSTOMER' || jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'SYSTEM') && rowId && (selectChildOrg || selectParentOrg)) {
                dispatch(moveSurveyOrganization({
                    surveyId: rowId,
                    orgId: isSubOrg ? selectChildOrg?.value : selectChildOrg?.value || selectParentOrg?.value,
                })).then((res) => {
                    if (isUnauthorized(res)) {
                        navigate("/")
                    }
                    if (res.meta.requestStatus === "fulfilled") {
                        callApiFunction && callApiFunction();
                        setShow(false)
                    }
                })

            }
        }

    }


    return (

        <>
            <Modal show={show} centered>
                <Modal.Header className={"d-flex"}>
                    <Modal.Title className='add_member_heading mediumFontSize'>{title || "Move Member"}</Modal.Title>
                    <IoIosClose className={"close_btn"} onClick={handleClose} size={24}/>
                </Modal.Header>

                <form onSubmit={handleSubmit}>
                    <Modal.Body className="modal_outer">


                        {roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) && (jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'CUSTOMER' || jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'SYSTEM') &&
                            <>

                                {!isSubOrg && roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) && jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'SYSTEM' &&
                                    <div className="p-2">

                                        <label htmlFor="exampleInputEmail1"
                                               className="userInfo_label mediumFontSize">Select Organization</label>
                                        {
                                            (orgList?.loading) ?
                                                <div className={"roles-loading"}>
                                                    <Skeleton height={40}/>
                                                </div> :
                                                <>
                                                    <Select
                                                        className="members-filter-role react-select-container mt-2"
                                                        classNamePrefix="react-select"
                                                        isDisabled={orgList?.loading}
                                                        value={selectParentOrg}
                                                        isClearable={true}
                                                        options={createOptionListForSelectTag(orgList?.data?.filter(c => c.orgLevel === 'CUSTOMER'), "name", "orgId")}
                                                        onChange={(selectData) => {
                                                            setSelectChildOrg(null);
                                                            dispatch(resetGetAllSubOrgByOrgIdReducer())
                                                            setSelectParentOrg(selectData)
                                                        }}
                                                        placeholder={"Organizations..."}/>

                                                </>

                                        }
                                    </div>
                                }

                                {roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) && (jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'CUSTOMER' || jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'SYSTEM') &&
                                    <div className="p-2">
                                        <label htmlFor="exampleInputEmail1"
                                               className="userInfo_label mediumFontSize">Select Sub-Organization</label>
                                        {
                                            (subOrgList?.loading) ?
                                                <div className={"roles-loading"}>
                                                    <Skeleton height={40}/>
                                                </div> :

                                                <>

                                                    <Select
                                                        className="members-filter-role react-select-container mt-2"
                                                        classNamePrefix="react-select"
                                                        isDisabled={subOrgList?.loading || (!isSubOrg && isNullOrEmpty(selectParentOrg?.value || "") && jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'SYSTEM')}
                                                        value={selectChildOrg}
                                                        isClearable={true}
                                                        options={createOptionListForSelectTag(subOrgList?.data, "name", "orgId", isSubOrg)}
                                                        onChange={(selectData) => {
                                                            setSelectChildOrg(selectData)
                                                        }}
                                                        placeholder={"Sub-Organizations..."}/>

                                                </>

                                        }
                                    </div>}

                            </>

                        }

                    </Modal.Body>
                    <Modal.Footer className="modal_footer">

                        <button className="modal_cancel_btn cmn_modal_btn mediumFontSize" onClick={handleClose}>Cancel
                        </button>
                        {roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) && (jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'CUSTOMER' || jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'SYSTEM') &&

                            <button
                                onClick={() => {
                                    if(title===null){
                                        setShowConfirmationModalForMoveUser(true)
                                    }

                                }}
                                style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                type="submit" disabled={subOrgList?.loading === true}
                                className={subOrgList.loading === true || (isNullOrEmpty(selectChildOrg?.value || "") && isNullOrEmpty(selectParentOrg?.value || "")) ? "disable-btn ms-2 modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize " : "ms-2 modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize "}
                            > Move
                                {(updateUserOrg?.loading || moveReflection?.loading) && (
                                    <TailSpinLoader/>)}
                            </button>
                        }


                    </Modal.Footer>
                </form>
                {
                    showConfirmationModalForMoveUser &&
                    <ConfirmationModel title={"Move User"}
                                       btnText={"Move"}
                                       isLoading={updateUserOrg?.loading}
                                       action={handleMoveUser}
                                       show={showConfirmationModalForMoveUser}
                                       bodyMessage={"If you proceed with moving this user, all reports and triggers created by this user will be permanently removed and cannot be recovered."}
                                       setShow={setShowConfirmationModalForMoveUser}
                                       showUserAcknowledgementCheckBox={true}
                                       userAcknowledgementCheckBoxText={"Yes, I understand"}
                    />
                }
            </Modal>


        </>
    )

}

export default MoveMemberAndOrgModal;
