import jwtDecode from "jwt-decode";
import {getDataFromLocalStorage} from "./utils";

export const Roles = Object.freeze({
    ORG_ADMIN:"ORG_ADMIN",
    SUB_ORG_ADMIN:"SUB_ORG_ADMIN",
    MANAGER:"MANAGER",
    MENTOR:"MENTOR",
    SYS_ADMIN:"SYS_ADMIN",
    REFLECTION_WRITER:"REFLECTION_WRITER",
    REPORT_WRITER:"REPORT_WRITER",
    MENTEE:"MENTEE",
    ANONYMOUS_USER:"ANONYMOUS_USER"
})
export const AuthorityLevel=Object.freeze({
    SYS_ADMIN:1500,
    ORG_ADMIN:900,
    SUB_ORG_ADMIN:850,
    MANAGER:800,
    REFLECTION_WRITER:800,
    REPORT_WRITER:700,
    MENTOR:500,
    MENTEE:200,
    ANONYMOUS_USER:0,
})
 export  const RolesData = [
    { name: "ORG_ADMIN", value: "ORG_ADMIN" },
    { name: "SUB_ORG_ADMIN", value: "SUB_ORG_ADMIN" },
    { name: "MANAGER", value: "MANAGER" },
    { name: "MENTOR", value: "MENTOR" },
    { name: "REFLECTION_WRITER", value: "REFLECTION_WRITER" },
    { name: "REPORT_WRITER", value: "REPORT_WRITER" },
    { name: "MENTEE", value: "MENTEE" },
    { name: "ANONYMOUS_USER", value: "ANONYMOUS_USER" }
];

export  const ReflectionStatus = [
    { name: "Stagnating", value: "HIGH_RISK" },
    { name: "Growing", value: "LOW_RISK" },
    { name: "Maturing", value: "GOOD" }
];
export  const ReportPeriod = [
    {value: "WEEKLY", label: "Weekly"},
    {value: "MONTHLY", label: "Monthly"},
    {value: "YEARLY", label: "Yearly"},
    {value: "CUSTOM", label: "Custom"}
];
export const UserFields=[
     {name:"First name",value:"firstName", type:"String"},
     { name :"Last name",value:"lastName", type: "String"},
     {name:"Roles", value:"roles",type: "Array"},
     {name:"Select Member", value:"UserSelection",type: "Array"}
]

export const AttemptSurveyOperation=[
    { name:"Reflection Result",value:"result", type:"Array"},
    { name :"Reflection Status",value:"status", type: "String"},
    { name :"Survey Name",value:"surveyName", type: "String"}
]
export const CriteriaFrequency =[
    { name:"Day",value:"DAY"},
    { name :"Weekly",value:"WEEKLY"},
    { name :"Monthly",value:"MONTHLY"}
]

export const ReportTypeEnum =[
    { name:"Line Chart",value:"LINE_CHART"},
    { name :"Pie Chart",value:"PIE_CHART"},
    { name :"Donut Chart",value:"DONUT_CHART"},
    { name :"Bubble Chart",value:"BUBBLE_CHART"},
    { name :"Bar Chart",value:"BAR_CHART"},
]
export const ReportFiled =[
    {name:"Reflection Name",value:"name", type:"String"},
    { name :"Reflection Status",value:"status", type: "Array"},
    { name :"Select Reflections",value:"RefSelection", type: "Array"}
]
export const CustomReportCriteria =[
    {name:"Attempt Reflection",value:"Attempt Reflection"},
    { name :"Users",value:"Users"},

]
export const Operators = [
    { name: "Equals", value: "EQUALS" , type:"String" },
    { name: "Contains", value: "CONTAINS" , type:"String" },
    { name: "Less Than", value: "LESS_THEN" , type: "Array" },
    { name: "Greater Than", value: "GREATER_THEN", type: "Array" },
    { name: "Less Than or Equal To", value: "LESS_THEN_EQUALS" , type: "Array" },
    { name: "Greater Than or Equal To", value: "GREATER_THEN_EQUALS" , type: "Array"}
];

export const OperatorsReport = [
    { name: "Equals", value: "EQUALS" , type:"String" },
    { name: "Contains", value: "CONTAINS" , type: "String" },
    { name: "Less Than", value: "LESS_THEN" , type: "Array" },
    { name: "Greater Than", value: "GREATER_THEN", type: "Array" },
    { name: "Less Than or Equal To", value: "LESS_THEN_EQUALS" , type: "Array" },
    { name: "Greater Than or Equal To", value: "GREATER_THEN_EQUALS" , type: "Array"}
];
export const Inputs = [
    { name: "Text", value: "text" , type:"String" },
    { name: "Email", value: "email" , type:"String" },
    { name: "Number", value: "number" , type: "Array" },
];

export const ActionEnum = [
    { name: "Send Email", value: "SEND_EMAIL"},
    { name: "Assign Mentor", value: "ASSIGNED_MENTOR"}
];

export const Permissions = Object.freeze({
    ORG_READ: "ORG_READ",
    ORG_WRITE: "ORG_WRITE",
    ORG_DELETE: "ORG_DELETE",
    ORG_CREATE: "ORG_CREATE",

    REFLECTION_READ: "REFLECTION_READ",
    REFLECTION_WRITE: "REFLECTION_WRITE",
    REFLECTION_DELETE: "REFLECTION_DELETE",
    REFLECTION_CREATE: "REFLECTION_CREATE",

    RESPONSE_READ: "RESPONSE_READ",
    RESPONSE_WRITE: "RESPONSE_WRITE",
    RESPONSE_DELETE: "RESPONSE_DELETE",
    RESPONSE_CREATE: "RESPONSE_CREATE",

    USER_WRITE: "USER_WRITE",
    USER_READ: "USER_READ",
    USER_DELETE: "USER_DELETE",
    USER_CREATE: "USER_CREATE",

    ROLE_READ: "ROLE_READ",
    ROLE_WRITE: "ROLE_WRITE",
    ROLE_DELETE: "ROLE_DELETE",
    ROLE_CREATE: "ROLE_CREATE",

    PERM_READ: "PERM_READ",
    PERM_WRITE: "PERM_WRITE",
    PERM_DELETE: "PERM_DELETE",
    PERM_CREATE: "PERM_CREATE",

    NOTE_READ: "NOTE_READ",
    NOTE_WRITE: "NOTE_WRITE",
    NOTE_DELETE: "NOTE_DELETE",
    NOTE_CREATE: "NOTE_CREATE",

    REPORT_READ: "REPORT_READ",
    REPORT_WRITE: "REPORT_WRITE",
    REPORT_DELETE: "REPORT_DELETE",
    REPORT_CREATE: "REPORT_CREATE",


    ATTEMPT_REFLECTION_READ: "ATTEMPT_REFLECTION_READ",
    ATTEMPT_REFLECTION_WRITE: "ATTEMPT_REFLECTION_WRITE",
    ATTEMPT_REFLECTION_DELETE: "ATTEMPT_REFLECTION_DELETE",
    ATTEMPT_REFLECTION_CREATE: "ATTEMPT_REFLECTION_CREATE",
})

// hasPermission takes in array of permissions allowed to access particular resource and strictCheck(boolean)
// if strictCheck=true user should have every permission passed in an array and hasPermission will return true
// if strictCheck=false user should have at least one of the permission and hasPermission will return true
export const hasPermission = (permissions = [], isMatchAll = false) => {
    if (isMatchAll) {
            return permissions.every(permission => jwtDecode(getDataFromLocalStorage("token"))?.permissions.includes(permission));
    } else {
        return permissions.some(userPermission => jwtDecode(getDataFromLocalStorage("token"))?.permissions.includes(userPermission))
    }
}

// Returns True if user has role passed inside Array(roles)
export const roleHasAccess=(roles=[])=>{
    return roles.some(role => jwtDecode(getDataFromLocalStorage("token"))?.roles.includes(role))

}

export const orgLevel=()=>{
    return jwtDecode(getDataFromLocalStorage("token"))?.orgLevel
}