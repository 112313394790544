import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {formatMessage, getAxiosConfig, showToast} from "../../helpers/utils";
import {CreatedSuccessfully} from "../../helpers/constants";

export const createTeam = createAsyncThunk('team/createTeam', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/team`, data, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
})
export const getTeams = createAsyncThunk('team/getTeams', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/team/list`, data, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
})
export const getTeamById = createAsyncThunk('team/getTeamById', async (data, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/team/${data}`, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
})
export const updateTeamById = createAsyncThunk('team/updateTeamById', async (data, thunkAPI) => {
    return await axios.put(`${process.env.REACT_APP_BASE_URL}/team/${data.id}`, data, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
})
export const getTeamMembers = createAsyncThunk('team/getTeamMembers', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/user/team/${data.teamId}`, data, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
})
export const getAllTeamMembers = createAsyncThunk('team/getAllTeamMembers', async (teamId, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/user/team/list/${teamId}`, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
})
export const getAllTeamLeaders = createAsyncThunk('team/getAllTeamLeaders', async (teamId, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/user/team/leader/list/${teamId}`, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
})
export const deleteTeamById = createAsyncThunk('team/deleteTeamById', async (teamId, thunkAPI) => {
    return await axios.delete(`${process.env.REACT_APP_BASE_URL}/team/${teamId}`, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
})

const teamSlice = createSlice({
    name: "team",
    initialState: {
        createTeamReducer: {loading: false},
        getTeamsReducer: {loading: false},
        getTeamByIdReducer: {loading: false},
        updateTeamByIdReducer: {loading: false},
        getTeamMembersReducer: {loading: false},
        getAllTeamMembersReducer: {loading: false},
        getAllTeamLeadersReducer: {loading: false},
        deleteTeamByIdReducer: {loading: false},
    },
    extraReducers: {
        // Create Team
        [createTeam.pending]: (state) => {
            state.createTeamReducer = {loading: true}
        },
        [createTeam.fulfilled]: (state, action) => {
            showToast(formatMessage(CreatedSuccessfully,["Team"]),"success")
            state.createTeamReducer = {loading: false, data: action.payload.data}
        },
        [createTeam.rejected]: (state, action) => {
            state.createTeamReducer = {loading: false}
            showToast(action?.payload?.detailDesc, "error")
        },
        // Get Teams
        [getTeams.pending]: (state) => {
            state.getTeamsReducer = {loading: true}
        },
        [getTeams.fulfilled]: (state, action) => {
            state.getTeamsReducer = {loading: false, data: action.payload.data}
        },
        [getTeams.rejected]: (state, action) => {
            state.getTeamsReducer = {loading: false}
            showToast(action?.payload?.detailDesc, "error")
        },

        // Get Team By Id
        [getTeamById.pending]: (state) => {
            state.getTeamByIdReducer = {loading: true}
        },
        [getTeamById.fulfilled]: (state, action) => {
            state.getTeamByIdReducer = {loading: false, data: action.payload.data}
        },
        [getTeamById.rejected]: (state, action) => {
            state.getTeamByIdReducer = {loading: false}
            showToast(action?.payload?.detailDesc, "error")
        },

        // Update Team By Id
        [updateTeamById.pending]: (state) => {
            state.updateTeamByIdReducer = {loading: true}
        },
        [updateTeamById.fulfilled]: (state, action) => {
            state.updateTeamByIdReducer = {loading: false, data: action.payload.data}
        },
        [updateTeamById.rejected]: (state, action) => {
            state.updateTeamByIdReducer = {loading: false}
            showToast(action?.payload?.detailDesc, "error")
        },

        // Get Team Members
        [getTeamMembers.pending]: (state) => {
            state.getTeamMembersReducer = {loading: true}
        },
        [getTeamMembers.fulfilled]: (state, action) => {
            state.getTeamMembersReducer = {loading: false, data: action.payload.data}
        },
        [getTeamMembers.rejected]: (state, action) => {
            state.getTeamMembersReducer = {loading: false}
        },

        // Get All Team Members
        [getAllTeamMembers.pending]: (state) => {
            state.getAllTeamMembersReducer = {loading: true}
        },
        [getAllTeamMembers.fulfilled]: (state, action) => {
            state.getAllTeamMembersReducer = {loading: false, data: action.payload.data}
        },
        [getAllTeamMembers.rejected]: (state, action) => {
            state.getAllTeamMembersReducer = {loading: false}
        },

        // Get All Team Leaders
        [getAllTeamLeaders.pending]: (state) => {
            state.getAllTeamLeadersReducer = {loading: true}
        },
        [getAllTeamLeaders.fulfilled]: (state, action) => {
            state.getAllTeamLeadersReducer = {loading: false, data: action.payload.data}
        },
        [getAllTeamLeaders.rejected]: (state, action) => {
            state.getAllTeamLeadersReducer = {loading: false}
        },

        // Delete Team By Id
        [deleteTeamById.pending]: (state) => {
            state.deleteTeamByIdReducer = {loading: true}
        },
        [deleteTeamById.fulfilled]: (state, action) => {
            state.deleteTeamByIdReducer = {loading: false, data: action.payload.data}
            showToast(action.payload.data, "success")
        },
        [deleteTeamById.rejected]: (state, action) => {
            state.deleteTeamByIdReducer = {loading: false}
            showToast(action?.payload?.detailDesc, "error")
        },

    }
})

export const {} = teamSlice.actions
export default teamSlice.reducer;


