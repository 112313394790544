import './AddSurvey.css'
import {AccessDenied, AccessDeniedMsg,} from "../../../helpers/constants";
import {hasPermission, Permissions} from "../../../helpers/accessControl";
import ErrorPage from "../../errorPage/ErrorPage";
import AddAndUpdateSurvey from "../addAndUpdateSurvey/AddAndUpdateSurvey";

const AddSurvey = () => {
    return !hasPermission([Permissions.REFLECTION_CREATE], true) ?
        <ErrorPage value={403} description={AccessDenied} detailDesc={AccessDeniedMsg}></ErrorPage> : (<>
            <AddAndUpdateSurvey formType={"Add"} ></AddAndUpdateSurvey>
        </>)
}
export default AddSurvey