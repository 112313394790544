import Modal from "react-bootstrap/Modal";
import {RxCross2} from "react-icons/rx";
import {useEffect, useState} from "react";
import {hexToRgb, Survey} from "../../../helpers/constants";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    assignReflectionToOrg,
    searchSurveysToAssignToOrg
} from "../../../reduxStore/surveySlices/surveySlices";
import {
    formatDate,
    isUnauthorized,
    tableCustomStyling,
    TableSkeletonLoading,
    trim
} from "../../../helpers/utils";
import Pagination from "../../commons/Pagination";
import DataTable from "react-data-table-component";
import {TailSpinLoader} from "../../layout/Loader";
import {IoIosClose} from "react-icons/io";

const AssignReflection = ({show, setShow,orgId,getUpdatedReflectionList}) => {
    const [timer, setTimer] = useState(null)
    const [applySearch,setApplySearch]=useState(false)
    const surveys = useSelector(state => state.survey)
    const [selectedReflection,setSelectedReflection]=useState(null)
    const handleClose = () => setShow(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [pageNumber, setPageNumber] = useState(1);

    const [sortBy,setSortBy]=useState("createdAt");
    const [sortOrder,setSortOrder]=useState("desc");
    const handleSort =  (column, sortDirection,) => {
        setSortBy(column.sortField)
        setSortOrder(sortDirection)
    };
    const [searchData, setSearchData] = useState({
        surveyName: "",
        tag: "",
        goal: "",
        page: "1",
        status: Survey.SURVEY_STATUS_DRAFT,
        enable: true,
        assignTo:true,
        sortBy:sortBy,
        sortOrder:sortOrder,
        pageSize:5
    })
    const handleOnChange = (e) => {
        setSearchData({...searchData, [e.target.name]: e.target.value})
    }
    useEffect(() => {
        if(applySearch){
            if (timer) {
                clearTimeout(timer)
            }
            setTimer(setTimeout(() => {
                callApiFunction()
            }, 1000))
        }

    }, [searchData])
    const callApiFunction = () => {
        const updatedSearchData = {...searchData, page: pageNumber,sortOrder:sortOrder,sortBy:sortBy}
        dispatch(searchSurveysToAssignToOrg(updatedSearchData)).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
        });


    };

    useEffect(() => {
        callApiFunction();
        setApplySearch(true)
        return()=>{
            getUpdatedReflectionList();
        }
    }, [pageNumber,sortOrder,sortBy])
    const columns = [
        {
            name: "Reflection",
            selector: row => row.name,
            sortable: true,
            sortField: 'name',
            style: {
                fontFamily: 'General Sans Medium',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            },
            cell: row => <div onClick={()=>{
                if(row.orgId!==orgId){
                    setSelectedReflection(row.surveyId)
                }
            }} title={row.name} className={"wrap-text"}>{trim(row.name, 40)}</div>

        },
        {
            name: "Date",
            selector: row => row.createdAt,
            sortField: 'createdAt',
            cell: row =>  <div>{(formatDate(row.createdAt))}</div>,
            sortable: true,
            style: {
                opacity: ".7"
            },
            width: surveys.searchSurveysToAssignToOrgReducer?.loading?"": "140px"
        },
        {
            name: "Status",
            selector: row => row.createdAt,
            sortField: 'createdAt',
            cell: row =>  <div>{row.orgId===orgId?"Assigned":"Unassigned"}</div>,
            sortable: true,
            style: {
                opacity: ".7"
            },
            width: surveys.searchSurveysToAssignToOrgReducer?.loading?"": "130px"
        },
    ]
    const conditionalRowStyle = [
        {
            when: row => row.surveyId === selectedReflection,
            style: {
                backgroundColor: (localStorage.getItem("color") === null || localStorage.getItem("color") === undefined || localStorage.getItem("color") === 'undefined') ? '#f4956c': hexToRgb(localStorage.getItem("color"),0.1),
            },
        },
    ]
    const handleAssignReflection=()=>{
        if(selectedReflection!==null && selectedReflection!==undefined && selectedReflection!==""){
            dispatch(assignReflectionToOrg({surveyId:selectedReflection,orgId:orgId})).then(res=>{
                if(isUnauthorized(res)){
                    navigate("/")
                } else if(res.meta.requestStatus==="fulfilled"){
                    setSelectedReflection(null)
                    callApiFunction();


                }
            })
        }
    }

    return (
        <>
            <Modal className={"modal-lg"} show={show} centered>
                <Modal.Header className={"d-flex"}>
                    <Modal.Title className='mediumFontSize'>Assign Reflection</Modal.Title>
                    <IoIosClose className={"close_btn"} onClick={handleClose} size={24}/>
                </Modal.Header>

                <Modal.Body className="modal_outer">

                    <div className={"attempt-survey-search-box search-box text-center"}>
                        <form className={"search-box-form"}>
                            <input type={"text"} className={"form-control search-box-input-name height"}
                                   name={"surveyName"}
                                   onChange={handleOnChange}
                                   value={searchData.surveyName}
                                   placeholder={"Name..."}/>
                            <div className={"cross-btn-outer"}><RxCross2
                                className={"survey-search-clr-btn cursor-pointer"} onClick={() => {
                                setSearchData({...searchData, surveyName: ""})
                            }}/></div>

                            <input type={"text"} className={"form-control search-box-input-tag height"}
                                   name={"tag"}
                                   onChange={handleOnChange}
                                   value={searchData.tag}
                                   placeholder={"Tag..."}/>
                            <div className={"cross-btn-outer"}><RxCross2
                                className={"survey-search-clr-btn cursor-pointer"} onClick={() => {
                                setSearchData({...searchData, tag: ""})
                            }}/></div>
                            <input type={"text"} className={"form-control search-box-input-goal search-box-input-goal-modal height"}
                                   placeholder={"Goal..."}
                                   value={searchData.goal}
                                   onChange={handleOnChange}
                                   name={"goal"}/>
                            <div className={"cross-btn-outer"}><RxCross2
                                className={"survey-search-clr-btn cursor-pointer"} onClick={() => {
                                setSearchData({...searchData, goal: ""})
                            }}/></div>
                        </form>
                    </div>


                    <div className={"attempt-reflection-table-outer mt-3"}>
                        <DataTable className={" "+(surveys?.searchSurveysToAssignToOrgReducer?.data?.totalPages > 1 ?" table_design2":" table_design1")}
                                   noDataComponent={<div className={"lightFontSize cmn_fontStyle mt-2 mb-2"}>No
                                       Reflection Found</div>}
                                   persistTableHead={true} responsive={true}
                                   progressPending={surveys.searchSurveysToAssignToOrgReducer?.loading}
                                   progressComponent={<div
                                       className={"w-100 attempted-reflection-skeleton-loader-modal"}>
                                       <TableSkeletonLoading columns={3} rows={5}/></div>}
                                   customStyles={{
                                       ...tableCustomStyling, rows: {
                                           ...tableCustomStyling.rows, style: {
                                               ...tableCustomStyling.rows.style, '&:hover': {
                                                   cursor: 'pointer'
                                               }
                                           }
                                       }
                                   }
                                   }
                                   onRowClicked={(row) => {
                                       if(row.orgId!==orgId){
                                           setSelectedReflection(row.surveyId)
                                       }

                                   }}
                                   columns={columns}
                                   data={surveys?.searchSurveysToAssignToOrgReducer?.data?.data}
                                   conditionalRowStyles={conditionalRowStyle}
                                   onSort={handleSort} sortServer={true}
                        />
                        {
                            !surveys.searchSurveysToAssignToOrgReducer?.loading && surveys?.searchSurveysToAssignToOrgReducer?.data && surveys?.searchSurveysToAssignToOrgReducer?.data?.totalPages > 1 &&
                            <Pagination totalPages={surveys?.searchSurveysToAssignToOrgReducer?.data?.totalPages}
                                        pageNumber={surveys?.searchSurveysToAssignToOrgReducer?.data.pageNumber}
                                        setPageNumber={setPageNumber}/>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal_footer">
                    <button className="modal_cancel_btn cmn_modal_btn mediumFontSize" onClick={handleClose}>Cancel
                    </button>
                    <button
                            disabled={selectedReflection===null || selectedReflection===undefined || selectedReflection==="" || surveys?.assignReflectionToOrgReducer?.loading}
                            onClick={handleAssignReflection}
                            style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""} }
                            className={"modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize  " +((selectedReflection===null || selectedReflection===undefined || selectedReflection==="" )?" disable-btn":"")}
                    >Assign
                        {
                            surveys?.assignReflectionToOrgReducer?.loading && <TailSpinLoader/>
                        }


                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default AssignReflection;