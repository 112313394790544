import {useState} from 'react'
import '../style.css'
import leftAroow from '../../../images/arrow-left.png'
import logo from '../../../images/Union.png'
import Form from 'react-bootstrap/Form'
import {useNavigate} from "react-router-dom";
import {emailRegExp, isNullOrEmpty, showToast} from "../../../helpers/utils";
import {EmailRequired, InvalidEmail} from "../../../helpers/constants";
import {useDispatch, useSelector} from "react-redux";
import {forgetPassword} from "../../../reduxStore/usersSlices/userSlice";
import {TailSpinLoader} from "../../layout/Loader";

const ForgetPassword = () => {

    const navigate = useNavigate();
    const users=useSelector(state => state.users)
    const [email, setEmail] = useState()
    const dispatch=useDispatch();
    const handleSubmit = (e) => {
        e.preventDefault();
        let message = "";
        if (isNullOrEmpty(email)) {
            message = EmailRequired
        } else if (!emailRegExp.test(email)) {
            message = InvalidEmail
        }
        if (message) {
            showToast(message,"error")
        }else{
            // Dispatch the Request to send OTP on Email
            dispatch(forgetPassword(email)).then((res)=>{
                if(res.meta.requestStatus==="fulfilled"){
                    setEmail("")
                    navigate("/")
                }
            })
        }

    }


    return (

        <>
            <div className='container_box'>
                <div className="cmn_outer">
                    <div className="">
                        <img className={"cursor"} src={leftAroow} height="25" width="25" alt={""} onClick={()=>{navigate("/")}}/>
                        <img src={logo} width="100" height="24" className='ms-2 pulse_img' alt={""}/>
                        <h2 className="cmn_heading mediumFontSize">Forgot password</h2>
                    </div>
                    <div className="cmn_form_outer">
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className='regularFontSize'>Email*</Form.Label>
                                <div className='password_container'>
                                    <Form.Control required={true} type='email' placeholder='Enter Your Email' value={email}
                                                  onChange={(e) => {
                                                      setEmail(e.target.value)
                                                  }}/>
                                </div>
                            </Form.Group>
                            <div className='btn_outer'>
                                <button type={"submit"} className=" cmn_btn cmn_background_color mediumFontSize" >Submit
                                    {
                                        users.forgetPasswordReducer?.loading && <TailSpinLoader></TailSpinLoader>
                                    }
                                </button>
                                <button className=" cmn_form_btn  mediumFontSize" onClick={() => {
                                    navigate("/")
                                }}>Cancel
                                </button>

                            </div>
                        </Form>

                    </div>

                </div>
            </div>
        </>
    )
}
export default ForgetPassword