import Modal from "react-bootstrap/Modal";
import {IoIosClose} from "react-icons/io";
import Select from "react-select";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {createOptionListForSelectTag} from "../../../helpers/utils";

const AssignStrategyToReflectionModal = ({show, setShow, strategyData, setStrategyData}) => {

    const orgPreferenceColor = localStorage.getItem("color") !== null && localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : "#fc6620"

    const [dropDownList, setDropDownList] = useState([])
    const [selectedReflections, setSelectedReflections] = useState([])

    const surveysApi = useSelector(state => state.survey.getPublishSurveysReducer)

    useEffect(() => {
        const selectedSurveyIds=strategyData?.surveys?.map(cur=>cur?.value)
        setDropDownList(createOptionListForSelectTag(surveysApi?.data?.filter(cur => !selectedSurveyIds?.includes(cur?.surveyId)) || [], "name", "surveyId"))
    }, []);

    const handleClose = () => setShow(false);
    const handleAssignReflection = () => {
        setStrategyData({
            ...strategyData,
            surveys: [...strategyData.surveys, ...selectedReflections]
        })
        handleClose()
    }

    return (
        <>
            <Modal className={"modal-lg"} show={show} centered>
                <Modal.Header className={"d-flex"}>
                    <Modal.Title className='mediumFontSize'>Assign Reflection</Modal.Title>
                    <IoIosClose className={"close_btn"} onClick={handleClose} size={24}/>
                </Modal.Header>

                <Modal.Body className="modal_outer">

                    <div className={"assigned-surveys"}>
                        <form className={""}>
                            <label>Select reflections</label>
                            <Select
                                className="members-filter-role react-select-container"
                                classNamePrefix="react-select"
                                isMulti
                                value={selectedReflections}
                                options={dropDownList}
                                onChange={(selectedOptions) => {
                                    setSelectedReflections(selectedOptions)
                                }}
                                placeholder={"Select Reflections ..."}/>

                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal_footer">
                    <button className="modal_cancel_btn cmn_modal_btn mediumFontSize" onClick={handleClose}>Cancel
                    </button>

                    <button
                        onClick={handleAssignReflection}
                        style={{background: orgPreferenceColor}}
                        className={"ms-2 modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize "}>
                        Assign
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default AssignStrategyToReflectionModal