import {Roles, roleHasAccess, hasPermission, Permissions} from "../../helpers/accessControl";
import React, {useEffect, useState} from "react";
import UserInfo from "../layout/UserInfo";
import "../teams/Teams.css"
import search_icon from "../../images/search_icon.svg";
import {
    applyValidDateRange,
    createOptionListForSelectTag, createOptionListForSelectTagWithMultipleLabels,
    formatAndComputeAutoDateRange,
    formatDate, getAuthorityLevelFor,
    getDataFromLocalStorage, getRolesWithAuthorityLevel, handleApiResponse,
    isUnauthorized,
    tableCustomStyling,
    TableSkeletonLoading, trim
} from "../../helpers/utils";
import DatePicker from "react-datepicker";
import filter_icon from "../../images/filter_icon.svg";
import DataTable from "react-data-table-component";
import Pagination from "../commons/Pagination";
import CreateTeamModal from "./modal/CreateTeamModal";
import {useDispatch, useSelector} from "react-redux";
import {deleteTeamById, getTeams} from "../../reduxStore/teamSlices/teamSlice";
import {NavLink, useNavigate} from "react-router-dom";
import jwtDecode from "jwt-decode";
import {CiEdit} from "react-icons/ci";
import {SearchFilterModal} from "../commons/SearchFilterModal";
import Select from "react-select";
import {getAllOrgAsList} from "../../reduxStore/orgSlices/orgSlices";
import {getAllUsersWithoutPagination} from "../../reduxStore/usersSlices/userSlice";
import UpdateTeamModal from "./modal/UpdateTeamModal";
import {RiDeleteBin6Line} from "react-icons/ri";
import ConfirmationModel from "../commons/ConfirmationModel";

const Teams = () => {

    const token = jwtDecode(getDataFromLocalStorage("token"))
    const isOrgLevelSys = token.orgLevel === "SYSTEM"
    const isRoleOrgAdmin = token.roles.includes(Roles.ORG_ADMIN)
    const isRoleSubOrgAdmin = token.roles.includes(Roles.SUB_ORG_ADMIN)
    const isRoleManager = token.roles.includes(Roles.MANAGER)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [membersList, setMembersList] = useState({})
    const [showCreateTeamModal, setShowCreateTeamModal] = useState(false)
    const [showUpdateTeamModal, setShowUpdateTeamModal] = useState(false)
    const [showDeleteTeamConfirmationModal, setShowDeleteTeamConfirmationModal] = useState(false)
    const [triggerApi, setTriggerApi] = useState(true)
    const [timer, setTimer] = useState(null)
    const [teamIdToUpdate, setTeamIdToUpdate] = useState(null)
    const [teamToDeleteId, setTeamToDeleteId] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [sortBy, setSortBy] = useState("createdAt");
    const [sortOrder, setSortOrder] = useState("desc");
    const [showSearchFilterModal, setShowSearchFilterModal] = useState(false)
    const [searchQuery, setSearchQuery] = useState({
        teamName: null,
        org: {
            label: "Organization...", value: token?.orgId
        },
        managedBy: [],
        membersIds: [],
        createdDateRange: null
    })
    const getTeamsData = useSelector(state => state.team.getTeamsReducer);
    const deleteTeamByIdData = useSelector(state => state.team.deleteTeamByIdReducer);
    const getAllOrgAsListData = useSelector(state => state.org.getAllOrgAsListReducer);
    const getAllUsersWithoutPaginationData = useSelector(state => state.users.getAllUsersWithoutPaginationReducer);

    const columns = [
        {
            name: <div className={"d-flex"}><span
                className={"ms-2 bulk_delete_Main_header"}> {"Name " + (isOrgLevelSys && isRoleOrgAdmin ? "( Organization )" : "")} </span>
            </div>,
            sortable: true,
            sortField: 'teamName',
            cell: (row) =>
                (
                    <div className={"d-flex"}>
                        <div className={"display_profile_data wrap-text ms-2"}>
                            <NavLink
                                title={row.teamName + (isOrgLevelSys && isRoleOrgAdmin ? " ( " + row.orgInfo.orgName + " )" : "")}
                                to={`/teams/${row.uid}`}>
                                {trim(row.teamName + (isOrgLevelSys && isRoleOrgAdmin ? " ( " + row.orgInfo.orgName + " )" : ""),45)}
                            </NavLink>
                        </div>
                    </div>

                ),
            style: {
                fontFamily: 'General Sans Medium',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            },
            width: getTeamsData?.loading ? "25%" : "30%"
        },
        {
            name: "Created By",
            selector: row => row.createdByUserInfo.firstName + " " + row.createdByUserInfo.lastName
        },
        {
            name: "Leaders",
            selector: row => trim(row.managedByUserInfo?.map(cur => cur.firstName + " " + cur.lastName).join(","), 80),
        },
        {
            name: "Created On",
            sortField: 'createdAt',
            sortable: true,
            cell: row => <div>{row.createdAt === "" ? "----" : (formatDate(row.createdAt))}</div>,
        },
        {
            name: "Action",
            cell: row => (
                <div>
                    <CiEdit
                        onClick={() => {
                            if (row.createdBy === token?.userid) {
                                setTeamIdToUpdate(row.uid)
                                setShowUpdateTeamModal(true)
                            }
                        }}
                        size={18}
                        className={row.createdBy === token?.userid ? " ms-1 cursor cursor-pointer" : " ms-1  opacity5"}/>
                    <RiDeleteBin6Line
                        onClick={() => {
                            if (row.createdBy === token?.userid) {
                                setTeamToDeleteId(row.uid)
                                setShowDeleteTeamConfirmationModal(true)
                            }
                        }}
                        size={16}
                        className={row.createdBy === token?.userid ? " ms-1 cursor cursor-pointer" : " ms-1  opacity6"}/>
                </div>
            ),
        }
    ]
    let searchFiltersTags = [
        {
            shouldShow: isOrgLevelSys && (isRoleOrgAdmin || isRoleManager),
            tagView: <Select
                className="members-filter-role react-select-container" classNamePrefix="react-select"
                isDisabled={getAllUsersWithoutPaginationData?.loading || getAllOrgAsListData?.loading}
                value={searchQuery?.org}
                options={createOptionListForSelectTag(getAllOrgAsListData?.data, "name", "orgId")}
                onChange={(selectedOrg) => {
                    if (searchQuery?.org?.value !== selectedOrg?.value) {
                        setSearchQuery({
                            ...searchQuery,
                            org: selectedOrg,
                            managedBy: [],
                            membersIds: [],
                        })
                    }
                }}
                isClearable={true}
                placeholder={"Organization..."}/>
        },
        {
            shouldShow: (isRoleOrgAdmin || isRoleSubOrgAdmin || isRoleManager)  && hasPermission([Permissions.USER_READ], true),
            tagView: <Select
                className="members-filter-role react-select-container"
                classNamePrefix="react-select"
                isDisabled={getAllUsersWithoutPaginationData?.loading || getAllOrgAsListData?.loading}
                isMulti
                isClearable={true}
                value={searchQuery.managedBy}
                options={createOptionListForSelectTagWithMultipleLabels(membersList[searchQuery?.org?.value || "all"]?.filter(user => user.roleObjects[0]?.name !== Roles.MENTEE), ["firstName", "lastName"], "uid")}
                onChange={(selectedValue) => {
                    setSearchQuery({
                        ...searchQuery,
                        managedBy: selectedValue?.length === 0 ? null : selectedValue
                    })
                }}
                placeholder={"Leaders..."}/>

        },
        {
            shouldShow: (isRoleOrgAdmin || isRoleSubOrgAdmin || isRoleManager)  &&  hasPermission([Permissions.USER_READ], true),
            tagView: <Select
                className="members-filter-role react-select-container"
                classNamePrefix="react-select"
                isDisabled={getAllUsersWithoutPaginationData?.loading || getAllOrgAsListData?.loading}
                isMulti
                isClearable={true}
                value={searchQuery?.membersIds}
                options={createOptionListForSelectTagWithMultipleLabels(membersList[searchQuery?.org?.value || "all"], ["firstName", "lastName"], "uid")}
                onChange={(selectedValue) => {
                    setSearchQuery({
                        ...searchQuery,
                        membersIds: selectedValue?.length === 0 ? null : selectedValue
                    })
                }}
                placeholder={"Members..."}/>

        },
        {
            tagView: <div className={"members-date-picker-outer "}>
                <DatePicker placeholderText="From: dd-mm-yyyy" dateFormat={"dd-MM-yyyy"}
                            selected={searchQuery?.createdDateRange?.startDate || ""}
                            className={"search-by-survey-name survey-name-search member_search "}
                            maxDate={searchQuery?.createdDateRange?.endDate || new Date()}
                            onChange={(date) => {
                                setSearchQuery({
                                    ...searchQuery,
                                    createdDateRange: applyValidDateRange({
                                        startDate: date,
                                        endDate: searchQuery?.createdDateRange?.endDate || null
                                    })
                                })
                            }}
                />
                <DatePicker placeholderText="To: dd-mm-yyyy" dateFormat={"dd-MM-yyyy"}
                            selected={searchQuery?.createdDateRange?.endDate || ""}
                            minDate={searchQuery?.createdDateRange?.startDate || ""}
                            className={"search-by-survey-name survey-name-search user-join-toDate member_search "}
                            maxDate={new Date()}
                            onChange={(date) => {
                                setSearchQuery({
                                    ...searchQuery,
                                    createdDateRange: applyValidDateRange({
                                        startDate: searchQuery?.createdDateRange?.startDate || null,
                                        endDate: date
                                    })
                                })
                            }}
                />

            </div>
        }
    ]


    useEffect(() => {
        if (isOrgLevelSys && isRoleOrgAdmin) {
            dispatch(getAllOrgAsList()).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                const onSuccess = () => {
                    const orgInfo = res.payload.data?.filter(org => org.orgId === token?.orgId)[0]
                    setSearchQuery({...searchQuery, org: {label: orgInfo.name, value: orgInfo.orgId}})
                }
                handleApiResponse(res, onSuccess, null)
            })
        }
    }, [])
    useEffect(() => {
        const orgId = searchQuery?.org?.value
        const isMembersPresentForOrgId = ((orgId === null || orgId === undefined) && (membersList["all"] !== null && membersList["all"] !== undefined)) || (membersList[orgId] !== undefined && membersList[orgId] !== null)
        if (!isMembersPresentForOrgId && hasPermission([Permissions.USER_READ], true) && (isRoleOrgAdmin || isRoleManager || isRoleSubOrgAdmin)) {
            const roleToGetAuthorityLevelFor = Roles.ORG_ADMIN
            const rolesToGet = getRolesWithAuthorityLevel("<=", getAuthorityLevelFor(roleToGetAuthorityLevelFor), [Roles.ANONYMOUS_USER])

            dispatch(getAllUsersWithoutPagination({
                roles: rolesToGet,
                ...(isOrgLevelSys ? {orgId: searchQuery?.org?.value} : {})
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                const onSuccess = () => {
                    if (searchQuery?.org?.value === null || searchQuery?.org?.value === undefined) {
                        setMembersList({
                            ...membersList,
                            "all": res.payload.data
                        })
                    } else {
                        setMembersList({
                            ...membersList,
                            [searchQuery?.org?.value]: res.payload.data
                        })
                    }
                }
                handleApiResponse(res, onSuccess())
            })
        }

    }, [searchQuery.org]);
    useEffect(() => {
        if (triggerApi) {
            const searchQueryToSubmit = {
                ...searchQuery,
                orgId: searchQuery?.org?.value,
                managedBy: searchQuery?.managedBy?.map(c => c.value) || [],
                membersIds: searchQuery?.membersIds?.map(c => c.value) || [],
                sortOrder: sortOrder,
                sortBy: sortBy,
                page: pageNumber,
                pageSize: 10,
            }
            if (searchQuery?.createdDateRange) {
                searchQueryToSubmit.createdDateRange = formatAndComputeAutoDateRange(searchQuery?.createdDateRange?.startDate || "", searchQuery?.createdDateRange?.endDate || "", "yyyy-MM-dd")
            }
            dispatch(getTeams(searchQueryToSubmit)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                setTriggerApi(false)
            })
        }
    }, [triggerApi]);
    useEffect(() => {
        if (searchQuery.teamName !== null) {
            if (timer) {
                clearTimeout(timer);
            }
            setTimer(setTimeout(() => {
                setTriggerApi(true);
            }, 1000));
        }
    }, [searchQuery.teamName]);


    const setPageNumberAndTriggerApi = (page) => {
        setPageNumber(page)
        setTriggerApi(true)
    }
    const handleSort = (column, sortDirection,) => {
        setSortBy(column.sortField)
        setSortOrder(sortDirection)
        setTriggerApi(true)
    };
    const handleDeleteTeam=()=>{
        dispatch(deleteTeamById(teamToDeleteId)).then(res=>{
            if(isUnauthorized(res)){
                navigate("/")
            }
            const onSuccess=()=>{
                setShowDeleteTeamConfirmationModal(false)
                setTriggerApi(true)
            }
            handleApiResponse(res,onSuccess)
        })
    }

    return (
        <>
            <section>


                <div className="cmn_header">
                    <h2 className="mediumFontSize m-0">Teams</h2> <UserInfo/>
                </div>

                <div className={"row main_content box_design1"}>
                    <div className={"col-md-12  px-1"}>


                        <div className="d-flex col-12">
                            <div className="reflection_search_wrapper ">
                                <img src={search_icon} alt=""/>
                                <input type={"text"} className={"form-control members-name-filter-input"}
                                       placeholder={"Name..."}
                                       value={searchQuery.teamName}
                                       onChange={(e) => {
                                           setSearchQuery({
                                               ...searchQuery,
                                               teamName: e.target.value
                                           })
                                       }}
                                />
                            </div>


                            <div className={"reflection_filters ms-auto"}>

                                <button type={"button"} onClick={() => {
                                    setShowSearchFilterModal(true)
                                }
                                }><img src={filter_icon} alt=""/>Filter
                                </button>
                            </div>

                            <div className={"members-btn"}>
                                {
                                    roleHasAccess([Roles.ORG_ADMIN, Roles.SUB_ORG_ADMIN, Roles.MANAGER]) &&
                                    <button
                                        style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                        className="cmn_background_color survey_addnew_btn mediumFontSize create-sub-org-btn ms-2"
                                        onClick={() => setShowCreateTeamModal(true)}
                                    > Create Team
                                    </button>
                                }
                            </div>
                        </div>


                    </div>

                    <div className={"colored_table pe-1 p-0"}>
                        <DataTable
                            className={(getTeamsData?.data?.totalPages > 1 ? " table_design2 " : " table_design1 ")}
                            persistTableHead={true} responsive={true}
                            customStyles={tableCustomStyling}
                            progressPending={getTeamsData?.loading}
                            columns={columns}
                            data={getTeamsData?.data?.data}
                            noDataComponent={<div className={"no-member-text"}>No Team Found</div>}
                            progressComponent={<TableSkeletonLoading columns={5} rows={5}/>}
                            onSort={handleSort}
                            sortServer={true}

                        />

                        {
                            !getTeamsData?.loading && getTeamsData?.data && getTeamsData?.data?.totalPages > 1 &&
                            <Pagination totalPages={getTeamsData?.data?.totalPages}
                                        pageNumber={pageNumber}
                                        setPageNumber={setPageNumberAndTriggerApi}/>
                        }

                    </div>

                </div>


            </section>
            {
                showCreateTeamModal &&
                <CreateTeamModal setTriggerApi={setTriggerApi} show={showCreateTeamModal}
                                 setShow={setShowCreateTeamModal}></CreateTeamModal>
            }
            {
                showUpdateTeamModal &&
                <UpdateTeamModal teamIdToUpdate={teamIdToUpdate} setTeamIdToUpdate={setTeamIdToUpdate}
                                 show={showUpdateTeamModal} setShow={setShowUpdateTeamModal}
                                 setTriggerApi={setTriggerApi}></UpdateTeamModal>
            }
            {
                showDeleteTeamConfirmationModal &&
                <ConfirmationModel title={"Delete Team"}
                                   btnText={"Delete"}
                                   isLoading={deleteTeamByIdData?.loading}
                                   action={handleDeleteTeam}
                                   show={showDeleteTeamConfirmationModal}
                                   bodyMessage={"Are you sure you want to proceed with the team deletion, which will also result in the removal of all reflection attempts and reports?"}
                                   setShow={setShowDeleteTeamConfirmationModal}
                                   showUserAcknowledgementCheckBox={true}
                                   userAcknowledgementCheckBoxText={"Yes, I understand"}
                />
            }
            {
                showSearchFilterModal &&
                <SearchFilterModal title={"Team Filters"}
                                   show={showSearchFilterModal}
                                   filtersTag={searchFiltersTags}
                                   applyFilter={triggerApi}
                                   setApplyFilter={setTriggerApi}
                                   resetFilters={() => {
                                       setSearchQuery({
                                           teamName: null,
                                           org: isOrgLevelSys && (isRoleOrgAdmin || isRoleManager) ? null : {
                                               label: "Organization...", value: token?.orgId
                                           },
                                           managedBy: [],
                                           membersIds: [],
                                           createdDateRange: null
                                       });
                                   }}
                                   handleClose={() => {
                                       setShowSearchFilterModal(false);
                                   }}
                                   setShow={setShowSearchFilterModal}

                />
            }
        </>
    )
        ;
}
export default Teams