import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getAxiosConfig, objectToQueryString, serialize, showToast} from "../../helpers/utils";
import {ReportCreatedSuccessfully} from "../../helpers/constants";

export const getAllCustomReportsData = createAsyncThunk('custom-report', async (data, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/custom-report?${objectToQueryString(data)}`, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    })
})

export const createCustomReport = createAsyncThunk('create-report', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/custom-report`,data, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    })
})

export const getReportDetails = createAsyncThunk('getReportDetails', async (reportId, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/custom-report/report-details/${reportId}`, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    })
})

export const getReportDetailsBySurveyIdAndSurveyeeId = createAsyncThunk('getReportDetailsBySurveyIdAndSurveyeeId', async (reportId, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/custom-report/report-details-surveyId-and-surveyeeId/${reportId}`, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    })
})

export const exportReportDetails = createAsyncThunk('exportReportDetails', async (reportId, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/custom-report/export/report-details/${reportId}`, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    })
})

export const updateCustomReport = createAsyncThunk('update-report', async (data, thunkAPI) => {
    return await axios.put(`${process.env.REACT_APP_BASE_URL}/custom-report/update/${data?.id}`,data, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    })
})

export const deleteCustomReport = createAsyncThunk('delete-report', async (reportId, thunkAPI) => {
    return await axios.delete(`${process.env.REACT_APP_BASE_URL}/custom-report/delete/${reportId}`, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    })
})

export const getIdCustomReport = createAsyncThunk('getIdCustomReport', async (reportId, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/custom-report/${reportId}`, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    })
})

export const getCustomReportsByOrgIdAndCreator = createAsyncThunk('getAllCustomReportsByOrgId', async (data, thunkAPI) => {
    const serializedData = serialize(data);
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/custom-report/list?${serializedData}`, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    })
})

const customReport = createSlice({
    name: "custom-report",
    initialState: {
        getAllCustomReportsDataReducer: {loading:false},
        createCustomReportReducer: {loading:false},
        getReportDetailsReducer: {loading:false},
        getReportDetailsBySurveyIdAndSurveyeeIdReducer: {loading:false},
        exportReportDetailsReducer: {loading:false},
        getIdCustomReportReducer: {loading:false},
        updateCustomReportReducer: {loading:false},
        deleteCustomReportReducer: {loading:false},
        getCustomReportsByOrgIdAndCreatorReducer: {loading:false},
    },
    reducers: {
        resetGetIdCustomReportReducer: (state) => {
            state.getIdCustomReportReducer = {loading: false, data: null}
        }
    },
    extraReducers: {

        [getAllCustomReportsData.pending]: (state) => {
            state.getAllCustomReportsDataReducer = {loading:true};
        },
        [getAllCustomReportsData.fulfilled]: (state, action) => {
            state.getAllCustomReportsDataReducer = {loading:false , data:action.payload.data };
        },
        [getAllCustomReportsData.rejected]: (state, action) => {
            state.getAllCustomReportsDataReducer = {loading:false};
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText,"error")
        },


        [createCustomReport.pending]: (state) => {
            state.createCustomReportReducer = {loading:true};
        },
        [createCustomReport.fulfilled]: (state, action) => {
            state.createCustomReportReducer = {loading:false , data:action.payload.data };
            showToast(ReportCreatedSuccessfully,"success")

        },
        [createCustomReport.rejected]: (state, action) => {
            state.createCustomReportReducer = {loading:false};
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText,"error")
        },

        [getReportDetails.pending]: (state) => {
            state.getReportDetailsReducer = {loading:true};
        },
        [getReportDetails.fulfilled]: (state, action) => {
            state.getReportDetailsReducer = {loading:false , data:action.payload.data };
        },
        [getReportDetails.rejected]: (state, action) => {
            state.getReportDetailsReducer = {loading:false};
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText,"error")
        },
        [getReportDetailsBySurveyIdAndSurveyeeId.pending]: (state) => {
            state.getReportDetailsBySurveyIdAndSurveyeeIdReducer = {loading:true};
        },
        [getReportDetailsBySurveyIdAndSurveyeeId.fulfilled]: (state, action) => {
            state.getReportDetailsBySurveyIdAndSurveyeeIdReducer = {loading:false , data:action.payload.data };
        },
        [getReportDetailsBySurveyIdAndSurveyeeId.rejected]: (state, action) => {
            state.getReportDetailsBySurveyIdAndSurveyeeIdReducer = {loading:false};
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText,"error")
        },

        [exportReportDetails.pending]: (state) => {
            state.exportReportDetailsReducer = {loading:true};
        },
        [exportReportDetails.fulfilled]: (state, action) => {
            state.exportReportDetailsReducer = {loading:false , data:action.payload.data };
        },
        [exportReportDetails.rejected]: (state, action) => {
            state.exportReportDetailsReducer = {loading:false};
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText,"error")
        },

        [getIdCustomReport.pending]: (state) => {
            state.getIdCustomReportReducer = {loading:true};
        },
        [getIdCustomReport.fulfilled]: (state, action) => {
            state.getIdCustomReportReducer = {loading:false , data:action.payload.data };
        },
        [getIdCustomReport.rejected]: (state, action) => {
            state.getIdCustomReportReducer = {loading:false};
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText,"error")
        },

        [updateCustomReport.pending]: (state) => {
            state.updateCustomReportReducer = {loading:true};
        },
        [updateCustomReport.fulfilled]: (state, action) => {
            state.updateCustomReportReducer = {loading:false , data:action.payload.data };
            showToast(action.payload.data,"success")

        },
        [updateCustomReport.rejected]: (state, action) => {
            state.updateCustomReportReducer = {loading:false};
            showToast( action?.payload?.data?.detailDesc || action?.payload?.statusText,"error")
        },

        [deleteCustomReport.pending]: (state) => {
            state.deleteCustomReportReducer = {loading:true};
        },
        [deleteCustomReport.fulfilled]: (state, action) => {
            state.deleteCustomReportReducer = {loading:false , data:action.payload.data };
            showToast(action.payload.data,"success")

        },
        [deleteCustomReport.rejected]: (state, action) => {
            state.deleteCustomReportReducer = {loading:false};
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText,"error")
        },

        [getCustomReportsByOrgIdAndCreator.pending]: (state) => {
            state.getCustomReportsByOrgIdAndCreatorReducer = {loading:true};
        },
        [getCustomReportsByOrgIdAndCreator.fulfilled]: (state, action) => {
            state.getCustomReportsByOrgIdAndCreatorReducer = {loading:false , data:action.payload.data };
        },
        [getCustomReportsByOrgIdAndCreator.rejected]: (state, action) => {
            state.getCustomReportsByOrgIdAndCreatorReducer = {loading:false};
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText,"error")
        }
    }

})

export const {resetGetIdCustomReportReducer} = customReport.actions
export default customReport.reducer;