import {
    applyValidDateRange, createOptionListForSelectTag,
    formatAndComputeAutoDateRange, formatDate, getDataFromLocalStorage, getFirstFiveElements,
    handleApiResponse, isCampaignEditable,
    isUnauthorized, tableCustomStyling, TableSkeletonLoading, toString, trim
} from "../../helpers/utils";
import React, {useEffect, useState} from "react";
import {Tooltip as ReactTooltip} from "react-tooltip";
import DatePicker from "react-datepicker";
import {useDispatch, useSelector} from "react-redux";
import {getAllOrgAsList} from "../../reduxStore/orgSlices/orgSlices";
import UserInfo from "../layout/UserInfo";
import search_icon from "../../images/search_icon.svg";
import filter_icon from "../../images/filter_icon.svg";
import DataTable from "react-data-table-component";
import Pagination from "../commons/Pagination";
import {SearchFilterModal} from "../commons/SearchFilterModal";
import ConfirmationModel from "../commons/ConfirmationModel";
import jwtDecode from "jwt-decode";
import {roleHasAccess, Roles} from "../../helpers/accessControl";
import {useNavigate} from "react-router-dom";
import {MdOutlineEdit} from "react-icons/md";
import {RiDeleteBinLine} from "react-icons/ri";
import {FaEye} from "react-icons/fa";
import Select from "react-select";
import image from "../../images/CrossIcon2.svg";
import "./Campaigns.css"
import {deleteCampaign, searchCampaign} from "../../reduxStore/campaignSlices/campaignSlice";
import {
    CampaignDeliveryMethod,
    CampaignNotEditable,
    CampaignRecurrence,
    CampaignStatus,
    CampaignType, ConnectApiKeyThenEdit
} from "../../helpers/constants";
import ViewCampaignModal from "./ViewCampaignModal";
import {getApiKeysByOrgId} from "../../reduxStore/apiKeySlices/apiKeySlice";
import {resetReducers} from "../../reduxStore/globalKeysSlice/globalKeysSlice";

const Campaigns = ({isSubOrg, subOrgId}) => {
    const token = jwtDecode(getDataFromLocalStorage("token"))
    const isOrgLevelSys = token.orgLevel === "SYSTEM"
    const isRoleOrgAdmin = token.roles.includes(Roles.ORG_ADMIN) || token.roles.includes(Roles.SUB_ORG_ADMIN)
    const isRoleManager = token.roles.includes(Roles.MANAGER)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [render, setRender] = useState(0);
    const [timer, setTimer] = useState(null)
    const [campaignToDeleteId, setCampaignToDeleteId] = useState(null)
    const [campaignToView, setCampaignToView] = useState(null)
    const [triggerApi, setTriggerApi] = useState(true)
    const [triggerApiOnNameChange, setTriggerApiOnNameChange] = useState(false)
    const [modals, setModals] = useState({
        showDeleteCampaignConfirmationModal: false,
        showViewCampaignModal: false,
        showSearchFilterModal: false,
    })
    const [searchQuery, setSearchQuery] = useState({
        name: null,
        org: {
            label: "Organization...", value: isSubOrg ? subOrgId : token?.orgId
        },
        type: null,
        status: null,
        recurrence: [],
        createdDateRange: null
    })
    const [sortCriteria, setSortCriteria] = useState({
        sortBy: "createdAt",
        sortOrder: "desc",
    })
    const [pageNumber, setPageNumber] = useState(1)

    const handleSearchQueryChange = (e) => {
        const {name, value} = e.target
        setSearchQuery({
            ...searchQuery,
            [name]: value
        })
    }

    const OrgListApi = useSelector(state => state.org.getAllOrgAsListReducer);
    const campaignApi = useSelector(state => state.campaign.searchCampaignReducer)
    const deleteCampaignApi = useSelector(state => state.campaign.deleteCampaignReducer)
    const getApiKeysApi = useSelector(state => state.apiKey.getApiKeysByOrgIdReducer)

    const columns = [
        {
            name: <div className={"d-flex"}>
                <span
                    className={"ms-2 bulk_delete_Main_header"}> {"Name " + (isOrgLevelSys && (isRoleOrgAdmin || isRoleManager) && !isSubOrg ? "( Organization )" : "")} </span>
            </div>,
            sortable: true,
            sortField: 'name',
            cell: (row) =>
                (
                    <div className={"d-flex"}>
                        <div className={"display_profile_data wrap-text ms-2"}>
                            <div
                                title={row.name + (isOrgLevelSys && (isRoleOrgAdmin || isRoleManager) && !isSubOrg ? " ( " + row.orgInfo.orgName + " )" : "")}>
                                {trim(row.name + (isOrgLevelSys && (isRoleOrgAdmin || isRoleManager) && !isSubOrg ? " ( " + row.orgInfo.orgName + " )" : ""), 30)}
                            </div>
                        </div>
                    </div>

                ),
            style: {
                fontFamily: 'General Sans Medium',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            },
            width: campaignApi?.loading ? "25%" : "30%"
        },
        {
            name: 'Created By',
            cell: row => <div>{trim(row.createdByUserInfo.firstName + " " + row.createdByUserInfo.lastName, 45)}</div>,
        },
        {
            name: 'Type',
            cell: row => <div title={toString(row.type)}>{row.type ? toString(row.type) : "---"}</div>,
        },
        {
            name: "Status",
            cell: row => (
                <>
                    {
                        row.status === "PAUSED" && <div>
                            <span
                                className={`survey-risk-status mx-0 bg-red campaign-status-text`}>{toString(row?.status?.toLowerCase())}</span>
                        </div>
                    }
                    {
                        row.status === "ACTIVE" && <div>
                            <span
                                className={`survey-risk-status mx-0 bg-orange campaign-status-text`}>{toString(row?.status?.toLowerCase())}</span>
                        </div>
                    }
                    {
                        row.status === "COMPLETED" && <div>
                            <span
                                className={`survey-risk-status mx-0 bg-green campaign-status-text`}>{toString(row?.status?.toLowerCase())}</span>
                        </div>
                    }
                </>

            ),
            width: "13%"
        },
        {
            name: "Created On",
            sortField: 'createdAt',
            sortable: true,
            cell: row => <div>{row.createdAt === "" ? "----" : (formatDate(row.createdAt))}</div>,
        },
        {
            name: 'Actions',
            cell: row => (
                <div className={"d-flex gap-2 strategy_action_wrapper "}>

                    {
                        (isRoleOrgAdmin && !isOrgLevelSys && row.createdBy === token.userid) ?
                            <>
                                {/*In this if campaign is not editable it will be viewable*/}
                                {
                                    !isCampaignEditable(row.scheduledOn, row.status) && <button
                                        className={`border-none bg-none`}
                                        onClick={() => {
                                            setCampaignToView(row)
                                            setModals({
                                                ...modals,
                                                showViewCampaignModal: true
                                            })
                                        }}
                                    ><FaEye/></button>
                                }
                                <button
                                    disabled={!isCampaignEditable(row.scheduledOn, row.status) || getApiKeysApi?.data?.length === 0}
                                    data-tooltip-id="EditCampaignBTN"
                                    data-tooltip-content={!isCampaignEditable(row.scheduledOn, row.status) ? CampaignNotEditable : getApiKeysApi?.data?.length === 0 ? ConnectApiKeyThenEdit : ""}
                                    onClick={() => {
                                        isCampaignEditable(row.scheduledOn, row.status) && navigate(`/campaign/${row.campaignId}`);
                                    }}
                                    className={`border-none bg-none`}
                                ><MdOutlineEdit/>
                                </button>
                                <ReactTooltip className={"ToolTip"} place={"bottom"} id="EditCampaignBTN"/>
                                <button
                                    onClick={() => {
                                        setCampaignToDeleteId(row.campaignId)
                                        setModals({
                                            ...modals,
                                            showDeleteCampaignConfirmationModal: true
                                        })
                                    }}
                                    className={`border-none bg-none`}
                                ><RiDeleteBinLine/>
                                </button>
                            </> :
                            <button
                                className={`border-none bg-none`}
                                onClick={() => {
                                    setCampaignToView(row)
                                    setModals({
                                        ...modals,
                                        showViewCampaignModal: true
                                    })
                                }}
                            ><FaEye/></button>

                    }
                </div>
            ),
        },
    ];
    let searchFiltersTags = [
        {
            shouldShow: isOrgLevelSys && (isRoleOrgAdmin || isRoleManager) && !isSubOrg,
            tagView: <Select
                className="members-filter-role react-select-container" classNamePrefix="react-select"
                isDisabled={OrgListApi?.loading}
                value={searchQuery?.org}
                options={createOptionListForSelectTag(OrgListApi?.data, "name", "orgId")}
                onChange={(selectedOrg) => {
                    if (searchQuery?.org?.value !== selectedOrg?.value) {
                        handleSearchQueryChange({target: {name: "org", value: selectedOrg}})
                    }
                }}
                isClearable={true}
                placeholder={"Organization..."}/>
        },
        {
            tagView: <>
                <div className="filter_input_wrapper">
                    <input
                        type={"text"}
                        className={"ms-0 form-control  campaign-name-input"}
                        placeholder={"Campaign Name..."}
                        onChange={handleSearchQueryChange}
                        value={searchQuery.name}
                        name={"name"}/>
                    <div className={"cross-btn-outer"}>
                        <img
                            src={image}
                            className={"survey-search-clr-btn cursor-pointer"}
                            onClick={() => {
                                handleSearchQueryChange({target: {name: "name", value: ""}})
                            }}/>
                    </div>
                </div>
            </>
        },
        {
            tagView: <Select
                className="members-filter-role react-select-container"
                classNamePrefix="react-select"
                options={createOptionListForSelectTag(CampaignType, "name", "value")}
                value={searchQuery?.type}
                onChange={(selectedValue) => {
                    if (searchQuery?.type?.value !== selectedValue?.value) {
                        setSearchQuery({...searchQuery, type: selectedValue})
                    }
                }}
                isClearable={true}
                placeholder={"Type..."}/>
        },
        {
            tagView: <Select
                className="members-filter-role react-select-container"
                classNamePrefix="react-select"
                options={createOptionListForSelectTag(CampaignStatus, "name", "value")}
                value={searchQuery?.status}
                onChange={(selectedValue) => {
                    if (searchQuery?.status?.value !== selectedValue?.value) {
                        setSearchQuery({...searchQuery, status: selectedValue})
                    }
                }}
                isClearable={true}
                placeholder={"Status..."}/>
        },
        {
            tagView: <Select
                className="members-filter-role react-select-container"
                classNamePrefix="react-select"
                options={createOptionListForSelectTag(CampaignRecurrence, "name", "value")}
                value={searchQuery?.recurrence}
                onChange={(selectedValue) => {
                    setSearchQuery({...searchQuery, recurrence: selectedValue})

                }}
                isMulti={true}
                isClearable={true}
                placeholder={"Recurrence..."}/>
        },
        {
            tagView: <div className={"members-date-picker-outer "}>
                <DatePicker placeholderText="From: dd-mm-yyyy" dateFormat={"dd-MM-yyyy"}
                            selected={searchQuery?.createdDateRange?.startDate || ""}
                            className={"search-by-survey-name survey-name-search member_search "}
                            maxDate={searchQuery?.createdDateRange?.endDate || new Date()}
                            onChange={(date) => {
                                handleSearchQueryChange({
                                    target: {
                                        name: "createdDateRange",
                                        value: applyValidDateRange({
                                            startDate: date,
                                            endDate: searchQuery?.createdDateRange?.endDate || null
                                        })
                                    }
                                })
                            }}
                />
                <DatePicker placeholderText="To: dd-mm-yyyy" dateFormat={"dd-MM-yyyy"}
                            selected={searchQuery?.createdDateRange?.endDate || ""}
                            minDate={searchQuery?.createdDateRange?.startDate || ""}
                            className={"search-by-survey-name survey-name-search user-join-toDate member_search "}
                            maxDate={new Date()}
                            onChange={(date) => {
                                handleSearchQueryChange({
                                    target: {
                                        name: "createdDateRange",
                                        value: applyValidDateRange({
                                            startDate: searchQuery?.createdDateRange?.startDate || null,
                                            endDate: date
                                        })
                                    }
                                })
                            }}
                />

            </div>
        }
    ]

    useEffect(() => {
        if (isOrgLevelSys && (isRoleOrgAdmin || isRoleManager)) {
            dispatch(getAllOrgAsList()).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (!isSubOrg) {
                    const onSuccess = () => {
                        const orgInfo = res.payload.data?.filter(org => org.orgId === token?.orgId)[0]
                        setSearchQuery({...searchQuery, org: {label: orgInfo.name, value: orgInfo.orgId}})
                    }
                    handleApiResponse(res, onSuccess)
                }
            })
        }
    }, [])
    useEffect(() => {
        if (roleHasAccess([Roles.ORG_ADMIN, Roles.SUB_ORG_ADMIN,Roles.MANAGER])) {
            dispatch(getApiKeysByOrgId(isSubOrg ? subOrgId : token?.orgId)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }
        return () => {
            dispatch(resetReducers({sliceNames: ["getApiKeysByOrgIdReducer"]}))
        }
    }, [])
    useEffect(() => {
        if (triggerApi) {
            const searchQueryToSubmit = {
                ...searchQuery,
                ...sortCriteria,
                orgId: searchQuery?.org?.value,
                type: searchQuery?.type?.value,
                recurrence: searchQuery?.recurrence?.map(cur => cur?.value) || [],
                status: searchQuery?.status?.value,
                page: pageNumber,
                pageSize: 10,
            }
            if (searchQuery?.createdDateRange) {
                searchQueryToSubmit.createdDateRange = formatAndComputeAutoDateRange(searchQuery?.createdDateRange?.startDate || "", searchQuery?.createdDateRange?.endDate || "", "yyyy-MM-dd")
            }
            dispatch(searchCampaign(searchQueryToSubmit)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                setTriggerApi(false)
            })
        }
    }, [triggerApi]);
    useEffect(() => {
        if (searchQuery.name != null && triggerApiOnNameChange) {
            if (timer) {
                clearTimeout(timer);
            }
            setTimer(setTimeout(() => {
                setTriggerApi(true);
            }, 1000));
        }
    }, [searchQuery.name]);
    useEffect(() => {
        const interval = setInterval(() => {
            setRender(render + 1)
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    const setPageNumberAndTriggerApi = (page) => {
        setPageNumber(page)
        setTriggerApi(true)
    }
    const handleSort = (column, sortDirection,) => {
        setSortCriteria({
            sortBy: column.sortField,
            sortOrder: sortDirection,
        })
        setTriggerApi(true)
    };
    const handleDeleteCampaign = () => {
        if (campaignToDeleteId) {
            dispatch(deleteCampaign(campaignToDeleteId)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                handleApiResponse(res, () => {
                    setCampaignToDeleteId(null)
                    setTriggerApi(true)
                    setModals({
                        ...modals,
                        showDeleteCampaignConfirmationModal: false
                    })
                })
            })
        }
    };
    return (
        <section className={"strategy_wrapper"}>
            {
                !isSubOrg && <div className="cmn_header">
                    <h2 className="mediumFontSize m-0">Campaign</h2> <UserInfo/>
                </div>
            }


            <div className={`row main_content ${isSubOrg ? " sub-org-members-outer" : " box_design1"}`}>
                <div className={"col-md-12  px-1"}>
                    <div className="d-flex col-12">
                        <div className="reflection_search_wrapper ">
                            <img src={search_icon} alt=""/>
                            <input
                                onFocus={() => {
                                    setTriggerApiOnNameChange(true)
                                }}
                                onBlur={() => {
                                    setTriggerApiOnNameChange(false)
                                }}
                                name={"name"}
                                type={"text"}
                                className={"form-control members-name-filter-input"}
                                placeholder={"Name..."}
                                value={searchQuery.name}
                                onChange={handleSearchQueryChange}
                            />
                        </div>


                        <div className={"reflection_filters ms-auto mx-2"}>
                            <button
                                type={"button"}
                                onClick={() => {
                                    setModals({
                                        ...modals,
                                        showSearchFilterModal: true
                                    })
                                }}>
                                <img src={filter_icon} alt=""/>Filter
                            </button>
                        </div>

                        {
                            !isSubOrg && isRoleOrgAdmin  && !isOrgLevelSys  && (CampaignDeliveryMethod.length > getApiKeysApi?.data?.length) &&
                            <div className={"members-btn"}>
                                <button
                                    style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                    className={' modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize me-2'}
                                    onClick={() => {
                                        navigate("/setting?connectAPI=true");
                                    }}>
                                    Connect Api Key
                                </button>
                            </div>
                        }
                        {
                            // Campaign can only be created by org admin and should be of customer level or sub org level not system level
                            !isSubOrg && isRoleOrgAdmin && !isOrgLevelSys  &&
                            <div className={"members-btn"}>
                                <button
                                    disabled={getApiKeysApi?.data?.length === 0}
                                    data-tooltip-id="createCampaignBtn"
                                    data-tooltip-content={getApiKeysApi?.data?.length === 0 ? "Please connect Api Key to create campaign" : ""}
                                    style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                    className={`modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize  ${getApiKeysApi?.data?.length === 0 ? " opacity-7" : ""}`}
                                    onClick={() => {
                                        getApiKeysApi?.data?.length > 0 && navigate(isSubOrg ? `/campaign/create/${subOrgId}` : "/campaign/create");
                                    }}>
                                    Create Campaign
                                </button>
                                <ReactTooltip place={"top"} id="createCampaignBtn"/>

                            </div>
                        }
                    </div>


                </div>

                <div className={"colored_table pe-1 p-0"}>
                    <DataTable
                        className={campaignApi?.data?.totalPages > 1 ? " table_design2 " : " table_design1 "}
                        persistTableHead={true} responsive={true}
                        customStyles={tableCustomStyling}
                        progressPending={campaignApi.loading}
                        columns={columns}
                        data={campaignApi?.data?.data}
                        noDataComponent={<div className={"no-member-text"}>No Campaign Found</div>}
                        progressComponent={<TableSkeletonLoading columns={6} rows={6}/>}
                        onSort={handleSort}
                        sortServer={true}

                    />
                    {
                        !campaignApi?.loading && campaignApi?.data && campaignApi?.data?.totalPages > 1 &&
                        <Pagination totalPages={campaignApi?.data?.totalPages}
                                    pageNumber={pageNumber}
                                    setPageNumber={setPageNumberAndTriggerApi}
                        />
                    }
                </div>

            </div>
            {
                modals.showSearchFilterModal &&
                <SearchFilterModal title={"Campaign Filters"}
                                   show={modals.showSearchFilterModal}
                                   filtersTag={searchFiltersTags}
                                   applyFilter={triggerApi}
                                   setApplyFilter={setTriggerApi}
                                   resetFilters={() => {
                                       setSearchQuery({
                                           name: "",
                                           org: isOrgLevelSys && (isRoleOrgAdmin || isRoleManager) && !isSubOrg ? null : {
                                               label: "Organization...", value: isSubOrg ? subOrgId : token?.orgId
                                           },
                                           type: null,
                                           status: null,
                                           recurrence: null,
                                           createdDateRange: null
                                       });
                                   }}
                                   handleClose={() => {
                                       setModals({
                                           ...modals,
                                           showSearchFilterModal: false
                                       })
                                   }}
                                   setShow={(value) => {
                                       setModals({
                                           ...modals,
                                           showSearchFilterModal: value
                                       })
                                   }}

                />
            }

            {
                modals.showViewCampaignModal &&
                <ViewCampaignModal
                    campaignData={campaignToView}
                    show={modals.showViewCampaignModal}
                    setShow={(value) => {
                        setModals({
                            ...modals,
                            showViewCampaignModal: value
                        })
                    }}
                />
            }

            {
                modals.showDeleteCampaignConfirmationModal &&
                <ConfirmationModel title={"Delete Campaign"}
                                   btnText={"Delete"}
                                   action={handleDeleteCampaign}
                                   show={modals.showDeleteCampaignConfirmationModal}
                                   bodyMessage={"Are you sure you want to delete this campaign? This action cannot be undone, and all associated data will be permanently removed. Are you sure you wish to proceed?"}
                                   setShow={(value) => {
                                       setModals({
                                           ...modals,
                                           showDeleteCampaignConfirmationModal: value
                                       })
                                   }}
                                   isLoading={deleteCampaignApi?.loading}
                />
            }
        </section>
    );
}
export default Campaigns