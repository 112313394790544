import './Roles.css';
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAllRoles} from "../../reduxStore/rolesSlices/rolesSlices";
import {AccessDenied, AccessDeniedMsg} from "../../helpers/constants";
import {hasPermission, Permissions, roleHasAccess, Roles as SysRole} from "../../helpers/accessControl";
import {useNavigate} from "react-router-dom";
import ErrorPage from "../errorPage/ErrorPage";
import {isUnauthorized, tableCustomStyling, TableSkeletonLoading, toString} from "../../helpers/utils";
import DataTable from "react-data-table-component";
import UserInfo from "../layout/UserInfo";

const Roles = () => {
    const roles = useSelector(state => state.roles)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (roleHasAccess([SysRole.SYS_ADMIN, SysRole.ORG_ADMIN, SysRole.SUB_ORG_ADMIN]) && hasPermission([Permissions.ROLE_READ], true)) {
            dispatch(getAllRoles()).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }
    }, [])
    const columns = [
        {
            name: "Name",
            selector: row => toString(row.name),
            sortable: true,
            cell: (row) =>
                (
                    <>
                        {
                            roleHasAccess([SysRole.SYS_ADMIN, SysRole.ORG_ADMIN, SysRole.SUB_ORG_ADMIN]) && hasPermission([Permissions.ROLE_READ, Permissions.ROLE_WRITE, Permissions.PERM_READ], true) ?
                                <div className={"display_profile_data cursor"}
                                     onClick={() => {
                                         navigate(`/roles/${row.roleId}`)
                                     }}>
                                    {toString(row.name)}
                                </div> :
                                <div className={"display_profile_data"}>
                                    {toString(row.name)}
                                </div>
                        }
                    </>

                ),
            style: {
                fontFamily: 'General Sans Medium',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            },
            width: "200px"
        },
        {
            name: "Permissions",
            cell: row => <div>
                {permissionsString(row.permissionData)}
            </div>,
            style: {
                opacity: ".7",
                fontFamily: 'General Sans Regular',
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            },
        },
    ]
    const permissionsString = (permissionData) => {
        return permissionData.map((permission) => {
            return toString(permission.name);
        }).join(", ");

    }

    return !(roleHasAccess([SysRole.SYS_ADMIN, SysRole.ORG_ADMIN, SysRole.SUB_ORG_ADMIN]) && hasPermission([Permissions.ROLE_READ], true)) ?

        <ErrorPage value={403} description={AccessDenied} detailDesc={AccessDeniedMsg}/> : (

            <>
                <section>
                    <div className="cmn_header">
                        <h2 className="mediumFontSize  org-heading m-0">Roles</h2> <UserInfo/>

                    </div>
                    <div className="row main_content box_design1">
                        <div className=" m-auto px-1">
                            <DataTable className={"mt-3 table_design1"} persistTableHead={true} responsive={true}
                                       customStyles={tableCustomStyling}
                                       progressPending={roles?.getAllRolesDataReducer?.loading}
                                       columns={columns}
                                       data={roles?.getAllRolesDataReducer?.data}
                                       noDataComponent={<div className={"no-member-text"}>No Role Found</div>}
                                       progressComponent={<div className={"roles-table-loading w-100"}>
                                           <TableSkeletonLoading columns={2} rows={5}/></div>}
                            >
                            </DataTable>
                        </div>

                    </div>


                </section>


            </>
        );
}
export default Roles;