import Modal from "react-bootstrap/Modal";
import {IoIosClose} from "react-icons/io";
import {roleHasAccess, Roles} from "../../../helpers/accessControl";
import jwtDecode from "jwt-decode";
import {
    createOptionListForSelectTag, createOptionListForSelectTagWithRole,
    getDataFromLocalStorage, getValueOrDefault,
    isNullOrEmpty, isUnauthorized,
} from "../../../helpers/utils";
import Skeleton from "react-loading-skeleton";
import Select from "react-select";
import {TailSpinLoader} from "../../layout/Loader";
import React, {useEffect, useState} from "react";
import {getAllUsersWithoutPagination} from "../../../reduxStore/usersSlices/userSlice";
import {useDispatch, useSelector} from "react-redux";
import {getAllCustomReportsData, updateCustomReport} from "../../../reduxStore/CustomReportSlices/customReport";
import {useNavigate} from "react-router-dom";
import {getAllTeamLeaders} from "../../../reduxStore/teamSlices/teamSlice";

const AssignCustomReport = ({
                                show,
                                setShow,
                                reportToUpdate,
                                isSubOrg, subOrgId,
                                isTeam, teamId
                            }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [assignReportTo, setAssignReportTo] = useState([])
    const getAllUsersWithoutPaginationData = useSelector(state => state.users.getAllUsersWithoutPaginationReducer)
    const updateCustomReportData = useSelector(state => state.customReport.updateCustomReportReducer)
    const getAllTeamLeadersData = useSelector(state => state.team.getAllTeamLeadersReducer)


    useEffect(() => {
        const rolesToGet = isTeam ? [Roles.SYS_ADMIN, Roles.ORG_ADMIN, Roles.SUB_ORG_ADMIN, Roles.MANAGER] : [Roles.SYS_ADMIN, Roles.ORG_ADMIN, Roles.SUB_ORG_ADMIN, Roles.MANAGER, Roles.MENTOR, Roles.REFLECTION_WRITER, Roles.REPORT_WRITER]
        dispatch(getAllUsersWithoutPagination({
            roles: rolesToGet,
            orgId: isSubOrg ? subOrgId : getDataFromLocalStorage("loggedInUserInfo")?.orgId
        }))
    }, [reportToUpdate])

    useEffect(() => {
        if (isTeam) {
            dispatch(getAllTeamLeaders(teamId)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }
    }, [])
    useEffect(() => {
        if (getAllUsersWithoutPaginationData?.data !== undefined && getAllUsersWithoutPaginationData?.data !== null && (!isTeam || (getAllTeamLeadersData?.data !== undefined && getAllTeamLeadersData?.data !== null))) {
            const allUsers=getAllUsersWithoutPaginationData?.data?.concat(getValueOrDefault(getAllTeamLeadersData?.data,[]))
            const selectedOptions = allUsers?.filter(member => {
                return reportToUpdate?.data?.assignedTo.includes(member.uid)
            })
            setAssignReportTo(selectedOptions?.map(selectedMember => {
                return {
                    value: selectedMember?.uid,
                    label: selectedMember?.firstName + " " + (" (" + selectedMember?.roleObjects?.[0].name.toLowerCase() + ")")
                }
            }))
        }

    }, [getAllUsersWithoutPaginationData, getAllTeamLeadersData])

    const handleClose = () => setShow(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateCustomReport({
            ...reportToUpdate.data,
            assignedTo: assignReportTo.map(assignedTo => assignedTo?.value)
        })).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
            if (res.meta.requestStatus === "fulfilled") {
                dispatch(getAllCustomReportsData({orgId: subOrgId,teamId:teamId})).then(res => {
                    if (isUnauthorized(res)) {
                        navigate("/")
                    }
                })
                setShow(false)
            }
        })
    }


    return (
        <>
            <Modal show={show} centered>
                <Modal.Header className={"d-flex"}>
                    <Modal.Title className='add_member_heading mediumFontSize'> Assign Report</Modal.Title>
                    <IoIosClose className={"close_btn"} onClick={handleClose} size={24}/>
                </Modal.Header>

                <form onSubmit={handleSubmit}>
                    <Modal.Body className="modal_outer">


                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="userInfo_label mediumFontSize"> Assign
                                To</label>
                            {
                                getAllUsersWithoutPaginationData?.loading ?
                                    <div className={"roles-loading"}>
                                        <Skeleton height={40}/>
                                    </div> :

                                    <>

                                        <Select
                                            className="members-filter-role react-select-container mt-2"
                                            classNamePrefix="react-select"
                                            isDisabled={getAllUsersWithoutPaginationData?.loading || getAllTeamLeadersData?.loading}
                                            isMulti
                                            value={assignReportTo}
                                            options={createOptionListForSelectTagWithRole(getAllUsersWithoutPaginationData?.data?.concat(getValueOrDefault(getAllTeamLeadersData?.data,[])), "firstName", "uid", true, "uid", [getDataFromLocalStorage("loggedInUserInfo").userId])}
                                            onChange={(selectData) => {
                                                setAssignReportTo(selectData)
                                            }}
                                            placeholder={"Assign..."}/>

                                    </>

                            }
                        </div>

                    </Modal.Body>
                    <Modal.Footer className="modal_footer">

                        <button className="modal_cancel_btn cmn_modal_btn mediumFontSize" onClick={handleClose}>Cancel
                        </button>

                        <button
                            style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                            type="submit" disabled={false}
                            className={"ms-2 modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize " + (updateCustomReportData?.loading ? " disable" : "")}
                        > Assign
                            {updateCustomReportData?.loading && <TailSpinLoader/>}
                        </button>


                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}
export default AssignCustomReport