import arrow from '../../images/Icon.png'
import leftArrow from '../../images/arrow-left.png'
import rightArrow from '../../images/arrow-right.png'
import {useState} from "react"

import './Result.css'
import {NavLink} from "react-router-dom";
import SelectMemberModal from './modal/SelectMemberModal'


const Result = ({showHeader}) => {

    const [showModal1, setShowModal2] = useState(false);

    return (
        <>
            <section>

                <div className={showHeader !== undefined ? "" : "row  box_design1  main_content"}>
                    <div className="col-md-12">
                        <div className="upper_result">
                            {showHeader === undefined ? <h5 className='mediumFontSize'>Results</h5> :
                                <h6 className='mediumFontSize'>Results</h6>}


                            {/* <div className="result_header_data">
                            <h6 className="mediumFontSize">Your Reports</h6>
                            <p>All completed reports</p>

                        </div>  */}
                            {/* <div className="div_search">
                            <input type="text" placeholder="Search" />
                            <p><img src={searchIcon} alt="" width={"20"} /></p>

                        </div> */}

                            {showHeader === undefined ?
                                <button className="cmn_background_color" onClick={() => setShowModal2(true)}><span>Add New Reflection</span>
                                </button> :
                                <button className="smallbtn cmn_background_color">
                                    <span className='mediumFontSize'>Add new</span></button>}
                        </div>


                        {/* <Surveys showHeader={true}/> */}

                        <div className="result_data mt-3 table-responsive">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th className="refelections_data show_header_data regularFontSize "
                                        scope="col"> Refelections
                                        <img src={arrow} alt="nothing"/>
                                    </th>
                                    <th className="display_date show_header_data regularFontSize" scope="col">Date

                                        <img src={arrow} alt="nothing"/>

                                    </th>
                                    {showHeader !== undefined &&

                                        <th className="display_date show_header_data" scope="col">Risk

                                            <img src={arrow} alt="nothing"/>

                                        </th>

                                    }

                                    {showHeader === undefined &&
                                        <>
                                            <th className="high_risk" scope="col">
                                                <button type="button" className="table_buttons">
                                                    <span className='regularFontSize'>High risk </span>
                                                </button>
                                            </th>
                                            <th className="low_risk" scope="col">

                                                <button type="button" className="table_buttons">
                                                    <span className='regularFontSize'> Low risk</span>
                                                </button>


                                            </th>
                                            <th className="good" scope="col">
                                                <button type="button" className="table_buttons">
                                                    <span className='regularFontSize'> Good</span>
                                                </button>


                                            </th>
                                        </>


                                    }
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th scope="row" className="show_header_data_first_td custom_width">
                                        <NavLink to="/details">
                                            Spiritual Pathways
                                        </NavLink>

                                    </th>

                                    <td className="show_header_data_other_td"><span>12.9.2023</span></td>
                                    {showHeader !== undefined &&
                                        <td className="show_header_data_other_td high_risk">
                                            <button type="button" className="btn btn-primary table_buttons">
                                                <span className='mediumFontSize'> Low risk</span>
                                            </button>
                                        </td>}
                                    {showHeader === undefined &&
                                        <>
                                            <td className="button_data show_header_data_other_td">2</td>
                                            <td className="button_data show_header_data_other_td">4</td>
                                            <td className="button_data show_header_data_other_td">2</td>
                                        </>

                                    }
                                </tr>
                                <tr>
                                    <th scope="row" className="show_header_data_first_td">
                                        <NavLink to="/details">
                                            Spiritual Pathways
                                        </NavLink>

                                    </th>

                                    <td className="show_header_data_other_td"><span>12.9.2023</span></td>
                                    {showHeader != undefined &&
                                        <td className="show_header_data_other_td low_risk">
                                            <button type="button" className="btn btn-primary table_buttons">
                                                <span className='mediumFontSize'> High risk</span>
                                            </button>
                                        </td>
                                    }
                                    {showHeader === undefined &&
                                        <>
                                            <td className="button_data show_header_data_other_td">2</td>
                                            <td className="button_data show_header_data_other_td">4</td>
                                            <td className="button_data show_header_data_other_td">2</td>
                                        </>

                                    }
                                </tr>
                                <tr>
                                    <th scope="row" className="show_header_data_first_td">
                                        <NavLink to="/details">
                                            Spiritual Pathways
                                        </NavLink>

                                    </th>

                                    <td className="show_header_data_other_td"><span>12.9.2023</span></td>
                                    {showHeader !== undefined &&

                                        <td className="show_header_data_other_td good">
                                            <button type="button" className="btn btn-primary table_buttons">
                                                <span className='mediumFontSize'> Good</span>
                                            </button>
                                        </td>}
                                    {showHeader === undefined &&
                                        <>
                                            <td className="button_data show_header_data_other_td">2</td>
                                            <td className="button_data show_header_data_other_td">4</td>
                                            <td className="button_data show_header_data_other_td">2</td>
                                        </>

                                    }
                                </tr>
                                <tr>
                                    <th scope="row" className="show_header_data_first_td">
                                        <NavLink to="/details">
                                            Spiritual Pathways
                                        </NavLink>

                                    </th>

                                    <td className="show_header_data_other_td"><span>12.9.2023</span></td>
                                    {showHeader != undefined &&
                                        <td className="show_header_data_other_td good">
                                            <button type="button" className="btn btn-primary table_buttons">
                                                <span className='mediumFontSize'> Good</span>
                                            </button>
                                        </td>}
                                    {showHeader == undefined &&
                                        <>
                                            <td className="button_data show_header_data_other_td">2</td>
                                            <td className="button_data show_header_data_other_td">4</td>
                                            <td className="button_data show_header_data_other_td">2</td>
                                        </>

                                    }
                                </tr>
                                <tr>
                                    <th scope="row" className="show_header_data_first_td">
                                        <NavLink to="/details">
                                            Spiritual Pathways
                                        </NavLink>

                                    </th>

                                    <td className="show_header_data_other_td"><span>12.9.2023</span></td>
                                    {showHeader != undefined &&
                                        <td className="show_header_data_other_td good">
                                            <button type="button" className="btn btn-primary table_buttons">
                                                <span className='mediumFontSize'> Good</span>
                                            </button>
                                        </td>}
                                    {showHeader == undefined &&
                                        <>
                                            <td className="button_data show_header_data_other_td">2</td>
                                            <td className="button_data show_header_data_other_td">4</td>
                                            <td className="button_data show_header_data_other_td">2</td>
                                        </>

                                    }
                                </tr>
                                <tr>
                                    <th scope="row" className="show_header_data_first_td">
                                        <NavLink to="/details">
                                            Spiritual Pathways
                                        </NavLink>

                                    </th>

                                    <td className="show_header_data_other_td"><span>12.9.2023</span></td>
                                    {showHeader != undefined &&
                                        <td className="show_header_data_other_td good">
                                            <button type="button" className="btn btn-primary table_buttons">
                                                <span className='mediumFontSize'> Good</span>
                                            </button>
                                        </td>}
                                    {showHeader == undefined &&
                                        <>
                                            <td className="button_data show_header_data_other_td">2</td>
                                            <td className="button_data show_header_data_other_td">4</td>
                                            <td className="button_data show_header_data_other_td">2</td>
                                        </>

                                    }
                                </tr>
                                <tr>
                                    <th scope="row" className="show_header_data_first_td">
                                        <NavLink to="/details">
                                            Spiritual Pathways
                                        </NavLink>

                                    </th>

                                    <td className="show_header_data_other_td"><span>12.9.2023</span></td>
                                    {showHeader != undefined &&
                                        <td className="show_header_data_other_td good">
                                            <button type="button" className="btn btn-primary table_buttons">
                                                <span className='mediumFontSize'> Good</span>
                                            </button>
                                        </td>}
                                    {showHeader == undefined &&
                                        <>
                                            <td className="button_data show_header_data_other_td">2</td>
                                            <td className="button_data show_header_data_other_td">4</td>
                                            <td className="button_data show_header_data_other_td">2</td>
                                        </>

                                    }
                                </tr>
                                <tr>
                                    <th scope="row" className="show_header_data_first_td">
                                        <NavLink to="/details">
                                            Spiritual Pathways
                                        </NavLink>

                                    </th>

                                    <td className="show_header_data_other_td"><span>12.9.2023</span></td>
                                    {showHeader != undefined &&
                                        <td className="show_header_data_other_td good">
                                            <button type="button" className="btn btn-primary table_buttons">
                                                <span className='mediumFontSize'> Good</span>
                                            </button>
                                        </td>}
                                    {showHeader == undefined &&
                                        <>
                                            <td className="button_data show_header_data_other_td">2</td>
                                            <td className="button_data show_header_data_other_td">4</td>
                                            <td className="button_data show_header_data_other_td">2</td>
                                        </>

                                    }
                                </tr>


                                </tbody>
                            </table>
                            {showHeader != undefined &&
                                <div className="pagination_row">
                                    <div className="arrow_img_prev">
                                        <img src={leftArrow} alt="nothing" width={"20"}/>
                                        <span className='mediumFontSize'>Prev</span>

                                    </div>
                                    <div className="buttons">

                                        <ul>
                                            <li>1</li>
                                            <li className='active'>2</li>
                                            <li>..</li>
                                            <li>5</li>
                                            <li>6</li>

                                        </ul>

                                    </div>
                                    <div className="arrow_img_next">
                                        <span className='mediumFontSize'>Next</span>
                                        <img src={rightArrow} alt="nothing" width={"20"}/>


                                    </div>

                                </div>
                            }
                        </div>

                    </div>


                </div>


            </section>

            {showModal1 && <SelectMemberModal show={showModal1} setShow={setShowModal2}/>}


        </>
    )

}

export default Result;