import { Form } from 'react-bootstrap'
import Dropdown from "react-bootstrap/Dropdown"
import DropdownButton from 'react-bootstrap/DropdownButton';
import './NewSurvey.css'
import cross_icon from '../../../images/croosIcon.png'
import sort_icon from '../../../images/sort.png'
import plus_Icon from '../../../images/whitePlusIcon.png'


const CreateSurvey = () => {
    return (
        <>

            <div className="main_content">
                <div className="new_survey_container">
                    <div className="new_survey_outer">
                        <div className="question_container">
                            <h2 className="mediumFontSize">Create New Survey</h2>
                            <p className="regularFontSize">List all Questions</p>
                        </div>
                        <div>

                            <button className="survey_cancel_btn mediumFontSize cmn_create_survey_btn">Cancel</button>
                            <button className="survey_save_btn mediumFontSize cmn_create_survey_btn cmn_background_color">Share</button>
                        </div>
                    </div>
                    <div className="add_survey">
                        <div className="add_survey_header">
                            <h2 className="mediumFontSize">Add survey name</h2>

                            <Form className="list_container">

                                <ul className="input_list">
                                    <div>
                                        <li>
                                            <div className="inputtext_wrapper">

                                                <Form.Group>
                                                    <Form.Control type="text" placeholder="write your answer" className="form_text"></Form.Control>
                                                </Form.Group>
                                            </div>

                                            <DropdownButton id="dropdown-basic-button" title="Radio Button" className="mediumFontSize">
                                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                            </DropdownButton>
                                        </li>
                                        <li>
                                            <Form.Group className="input_radio">
                                                <input type="radio" className="radio_button"/>
                                                <Form.Control type="text" placeholder="write your answer here" className="user_response"></Form.Control>

                                            </Form.Group>

                                            <div className="crossAndSort_img">
                                                <div className="cross_icon">
                                                    <img src={cross_icon}  />

                                                </div>
                                                <div className="sort_icon">
                                                    <img src={sort_icon} />

                                                </div>
                                            </div>
                                        </li>
                                        <li>


                                            <Form.Group className="input_radio">
                                                <input type="radio" className="radio_button"/>
                                                <Form.Control type="text" placeholder="write your answer here" className="user_response"></Form.Control>

                                            </Form.Group>

                                            <div className="crossAndSort_img">
                                                <div className="cross_icon">
                                                    <img src={cross_icon}   />

                                                </div>
                                                <div className="sort_icon">
                                                    <img src={sort_icon} />

                                                </div>
                                            </div>
                                        </li>

                                        <button className="add_answer_btn cmn_background_color cmn_create_survey_btn">
                                            <div className="plus_img_container">
                                                <img src={plus_Icon}></img>
                                                <span>Add answer</span>
                                            </div>
                                        </button>

                                    </div>
                                    <div className="mt-3">
                                        <li>
                                            <div className="inputtext_wrapper">

                                                <Form.Group>
                                                    <Form.Control type="text" placeholder="write your answer" className="form_text"></Form.Control>
                                                </Form.Group>
                                            </div>

                                            <DropdownButton id="dropdown-basic-button" title="Check Box">
                                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                            </DropdownButton>
                                        </li>
                                        <li>


                                            <Form.Group className="input_radio">
                                                <input type="radio" className="radio_button"/>
                                                <Form.Control type="text" placeholder="write your answer here" className="user_response"></Form.Control>

                                            </Form.Group>

                                            <div className="crossAndSort_img">
                                                <div className="cross_icon">
                                                    <img src={cross_icon}   />

                                                </div>
                                                <div className="sort_icon">
                                                    <img src={sort_icon} />

                                                </div>
                                            </div>
                                        </li>
                                        <li>


                                            <Form.Group className="input_radio">
                                                <input type="radio" className="radio_button"/>
                                                <Form.Control type="text" placeholder="write your answer here" className="user_response"></Form.Control>

                                            </Form.Group>

                                            <div className="crossAndSort_img">
                                                <div className="cross_icon">
                                                    <img src={cross_icon}   />

                                                </div>
                                                <div className="sort_icon">
                                                    <img src={sort_icon} />

                                                </div>
                                            </div>
                                        </li>
                                        <button className="add_answer_btn cmn_create_survey_btn cmn_background_color">
                                            <div className="plus_img_container">
                                                <img src={plus_Icon}></img>
                                                <span>Add answer</span>
                                            </div>

                                        </button>
                                    </div>
                                    <div>
                                        <li>
                                            <div className="inputtext_wrapper">

                                                <Form.Group>
                                                    <Form.Control type="text" placeholder="write your answer" className="form_text"></Form.Control>
                                                </Form.Group>
                                            </div>

                                            <DropdownButton id="dropdown-basic-button" title="Input field">
                                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                            </DropdownButton>
                                        </li>
                                        <li>
                                            <Form.Control type="text" placeholder="Input Field" className="input_Field mediumFontSize"></Form.Control>
                                        </li>
                                        <button className="cmn_create_survey_btn add_question_btn cmn_background_color"><img src={plus_Icon}></img> Add Question</button>
                                    </div>

                                </ul>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CreateSurvey