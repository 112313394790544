import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    assignedReflectionsToSubOrg,
    getPublishSurveys,
    searchSurveys
} from "../../../reduxStore/surveySlices/surveySlices";
import {createOptionListForSelectTag, isUnauthorized} from "../../../helpers/utils";
import Select from "react-select";
import {TailSpinLoader} from "../../layout/Loader";
import {toast} from "react-toastify";
import {IoIosClose} from "react-icons/io";

const AssignedReflectionToSubOrg = ({show, setShow,subOrgId}) => {
    const handleClose = () => setShow(false);
    const [surveyIds,setSurveyIds]=useState(null)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const surveys = useSelector(state => state.survey)

    useEffect ( ()=>{
        dispatch(getPublishSurveys()).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
        })
    },[])



    const handleSelectChange = (selectedOptions) => {
        if (selectedOptions !== null && Array.isArray(selectedOptions)) {
            const data = selectedOptions.map(item => item.value);
            setSurveyIds(data)

        }
    }

    const handleAssignReflection =()=>{
        const data ={surveyIds:surveyIds, subOrgId:subOrgId}
        if(surveyIds === null || surveyIds.length === 0){
            toast.error("Reflections can't be empty or null !", {toastId: "toast"})

        }else{
            dispatch(assignedReflectionsToSubOrg(data)).then(res=>{
                if(isUnauthorized(res)){
                    navigate("/")
                }
                if (res.meta.requestStatus === "fulfilled") {
                    setShow(false)
                    dispatch(searchSurveys({subOrgId:subOrgId})).then(res => {
                        if (isUnauthorized(res)) {
                            navigate("/")
                        }
                    });
                }
            })
        }
    }

    return (
        <>
            <Modal className={"modal-lg"} show={show} centered>
                <Modal.Header className={"d-flex"}>
                    <Modal.Title className='mediumFontSize'>Assign Reflection</Modal.Title>
                    <IoIosClose className={"close_btn"} onClick={handleClose} size={24}/>
                </Modal.Header>

                <Modal.Body className="modal_outer">

                    <div className={"assigned-surveys"}>
                        <form className={""}>
                            <label>Select reflections</label>
                            <Select
                                className="members-filter-role react-select-container"
                                classNamePrefix="react-select"
                                isDisabled={surveys.getPublishSurveysReducer?.loading}
                                isMulti
                                options={createOptionListForSelectTag(surveys.getPublishSurveysReducer?.data, "name", "surveyId")}
                                onChange={handleSelectChange}
                                placeholder={"Select Reflections ..."}/>

                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal_footer">
                    <button className="modal_cancel_btn cmn_modal_btn mediumFontSize" onClick={handleClose}>Cancel
                    </button>

                    <button onClick={handleAssignReflection} style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""} } type="submit" disabled={surveys.assignedReflectionsToSubOrgReducer?.loading === true} className= {surveys.assignedReflectionsToSubOrgReducer?.loading === true ?"disable-btn ms-2 modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize " : "ms-2 modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize "}> Assign Reflections

                        {surveys.assignedReflectionsToSubOrgReducer?.loading && (<TailSpinLoader/>)}

                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default AssignedReflectionToSubOrg;