import PulseIcon from "../../images/PulseIcon.svg";
import "../plans/Plans.css"
import {AiOutlineCheckCircle} from "react-icons/ai";
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    createStripeSession,
    stripeFetchAllPrices,
    stripeFetchAllProducts, userSubscriptionData,stripeResetAllReducer
} from "../../reduxStore/StripeSlices/stripeSlices";
import Skeleton from "react-loading-skeleton";
import {PulsePlans, SubscriptionPlans} from "../../helpers/constants";
import {Navigate, useNavigate} from "react-router-dom";
import {
    encryptData,
    getDataFromLocalStorage,
    getInitials,
    isUnauthorized,
    validatePlanVisibility
} from "../../helpers/utils";
import jwtDecode from "jwt-decode";
import ProfilePicPlaceHolder from "../commons/ProfilePicPlaceHolder";
import leftArrow from "../../images/arrow-left.png";
import {toast} from "react-toastify";
import {TailSpinLoader} from "../layout/Loader";


const Plans = ({upgradePlan=false,userCurrentPlan=null}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [selectedPlan, setSelectedPlan] = useState("month")
    const pricingData = useSelector(state => state.stripe.getAllPricesDataReducer)
    const plansData = useSelector(state => state.stripe.getAllProductDataReducer)
    const [individualPlanData, setIndividualPlanData] = useState({
        priceId: "FREE_PLAN",
        amount: "Free Forever",
        planName: "Individual",
        interval: "",
        trialDays: 0
    })


    useEffect(() => {
        dispatch(stripeFetchAllPrices()).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
        })
        dispatch(stripeFetchAllProducts()).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
        })

        return ()=>{
            dispatch(stripeResetAllReducer(true));
        }

    }, []);

    const plansCode = useMemo(() => {
        if (pricingData?.data?.length > 0 && plansData?.data?.length > 0) {
            const planDataLookup = plansData?.data.reduce((lookup, plan) => {
                lookup[plan.id] = plan.name;
                return lookup;
            }, {});

            return pricingData.data
                .filter(cur => cur.recurring !== null)
                .map(cur => ({
                    priceId: cur.id,
                    amount: cur.unit_amount / 100,
                    planName: planDataLookup[cur.product],
                    interval: cur.recurring.interval,
                    trialDays: cur.recurring.trial_period_days
                }))
                .sort((a, b) => {
                    if (a.interval === b.interval) {
                        return a.amount - b.amount;
                    }
                    return a.interval.localeCompare(b.interval);
                });
        }
        return [];
    }, [pricingData, plansData]);
    let token = localStorage.getItem("token")

    return (token === null || token === undefined) ? <Navigate
            to={"/"}></Navigate> :
        (jwtDecode(getDataFromLocalStorage("token"))?.subscriptionStatus === SubscriptionPlans.SUBSCRIBED || jwtDecode(getDataFromLocalStorage("token"))?.subscriptionStatus === SubscriptionPlans.TRIAL || jwtDecode(getDataFromLocalStorage("token"))?.subscriptionStatus === SubscriptionPlans.CANCELED) && !upgradePlan  ?
            <Navigate to={"/admin"}></Navigate> :
            (
                <section>
                    {
                        !upgradePlan && <div className={"pulse-logo"}>
                            <img src={PulseIcon} alt="nothing"/>
                            <div className={"profile-log-out-btn"}>
                                <div>
                                    <img className={"log-out-arrow"} src={leftArrow} alt=""/>
                                    <span className={"cursor-pointer me-4 log-out-btn mediumFontSize "} onClick={() => {
                                        localStorage.clear();
                                        toast.dismiss();
                                        navigate("/")
                                    }}>Logout
                                </span>
                                </div>
                                <ProfilePicPlaceHolder
                                    letters={getInitials(getDataFromLocalStorage("loggedInUserInfo").firstName + " " + getDataFromLocalStorage("loggedInUserInfo").lastName)}/>


                            </div>
                        </div>
                    }
                    <div className={"plans-section "+(upgradePlan?"plans-section-upgrade":"")}>
                        <div className={"plans-body"}>
                            <h1 className={"choose-plan-heading"+(upgradePlan?" choose-plan-heading-upgrade":"")}>Choose your plan</h1>
                            <p className={"tag-line"+(upgradePlan?" tag-line-upgrade":"")}>A plan for everyone. We support your growth. Contact us at <span
                                className={"pulse-email"}>support@pulseconnect.us</span> for
                                discounted
                                rates when you scale to more users.</p>
                            {
                                (pricingData?.loading || plansData?.loading) && plansCode.length === 0 ?
                                    <div className={""}>
                                        <Skeleton className={"plan-buttons-loader"}/>
                                    </div> :

                                    <div className={"plan-buttons"+(upgradePlan?" plan-buttons-upgrade":"")}>
                                        <div onClick={() => {
                                            setSelectedPlan("month")
                                        }}
                                             className={"monthly-plan-button " + (selectedPlan === "month" ? "selected-plan " : "")}>Monthly
                                            plan
                                        </div>
                                        <div onClick={() => {
                                            setSelectedPlan("year")
                                        }}
                                             className={"annual-plan-button " + (selectedPlan === "year" ? "selected-plan " : "")}>Annual
                                            plan
                                        </div>
                                    </div>
                            }
                            <div className={"plans-outer "+(userCurrentPlan===PulsePlans.PULSE_PRO.value ? "content-mid":"")}>
                                {
                                    validatePlanVisibility(userCurrentPlan,PulsePlans.INDIVIDUAL.value) && <PlansComponent
                                        isPlanUpgrade={upgradePlan}
                                        isLoading={(pricingData?.loading || plansData?.loading) && plansCode?.length === 0}
                                    data={individualPlanData}/>
                                }
                                {validatePlanVisibility(userCurrentPlan,PulsePlans.PULSE_PRO.value) && <PlansComponent
                                    isPlanUpgrade={upgradePlan}
                                    isLoading={(pricingData?.loading || plansData?.loading) && plansCode?.length === 0}
                                    data={(plansCode?.filter((plan) => {
                                        return plan.interval === selectedPlan && plan.planName === PulsePlans.PULSE_PRO.value
                                    }))[0]}/>
                                }
                                {validatePlanVisibility(userCurrentPlan,PulsePlans.PULSE_PLUS.value) &&
                                <PlansComponent
                                    isPlanUpgrade={upgradePlan}
                                    isLoading={(pricingData?.loading || plansData?.loading) && plansCode?.length === 0}
                                    data={(plansCode?.filter((plan) => {
                                        return plan.interval === selectedPlan && plan.planName === PulsePlans.PULSE_PLUS.value
                                    }))[0]}/>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            );
}

export const PlansComponent = ({isLoading, data = null , isPlanUpgrade=false}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userSubscriptionResult = useSelector(state => state.stripe.userSubscriptionResultReducer)
    const createStripeSessionData = useSelector(state => state.stripe.createStripeSessionDataReducer)
    
    const redirectUserToCheckOut = (priceId, trialDays = null) => {
        if (priceId === "FREE_PLAN") {
            navigate("/subscription-success?plan_type="+(isPlanUpgrade?"UPGRADE":"NEW")+"&session_id=FREE_FOREVER")
        } else {
           const successURL = `${process.env.REACT_APP_SUCCESS_URL}`.replace('{TYPE}',isPlanUpgrade ? "UPGRADE" : "NEW" )
            dispatch(createStripeSession({
                    payment_method_types: ['card'],
                    line_items: [
                        {
                            price: priceId,
                            quantity: 1
                        }
                    ],
                    mode: "subscription",
                    ...(trialDays !== null && {
                        subscription_data: {
                            trial_period_days: trialDays,
                        },
                    }),
                    success_url:successURL ,
                }
            ))
        }

    }
    // useEffect(() => {
    //     if (createStripeSessionData.data !== undefined) {
    //         window.location.href = createStripeSessionData.data.url
    //     }
    // }, [createStripeSessionData])
    return (
        isLoading || data === null ?
            <>
                <div className={"plans individual-plan"}>
                    <div className={"plan-content"}>
                        <h2 className={"plan-heading"}><Skeleton width={100}/>
                            <div className={"price"}><Skeleton width={30}/></div>
                        </h2>
                        <div className={"vertical-divider"}></div>
                        <h5 className={"plan-spec-heading"}><Skeleton width={120} height={20}/></h5>
                        <div className={"plan-spec-list"}>
                            <div className={"plan-spec"}>
                                <Skeleton width={190}/>
                            </div>
                            <div className={"plan-spec"}><Skeleton width={190}/>
                            </div>

                        </div>
                        <div className={"get-started-btn-outer"}>
                            <Skeleton width={150} height={"40px"}/>
                        </div>

                    </div>
                </div>

            </>
            :
            <div className={"plans individual-plan mb-4"+(isPlanUpgrade?" plans individual-plan-upgrade":"")}>
                <div className={"plan-content"}>
                    <h2 className={"plan-heading"}>{data.planName}
                        {
                            data.planName === PulsePlans.INDIVIDUAL.value ? <div className={"price"}>{data.amount}</div> :
                                <div className={"price"}>${data?.amount?.toFixed(2)}</div>
                        }
                    </h2>
                    <div className={"vertical-divider"}></div>
                    <h5 className={"plan-spec-heading"}>WHAT'S INCLUDED:</h5>
                    <div className={"plan-spec-list"}>
                        {
                            data.planName === PulsePlans.INDIVIDUAL.value &&
                            <>
                                <div className={"plan-spec"}>
                                    <AiOutlineCheckCircle className={"check-circle"}/>
                                    <div className={"specification"}>Access to templated reflections</div>
                                </div>
                                <div className={"plan-spec"}>
                                    <AiOutlineCheckCircle className={"check-circle"}/>
                                    <div className={"specification"}>Access to public resource library</div>
                                </div>
                            </>
                        }
                        {
                            data.planName === PulsePlans.PULSE_PRO.value &&
                            <>
                                <div className={"plan-spec"}>
                                    <AiOutlineCheckCircle className={"check-circle"}/>
                                    <div className={"specification"}>Everything in Individual Plan</div>
                                </div>
                                <div className={"plan-spec"}>
                                    <AiOutlineCheckCircle className={"check-circle"}/>
                                    <div className={"specification"}>Build custom reflections</div>
                                </div>
                            </>
                        }
                        {
                            data.planName === PulsePlans.PULSE_PLUS.value &&
                            <>
                                <div className={"plan-spec"}>
                                    <AiOutlineCheckCircle className={"check-circle"}/>
                                    <div className={"specification"}>Everything in Pro Plan</div>
                                </div>
                                <div className={"plan-spec"}>
                                    <AiOutlineCheckCircle className={"check-circle"}/>
                                    <div className={"specification"}>Add content to resource library</div>
                                </div>
                            </>
                        }
                    </div>
                    <div className={"get-started-btn-outer"}>
                        {
                            data.planName === PulsePlans.INDIVIDUAL.value ?
                                <button
                                    disabled={userSubscriptionResult?.loading}
                                    className={"get-started-btn"} onClick={() => {
                                    redirectUserToCheckOut(data.priceId)
                                }}> Start free forever {
                                    userSubscriptionResult?.loading && <TailSpinLoader></TailSpinLoader>
                                }
                                </button>
                                : <>
                                    {
                                        data.trialDays === null || jwtDecode(getDataFromLocalStorage("token"))?.subscriptionStatus !== SubscriptionPlans.UNSUBSCRIBED ?
                                            <button
                                                disabled={createStripeSessionData?.loading}
                                                className={"get-started-btn"} onClick={() => {
                                                redirectUserToCheckOut(data.priceId)
                                            }}> Get started
                                            </button> : <button
                                                disabled={createStripeSessionData?.loading}
                                                className={"get-started-btn"} onClick={() => {
                                                redirectUserToCheckOut(data.priceId, data.trialDays)
                                            }}> Get {data.trialDays + (data.trialDays === 1 ? " day" : " days")} trial

                                            </button>
                                    }
                                </>
                        }

                    </div>

                </div>
            </div>
    );
}
export default Plans;