import "./MemberDetails.css"
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {

    getUserById,
    sentInviteToUpdateRoleToPulseManager,
    revokePulseManagerRoleByOrgAdmin
} from "../../reduxStore/usersSlices/userSlice";
import {Loader, TailSpinLoader} from "../layout/Loader";
import {
    createOptionListForSelectTag,
    formatDate, getColor, getComputeData, getCurrentAndPreviousMonths,
    getDataFromLocalStorage, getFirstFiveElements, getInitials,
    isNullOrEmpty,
    isUnauthorized, showToast,
    tableCustomStyling,
    TableSkeletonLoading, trim,
} from "../../helpers/utils";
import {
    AccessDenied,
    AccessDeniedMsg,
    EnterThought,
    hexToRgb,
    Marks,
    Survey,
    SurveyAttemptStatus
} from "../../helpers/constants";
import ErrorPage from "../errorPage/ErrorPage";
import {hasPermission, Permissions, roleHasAccess, Roles} from "../../helpers/accessControl";
import SurveyAttempt from "./modal/SurveyAttempt";
import {
    getAllAttemptSurveyByUserId,
    getAllSurveysWithoutPagination,
    recommendedCoachById
} from "../../reduxStore/surveySlices/surveySlices";
import Pagination from "../commons/Pagination";
import {createNote, deleteNoteById, searchNotes} from "../../reduxStore/notesSlices/notesSlice";

import DataTable from "react-data-table-component";
import Skeleton from "react-loading-skeleton";
import ConfirmationModel from "../commons/ConfirmationModel";
import ProfilePicPlaceHolder from "../commons/ProfilePicPlaceHolder";

import {
    getMemberVsReflectionAttemptReport,
    getMemberVsReflectionYearlyAttemptReport
} from "../../reduxStore/reportsSlice/reportsSlice";
import {
    Chart as ChartJs,
    Tooltip,
    Title,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Filler
} from "chart.js";
import {Line} from "react-chartjs-2";
import AssignMember from "./modal/AssignMember";
import {HiPencil} from "react-icons/hi";
import UpdateUserModal from "./modal/UpdateUserModal";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {FiPhone} from "react-icons/fi";

import {MdOutlineEmail} from "react-icons/md";
import UserInfo from "../layout/UserInfo";
import {TiPlus} from "react-icons/ti";
import {FaPlus} from "react-icons/fa";


ChartJs.register(
    Tooltip, LineElement, Title, CategoryScale, LinearScale, PointElement, Filler
)


const MemberDetails = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {userId} = useParams();
    const [pageNumber, setPageNumber] = useState(1)
    const [showModal, setShowModal] = useState(false)
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [hasError, setHasError] = useState(null);
    const [userDetail, setUserDetail] = useState(null);
    const [edit, setEdit] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [noteDetails, setNoteDetails] = useState(null)
    const [noteId, setNoteId] = useState(null);
    const users = useSelector(state => state.users)
    const survey = useSelector(state => state.survey)
    const notes = useSelector(state => state.notes)
    const [sortBy, setSortBy] = useState("createdAt");
    const [sortOrder, setSortOrder] = useState("desc");
    const [surveyDropDownList, setSurveyDropDownList] = useState([]);
    const [headerLabels, setHeaderLabels] = useState([]);
    const [newFirstName, setNewFirstName] = useState('');
    const [newLastName, setNewLastName] = useState('');

    const [showPromoteConfirmationModal, setShowPromoteConfirmationModal] = useState(false)
    const [showDemoteConfirmationModal, setShowDemoteConfirmationModal] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    const surveysWithoutPaginationData = useSelector(state => state.survey.getAllSurveysWithoutPaginationDataReducer);
    const recommededCoachData = useSelector(state => state.survey.recommendedCoachByIdDataReducer);
    const responseData = useSelector(state => state.reports.getMemberVsReflectionYearlyAttemptReportReducer)

    const handleShowText = (index) => {
        const updatedSelectedItems = [...selectedItems];
        updatedSelectedItems[index] = !updatedSelectedItems[index];
        setSelectedItems(updatedSelectedItems);
    };

    useEffect(() => {

        if (responseData.data !== undefined && responseData.data.length > 0) {
            const groupedData = groupDataBySurveyName(responseData.data);
            setChartData({
                labels: getCurrentAndPreviousMonths(),
                datasets: Object.keys(groupedData)?.map(key => {

                    return {
                        label: key,
                        data: getComputeData(key, responseData.data),
                        borderColor: getColor(key, headerLabels, setHeaderLabels),
                    }

                }),

            })
        }

    }, [responseData])

    const groupDataBySurveyName = (data) => {
        return data.reduce((acc, item) => {
            const key = item.surveyName

            if (!acc[key]) {
                acc[key] = [];
            }

            acc[key].push(item);
            return acc;
        }, {});
    };
    const [chartData, setChartData] = useState({

        labels: getCurrentAndPreviousMonths(),
        datasets: [
            {
                label: "",
                data: [],
                borderColor: "",
            }
        ],
    })
    const [options, setOptions] = useState({

        scales: {
            y: {
                min: 0,
                max: 100,
                ticks: {
                    stepSize: 33,
                    callback: (value) => {
                        if (value <= 66 && value > 33) return 'Needs Support';
                        if (value > 66 && value <= 99) return 'Growing';
                        if (value > 99) return 'Maturing';
                        return '';
                    },
                },
            },
        },
        plugins: {
            tooltips: {
                callback: {
                    label: function (tooltipItem, data) {
                        const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        return `Value: ${value}`;
                    },
                },
            },
            legend: {
                display: false,
            },
        },
    })
    const handleSort = (column, sortDirection,) => {
        setSortBy(column.sortField)
        setSortOrder(sortDirection)
    };

    const handleDeleteNote = () => {
        if (noteId != null && noteId !== "") {
            dispatch(deleteNoteById({noteId, setNoteId, setShowConfirmationModal})).then((response) => {
                if (isUnauthorized(response)) {
                    navigate("/")
                }
                if (response.meta.requestStatus === "fulfilled") {
                    setNoteId("")
                    dispatch(searchNotes({
                        page: pageNumber,
                        memberId: userId
                    })).then((res) => {
                        if (isUnauthorized(res)) {
                            navigate("/")
                        }
                    });
                }
            });
        }
    }

    const columns = [
        {
            selector: row => row.surveyName, sortable: true, sortField: 'surveyName',
            cell: (row) =>
                (
                    <div title={row.surveyName}
                         className={"wrap-text  " + (row.enable ? "" : " color-grey ") + ((((row.surveyAttemptStatus === Survey.SURVEY_STATUS_DRAFT || row.surveyAttemptStatus === SurveyAttemptStatus.ASSIGNED) && hasPermission([Permissions.REFLECTION_READ, Permissions.ATTEMPT_REFLECTION_READ, Permissions.ATTEMPT_REFLECTION_CREATE], true)) || (row.surveyAttemptStatus === Survey.ATTEMPT_SURVEY_STATUS_COMPLETED && hasPermission([Permissions.REFLECTION_READ, Permissions.ATTEMPT_REFLECTION_READ], true))) ? "cursor" : "")}
                         onClick={() => {
                             if ((row.surveyAttemptStatus === Survey.SURVEY_STATUS_DRAFT || row.surveyAttemptStatus === SurveyAttemptStatus.ASSIGNED) && hasPermission([Permissions.REFLECTION_READ, Permissions.ATTEMPT_REFLECTION_READ, Permissions.ATTEMPT_REFLECTION_CREATE], true)) {
                                 navigate(`/members/answerReflection/${userId}/${row.surveyId}`)
                             } else if (row.surveyAttemptStatus === Survey.ATTEMPT_SURVEY_STATUS_COMPLETED && hasPermission([Permissions.REFLECTION_READ, Permissions.ATTEMPT_REFLECTION_READ], true)) {
                                 navigate(`/members/reflectionResult/${row.surveyId}/${row.id}`)
                             }
                         }}>
                        {trim(row.surveyName, 50)}
                    </div>
                ),
            style: {
                fontFamily: 'General Sans Medium',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            },


        },
        {

            selector: row => row.createdAt,
            sortField: 'createdAt',
            cell: row => <div>{(formatDate(row.createdAt))}</div>,
            sortable: true,
            style: {opacity: ".7",},
            width: survey?.getAllAttemptSurveyByUserIdDataReducer?.loading ? "" : "25%"
        },
        {
            selector: row => Marks[row.surveyAttemptResult.overallRiskLevel],
            cell: row => (
                (row.surveyAttemptStatus === Survey.SURVEY_STATUS_DRAFT || row.surveyAttemptStatus === SurveyAttemptStatus.ASSIGNED) ?
                    <span>{"---"}</span> : !row["surveyAttemptResult"] ? <span>{"---"}</span> :
                        <>

                            {row.surveyAttemptResult.overallRiskLevel === "HIGH_RISK" &&

                                <div className={"show_status"}>
                                        <span
                                            className={"survey-risk-status bg-light-red"}>{Marks[row.surveyAttemptResult.overallRiskLevel]}</span>
                                </div>
                            }
                            {row.surveyAttemptResult.overallRiskLevel === "LOW_RISK" &&

                                <div className={"show_status"}>
                                        <span
                                            className={"survey-risk-status bg-light-orange"}>{Marks[row.surveyAttemptResult.overallRiskLevel]}</span>
                                </div>

                            }
                            {row.surveyAttemptResult.overallRiskLevel === "GOOD" &&

                                <div className={"show_status"}>
                                        <span
                                            className={"survey-risk-status bg-light-green"}>{Marks[row.surveyAttemptResult.overallRiskLevel]}</span>
                                </div>

                            }
                        </>

            ),
            width: survey?.getAllAttemptSurveyByUserIdDataReducer?.loading ? "" : "30%"
        },
    ]

    useEffect(() => {
        setChartData({
            labels: getCurrentAndPreviousMonths(),
            datasets: [
                {
                    label: "",
                    data: [],
                    borderColor: "",
                }
            ],
        })
        setHeaderLabels([])

        if (userId !== null) {
            dispatch(getMemberVsReflectionYearlyAttemptReport(userId)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
            dispatch(recommendedCoachById(userId)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }

    }, [userId])


    const getUserDetails = () => {
        dispatch(getUserById(userId))
            .then((response) => {
                if (isUnauthorized(response)) {
                    navigate("/")
                }
                if (response.meta.requestStatus === "fulfilled") {
                    setHasError(false)
                    setUserDetail(response.payload.data)
                    setNewFirstName(response.payload.data?.firstName)
                    setNewLastName(response.payload.data?.lastName)

                } else if (response.meta.requestStatus === "rejected") {
                    if (response?.payload?.errorCode?.value === 400) {
                        showToast(response.payload.detailDesc,"error")
                    }
                    setHasError(true)
                }
            })
    }


    const getAttemptSurveyData = (data) => {
        if (hasPermission([Permissions.ATTEMPT_REFLECTION_READ])) {
            dispatch(getAllAttemptSurveyByUserId(data)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                setHasError(res.meta.requestStatus === "fulfilled" ? false : true)
            })
        }

    }

    useEffect(() => {
        if (hasPermission([Permissions.USER_READ], true)) {
            getUserDetails();
        }
    }, []);
    useEffect(() => {
        getAttemptSurveyData({userId: userId, pageNumber: pageNumber, sortBy: sortBy, sortOrder: sortOrder});
    }, [pageNumber, sortOrder, sortBy])


    useEffect(() => {
        return () => {
            setHasError(null)
        }
    }, [])


    useEffect(() => {
        if (hasPermission([Permissions.REFLECTION_READ], true)) {
            dispatch(getAllSurveysWithoutPagination()).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }
    }, [])
    useEffect(() => {
        if (hasPermission([Permissions.REPORT_READ], true)) {
            dispatch(getMemberVsReflectionAttemptReport({
                menteeIds: [userId],
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }
    }, [])

    useEffect(() => {

        dispatch(searchNotes({
            page: pageNumber,
            memberId: userId
        })).then((res) => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
        });

    }, [])


    const handleNoteSubmit = (e) => {
        e.preventDefault();
        if (isNullOrEmpty(noteDetails)) {
            showToast(EnterThought,"error")

        } else {
            dispatch(createNote({
                orgId: getDataFromLocalStorage("loggedInUserInfo").orgId,
                note: noteDetails,
                memberId: userId
            })).then((res) => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (res.meta.requestStatus === "fulfilled") {
                    setNoteDetails("")

                    dispatch(searchNotes({
                        page: pageNumber,
                        memberId: userId
                    })).then((res) => {
                        if (isUnauthorized(res)) {
                            navigate("/")
                        }
                    });
                }
            })
        }
    }

    useEffect(() => {
        if (surveysWithoutPaginationData.data !== null && surveysWithoutPaginationData.data !== undefined) {
            setSurveyDropDownList(createOptionListForSelectTag(surveysWithoutPaginationData?.data, "name", "surveyId"))
        }

    }, [surveysWithoutPaginationData])

    const sendInvite = () => {
        dispatch(sentInviteToUpdateRoleToPulseManager({
            userId: users.getUserByIdReducer?.data?.userId,
        })).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            } else if (res.meta.requestStatus === "fulfilled") {
                setShowPromoteConfirmationModal(false)
            }
        })
    }
    const revokePulseManagerRole = () => {
        dispatch(revokePulseManagerRoleByOrgAdmin({
            userId: users?.getUserByIdReducer?.data?.userId,
        })).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            } else if (res.meta.requestStatus === "fulfilled") {
                setShowDemoteConfirmationModal(false)
                getUserDetails();
            }
        })
    }

    return !hasPermission([Permissions.USER_READ, Permissions.ATTEMPT_REFLECTION_READ, Permissions.NOTE_READ]) ?
        <ErrorPage value={403} description={AccessDenied} detailDesc={AccessDeniedMsg}/> :
        hasError === null ?
            <Loader/> :

            (<>

                <div className="cmn_header">
                    <h2 className="mediumFontSize  org-heading m-0">Member Details </h2> <UserInfo/>
                </div>
                <div className="box_design1">
                    <div className={"row m-0 mb-3"}>

                        <div className={"col-xl-12"}>
                            <div className={"member-header mediumFontSize"}>
                                <div className={"member-img"}>
                                    <ProfilePicPlaceHolder
                                        letters={getInitials(userDetail?.firstName + " " + userDetail?.lastName)}/>
                                </div>
                                <div className={"member-details flex-grow-1"}>


                                    <div className={"gruop-data"}>
                                        <h5>{userDetail?.firstName + " " + userDetail?.lastName}</h5>

                                        {roleHasAccess([Roles.MENTOR]) && userDetail?.roles?.map(role => role.name).includes("MENTOR") ?
                                            <>
                                            </>
                                            :
                                            <>
                                                <HiPencil onClick={(e) => setEdit(true)}/>
                                            </>
                                        }

                                    </div>
                                    <div className={"other-details"}>

                                        <span> {userDetail?.email} </span>

                                        <div className={"mediumFontSize"}>
                                            <p className={"phone-number m-0"}>Mentor/Manager :

                                                <>
                                                 <span data-tooltip-id="assignData"
                                                       data-tooltip-content={userDetail?.assignTo?.map(c => c).join(",")}>
                                                {getFirstFiveElements(userDetail?.assignTo)}
                                                 </span>
                                                    <ReactTooltip className={"assignData"} place={"bottom"}
                                                                  id="assignData"/>
                                                </>


                                                {(!roleHasAccess([Roles.SYS_ADMIN, Roles.MENTOR])) &&
                                                    <span>
                                                <i className="fas fa-user-check assign-icon" onClick={() => {


                                                    setShowAssignModal(true)


                                                }}/> </span>}

                                            </p>
                                        </div>

                                    </div>

                                </div>

                                <div className={"buttons-reflection"}>

                                    {hasPermission([Permissions.ATTEMPT_REFLECTION_READ]) &&

                                        < button
                                            style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                            className="modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize ms-2 "
                                            onClick={() => {
                                                setShowModal(true)
                                            }}> Add Reflection
                                        </button>
                                    }

                                </div>

                            </div>
                        </div>

                    </div>


                    <div className={"row m-0 mb-3"}>
                        <div className={"col-xl-12"}>

                            <div className={"first-Card "}>
                                <div className={"graph-heading-outer mt-2 mx-2 mb-3"}>
                                    <div className={" stat-txt mediumFontSize"}>Statistics</div>
                                    <div className={"d-flex reflection-names"}>
                                        {headerLabels && Array.isArray(headerLabels) && headerLabels.map((c) => {
                                            return (
                                                <>
                                                    <div>
                                                        <div className={"reflection_color"}
                                                             style={{background: c?.color}}/>
                                                        {c?.name}</div>
                                                </>
                                            )
                                        })
                                        }
                                    </div>
                                </div>

                                <Line className={"members-detail-graph "} data={chartData} options={options}/>

                            </div>
                        </div>


                    </div>

                    <div className={"row m-0 mb-3 custom_pagination"}>
                        <div
                            className={(userDetail?.roles?.[0].name === "MENTEE" || userDetail?.roles?.[0]?.name === "MENTOR") ? "col-xl-6" : "col-xl-12"}>
                            <div className="first-Card ">
                                <div className={"reflection-result mediumFontSize"}>
                                    <h5>Results</h5>
                                    <div onClick={() => {
                                        setShowModal(true)
                                    }}
                                         style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                         className={"Icon-btn cmn_background_color"}>
                                        <FaPlus/>
                                    </div>

                                    <span className={"ms-1"}>Add Reflection</span>

                                </div>
                                <DataTable
                                    className={"attempted-reflection-table " + (survey?.getAllAttemptSurveyByUserIdDataReducer?.data?.totalPages > 1 ? "" : "table_design")}
                                    persistTableHead={false} responsive={true}
                                    progressPending={survey?.getAllAttemptSurveyByUserIdDataReducer?.loading || hasError}
                                    progressComponent={<div className={"attempted-reflection-skeleton-loader"}>
                                        <TableSkeletonLoading columns={3} rows={10}/></div>}
                                    customStyles={tableCustomStyling} columns={columns}
                                    data={survey?.getAllAttemptSurveyByUserIdDataReducer?.data?.data}
                                    noDataComponent={

                                        <div className={" mediumFontSize no-thought-text w-100"} style={{
                                            color: localStorage.getItem("color"),
                                            backgroundColor: hexToRgb(localStorage.getItem("color"), 0.1),
                                            background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? hexToRgb(localStorage.getItem("color"), 0.1) : ""
                                        }}>
                                        <span className={"mediumFontSize"}>No
                                        Reflection Attempted</span>
                                        </div>

                                    }
                                    noHeader={true}
                                    noTableHead={true}
                                    onSort={handleSort} sortServer={true}
                                />
                                {
                                    !survey?.getAllAttemptSurveyByUserIdDataReducer?.loading && survey?.getAllAttemptSurveyByUserIdDataReducer?.data && survey?.getAllAttemptSurveyByUserIdDataReducer?.data?.totalPages > 1 &&
                                    <Pagination
                                        totalPages={survey?.getAllAttemptSurveyByUserIdDataReducer?.data?.totalPages}
                                        pageNumber={survey?.getAllAttemptSurveyByUserIdDataReducer?.data?.pageNumber}
                                        setPageNumber={setPageNumber}/>
                                }

                            </div>


                        </div>
                        <div className={"col-xl-6 mediumFontSize"}>

                            {(userDetail?.roles[0].name === "MENTEE" || userDetail?.roles[0].name === "MENTOR") &&
                                <div className="first-Card thought-height">
                                    <h5 className={" mediumFontSize"}>Recommended Coaches</h5>
                                    {recommededCoachData?.loading ?
                                        (<>
                                                {[...Array(5)].map((x, index) => {
                                                    return (


                                                        <div key={index} className={"card-recommended"}>
                                                            <Skeleton width={70} height={70}/>
                                                            <div className={"recommended-info"}>
                                                                <h5><Skeleton width={30}/></h5>
                                                                <p><Skeleton width={30}/></p>
                                                            </div>
                                                            <div className={"recommended-info-others"}>
                                                                <h6><Skeleton width={30} height={30}/></h6>
                                                                <p><Skeleton width={30} height={30}/></p>

                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </>
                                        ) :
                                        (recommededCoachData?.data && recommededCoachData?.data?.length === 0 ?
                                                <h6 className={"ps-3 mt-2 no-thought-text mediumFontSize"} style={{
                                                    color: localStorage.getItem("color"),
                                                    backgroundColor: hexToRgb(localStorage.getItem("color"), 0.1),
                                                    background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? hexToRgb(localStorage.getItem("color"), 0.1) : ""
                                                }}>No Recommended Coaches available</h6>
                                                :
                                                recommededCoachData?.data && recommededCoachData?.data?.length > 0 && recommededCoachData?.data?.map((cur) => {
                                                    return (

                                                        <>
                                                            <div className={"recommendations"}>

                                                                <div className={"card-recommended"}>
                                                                    <img src={cur?.profile} alt=""/>

                                                                    <div className={"user-data"}>

                                                                        <div className={"recommended-info"}>
                                                                            <h5>{cur.name}</h5>
                                                                            <ul className={"specialities"}>
                                                                                {cur?.specialities?.length > 0 &&
                                                                                    <li>
                                                                                        {Array.isArray(cur.specialities) &&
                                                                                            cur.specialities.join(", ")}
                                                                                    </li>

                                                                                }


                                                                            </ul>
                                                                            <p>USD {cur.hourly_rate} / hour</p>

                                                                        </div>
                                                                        <div className={"recommended-info-others"}>
                                                                            <p><FiPhone className={"phone-icon"}/>
                                                                                <span>{cur.number}</span></p>
                                                                            <h6><MdOutlineEmail className={"email-icon"}/>
                                                                                <span>{cur.email}</span></h6>


                                                                        </div>

                                                                    </div>


                                                                </div>
                                                                <hr/>
                                                            </div>
                                                        </>

                                                    )
                                                })
                                        )
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div className="row m-0">
                        <div className="col-md-6">

                            <div className={"first-Card p-3"}>
                                <h5 className="ms-0 mb-3 mediumFontSize">Enter new thoughts</h5>

                                <form onSubmit={handleNoteSubmit}>

                                    <textarea name="" id="" cols="30" rows="5" value={noteDetails}
                                              onChange={(e) => setNoteDetails(e.target.value)}
                                              className="form-control"/>
                                    <div className="thoughts_footer">
                                        <div className="add_thouts">
                                            <button type={"submit"}
                                                    style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                    disabled={notes?.createNoteReducer?.loading === true}
                                                    className="modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize ms-2 ">
                                                Add thought
                                                {notes?.createNoteReducer?.loading && <TailSpinLoader/>}
                                            </button>
                                        </div>
                                    </div>

                                </form>


                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="first-Card thought-height p-3">
                                <h5 className={""}>Thoughts</h5>

                                <div
                                    className={notes?.getNotesDataReducer?.data?.items?.length > 5 ? "thought-scroll" : ""}>

                                    {notes.getNotesDataReducer?.loading ?

                                        (<>
                                                {[...Array(10)].map((x, index) => {
                                                    return (

                                                        <div key={index} className="thoughts_card">
                                                            <p><Skeleton width={250} height={40}/></p>

                                                            <div className="d-flex">
                                                                <img src="" alt=""/> <p className="mb-0"><Skeleton
                                                                width={200}/></p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </>
                                        ) :

                                        (
                                            // In case of Search if length is 0 show "No Result Found"

                                            notes?.getNotesDataReducer?.data?.items?.length === 0 ?
                                                <h6 className={"ps-3 mt-2 no-thought-text"} style={{
                                                    color: localStorage.getItem("color"),
                                                    backgroundColor: hexToRgb(localStorage.getItem("color"), 0.1),
                                                    background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? hexToRgb(localStorage.getItem("color"), 0.1) : ""
                                                }}>No thought to display</h6> :

                                                (
                                                    notes.getNotesDataReducer?.data?.items?.map((note, index) => {
                                                        return (
                                                            <div>

                                                                <div className="thoughts_card">

                                                                    <div className={"thoughts-content"}>

                                                                        <p>{(note.note.length > 120 && !selectedItems[index]) ? note.note.substring(0, 120) + "..." : note.note} </p>
                                                                        <i onClick={() => {
                                                                            setNoteId(note.noteId)
                                                                            setShowConfirmationModal(true)
                                                                        }} className={"fa fa-trash trash-icon"}/>

                                                                    </div>


                                                                    {note.note.length > 120 &&
                                                                        <span className={'show_more'}
                                                                              onClick={() => handleShowText(index)}>{!selectedItems[index] ? 'More' : "less"}</span>}
                                                                    <div className="d-flex">
                                                                        <img src="" alt=""/> <p
                                                                        className="mb-0">{formatDate(note.createdAt)}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                )
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showModal && <SurveyAttempt show={showModal} setShow={setShowModal} memberId={userId} memberDetails={userDetail}/>}
                {
                    showPromoteConfirmationModal &&
                    <ConfirmationModel isLoading={users?.sentInviteToUpdateRoleToPulseManagerReducer?.loading}
                                       title={"Send Invitation"}
                                       btnText={"Send"}
                                       bodyMessage={"Are you sure you want to invite this member as Pulse Manager? This will allow them to see other organizations and perform actions on them."}
                                       action={sendInvite} show={showPromoteConfirmationModal}
                                       setShow={setShowPromoteConfirmationModal}
                                       showUserAcknowledgementCheckBox={true}
                                       userAcknowledgementCheckBoxText={"Yes, I understand"}/>
                }
                {
                    showDemoteConfirmationModal &&
                    <ConfirmationModel isLoading={users?.revokePulseManagerRoleByOrgAdminReducer?.loading}
                                       title={"Demote Organization Back"}
                                       btnText={"Confirm"}
                                       bodyMessage={"Are you sure you want to demote this member back to its previous status? This action will revert any changes made to the member's role structure."}
                                       action={revokePulseManagerRole} show={showDemoteConfirmationModal}
                                       setShow={setShowDemoteConfirmationModal}
                                       showUserAcknowledgementCheckBox={true}
                                       userAcknowledgementCheckBoxText={"Yes, I understand"}/>
                }

                {showAssignModal &&
                    <AssignMember show={showAssignModal}
                                  setShow={setShowAssignModal} userId={userId} userData={userDetail}
                                  setUserData={setUserDetail} query={null} setQuery={null}
                    />}

                {
                    showConfirmationModal &&
                    <ConfirmationModel isLoading={notes?.deleteNoteByIdDataReducer?.loading} title={"Delete Thought"}
                                       btnText={"Delete"}
                                       setKeyword={null} bodyMessage={"Are you sure you want to delete?"}
                                       action={handleDeleteNote} show={showConfirmationModal}
                                       setShow={setShowConfirmationModal}
                                       showUserAcknowledgementCheckBox={false}/>
                }

                {
                    edit &&
                    <UpdateUserModal show={edit}
                                     setShow={setEdit} userDetails={userDetail} setUserDetails={setUserDetail}
                                     data={getUserDetails}/>
                }


            </>);
}
export default MemberDetails;
