import {useEffect, useState} from "react";
import {getRandomColor} from "../../helpers/utils";

const ProfilePicPlaceHolder = ({backColor=null,id="profile-pic-placeholder",className="",letters,onClick=()=>{}}) => {
    const [backgroundColor, setBackgroundColor] = useState("#")
    const style = {
        display: "flex",
        alignItems: "center",
        borderRadius: "50%",
        background: backColor===null?backgroundColor:backColor,
        color: "white",
        justifyContent: "space-around",
        fontSize: "10px",
        cursor: "default",
        textTransform: "uppercase"
    }
    useEffect(() => {
        if(backColor===null){
            setBackgroundColor(getRandomColor())
        }

    }, [])
    return (
        <div id={id} onClick={onClick} className={"placeholder-container " +className} style={style}>
            {letters}
        </div>
    );
}
export default ProfilePicPlaceHolder;