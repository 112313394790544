import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import {
    createOptionListForSelectTagWithMultipleLabels,
    createOptionListForSelectTagWithMultipleLabelsWithAdditionalInfo,
    eliminateDuplicateObjects,
    emailRegExp, formatMessage,
    getAuthorityLevelFor,
    getDataFromLocalStorage,
    getRolesWithAuthorityLevel,
    handleApiResponse,
    isUnauthorized,
    showToast
} from "../../../helpers/utils";
import {toast} from "react-toastify";
import {EmailRequired, IncludesAnInvalidEmail, IsRequired, orgLevels} from "../../../helpers/constants";
import {TailSpinLoader} from "../../layout/Loader";
import CreatableSelect from "react-select/creatable";
import {IoIosClose} from "react-icons/io";
import {useDispatch, useSelector} from "react-redux";
import {Roles} from "../../../helpers/accessControl";
import {getAllUsersWithoutPagination} from "../../../reduxStore/usersSlices/userSlice";
import {useNavigate} from "react-router-dom";
import jwtDecode from "jwt-decode";
import Select from "react-select";


const components = {
    DropdownIndicator: null,
};

const tagOption = (label) => ({
    label: label,
    value: label,
});


const ShareReport = ({
                         show,
                         setShow,
                         email,
                         setEmail,
                         title,
                         reportShare,
                         isReportShare,
                         selectedMembersToSendEmailTo,
                         setSelectedMembersToSendEmailTo
                     }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [stateValue, setStateValue] = useState('');


    const getAllUsersWithoutPaginationData = useSelector(state => state.users.getAllUsersWithoutPaginationReducer);

    useEffect(() => {
        if (title === "Share Reflection") {
            const token = jwtDecode(getDataFromLocalStorage("token"))
            const roleToGetAuthorityLevelFor = token?.roles[0]
            const rolesToGet = getRolesWithAuthorityLevel("<", getAuthorityLevelFor(roleToGetAuthorityLevelFor), [Roles.ANONYMOUS_USER])

            dispatch(getAllUsersWithoutPagination({
                roles: rolesToGet,
                ...(token?.orgLevel === orgLevels.SYS.name ? {orgId: token?.orgId} : {})
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }
    }, [title])
    useEffect(() => {
        setEmail([]);
        setStateValue("");
    }, []);


    const handleChange = (value) => {
        setEmail(value)
    };
    const handleInputChange = (inputValue) => {
        setStateValue(inputValue);
    };
    const handleKeyDown = (event) => {
        if (!stateValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                saveValue();
                event.preventDefault();
        }
    };
    const handleClose = () => {
        isReportShare(false);
        setEmail([]);
        setShow(false)
    };

    function handleRequiredTag(e) {
        if (e.key !== 'Enter') return
        const value = e.target.value;
        if (value.trim()) {
            setEmail([...email, tagOption(value)]);
        }

        e.preventDefault();
    }

    function handleOnBlur(e) {
        const value = e.target.value;
        if (value.trim()) {
            setEmail([...email, tagOption(value)]);
        }
    }

    function handleSubmit(e) {

        e.preventDefault();
        // If its Share Reflection we should check for selected Members also
        if (email.length === 0 && ((title === "Share Reflection" && selectedMembersToSendEmailTo?.length === 0) || title !== "Share Reflection")) {
            return showToast(title === "Share Reflection" ? formatMessage(IsRequired, ["Please Select at least one member or email"]) : EmailRequired, "error")
        }
        if (email.every(email => email && emailRegExp.test(email.value))) {
            isReportShare(true);
        } else {
            if (email.some(email => !email)) {
                showToast(EmailRequired, "error")
            } else if (email.some(email => !emailRegExp.test(email))) {
                showToast(IncludesAnInvalidEmail, "error")
            }
        }
    }

    function saveValue() {
        if (!stateValue) return;
        const emails = stateValue.split(/[ ,]+/).filter(Boolean);
        const existingEmailsSet = new Set(email.map(item => item.value));
        const newEmails = emails.filter(email => {
            if (existingEmailsSet.has(email)) {
                showToast(`Email already exists, please try unique one!`, "info")
                return false;
            }
            return true;
        }).map(tagOption);

        if (newEmails.length > 0) {
            setEmail(prevEmails => [...prevEmails, ...newEmails]);
        }
        setStateValue('');
    }

    return (

        <>
            <Modal className='mt-4' show={show} centered>
                <Modal.Header className={"d-flex"}>
                    <Modal.Title
                        className='add_member_heading mediumFontSize'>{title}</Modal.Title>
                    <IoIosClose className={"close_btn"} onClick={handleClose} size={24}/>

                </Modal.Header>

                <form onSubmit={handleSubmit}>

                    <Modal.Body className="modal_outer">

                        <Modal.Body className="modal_outer">

                            <div className="reset_form">
                                {
                                    title === "Share Reflection" && <div className={"form-group mt-1 mb-2"}>
                                        <Form.Group className='userInfo_label mediumFontSize'>
                                            <Form.Label className='regularFontSize'>Members</Form.Label>
                                            <Select
                                                className="members-filter-role react-select-container"
                                                classNamePrefix="react-select"
                                                isDisabled={getAllUsersWithoutPaginationData?.loading}
                                                isMulti
                                                isClearable={true}
                                                value={selectedMembersToSendEmailTo}
                                                options={createOptionListForSelectTagWithMultipleLabelsWithAdditionalInfo(getAllUsersWithoutPaginationData?.data?.filter(user => !user.email.endsWith("fake.com")), ["firstName", "lastName"], "email", "email")}
                                                onChange={(selectedValue) => {
                                                    setSelectedMembersToSendEmailTo(selectedValue)
                                                }}
                                                placeholder={"Select Members..."}/>
                                        </Form.Group>
                                    </div>
                                }
                                <div className="form-group mt-1">
                                    <Form.Group className='userInfo_label mediumFontSize'>
                                        <Form.Label
                                            className='regularFontSize'>Email {title === "Share Reflection" ? "" : "*"}</Form.Label>
                                        <div className={"share_reflection"}>
                                            <CreatableSelect
                                                components={components}
                                                inputValue={stateValue}
                                                autoFocus={email.length !== 0}
                                                isClearable
                                                isMulti
                                                menuIsOpen={false}
                                                onChange={handleChange}
                                                onInputChange={handleInputChange}
                                                onKeyDown={handleKeyDown}
                                                onMenuClose={saveValue}
                                                value={email}
                                                isDisabled={false}
                                                className={"share_reflection_wrapper"}
                                            />
                                        </div>
                                    </Form.Group>
                                </div>

                            </div>

                        </Modal.Body>


                    </Modal.Body>
                </form>

                <Modal.Footer className="modal_footer">
                    <button className="modal_cancel_btn cmn_modal_btn mediumFontSize"
                            onClick={handleClose}>Cancel
                    </button>
                    <button
                        style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined || localStorage.getItem("color") !== "undefined" ? localStorage.getItem("color") : ""}}
                        type="submit"
                        disabled={reportShare}
                        className="modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize"
                        onClick={(e) => {
                            handleSubmit(e)
                        }}>Share

                        {reportShare && <TailSpinLoader/>}

                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )


}
export default ShareReport;