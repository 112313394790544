import Modal from "react-bootstrap/Modal";
import {IoIosClose} from "react-icons/io";
import Select from "react-select";
import {
    createOptionListForSelectTagWithMultipleLabels,
    eliminateDuplicateObjects, eliminateDuplicatesFromArray, getAuthorityLevelFor, getDataFromLocalStorage,
    getRolesWithAuthorityLevel, handleApiResponse, isUnauthorized
} from "../../../helpers/utils";
import {hasPermission, Permissions, Roles} from "../../../helpers/accessControl";
import {TailSpinLoader} from "../../layout/Loader";
import React, {useEffect, useState} from "react";
import {getAllUsersWithoutPagination} from "../../../reduxStore/usersSlices/userSlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import jwtDecode from "jwt-decode";
import {updateTeamById} from "../../../reduxStore/teamSlices/teamSlice";

const AddNewTeamMemberModal = ({show, setShow, teamData, callback}) => {

    const token = jwtDecode(getDataFromLocalStorage("token"))
    const isOrgLevelSys = token.orgLevel === "SYSTEM"

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [updateTeamData, setUpdateTeamData] = useState({
        managedBy: [],
        membersIds: []
    })

    const getAllUsersWithoutPaginationData = useSelector(state => state.users.getAllUsersWithoutPaginationReducer);
    const updateTeamByIdData = useSelector(state => state.team.updateTeamByIdReducer);
    const getTeamByIdData = useSelector(state => state.team.getTeamByIdReducer)


    useEffect(() => {
        if (hasPermission([Permissions.USER_READ], true)) {
            const roleToGetAuthorityLevelFor = Roles.ORG_ADMIN
            const rolesToGet = getRolesWithAuthorityLevel("<=", getAuthorityLevelFor(roleToGetAuthorityLevelFor), [Roles.ANONYMOUS_USER])
            dispatch(getAllUsersWithoutPagination({
                roles: rolesToGet,
                ...(isOrgLevelSys ? {orgId: token?.orgId} : {})
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }
    }, []);

    const handleClose = () => {
        setShow(false);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const dataToSubmit = {
            id: getTeamByIdData?.data?.uid,
            teamName: teamData.teamName,
            description: teamData.description,
            managedBy: eliminateDuplicatesFromArray(updateTeamData?.managedBy?.map(cur => cur.value).concat(teamData?.managedBy)),
            membersIds: eliminateDuplicatesFromArray(updateTeamData?.membersIds?.map(cur => cur.value).concat(teamData?.membersIds)),
        }
        dispatch(updateTeamById(dataToSubmit)).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
            const onSuccess = () => {
                callback(dataToSubmit)
                handleClose();
            }
            handleApiResponse(res, onSuccess)
        })
    }

    return (
        <>
            <Modal className='mt-4' show={show} centered>
                <Modal.Header className={"d-flex"}>
                    <Modal.Title className='add_member_heading mediumFontSize'>Add Members</Modal.Title>
                    <IoIosClose className={"close_btn"} onClick={handleClose} size={24}/>
                </Modal.Header>

                <form onSubmit={handleSubmit}>

                    <Modal.Body className="modal_outer">


                        <div className="reset_form">

                            <div className={"form-group mt-1"}>

                                <label className={"userInfo_label mb-2"}
                                       htmlFor="exampleFormControlSelect1"> Leaders* </label>


                                <Select
                                    className="members-filter-role react-select-container"
                                    classNamePrefix="react-select"
                                    isDisabled={getAllUsersWithoutPaginationData?.loading}
                                    isMulti
                                    isClearable={true}
                                    value={updateTeamData?.managedBy}
                                    options={createOptionListForSelectTagWithMultipleLabels(getAllUsersWithoutPaginationData?.data?.filter(user => user.roleObjects[0]?.name !== Roles.MENTEE && !teamData?.managedBy?.includes(user.uid) && !teamData?.membersIds?.includes(user.uid)), ["firstName", "lastName"], "uid")}
                                    onChange={(selectedValue) => {
                                        setUpdateTeamData({
                                            ...updateTeamData,
                                            managedBy: selectedValue?.length === 0 ? [] : selectedValue,
                                            membersIds: eliminateDuplicateObjects(updateTeamData?.membersIds, selectedValue, "value")
                                        })
                                    }}
                                    placeholder={"Select..."}/>

                            </div>
                            <div className={"form-group mt-1"}>

                                <label className={"userInfo_label mb-2"}
                                       htmlFor="exampleFormControlSelect1">Team Members* </label>


                                <Select
                                    className="members-filter-role react-select-container"
                                    classNamePrefix="react-select"
                                    isDisabled={getAllUsersWithoutPaginationData?.loading}
                                    isMulti
                                    isClearable={true}
                                    value={updateTeamData?.membersIds}
                                    // Exclude Managed By members from members list
                                    options={createOptionListForSelectTagWithMultipleLabels(getAllUsersWithoutPaginationData?.data?.filter(user => !updateTeamData?.managedBy?.map(cur => cur.value)?.includes(user.uid) && !teamData?.membersIds?.includes(user.uid) && !teamData?.managedBy?.includes(user.uid)), ["firstName", "lastName"], "uid")}
                                    onChange={(selectedValue) => {
                                        setUpdateTeamData({
                                            ...updateTeamData,
                                            membersIds: selectedValue?.length === 0 ? [] : selectedValue
                                        })
                                    }}
                                    placeholder={"Select..."}/>

                            </div>


                        </div>


                    </Modal.Body>

                    <Modal.Footer className="modal_footer">
                        <div className={"add-member-modal-btns"}>
                            <button
                                style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                type="submit"
                                disabled={updateTeamByIdData?.loading}
                                className="ms-2 modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize ">Add
                                {
                                    updateTeamByIdData?.loading && <TailSpinLoader/>
                                }

                            </button>
                            <button className="modal_cancel_btn cmn_modal_btn mediumFontSize"
                                    disabled={updateTeamByIdData?.loading}
                                    onClick={handleClose}>Cancel
                            </button>

                        </div>

                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}
export default AddNewTeamMemberModal