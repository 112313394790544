import search_icon from "../../images/search_icon.svg";
import {
    applyValidDateRange,
    createOptionListForSelectTag,
    getAuthorityLevelFor,
    getDataFromLocalStorage,
    getFirstFiveElements,
    getInitials,
    getRolesObjectExcluding,
    getRolesWithAuthorityLevel, handleApiResponse,
    isUnauthorized, showToast,
    tableCustomStyling,
    TableSkeletonLoading,
    toString,
    trim, validateUpdateTeamObject
} from "../../helpers/utils";
import filter_icon from "../../images/filter_icon.svg";
import {hasPermission, Permissions, Roles} from "../../helpers/accessControl";
import DataTable from "react-data-table-component";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getTeamMembers, updateTeamById} from "../../reduxStore/teamSlices/teamSlice";
import {NavLink, useNavigate} from "react-router-dom";
import ProfilePicPlaceHolder from "../commons/ProfilePicPlaceHolder";
import {Tooltip as ReactTooltip} from "react-tooltip";
import jwtDecode from "jwt-decode";
import {MdPersonRemove} from "react-icons/md";
import {LuClipboardCopy} from "react-icons/lu";
import {MdOutlineNoteAlt} from "react-icons/md";
import Pagination from "../commons/Pagination";
import {SearchFilterModal} from "../commons/SearchFilterModal";
import Select from "react-select";
import DatePicker from "react-datepicker";
import ConfirmationModel from "../commons/ConfirmationModel";
import AddNewTeamMemberModal from "./modal/AddNewTeamMemberModal";
import AttemptReflectionModal from "./modal/AttemptReflectionModal";
import AddOrUpdateNoteModal from "../dashboard/modal/AddOrUpdateNoteModal";

const TeamMembers = ({teamId}) => {

    const token = jwtDecode(getDataFromLocalStorage("token"))
    const isRoleOrgAdmin = token?.roles?.includes(Roles.ORG_ADMIN)
    const isRoleManager = token?.roles?.includes(Roles.MANAGER)
    const isRoleSubOrgAdmin = token?.roles?.includes(Roles.SUB_ORG_ADMIN)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [searchQuery, setSearchQuery] = useState({
        firstName: null,
        role: [],
        createdDateRange: null
    })
    const [teamData, setTeamData] = useState({
        teamName: "",
        description: "",
        managedBy: [],
        membersIds: []
    })

    const [triggerApi, setTriggerApi] = useState(false)
    const [handleRemoveUser, setHandleRemoveUser] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [sortCriteria, setSortCriteria] = useState({
        sortBy: "createdAt",
        sortOrder: "desc",
    })
    const [userToRemove, setUserToRemove] = useState(null)
    const [timer, setTimer] = useState(null)
    const [showSearchFilterModal, setShowSearchFilterModal] = useState(false)
    const [showAddNoteModal, setShowAddNoteModal] = useState(false)
    const [addNoteForMemberId, setAddNoteForMemberId] = useState(null)
    const [showRemoveUserFromTeamConfirmationModal, setShowRemoveUserFromTeamConfirmationModal] = useState(false)
    const [showAttemptReflectionModal, setShowAttemptReflectionModal] = useState(false)
    const [selectedMemberToAttemptReflection, setSelectedMemberToAttemptReflection] = useState(null)
    const [showAddNewMemberModal, setShowAddNewMemberModal] = useState(false)

    const getTeamByIdData = useSelector(state => state.team.getTeamByIdReducer)
    const getTeamMembersData = useSelector(state => state.team.getTeamMembersReducer)
    const updateTeamByIdData = useSelector(state => state.team.updateTeamByIdReducer)


    const columns = [
        {
            name: <div className={"d-flex"}><span className={"ms-2 bulk_delete_Main_header"}> Name </span></div>,
            selector: row => row.firstName,
            sortable: true,
            sortField: 'firstName',
            cell: (row) =>
                (
                    <div className={"d-flex"}>
                        <div className={"display_profile_data wrap-text ms-2"}>
                            <ProfilePicPlaceHolder letters={getInitials(row.firstName + " " + row.lastName)}/>
                            {
                                hasPermission([Permissions.USER_READ, Permissions.ATTEMPT_REFLECTION_READ, Permissions.NOTE_READ]) ?
                                    <NavLink
                                        title={row.firstName + " " + row.lastName}
                                        to={`/members/${row.uid}`}>
                                        {row.firstName + " " + row.lastName }
                                    </NavLink> :
                                    <span
                                        title={row.firstName + " " + row.lastName}
                                        className={"ps-2 wrap-text font-wt-500"}>
                                        {trim(row.firstName + " " + row.lastName, 45)}
                                    </span>
                            }
                        </div>
                    </div>

                ),
            style: {
                fontFamily: 'General Sans Medium',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            },
            width: getTeamMembersData?.loading ? "" : "30%"
        },
        {
            name: "Member Type",
            selector: row => row?.teamIdsUserLeads?.includes(teamId) ? "Leader" : "Member",
            width: getTeamMembersData?.loading ? "" : "15%"
        },
        {
            name: "Email",
            selector: row => row.email,
            width: getTeamMembersData?.loading ? "" : "20%"
        },
        {
            name: "Role",
            selector: row => row.roleObjects?.map(role => role.name)?.map(role => toString(role))?.join(" / "),
            style: {opacity: ".7",},
            width: getTeamMembersData?.loading ? "" : "12%"
        },
        {
            name: "Mentor/Manager",
            sortField: 'assignTo',
            cell: row => {
                return (
                    <div data-tooltip-id="assignData" data-tooltip-content={row?.assignTo?.map(c => c).join(",")}>
                        {row?.assignTo?.length > 0 ? getFirstFiveElements(row?.assignTo) : "---------"}
                        <ReactTooltip className={"assignData"} place={"bottom"} id="assignData"/>
                    </div>
                );
            },
            style: {opacity: ".7"},
        },
        {
            name: "Action",
            cell: row => (
                <div>
                    <MdPersonRemove
                        title={"Remove from team"}
                        onClick={() => {
                            if (getTeamByIdData?.data?.createdBy === token?.userid) {
                                setShowRemoveUserFromTeamConfirmationModal(true)
                                setUserToRemove(row)
                            }
                        }}
                        size={18}
                        className={getTeamByIdData?.data?.createdBy === token?.userid ? " ms-1 cursor cursor-pointer" : " ms-1  opacity5"}/>
                    <LuClipboardCopy
                        title={"Attempt Reflection"}
                        onClick={() => {
                            if ((getTeamByIdData?.data?.createdBy === token?.userid || getTeamByIdData?.data?.managedBy?.includes(token?.userid)) && row.uid !== token?.userid) {
                                setShowAttemptReflectionModal(true)
                                setSelectedMemberToAttemptReflection(row?.uid)
                            }
                        }}
                        size={16}
                        className={((getTeamByIdData?.data?.createdBy === token?.userid || getTeamByIdData?.data?.managedBy?.includes(token?.userid)) && row.uid !== token?.userid) ? " ms-2 cursor cursor-pointer" : " ms-2 opacity5"}/>
                    <MdOutlineNoteAlt
                        title={"Add Thought"}
                        onClick={() => {
                            if ((getTeamByIdData?.data?.createdBy === token?.userid || getTeamByIdData?.data?.managedBy?.includes(token?.userid)) && row.uid !== token?.userid) {
                                setShowAddNoteModal(true)
                                setAddNoteForMemberId(row?.uid)
                            }
                        }}
                        size={18}
                        className={((getTeamByIdData?.data?.createdBy === token?.userid || getTeamByIdData?.data?.managedBy?.includes(token?.userid)) && row.uid !== token?.userid) ? " ms-2 cursor cursor-pointer" : " ms-2 opacity5"}/>

                </div>
            ),
        }

    ]
    let searchFiltersTags = [
        {
            shouldShow: (isRoleOrgAdmin || isRoleManager || isRoleSubOrgAdmin) && hasPermission([Permissions.ROLE_READ], true),
            tagView: <Select
                className="members-filter-role react-select-container"
                classNamePrefix="react-select"
                isMulti
                isClearable={true}
                value={searchQuery.role}
                options={createOptionListForSelectTag(getRolesObjectExcluding(getRolesWithAuthorityLevel("<", getAuthorityLevelFor(token?.roles[0]), [Roles.SUB_ORG_ADMIN, Roles.ANONYMOUS_USER]), []), "name", "value")}
                onChange={(selectedOptions) => {
                    setSearchQuery({...searchQuery, role: selectedOptions.length === 0 ? [] : selectedOptions})
                }}
                placeholder={"Role..."}/>

        },
        {
            tagView: <div className={"members-date-picker-outer "}>
                <DatePicker placeholderText="From: dd-mm-yyyy" dateFormat={"dd-MM-yyyy"}
                            selected={searchQuery?.createdDateRange?.startDate || ""}
                            className={"search-by-survey-name survey-name-search member_search "}
                            maxDate={searchQuery?.createdDateRange?.endDate || new Date()}
                            onChange={(date) => {
                                setSearchQuery({
                                    ...searchQuery,
                                    createdDateRange: applyValidDateRange({
                                        startDate: date,
                                        endDate: searchQuery?.createdDateRange?.endDate || null
                                    })
                                })
                            }}
                />
                <DatePicker placeholderText="To: dd-mm-yyyy" dateFormat={"dd-MM-yyyy"}
                            selected={searchQuery?.createdDateRange?.endDate || ""}
                            minDate={searchQuery?.createdDateRange?.startDate || ""}
                            className={"search-by-survey-name survey-name-search user-join-toDate member_search "}
                            maxDate={new Date()}
                            onChange={(date) => {
                                setSearchQuery({
                                    ...searchQuery,
                                    createdDateRange: applyValidDateRange({
                                        startDate: searchQuery?.createdDateRange?.startDate || null,
                                        endDate: date
                                    })
                                })
                            }}
                />

            </div>
        }
    ]

    useEffect(() => {
        if (teamId !== null && teamId !== undefined) {
            setTriggerApi(true)
        }
    }, [teamId]);
    useEffect(() => {
        if (triggerApi && hasPermission([Permissions.USER_READ], true)) {
            dispatch(getTeamMembers({
                ...searchQuery,
                ...sortCriteria,
                page: pageNumber,
                role: searchQuery?.role?.map(cur => cur.value) || [],
                teamId: teamId
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                setTriggerApi(false)
            })
        }
    }, [triggerApi]);
    useEffect(() => {
        if (searchQuery.firstName !== null) {
            if (timer) {
                clearTimeout(timer);
            }
            setTimer(setTimeout(() => {
                setTriggerApi(true);
            }, 1000));
        }
    }, [searchQuery.firstName]);
    useEffect(() => {
        if (getTeamByIdData?.data !== null && getTeamByIdData?.data !== undefined) {
            setTeamData({
                ...teamData,
                teamName: getTeamByIdData?.data?.teamName,
                description: getTeamByIdData?.data?.description,
                managedBy: getTeamByIdData?.data?.managedBy,
                membersIds: getTeamByIdData?.data?.membersIds
            })
        }
    }, [getTeamByIdData]);
    useEffect(() => {
        if (handleRemoveUser) {
            const updatedTeamData = {
                ...teamData,
                id: teamId,
                managedBy: teamData?.managedBy?.filter(cur => cur !== userToRemove?.uid),
                membersIds: teamData?.membersIds?.filter(cur => cur !== userToRemove?.uid),
            };
            const message = validateUpdateTeamObject(updatedTeamData)
            if (message) {
                showToast(message, "error");
                handleCloseConfirmationBox();
            } else {
                dispatch(updateTeamById(updatedTeamData)).then(res => {
                    if (isUnauthorized(res)) {
                        navigate("/")
                    }
                    const onSuccess = () => {
                        setTeamData({
                            ...updatedTeamData
                        })
                        setTriggerApi(true)
                        handleCloseConfirmationBox();
                    }
                    handleApiResponse(res, onSuccess, handleCloseConfirmationBox)
                })
            }
        }
    }, [handleRemoveUser]);

    const setPageNumberAndTriggerApi = (page) => {
        setPageNumber(page)
        setTriggerApi(true)
    }

    const handleSort = (column, sortDirection,) => {
        setSortCriteria({
            ...sortCriteria,
            sortBy: column.sortField,
            sortOrder: sortDirection,
        })
        setTriggerApi(true)
    };

    const handleCloseConfirmationBox = () => {
        setUserToRemove(null)
        setHandleRemoveUser(false)
        setShowRemoveUserFromTeamConfirmationModal(false)
    }

    return (
        <>
            <div className={"row main_content  sub-org-members-outer"}>
                <div className={"col-md-12   px-0"}>


                    <div className="d-flex col-12">
                        <div className="reflection_search_wrapper ">
                            <img src={search_icon} alt=""/>
                            <input type={"text"} className={"form-control members-name-filter-input"}
                                   placeholder={"Name..."}
                                   value={searchQuery.firstName}
                                   onChange={(e) => {
                                       setSearchQuery({...searchQuery, firstName: e.target.value})
                                   }}
                            />
                        </div>


                        <div className={"reflection_filters ms-auto"}>
                            <button type={"button"} onClick={() => {
                                setShowSearchFilterModal(true)
                            }}>
                                <img src={filter_icon} alt=""/>Filter
                            </button>
                        </div>

                        <div className={"members-btn"}>

                            {
                                token?.userid === getTeamByIdData?.data?.createdBy && <button
                                    style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                    className="cmn_background_color survey_addnew_btn mediumFontSize create-sub-org-btn ms-2"
                                    onClick={() => setShowAddNewMemberModal(true)}
                                >Add Team Member
                                </button>
                            }


                        </div>
                    </div>


                </div>

                <div className={"colored_table p-0"}>
                    <DataTable
                        className={getTeamMembersData?.data?.totalPages > 1 ? " table_design2 " : " table_design1 "}
                        persistTableHead={true} responsive={true}
                        customStyles={tableCustomStyling}
                        progressPending={getTeamMembersData?.loading}
                        columns={columns}
                        data={getTeamMembersData?.data?.data}
                        noDataComponent={<div className={"no-member-text"}>No Member Found in Team</div>}
                        progressComponent={<TableSkeletonLoading columns={6} rows={5}/>}
                        onSort={handleSort}
                        sortServer={true}
                    />

                    {
                        !getTeamMembersData?.loading && getTeamMembersData?.data && getTeamMembersData?.data?.totalPages > 1 &&
                        <Pagination className={"pagination-border"}
                                    totalPages={getTeamMembersData?.data?.totalPages} pageNumber={pageNumber}
                                    setPageNumber={setPageNumberAndTriggerApi}/>
                    }

                </div>

            </div>
            {
                showSearchFilterModal &&
                <SearchFilterModal title={"Attempts Filters"}
                                   show={showSearchFilterModal}
                                   filtersTag={searchFiltersTags}
                                   applyFilter={triggerApi}
                                   setApplyFilter={setTriggerApi}
                                   resetFilters={() => {
                                       setSearchQuery({
                                           firstName: null,
                                           role: [],
                                           createdDateRange: null
                                       });
                                   }}
                                   handleClose={() => {
                                       setShowSearchFilterModal(false);
                                   }}
                                   setShow={setShowSearchFilterModal}
                />
            }
            {
                showAddNoteModal &&
                <AddOrUpdateNoteModal
                                      type={"Add"}
                                      show={showAddNoteModal}
                                      setShow={setShowAddNoteModal}
                                      isTeam={true}
                                      memberId={addNoteForMemberId}
                />
            }
            {
                showAddNewMemberModal &&
                <AddNewTeamMemberModal
                    show={showAddNewMemberModal}
                    setShow={setShowAddNewMemberModal}
                    teamData={teamData}
                    callback={(updatedTeamData) => {
                        setTeamData(updatedTeamData)
                        setTriggerApi(true)
                    }}
                />
            }
            {
                showAttemptReflectionModal &&
                <AttemptReflectionModal
                    show={showAttemptReflectionModal}
                    setShow={setShowAttemptReflectionModal}
                    selectedMember={selectedMemberToAttemptReflection}
                />
            }
            {
                showRemoveUserFromTeamConfirmationModal &&
                <ConfirmationModel title={"Remove User"}
                                   btnText={"Confirm"}
                                   action={() => {
                                       setHandleRemoveUser(true)
                                   }}
                                   show={showRemoveUserFromTeamConfirmationModal}
                                   bodyMessage={"Are you sure you want to remove this user from the team?"}
                                   setShow={handleCloseConfirmationBox}
                                   isLoading={updateTeamByIdData?.loading}
                                   showUserAcknowledgementCheckBox={false}
                />
            }
        </>
    );
}
export default TeamMembers