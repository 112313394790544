import React from "react";
import Modal from "react-bootstrap/Modal";
import {IoIosClose} from "react-icons/io";



export const SearchFilterModal=({show,setShow,handleClose,title,filtersTag , applyFilter, setApplyFilter, triggerOnChange=false,resetFilters})=>{

    return(
        <>
            <Modal className='mt-4' show={show} centered size="lg">
                <Modal.Header className={"d-flex"}>

                    <Modal.Title className='mediumFontSize'>{title}</Modal.Title>

                        <IoIosClose className={"close_btn"} onClick={handleClose} size={24}/>
                </Modal.Header>

                <Modal.Body className="modal_outer">
                    <div className="reset_form ">
                        <div className="form-group mt-1">
                           <div className={"m-0 row search_filter_common_modal"}>
                               {Array.isArray(filtersTag) && filtersTag.filter(c=>(c?.shouldShow===undefined || c?.shouldShow===true) && c.tagView!==false).map((curView)=>(
                                   (curView?.shouldShow!==undefined  ? curView.shouldShow  : curView?.tagView) && <div className={filtersTag.filter(c=>(c?.shouldShow===undefined || c?.shouldShow===true) && c.tagView!==false).length>1 ? "col-6 mb-3 px-1" : "px-1 col-12 mb-3"}>
                                       {curView?.tagView || <></>}
                                   </div>
                               ))
                               }

                           </div>

                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer className="modal_footer">
                    <button className="modal_cancel_btn cmn_modal_btn mediumFontSize  search_filter_clear" onClick={()=>{
                        resetFilters();

                        setShow(false)
                        setApplyFilter(true)
                    }}>Clear
                    </button>

                    <button style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""} } className="modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize" onClick={()=>{
                       setApplyFilter(true)
                        setShow(false)
                    }}>Submit
                    </button>
                </Modal.Footer>
            </Modal>
        </>

    )

}
