import React, {useEffect, useState} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import {Marks} from "../../helpers/constants";
import {Loader} from "../layout/Loader";
import {getGraphWidth, trim} from "../../helpers/utils";
import html2canvas from "html2canvas";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const Graphs = ({reportType, data, isDataLoading , isShareReport, reports, setReports,graphId}) => {

    const chartOptions = {
        responsive: true,
        scales: {
            x: {
                ticks:{
                    color:"black",
                    callback:function (value){
                        return trim(chartData.labels[value],24);
                    }
                },
                stacked: true,
            },
            y: {
                stacked: true,
                ticks:{
                    color:"black",
                }
            },
        },

        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },

        },

    };

    const [chartData, setChartData] = useState(
        {
            labels: [],
            datasets: [
                {
                    label: "Total Attempt",
                    data: [],
                    backgroundColor: '#4298d7',
                    stack: 'Stack 0',
                },
                {
                    label: Marks.HIGH_RISK,
                    data: [],
                    backgroundColor: "red",
                    stack: 'Stack 1',

                },
                {
                    label: Marks.LOW_RISK,
                    data: [],
                    backgroundColor: '#ff7100',
                    stack: 'Stack 1',

                },
                {
                    label: Marks.GOOD,
                    data: [],
                    backgroundColor: 'green',
                    stack: 'Stack 1',

                },
                {
                    label: "Result N/A",
                    data: [],
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    stack: 'Stack 1',

                },
            ]
        }
    )
    useEffect(() => {
        if (data !== null && data !== undefined) {
            const chartLabels = data?.map(data => reportType === "SURVEY_ATTEMPT_REPORT" ? data.surveyName : data.menteeName)
            const totalAttempts = chartData.datasets[0];
            const highRisk = chartData.datasets[1];
            const lowRisk = chartData.datasets[2];
            const good = chartData.datasets[3];
            const resultna = chartData.datasets[4];
            totalAttempts.data = data?.map(surveyData => surveyData.totalCount)
            highRisk.data = data?.map(surveyData => surveyData.highRiskCount)
            lowRisk.data = data?.map(surveyData => surveyData.lowRiskCount)
            good.data = data?.map(surveyData => surveyData.goodRiskCount)
            resultna.data = data?.map(surveyData => surveyData.noResultCount)
            setChartData({
                ...chartData,
                labels: chartLabels,
                datasets: [totalAttempts, highRisk, lowRisk, good, resultna]
            })

        }
    }, [data])


    // useEffect(() => {
    //     if (isShareReport && reports.length === 2) {
    //         const chartElement = document.querySelector('.member_attempt_summary_graph_download_class');
    //
    //         if (chartElement) {
    //             html2canvas(chartElement).then((canvas) => {
    //                 canvas.toBlob((blob) => {
    //                     setReports([...reports, { blob: blob, name: 'member_attempt_summary_report.png' }]);
    //                 });
    //             });
    //         }
    //     }
    // }, [isShareReport, reports]);

    return (
        <>

            <div className={"mt-2"} style={{width:'100%'}}>
                {/*{*/}
                {/*    !isDataLoading && <div className={"user-attempt-graph-legends-outer"}>*/}
                {/*        <div className={"user-attempt-graph-legend-outer user-attempt-graph-legend-tot-attempt-outer"}>*/}
                {/*            <div className={"legend-color user-attempt-graph-legend-tot-attempt-color"}/>*/}
                {/*            <div className={"legend-txt user-attempt-graph-legend-tot-attempt-text"}>Total Attempt</div>*/}
                {/*        </div>*/}
                {/*        <div className={"user-attempt-graph-legend-outer user-attempt-graph-legends-stag-outer"}>*/}
                {/*            <div className={"legend-color user-attempt-graph-legends-stag-color"}/>*/}
                {/*            <div className={"legend-txt user-attempt-graph-legends-stag-text"}>Stagnating</div>*/}
                {/*        </div>*/}
                {/*        <div className={"user-attempt-graph-legend-outer user-attempt-graph-legends-gro-outer"}>*/}
                {/*            <div className={"legend-color user-attempt-graph-legends-gro-color"}/>*/}
                {/*            <div className={"legend-txt user-attempt-graph-legends-gro-text"}>Growing</div>*/}
                {/*        </div>*/}
                {/*        <div className={"user-attempt-graph-legend-outer user-attempt-graph-legends-mat-outer"}>*/}
                {/*            <div className={"legend-color user-attempt-graph-legends-mat-color"}/>*/}
                {/*            <div className={"legend-txt user-attempt-graph-legends-mat-text"}>Maturing</div>*/}
                {/*        </div>*/}
                {/*        <div className={"user-attempt-graph-legend-outer user-attempt-graph-legends-res-na-outer"}>*/}
                {/*            <div className={"legend-color user-attempt-graph-legends-res-na-color"}/>*/}
                {/*            <div className={"legend-txt user-attempt-graph-legends-res-na-text"}>Result N/A</div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}

                <div className={""}

                    style={{width:getGraphWidth(data?.length,window.innerWidth) }}>
                    {
                        (isDataLoading) ? <div className={"bar-graph-report-loader-outer"}><Loader></Loader></div> :
                            <div className={"bar-height"}>
                                <Bar  id={graphId} className={"bar-chart  member_attempt_summary_graph_download_class"} data={chartData} options={chartOptions}/>
                            </div>
                    }
                </div>

            </div>
        </>
    )
}
export default Graphs;