import './Header.css'
import {
    getDataFromLocalStorage,
    getInitials, getRandomColor,
    isUnauthorized, showToast
} from "../../helpers/utils";

import AddReflectionWindow from "./AddReflectionWindow";
import React, {useEffect, useState} from "react";
import {hasPermission, Permissions} from "../../helpers/accessControl";
import "../layout/AddReflectionWindow.css"
import ProfilePicPlaceHolder from "../commons/ProfilePicPlaceHolder";
import {useDispatch, useSelector} from "react-redux";
import {setLoggedInUserName} from "../../reduxStore/globalKeysSlice/globalKeysSlice";
import {useNavigate} from "react-router-dom";
import jwtDecode from "jwt-decode";
import {EXPONENT_SURVEY, NoReflectionToAttempt,  PulsePlans} from "../../helpers/constants";
import {
    getAllSurveysWithoutPaginationForAddReflectionBtn
} from "../../reduxStore/surveySlices/surveySlices";
import {TailSpinLoader} from "./Loader";
import UserProfileWindow from "./UserProfileWindow";
import {getOrganisationPreferenceByUserId} from "../../reduxStore/orgSlices/orgSlices";


const UserInfo = ({showAddReflectionBox=true,showProfilePicPlaceholder=true}) => {
    const [showAddReflectionWindow, setShowAddReflectionWindow] = useState(false)
    const [profilePlaceHolderColor,setProfilePlaceHolderColor]=useState(getRandomColor())
    const [showUserProfileWindow, setShowUserProfileWindow] = useState(false)
    const [data, setData] = useState(null)
    const [colorName, setColorName] = useState(null)
    const hideSidebar = useSelector(state => state.globalKeys.toggleSidebar)
    const fullName = useSelector(state => state.globalKeys.loggedInUserFullName)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSurveyAPIDispatchedInHeader, setIsSurveyAPIDispatchedInHeader] = useState(false)
    const [updateLocalStorage, setUpdateLocalStorage] = useState(false)
    const survey = useSelector(state => state.survey.getAllSurveysWithoutPaginationForAddReflectionBtnReducer)
    const [userCurrentPlan, setUserCurrentPlan] = useState(jwtDecode(getDataFromLocalStorage("token")).planName)

    const org = useSelector(state => state.org)


    useEffect(() => {
        dispatch(setLoggedInUserName((getDataFromLocalStorage("loggedInUserInfo")?.lastName === "" || getDataFromLocalStorage("loggedInUserInfo")?.lastName === undefined) ? getDataFromLocalStorage("loggedInUserInfo").firstName : getDataFromLocalStorage("loggedInUserInfo").firstName + " " + getDataFromLocalStorage("loggedInUserInfo").lastName))
        setUpdateLocalStorage(true)
        if(data===null || colorName===null){
            dispatch(getOrganisationPreferenceByUserId(getDataFromLocalStorage("loggedInUserInfo").orgId))
        }

    }, [])

    const redirectUserToAttemptDefaultReflection = () => {
        dispatch(getAllSurveysWithoutPaginationForAddReflectionBtn()).then(res => {
            setIsSurveyAPIDispatchedInHeader(false)
            if (isUnauthorized(res)) {
                navigate("/")
            } else if (res.meta.requestStatus === "fulfilled") {
                const defaultReflection = res.payload?.data?.filter((survey) => survey.name === EXPONENT_SURVEY)
                if (res.payload?.data.length === 0 || defaultReflection?.length === 0) {
                    showToast(NoReflectionToAttempt,"error")
                } else {
                    navigate(`/members/answerReflection/${jwtDecode(getDataFromLocalStorage("token")).userid}/${defaultReflection[0]?.surveyId}`)
                }

            }
        })

    }

    useEffect( ()=>{
        if(org?.getOrganisationPreferenceByUserIdReducer?.data !== undefined){
            setData(org?.createOrganisationPreferenceReducer?.data?.logo)
            setColorName(org?.createOrganisationPreferenceReducer?.data?.colorName)
        }

    },[org?.getOrganisationPreferenceByUserIdReducer?.data])

    useEffect( ()=>{
        if( data != null){
            localStorage.setItem("logo",data)
        }
        if(colorName != null){
            localStorage.setItem("color",colorName)
        }

        },[data,colorName])

    return (
        <>






                <div className="user_info">
                    {
                        showAddReflectionBox &&  hasPermission([Permissions.USER_READ, Permissions.REFLECTION_READ, Permissions.ATTEMPT_REFLECTION_READ], true) &&
                        <div className={"add-reflection-btn-outer"}>
                            <button
                                disabled={userCurrentPlan === PulsePlans.INDIVIDUAL.value && survey.loading && isSurveyAPIDispatchedInHeader}
                                id={'addReflectionModalButton'} style={{background : localStorage.getItem("color") === null || localStorage.getItem("color") === 'undefined' ? "":localStorage.getItem("color")} } className={"add-reflection-btn cmn_background_color"}
                                onClick={() => {
                                    if (userCurrentPlan === PulsePlans.INDIVIDUAL.value) {
                                        setIsSurveyAPIDispatchedInHeader(true)
                                        redirectUserToAttemptDefaultReflection()
                                    } else {
                                        setShowAddReflectionWindow(!showAddReflectionWindow)
                                    }

                                }}>Add Reflection
                                {
                                    userCurrentPlan === PulsePlans.INDIVIDUAL.value && survey.loading && isSurveyAPIDispatchedInHeader &&
                                    <TailSpinLoader/>
                                }
                            </button>
                        </div>
                    }

                    {
                        showProfilePicPlaceholder && <ProfilePicPlaceHolder backColor={profilePlaceHolderColor} id={"userProfileWindowButton"} onClick={() => {
                            setShowUserProfileWindow(true)

                        }} className={" cursor-pointer"} letters={getInitials(fullName)}/>
                    }

                </div>

                {
                    showAddReflectionWindow && <AddReflectionWindow setShow={setShowAddReflectionWindow}/>
                }
                {
                    showUserProfileWindow && <UserProfileWindow profilePicBackGroundColor={profilePlaceHolderColor} setShow={setShowUserProfileWindow}/>
                }





        </>
    )
}

export default UserInfo;