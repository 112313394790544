import {useNavigate} from 'react-router-dom';
import "./Surveys.css"
import {hasPermission, Permissions, roleHasAccess, Roles} from "../../helpers/accessControl";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import filter_icon from "../../images/filter_icon.svg";
import search_icon from "../../images/search_icon.svg";
import {
    bulkSurveyDelete,
    deleteSurveyById,
    disableSurveyById,
    isAttemptExistsForSurvey,
    searchSurveys,
    unAssignReflection,
    unAssignSurveyByOrgAdmin
} from "../../reduxStore/surveySlices/surveySlices";
import {
    BulkDeleteIcon,
    createOptionListForSelectTag,
    formatDate,
    getDataFromLocalStorage,
    handleApiResponse,
    isUnauthorized,
    tableCustomStyling,
    TableSkeletonLoading,
    trim
} from "../../helpers/utils";
import Pagination from "../commons/Pagination";
import {
    AccessDenied,
    AccessDeniedMsg,
    DisabledModifyReflectionBTNText,
    PulsePlans,
    Survey, UnassignReflectionConfirmationMsg
} from "../../helpers/constants";
import image from "../../images/CrossIcon2.svg"
import ErrorPage from "../errorPage/ErrorPage";
import ConfirmationModel from "../commons/ConfirmationModel";
import DataTable from "react-data-table-component";
import {Tooltip as ReactTooltip} from "react-tooltip";
import jwtDecode from "jwt-decode";
import Select from "react-select";
import AssignReflection from "./assignReflectionModal/AssignReflection";
import {RiUserSharedLine} from "react-icons/ri";
import {getAllOrgAsList} from "../../reduxStore/orgSlices/orgSlices";
import AssignedReflectionToSubOrg from "./assignReflectionModal/AssignedReflectionToSubOrg";
import {SearchFilterModal} from "../commons/SearchFilterModal";
import UserInfo from "../layout/UserInfo";
import {FiTrash2} from "react-icons/fi";
import {GoArrowSwitch, GoPencil} from "react-icons/go";
import MoveMemberAndOrgModal from "../members/modal/MoveMemberAndOrgModal";
import {FaEye} from "react-icons/fa";

const Surveys = ({isSubOrg = false, subOrgId}) => {

    const loggedInUserInfo = getDataFromLocalStorage("loggedInUserInfo")
    const token = jwtDecode(getDataFromLocalStorage("token"))
    const isOrgLevelSys = token.orgLevel === "SYSTEM"
    const isRoleOrgAdmin = token.roles.includes(Roles.ORG_ADMIN) || token.roles.includes(Roles.SUB_ORG_ADMIN)
    const isRoleManager = token.roles.includes(Roles.MANAGER)

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [timer, setTimer] = useState(null)
    const [triggerSearchApi, setTriggerSearchApi] = useState(true);
    const [modal, setModal] = useState({
        showSearchFilterModal: false,
        showDeleteReflectionConfirmationModal: false,
        showDisableReflectionConfirmationModal: false,
        showAssignReflectionModal: false,
        showAssignReflectionToSubOrg: false,
        showUnassignReflectionConfirmationModal: false,
        showMoveReflectionModal: false,
    });
    const [searchQuery, setSearchQuery] = useState({
        surveyName: null,
        tag: "",
        enable: "",
        ...(isSubOrg ? {subOrgId: subOrgId} : {}),
        // If is sys admin or sys manager then required orgId and for first search it is always search for its own org by default
        ...(isOrgLevelSys && (isRoleOrgAdmin || isRoleManager) && !isSubOrg ? {
            org: {
                label: "Organization...",
                value: token?.orgId
            }
        } : {})
    })
    const [sortCriteria, setSortCriteria] = useState({
        sortBy: "createdAt",
        sortOrder: "desc",
    })
    const [pageNumber, setPageNumber] = useState(1);

    const [surveyId, setSurveyId] = useState(null);
    const [selectedReflectionToUnAssign, setSelectedReflectionToUnAssign] = useState(null)
    const [unAssignReflectionId, setUnAssignReflectionId] = useState(null)
    const [selectCheckBoxItems, setSelectCheckBoxItems] = useState([]);
    const [showBulkDeleteConfirmationModal, setShowBulkDeleteConfirmationModal] = useState(false);

    const OrgListApi = useSelector(state => state.org.getAllOrgAsListReducer);
    const searchSurveyApi = useSelector(state => state.survey.getSearchSurveyDataReducer)
    const deleteSurveyApi = useSelector(state => state.survey.deleteSurveyByIdDataReducer)
    const bulkDeleteSurveyApi = useSelector(state => state.survey.bulkDeleteSurveysDataReducer)
    const disableSurveyApi = useSelector(state => state.survey.disableSurveyByIdDataReducer)
    const unAssignReflectionApi = useSelector(state => state.survey.unAssignReflectionReducer)

    useEffect(() => {
        // Role should be sys admin or sys manager and no sub org
        if (isOrgLevelSys && (isRoleManager || isRoleOrgAdmin) && !isSubOrg) {
            dispatch(getAllOrgAsList()).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (!isSubOrg) {
                    const onSuccess = () => {
                        const orgInfo = res.payload.data?.filter(org => org.orgId === token?.orgId)[0]
                        setSearchQuery({...searchQuery, org: {label: orgInfo.name, value: orgInfo.orgId}})
                    }
                    handleApiResponse(res, onSuccess)
                }
            })
        }

    }, [])
    useEffect(() => {
        if (hasPermission([Permissions.REFLECTION_READ], true) && triggerSearchApi) {
            setTriggerSearchApi(false)
            setSelectCheckBoxItems([])
            const updatedSearchQuery = {
                ...searchQuery,
                ...sortCriteria,
                page: pageNumber,
                orgId: searchQuery?.org?.value,
                enable: searchQuery?.enable?.value
            }
            dispatch(searchSurveys(updatedSearchQuery)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            });
        }
    }, [triggerSearchApi])
    useEffect(() => {
        if (searchQuery.surveyName !==null) {
            if (timer) {
                clearTimeout(timer)
            }
            setTimer(setTimeout(() => {
                setTriggerSearchApi(true)
            }, 1000))

        }
    }, [searchQuery.surveyName])

    const handleSearchQueryChange = (e) => {
        const {name, value} = e.target
        setSearchQuery({
            ...searchQuery,
            [name]: value
        })
    }
    const handleSort = (column, sortDirection,) => {
        setSortCriteria({
            sortBy: column.sortField,
            sortOrder: sortDirection,
        })
        setTriggerSearchApi(true)
    };
    const setPageNumberAndTriggerApi = (page) => {
        setPageNumber(page)
        setTriggerSearchApi(true)
    }
    const handleDisableSurvey = () => {
        if (surveyId != null && surveyId !== "") {
            dispatch(disableSurveyById({surveyId: surveyId, setSurveyId: setSurveyId})).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                handleApiResponse(res, () => {
                    setModal({
                        ...modal,
                        showDisableReflectionConfirmationModal: false
                    })
                    setTriggerSearchApi(true)
                })
            })
        }
    }
    const handleDeleteSurvey = (isBulkDelete = false) => {
        if (surveyId != null && surveyId !== "") {
            dispatch(deleteSurveyById({surveyId, setSurveyId})).then((response) => {
                if (isUnauthorized(response)) {
                    navigate("/")
                }
                const onSuccess = () => {
                    setModal({
                        ...modal,
                        showDeleteReflectionConfirmationModal: false
                    })
                    setTriggerSearchApi(true)
                }
                handleApiResponse(response, onSuccess)
            });

        } else if (isBulkDelete && selectCheckBoxItems.length > 0) {
            dispatch(bulkSurveyDelete({selectCheckBoxItems, setSelectCheckBoxItems})).then((response) => {
                if (isUnauthorized(response)) {
                    navigate("/")
                }
                handleApiResponse(response, () => {
                    setShowBulkDeleteConfirmationModal(false)
                    setTriggerSearchApi(true)
                })
            });

        }
    }
    const checkAttemptsForSurvey = (surveyId) => {
        dispatch(isAttemptExistsForSurvey(surveyId)).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
            handleApiResponse(res, () => {
                setModal({
                    ...modal,
                    showDisableReflectionConfirmationModal: res.payload.data,
                    showDeleteReflectionConfirmationModal: !res.payload.data
                })
            })
        })

    }
    const handleUnAssignSurvey = () => {
        if (isOrgLevelSys) {
            dispatch(unAssignReflection(selectedReflectionToUnAssign)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                handleApiResponse(res, () => {
                    setModal({
                        ...modal,
                        showUnassignReflectionConfirmationModal: false
                    })
                    setTriggerSearchApi(true)
                    setSelectedReflectionToUnAssign(null)
                })
            })
        } else {
            const data = {surveyId: unAssignReflectionId, subOrgId: subOrgId}
            dispatch(unAssignSurveyByOrgAdmin(data)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                handleApiResponse(res, () => {
                    setModal({
                        ...modal,
                        showUnassignReflectionConfirmationModal: false
                    })
                    setTriggerSearchApi(true)
                    setUnAssignReflectionId(null)
                })
            })
        }

    }

    let searchFiltersTags = [
        {
            shouldShow: isOrgLevelSys && (isRoleOrgAdmin || isRoleManager) && !subOrgId,
            tagView: < Select
                className="ms-0 members-filter-role react-select-container"
                classNamePrefix="ms-0 react-select"
                isDisabled={OrgListApi?.loading}
                value={searchQuery?.org}
                options={createOptionListForSelectTag(OrgListApi?.data, "name", "orgId")}
                onChange={(selectedOrg) => {
                    if (searchQuery?.org?.value !== selectedOrg?.value) {
                        handleSearchQueryChange({target: {name: "org", value: selectedOrg}})
                    }
                }}
                isClearable={true}
                placeholder={"Organization..."}/>

        },
        {
            tagView: <div className={"filter_input_wrapper "}>
                <input type={"text"} className={"ms-0 form-control search-box-input-tag"}
                       value={searchQuery.tag}
                       onChange={handleSearchQueryChange}
                       name={"tag"}
                       placeholder={"Tag..."}/>
                <div className={"cross-btn-outer"}>
                    <img src={image}
                         className={"survey-search-clr-btn cursor-pointer"}
                         onClick={() => {
                             handleSearchQueryChange({target: {name: "tag", value: ""}})
                         }}/>
                </div>
            </div>

        },
        {
            tagView: <Select
                className="search-box-input-active-inactive ms-0 react-select-container"
                classNamePrefix="survey-search-box-input-active-inactive react-select"
                value={searchQuery?.enable}
                options={[
                    {value: true, label: "Active"},
                    {value: false, label: "In Active"}
                ]}
                onChange={(selectedOption) => {
                    handleSearchQueryChange({target: {name: "enable", value: selectedOption}})
                }}
                isClearable={true}
                placeholder={"Active/In Active..."}/>
        }
    ]
    const columns = [
        {
            name: <div className={"d-flex"}>
                <input type={"checkbox"}
                       disabled={!(hasPermission([Permissions.REFLECTION_DELETE], true) && (roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER, Roles.SUB_ORG_ADMIN]) || roleHasAccess([Roles.MENTOR, Roles.REFLECTION_WRITER])))}
                       checked={
                           Array.isArray(searchSurveyApi?.data?.data) &&
                           searchSurveyApi?.data?.data.length > 0 &&
                           selectCheckBoxItems.length > 0 &&
                           searchSurveyApi?.data?.data?.filter(e =>
                               hasPermission([Permissions.REFLECTION_DELETE], true) &&
                               (
                                   roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER, Roles.SUB_ORG_ADMIN]) ?
                                       loggedInUserInfo.orgId === e.orgId :
                                       roleHasAccess([Roles.MENTOR, Roles.REFLECTION_WRITER]) ?
                                           loggedInUserInfo.userId === e.createdBy :
                                           false
                               )
                           ).every(id => selectCheckBoxItems.includes(id.surveyId))
                       }
                       onClick={(e) => {
                           e.stopPropagation();
                           if (searchSurveyApi?.data?.data) {
                               let surveyIds = [];

                               if (hasPermission([Permissions.REFLECTION_DELETE], true)) {
                                   if (roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER, Roles.SUB_ORG_ADMIN])) {
                                       surveyIds = searchSurveyApi.data.data.filter(e => loggedInUserInfo.orgId === e.orgId).map(c => c.surveyId);
                                   } else if (roleHasAccess([Roles.MENTOR, Roles.REFLECTION_WRITER])) {
                                       surveyIds = searchSurveyApi.data.data.filter(e => loggedInUserInfo.userId === e.createdBy).map(c => c.surveyId);
                                   }
                               }
                               if (surveyIds.every(id => selectCheckBoxItems.includes(id))) {
                                   setSelectCheckBoxItems([]);
                               } else {
                                   setSelectCheckBoxItems(surveyIds);
                               }
                           }


                       }}/> <span className={"ms-2 bulk_delete_Main_header"}> Name </span></div>,
            selector: row => {
                trim(row.name, 30)
            },
            sortField: 'name',
            sortable: true,
            style: {
                fontFamily: 'General Sans Medium',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            },
            width: searchSurveyApi?.loading ? "" : "25%",
            cell: row => (
                <div className={'d-flex '}>

                    <div>
                        <input


                            disabled={!(hasPermission([Permissions.REFLECTION_DELETE], true) &&
                                (roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER, Roles.SUB_ORG_ADMIN]) ? loggedInUserInfo.orgId === row.orgId : (roleHasAccess([Roles.MENTOR, Roles.REFLECTION_WRITER]) ? loggedInUserInfo.userId === row.createdBy : false)))}
                            checked={Array.isArray(selectCheckBoxItems) && selectCheckBoxItems.length > 0 && selectCheckBoxItems.includes(row?.surveyId)}
                            type={"checkbox"}
                            onClick={(e) => {
                                if (!selectCheckBoxItems.includes(row?.surveyId)) {
                                    setSelectCheckBoxItems([...selectCheckBoxItems, row?.surveyId]);
                                } else {
                                    const updatedItems = selectCheckBoxItems.filter(item => item !== row?.surveyId);
                                    setSelectCheckBoxItems(updatedItems);
                                }
                            }}
                        />
                    </div>


                    <div className={""}>
                    <span title={row.name} className={"ms-2 name_title"}>
                       {trim(row.name, 50)}</span>
                    </div>
                </div>


            ),

        },
        {
            name: "Created Date",
            selector: row => row.createdAt,
            sortField: 'createdAt',
            sortable: true,
            cell: row => <div>{formatDate(row.createdAt)}</div>,
            style: {
                opacity: ".7"
            },

        },
        {
            name: "Owner",
            selector: row => row.owner,
            style: {
                opacity: ".7"
            },

        },
        {
            name: "Status",
            selector: row => row.status,
            sortField: 'status',
            sortable: true,
            cell: row => (
                <div
                    className={row.status === Survey.SURVEY_STATUS_DRAFT ? "status-container inviteStatus_disable text-end" : "status-container inviteStatus text-end"}>
                    <span
                        className="badge badge-primary ">{row.status === Survey.SURVEY_STATUS_DRAFT ? "Draft" : "Published"}</span>
                </div>
            ),


        },
        {
            name: "Active",
            selector: row => row.enable,
            cell: row => (
                row.enable ?
                    <i title="Enabled"
                       className="fa fa-check-circle enabled-data "/>
                    :
                    <i title="Disabled"
                       className="fa fa-times-circle disabled-data "/>
            ),
            width: searchSurveyApi?.loading ? "" : "10%",

        },
        hasPermission([Permissions.REFLECTION_WRITE, Permissions.REFLECTION_READ, Permissions.REFLECTION_DELETE]) &&
        {
            name: "Action",
            cell: row => (
                <>
                    {
                        hasPermission([Permissions.REFLECTION_READ], true) &&
                        <FaEye size={18}
                               className={"me-3 cursor-pointer"}
                               title={"View Reflection"}
                               onClick={() => {
                                   navigate(`/view/reflection/${row.surveyId}`)
                               }}
                        />
                    }
                    <div
                        data-tooltip-id="DeleteReflectionBTN"
                        data-tooltip-content={
                            (
                                row.enable &&
                                ((roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER, Roles.SUB_ORG_ADMIN, Roles.MENTOR, Roles.REFLECTION_WRITER]) && isOrgLevelSys) ||
                                    (loggedInUserInfo.orgId === row.orgId && (roleHasAccess([Roles.ORG_ADMIN, Roles.SUB_ORG_ADMIN, Roles.MANAGER, Roles.MENTOR]) || loggedInUserInfo.userId === row.createdBy))
                                )
                            ) ? "" : (roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) && (isOrgLevelSys) || (roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) && (token?.orgLevel === 'CUSTOMER') && (isSubOrg ? (row.orgId === subOrgId ? true : false) : row.orgId === loggedInUserInfo.orgId ? true : false)))
                                ? "" : DisabledModifyReflectionBTNText}
                        className={"status-container inviteStatus_disable text-end me-1 " + (roleHasAccess([Roles.ORG_ADMIN, Roles.SUB_ORG_ADMIN]) && loggedInUserInfo.userId === row.createdBy) ? "cursor-pointer" : " disable-trash-icon"}>

                        {
                            isSubOrg && (isRoleOrgAdmin || isRoleManager) && isOrgLevelSys &&
                            <RiUserSharedLine title={"Unassign Reflection "}
                                              onClick={() => {
                                                  if (row.createdBy === loggedInUserInfo.userId) {
                                                      setSelectedReflectionToUnAssign(row.surveyId)
                                                      setModal({
                                                          ...modal,
                                                          showUnassignReflectionConfirmationModal: true
                                                      })
                                                  }
                                              }}
                                              className={"unassign-icon me-2" + (row.createdBy === loggedInUserInfo.userId ? "" : " disable-unassign-icon")}/>
                        }
                        {
                            isSubOrg && (isRoleOrgAdmin || isRoleManager) && token?.orgLevel === "CUSTOMER" &&
                            <RiUserSharedLine title={"Unassign Reflection "}
                                              onClick={() => {
                                                  if (row.assignTo?.length > 0 && row.assignTo?.includes(subOrgId)) {
                                                      setUnAssignReflectionId(row.surveyId)
                                                      setModal({
                                                          ...modal,
                                                          showUnassignReflectionConfirmationModal: true
                                                      })
                                                  }
                                              }}
                                              className={"unassign-icon me-2" + (row.assignTo?.length > 0 && row.assignTo?.includes(subOrgId) ? "" : " disable-unassign-icon")}/>
                        }

                        {
                            (isRoleOrgAdmin || isRoleManager) &&
                            <>

                                {
                                    hasPermission([Permissions.REFLECTION_WRITE], true) &&
                                    <GoPencil size={18}
                                              className={"" + (row.orgId === loggedInUserInfo.orgId ? "" : " disable-unassign-icon")}
                                              title={"Edit Reflection"}
                                              onClick={() => {
                                                  if (loggedInUserInfo.orgId === row.orgId) {
                                                      navigate(`/reflections/${row.surveyId}`)
                                                  }
                                              }}
                                    />
                                }

                                {
                                     isOrgLevelSys &&
                                    <GoArrowSwitch size={18} className={"ms-3 me-2"} title={"Move Reflection"}
                                                   onClick={() => {
                                                       setModal({
                                                           ...modal,
                                                           showMoveReflectionModal: true
                                                       })
                                                       setSurveyId(row.surveyId)
                                                   }}/>
                                }
                                {
                                    token?.orgLevel === 'CUSTOMER' &&
                                    <GoArrowSwitch size={18}
                                                   className={"ms-3 me-2 " + (isSubOrg ? (row.orgId === subOrgId ? "" : "disable-unassign-icon") : row.orgId === loggedInUserInfo.orgId ? "" : "disable-unassign-icon")}
                                                   title={"Move Reflection"}
                                                   onClick={() => {
                                                       setModal({
                                                           ...modal,
                                                           showMoveReflectionModal: true
                                                       })
                                                       setSurveyId(row.surveyId)
                                                   }}/>
                                }

                                {
                                    hasPermission([Permissions.REFLECTION_DELETE], true) &&
                                    <>
                                        <FiTrash2 size={18}
                                                  className={"ms-2 " + (row.orgId === loggedInUserInfo.orgId ? "" : " disable-unassign-icon")}
                                                  title={"Delete Reflection"}
                                                  onClick={() => {
                                                      if ( loggedInUserInfo.orgId === row.orgId) {
                                                          checkAttemptsForSurvey(row.surveyId)
                                                          setSurveyId(row.surveyId)
                                                      }
                                                  }}
                                        />
                                    </>

                                }
                            </>
                        }

                        {
                            roleHasAccess([Roles.MENTOR]) &&
                            <>

                                {
                                    hasPermission([Permissions.REFLECTION_WRITE], true) &&
                                    <GoPencil size={18}
                                              className={row.createdBy === loggedInUserInfo?.userId ? "" : " disable-unassign-icon"}
                                              title={"Edit Reflection"}
                                              onClick={() => {
                                                  if (row.createdBy === loggedInUserInfo?.userId) {
                                                      navigate(`/reflections/${row.surveyId}`)
                                                  }
                                              }}
                                    />
                                }
                                {
                                    hasPermission([Permissions.REFLECTION_DELETE], true) &&
                                    <FiTrash2 size={18}
                                              className={"ms-2" + (row.createdBy === loggedInUserInfo.userId ? "" : " disable-unassign-icon")}
                                              onClick={() => {
                                                  if (loggedInUserInfo.userId === row.createdBy) {
                                                      checkAttemptsForSurvey(row.surveyId)
                                                      setSurveyId(row.surveyId)
                                                  }
                                              }}/>
                                }

                            </>
                        }


                    </div>
                    <ReactTooltip className={"ToolTip"} place={"bottom"} id="DeleteReflectionBTN"/>
                </>
            ),
        },

    ].filter(Boolean)


    return !hasPermission([Permissions.REFLECTION_READ], true) ?

        <ErrorPage value={403} description={AccessDenied} detailDesc={AccessDeniedMsg}/> :

        (

            <section>

                {
                    !isSubOrg &&
                    <>
                        <div className="cmn_header">
                            <h2 className="mediumFontSize  org-heading m-0">Reflections</h2> <UserInfo/>
                        </div>
                    </>
                }
                <div
                    className={"row main_content " + (isSubOrg ? "" : " box_design1")}>
                    <div className={"col-md-12 p-0"}>
                        <div>
                            <div className={isSubOrg ? " sub-org-survey-outer" : " survey_outer "}>
                                <div
                                    className={isSubOrg ? "sub-org-survey_wrapper" : " survey_wrapper  mb-2"}></div>
                                <>
                                    {
                                        token?.planName !== PulsePlans.INDIVIDUAL.value &&
                                        <div className={"search-box ms-1 me-1"}>
                                            <div
                                                className={"search-box-form search-box-form-reflection "}>
                                                <div className="d-flex col-12">
                                                    <div className="reflection_search_wrapper ">
                                                        <img src={search_icon} alt=""/>
                                                        <input type="text"
                                                               className="form-control"
                                                               placeholder="Search Reflection..."
                                                               onChange={handleSearchQueryChange}
                                                               value={searchQuery.surveyName}
                                                               name="surveyName"/>
                                                    </div>

                                                    <div className={"reflection_filters ms-auto"}>
                                                        {
                                                            Array.isArray(selectCheckBoxItems) && selectCheckBoxItems.length > 0 && (

                                                                <BulkDeleteIcon selectCheckBoxItems={selectCheckBoxItems}
                                                                                setShowBulkDeleteConfirmationModal={setShowBulkDeleteConfirmationModal}/>

                                                            )
                                                        }
                                                        <button type={"button"}
                                                                onClick={() => {
                                                                    setModal({
                                                                        ...modal,
                                                                        showSearchFilterModal: true
                                                                    })
                                                                }}>
                                                            <img src={filter_icon} alt=""/>Filter
                                                        </button>
                                                    </div>
                                                    <div
                                                        className={"ms-2  " + (isSubOrg ? "text-end" : " search-box-add-btn-outer")}>

                                                        {
                                                            hasPermission([Permissions.REFLECTION_CREATE], true) && (!isSubOrg && token?.planName !== PulsePlans.INDIVIDUAL.value) &&
                                                            <button
                                                                style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                className={'cmn_background_color survey_addnew_btn mediumFontSize ' + (isSubOrg ? " mb-2" : "")}
                                                                onClick={() => {
                                                                    navigate('/creationReflection')
                                                                }}
                                                            >Create Reflection</button>
                                                        }
                                                    </div>

                                                    {
                                                        isSubOrg && (isRoleOrgAdmin || isRoleManager) && isOrgLevelSys &&
                                                        <div className={"text-end "}>
                                                            <button
                                                                style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                className={'cmn_background_color survey_addnew_btn mediumFontSize '}
                                                                onClick={() => {
                                                                    setModal({
                                                                        ...modal,
                                                                        showAssignReflectionModal: true
                                                                    })
                                                                }}>Assign Reflection
                                                            </button>
                                                        </div>
                                                    }

                                                    {
                                                        isSubOrg && (isRoleOrgAdmin || isRoleManager) && token?.orgLevel === "CUSTOMER" &&
                                                        <div className={"text-end"}>

                                                            <button
                                                                style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                className={'cmn_background_color survey_addnew_btn mediumFontSize '}
                                                                onClick={() => {
                                                                    setModal({
                                                                        ...modal,
                                                                        showAssignReflectionToSubOrg: true
                                                                    })
                                                                }}>Assign Reflections
                                                            </button>
                                                        </div>
                                                    }

                                                </div>

                                            </div>

                                        </div>
                                    }</>

                                <div className={"custom_table colored_table"}>
                                    <DataTable
                                        className={searchSurveyApi?.data?.totalPages > 1 ? "table_design2" : "table_design1"}
                                        noDataComponent={<div className={"lightFontSize cmn_fontStyle mt-2 mb-2"}>No
                                            Reflection Found</div>}
                                        persistTableHead={true}
                                        responsive={true}
                                        progressPending={searchSurveyApi?.loading}
                                        progressComponent={<div className={"reflection-skeleton-loader w-100"}>
                                            <TableSkeletonLoading columns={columns.length || 0} rows={5}/></div>}
                                        customStyles={tableCustomStyling}
                                        columns={columns}
                                        data={searchSurveyApi?.data?.data}
                                        onSort={handleSort} sortServer={true}
                                    />
                                    {
                                        !searchSurveyApi?.loading && searchSurveyApi.data && searchSurveyApi?.data?.totalPages > 1 &&
                                        <Pagination className={isSubOrg ? "pagination-border" : ""}
                                                    totalPages={searchSurveyApi?.data?.totalPages}
                                                    pageNumber={searchSurveyApi?.data?.pageNumber}
                                                    setPageNumber={setPageNumberAndTriggerApi}/>
                                    }
                                </div>

                            </div>
                        </div>

                        {
                            modal.showDeleteReflectionConfirmationModal &&
                            <ConfirmationModel title={"Delete Reflection"}
                                               btnText={"Delete"}
                                               action={handleDeleteSurvey}
                                               show={modal.showDeleteReflectionConfirmationModal}
                                               bodyMessage={"Are you sure you want to delete this reflection? You cannot view or retrieve the data from this reflection once it is deleted. Are you sure you wish to proceed?"}
                                               setShow={(value) => {
                                                   setModal({
                                                       ...modal,
                                                       showDeleteReflectionConfirmationModal: value
                                                   })
                                               }}
                                               isLoading={deleteSurveyApi?.loading}
                                               showUserAcknowledgementCheckBox={true}
                                               userAcknowledgementCheckBoxText={"Yes, I understand"}
                            />
                        }

                        {
                            showBulkDeleteConfirmationModal &&
                            <ConfirmationModel title={"Delete Reflections"}
                                               btnText={"Delete"}
                                               action={() => handleDeleteSurvey(true)}
                                               show={showBulkDeleteConfirmationModal}
                                               bodyMessage={"Are you sure you want to delete all selected reflections? You cannot view or retrieve the data from these reflections once it is deleted. Are you sure you wish to proceed?"}
                                               setShow={setShowBulkDeleteConfirmationModal}
                                               isLoading={bulkDeleteSurveyApi?.loading}
                                               showUserAcknowledgementCheckBox={true}
                                               userAcknowledgementCheckBoxText={"Yes, I understand"}
                            />
                        }
                        {
                            modal.showDisableReflectionConfirmationModal &&
                            <ConfirmationModel title={"Disable Reflection"}
                                               btnText={"Disable"}
                                               action={handleDisableSurvey}
                                               show={modal.showDisableReflectionConfirmationModal}
                                               bodyMessage={"Deleting the reflection is not possible due to prior participant involvement. However, you can disable the reflection to prevent future attempts while preserving the existing data and insights."}
                                               setShow={(value) => {
                                                   setModal({
                                                       ...modal,
                                                       showDisableReflectionConfirmationModal: value
                                                   })
                                               }}
                                               isLoading={disableSurveyApi?.loading}
                                               showUserAcknowledgementCheckBox={true}
                                               userAcknowledgementCheckBoxText={"Yes, I Agree"}
                            />
                        }


                        {
                            modal.showMoveReflectionModal &&
                            <MoveMemberAndOrgModal show={modal.showMoveReflectionModal}
                                                   setShow={(value) => {
                                                       setModal({
                                                           ...modal,
                                                           showMoveReflectionModal: value
                                                       })
                                                   }}
                                                   rowId={surveyId}
                                                   userData={null} setUserData={null} query={null}
                                                   callApiFunction={() => {
                                                       setTriggerSearchApi(true)
                                                   }}
                                                   setQuery={null}
                                                   isSubOrg={isSubOrg} subOrgId={subOrgId}
                                                   title={"Move Reflection"}
                            />}

                        {
                            modal.showAssignReflectionModal &&
                            <AssignReflection
                                getUpdatedReflectionList={() => {
                                    setTriggerSearchApi(true)
                                }}
                                orgId={subOrgId}
                                show={modal.showAssignReflectionModal}
                                setShow={(value) => {
                                    setModal({
                                        ...modal,
                                        showAssignReflectionModal: value
                                    })
                                }}
                            />
                        }
                        {
                            modal.showAssignReflectionToSubOrg &&
                            <AssignedReflectionToSubOrg show={modal.showAssignReflectionToSubOrg}
                                                        setShow={(value) => {
                                                            setModal({
                                                                ...modal,
                                                                showAssignReflectionToSubOrg: value
                                                            })
                                                        }} subOrgId={subOrgId}
                            />
                        }
                        {
                            modal.showUnassignReflectionConfirmationModal &&
                            <ConfirmationModel title={"Unassign Reflection"}
                                               btnText={"Unassign"}
                                               action={handleUnAssignSurvey}
                                               show={modal.showUnassignReflectionConfirmationModal}
                                               bodyMessage={UnassignReflectionConfirmationMsg}
                                               setShow={(value) => {
                                                   setModal({
                                                       ...modal,
                                                       showUnassignReflectionConfirmationModal: value
                                                   })
                                               }}
                                               isLoading={unAssignReflectionApi?.loading}
                            />
                        }


                        {
                            modal.showSearchFilterModal &&
                            <SearchFilterModal title={"Reflection Filters"}
                                               show={modal.showSearchFilterModal}
                                               filtersTag={searchFiltersTags}
                                               applyFilter={triggerSearchApi}
                                               setApplyFilter={setTriggerSearchApi}
                                               resetFilters={() => setSearchQuery({
                                                   surveyName: searchQuery.surveyName,
                                                   tag: "",
                                                   enable: null,
                                                   orgId: "",
                                                   ...(isSubOrg ? {subOrgId: subOrgId} : {}),
                                                   ...(isOrgLevelSys && (isRoleOrgAdmin || isRoleManager) && !isSubOrg ? {org: null} : {})
                                               })}
                                               handleClose={() => {
                                                   setModal({
                                                       ...modal,
                                                       showSearchFilterModal: false
                                                   })
                                               }}
                                               setShow={(value) => {
                                                   setModal({
                                                       ...modal,
                                                       showSearchFilterModal: value
                                                   })
                                               }}
                            />
                        }


                    </div>

                </div>


            </section>

        )
}

export default Surveys;
