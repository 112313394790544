import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {Card} from "react-bootstrap";
import {
    defaultQuestionForReflection,
    exceptThisSymbols,
    Marks, OptionValueWeightError,
    OutOfRange,
    QuestionType,
    Survey
} from "../../helpers/constants";
import {
    getDataFromLocalStorage,
    getValueOrDefault,
    isNullOrEmpty,
    removeItemFromArrayAtIndex,
    trim
} from "../../helpers/utils";
import {hasPermission, Permissions, roleHasAccess, Roles} from "../../helpers/accessControl";
import jwtDecode from "jwt-decode";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";


const DraggableQuestions = ({
                                data,
                                questionsList,
                                setQuestionsList,
                                formType,
                                segmentType,
                                setSegmentType,
                                parentId,
                                reflectionSegments,
                                setReflectionSegments,
                                handleSelectedParentSection,
                                tags,
                                setTags,
                                setActiveQuestionIndex,
                                activeQuestionIndex,
                                newAddedQuestion,
                                setNewAddedQuestion
                            }) => {

    const [render, setRender] = useState("");
    const [surveyTags, setSurveyTags] = useState("");
    const [survey, setSurvey] = useState({
        name: "",
        planLevel: "",
        status: Survey.SURVEY_STATUS_DRAFT,
        description: "",
        tags: [],
        visibility: "TENANCY",
        questions: [],
    });
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (parentId !== null) {
            let updatedSegments = reflectionSegments.map((segment, i) => {
                if (segment.questionsList && Array.isArray(segment.questionsList)) {
                    let updatedQuestionsList = segment.questionsList.map((question, index) => {
                        return {
                            ...question,
                            segmentIndex: i
                        }
                    })

                    return {
                        ...segment,
                        questionsList: updatedQuestionsList
                    };
                }
                return segment;
            });
            setReflectionSegments(updatedSegments)
        }

    }, [parentId]);

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const updatedCharacters = [...questionsList];
        const [movedCharacter] = updatedCharacters.splice(result.source.index, 1);
        updatedCharacters.splice(result.destination.index, 0, movedCharacter);

        setQuestionsList(updatedCharacters);
    };

    const handleQuestionTypeChange = (questionIndex, e, segmentIndex) => {

        if (segmentIndex !== null) {
            const updatedSegments = reflectionSegments.map((segment, segIndex) => {
                if (segIndex === segmentIndex && segment.questionsList) {
                    const updatedQuestionsList = segment.questionsList.map((question, index) => {
                        if (index === questionIndex) {
                            return {
                                ...question,
                                [e.target.name]: e.target.value,
                                options: e.target.value === QuestionType.OPTION ? [{
                                    optionText: "",
                                    optionValue: null
                                }, {optionText: "", optionValue: null}, {
                                    optionText: "",
                                    optionValue: null
                                }] : [],
                                number: e.target.value === QuestionType.NUMBER ? {
                                    min: null,
                                    max: null,
                                    isMinLowestRank: true
                                } : null
                            };
                        }
                        return question;
                    });
                    return {
                        ...segment,
                        questionsList: updatedQuestionsList
                    };
                }
                return segment;
            });
            setReflectionSegments(updatedSegments)
        } else {
            const updatedQuestionsList = [...questionsList];
            updatedQuestionsList[questionIndex] = {
                ...updatedQuestionsList[questionIndex],
                [e.target.name]: e.target.value,
                options: e.target.value === QuestionType.OPTION ? [{
                    optionText: "",
                    optionValue: null
                }, {optionText: "", optionValue: null}, {
                    optionText: "",
                    optionValue: null
                }] : [],
                number: e.target.value === QuestionType.NUMBER ? {
                    min: null,
                    max: null,
                    isMinLowestRank: true
                } : null
            };
            setQuestionsList(updatedQuestionsList);
        }
    };

    const isReflectionEditable = (Id) => {
        if (formType === "Add") {
            return true
        } else if (formType === "Update") {
            return true
        } else if (formType === "Edit" && typeof Id === "undefined") {
            return true
        }
        return false
    }

    const checkBtnPermission = (Id) => {
        return ((formType === "Add" && hasPermission([Permissions.REFLECTION_CREATE], true)) || ((formType === "Update") && hasPermission([Permissions.REFLECTION_WRITE], true) && (data.orgId === jwtDecode(getDataFromLocalStorage("token"))?.orgId || roleHasAccess([Roles.ORG_ADMIN])))
            || (formType === "Edit" && data.enable && hasPermission([Permissions.REFLECTION_WRITE], true) && (data.orgId === jwtDecode(getDataFromLocalStorage("token"))?.orgId || roleHasAccess([Roles.ORG_ADMIN])) && typeof Id === "undefined" || Id === undefined)
        )
    };

    const handleRequiredChange = (e, questionIndex, segmentIndex) => {
        if (segmentIndex !== null) {
            const updatedSegments = reflectionSegments.map((segment, segIndex) => {
                if (segIndex === segmentIndex && segment.questionsList) {
                    const updatedQuestionsList = segment.questionsList.map((question, index) => {
                        if (index === questionIndex) {
                            return {
                                ...question,
                                [e.target.name]: e.target.checked
                            };
                        }
                        return question;
                    });
                    return {
                        ...segment,
                        questionsList: updatedQuestionsList
                    };
                }
                return segment;
            });
            setReflectionSegments(updatedSegments)

        } else {
            const updatedQuestionsList = [...questionsList];
            updatedQuestionsList[questionIndex] = {
                ...updatedQuestionsList[questionIndex],
                [e.target.name]: e.target.checked
            };
            setQuestionsList(updatedQuestionsList);
        }

    };

    const handleChangeQuestionText = (e, questionIndex, segmentIndex) => {
        if (segmentIndex !== null) {
            const updatedSegments = reflectionSegments.map((segment, segIndex) => {
                if (segIndex === segmentIndex && segment.questionsList) {
                    const updatedQuestionsList = segment.questionsList.map((question, index) => {
                        if (index === questionIndex) {
                            return {
                                ...question,
                                [e.target.name]: e.target.value,
                                isTextLimitExceeded: false
                            };
                        }
                        return question;
                    });
                    return {
                        ...segment,
                        questionsList: updatedQuestionsList
                    };
                }
                return segment;
            });
            setReflectionSegments(updatedSegments)
        } else {
            const updatedQuestionsList = [...questionsList];
            updatedQuestionsList[questionIndex] = {
                ...updatedQuestionsList[questionIndex],
                [e.target.name]: e.target.value,
                isTextLimitExceeded: false
            };
            setQuestionsList(updatedQuestionsList);
        }
    };

    const updateMinMaxNumber = (e, questionIndex, segmentIndex) => {
        if (segmentIndex !== null) {
            const updatedSegments = reflectionSegments.map((segment, segIndex) => {
                if (segIndex === segmentIndex && segment.questionsList) {
                    const updatedQuestionsList = segment.questionsList.map((question, index) => {
                        if (index === questionIndex) {
                            return {
                                ...question,
                                number: {
                                    ...question.number,
                                    [e.target.name]: e.target.value === "" ? null : e.target.value
                                }
                            };
                        }
                        return question;
                    });
                    return {
                        ...segment,
                        questionsList: updatedQuestionsList
                    };
                }
                return segment;
            });
            setReflectionSegments(updatedSegments);
        } else {
            const updatedQuestionsList = [...questionsList];
            updatedQuestionsList[questionIndex] = {
                ...updatedQuestionsList[questionIndex],
                number: {
                    ...updatedQuestionsList[questionIndex].number,
                    [e.target.name]: e.target.value === "" ? null : e.target.value
                }
            };
            setQuestionsList(updatedQuestionsList);
        }
    };

    const handleDeleteQuestion = (index, segmentIndex) => {
        if (segmentType === "LINEAR") {
            setActiveQuestionIndex({
                ...activeQuestionIndex,
                rowIndex: questionsList?.length === 1 ? null : 0,
                columnIndex: null
            })
            setTags(removeItemFromArrayAtIndex([...tags], index))
        }
        if (segmentType === "TABULAR") {
            setActiveQuestionIndex({
                rowIndex: null,
                columnIndex: null,
            })

            if (tags?.length > 0 && tags?.[parentId]?.[index] !== undefined && tags?.[parentId]?.[index] !== null && tags?.[parentId]?.[index] !== "") {
                let updatedTags = tags?.map(row => [...row]);
                updatedTags[parentId][index] = undefined
                setTags(updatedTags)
            }
        }
        if (segmentIndex !== null) {
            let shouldDeleteSegement = false;

            const updatedSegments = reflectionSegments.map((segment, segIndex) => {
                if (segIndex === segmentIndex && segment.questionsList) {
                    const updatedQuestionsList = segment.questionsList.filter((question, questionIndex) => questionIndex !== index);
                    shouldDeleteSegement = Array.isArray(updatedQuestionsList) && updatedQuestionsList.length === 0;
                    return {
                        ...segment,
                        questionsList: updatedQuestionsList
                    };
                }
                return segment;
            });

            if (Array.isArray(updatedSegments) && updatedSegments.length > 0 && shouldDeleteSegement) {


                let filteredSegments = updatedSegments.filter((segment, ind) => segmentIndex !== ind);

                if (filteredSegments.length === 1) {
                    setSegmentType("LINEAR");
                    const updatedQuestionsList = [...filteredSegments[0]?.questionsList]

                    if (updatedQuestionsList?.length > 0) {
                        updatedQuestionsList?.forEach((cur) => {
                            cur = {...cur, isShow: false}
                        })
                    }
                    handleSelectedParentSection(0, 0, [...updatedQuestionsList]);

                } else {
                    const updatedFilteredSegments = filteredSegments.map((segment, index) => ({
                        ...segment,
                        questionsList: segment.questionsList.map((question, questionIndex) => ({
                            ...question,
                            segmentIndex: index
                        }))
                    }));

                    setReflectionSegments(updatedFilteredSegments);
                }
            } else {
                setReflectionSegments(updatedSegments);
            }

        } else {
            let updatedQuestionsList = questionsList.filter((el, i) => {
                return i !== index;
            })
            if (Array.isArray(updatedQuestionsList) && updatedQuestionsList.length > 0) {
                if (updatedQuestionsList[0]?.hasOwnProperty('isShow')) {
                    updatedQuestionsList[0].isShow = true;
                }
            }
            setQuestionsList(updatedQuestionsList);
        }

    };

    const setOptionTextLimitExceeds = (index, optionIndex) => {
        const updatedQuestionsList = [...questionsList];
        const updatedOptions = [...updatedQuestionsList[index].options];
        updatedOptions[optionIndex] = {
            ...updatedOptions[optionIndex],
            isTextLimitExceeded: true
        };
        updatedQuestionsList[index] = {
            ...updatedQuestionsList[index],
            options: updatedOptions
        };
        setQuestionsList(updatedQuestionsList);
        setRender(new Date().getMilliseconds());
    }

    const handleAddOptions = (e, questionIndex, i, segmentIndex) => {

        if (segmentIndex !== null) {
            const newOption = {
                isTextLimitExceeded: false,
                optionText: "",
                optionValue: null
            };
            const updatedQuestionsList = reflectionSegments.map((segment, segIndex) => {
                if (segIndex === segmentIndex && segment.questionsList) {
                    return {
                        ...segment,
                        questionsList: segment.questionsList.map((question, index) => {
                            if (index === questionIndex) {
                                if (question.options.length >= 10) {
                                    toast.info(OutOfRange, {toastId: "toast"});
                                    return question; // Return unchanged question if the option limit is exceeded
                                }
                                return {
                                    ...question,
                                    options: [...question.options, newOption]
                                };
                            }
                            return question;
                        })
                    };
                }
                return segment;
            });
            setReflectionSegments(updatedQuestionsList);
        } else {
            const newOption = {
                isTextLimitExceeded: false,
                optionText: "",
                optionValue: null
            };
            const updatedQuestionsList = [...questionsList];
            updatedQuestionsList[questionIndex] = {
                ...updatedQuestionsList[questionIndex],
                options: [...updatedQuestionsList[questionIndex].options, newOption]
            };
            if (updatedQuestionsList[questionIndex].options.length > 10) {
                toast.info(OutOfRange, {toastId: "toast"})
            } else {
                setQuestionsList(updatedQuestionsList);
            }
        }


    };

    const handleRemoveOptions = (e, questionIndex, i, segmentIndex) => {
        if (segmentIndex !== null) {
            const updatedQuestionsList = reflectionSegments.map((segment, segIndex) => {
                if (segIndex === segmentIndex && segment.questionsList) {
                    return {
                        ...segment,
                        questionsList: segment.questionsList.map((question, index) => {
                            if (index === questionIndex) {
                                const updatedOptions = question.options.filter((option, optIndex) => optIndex !== i);
                                return {
                                    ...question,
                                    options: updatedOptions
                                };
                            }
                            return question;
                        })
                    };
                }
                return segment;
            });

            setReflectionSegments(updatedQuestionsList);
        } else {
            const updatedQuestionsList = [...questionsList];
            const updatedOptions = [...updatedQuestionsList[questionIndex].options];
            updatedOptions.splice(i, 1);
            updatedQuestionsList[questionIndex] = {
                ...updatedQuestionsList[questionIndex],
                options: updatedOptions,
            };
            setQuestionsList(updatedQuestionsList);
        }
    };

    const handleKeyDown = (e, index, segmentIndex) => {
        if (e.key === "Enter") {
            if (segmentIndex !== null) {
                const updatedSegments = reflectionSegments.map((segment, segIndex) => {
                    if (segIndex === segmentIndex && segment.questionsList) {
                        return {
                            ...segment,
                            questionsList: segment.questionsList.map((question, qIndex) => {
                                if (qIndex === index) {
                                    const newValue = e.target.value.trim();
                                    const updatedValues = [...question[e.target.name]];
                                    if (!updatedValues.includes(newValue) && newValue !== null && newValue !== undefined && newValue !== "") {
                                        updatedValues.push(newValue);
                                    }
                                    return {
                                        ...question,
                                        [e.target.name]: updatedValues
                                    };
                                }
                                return question;
                            })
                        };
                    }
                    return segment;
                });
                setReflectionSegments(updatedSegments);
                handleTagChange("", index)
            } else {
                if (index === -1) {
                    if (!isNullOrEmpty(e.target.value.trim()) && !survey[e.target.name].includes(e.target.value.trim())) {
                        setSurvey({...survey, [e.target.name]: [...survey[e.target.name], e.target.value.trim()]});
                    }
                     setSurveyTags("")
                } else {
                    const updatedQuestionsList = [...questionsList];
                    const question = {...updatedQuestionsList[index]};

                    if (!isNullOrEmpty(e.target.value.trim()) && !question[e.target.name].includes(e.target.value.trim())) {
                        question[e.target.name] = [...question[e.target.name], e.target.value.trim()];
                    }
                    updatedQuestionsList[index] = question;
                    setQuestionsList(updatedQuestionsList);
                    handleTagChange("", index)
                }
            }

        }
    };

    const handleOptionChange = (e, questionIndex, optionIndex, segmentIndex) => {
        const {name, value} = e.target;
        const numericValue = parseFloat(value);
        if (segmentIndex !== null) {
            const updatedSegments = reflectionSegments.map((segment, segIndex) => {
                if (segIndex === segmentIndex && segment.questionsList) {
                    const updatedQuestionsList = segment.questionsList.map((question, index) => {
                        if (index === questionIndex) {
                            const updatedOptions = question.options.map((option, optIndex) => {
                                if (optIndex === optionIndex) {
                                    if (name === "optionValue") {
                                        const numericValue = parseFloat(e.target.value);
                                        if ((numericValue > 0 && numericValue <= 100) || isNaN(numericValue)) {
                                            return {
                                                ...option,
                                                [e.target.name]: e.target.value,
                                                isTextLimitExceeded: false
                                            };
                                        } else {
                                            toast.info("Weight should be a value greater than 0 and less than or equal to 100. Please review and adjust your choices.", {toastId: "toast"});
                                            return option;
                                        }
                                    } else {
                                        return {
                                            ...option,
                                            [e.target.name]: e.target.value,
                                            isTextLimitExceeded: false
                                        };
                                    }
                                }
                                return option;
                            });
                            return {
                                ...question,
                                options: updatedOptions
                            };
                        }
                        return question;
                    });

                    return {
                        ...segment,
                        questionsList: updatedQuestionsList
                    };
                }
                return segment;
            });

            setReflectionSegments(updatedSegments);
        } else {
            //ToDo code optimize later
            if (name === "optionValue") {
                if ((numericValue > 0 && numericValue <= 100) || isNaN(numericValue)) {
                    const updatedQuestionsList = [...questionsList];
                    const updatedOptions = [...updatedQuestionsList[questionIndex].options];
                    updatedOptions[optionIndex] = {
                        ...updatedOptions[optionIndex],
                        [e.target.name]: e.target.value,
                        isTextLimitExceeded: false
                    };
                    updatedQuestionsList[questionIndex] = {
                        ...updatedQuestionsList[questionIndex],
                        options: updatedOptions
                    };
                    setQuestionsList(updatedQuestionsList);
                } else {
                    toast.info(`Weight  should be a value greater than 0 and less than or equal to 100. Please review and adjust your choices.`, {toastId: "toast"})
                }
            } else {
                const updatedQuestionsList = [...questionsList];
                const updatedOptions = [...updatedQuestionsList[questionIndex].options];
                updatedOptions[optionIndex] = {
                    ...updatedOptions[optionIndex],
                    [e.target.name]: e.target.value,
                    isTextLimitExceeded: false
                };
                updatedQuestionsList[questionIndex] = {
                    ...updatedQuestionsList[questionIndex],
                    options: updatedOptions
                };
                setQuestionsList(updatedQuestionsList);
            }
        }


    }

    const setQuestionTextLimitExceeds = (index) => {
        let updatedQuestionsList = [...questionsList];
        updatedQuestionsList[index] = {
            ...updatedQuestionsList[index],
            isTextLimitExceeded: true
        };
        setRender(new Date().getMilliseconds());
        setQuestionsList(updatedQuestionsList);
    };

    const handleTagChange = (value, index) => {
        const updatedTags = [...tags]
        if (segmentType === "LINEAR") {
            updatedTags[index] = value;
        }
        if (segmentType === "TABULAR") {
            let parentIndexArray = updatedTags[parentId] || []
            parentIndexArray[index] = value
            updatedTags[parentId] = parentIndexArray;
        }
        setTags(updatedTags)

    };

    const updateMinMaxValue = (e, questionIndex, segmentIndex) => {

        if (segmentIndex !== null) {
            const updatedSegments = reflectionSegments.map((segment, segIndex) => {
                if (segIndex === segmentIndex && segment.questionsList) {
                    const updatedQuestionsList = segment.questionsList.map((question, index) => {
                        if (index === questionIndex) {
                            return {
                                ...question,
                                number: {
                                    ...question.number,
                                    isMinLowestRank: e.target.value === 'true' ? true : false
                                }
                            };
                        }
                        return question;
                    });
                    return {
                        ...segment,
                        questionsList: updatedQuestionsList
                    };
                }
                return segment;
            });
            setReflectionSegments(updatedSegments);
        } else {
            const updatedQuestionsList = [...questionsList];
            updatedQuestionsList[questionIndex] = {
                ...updatedQuestionsList[questionIndex],
                number: {
                    ...updatedQuestionsList[questionIndex].number,
                    [e.target.name]: e.target.value === 'true' ? true : false
                }
            };
            setRender(new Date().getMilliseconds());
            setQuestionsList(updatedQuestionsList);
        }

    };

    const handleRemoveTag = (index, tagIndex, segmentId) => {
        if (segmentId !== null) {
            const updatedSegments = reflectionSegments.map((segment, segIndex) => {
                if (segIndex === segmentId && segment.questionsList) {
                    return {
                        ...segment,
                        questionsList: segment.questionsList.map((question, qIndex) => {
                            if (qIndex === index) {
                                const updatedTags = [...question.tags];
                                updatedTags.splice(tagIndex, 1);
                                return {
                                    ...question,
                                    tags: updatedTags
                                };
                            }
                            return question;
                        })
                    };
                }
                return segment;
            });
            setReflectionSegments(updatedSegments);
        } else {
            const updatedQuestionsList = [...questionsList];
            const question = {...updatedQuestionsList[index]};
            const tags = [...question.tags];
            tags.splice(tagIndex, 1);
            question.tags = tags;
            updatedQuestionsList[index] = question;
            setQuestionsList(updatedQuestionsList)
        }
    };

    const handleAddNewQuestion = () => {
        // Add new question and set it visibility to true
        setNewAddedQuestion({
            ...newAddedQuestion,
            sectionIndex: segmentType === "LINEAR" ? null : reflectionSegments.length - 1,
            show: true,
            question: {
                ...defaultQuestionForReflection,
                ...newAddedQuestion.question,
                segmentIndex: segmentType === "LINEAR" ? null : reflectionSegments.length - 1
            }
        })
        setActiveQuestionIndex({
            rowIndex: null,
            columnIndex: null
        })
        // Add other question and set it visibility to false
        if (segmentType === "LINEAR") {
            setQuestionsList(questionsList?.map((question) => {
                    return {...question, isShow: false};
                })
            )
        }
        if (segmentType === "TABULAR") {
            let updatedReflectionSegments = [...reflectionSegments]
            updatedReflectionSegments = updatedReflectionSegments?.map((cur, index) => {
                return {
                    ...cur,
                    isShow: false,
                    questionsList: cur.questionsList.map((question) => {
                        return {...question, isShow: false};
                    })
                };
            });
            setReflectionSegments(updatedReflectionSegments)
        }
    }
    const handleNewQuestionChange = (e, index) => {
        const {name, value} = e.target
        switch (name) {
            case "sectionIndex": {
                setNewAddedQuestion({
                    ...newAddedQuestion,
                    sectionIndex: value,
                    question:{...newAddedQuestion.question,segmentIndex:value}
                })
                break;
            }
            case "type": {
                setNewAddedQuestion({
                    ...newAddedQuestion,
                    question: {
                        ...newAddedQuestion.question,
                        [name]: value,
                        options: value === QuestionType.OPTION ? [
                            {optionText: "", optionValue: null},
                            {optionText: "", optionValue: null},
                            {optionText: "", optionValue: null}] : [],
                        number: value === QuestionType.NUMBER ? {
                            min: null,
                            max: null,
                            isMinLowestRank: true
                        } : null
                    }
                })
                break;
            }
            case "questionText": {
                setNewAddedQuestion({
                    ...newAddedQuestion,
                    question: {
                        ...newAddedQuestion.question,
                        ...(value.length <= 512 ? {[name]: value} : {}),
                        isTextLimitExceeded: value.length > 512
                    }
                })
                break;
            }
            case "optionText": {
                let updatedOptions = [...newAddedQuestion.question.options]
                updatedOptions[index] = {
                    ...updatedOptions[index],
                    ...(value.length <= 512 ? {[name]: value} : {}),
                    isTextLimitExceeded: value.length > 512
                }
                setNewAddedQuestion({
                    ...newAddedQuestion,
                    question: {
                        ...newAddedQuestion.question,
                        options: [...updatedOptions]
                    }
                })
                break;
            }
            case "optionValue": {
                if ((value > 0 && value <= 100) || value === "") {
                    let updatedOptions = [...newAddedQuestion.question.options]
                    updatedOptions[index] = {
                        ...updatedOptions[index],
                        [name]: value
                    }
                    setNewAddedQuestion({
                        ...newAddedQuestion,
                        question: {
                            ...newAddedQuestion.question,
                            options: [...updatedOptions]
                        }
                    })
                    break;
                }
                toast.info(OptionValueWeightError, {toastId: "toast"});
                break;
            }
            case "min": {
                if (value >= 0 || value === "") {
                    setNewAddedQuestion({
                        ...newAddedQuestion,
                        question: {
                            ...newAddedQuestion.question,
                            number: {...newAddedQuestion.question.number, [name]: value}
                        }
                    })
                    break;
                }
                break;
            }
            case "max": {
                setNewAddedQuestion({
                    ...newAddedQuestion,
                    question: {
                        ...newAddedQuestion.question,
                        number: {...newAddedQuestion.question.number, [name]: value}
                    }
                })
                break;
            }
            case "isMinLowestRank": {
                setNewAddedQuestion({
                    ...newAddedQuestion,
                    question: {
                        ...newAddedQuestion.question,
                        number: {...newAddedQuestion.question.number, [name]: value === 'true'}
                    }
                })
                break;
            }
            case "isRequired": {
                setNewAddedQuestion({
                    ...newAddedQuestion,
                    question: {
                        ...newAddedQuestion.question,
                        [name]: e.target.checked
                    }
                })
                break;
            }
            case "unSavedTags": {
                setNewAddedQuestion({
                    ...newAddedQuestion,
                    [name]: value
                })
                break;
            }
            case "tags": {
                if (e.key === "Enter") {
                    setNewAddedQuestion({
                        ...newAddedQuestion,
                        question: {
                            ...newAddedQuestion.question,
                            ...(newAddedQuestion.unSavedTags !== "" ? {[name]: [...newAddedQuestion?.question?.tags, newAddedQuestion.unSavedTags]} : {}),
                        },
                        unSavedTags: ""
                    })
                }
                break;
            }
            case "removeTag": {
                let updatedTags = [...newAddedQuestion.question.tags]
                updatedTags.splice(index, 1);
                setNewAddedQuestion({
                    ...newAddedQuestion,
                    question: {
                        ...newAddedQuestion.question,
                        tags: updatedTags
                    }
                })
                break;
            }
            case "addOption": {
                if (newAddedQuestion.question.options.length >= 10) {
                    toast.info(OutOfRange, {toastId: "toast"})
                    break;
                }
                setNewAddedQuestion({
                    ...newAddedQuestion,
                    question: {
                        ...newAddedQuestion.question,
                        options: [...newAddedQuestion.question.options, {
                            isTextLimitExceeded: false,
                            optionText: "",
                            optionValue: null
                        }]
                    }
                })
                break;
            }
            case "removeOption": {
                const updatedOptions = [...newAddedQuestion.question.options]
                updatedOptions.splice(index, 1);
                setNewAddedQuestion({
                    ...newAddedQuestion,
                    question: {
                        ...newAddedQuestion.question,
                        options: updatedOptions
                    }
                })
                break;
            }
        }
    }
    const resetNewAddedQuestion = () => {
        setNewAddedQuestion({
            unSavedTags: "",
            show: false,
            question: null,
            sectionIndex: null,
        })
    }
    const handleAddNewQuestionToList = () => {
        if (segmentType === "LINEAR") {
            let updatedQuestions = [...questionsList]
            updatedQuestions.push({
                ...newAddedQuestion.question
            });
            setQuestionsList(updatedQuestions);
            resetNewAddedQuestion();
        }
        if (segmentType === "TABULAR") {
            let updatedReflectionSegments = [...reflectionSegments]
            updatedReflectionSegments.forEach((cur, index) => {
                cur.questionsList = cur.questionsList.map((question) => {
                    return {...question, isShow: false};
                });
            });
            let updatedQuestionsList = updatedReflectionSegments[newAddedQuestion.sectionIndex]
            updatedQuestionsList = [...updatedQuestionsList.questionsList, newAddedQuestion.question]
            updatedReflectionSegments[newAddedQuestion.sectionIndex].questionsList = updatedQuestionsList
            updatedReflectionSegments[newAddedQuestion.sectionIndex].isShow = false
            setReflectionSegments(updatedReflectionSegments)
            resetNewAddedQuestion();
        }
    }

    const handleFocusedSection = (isFocused) => {
        setIsFocused(isFocused)
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="characters">
                {
                    (provided) => (
                        <ul className="inner_item" {...provided.droppableProps} ref={provided.innerRef}>
                            {
                                newAddedQuestion.show && <li>
                                    <div className={"d-flex"}>
                                        <Card
                                            style={{
                                                border: isFocused ? `1px solid ${localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}` : "",
                                            }}
                                            className={'question-card question-list mt-2 cmn_radius'}>
                                            <Card.Body>
                                                {
                                                    segmentType === "TABULAR" && <div className={""}>
                                                        <select
                                                            onFocus={() => {
                                                                handleFocusedSection(true)
                                                            }}
                                                            onBlur={() => {
                                                                handleFocusedSection(false)
                                                            }}
                                                            value={newAddedQuestion?.sectionIndex}
                                                            name={"sectionIndex"}
                                                            onChange={handleNewQuestionChange}
                                                            className={"question-type-dropdown gray_bg_color on_focus box-shadow-unset-on-focus"}>
                                                            {
                                                                reflectionSegments?.map((segment, index) => {
                                                                    return <option
                                                                        value={index}>{segment?.segmentName || `Section ${index + 1}`}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                }
                                                <div className={"mt-2"}>
                                                    <select
                                                        onFocus={() => {
                                                            handleFocusedSection(true)
                                                        }}
                                                        onBlur={() => {
                                                            handleFocusedSection(false)
                                                        }}
                                                        value={newAddedQuestion?.question?.type}
                                                        name={"type"}
                                                        onChange={handleNewQuestionChange}
                                                        className={"question-type-dropdown gray_bg_color on_focus box-shadow-unset-on-focus"}>
                                                        <option value={QuestionType.OPTION}>Option</option>
                                                        <option value={QuestionType.TEXT}>Text</option>
                                                        <option value={QuestionType.NUMBER}>Number</option>
                                                    </select>
                                                </div>
                                                <div className="question-dropdown-outer mt-2">
                                                    <input
                                                        onFocus={() => {
                                                            handleFocusedSection(true)
                                                        }}
                                                        onBlur={() => {
                                                            handleFocusedSection(false)
                                                        }}
                                                        type={"text"}
                                                        value={newAddedQuestion?.question?.questionText}
                                                        name={"questionText"}
                                                        onChange={handleNewQuestionChange}
                                                        className={"form-control cmn_fontStyle question-txt-input border-gray-on-focus gray_bg_color on_focus box-shadow-unset-on-focus"}
                                                        placeholder='Enter Question'
                                                    ></input>
                                                </div>
                                                {
                                                    newAddedQuestion?.question?.isTextLimitExceeded &&
                                                    <div className={"question-length-exceeds-warning "}>Question length
                                                        cannot be greater than 512 characters</div>
                                                }
                                                {
                                                    newAddedQuestion?.question?.type === QuestionType.NUMBER &&
                                                    <div className={"number-range-value-outer"}>
                                                        <div className={"mt-2 min-value-container"}>
                                                            <div className={"max-value-heading "}>Min :</div>
                                                            <input type={"number"}
                                                                   onFocus={() => {
                                                                       handleFocusedSection(true)
                                                                   }}
                                                                   onBlur={() => {
                                                                       handleFocusedSection(false)
                                                                   }}
                                                                   inputMode={"numeric"}
                                                                   name={"min"}
                                                                   value={newAddedQuestion?.question?.number?.min}
                                                                   onWheel={(e) => {
                                                                       document.activeElement.blur();
                                                                   }}
                                                                   onKeyPress={(e) => {
                                                                       const isValidKey = /^\d*$/.test(e.key);
                                                                       if (!isValidKey) {
                                                                           e.preventDefault();
                                                                       }
                                                                   }}
                                                                   onKeyDown={(e) => {
                                                                       if (e.key === '.' || e.key === "-") {
                                                                           e.preventDefault();
                                                                       }
                                                                   }}
                                                                   onChange={handleNewQuestionChange}
                                                                   min={0}
                                                                   placeholder={"0"}
                                                                   className={"form-control gray_bg_color on_focus range-value-input me-auto"}/>
                                                            <div className={"me-2 good-bad-outer"}>
                                                                <div
                                                                    className={newAddedQuestion?.question?.number?.isMinLowestRank ? "color-data-high-risk" : "color-data-good"}/>
                                                                <select
                                                                    onFocus={() => {
                                                                        handleFocusedSection(true)
                                                                    }}
                                                                    onBlur={() => {
                                                                        handleFocusedSection(false)
                                                                    }}
                                                                    name={"isMinLowestRank"}
                                                                    value={newAddedQuestion?.question?.number?.isMinLowestRank}
                                                                    className={"good-bad-dropdown"}
                                                                    onChange={handleNewQuestionChange}>
                                                                    <option value={"true"}
                                                                            selected={!newAddedQuestion?.question?.number?.isMinLowestRank}>{Marks.HIGH_RISK}</option>
                                                                    <option value={"false"}
                                                                            selected={newAddedQuestion?.question?.number?.isMinLowestRank}>{Marks.GOOD}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className={"mt-2 max-value-container"}>
                                                            <div className={"max-value-heading "}>Max :</div>
                                                            <input type={"number"}
                                                                   onFocus={() => {
                                                                       handleFocusedSection(true)
                                                                   }}
                                                                   onBlur={() => {
                                                                       handleFocusedSection(false)
                                                                   }}
                                                                   inputMode={"numeric"}
                                                                   name={"max"}
                                                                   value={newAddedQuestion?.question?.number?.max}
                                                                   onWheel={(e) => {
                                                                       document.activeElement.blur();
                                                                   }}
                                                                   onKeyPress={(e) => {
                                                                       const isValidKey = /^\d*$/.test(e.key);
                                                                       if (!isValidKey) {
                                                                           e.preventDefault();
                                                                       }
                                                                   }}
                                                                   onKeyDown={(e) => {
                                                                       if (e.key === '.' || e.key === "-") {
                                                                           e.preventDefault();
                                                                       }
                                                                   }}
                                                                   onChange={handleNewQuestionChange}
                                                                   min={0}
                                                                   placeholder={"..."}
                                                                   className={"form-control gray_bg_color on_focus range-value-input me-auto"}/>
                                                            <div className={"me-2 good-bad-outer"}>
                                                                <div
                                                                    className={newAddedQuestion?.question?.number?.isMinLowestRank ? "color-data-good" : "color-data-high-risk"}/>
                                                                <select
                                                                    onFocus={() => {
                                                                        handleFocusedSection(true)
                                                                    }}
                                                                    onBlur={() => {
                                                                        handleFocusedSection(false)
                                                                    }}
                                                                    value={newAddedQuestion?.question?.number?.isMinLowestRank}
                                                                    name={"isMinLowestRank"}
                                                                    className={"good-bad-dropdown"}
                                                                    onChange={handleNewQuestionChange}>
                                                                    <option value={"false"}
                                                                            selected={!newAddedQuestion?.question?.number?.isMinLowestRank}>{Marks.HIGH_RISK}</option>
                                                                    <option value={"true"}
                                                                            selected={newAddedQuestion?.question?.number?.isMinLowestRank}>{Marks.GOOD}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    newAddedQuestion?.question?.type === QuestionType.OPTION &&
                                                    <div className={"options-list-container mt-2"}>
                                                        {
                                                            [...Array(newAddedQuestion?.question?.options?.length)].map((x, i) => {

                                                                return (
                                                                    <div key={i}>
                                                                        <div
                                                                            className={"option-select-container mt-2 position-relative"}>
                                                                            <div
                                                                                className={"option-container option_Wrapper align-items-center"}>
                                                                                <input
                                                                                    onFocus={() => {
                                                                                        handleFocusedSection(true)
                                                                                    }}
                                                                                    onBlur={() => {
                                                                                        handleFocusedSection(false)
                                                                                    }}
                                                                                    type={"text"}
                                                                                    name={"optionText"}
                                                                                    onChange={(e) => {
                                                                                        handleNewQuestionChange(e, i)
                                                                                    }}
                                                                                    value={newAddedQuestion?.question?.options[i]?.optionText}
                                                                                    className={"form-control gray_bg_color box-shadow-unset-on-focus border-gray-on-focus on_focus option-input"}
                                                                                    placeholder={"Option " + (i + 1)}/>

                                                                                <div
                                                                                    className={"risk-color-and-dropdown"}>
                                                                                    <input
                                                                                        onFocus={() => {
                                                                                            handleFocusedSection(true)
                                                                                        }}
                                                                                        onBlur={() => {
                                                                                            handleFocusedSection(false)
                                                                                        }}
                                                                                        placeholder={"Enter weight here..."}
                                                                                        onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                                                                        value={newAddedQuestion?.question?.options[i]?.optionValue}
                                                                                        className={"form-control border-gray-on-focus gray_bg_color on_focus box-shadow-unset-on-focus"}
                                                                                        type={"number"}
                                                                                        min={1}
                                                                                        name={"optionValue"}
                                                                                        onChange={(e) => {
                                                                                            handleNewQuestionChange(e, i)
                                                                                        }}/>
                                                                                </div>

                                                                                {
                                                                                    (i > 2) &&
                                                                                    <div className={" "}>
                                                                                        <i
                                                                                            onClick={(e) => {
                                                                                                handleNewQuestionChange({target: {name: "removeOption"}}, i)
                                                                                            }}
                                                                                            className="fa fa-trash trash-icon"/>

                                                                                    </div>
                                                                                }


                                                                            </div>

                                                                            {
                                                                                newAddedQuestion?.question?.options[i]?.isTextLimitExceeded &&
                                                                                <div
                                                                                    className={"option-length-exceeds-warning"}>Option
                                                                                    length cannot exceed 512
                                                                                    characters</div>
                                                                            }

                                                                        </div>
                                                                        <div
                                                                            style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                            className={"add-Icon-button cmn_background_color"}>
                                                                            <i className={"fa fa-plus add-tag-icon opacity-1 text-white"}/>
                                                                            <button
                                                                                style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                                className={"cmn_background_color"}
                                                                                onClick={(e) => {
                                                                                    handleNewQuestionChange({target: {name: "addOption"}})
                                                                                }}>Add More Options...
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                                <div
                                                    className={`tag-outer mt-3 mb-2 material-icons gray_bg_color focus-within-bg-white `}>
                                                    {
                                                        newAddedQuestion?.question?.tags?.map((tag, tagIndex) => {
                                                            return (
                                                                <div className={"tags"} key={tagIndex}>
                                                                    <div className={"tag-text"}
                                                                         title={"tag"}>{trim(tag, 12)}</div>
                                                                    <div className={"tag-cross"}
                                                                         onClick={() => {
                                                                             handleNewQuestionChange({target: {name: "removeTag"}}, tagIndex)
                                                                         }}>
                                                                        <i className={"fa fa-times remove-tag-icon"}/>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    <div className={`add-tag-outer `}>
                                                        <i style={{color: "black"}}
                                                           className={"fa fa-plus add-tag-icon text-dark"}/>
                                                        <input
                                                            style={{
                                                                backgroundColor: isFocused ? `white ` : "#F5F5F5",
                                                            }}
                                                            onFocus={() => {
                                                                handleFocusedSection(true)
                                                            }}
                                                            value={newAddedQuestion?.unSavedTags}
                                                            name={"unSavedTags"}
                                                            onKeyDown={(e) => {
                                                                handleNewQuestionChange({
                                                                    ...e,
                                                                    target: {name: "tags"}
                                                                })
                                                            }}
                                                            onBlur={() => {
                                                                handleFocusedSection(false)
                                                                handleNewQuestionChange({
                                                                    key: "Enter",
                                                                    target: {name: "tags"}
                                                                })
                                                            }}
                                                            onChange={handleNewQuestionChange}
                                                            placeholder={"Add Question Tag"}
                                                            className={`tags-input-field  gray_bg_color box-shadow-unset-on-focus border-gray-on-focus on_focus`}
                                                            type={"text"}/>
                                                    </div>
                                                </div>
                                                <label className={"required-label"}>
                                                    <input name={"isRequired"}
                                                           onFocus={() => {
                                                               handleFocusedSection(true)
                                                           }}
                                                           onBlur={() => {
                                                               handleFocusedSection(false)
                                                           }}
                                                           onChange={handleNewQuestionChange}
                                                           checked={newAddedQuestion?.question?.isRequired}
                                                           className={"required-checkbox"}
                                                           type="checkbox"/>
                                                    Marked as required
                                                </label>
                                                <div
                                                    className={"d-flex align-items-center justify-content-end mb-1 me-3"}>
                                                    <button
                                                        style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                        className='modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize submit-survey-btn mt-2'
                                                        onClick={resetNewAddedQuestion}
                                                    >Delete
                                                    </button>
                                                    <button
                                                        style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                        className={'modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize publish-survey-btn mt-2 '}
                                                        onClick={handleAddNewQuestionToList}
                                                    >Save
                                                    </button>

                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </li>
                            }
                            {
                                ((segmentType === "LINEAR" && activeQuestionIndex?.rowIndex === null) || (segmentType === "TABULAR" &&   activeQuestionIndex?.columnIndex === null)) && !newAddedQuestion.show &&
                                <div
                                    className={` question_tabs_wrapper add-ques-btn-container d-flex align-items-center justify-content-center mt-2 text-center gray_bg_color`}>
                                    <button
                                        style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                        disabled={checkBtnPermission() === false}
                                        className={'modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize publish-survey-btn mt-2 '}
                                        onClick={() => {
                                            if (checkBtnPermission() === true) {
                                                handleAddNewQuestion()
                                            }
                                        }}
                                    >Add new question
                                    </button>
                                </div>
                            }

                            {
                                segmentType === "LINEAR" &&
                                <>
                                    {
                                        Array.isArray(questionsList) && questionsList.map((question, index) => (
                                            <Draggable key={index} draggableId={"characters" + index} index={index}>
                                                {
                                                    (provided) => (
                                                        <li ref={provided.innerRef} {...provided.draggableProps}
                                                            key={index}>
                                                            {
                                                                question.isShow &&
                                                                <div className={"d-flex"}>
                                                                    <Card
                                                                        className={'question-card  question-list mt-2'}
                                                                        style={{
                                                                            border: isFocused ? `1px solid ${localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}` : "",
                                                                        }}
                                                                    >
                                                                        <Card.Body>
                                                                            <div className={""}>
                                                                                {
                                                                                    isReflectionEditable(question?.questionId) &&
                                                                                    <select
                                                                                        onFocus={() => {
                                                                                            handleFocusedSection(true)
                                                                                        }}
                                                                                        onBlur={() => {
                                                                                            handleFocusedSection(false)
                                                                                        }}
                                                                                        value={question.type}
                                                                                        disabled={checkBtnPermission() === false}
                                                                                        name={"type"}
                                                                                        onChange={(e) => {
                                                                                            if (checkBtnPermission() === true) {
                                                                                                handleQuestionTypeChange(index, e, parentId)
                                                                                            }
                                                                                        }}
                                                                                        className={" question-type-dropdown gray_bg_color on_focus box-shadow-unset-on-focus"}>
                                                                                        <option
                                                                                            value={QuestionType.OPTION}>Option
                                                                                        </option>
                                                                                        <option
                                                                                            value={QuestionType.TEXT}>Text
                                                                                        </option>
                                                                                        <option
                                                                                            value={QuestionType.NUMBER}>Number
                                                                                        </option>
                                                                                    </select>
                                                                                }
                                                                            </div>
                                                                            <div className="question-dropdown-outer mt-2">
                                                                                <input
                                                                                    onFocus={() => {
                                                                                        handleFocusedSection(true)
                                                                                    }}
                                                                                    onBlur={() => {
                                                                                        handleFocusedSection(false)
                                                                                    }}
                                                                                    type="text"
                                                                                    value={questionsList[index].questionText}
                                                                                    name={"questionText"}
                                                                                    onChange={(e) => {
                                                                                        if (checkBtnPermission() === true) {
                                                                                            if (e.target.value.length <= 512) {
                                                                                                handleChangeQuestionText(e, index, parentId)
                                                                                            } else if (e.target.value.length > 512) {
                                                                                                setQuestionTextLimitExceeds(index)
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                    disabled={checkBtnPermission() === false}
                                                                                    className={"form-control cmn_fontStyle question-txt-input border-gray-on-focus gray_bg_color on_focus box-shadow-unset-on-focus"}
                                                                                    placeholder='Enter Question'
                                                                                />
                                                                            </div>
                                                                            {
                                                                                questionsList[index].isTextLimitExceeded &&
                                                                                <div
                                                                                    className={"question-length-exceeds-warning "}>Question
                                                                                    length cannot be greater than 512
                                                                                    characters</div>
                                                                            }
                                                                            {
                                                                                question.type === QuestionType.NUMBER &&
                                                                                <div className={"number-range-value-outer"}>
                                                                                    <div
                                                                                        className={"mt-2 min-value-container"}>
                                                                                        <div
                                                                                            className={"max-value-heading "}>Min
                                                                                            :
                                                                                        </div>
                                                                                        <input type={"number"}
                                                                                               onFocus={() => {
                                                                                                   handleFocusedSection(true)
                                                                                               }}
                                                                                               onBlur={() => {
                                                                                                   handleFocusedSection(false)
                                                                                               }}
                                                                                               inputMode={"numeric"}
                                                                                               name={"min"}
                                                                                               disabled={checkBtnPermission() === false}
                                                                                               value={questionsList[index].number.min}
                                                                                               onWheel={(e) => {
                                                                                                   document.activeElement.blur();
                                                                                               }}
                                                                                               onKeyPress={(e) => {
                                                                                                   const isValidKey = /^\d*$/.test(e.key);
                                                                                                   if (!isValidKey) {
                                                                                                       e.preventDefault();
                                                                                                   }
                                                                                               }}
                                                                                               onKeyDown={(e) => {
                                                                                                   if (e.key === '.' || e.key === "-") {
                                                                                                       e.preventDefault();
                                                                                                   }
                                                                                               }}
                                                                                               onChange={(e) => {
                                                                                                   if (checkBtnPermission() === true && e.target.value >= 0) {
                                                                                                       updateMinMaxNumber(e, index, parentId)
                                                                                                   }
                                                                                               }}
                                                                                               min={0} placeholder={"0"}
                                                                                               className={"form-control gray_bg_color on_focus range-value-input me-auto"}/>
                                                                                        <div
                                                                                            className={"me-2 good-bad-outer"}>
                                                                                            <div
                                                                                                className={question.number.isMinLowestRank ? "color-data-high-risk" : "color-data-good"}/>
                                                                                            <select
                                                                                                onFocus={() => {
                                                                                                    handleFocusedSection(true)
                                                                                                }}
                                                                                                onBlur={() => {
                                                                                                    handleFocusedSection(false)
                                                                                                }}
                                                                                                name={"isMinLowestRank"}
                                                                                                value={question.number.isMinLowestRank}
                                                                                                disabled={checkBtnPermission() === false}
                                                                                                className={"good-bad-dropdown"}
                                                                                                onChange={(e) => {
                                                                                                    if (checkBtnPermission() === true) {
                                                                                                        updateMinMaxValue(e, index, parentId)
                                                                                                    }
                                                                                                }}>
                                                                                                <option
                                                                                                    value={true}>{Marks.HIGH_RISK}</option>
                                                                                                <option
                                                                                                    value={false}>{Marks.GOOD}</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className={"mt-2 max-value-container"}>
                                                                                        <div
                                                                                            className={"max-value-heading "}>Max
                                                                                            :
                                                                                        </div>
                                                                                        <input type={"number"}
                                                                                               inputMode={"numeric"}
                                                                                               name={"max"}
                                                                                               value={question.number.max}
                                                                                               onWheel={(e) => {
                                                                                                   document.activeElement.blur();
                                                                                               }}
                                                                                               onKeyPress={(e) => {
                                                                                                   const isValidKey = /^\d*$/.test(e.key);
                                                                                                   if (!isValidKey) {
                                                                                                       e.preventDefault();
                                                                                                   }
                                                                                               }}
                                                                                               onKeyDown={(e) => {
                                                                                                   if (e.key === '.' || e.key === "-") {
                                                                                                       e.preventDefault();
                                                                                                   }
                                                                                               }}
                                                                                               disabled={checkBtnPermission() === false}
                                                                                               onChange={(e) => {
                                                                                                   if (e.target.value >= 0) {
                                                                                                       updateMinMaxNumber(e, index, parentId)
                                                                                                   }
                                                                                               }} min={0}
                                                                                               placeholder={"..."}
                                                                                               onFocus={() => {
                                                                                                   handleFocusedSection(true)
                                                                                               }}
                                                                                               onBlur={() => {
                                                                                                   handleFocusedSection(false)
                                                                                               }}
                                                                                               className={"form-control gray_bg_color on_focus range-value-input me-auto"}/>
                                                                                        <div
                                                                                            className={"me-2 good-bad-outer"}>
                                                                                            <div
                                                                                                className={question.number.isMinLowestRank ? "color-data-good" : "color-data-high-risk"}/>
                                                                                            <select
                                                                                                value={question.number.isMinLowestRank}
                                                                                                name={"isMinLowestRank"}
                                                                                                disabled={checkBtnPermission() === false}
                                                                                                onFocus={() => {
                                                                                                    handleFocusedSection(true)
                                                                                                }}
                                                                                                onBlur={() => {
                                                                                                    handleFocusedSection(false)
                                                                                                }}
                                                                                                className={"good-bad-dropdown"}
                                                                                                onChange={(e) => {
                                                                                                    if (checkBtnPermission() === true) {
                                                                                                        updateMinMaxValue(e, index, parentId)

                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <option value={false}
                                                                                                        selected={!question.number.isMinLowestRank}>{Marks.HIGH_RISK}</option>
                                                                                                <option value={true}
                                                                                                        selected={question.number.isMinLowestRank}>{Marks.GOOD}</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                question.type === QuestionType.OPTION &&
                                                                                <div
                                                                                    className={"options-list-container mt-2"}>
                                                                                    {
                                                                                        [...Array(questionsList[index].options.length)].map((x, i) => {

                                                                                            return (
                                                                                                <>
                                                                                                    <div key={i}
                                                                                                         className={"option-select-container mt-2 position-relative"}>
                                                                                                        <div
                                                                                                            className={"option-container option_Wrapper align-items-center"}>
                                                                                                            <input
                                                                                                                type={"text"}
                                                                                                                name={"optionText"}
                                                                                                                disabled={checkBtnPermission() === false}
                                                                                                                onChange={(e) => {
                                                                                                                    if (checkBtnPermission() === true) {
                                                                                                                        if (e.target.value.length <= 512) {
                                                                                                                            handleOptionChange(e, index, i, parentId)
                                                                                                                        } else if (e.target.value.length > 512) {
                                                                                                                            setOptionTextLimitExceeds(index, i, parentId)
                                                                                                                        }
                                                                                                                    }
                                                                                                                }}
                                                                                                                onFocus={() => {
                                                                                                                    handleFocusedSection(true)
                                                                                                                }}
                                                                                                                onBlur={() => {
                                                                                                                    handleFocusedSection(false)
                                                                                                                }}
                                                                                                                className={"form-control gray_bg_color box-shadow-unset-on-focus border-gray-on-focus on_focus option-input"}
                                                                                                                value={questionsList[index].options[i].optionText}
                                                                                                                placeholder={"Enter option here"}/>

                                                                                                            <div
                                                                                                                className={"risk-color-and-dropdown"}>
                                                                                                                <input
                                                                                                                    onFocus={() => {
                                                                                                                        handleFocusedSection(true)
                                                                                                                    }}
                                                                                                                    onBlur={() => {
                                                                                                                        handleFocusedSection(false)
                                                                                                                    }}
                                                                                                                    className={"form-control border-gray-on-focus gray_bg_color on_focus box-shadow-unset-on-focus"}
                                                                                                                    placeholder={"Enter weight here..."}
                                                                                                                    onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                                                                                                    disabled={checkBtnPermission() === false}
                                                                                                                    value={questionsList[index].options[i].optionValue}
                                                                                                                    type={"number"}
                                                                                                                    min={1}
                                                                                                                    name={"optionValue"}
                                                                                                                    onChange={(e) => {
                                                                                                                        if (checkBtnPermission() === true) {
                                                                                                                            handleOptionChange(e, index, i, parentId);
                                                                                                                        }
                                                                                                                    }}/>
                                                                                                            </div>

                                                                                                            {((formType === "Edit" || formType === "Update") && i > 2) &&
                                                                                                                <div
                                                                                                                    className={" "}>
                                                                                                                    <i onClick={(e) => {
                                                                                                                        if (checkBtnPermission() === true) {
                                                                                                                            handleRemoveOptions(e, index, i, parentId)
                                                                                                                        }
                                                                                                                    }}
                                                                                                                       className="fa fa-trash trash-icon"/>

                                                                                                                </div>
                                                                                                            }
                                                                                                            {(formType === "Update" && i > 2) &&
                                                                                                                <div
                                                                                                                    className={" "}>
                                                                                                                    <i onClick={(e) => {
                                                                                                                        if (checkBtnPermission() === true) {
                                                                                                                            handleRemoveOptions(e, index, i, parentId)
                                                                                                                        }
                                                                                                                    }}
                                                                                                                       className="fa fa-trash trash-icon"/>

                                                                                                                </div>
                                                                                                            }

                                                                                                            {(formType === "Add" && i > 2) &&
                                                                                                                <div
                                                                                                                    className={" "}>
                                                                                                                    <i onClick={(e) => {
                                                                                                                        if (checkBtnPermission() === true) {
                                                                                                                            handleRemoveOptions(e, index, i, parentId)
                                                                                                                        }
                                                                                                                    }}
                                                                                                                       className="fa fa-trash trash-icon"/>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                        {
                                                                                                            questionsList[index].options[i].isTextLimitExceeded &&
                                                                                                            <div
                                                                                                                className={"option-length-exceeds-warning"}>Option
                                                                                                                length
                                                                                                                cannot
                                                                                                                exceed 512
                                                                                                                characters</div>
                                                                                                        }
                                                                                                    </div>


                                                                                                    {((formType === "Add" || formType === "Update") && i === 2) &&

                                                                                                        <div
                                                                                                            style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                                                            className={"add-Icon-button cmn_background_color"}>
                                                                                                            <i className={"fa fa-plus add-tag-icon opacity-1 text-white"}/>
                                                                                                            <button
                                                                                                                style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                                                                className={"cmn_background_color"}
                                                                                                                onClick={(e) => {
                                                                                                                    if (checkBtnPermission() === true) {
                                                                                                                        handleAddOptions(e, index, i, parentId)
                                                                                                                    }
                                                                                                                }}>Add More Options...

                                                                                                            </button>

                                                                                                        </div>
                                                                                                    }
                                                                                                    {(formType === "Edit" && i === 2) &&

                                                                                                        <div
                                                                                                            style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                                                            className={"add-Icon-button"}>
                                                                                                            <i className={"fa fa-plus add-tag-icon opacity-1 text-white"}/>
                                                                                                            <button
                                                                                                                className={"cmn_background_color"}
                                                                                                                style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                                                                onClick={(e) => {
                                                                                                                    if (checkBtnPermission() === true) {
                                                                                                                        handleAddOptions(e, index, i, parentId)
                                                                                                                    }
                                                                                                                }}>Add More
                                                                                                                Options...
                                                                                                            </button>

                                                                                                        </div>
                                                                                                    }
                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            }
                                                                            <div
                                                                                className={`tag-outer mt-3 mb-2 material-icons gray_bg_color focus-within-bg-white `}>
                                                                                {
                                                                                    questionsList[index].tags?.map((tag, tagIndex) => {
                                                                                        return (
                                                                                            <div className={"tags"}
                                                                                                 key={tagIndex}>
                                                                                                <div
                                                                                                    className={"tag-text"}
                                                                                                    title={tag}>{trim(tag, 12)}</div>
                                                                                                <div
                                                                                                    className={"tag-cross"}
                                                                                                    onClick={() => {
                                                                                                        if (checkBtnPermission() === true) {
                                                                                                            handleRemoveTag(index, tagIndex, parentId)
                                                                                                        }
                                                                                                    }}>
                                                                                                    <i className={"fa fa-times remove-tag-icon"}/>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                <div className={"add-tag-outer"}>
                                                                                    <i style={{color: "black"}}
                                                                                       className={"fa fa-plus add-tag-icon"}/>
                                                                                    <input
                                                                                        id={`tag-field-${index}`}
                                                                                        value={tags[index]}
                                                                                        name={"tags"}
                                                                                        style={{
                                                                                            backgroundColor: isFocused ? `white ` : "#F5F5F5",
                                                                                        }}
                                                                                        onFocus={() => {
                                                                                            handleFocusedSection(true)
                                                                                        }}
                                                                                        disabled={checkBtnPermission() === false}
                                                                                        onKeyDown={(e) => {
                                                                                            if (checkBtnPermission() === true) {
                                                                                                handleKeyDown(e, index, parentId)
                                                                                            }
                                                                                        }}
                                                                                        onBlur={(e) => {
                                                                                            handleFocusedSection(false)
                                                                                            handleKeyDown({
                                                                                                ...e,
                                                                                                key: "Enter"
                                                                                            }, index, parentId)
                                                                                        }}
                                                                                        onChange={(e) => {
                                                                                            checkBtnPermission() === true ? handleTagChange(e.target.value, index) : handleTagChange("", index)
                                                                                        }}
                                                                                        placeholder={"Add Question Tag"}
                                                                                        className={`tags-input-field  gray_bg_color box-shadow-unset-on-focus border-gray-on-focus on_focus`}
                                                                                        type={"text"}/>

                                                                                </div>
                                                                            </div>
                                                                            <label className={"required-label"}>
                                                                                <input name={"isRequired"}
                                                                                       disabled={checkBtnPermission() === false}
                                                                                       onChange={(e) => {
                                                                                           if (checkBtnPermission() === true) {
                                                                                               handleRequiredChange(e, index, parentId)
                                                                                           }
                                                                                       }}
                                                                                       onFocus={() => {
                                                                                           handleFocusedSection(true)
                                                                                       }}
                                                                                       onBlur={() => {
                                                                                           handleFocusedSection(false)
                                                                                       }}
                                                                                       checked={questionsList[index].isRequired}
                                                                                       className={"required-checkbox"}
                                                                                       type="checkbox"/>
                                                                                Marked as required
                                                                            </label>
                                                                            <div className='d-flex align-items-center justify-content-end'>
                                                                                <div
                                                                                    className={"d-flex align-items-center"}>
                                                                                    <button
                                                                                        style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                                        className={'modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize submit-survey-btn mt-2 me-2' + (checkBtnPermission() === true ? "" : "disable-btn")}
                                                                                        onClick={() => {
                                                                                            if (checkBtnPermission() === true) {
                                                                                                handleDeleteQuestion(index, parentId)
                                                                                            }
                                                                                        }}
                                                                                    >Delete
                                                                                    </button>
                                                                                    <button
                                                                                        style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                                        disabled={checkBtnPermission() === false}
                                                                                        className={'modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize publish-survey-btn mt-2 '}
                                                                                        onClick={() => {
                                                                                            if (checkBtnPermission() === true) {
                                                                                                handleAddNewQuestion()
                                                                                            }
                                                                                        }}
                                                                                    >Add new question
                                                                                    </button>
                                                                                </div>


                                                                            </div>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                            }
                                                        </li>
                                                    )
                                                }
                                            </Draggable>
                                        ))}
                                </>
                            }
                            {
                                segmentType === "TABULAR" &&
                                <>
                                    {
                                        reflectionSegments[parentId]?.questionsList.map((question, index) => (
                                            <Draggable key={index} draggableId={"characters" + index} index={index}>
                                                {
                                                    (provided) => (
                                                        <li ref={provided.innerRef} {...provided.draggableProps}
                                                            key={index}>
                                                            {
                                                                question.isShow && <div className={"d-flex mt-3"}>
                                                                    <Card
                                                                        className={" question-card question-list question-card question-list input"}
                                                                        style={{
                                                                            border: isFocused ? `1px solid ${localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}` : "",
                                                                        }}
                                                                    >
                                                                        <Card.Body>
                                                                            <div className={"required-check mb-2"}>
                                                                                {
                                                                                    isReflectionEditable(question?.questionId) &&
                                                                                    <select
                                                                                        onFocus={() => {
                                                                                            handleFocusedSection(true)
                                                                                        }}
                                                                                        onBlur={() => {
                                                                                            handleFocusedSection(false)
                                                                                        }}
                                                                                        value={question.type}
                                                                                        disabled={checkBtnPermission() === false}
                                                                                        name={"type"}
                                                                                        onChange={(e) => {
                                                                                            if (checkBtnPermission() === true) {
                                                                                                handleQuestionTypeChange(index, e, parentId)
                                                                                            }
                                                                                        }}
                                                                                        className={"question-type-dropdown gray_bg_color on_focus box-shadow-unset-on-focus"}>
                                                                                        <option
                                                                                            value={QuestionType.OPTION}>Option
                                                                                        </option>
                                                                                        <option
                                                                                            value={QuestionType.TEXT}>Text
                                                                                        </option>
                                                                                        <option
                                                                                            value={QuestionType.NUMBER}>Number
                                                                                        </option>
                                                                                    </select>
                                                                                }


                                                                            </div>
                                                                            <div
                                                                                className="question-dropdown-outer mt-2">
                                                                                <input
                                                                                    onFocus={() => {
                                                                                        handleFocusedSection(true)
                                                                                    }}
                                                                                    onBlur={() => {
                                                                                        handleFocusedSection(false)
                                                                                    }}
                                                                                    type="text"
                                                                                    name={"questionText"}
                                                                                    className={"form-control cmn_fontStyle question-txt-input border-gray-on-focus gray_bg_color on_focus box-shadow-unset-on-focus"}
                                                                                    placeholder='Enter Question'
                                                                                    value={reflectionSegments[parentId]?.questionsList[index].questionText}
                                                                                    onChange={(e) => {
                                                                                        if (checkBtnPermission() === true) {
                                                                                            if (e.target.value.length <= 512) {
                                                                                                handleChangeQuestionText(e, index, parentId)
                                                                                            } else if (e.target.value.length > 512) {
                                                                                                setQuestionTextLimitExceeds(index)
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                    disabled={checkBtnPermission() === false}
                                                                                />
                                                                            </div>
                                                                            {
                                                                                reflectionSegments[parentId]?.questionsList[index].isTextLimitExceeded &&
                                                                                <div
                                                                                    className={"question-length-exceeds-warning "}>Question
                                                                                    length cannot be greater than
                                                                                    512
                                                                                    characters</div>
                                                                            }
                                                                            {
                                                                                question.type === QuestionType.NUMBER &&
                                                                                <div
                                                                                    className={"number-range-value-outer"}>
                                                                                    <div
                                                                                        className={"mt-2 min-value-container"}>
                                                                                        <div
                                                                                            className={"max-value-heading  "}>Min
                                                                                            :
                                                                                        </div>
                                                                                        <input type={"number"}
                                                                                               inputMode={"numeric"}
                                                                                               name={"min"}
                                                                                               value={getValueOrDefault(reflectionSegments[parentId]?.questionsList[index].number.min, "")}
                                                                                               disabled={checkBtnPermission() === false}
                                                                                               onWheel={(e) => {
                                                                                                   document.activeElement.blur();
                                                                                               }}
                                                                                               onKeyPress={(e) => {
                                                                                                   const isValidKey = /^\d*$/.test(e.key);
                                                                                                   if (!isValidKey) {
                                                                                                       e.preventDefault();
                                                                                                   }
                                                                                               }}
                                                                                               onFocus={() => {
                                                                                                   handleFocusedSection(true)
                                                                                               }}
                                                                                               onBlur={() => {
                                                                                                   handleFocusedSection(false)
                                                                                               }}
                                                                                               onKeyDown={(e) => {
                                                                                                   if (e.key === '.' || e.key === "-") {
                                                                                                       e.preventDefault();
                                                                                                   }
                                                                                               }}
                                                                                               onChange={(e) => {
                                                                                                   if (checkBtnPermission() === true && e.target.value >= 0) {
                                                                                                       updateMinMaxNumber(e, index, parentId)
                                                                                                   }
                                                                                               }}
                                                                                               min={0}
                                                                                               placeholder={"0"}
                                                                                               className={"form-control range-value-input me-auto  gray_bg_color on_focus"}/>
                                                                                        <div
                                                                                            className={"me-2 good-bad-outer"}>
                                                                                            <div
                                                                                                className={question.number.isMinLowestRank ? "color-data-high-risk" : "color-data-good"}/>
                                                                                            <select
                                                                                                onFocus={() => {
                                                                                                    handleFocusedSection(true)
                                                                                                }}
                                                                                                onBlur={() => {
                                                                                                    handleFocusedSection(false)
                                                                                                }}
                                                                                                name={"isMinLowestRank"}
                                                                                                value={question.number.isMinLowestRank}
                                                                                                disabled={checkBtnPermission() === false}
                                                                                                className={"good-bad-dropdown"}
                                                                                                onChange={(e) => {
                                                                                                    if (checkBtnPermission() === true) {
                                                                                                        updateMinMaxValue(e, index, parentId)
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <option value={true}
                                                                                                >{Marks.HIGH_RISK}</option>
                                                                                                <option
                                                                                                    value={false}
                                                                                                >{Marks.GOOD}</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className={"mt-2 max-value-container"}>
                                                                                        <div
                                                                                            className={"max-value-heading "}>Max
                                                                                            :
                                                                                        </div>
                                                                                        <input type={"number"}
                                                                                               inputMode={"numeric"}
                                                                                               name={"max"}
                                                                                               value={getValueOrDefault(question.number.max, "")}
                                                                                               disabled={checkBtnPermission() === false}

                                                                                               onWheel={(e) => {
                                                                                                   document.activeElement.blur();
                                                                                               }}
                                                                                               onKeyPress={(e) => {
                                                                                                   const isValidKey = /^\d*$/.test(e.key);
                                                                                                   if (!isValidKey) {
                                                                                                       e.preventDefault();
                                                                                                   }
                                                                                               }}
                                                                                               onKeyDown={(e) => {
                                                                                                   if (e.key === '.' || e.key === "-") {
                                                                                                       e.preventDefault();
                                                                                                   }
                                                                                               }}
                                                                                               onChange={(e) => {
                                                                                                   if (checkBtnPermission() === true && e.target.value >= 0) {
                                                                                                       updateMinMaxNumber(e, index, parentId)
                                                                                                   }
                                                                                               }} min={0}
                                                                                               placeholder={"..."}
                                                                                               onFocus={() => {
                                                                                                   handleFocusedSection(true)
                                                                                               }}
                                                                                               onBlur={() => {
                                                                                                   handleFocusedSection(false)
                                                                                               }}
                                                                                               className={"form-control range-value-input me-auto gray_bg_color on_focus"}/>
                                                                                        <div
                                                                                            className={"me-2 good-bad-outer"}>
                                                                                            <div
                                                                                                className={question.number.isMinLowestRank ? "color-data-good" : "color-data-high-risk"}/>
                                                                                            <select
                                                                                                value={question.number.isMinLowestRank}
                                                                                                name={"isMinLowestRank"}
                                                                                                onFocus={() => {
                                                                                                    handleFocusedSection(true)
                                                                                                }}
                                                                                                onBlur={() => {
                                                                                                    handleFocusedSection(false)
                                                                                                }}
                                                                                                disabled={checkBtnPermission() === false}
                                                                                                className={"good-bad-dropdown"}
                                                                                                onChange={(e) => {
                                                                                                    if (checkBtnPermission() === true) {
                                                                                                        updateMinMaxValue(e, index, parentId)

                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <option value={false}
                                                                                                        selected={!question.number.isMinLowestRank}>{Marks.HIGH_RISK}</option>
                                                                                                <option value={true}
                                                                                                        selected={question.number.isMinLowestRank}>{Marks.GOOD}</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                question.type === QuestionType.OPTION &&
                                                                                <div
                                                                                    className={"options-list-container mt-2"}>
                                                                                    {
                                                                                        [...Array(reflectionSegments[parentId]?.questionsList[index].options.length)].map((x, i) => {

                                                                                            return (

                                                                                                <>
                                                                                                    <div key={i}
                                                                                                         className={"option-select-container mt-2 position-relative"}>
                                                                                                        <div
                                                                                                            className={"option-container option_Wrapper align-items-center"}>
                                                                                                            <input
                                                                                                                type={"text"}
                                                                                                                name={"optionText"}
                                                                                                                disabled={checkBtnPermission() === false}
                                                                                                                onChange={(e) => {
                                                                                                                    if (checkBtnPermission() === true) {
                                                                                                                        if (e.target.value.length <= 512) {
                                                                                                                            handleOptionChange(e, index, i, parentId)
                                                                                                                        } else if (e.target.value.length > 512) {
                                                                                                                            setOptionTextLimitExceeds(index, i)
                                                                                                                        }

                                                                                                                    }
                                                                                                                }}
                                                                                                                onFocus={() => {
                                                                                                                    handleFocusedSection(true)
                                                                                                                }}
                                                                                                                onBlur={() => {
                                                                                                                    handleFocusedSection(false)
                                                                                                                }}
                                                                                                                className={"form-control gray_bg_color box-shadow-unset-on-focus border-gray-on-focus on_focus option-input"}
                                                                                                                value={reflectionSegments[parentId]?.questionsList[index].options[i].optionText}
                                                                                                                placeholder={"Enter option here"}/>
                                                                                                            <div
                                                                                                                className={"risk-color-and-dropdown"}>
                                                                                                                <input
                                                                                                                    placeholder={"Enter weight here..."}
                                                                                                                    onFocus={() => {
                                                                                                                        handleFocusedSection(true)
                                                                                                                    }}
                                                                                                                    onBlur={() => {
                                                                                                                        handleFocusedSection(false)
                                                                                                                    }}
                                                                                                                    className={"form-control border-gray-on-focus gray_bg_color on_focus box-shadow-unset-on-focus"}
                                                                                                                    onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                                                                                                    disabled={checkBtnPermission() === false}
                                                                                                                    value={getValueOrDefault(reflectionSegments[parentId]?.questionsList[index].options[i].optionValue, "")}
                                                                                                                    type={"number"}
                                                                                                                    min={1}
                                                                                                                    name={"optionValue"}
                                                                                                                    onChange={(e) => {
                                                                                                                        if (checkBtnPermission() === true) {
                                                                                                                            handleOptionChange(e, index, i, parentId);
                                                                                                                        }
                                                                                                                    }}/>
                                                                                                            </div>

                                                                                                            {((formType === "Edit" || formType === "Update") && i > 2) &&
                                                                                                                <div
                                                                                                                    className={" "}>
                                                                                                                    <i onClick={(e) => {
                                                                                                                        if (checkBtnPermission() === true) {
                                                                                                                            handleRemoveOptions(e, index, i, parentId)
                                                                                                                        }
                                                                                                                    }}
                                                                                                                       className="fa fa-trash trash-icon"/>

                                                                                                                </div>
                                                                                                            }
                                                                                                            {(formType === "Update" && i > 2) &&
                                                                                                                <div
                                                                                                                    className={" "}>
                                                                                                                    <i onClick={(e) => {
                                                                                                                        if (checkBtnPermission() === true) {
                                                                                                                            handleRemoveOptions(e, index, i, parentId)
                                                                                                                        }
                                                                                                                    }}
                                                                                                                       className="fa fa-trash trash-icon"/>

                                                                                                                </div>
                                                                                                            }

                                                                                                            {(formType === "Add" && i > 2) &&
                                                                                                                <div
                                                                                                                    className={" "}>
                                                                                                                    <i onClick={(e) => {
                                                                                                                        if (checkBtnPermission() === true) {
                                                                                                                            handleRemoveOptions(e, index, i, parentId)
                                                                                                                        }
                                                                                                                    }}
                                                                                                                       className="fa fa-trash trash-icon"/>

                                                                                                                </div>
                                                                                                            }


                                                                                                        </div>

                                                                                                        {
                                                                                                            reflectionSegments[parentId]?.questionsList[index].options[i].isTextLimitExceeded &&
                                                                                                            <div
                                                                                                                className={"option-length-exceeds-warning"}>Option
                                                                                                                length
                                                                                                                cannot
                                                                                                                exceed
                                                                                                                512
                                                                                                                characters</div>
                                                                                                        }

                                                                                                    </div>


                                                                                                    {((formType === "Add" || formType === "Update") && i === 2) &&

                                                                                                        <div
                                                                                                            style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                                                            className={"add-Icon-button cmn_background_color"}>
                                                                                                            <i className={"fa fa-plus add-tag-icon opacity-1 text-white"}/>
                                                                                                            <button
                                                                                                                style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                                                                className={"cmn_background_color"}
                                                                                                                onClick={(e) => {
                                                                                                                    if (checkBtnPermission() === true) {
                                                                                                                        handleAddOptions(e, index, i, parentId)
                                                                                                                    }
                                                                                                                }}>Add
                                                                                                                More
                                                                                                                Options...
                                                                                                            </button>

                                                                                                        </div>
                                                                                                    }
                                                                                                    {(formType === "Edit" && i === 2) &&

                                                                                                        <div
                                                                                                            style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}

                                                                                                            className={"add-Icon-button"}>
                                                                                                            <i className={"fa fa-plus add-tag-icon opacity-1 text-white"}/>
                                                                                                            <button
                                                                                                                style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                                                                className={"cmn_background_color"}
                                                                                                                onClick={(e) => {
                                                                                                                    if (checkBtnPermission() === true) {
                                                                                                                        handleAddOptions(e, index, i, parentId)
                                                                                                                    }
                                                                                                                }}>Add
                                                                                                                More
                                                                                                                Options...
                                                                                                            </button>

                                                                                                        </div>
                                                                                                    }

                                                                                                </>


                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            }


                                                                            <div
                                                                                className={`tag-outer mt-3 mb-2 material-icons gray_bg_color focus-within-bg-white `}>
                                                                                {
                                                                                    reflectionSegments[parentId]?.questionsList[index].tags?.map((tag, tagIndex) => {
                                                                                        return (
                                                                                            <div className={"tags"}
                                                                                                 key={tagIndex}>
                                                                                                <div
                                                                                                    className={"tag-text"}
                                                                                                    title={tag}>{trim(tag, 12)}</div>
                                                                                                <div
                                                                                                    className={"tag-cross"}
                                                                                                    onClick={() => {
                                                                                                        if (checkBtnPermission() === true) {
                                                                                                            handleRemoveTag(index, tagIndex, parentId)
                                                                                                        }
                                                                                                    }}>
                                                                                                    <i className={"fa fa-times remove-tag-icon"}/>
                                                                                                </div>
                                                                                            </div>)
                                                                                    })
                                                                                }
                                                                                <div className={"add-tag-outer"}>
                                                                                    <i style={{color: "black"}}
                                                                                       className={"fa fa-plus add-tag-icon"}/>
                                                                                    <input
                                                                                        value={tags?.[parentId]?.[index] || ""}
                                                                                        name={"tags"}
                                                                                        style={{
                                                                                            backgroundColor: isFocused ? `white ` : "#F5F5F5",
                                                                                        }}
                                                                                        onFocus={() => {
                                                                                            handleFocusedSection(true)
                                                                                        }}
                                                                                        disabled={checkBtnPermission() === false}
                                                                                        onKeyDown={(e) => {
                                                                                            if (checkBtnPermission() === true) {
                                                                                                handleKeyDown(e, index, parentId)
                                                                                            }
                                                                                        }}
                                                                                        onBlur={(e) => {
                                                                                            handleFocusedSection(true)
                                                                                            handleKeyDown({
                                                                                                ...e,
                                                                                                key: "Enter"
                                                                                            }, index, parentId)
                                                                                        }}
                                                                                        onChange={(e) => {
                                                                                            checkBtnPermission() === true ? handleTagChange(e.target.value, index) : handleTagChange("", index)
                                                                                        }}
                                                                                        placeholder={"Add Question Tag"}
                                                                                        className={`tags-input-field  gray_bg_color box-shadow-unset-on-focus border-gray-on-focus on_focus`}
                                                                                        type={"text"}/>
                                                                                </div>
                                                                            </div>
                                                                            <label className={"required-label"}>
                                                                                <input name={"isRequired"}
                                                                                       disabled={checkBtnPermission() === false}
                                                                                       onChange={(e) => {
                                                                                           if (checkBtnPermission() === true) {
                                                                                               handleRequiredChange(e, index, parentId)
                                                                                           }
                                                                                       }}
                                                                                       onFocus={() => {
                                                                                           handleFocusedSection(true)
                                                                                       }}
                                                                                       onBlur={() => {
                                                                                           handleFocusedSection(false)
                                                                                       }}
                                                                                       checked={reflectionSegments[parentId]?.questionsList[index].isRequired}
                                                                                       className={"required-checkbox"}
                                                                                       type="checkbox"/>
                                                                                Marked as required
                                                                            </label>
                                                                            <div
                                                                                className='d-flex align-items-center justify-content-end'>
                                                                                <button
                                                                                    style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                                    className={'modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize submit-survey-btn mt-2 me-2' + (checkBtnPermission() === true ? "" : "disable-btn")}
                                                                                    onClick={() => {
                                                                                        if (checkBtnPermission() === true) {
                                                                                            handleDeleteQuestion(index, parentId)
                                                                                        }
                                                                                    }}
                                                                                >Delete
                                                                                </button>
                                                                                <button
                                                                                    style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                                    disabled={checkBtnPermission() === false}
                                                                                    className={'modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize publish-survey-btn mt-2 '}
                                                                                    onClick={() => {
                                                                                        if (checkBtnPermission() === true) {
                                                                                            handleAddNewQuestion()
                                                                                        }
                                                                                    }}
                                                                                >Add new question
                                                                                </button>
                                                                            </div>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                            }
                                                        </li>
                                                    )
                                                }
                                            </Draggable>
                                        ))
                                    }

                                </>
                            }
                        </ul>
                    )
                }
            </Droppable>
        </DragDropContext>
    );
};
export default DraggableQuestions;