import './Dashboard.css'
import "react-circular-progressbar/dist/styles.css";
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import 'react-loading-skeleton/dist/skeleton.css'
import {
    createOptionListForSelectTag,
    getDataFromLocalStorage, isUnauthorized,
} from "../../helpers/utils";
import {hasPermission, Permissions, roleHasAccess, Roles} from "../../helpers/accessControl";
import {useNavigate, useParams} from "react-router-dom";
import ThoughtsSection from "../commons/ThoughtsSection";
import {AccessDenied, AccessDeniedMsg, EXPONENT_SURVEY} from "../../helpers/constants";
import DashBoardMetrics from "./DashBoardMetrics";
import {getAllSurveysWithoutPagination, resetSurveyListReducer} from "../../reduxStore/surveySlices/surveySlices";
import Skeleton from "react-loading-skeleton";
import {getReportBySurveyId} from "../../reduxStore/reportsSlice/reportsSlice";
import MembersVsAttempts from "../commons/MembersVsAttempts";
import Select from "react-select";
import jwtDecode from "jwt-decode";
import {getAllOrgAsList} from "../../reduxStore/orgSlices/orgSlices";
import ErrorPage from "../errorPage/ErrorPage";
import UserInfo from "../layout/UserInfo";

const Dashboard = () => {

    const dispatch = useDispatch();
    const {orgName} = useParams();
    const [selectedReflectionId, setSelectedReflectionId] = useState(null)
    const survey = useSelector(state => state.survey)
    const reports = useSelector(state => state.reports)
    const [orgData, setOrgData] = useState({orgId:""})
    const navigate = useNavigate();


    const isSystemOrgAdmin = ((roleHasAccess([Roles.ORG_ADMIN,Roles.MANAGER])) && jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === "SYSTEM")
    const getAllOrgAsListData = useSelector(state => state.org.getAllOrgAsListReducer);

    useEffect(() => {
        dispatch(resetSurveyListReducer())
        if (hasPermission([Permissions.REFLECTION_READ], true) && !isSystemOrgAdmin) {
            dispatch(getAllSurveysWithoutPagination()).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                } else if (res.meta.requestStatus === "fulfilled") {
                    const defaultReflectionId=res?.payload?.data.filter(survey => survey.name === EXPONENT_SURVEY)[0]?.surveyId
                    setSelectedReflectionId((defaultReflectionId===undefined || defaultReflectionId===null )? res?.payload?.data[0]?.surveyId:defaultReflectionId)
                }
            })
        }

        if (isSystemOrgAdmin) {
            dispatch(getAllOrgAsList()).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }else if(res.meta.requestStatus==="fulfilled"){
                    const orgInfo = res.payload.data?.filter(org=>org.orgId===jwtDecode(getDataFromLocalStorage("token")).orgId)[0]
                    setOrgData({...orgData,orgId: {label:orgInfo.name ,value:orgInfo.orgId}})
                }
            })
        }

    }, [])

     useEffect( ()=>{
         if(orgData?.orgId){
                 dispatch(getAllSurveysWithoutPagination({orgId:orgData.orgId.value})).then(res => {
                     if (isUnauthorized(res)) {
                         navigate("/")
                     } else if (res.meta.requestStatus === "fulfilled") {
                         const defaultReflectionId=res?.payload?.data.filter(survey => survey.name === EXPONENT_SURVEY)[0]?.surveyId
                         setSelectedReflectionId((defaultReflectionId===undefined || defaultReflectionId===null )? res?.payload?.data[0]?.surveyId:defaultReflectionId)
                     }
                 })
         }

         if(orgData?.orgId == null){
             dispatch(getAllSurveysWithoutPagination()).then(res => {
                 if (isUnauthorized(res)) {
                     navigate("/")
                 } else if (res.meta.requestStatus === "fulfilled") {
                     const defaultReflectionId=res?.payload?.data.filter(survey => survey.name === EXPONENT_SURVEY)[0]?.surveyId
                     setSelectedReflectionId((defaultReflectionId===undefined || defaultReflectionId===null )? res?.payload?.data[0]?.surveyId:defaultReflectionId)
                 }
             })
         }


     },[orgData?.orgId])


    useEffect(() => {
        if (selectedReflectionId !== null  && selectedReflectionId !== undefined && hasPermission([Permissions.REPORT_READ], true)) {

            dispatch(getReportBySurveyId({surveyId:selectedReflectionId ,orgId:orgData?.orgId?.value})).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }
    }, [selectedReflectionId]);

    return  jwtDecode(getDataFromLocalStorage("token")).orgName !== orgName ?
        <ErrorPage value={403} description={AccessDenied} detailDesc={AccessDeniedMsg}/>
        :
        <>
        <section>
            <>
                    <div className="cmn_header">
                        <h2 className="mediumFontSize  org-heading m-0">Dashboard</h2> <UserInfo/>
                    </div>

                <div className="row main_content">


                    {
                        hasPermission([Permissions.REPORT_READ, Permissions.REFLECTION_READ], true) &&
                        <div className={"reflection-metrics-container"}>

                            <div className={"row reflection-metrics-tile-container"}>
                                <div className={"reflection-list mb-2 "}>

                                    {
                                        <div className={"dashboard-reflection-dropdown-outer ms-auto mb-1"}>
                                            {
                                                (survey?.getAllSurveysWithoutPaginationDataReducer?.loading) ?
                                                    <>
                                                        <div className={"ms-auto me-2"}>
                                                            <Skeleton  className={"  dashboard-skeleton"}  height={40}/>
                                                        </div>

                                                    </>
                                                    :
                                                    <div className={"dashboard-reflection-outer ms-auto me-2"} >
                                                        <div className={"select-reflection-txt"}>Reflection:</div>
                                                        <select
                                                            onChange={(e) => {
                                                                setSelectedReflectionId(e.target.value);
                                                            }}
                                                            value={selectedReflectionId !== null ? selectedReflectionId : ""}
                                                            className={"form-select dashboard-reflection-dropdown  truncate-select"}
                                                        >
                                                            {survey?.getAllSurveysWithoutPaginationDataReducer?.data?.length === 0 ? (
                                                                <option value="" selected disabled>Data Not Found</option>
                                                            ) : (
                                                                survey?.getAllSurveysWithoutPaginationDataReducer?.data?.map((reflection, index) => (
                                                                    <option key={index} value={reflection.surveyId}>
                                                                        {reflection.name}
                                                                    </option>
                                                                ))
                                                            )}
                                                        </select>

                                                    </div>
                                            }

                                            { isSystemOrgAdmin &&
                                                <Select
                                                    className="members-filter-role react-select-container"
                                                    classNamePrefix="react-select"
                                                    isDisabled={getAllOrgAsListData?.loading}
                                                    value={orgData?.orgId}
                                                    options={createOptionListForSelectTag(getAllOrgAsListData?.data, "name", "orgId")}
                                                    onChange={(selectedOrg) => {
                                                        if(orgData?.orgId?.value!==selectedOrg?.value){
                                                            setOrgData({...orgData, orgId: selectedOrg})
                                                        }

                                                    }}
                                                    isClearable={true}
                                                    placeholder={"Organization..."}/>
                                            }


                                        </div>
                                    }
                                </div>
                                <div className={"col-lg-6 col-xl-4 high-risk-tile-outer"}>
                                    <DashBoardMetrics
                                        selectedReflectionId={selectedReflectionId}
                                        isLoading={reports?.getReportBySurveyIdDataReducer?.loading || survey?.getAllSurveysWithoutPaginationDataReducer?.loading || getAllOrgAsListData?.loading}
                                        data={selectedReflectionId === undefined ? undefined : reports?.getReportBySurveyIdDataReducer?.data?.find(data => data.riskLevel === "HIGH_RISK")} orgId={orgData.orgId}/>
                                </div>
                                <div className={"col-lg-6 col-xl-4 low-risk-tile-outer"}>
                                    <DashBoardMetrics
                                        selectedReflectionId={selectedReflectionId}
                                        isLoading={reports?.getReportBySurveyIdDataReducer?.loading || survey?.getAllSurveysWithoutPaginationDataReducer?.loading || getAllOrgAsListData?.loading}
                                        data={selectedReflectionId === undefined ? undefined : reports.getReportBySurveyIdDataReducer?.data?.find(data => data.riskLevel === "LOW_RISK")} orgId={orgData.orgId}/>
                                </div>
                                <div className={"col-lg-6 col-xl-4 good-tile-outer"}>
                                    <DashBoardMetrics
                                        selectedReflectionId={selectedReflectionId}
                                        isLoading={reports?.getReportBySurveyIdDataReducer?.loading || survey?.getAllSurveysWithoutPaginationDataReducer?.loading || getAllOrgAsListData?.loading}
                                        data={selectedReflectionId === undefined ? undefined : reports?.getReportBySurveyIdDataReducer?.data?.find(data => data.riskLevel === "GOOD")} orgId={orgData.orgId}/>
                                </div>
                            </div>
                        </div>
                    }
                    {hasPermission([Permissions.USER_READ,Permissions.REPORT_READ], true) && !roleHasAccess([Roles?.MENTEE]) &&

                        <div
                            className={hasPermission([Permissions.USER_READ,Permissions.REPORT_READ], true) ? 'col-md-12 col-lg-12 col-sm-12 ps-0 mt-1' : 'col-12 ps-0 mt-1 permission_check'}>
                            <div className={" members-table-outer pe-0"}>
                                <h4 className='mediumFontSize ps-2 mt-2 mb-3 custom_font'>Members</h4>
                                <MembersVsAttempts isSubOrg={false} subOrgId={undefined} orgId={orgData?.orgId}/>
                            </div>

                        </div>
                    }

                    {hasPermission([Permissions.NOTE_READ], true) &&
                        <ThoughtsSection isDashBoard={true}
                                         memberId={getDataFromLocalStorage("loggedInUserInfo")?.userId} orgId={orgData?.orgId}
                                         />}

                </div>
            </>


        </section>


    </>
}
export default Dashboard;