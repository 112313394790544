import SignUp from "../authentication/signUp/SignUp";
import {useLocation} from "react-router-dom";
import UserInfo from "../layout/UserInfo";

const CreateOrganizationForm = () => {
    const {pathname}=useLocation();
    const urlParams = new URLSearchParams(window.location.search);
     const myParam = urlParams.get('parentOrgId');
    return (
        <>
            <section>
                <div className="cmn_header">
                    <h2 className="mediumFontSize  org-heading m-0">Create {pathname.startsWith("/subOrganizations")?"Sub Organization":" Organization"}</h2> <UserInfo/>
                </div>
                <div className="row main_content">
                    <div className={"col-md-12 p-0"}>
                        <div className={"roles_outer"}>
                            {/*<div className={"sub-org_heading_outer"}>*/}
                            {/*    <h2 className="survey_heading mediumFontSize mt-2 reports-txt">Create {pathname.startsWith("/subOrganizations")?"Sub Organization":" Organization"}</h2>*/}
                            {/*</div>*/}
                            <SignUp reference={pathname.startsWith("/subOrganizations")?"CREATE_SUB_ORG":"CREATE_ORG"} parentOrgId={myParam}/>



                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default CreateOrganizationForm