import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import {encryptData, getStipeConfig, serialize, showToast} from "../../helpers/utils";
import axios from "axios";
import {getAxiosConfig} from "../../helpers/utils";
import {LinkExpired} from "../../helpers/constants";


export const loginUser = createAsyncThunk('user/loginUser', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/login`, data).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    })
})

export const signUp = createAsyncThunk('user/signUp', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/org`, data)
        .then(res => {
            return res.data;
        }).catch(error => {
            return thunkAPI.rejectWithValue(error.response);
        })
})
export const getAllUsersByOrgId = createAsyncThunk('user/getAllUsersByOrgId', async (data, thunkAPI) => {
    const serializeData = serialize({
        page: data.page,
        sortBy: data.sortBy,
        sortOrder: data.sortOrder,
        pageSize:data?.pageSize || null
    })
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/user/org/${data.orgId}?${serializeData}`, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    })
})


export const createPassword = createAsyncThunk('user/createPassword', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/user/create-password`, data)
        .then(res => {
            return res.data
        }).catch(error => {
            return thunkAPI.rejectWithValue(error.response)
        })

})

export const getLoggedInUserData = createAsyncThunk('user/getLoggedInUserData', async (email, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/user/email/${email}`, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
})
export const getUserById = createAsyncThunk('user/getUserById', async (userId, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/user/${userId}`, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
})


export const createUser = createAsyncThunk('user/createUser', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/user/create`, data, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
})


export const addUser = createAsyncThunk('user/addUser', async (data, thunkAPI) => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/user/invite-user/${data.userEmail}/${data.roleId}`,
        headers: getAxiosConfig().headers
    };
    return await axios.request(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return thunkAPI.rejectWithValue(error.response);
        });

})
export const getAllUsers = createAsyncThunk('user/getAllUsers', async (data, thunkAPI) => {
    const serializedData = serialize({subOrgId: data?.subOrgId, orgId: data?.orgId});
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/user?${serializedData}`, data, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    });
})

export const deleteUserById = createAsyncThunk('user/deleteUserById', async ({userId}, thunkAPI) => {
    return await axios.delete(`${process.env.REACT_APP_BASE_URL}/user/${userId}`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})


export const bulkUserDelete = createAsyncThunk(
    'survey/bulkUserDelete',
    async ({selectCheckBoxItems , setSelectCheckBoxItems}, thunkAPI) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/user/bulk-delete-users`,
                {data: selectCheckBoxItems || [], ...getAxiosConfig()});
            setSelectCheckBoxItems && setSelectCheckBoxItems([])
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);


export const getAllUsersWithoutPaginationAddReflectionBtn = createAsyncThunk('user/getAllUsersWithoutPaginationAddReflectionBtn', async (data, thunkAPI) => {
    const serializedData = serialize(data)
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/user/find-all?${serializedData}`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    });
})
export const getAllUsersWithoutPagination = createAsyncThunk('user/getAllUsersWithoutPagination', async (data, thunkAPI) => {
    const serializedData = serialize(data)
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/user/find-all?${serializedData}`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    });
})

export const getAllUsersByRolesPagination = createAsyncThunk('user/getAllUsersByRolesPagination', async (data, thunkAPI) => {
    const serializedData = serialize(data)
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/user/find-all-roles-pagination?${serializedData}`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    });
})

export const usersImports = createAsyncThunk('user/importUsers', async (data, thunkAPI) => {
    try {
        const formData = new FormData();
        formData.append('file', data);
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/import-users-via-files`, formData, getAxiosConfig(),{
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response);
    }
});

export const getAllUsersMentees = createAsyncThunk('user/getAllUsersMentees', async (data, thunkAPI) => {
    const serializedData = serialize(data)
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/user/mentees?${serializedData}`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    });
})
export const getAllUsersWithoutPaginationForSubOrg = createAsyncThunk('user/getAllUsersWithoutPaginationForSubOrg', async (data, thunkAPI) => {
    const serializedData = serialize(data)
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/user/find-all?${serializedData}`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    });
})
export const forgetPassword = createAsyncThunk('user/forgetPassword', async (email, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/user/forget-password?email=${email}`).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    });
})
export const updateUserById = createAsyncThunk('user/updateUserById', async (data, thunkAPI) => {
    return await axios.put(`${process.env.REACT_APP_BASE_URL}/user/${data.userId}`, {
        firstName: data.firstName,
        lastName: data.lastName,
        orgId: data.orgId,
        enable: data.enable,
        roles: data.roles,
        mentorIds:data.mentorIds,
        tags:data.tags
        // ...(data.hasOwnProperty("mentorId") ? {mentorId: data.mentorId} : {}),
    }, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    });
})

export const updateUserOrganization = createAsyncThunk('user/updateUserOrganization', async (data, thunkAPI) => {
    return await axios.put(`${process.env.REACT_APP_BASE_URL}/user/move-user-organization?orgId=${data.orgId}&userId=${data.userId}`, {}, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    });
})

export const resetPassword = createAsyncThunk('user/resetPassword', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/user/reset-password`, data, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    });

})
export const refreshToken = createAsyncThunk('user/refreshToken', async (data, thunkAPI) => {
    const serializedData = serialize(data)
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/user/update-token?${serializedData}`,
        headers: getAxiosConfig().headers
    };
    return await axios.request(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return thunkAPI.rejectWithValue(error.response);
        });
})
export const getSubscriptionData = createAsyncThunk('user/getSubscriptionData', async (data, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/user-subscription/`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})
export const cancelSubscription = createAsyncThunk('user/cancelSubscription', async (userId, thunkAPI) => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/user-subscription/cancel?userId=${userId}`,
        headers: getAxiosConfig().headers
    };
    return await axios.request(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return thunkAPI.rejectWithValue(error.response);
        });
})
export const sentInviteToUpdateRoleToPulseManager = createAsyncThunk('user/sentInviteToUpdateRoleToPulseManager', async (data, thunkAPI) => {
    const serializedData = serialize(data)
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/user/assign-pulse-manager-role?${serializedData}`, data, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})
export const updateRoleToPulseManager = createAsyncThunk('user/updateRoleToPulseManager', async (data, thunkAPI) => {
    const serializedData = serialize(data)
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/user/update-pulse-manager-role?${serializedData}`, data).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})


export const sampleCSVFile = createAsyncThunk('user/sampleCSVFile', async (data, thunkAPI) => {
    const serializedData = serialize({ isCsv: data });
    try {
        return  await axios.post(
            `${process.env.REACT_APP_BASE_URL}/user/csv-sample-file?${serializedData}`,
            "",
            {
                ...getAxiosConfig(),
                responseType: 'arraybuffer',
            }
        );
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const exportMembers = createAsyncThunk('reports/exportMembers', async (data, thunkAPI) => {
    const serializedData = serialize({subOrgId: data?.subOrgId, orgId: data?.orgId});
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/user/exportsUsers?${serializedData}`,
        headers: getAxiosConfig().headers,
        data: data
    };
    return await axios.request(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return thunkAPI.rejectWithValue(error.response);
        });
})
export const revokePulseManagerRoleByOrgAdmin = createAsyncThunk('user/revokePulseManagerRoleByOrgAdmin', async (data, thunkAPI) => {
    const serializedData = serialize(data)
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/user/revoke-pulse-manager-role?${serializedData}`, data, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})
export const revokePulseManagerRoleBySysAdmin = createAsyncThunk('user/revokePulseManagerRoleBySysAdmin', async (data, thunkAPI) => {
    const serializedData = serialize(data)
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/user/revoke-pulse-manager-role-by-sys-admin?${serializedData}`, data, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

const userSlice = createSlice({
    name: "user",
    initialState: {
        loginUserReducer: {loading: false},
        signUpReducer: {loading: false},
        getAllUsersByOrgIdReducer: {loading: false},
        createPasswordReducer: {loading: false},
        getLoggedInUserDataReducer: {loading: false},
        getUserByIdReducer: {loading: false},
        createUserReducer: {loading: false},
        addUserReducer: {loading: false},
        getAllUsersReducer: {loading: false},
        getAllUsersWithoutPaginationReducer: {loading: false},
        getAllUsersByRolesPaginationReducer: {loading: false},
        userImportReducer: {loading: false},
        getAllUsersMenteesReducer: {loading: false},
        getAllUsersWithoutPaginationForSubOrgReducer: {loading: false},
        getAllUsersWithoutPaginationAddReflectionBtnReducer:{loading:false},
        forgetPasswordReducer: {loading: false},
        updateUserByIdReducer: {loading: false},
        updateUserOrganizationReducer: {loading: false},
        resetPasswordReducer: {loading: false},
        refreshTokenReducer: {loading: false},
        deleteUserByIdReducer: {loading: false},
        bulkUserDeleteReducer: {loading: false},
        getSubscriptionDataReducer: {loading: false},
        cancelSubscriptionReducer: {loading: false},
        sentInviteToUpdateRoleToPulseManagerReducer: {loading: false},
        updateRoleToPulseManagerReducer: {loading: false},
        sampleCSVFileReducer: {loading: false},
        exportMembersReducer: {loading: false},
        revokePulseManagerRoleByOrgAdminReducer: {loading: false},
        revokePulseManagerRoleBySysAdminReducer:{loading: false},

    },
    reducers: {
        resetGetAllUsersWithoutPaginationReducer: (state) => {
            state.getAllUsersWithoutPaginationReducer = {loading: false, data: null}
        },
        resetGetUserByIdReducer: (state) => {
            state.getUserByIdReducer = {loading: false, data: null}
        },
        resetGetAllUsersWithoutPaginationForSubOrgReducer: (state) => {
            state.getAllUsersWithoutPaginationForSubOrgReducer = {loading: false, data: null}
        },
        resetGetAllUsersMenteesReducer: (state) => {
            state.getAllUsersMenteesReducer = {loading: false, data: null}
        },
        setGetAllUsersLoadingReducer:(state,action)=>{
            state.getAllUsersReducer={loading:action.payload}
        },

    },
    extraReducers: {
        // Get User By Id
        [getUserById.pending]: (state) => {
            state.getUserByIdReducer = {loading: true}
        },
        [getUserById.fulfilled]: (state, action) => {
            //toast.success(action.payload.data)
            state.getUserByIdReducer = {loading: false, data: action.payload.data}
        },
        [getUserById.rejected]: (state, action) => {
            state.getUserByIdReducer = {loading: false}
            if (!(action.payload.errorCode.value === 400 || action.payload.errorCode.value === 404)) {
                showToast(action?.payload?.detailDesc || action?.payload?.statusText, "error")
            }
        },

        // delete user by id

        [deleteUserById.pending]: (state) => {
            state.deleteUserByIdReducer = {loading: true};
        },
        [deleteUserById.fulfilled]: (state, action) => {
            state.deleteUserByIdReducer = {loading: false, data: action.payload.data};
            showToast(action?.payload?.data, "success")
        },
        [deleteUserById.rejected]: (state, action) => {
            state.deleteUserByIdReducer = {loading: false};
            showToast(action?.payload?.detailDesc, "error")
        },


        //bulk-delete
        [bulkUserDelete.pending]: (state) => {
            state.bulkUserDeleteReducer = {loading: true};
        },
        [bulkUserDelete.fulfilled]: (state, action) => {
            state.bulkUserDeleteReducer = {loading: false, data: action?.payload?.data};
            showToast(action?.payload?.data, "success")
        },
        [bulkUserDelete.rejected]: (state, action) => {
            state.bulkUserDeleteReducer = {loading: false};
            showToast(action?.payload?.detailDesc, "error")

        },

        //login-user
        [loginUser.pending]: (state, action) => {
            state.loginUserReducer = {loading: true}
        },
        [loginUser.fulfilled]: (state, action) => {
            state.loginUserReducer = {loading: false}

        },
        [loginUser.rejected]: (state, action) => {
            state.loginUserReducer = {loading: false}
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText, "error")
        },
        //Get-All-users
        [getAllUsers.pending]: (state, action) => {
            state.getAllUsersReducer = {loading: true}
        },
        [getAllUsers.fulfilled]: (state, action) => {
            state.getAllUsersReducer = {loading: false, data: action.payload.data}
        },
        [getAllUsers.rejected]: (state, action) => {
            state.getAllUsersReducer = {loading: false}
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText, "error")
        },

        //getAllUsersMenteesReducer


        [getAllUsersMentees.pending]: (state, action) => {
            state.getAllUsersMenteesReducer = {loading: true}
        },
        [getAllUsersMentees.fulfilled]: (state, action) => {
            state.getAllUsersMenteesReducer = {loading: false, data: action.payload.data}
        },
        [getAllUsersMentees.rejected]: (state, action) => {
            state.getAllUsersMenteesReducer = {loading: false}
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText, "error")
        },

        // getLoggedInUserData
        [getLoggedInUserData.pending]: (state, action) => {
            state.getLoggedInUserDataReducer = {loading: true}
        },
        [getLoggedInUserData.fulfilled]: (state, action) => {
            state.getLoggedInUserDataReducer = {loading: false, data: action.payload}
            localStorage.setItem("loggedInUserInfo", encryptData(state.getLoggedInUserDataReducer.data.data))

        },
        [getLoggedInUserData.rejected]: (state, action) => {
            state.getLoggedInUserDataReducer = {loading: false}
            showToast(action?.payload?.detailDesc, "error")
        },

        //create-Org and Admin
        [signUp.pending]: (state, action) => {
            state.signUpReducer = {loading: true}
        },
        [signUp.fulfilled]: (state, action) => {
            state.signUpReducer = {loading: false, data: action.payload.data};
        },
        [signUp.rejected]: (state, action) => {
            state.signUpReducer = {loading: false}
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText, "error")
        },
        // Create-Password
        [createPassword.pending]: (state, action) => {
            state.createPasswordReducer = {loading: true}
        },
        [createPassword.fulfilled]: (state, action) => {
            state.createPasswordReducer = {loading: false}
            showToast(action?.payload?.data, "success")
        },
        [createPassword.rejected]: (state, action) => {
            state.createPasswordReducer = {loading: false}
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText, "error")
        },
        // SignUp-Email
        [addUser.pending]: (state) => {
            state.addUserReducer = {loading: true}
        },
        [addUser.fulfilled]: (state, action) => {
            state.addUserReducer = {loading: false, data: action.payload.data}
            showToast(action?.payload?.detailDesc || action?.payload?.statusText, "success")
        },
        [addUser.rejected]: (state, action) => {
            state.addUserReducer = {loading: false}
            showToast(action?.payload?.data?.detailDesc, "error")
        },
        // Reset Password
        [resetPassword.pending]: (state) => {
            state.resetPasswordReducer = {loading: true}
        },
        [resetPassword.fulfilled]: (state, action) => {
            state.resetPasswordReducer = {loading: false, data: action.payload.data}
            toast.success(action.payload.data)

        },
        [resetPassword.rejected]: (state, action) => {
            state.resetPasswordReducer = {loading: false}
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText, "error")
        },
        // Update User By Id
        [updateUserById.pending]: (state) => {
            state.updateUserByIdReducer = {loading: true}
        },
        [updateUserById.fulfilled]: (state, action) => {
            state.updateUserByIdReducer = {loading: false, data: action.payload.data}
            showToast(action?.payload?.data, "success")

        },
        [updateUserById.rejected]: (state, action) => {
            state.updateUserByIdReducer = {loading: false}
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText, "error")
        },


        [updateUserOrganization.pending]: (state) => {
            state.updateUserOrganizationReducer = {loading: true}
        },
        [updateUserOrganization.fulfilled]: (state, action) => {
            state.updateUserOrganizationReducer = {loading: false, data: action.payload.data}
            showToast(action?.payload?.data, "success")

        },
        [updateUserOrganization.rejected]: (state, action) => {
            state.updateUserOrganizationReducer = {loading: false}
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText, "error")
        },


        // Create User
        [createUser.pending]: (state) => {
            state.createUserReducer = {loading: true}
        },
        [createUser.fulfilled]: (state, action) => {
            state.createUserReducer = {loading: false, data: action.payload.data}
            showToast(action?.payload?.data, "success")
        },
        [createUser.rejected]: (state, action) => {
            state.createUserReducer = {loading: false}
            showToast(action?.payload?.detailDesc || action?.payload?.statusText, "error")
        },
        // Get All Users By Org Id
        [getAllUsersByOrgId.pending]: (state) => {
            state.getAllUsersByOrgIdReducer = {loading: true}
        },
        [getAllUsersByOrgId.fulfilled]: (state, action) => {
            state.getAllUsersByOrgIdReducer = {loading: false, data: action.payload.data}
        },
        [getAllUsersByOrgId.rejected]: (state, action) => {
            state.getAllUsersByOrgIdReducer = {loading: true}
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText, "error")
        },
        // Forget Password
        [forgetPassword.pending]: (state) => {
            state.forgetPasswordReducer = {loading: true}
        },
        [forgetPassword.fulfilled]: (state, action) => {
            state.forgetPasswordReducer = {loading: false, data: action.payload.data}
            showToast(action?.payload?.data, "success")

        },
        [forgetPassword.rejected]: (state, action) => {
            state.forgetPasswordReducer = {loading: false}
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText, "error")
        },

        // get All Users WithoutPagination
        [getAllUsersWithoutPagination.pending]: (state) => {
            state.getAllUsersWithoutPaginationReducer = {loading: true}
        },
        [getAllUsersWithoutPagination.fulfilled]: (state, action) => {
            state.getAllUsersWithoutPaginationReducer = {
                loading: false,
                data: action.payload.data,
            }

        },
        [getAllUsersWithoutPagination.rejected]: (state, action) => {
            state.getAllUsersWithoutPaginationReducer = {loading: false}
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText, "error")
        },

        // getAllUsersByRolesPagination
        [getAllUsersByRolesPagination.pending]: (state) => {
            state.getAllUsersByRolesPaginationReducer = {loading: true}
        },
        [getAllUsersByRolesPagination.fulfilled]: (state, action) => {
            state.getAllUsersByRolesPaginationReducer = {
                loading: false,
                data: action.payload.data,
            }

        },
        [getAllUsersByRolesPagination.rejected]: (state, action) => {
            state.getAllUsersByRolesPaginationReducer = {loading: false}
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText, "error")
        },

        // usersImports All Users
        [usersImports.pending]: (state) => {
            state.userImportReducer = {loading: true}
        },
        [usersImports.fulfilled]: (state, action) => {
            state.userImportReducer = {loading: false, data: action.payload.data}

        },
        [usersImports.rejected]: (state, action) => {
            state.userImportReducer = {loading: false}
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText, "error")
        },

        // get All Users WithoutPagination Add Reflection Btn
        [getAllUsersWithoutPaginationAddReflectionBtn.pending]: (state) => {
            state.getAllUsersWithoutPaginationAddReflectionBtnReducer = {loading: true}
        },
        [getAllUsersWithoutPaginationAddReflectionBtn.fulfilled]: (state, action) => {
            state.getAllUsersWithoutPaginationAddReflectionBtnReducer = {
                loading: false,
                data: action.payload.data,
            }

        },
        [getAllUsersWithoutPaginationAddReflectionBtn.rejected]: (state, action) => {
            state.getAllUsersWithoutPaginationAddReflectionBtnReducer = {loading: false}
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText, "error")
        },

        // get All Users WithoutPagination For Sub Org
        [getAllUsersWithoutPaginationForSubOrg.pending]: (state) => {
            state.getAllUsersWithoutPaginationForSubOrgReducer = {loading: true}
        },
        [getAllUsersWithoutPaginationForSubOrg.fulfilled]: (state, action) => {
            state.getAllUsersWithoutPaginationForSubOrgReducer = {loading: false, data: action.payload.data}

        },
        [getAllUsersWithoutPaginationForSubOrg.rejected]: (state, action) => {
            state.getAllUsersWithoutPaginationForSubOrgReducer = {loading: false}
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText, "error")
        },

        // Refresh Token
        [refreshToken.pending]: (state) => {
            state.refreshTokenReducer = {loading: true}
        },
        [refreshToken.fulfilled]: (state, action) => {
            state.refreshTokenReducer = {loading: false, data: action.payload.data}

        },
        [refreshToken.rejected]: (state, action) => {
            state.refreshTokenReducer = {loading: false}
            showToast(action?.payload?.detailDesc || action?.payload?.statusText, "error")
        },

        // Cancel Subscription
        [cancelSubscription.pending]: (state) => {
            state.cancelSubscriptionReducer = {loading: true}
        },
        [cancelSubscription.fulfilled]: (state, action) => {
            state.cancelSubscriptionReducer = {loading: false, data: action.payload.data}
            showToast(action?.payload?.data, "success")

        },
        [cancelSubscription.rejected]: (state, action) => {
            state.cancelSubscriptionReducer = {loading: false}
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText, "error")
        },

        // Get Subscription Data
        [getSubscriptionData.pending]: (state) => {
            state.getSubscriptionDataReducer = {loading: true}
        },
        [getSubscriptionData.fulfilled]: (state, action) => {
            state.getSubscriptionDataReducer = {loading: false, data: action.payload.data}

        },
        [getSubscriptionData.rejected]: (state, action) => {
            state.getSubscriptionDataReducer = {loading: false}
            showToast(action?.payload?.detailDesc || action?.payload?.statusText, "error")
        },

        // Sent Invite To Update Role To Pulse Manager
        [sentInviteToUpdateRoleToPulseManager.pending]: (state) => {
            state.sentInviteToUpdateRoleToPulseManagerReducer = {loading: true}
        },
        [sentInviteToUpdateRoleToPulseManager.fulfilled]: (state, action) => {
            showToast(action?.payload?.data, "success")
            state.sentInviteToUpdateRoleToPulseManagerReducer = {loading: false, data: action.payload.data}

        },
        [sentInviteToUpdateRoleToPulseManager.rejected]: (state, action) => {
            state.sentInviteToUpdateRoleToPulseManagerReducer = {loading: false}
            showToast(action?.payload?.detailDesc || action?.payload?.statusText, "error")
        },

        // sampleCSVFile
        [sampleCSVFile.pending]: (state) => {
            state.sampleCSVFileReducer = {loading: true}
        },
        [sampleCSVFile.fulfilled]: (state, action) => {
            state.sampleCSVFileReducer = {loading: false, data: action.payload.data}

        },
        [sampleCSVFile.rejected]: (state, action) => {
            state.sampleCSVFileReducer = {loading: false}
            showToast(action?.payload?.detailDesc || action?.payload?.statusText, "error")
        },


        // export users
        [exportMembers.pending]: (state) => {
            state.exportMembersReducer = {loading: true}
        },
        [exportMembers.fulfilled]: (state, action) => {
            state.exportMembersReducer = {loading: false, data: action.payload.data}

        },
        [exportMembers.rejected]: (state, action) => {
            state.exportMembersReducer = {loading: false}
            showToast(action?.payload?.detailDesc || action?.payload?.statusText, "error")
        },


        // Update Role To Pulse Manager Reducer
        [updateRoleToPulseManager.pending]: (state) => {
            state.updateRoleToPulseManagerReducer = {loading: true}
        },
        [updateRoleToPulseManager.fulfilled]: (state, action) => {
            if(action.payload.data === LinkExpired){
                showToast(action?.payload?.data, "error")
            }else{
                showToast(action?.payload?.data, "success")
            }
            state.updateRoleToPulseManagerReducer = {loading: false, data: action.payload.data}

        },
        [updateRoleToPulseManager.rejected]: (state, action) => {
            state.updateRoleToPulseManagerReducer = {loading: false}
            showToast(action?.payload?.detailDesc || action?.payload?.statusText, "error")
        },
        // Revoke Pulse Manager Role Promoted By Org Admin
        [revokePulseManagerRoleByOrgAdmin.pending]: (state) => {
            state.revokePulseManagerRoleByOrgAdminReducer = {loading: true}
        },
        [revokePulseManagerRoleByOrgAdmin.fulfilled]: (state, action) => {
            showToast(action?.payload?.data, "success")
            state.revokePulseManagerRoleByOrgAdminReducer = {loading: false, data: action.payload.data}

        },
        [revokePulseManagerRoleByOrgAdmin.rejected]: (state, action) => {
            state.revokePulseManagerRoleByOrgAdminReducer = {loading: false}
            showToast(action?.payload?.detailDesc || action?.payload?.statusText, "error")
        },

        // Revoke Pulse Manager Role By Sys Admin
        [revokePulseManagerRoleBySysAdmin.pending]: (state) => {
            state.revokePulseManagerRoleBySysAdminReducer = {loading: true}
        },
        [revokePulseManagerRoleBySysAdmin.fulfilled]: (state, action) => {
            showToast(action?.payload?.data, "success")
            state.revokePulseManagerRoleBySysAdminReducer = {loading: false, data: action.payload.data}

        },
        [revokePulseManagerRoleBySysAdmin.rejected]: (state, action) => {
            state.revokePulseManagerRoleBySysAdminReducer = {loading: false}
            showToast(action?.payload?.detailDesc || action?.payload?.statusText, "error")
        },

    }
})

export const {
    resetGetAllUsersWithoutPaginationReducer,
    resetGetUserByIdReducer,
    resetGetAllUsersWithoutPaginationForSubOrgReducer,
    setGetAllUsersLoadingReducer,
    resetGetAllUsersMenteesReducer
} = userSlice.actions
export default userSlice.reducer;


