import './CustomCriteria.css'
import {useEffect, useState} from "react";
import {CreateTriggerModal} from "./Modal/CreateTriggerModal";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {deleteCriteria, getAllCriteria, upsertCriteria} from "../../reduxStore/customEventSlices/customEvent";
import {
    formatDate,
    isUnauthorized,
    tableCustomStyling,
    TableSkeletonLoading,

} from "../../helpers/utils";
import DataTable from "react-data-table-component";
import ConfirmationModel from "../commons/ConfirmationModel";
import Pagination from "../commons/Pagination";
import UserInfo from "../layout/UserInfo";
import {FiTrash2} from "react-icons/fi";
import {GoPencil} from "react-icons/go";

const CustomCriteria = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showCriteria, setShowCriteria] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [triggerStatus, setTriggerStatus] = useState(null);
    const [eventId, setEventId] = useState(null)
    const [sortBy, setSortBy] = useState("createdAt");
    const [sortOrder, setSortOrder] = useState("desc")
    const [pageNumber, setPageNumber] = useState(1);
    const [formType, setFromType] = useState(null)
    const customCriteria = useSelector(state => state.customCriteria);

    useEffect(() => {
        const data = {
            page: pageNumber,
            sortBy: sortBy,
            sortOrder: sortOrder,
            pageSize: 10
        }
        dispatch(getAllCriteria(data)).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
        });

    }, [sortBy, sortOrder, pageNumber])


    const handleDeleteTrigger = () => {
        if (eventId !== null && eventId !== "") {
            dispatch(deleteCriteria(eventId)).then((response) => {
                if (isUnauthorized(response)) {
                    navigate("/")
                }
                if (response.meta.requestStatus === "fulfilled") {
                    setShowConfirmationModal(false)
                    setEventId(null)
                    const data = {
                        page: 1,
                        sortBy: "createdAt",
                        sortOrder: "desc",
                        pageSize: 10

                    }
                    dispatch(getAllCriteria(data)).then(res => {
                        if (isUnauthorized(res)) {
                            navigate("/")
                        }
                    });

                }
            });

        }
    }

    const upsertTrigger = () => {
        if (eventId !== null && eventId !== "" && triggerStatus !== null) {
            const data= {id:eventId, enable:triggerStatus}
            dispatch(upsertCriteria(data)).then((response) => {
                if (isUnauthorized(response)) {
                    navigate("/")
                }
                if (response.meta.requestStatus === "fulfilled") {
                    setShowConfirmationModal(false)
                    setEventId(null)
                    const data = {
                        page: 1,
                        sortBy: "createdAt",
                        sortOrder: "desc",
                        pageSize: 10

                    }
                    dispatch(getAllCriteria(data)).then(res => {
                        if (isUnauthorized(res)) {
                            navigate("/")
                        }
                    });

                }
            });

        }
    }

    const columns = [
        {
            name: "Trigger Name",
            selector: row => row?.operandName,
            style: {
                fontFamily: 'General Sans Medium',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            }
        },

        {
            name: "Trigger Description",
            selector: row => row?.operandDescription,
            style: {
                fontFamily: 'General Sans Medium',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            }
        },
        {
            name: "Created Date",
            selector: row => row.createdAt === "" ? "----" : (formatDate(row.createdAt)),
            sortField: 'createdAt',
            sortable: true,
            cell: row => <div>{row.createdAt === "" ? "----" : (formatDate(row.createdAt))}</div>,
        },
        {
            name: "Action",
            cell: row => (
                <>
                    <div className={"action-icons"}>

                        <GoPencil
                            className="assign-icon " onClick={() => {
                            setEventId(row?.id)
                            setShowCriteria(true)
                            setFromType("Update")
                        }}
                        />



                        <FiTrash2
                            className="fa fa-trash trash-icon ms-2" onClick={() => {
                            setEventId(row?.id)
                            setShowConfirmationModal(true)
                            setTriggerStatus(null)
                        }}

                        />


                        {row.enable === true
                            ?
                            <>
                                <i className="fa fa-check-circle check-icon ms-2 " onClick={() => {
                                    setEventId(row?.id)
                                    setTriggerStatus(!row.enable)
                                    setShowConfirmationModal(true)

                                }}/>
                            </>
                            :
                            <>
                                <i className="fa fa-times-circle  cross-icon ms-2 " onClick={() => {
                                    setEventId(row?.id)
                                    setTriggerStatus(!row.enable)
                                    setShowConfirmationModal(true)
                                }}/>
                            </>
                        }
                    </div>
                </>),
        }
    ].filter(Boolean)
    const handleSort = (column, sortDirection,) => {
        setSortBy(column.sortField)
        setSortOrder(sortDirection)
    };
    return (

        <>
            <section>

                <div className="cmn_header">
                  <h2 className="mediumFontSize m-0">Triggers</h2> <UserInfo/>
                </div>
                <div className={"row main_content mt-3"}>
                    <div className={"col-xl-12"}>
                        <div className={"create-criteria"}>
                            <button
                                style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined || localStorage.getItem("color") !== "undefined" ? localStorage.getItem("color") : ""}}
                                className={"cmn_background_color mediumFontSize"} onClick={() => {
                                setShowCriteria(true)
                                setFromType("Submit")
                            }}> Create Trigger
                            </button>
                        </div>
                    </div>

                    <div className={"colored_table"}>
                    <DataTable
                        className={"" + (customCriteria.getSearchCriteriaDataReducer?.data?.totalPage > 1 ? " table_design2 " : " table_design1 p-0 ")}
                        persistTableHead={true} responsive={true}
                        customStyles={tableCustomStyling}
                        progressPending={customCriteria.getSearchCriteriaDataReducer?.loading}
                        columns={columns} data={customCriteria.getSearchCriteriaDataReducer?.data?.items}
                        noDataComponent={<div className={"no-member-text"}>No Trigger Found</div>}
                        progressComponent={<TableSkeletonLoading columns={4} rows={4}/>}
                        onSort={handleSort} sortServer={true}

                    />

                    {customCriteria.getSearchCriteriaDataReducer?.data && customCriteria.getSearchCriteriaDataReducer?.data?.totalPage > 1 &&
                        <Pagination className={"pagination-border"}
                                    totalPages={customCriteria.getSearchCriteriaDataReducer?.data?.totalPage}
                                    pageNumber={pageNumber}
                                    setPageNumber={setPageNumber}/>}
                </div>
                </div>
            </section>
            {showConfirmationModal &&
                <ConfirmationModel title={ triggerStatus=== null ? "Delete Trigger" : (triggerStatus  === false ? "Disable Trigger " : "Enable Trigger")}
                                   btnText={triggerStatus === null ? "Delete" : (triggerStatus === false ? "Disable" : "Enable")}
                                   isLoading={triggerStatus !== null ? customCriteria?.upsertCriteriaByIdReducer?.loading : customCriteria?.deleteCriteriaByIdReducer?.loading}
                                   action={triggerStatus !== null ? upsertTrigger : handleDeleteTrigger}
                                   show={showConfirmationModal}
                                   bodyMessage={triggerStatus === null ? "Are you sure you want to proceed with the deletion?" : (triggerStatus === false ? "Are you sure you want to proceed with  disabling the trigger?." :"Are you sure you want to proceed with enabling the trigger?")}
                                   setShow={setShowConfirmationModal}
                />
            }
            {showCriteria && <CreateTriggerModal show={showCriteria} setShow={setShowCriteria} eventId={eventId} setEventId={setEventId} fromType={formType}/>}

        </>
    )

}
export default CustomCriteria;