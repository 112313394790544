import {combineReducers, configureStore} from "@reduxjs/toolkit";
import userSlice from "./usersSlices/userSlice";
import teamSlice from "./teamSlices/teamSlice";
import notesSlice from "./notesSlices/notesSlice";
import thunk from "redux-thunk";
import rolesSlice from "./rolesSlices/rolesSlices";
import permissionsSlice from "./permissionsSlices/permissionsSlice";
import orgSlice from "./orgSlices/orgSlices";
import customEvent from "./customEventSlices/customEvent";
import surveySlices from "./surveySlices/surveySlices"
import strategySlice from "./strategySlice/strategySlice"
import apiKeySlice from "./apiKeySlices/apiKeySlice"
import campaignSlice from "./campaignSlices/campaignSlice"
import reportsSlice from "./reportsSlice/reportsSlice";
import stripeSlices from "./StripeSlices/stripeSlices";
import globalKeysSlice, {resetReducers} from "./globalKeysSlice/globalKeysSlice";
import customReport from "./CustomReportSlices/customReport";


const rootReducers = combineReducers({
    users: userSlice,
    roles: rolesSlice,
    notes:notesSlice,
    apiKey:apiKeySlice,
    org:orgSlice,
    customCriteria:customEvent,
    customReport:customReport,
    permissions:permissionsSlice,
    survey:surveySlices,
    strategy:strategySlice,
    campaign:campaignSlice,
    reports:reportsSlice,
    stripe:stripeSlices,
    globalKeys:globalKeysSlice,
    team:teamSlice,
    reset:resetReducers

})

const rootReducer = (state, action) => {

    if (action.type === resetReducers?.type) {
        const {sliceNames} = action.payload;
        let newState = {...state};
        Object.keys(newState).forEach(curKey => {
            Object.keys(newState[curKey]).forEach(curReducer => {
                if (sliceNames.includes(curReducer)) {
                    newState[curKey] = {
                        ...newState[curKey],
                        [curReducer]: {loading: false}
                    };
                }
            });
        });
        return newState;
    }

    return rootReducers(state, action);
};


const store = configureStore({
    reducer:rootReducer,
    middleware: [thunk]
})


export default store;