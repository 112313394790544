import React, {useEffect} from 'react';
import {
    CDBSidebar,
    CDBSidebarContent,
    CDBSidebarMenu,
    CDBSidebarMenuItem,
} from 'cdbreact';
import {useLocation} from 'react-router-dom';
import './Sidebar.css'
import {hasPermission, Permissions, Roles} from "../../helpers/accessControl";
import dashbaord from '../../images/dashbaord.svg'
import attemptIcon from '../../images/attemptIcon.svg'
import {GoGoal} from "react-icons/go";
import members from '../../images/members.svg'
import teamsIcon from '../../images/teamsIcon.svg'
import reflection from '../../images/Reflection.svg'
import {NavLink} from 'react-router-dom';
import {roleHasAccess} from "../../helpers/accessControl";
import {TbReportSearch} from "react-icons/tb";
import {SlOrganization} from "react-icons/sl";
import {MdOutlineCampaign} from "react-icons/md"
import {FaList} from "react-icons/fa";
import {AiOutlineSetting} from "react-icons/ai";
import {RiAdminLine} from "react-icons/ri";
import {toggleSidebar} from "../../reduxStore/globalKeysSlice/globalKeysSlice";
import {useDispatch, useSelector} from "react-redux";
import jwtDecode from "jwt-decode";
import {getDataFromLocalStorage} from "../../helpers/utils";
import {useState} from "react";
import {hexToRgb} from "../../helpers/constants";
import Header from "./Header";

const Sidebar = () => {
    const {pathname} = useLocation();
    const dispatch = useDispatch();
    const hideSidebar = useSelector(state => state.globalKeys?.toggleSidebar)
    const getLoggedInUserData = getDataFromLocalStorage("loggedInUserInfo")
    const token = jwtDecode(getDataFromLocalStorage("token"))
    const org = useSelector(state => state.org)
    const [colorName, setColorName] = useState(null)

    useEffect(() => {
        if (org?.getOrganisationPreferenceByUserIdReducer?.data !== undefined) {
            setColorName(org?.createOrganisationPreferenceReducer?.data?.colorName)
        }

    }, [org?.getOrganisationPreferenceByUserIdReducer?.data])

    useEffect(() => {
        if (colorName != null) {
            localStorage.setItem("color", colorName)
        }

    }, [colorName])

    const handleToggleSidebar = () => {
        if (hideSidebar) {
            dispatch(toggleSidebar(false))
        }

    }


    return (
        <div>


            <CDBSidebar className={"sidebar " + (hideSidebar ? " hamburger " : "")} textColor="#fff"
                        backgroundColor="#F5F5F5">
                <Header/>

                <CDBSidebarContent className="sidebar-content">
                    <CDBSidebarMenu>
                        <NavLink onClick={handleToggleSidebar} exact="true"
                                 to={"/" + jwtDecode(getDataFromLocalStorage("token")).orgName + "/admin"}
                                 activeclassname="activeClicked">

                            {pathname.startsWith("/" + jwtDecode(getDataFromLocalStorage("token")).orgName + "/admin") && localStorage.getItem("color") !== null && localStorage.getItem("color") !== 'undefined' ?

                                <CDBSidebarMenuItem style={{
                                    color: localStorage.getItem("color"),
                                    background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""
                                }}>
                                    <div className="icon_img">
                                        <img className={"sidebar-icons"} src={dashbaord} alt=""/>
                                        <span className='mediumFontSize sidebar_data'>Dashboard</span>

                                    </div>
                                </CDBSidebarMenuItem>

                                :
                                <CDBSidebarMenuItem className={"customClass"}>
                                    <div className="icon_img">
                                        <img className={"sidebar-icons"} src={dashbaord} alt=""/>
                                        <span className='mediumFontSize sidebar_data'>Dashboard</span>

                                    </div>
                                </CDBSidebarMenuItem>

                            }
                        </NavLink>

                        <span className={"general-data mediumFontSize"}>General</span>
                        <NavLink

                            onClick={handleToggleSidebar}
                            to="/members"
                            className={pathname === "/memberDetails" ? "active" : ""}
                            activeClassName="activeClicked"
                        >
                            {/*{pathname.startsWith("/members") && <div style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== 'undefined' ? localStorage.getItem("color"):""} } className="bar" />}*/}


                            {pathname.startsWith("/members") && localStorage.getItem("color") !== null && localStorage.getItem("color") !== "undefined" ?

                                <CDBSidebarMenuItem
                                    style={{
                                        color: localStorage.getItem("color"),
                                        backgroundColor: hexToRgb(localStorage.getItem("color"), 0.1),
                                        background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""
                                    }}
                                >
                                    <div className="icon_img">
                                        {/*<BsPeople className={"sidebar-icons"} />*/}
                                        <img className={"sidebar-icons"} src={members} alt=""/>
                                        <span className='mediumFontSize sidebar_data'>Members</span>
                                    </div>
                                </CDBSidebarMenuItem>
                                :

                                <CDBSidebarMenuItem

                                >
                                    <div className="icon_img">
                                        {/*<img src={users} alt="" width={"20"}/>*/}
                                        <img className={"sidebar-icons"} src={members} alt=""/>
                                        <span className='mediumFontSize sidebar_data'>Members</span>
                                    </div>
                                </CDBSidebarMenuItem>

                            }

                        </NavLink>
                        {
                            (roleHasAccess([Roles.ORG_ADMIN, Roles.SUB_ORG_ADMIN, Roles.MANAGER]) || getLoggedInUserData?.teamIdsUserLeads?.length > 0) ?
                                <NavLink onClick={handleToggleSidebar} exact="true" to="/teams"
                                         className={(pathname === "/teams") ? "active" : ""}
                                         activeclassname="activeClicked">

                                    {pathname.startsWith("/teams") && localStorage.getItem("color") !== null && localStorage.getItem("color") !== 'undefined'
                                        ?
                                        <CDBSidebarMenuItem style={{
                                            color: localStorage.getItem("color"),
                                            backgroundColor: hexToRgb(localStorage.getItem("color"), 0.1),
                                            background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""
                                        }}>
                                            <div className="icon_img">
                                                <img className={"sidebar-icons"} src={teamsIcon} alt=""/>
                                                <span
                                                    className='mediumFontSize sidebar_data'>Teams </span>
                                            </div>
                                        </CDBSidebarMenuItem>
                                        :

                                        <CDBSidebarMenuItem>
                                            <div className="icon_img">
                                                <img className={"sidebar-icons"} src={teamsIcon} alt=""/>
                                                <span
                                                    className='mediumFontSize sidebar_data'>Teams </span>
                                            </div>
                                        </CDBSidebarMenuItem>


                                    }
                                </NavLink> : <></>
                        }
                        {/*campaigns*/}
                        {
                            roleHasAccess([Roles.ORG_ADMIN, Roles.SUB_ORG_ADMIN,Roles.MANAGER]) ?
                                <NavLink onClick={handleToggleSidebar} exact="true" to="/campaign"
                                         activeclassname="activeClicked">
                                    {pathname.startsWith("/campaign")  && localStorage.getItem("color") !== null && localStorage.getItem("color") !== "undefined" ?
                                        <CDBSidebarMenuItem style={{
                                            color: localStorage.getItem("color"),
                                            backgroundColor: hexToRgb(localStorage.getItem("color"), 0.1),
                                            background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""
                                        }}>

                                            <div className="icon_img">
                                                <MdOutlineCampaign className={"sidebar-icons campaign-icon"}/>
                                                <span className='mediumFontSize sidebar_data'>Campaigns</span>
                                            </div>

                                        </CDBSidebarMenuItem>

                                        :
                                        <CDBSidebarMenuItem>

                                            <div className="icon_img">
                                                <MdOutlineCampaign className={"sidebar-icons campaign-icon"}/>
                                                <span className='mediumFontSize sidebar_data'>Campaigns</span>
                                            </div>

                                        </CDBSidebarMenuItem>
                                    }
                                </NavLink> : <></>
                        }
                        {
                            jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === "CUSTOMER" && roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) && hasPermission([Permissions.ORG_CREATE], true) ?
                                <NavLink exact="true" to="/subOrganizations"
                                         className={(pathname === "/subOrganizations") ? "active" : ""}
                                         activeclassname="activeClicked">
                                    {
                                        pathname.startsWith("/subOrganizations") && localStorage.getItem("color") !== null && localStorage.getItem("color") !== "undefined" ?
                                            <CDBSidebarMenuItem style={{
                                                color: localStorage.getItem("color"),
                                                backgroundColor: hexToRgb(localStorage.getItem("color"), 0.1),
                                                background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""
                                            }}>

                                                <div className="icon_img">
                                                    <SlOrganization className={"sidebar-icons"}/>
                                                    <span
                                                        className='mediumFontSize sidebar_data'>Sub-Organizations</span>
                                                </div>
                                            </CDBSidebarMenuItem>
                                            :

                                            <CDBSidebarMenuItem>

                                                <div className="icon_img">
                                                    <SlOrganization className={"sidebar-icons"}/>
                                                    <span
                                                        className='mediumFontSize sidebar_data'>Sub-Organizations</span>
                                                </div>
                                            </CDBSidebarMenuItem>

                                    }
                                </NavLink> : <></>
                        }

                        {
                            jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === "SYSTEM" && roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) ?
                                <NavLink onClick={handleToggleSidebar} exact="true" to="/organizations"
                                         activeclassname="activeClicked"
                                         className={pathname.startsWith("/subOrganizations") ? "active cmn_background_color " : ""}>
                                    {/*{(pathname.startsWith("/organizations") || pathname.startsWith("/subOrganizations")) && <div style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""} } className="bar"/>}*/}


                                    {(pathname.startsWith("/organizations") || pathname.startsWith("/subOrganizations")) && localStorage.getItem("color") !== null && localStorage.getItem("color") !== "undefined" ?

                                        <CDBSidebarMenuItem style={{
                                            color: localStorage.getItem("color"),
                                            backgroundColor: hexToRgb(localStorage.getItem("color"), 0.1),
                                            background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""
                                        }}>

                                            <div className="icon_img">
                                                {/*<img src={organization} alt="" width={"20"}/>*/}
                                                <i className={"fa fa-building sidebar-icons "} aria-hidden="true"/>
                                                <span className='mediumFontSize sidebar_data'>Organizations</span>

                                            </div>
                                        </CDBSidebarMenuItem>
                                        :

                                        <CDBSidebarMenuItem>

                                            <div className="icon_img">
                                                {/*<img src={organization} alt="" width={"20"}/>*/}
                                                <i className={"fa fa-building sidebar-icons "} aria-hidden="true"/>
                                                <span className='mediumFontSize sidebar_data'>Organizations</span>

                                            </div>
                                        </CDBSidebarMenuItem>

                                    }
                                </NavLink> : <></>
                        }


                        {
                            hasPermission([Permissions.REFLECTION_READ], true) ?
                                <NavLink onClick={handleToggleSidebar} exact="true" to="/reflections"
                                         className={(pathname === "/surveyDetails" || pathname === "/creationReflection" || pathname === "/reflections" || pathname.startsWith("/view/reflection")) ? "active" : ""}
                                         activeclassname="activeClicked">

                                    {/*{*/}
                                    {/*    ( pathname.startsWith("/reflections") || pathname.startsWith("/creationReflection")) &&*/}
                                    {/*    <div className="bar" style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined || localStorage.getItem("color") !== 'undefined' ? localStorage.getItem("color"):""} }/>*/}
                                    {/*}*/}

                                    {(pathname.startsWith("/reflections") || pathname.startsWith("/creationReflection")|| pathname.startsWith("/view/reflection")) && localStorage.getItem("color") !== null && localStorage.getItem("color") !== 'undefined'
                                        ?
                                        <CDBSidebarMenuItem style={{
                                            color: localStorage.getItem("color"),
                                            backgroundColor: hexToRgb(localStorage.getItem("color"), 0.1),
                                            background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""
                                        }}>


                                            <div className="icon_img">
                                                <img className={"sidebar-icons"} src={reflection} alt=""/>
                                                <span
                                                    className='mediumFontSize sidebar_data'>Reflections </span>
                                            </div>
                                        </CDBSidebarMenuItem>

                                        :

                                        <CDBSidebarMenuItem>


                                            <div className="icon_img">
                                                <img className={"sidebar-icons"} src={reflection} alt=""/>
                                                <span
                                                    className='mediumFontSize sidebar_data'>Reflections </span>
                                            </div>
                                        </CDBSidebarMenuItem>


                                    }
                                </NavLink> : <></>
                        }
                        {/*strategy*/}
                        {
                            (roleHasAccess([Roles.ORG_ADMIN, Roles.SUB_ORG_ADMIN]) || (roleHasAccess([Roles.MANAGER]) && token.orgLevel==="SYSTEM")) ?
                                <NavLink onClick={handleToggleSidebar} exact="true" to="/strategy"
                                         activeclassname="activeClicked">
                                    {pathname === "/strategy" && localStorage.getItem("color") !== null && localStorage.getItem("color") !== "undefined" ?
                                        <CDBSidebarMenuItem style={{
                                            color: localStorage.getItem("color"),
                                            backgroundColor: hexToRgb(localStorage.getItem("color"), 0.1),
                                            background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""
                                        }}>

                                            <div className="icon_img">
                                                <GoGoal className={"sidebar-icons"}/>
                                                <span className='mediumFontSize sidebar_data'>Strategy</span>
                                            </div>

                                        </CDBSidebarMenuItem>

                                        :
                                        <CDBSidebarMenuItem>

                                            <div className="icon_img">
                                                <GoGoal className={"sidebar-icons"}/>
                                                <span className='mediumFontSize sidebar_data'>Strategy</span>
                                            </div>

                                        </CDBSidebarMenuItem>
                                    }
                                </NavLink> : <></>
                        }


                        {
                            hasPermission([Permissions.ATTEMPT_REFLECTION_READ], true) ?
                                <NavLink onClick={handleToggleSidebar} exact="true" to="/attempts"
                                         className={(pathname === "/attempts") ? "active" : ""}
                                         activeclassname="activeClicked">

                                    {/*{*/}
                                    {/*    ( pathname.startsWith("/reflections") || pathname.startsWith("/creationReflection")) &&*/}
                                    {/*    <div className="bar" style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined || localStorage.getItem("color") !== 'undefined' ? localStorage.getItem("color"):""} }/>*/}
                                    {/*}*/}

                                    {pathname.startsWith("/attempts") && localStorage.getItem("color") !== null && localStorage.getItem("color") !== 'undefined'
                                        ?
                                        <CDBSidebarMenuItem style={{
                                            color: localStorage.getItem("color"),
                                            backgroundColor: hexToRgb(localStorage.getItem("color"), 0.1),
                                            background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""
                                        }}>
                                            <div className="icon_img">
                                                <img className={"sidebar-icons"} src={attemptIcon} alt=""/>
                                                <span
                                                    className='mediumFontSize sidebar_data'>Attempts </span>
                                            </div>
                                        </CDBSidebarMenuItem>
                                        :

                                        <CDBSidebarMenuItem>
                                            <div className="icon_img">
                                                <img className={"sidebar-icons"} src={attemptIcon} alt=""/>
                                                <span
                                                    className='mediumFontSize sidebar_data'>Attempts </span>
                                            </div>
                                        </CDBSidebarMenuItem>


                                    }
                                </NavLink>

                                : <></>
                        }
                        {
                            hasPermission([Permissions.REPORT_READ], true) ? <NavLink exact="true" to="/reports"
                                                                                      className={(pathname.startsWith("/reports") || pathname.startsWith("/report")) ? "active" : ""}
                                                                                      activeclassname="activeClicked">
                                {/*{(pathname.startsWith("/reports") || pathname.startsWith("/report")) &&*/}
                                {/*    <div style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""} } className="bar"/>}*/}


                                {(pathname.startsWith("/reports") || pathname.startsWith("/report")) && localStorage.getItem("color") !== null && localStorage.getItem("color") !== 'undefined' ?
                                    <CDBSidebarMenuItem style={{
                                        color: localStorage.getItem("color"),
                                        backgroundColor: hexToRgb(localStorage.getItem("color"), 0.1),
                                        background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""
                                    }}>

                                        <div className="icon_img">
                                            <TbReportSearch className={"sidebar-icons"}/>
                                            <span className='mediumFontSize sidebar_data'>Reports</span>
                                        </div>
                                    </CDBSidebarMenuItem>

                                    :
                                    <CDBSidebarMenuItem>
                                        <div className="icon_img">
                                            <TbReportSearch className={"sidebar-icons"}/>
                                            <span className='mediumFontSize sidebar_data'>Reports</span>
                                        </div>
                                    </CDBSidebarMenuItem>
                                }
                            </NavLink> : <></>
                        }
                        {
                            roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) ?
                                <>
                                    <NavLink
                                        onClick={handleToggleSidebar}
                                        to="/triggers"
                                        className={pathname === "/memberDetails" ? "active" : ""}
                                        activeClassName="activeClicked"
                                    >
                                        {/*{pathname.startsWith("/triggers") && <div style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== 'undefined' ? localStorage.getItem("color"):""} } className="bar" />}*/}


                                        {pathname.startsWith("/triggers") && localStorage.getItem("color") !== null && localStorage.getItem("color") !== "undefined" ?

                                            <CDBSidebarMenuItem
                                                style={{
                                                    color: localStorage.getItem("color"),
                                                    backgroundColor: hexToRgb(localStorage.getItem("color"), 0.1),
                                                    background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""
                                                }}
                                            >
                                                <div className="icon_img">
                                                    <FaList className={"sidebar-icons"}/>
                                                    <span className='mediumFontSize sidebar_data'>Triggers</span>
                                                </div>
                                            </CDBSidebarMenuItem>
                                            :
                                            <CDBSidebarMenuItem>
                                                <div className="icon_img">
                                                    <FaList className={"sidebar-icons"}/>
                                                    <span className='mediumFontSize sidebar_data'>Triggers</span>
                                                </div>
                                            </CDBSidebarMenuItem>

                                        }

                                    </NavLink>
                                </>

                                :
                                <>
                                    <NavLink></NavLink>
                                </>

                        }


                        {/*{*/}
                        {/*    hasPermission([Permissions.REPORT_READ], true) ? <NavLink exact="true" to="/reports"*/}
                        {/*                                                              className={(pathname === "/reportDetails") ? "active" : ""}*/}
                        {/*                                                              activeclassname="activeClicked">*/}
                        {/*        {(pathname.startsWith("/custom-report") || pathname.startsWith("/reportDetails")) &&*/}
                        {/*            <div style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""} } className="bar"/>}*/}


                        {/*        { (pathname.startsWith("/reportDetails") || pathname.startsWith("/reportDetails"))  && localStorage.getItem("color") !== null  && localStorage.getItem("color") !== 'undefined'?*/}
                        {/*            <CDBSidebarMenuItem  style={{color:localStorage.getItem("color"),backgroundColor:hexToRgb(localStorage.getItem("color"),0.1),background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""}}>*/}


                        {/*                <div className="icon_img">*/}
                        {/*                    <HiDocumentReport className={"sidebar-icons"}/>*/}
                        {/*                    <span className='mediumFontSize sidebar_data'>Reports</span>*/}
                        {/*                </div>*/}
                        {/*            </CDBSidebarMenuItem>*/}

                        {/*            :*/}
                        {/*            <CDBSidebarMenuItem>*/}
                        {/*                <div className="icon_img">*/}
                        {/*                    <HiDocumentReport className={"sidebar-icons"}/>*/}
                        {/*                    <span className='mediumFontSize sidebar_data'>Reports</span>*/}
                        {/*                </div>*/}
                        {/*            </CDBSidebarMenuItem>*/}
                        {/*        }*/}
                        {/*    </NavLink> : <></>*/}
                        {/*}*/}


                        {
                            roleHasAccess([Roles.ORG_ADMIN, Roles.SUB_ORG_ADMIN]) && hasPermission([Permissions.ROLE_READ], true) ?
                                <NavLink onClick={handleToggleSidebar} exact="true" to="/roles"
                                         className={(pathname === "/roles") ? "active" : ""}
                                         activeclassname="activeClicked">
                                    {/*{pathname.startsWith("/roles") && <div className="bar" style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ||  localStorage.getItem("color") !== "undefined" ? localStorage.getItem("color"):""} }/>}*/}

                                    {pathname.startsWith("/roles") && localStorage.getItem("color") !== null && localStorage.getItem("color") !== "undefined" ?
                                        <CDBSidebarMenuItem style={{
                                            color: localStorage.getItem("color"),
                                            backgroundColor: hexToRgb(localStorage.getItem("color"), 0.1),
                                            background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""
                                        }}>

                                            <div className="icon_img">
                                                <RiAdminLine className={"sidebar-icons"}/>
                                                <span className='mediumFontSize sidebar_data'>Roles</span>

                                            </div>


                                        </CDBSidebarMenuItem>
                                        :

                                        <CDBSidebarMenuItem>

                                            <div className="icon_img">
                                                <RiAdminLine className={"sidebar-icons"}/>
                                                <span className='mediumFontSize sidebar_data'>Roles</span>

                                            </div>


                                        </CDBSidebarMenuItem>
                                    }
                                </NavLink> : <></>
                        }
                        <NavLink onClick={handleToggleSidebar} exact="true" to="/setting"
                                 activeclassname="activeClicked">
                            {/*{pathname === "/setting" && <div className="bar" style={{background : localStorage.getItem("color") === null || localStorage.getItem("color") === undefined || localStorage.getItem("color") === "undefined" ? "":localStorage.getItem("color")} }/>}*/}


                            {pathname === "/setting" && localStorage.getItem("color") !== null && localStorage.getItem("color") !== "undefined" ?
                                <CDBSidebarMenuItem style={{
                                    color: localStorage.getItem("color"),
                                    backgroundColor: hexToRgb(localStorage.getItem("color"), 0.1),
                                    background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""
                                }}>

                                    <div className="icon_img">
                                        <AiOutlineSetting className={"sidebar-icons"}/>
                                        <span className='mediumFontSize sidebar_data'>Settings</span>
                                    </div>

                                </CDBSidebarMenuItem>

                                :
                                <CDBSidebarMenuItem>

                                    <div className="icon_img">
                                        <AiOutlineSetting className={"sidebar-icons"}/>
                                        <span className='mediumFontSize sidebar_data'>Settings</span>
                                    </div>

                                </CDBSidebarMenuItem>
                            }
                        </NavLink>

                    </CDBSidebarMenu>
                </CDBSidebarContent>

            </CDBSidebar>
        </div>
    );
};

export default Sidebar;