import Modal from "react-bootstrap/Modal";
import close_square from "../../images/croosIcon.png";
import React, {useEffect, useState} from "react";
import Select from "react-select";
import {
    createOptionListForSelectTag, getDataFromLocalStorage,
    handleApiResponse,
    isUnauthorized, isValidCreateApiKeyRequest,
} from "../../helpers/utils";
import {CampaignDeliveryMethod} from "../../helpers/constants";
import {useDispatch, useSelector} from "react-redux";
import {Loader, TailSpinLoader} from "../layout/Loader";
import {createApiKey, validateSendGridApiKey} from "../../reduxStore/apiKeySlices/apiKeySlice";
import {useNavigate} from "react-router-dom";
import jwtDecode from "jwt-decode";

const ConnectApiKeysModal=({show, setShow,setTriggerGetApiKeys})=>{

    const handleClose = () => setShow(false);

    const dispatch=useDispatch()
    const navigate=useNavigate()
    const token=jwtDecode(getDataFromLocalStorage("token"))
    const loggedInUserInfo=getDataFromLocalStorage("loggedInUserInfo")

    const getApiKeysApi=useSelector(state => state.apiKey.getApiKeysByOrgIdReducer)
    const validateSendGridKeyApi=useSelector(state => state.apiKey.validateSendGridApiKeyReducer)
    const createApi=useSelector(state => state.apiKey.createApiKeyReducer)

    const providers=CampaignDeliveryMethod?.map(cur=>cur.value)
    const connectedProviders=getApiKeysApi?.data?.map(cur=>cur?.provider)
    const providersNotConnected=providers?.filter(cur=>!connectedProviders?.includes(cur))

    const [apiKeyData,setApiKeyData]=useState({
        orgId: token?.orgId,
        provider: null,
        email: loggedInUserInfo?.email,
        apiKey: ""
    })

    useEffect(() => {
        if(providersNotConnected?.length===0){
            handleClose()
        }
    }, [providersNotConnected]);

    const handleOnChange=(e)=>{
        const {name,value}=e.target
        setApiKeyData({
            ...apiKeyData,
            [name]:value
        })
    }
    const handleConnectApiKey=()=>{
        const queryToSubmit={
            ...apiKeyData,
            provider:apiKeyData.provider.value
        }
        isValidSendGridApiKey(queryToSubmit.apiKey).then(isValidApiKey=>{
            if(isValidApiKey && isValidCreateApiKeyRequest(queryToSubmit)){
                dispatch(createApiKey(queryToSubmit)).then(res => {
                    if (isUnauthorized(res)) {
                        navigate("/")
                    }
                    handleApiResponse(res,()=>{
                        setTriggerGetApiKeys(true)
                        handleClose()
                    })
                })
            }
        })


    }
    const isValidSendGridApiKey=async (apiKey)=>{
        let isValidApiKey=false;
        await dispatch(validateSendGridApiKey(apiKey)).then(res => {
           handleApiResponse(res,()=>{
               isValidApiKey=true
           })
        })
        return isValidApiKey;
    }

    return (
        <>
            <Modal className={"upgrade-plan-modal mt-4 "} show={show} centered>
                <Modal.Header>
                    <Modal.Title
                        className='add_member_heading mediumFontSize'>Connect Api Key</Modal.Title>
                    <div className="closeImg" onClick={handleClose}>
                        <img src={close_square} alt="" width={"15"}/>
                    </div>


                </Modal.Header>
                <Modal.Body className={"upgrade-modal-body connect-api-key-modal"}>
                    {
                        getApiKeysApi?.loading ? <Loader/>:
                            <>
                                <div className="form_content_outer mb-3">
                                    <label>*Provider</label>
                                    <Select
                                        options={createOptionListForSelectTag(CampaignDeliveryMethod?.filter(cur => providersNotConnected.includes(cur.value)), "name", "value")}
                                        value={apiKeyData?.provider}
                                        onChange={(selectedValue) => {
                                            if (apiKeyData?.provider?.value !== selectedValue?.value) {
                                                handleOnChange({target: {name: "provider", value: selectedValue}})
                                            }
                                        }}/>
                                </div>
                                <div className="form_content_outer mb-3">
                                    <label>*Api Key</label>
                                    <input
                                        name={"apiKey"}
                                        className='form-control'
                                        value={apiKeyData.apiKey}
                                        onChange={handleOnChange}
                                        type="text"
                                    />
                                </div>
                                {
                                    apiKeyData?.provider?.value==="SENDGRID" &&
                                    <>
                                        <div className="form_content_outer mb-3">
                                            <label>*Sender Email</label>
                                            <input
                                                name={"email"}
                                                className='form-control'
                                                value={apiKeyData.email}
                                                onChange={handleOnChange}
                                                type="text"
                                            />
                                            <div className={"sender-email-note"}>Note: The sender email must match the authenticated sender email on SendGrid.</div>
                                        </div>

                                    </>
                                }
                            </>
                    }

                </Modal.Body>

                <Modal.Footer className="modal_footer">
                    <div className={"add-member-modal-btns"}>
                        <button
                            disabled={createApi?.loading || validateSendGridKeyApi?.loading}
                            style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                            className={' modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize me-2'}
                            onClick={handleConnectApiKey}>
                            Connect {(createApi?.loading || validateSendGridKeyApi?.loading) && <TailSpinLoader/> }
                        </button>
                        <button className="modal_cancel_btn cmn_modal_btn mediumFontSize me-2"
                                disabled={createApi?.loading}
                                onClick={handleClose}>Close
                        </button>


                    </div>

                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ConnectApiKeysModal