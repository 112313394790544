import Modal from "react-bootstrap/Modal";
import {useEffect, useState} from "react";
import {Marks} from "../../../helpers/constants";
import {Doughnut} from "react-chartjs-2";
import {IoIosClose} from "react-icons/io";
import {useNavigate} from "react-router-dom";

const  ResultModal = ({show, setShow, data,resetForm,segmentType,setCurrentSection})=>{


    const navigate=useNavigate();

    const handleClose = () => {
        setShow(false);
        navigate("/")
    }
    const attemptAgain = () => {
        resetForm()
        if(segmentType==="TABULAR"){
            setCurrentSection(0)
        }
        setShow(false);
    }

    const chartOptions={

        cutout:"60%",
        plugins: {
            legend: {
                display: true,
                align: 'left',
                position: 'top',
                labels: {
                    usePointStyle: true,
                },
            },

        },
    }

    const [chartData, setChartData] = useState({
        labels: ["Stagnating", 'Growing', 'Maturing'],
        datasets: [
            {
                data: [],
                backgroundColor: ['red', '#ff7100', 'green'],
            },
        ],
        options: {
            title: {
                display: true,
            }
        }
    })


    useEffect( ()=>{

        if (data.data !== null && data.data.surveyAttemptResult !== undefined) {

            const newData = {
                ...chartData,
                datasets: [
                    {
                        data: [data.data.surveyAttemptResult.highRiskAnswersCount, data.data.surveyAttemptResult.lowRiskAnswersCount, data.data.surveyAttemptResult.goodAnswersCount],
                        backgroundColor: ['red', '#ff7100', 'green'],
                    },
                ],
            };
            setChartData(newData);


    }},[data])

     return (
         <>
             <Modal className='mt-4' show={show} centered>
                 <Modal.Header className={"d-flex"}>
                     <Modal.Title className='add_member_heading mediumFontSize'>Result</Modal.Title>

                     {/*<IoIosClose className={"close_btn"} onClick={handleClose} size={24}/>*/}
                 </Modal.Header>

                 <Modal.Body className={"result-card-body"}>
                     {data.data != null && data.data.surveyAttemptResult !== undefined &&

                         <>

                             <div className={"data-content"}>

                                 <div className={"quote"}>

                                     <Doughnut data={chartData} options={chartOptions}/>
                                 </div>

                                 <div className={"survey-details-outer mt-2"}>
                                     <div
                                          className={"survey-answer-detail-heading mb-2 "}> OverAll Result  <span className={" span-result " + (data.data.surveyAttemptResult.overallRiskLevel === "HIGH_RISK" ? "bg-light-red" : data.data.surveyAttemptResult.overallRiskLevel === "LOW_RISK" ? "bg-light-orange" : "bg-light-green") } > {Marks[data.data.surveyAttemptResult.overallRiskLevel]} </span>
                                     </div>
                                     <div className={"survey-details"}>
                                         <div className={"detail-outer mb-1"}>
                                             <div className={"mediumFontSize detail-heading"}>Total Questions :</div>
                                             <div className={"detail-text"}>{data.data.attemptQuestions.length}</div>
                                         </div>
                                         <div className={"detail-outer mb-1"}>
                                             <div
                                                 className={"mediumFontSize detail-heading"}>{Marks["GOOD"]} Attempts
                                                 :
                                             </div>
                                             <div
                                                 className={"detail-text"}> {data.data.surveyAttemptResult.goodAnswersCount === undefined ? 0 : data.data.surveyAttemptResult.goodAnswersCount}</div>
                                         </div>
                                         <div className={"detail-outer mb-1"}>
                                             <div
                                                 className={"mediumFontSize detail-heading"}>{Marks["LOW_RISK"]} Attempts
                                                 :
                                             </div>
                                             <div
                                                 className={"regularFontSize detail-text"}> {data.data.surveyAttemptResult.lowRiskAnswersCount === undefined ? 0 : data.data.surveyAttemptResult.lowRiskAnswersCount}</div>
                                         </div>
                                         <div className={"detail-outer mb-1"}>
                                             <div
                                                 className={"mediumFontSize detail-heading"}>{Marks["HIGH_RISK"]} Attempts
                                                 :
                                             </div>
                                             <div
                                                 className={"regularFontSize detail-text"}> {data.data.surveyAttemptResult.highRiskAnswersCount === undefined ? 0 : data.data.surveyAttemptResult.highRiskAnswersCount}</div>
                                         </div>
                                     </div>
                                 </div>


                             </div>

                         </>
                     }
                 </Modal.Body>

                 <Modal.Footer>
                     <button className="modal_cancel_btn cmn_modal_btn mediumFontSize" onClick={attemptAgain}>Attempt Again</button>
                     <button className="modal_cancel_btn cmn_modal_btn mediumFontSize" onClick={handleClose}>Close</button>
                 </Modal.Footer>

             </Modal>
         </>
     )

}
 export  default  ResultModal;