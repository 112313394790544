import logo from "../../images/PulseIcon.svg";
import "./AttemptAssignedReflection.css"
import {useEffect, useState} from "react";
import {
    formatDate,
    getDataFromLocalStorage,
    isUnauthorized,
    tableCustomStyling,
    TableSkeletonLoading, toString,
    trim
} from "../../helpers/utils";
import {useDispatch, useSelector} from "react-redux";
import DataTable from "react-data-table-component";
import {hexToRgb, Marks, Survey, SurveyAttemptStatus} from "../../helpers/constants";
import Pagination from "../commons/Pagination";
import {hasPermission, Permissions} from "../../helpers/accessControl";
import {getAllAttemptSurveyByUserId} from "../../reduxStore/surveySlices/surveySlices";
import {useNavigate} from "react-router-dom";
import UserInfo from "../layout/UserInfo";

const AttemptAssignedReflection = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [pageNumber, setPageNumber] = useState(1)
    const getAllAttemptSurveyByUserIdData = useSelector(state => state.survey.getAllAttemptSurveyByUserIdDataReducer)
    const [sortBy, setSortBy] = useState("createdAt");
    const [sortOrder, setSortOrder] = useState("desc");


    useEffect(() => {
        if (hasPermission([Permissions.ATTEMPT_REFLECTION_READ])) {
            dispatch(getAllAttemptSurveyByUserId({
                pageNumber: pageNumber,
                sortBy: sortBy,
                sortOrder: sortOrder,
                userId: getDataFromLocalStorage("loggedInUserInfo")?.userId,
                attemptStatus:[SurveyAttemptStatus.ASSIGNED,SurveyAttemptStatus.COMPLETED]
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }
    }, [pageNumber, sortBy, sortOrder]);


    const columns = [
        {
            name:"Reflection",
            selector: row => row.surveyName,
            sortable: false,
            sortField: 'surveyName',
            cell: (row) =>
                (
                    <div title={row.surveyName}
                        className={"wrap-text  " + (row.enable ? "" : " color-grey ") + ((((row.surveyAttemptStatus === Survey.SURVEY_STATUS_DRAFT || row.surveyAttemptStatus === SurveyAttemptStatus.ASSIGNED) && hasPermission([Permissions.REFLECTION_READ, Permissions.ATTEMPT_REFLECTION_READ, Permissions.ATTEMPT_REFLECTION_CREATE], true)) || (row.surveyAttemptStatus === Survey.ATTEMPT_SURVEY_STATUS_COMPLETED && hasPermission([Permissions.REFLECTION_READ, Permissions.ATTEMPT_REFLECTION_READ], true))) ? "cursor" : "")}
                         onClick={() => {
                             if ((row.surveyAttemptStatus === Survey.SURVEY_STATUS_DRAFT || row.surveyAttemptStatus === SurveyAttemptStatus.ASSIGNED) && hasPermission([Permissions.REFLECTION_READ, Permissions.ATTEMPT_REFLECTION_READ, Permissions.ATTEMPT_REFLECTION_CREATE], true)) {
                                 const userId=getDataFromLocalStorage("loggedInUserInfo")?.userId
                                 navigate(`/mentee/attempt/${userId}/${row.surveyId}`)
                             }
                             else if (row.surveyAttemptStatus === Survey.ATTEMPT_SURVEY_STATUS_COMPLETED && hasPermission([Permissions.REFLECTION_READ, Permissions.ATTEMPT_REFLECTION_READ], true)) {
                                 navigate(`/mentee/reflectionResult/${row.surveyId}/${row.id}`)
                             }
                         }}>
                        {trim(row.surveyName, 50)}
                    </div>
                ),
            style: {
                fontFamily: 'General Sans Medium',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            },
        },
        {
            name:"Assigned On",
            selector: row => row.createdAt,
            sortField: 'createdAt',
            cell: row => <div>{(formatDate(row.createdAt))}</div>,
            sortable: false,
            style: {opacity: ".7",},
            // width: survey?.getAllAttemptSurveyByUserIdDataReducer?.loading ? "" : "25%"
        },
        {
            name:"Status",
            selector: row => row.surveyAttemptStatus,
            cell: row =>  toString(row.surveyAttemptStatus===SurveyAttemptStatus.COMPLETED ?SurveyAttemptStatus.COMPLETED:"Pending"),
            sortable: false,
            style: {opacity: ".7",},
            // width: survey?.getAllAttemptSurveyByUserIdDataReducer?.loading ? "" : "25%"
        },
        {
            name:"Result",
            selector: row => Marks[row.surveyAttemptResult.overallRiskLevel],
            cell: row => (
                (row.surveyAttemptStatus === Survey.SURVEY_STATUS_DRAFT || row.surveyAttemptStatus === SurveyAttemptStatus.ASSIGNED) ?
                    <span>{"---"}</span> : !row["surveyAttemptResult"] ? <span>{"---"}</span> : <>

                        <>

                            {row.surveyAttemptResult.overallRiskLevel === "HIGH_RISK" &&
                                <div className={"show_status"}>
                                        <span className={"survey-risk-status bg-light-red"}>{Marks[row.surveyAttemptResult.overallRiskLevel]}</span>
                                </div>
                            }
                            {row.surveyAttemptResult.overallRiskLevel === "LOW_RISK" &&
                                <div className={"show_status"}>
                                        <span className={"survey-risk-status bg-light-orange"}>{Marks[row.surveyAttemptResult.overallRiskLevel]}</span>
                                </div>

                            }
                            {row.surveyAttemptResult.overallRiskLevel === "GOOD" &&
                                <div className={"show_status"}>
                                        <span className={"survey-risk-status bg-light-green"}>{Marks[row.surveyAttemptResult.overallRiskLevel]}</span>
                                </div>

                            }
                        </>
                    </>


            ),
            // width: survey?.getAllAttemptSurveyByUserIdDataReducer?.loading ? "" : "30%"
        },
    ]

    return (
        <div className={"cmn_bg pb-5"}>

            <div className={"header-assigned-reflection"}>
                <img width={100} src={logo} alt=""/>
                <h5 className={"mediumFontSize reflection_heading"}>Reflections</h5>
                <UserInfo showAddReflectionBox={false}/>
            </div>
            <div className=" assigned-reflections-wrapper">
                <div className={" assigned-reflections-outer mt-3"}>
                    <div className={"options-list-heading "}>
                        <DataTable
                            className={"attempted-reflection-table " + (getAllAttemptSurveyByUserIdData?.data?.totalPages > 1 ? "table_design2" : "table_design1")}
                            persistTableHead={true} responsive={true}
                            progressPending={getAllAttemptSurveyByUserIdData?.loading}
                            progressComponent={<div className={"attempted-reflection-skeleton-loader"}>
                                <TableSkeletonLoading columns={4} rows={7}/></div>}
                            customStyles={tableCustomStyling} columns={columns}
                            data={getAllAttemptSurveyByUserIdData?.data?.data}
                            noDataComponent={

                                <div className={" mediumFontSize no-thought-text w-100"} style={{
                                    color: localStorage.getItem("color"),
                                    backgroundColor: hexToRgb(localStorage.getItem("color"), 0.1),
                                    background: (localStorage.getItem("color") !== null && localStorage.getItem("color") !== undefined) ? hexToRgb(localStorage.getItem("color"), 0.1) : ""
                                }}><span className={"mediumFontSize"}>No Reflection Attempts</span>
                                </div>

                            }
                            // onSort={handleSort} sortServer={true}
                        />
                        {
                            !getAllAttemptSurveyByUserIdData?.loading && getAllAttemptSurveyByUserIdData?.data && getAllAttemptSurveyByUserIdData?.data?.totalPages > 1 &&
                            <Pagination totalPages={getAllAttemptSurveyByUserIdData?.data?.totalPages}
                                        pageNumber={getAllAttemptSurveyByUserIdData?.data?.pageNumber}
                                        setPageNumber={setPageNumber}/>
                        }


                    </div>
                </div>
            </div>


        </div>
    );
}
export default AttemptAssignedReflection