import Header from "../layout/Header";
import Sidebar from "../layout/Sidebar";
import pagenotfound404 from "../../images/pagenotfound404.svg"
import badrequest400 from "../../images/badrequest400.svg"
import Forbidden403 from "../../images/Forbidden403.svg"
import "./ErrorPage.css"
import {Navigate} from "react-router-dom";
import {useSelector} from "react-redux";
import jwtDecode from "jwt-decode";
import {Roles} from "../../helpers/accessControl";
import {getDataFromLocalStorage} from "../../helpers/utils";

const ErrorPage = ({value, description, detailDesc}) => {
    const token = jwtDecode(getDataFromLocalStorage("token"))
    const organizationName = useSelector(state => state.globalKeys.orgName)
    // const subscriptionStatus = token === null ? null : jwtDecode(getDataFromLocalStorage("token")).subscriptionStatus
    return token === null ? <Navigate
        to={"/"}></Navigate>
        // : (subscriptionStatus === SubscriptionPlans.UNSUBSCRIBED || subscriptionStatus === SubscriptionPlans.EXPIRED) ?
        // <Navigate to={"/plans"}></Navigate>
            : !value ?
            <>
                <Header/>
                {
                    token?.roles[0]!==Roles.MENTEE &&<Sidebar/>
                }
                <div className={"component_content innerComponents"}>
                    <div className={'main_wrapper'}>
                        <div className={"row main_content box_design1"}>

                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-md-6 text-center">
                                        <img
                                            src={pagenotfound404}
                                            alt="Page Not Found"
                                            className="img-fluid mb-4 page-not-found-image"
                                        />
                                        {/*<h1 className="display-4">404</h1>*/}
                                        <p className="lead">Oops! Page not found.</p>
                                        <p>The page you are looking for might have been removed or doesn't exist.</p>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
            :
            <>
                <div className="row main_content box_design1">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-6 text-center">
                                {
                                    value === 400 && <img
                                        src={badrequest400}
                                        alt="Page Not Found"
                                        className="img-fluid mb-4 page-not-found-image"
                                    />
                                }
                                {
                                    value === 403 && <img
                                        src={Forbidden403}
                                        alt="Page Not Found"
                                        className="img-fluid mb-4 page-not-found-image"
                                    />
                                }
                                {
                                    value === 404 && <img
                                        src={pagenotfound404}
                                        alt="Page Not Found"
                                        className="img-fluid mb-4 page-not-found-image"
                                    />
                                }
                                {/*<h1 className="display-4">{value}</h1>*/}

                                <p className="lead">{description}</p>
                                <p>{detailDesc}</p>

                            </div>
                        </div>
                    </div>

                </div>

            </>
}
export default ErrorPage;