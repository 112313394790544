import UserInfo from "../layout/UserInfo";
import React, {useEffect, useState} from "react";
import "./Campaigns.css"
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import Select from 'react-select';
import {useNavigate, useParams} from "react-router-dom";
import jwtDecode from "jwt-decode";
import {
    createOptionListForSelectTag, formatDateTimeForSelectedTimeZoneId,
    getDataFromLocalStorage, handleApiResponse, isNullOrEmpty,
    isUnauthorized,
    isValidCreateCampaignQuery, showToast
} from "../../helpers/utils";
import {useDispatch, useSelector} from "react-redux";
import {allTimezones, useTimezoneSelect} from "react-timezone-select";
import {getPublishSurveys, getSurveysByCriteria} from "../../reduxStore/surveySlices/surveySlices";
import {
    getCustomReportsByOrgIdAndCreator
} from "../../reduxStore/CustomReportSlices/customReport";
import {createCampaign} from "../../reduxStore/campaignSlices/campaignSlice";
import {CampaignDeliveryMethod, CampaignType, DefaultReports} from "../../helpers/constants";
import CreatableSelect from "react-select/creatable";
import {TailSpinLoader} from "../layout/Loader";

const CreateCampaign = () => {

    const {orgId} = useParams();
    const token = jwtDecode(getDataFromLocalStorage("token"))
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const defaultReports = DefaultReports?.map(cur => cur.name)
    const orgPreferenceColor = localStorage.getItem("color") !== null && localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : "#fc6620"

    const tagOption = (label) => ({
        label: label,
        value: label,
    });
    const labelStyle = "altName"
    const timezones = {
        ...allTimezones
    }
    const {options, parseTimezone} = useTimezoneSelect({labelStyle, timezones})
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'size': ['small', false, 'large', 'huge'] }],
        ],
    };
    const formats = ['bold', 'italic', 'underline','size'];

    const createCampaignApi = useSelector(state => state.campaign.createCampaignReducer)
    const surveysApi = useSelector(state => state.survey.getSurveysByCriteriaReducer)
    const reportsApi = useSelector(state => state.customReport.getCustomReportsByOrgIdAndCreatorReducer)

    const [createCampaignData, setCreateCampaignData] = useState({
        name: "",
        type: null,
        relationalSelectedItem: null,
        orgId: (orgId === null || orgId === undefined) ? token?.orgId : orgId,
        deliveryMethod: {label: "SendGrid", value: "SENDGRID"},
        recipients: [],
        message: "",
        subject: "",
        scheduledOn: "",
        selectedTimeZone: null
    })
    const [recipientStateValue, setRecipientStateValue] = useState('');


    useEffect(() => {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const parsedUserTimeZone = parseTimezone(userTimeZone); // Parse the user's timezone
        setCreateCampaignData({
            ...createCampaignData,
            selectedTimeZone: parsedUserTimeZone
        });
    }, []);
    useEffect(() => {
        if (createCampaignData?.type && createCampaignData?.type?.value === "REFLECTION") {
            dispatch(getSurveysByCriteria({
                orgId: createCampaignData?.orgId,
                status: ["PUBLISHED"],
                isSysReflectionRequired:true,
                isAssignedReflectionRequired:false,
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }

    }, [createCampaignData.type]);
    useEffect(() => {
        if (createCampaignData?.type && createCampaignData?.type?.value === "REPORT") {
            const query={
                orgId:createCampaignData?.orgId,
                createdBy:isNullOrEmpty(orgId) ? token.userid:null,
            }
            dispatch(getCustomReportsByOrgIdAndCreator(query)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }

    }, [createCampaignData.type]);

    const handleKeyDown = (event) => {
        if (!recipientStateValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                saveValue();
                event.preventDefault();
        }
    };
    function saveValue() {
        if (!recipientStateValue) return;
        const emails = recipientStateValue.split(/[ ,]+/).filter(Boolean);
        const existingEmailsSet = new Set(createCampaignData.recipients.map(item => item.value));
        const newEmails = emails.filter(email => {
            if (existingEmailsSet.has(email)) {
                showToast(`Email already exists, please try unique one!`, "info")
                return false;
            }
            return true;
        }).map(tagOption);

        if (newEmails.length > 0) {
            setCreateCampaignData({
                ...createCampaignData,
                recipients: [...createCampaignData.recipients, ...newEmails]
            })
        }
        setRecipientStateValue('');
    }
    const handleOnChange = (e) => {
        const {name, value} = e.target
        setCreateCampaignData({
            ...createCampaignData,
            [name]: value,
            // If type is de-selected it should empty the relationalSelectedItem field too
            ...(name === "type" ? {"relationalSelectedItem": null} : {})
        })
    }
    const handleCreateCampaign = () => {
        let queryToSubmit = {
            ...createCampaignData,
            type: createCampaignData?.type?.value,
            deliveryMethod: [createCampaignData?.deliveryMethod?.value],
            relationalId: defaultReports.includes(createCampaignData?.relationalSelectedItem?.label) ? null : createCampaignData?.relationalSelectedItem?.value,
            relationalName: createCampaignData?.relationalSelectedItem?.label,
            selectedTimeZone: createCampaignData?.selectedTimeZone?.value,
            recipients: createCampaignData?.recipients?.map(cur => cur?.value) || [],
        }
        if (isValidCreateCampaignQuery(queryToSubmit)) {
            queryToSubmit = {
                ...queryToSubmit,
                scheduledOn: formatDateTimeForSelectedTimeZoneId(queryToSubmit.scheduledOn.split("T")[0], queryToSubmit.scheduledOn.split("T")[1], queryToSubmit.selectedTimeZone)
            }
            dispatch(createCampaign(queryToSubmit)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                handleApiResponse(res, () => {
                    navigate(-1)
                })
            })
        }
    }

    return (
        <section>
            <div className="cmn_header">
                <h2 className="mediumFontSize  org-heading m-0">Create Campaign </h2>
                <UserInfo/>
            </div>
            <div className="row main_content">
                <div className={"col-md-12 p-0"}>
                    <div className={"roles_outer"}>
                        <section className=" create_campaign_inner_content pt-3 pb-3  sub-org-container-box">
                            <div className="row">
                                <div className="col-lg-6 col-sm-12 col-md-12">
                                    <div className="cmn_campaign_Wrapper">
                                        <h3 className="cmn_sub_heading mediumFontSize">Campaign Configuration</h3>
                                        <div className="form_content_outer mb-3">
                                            <label>*Campaign Name</label>
                                            <input
                                                name={"name"}
                                                className='form-control'
                                                value={createCampaignData.name}
                                                onChange={handleOnChange}
                                                type="text"
                                            />
                                        </div>
                                        <div className="form_content_outer mb-3">
                                            <label>*Provider</label>
                                            <Select
                                                options={createOptionListForSelectTag(CampaignDeliveryMethod, "name", "value")}
                                                value={createCampaignData?.deliveryMethod}
                                                onChange={(selectedValue) => {
                                                    if (createCampaignData?.deliveryMethod?.value !== selectedValue?.value) {
                                                        handleOnChange({target: {name: "deliveryMethod", value: selectedValue}})
                                                    }
                                                }}/>
                                        </div>
                                        <div className="form_content_outer mb-3">
                                            <label>*Campaign Type</label>
                                            <Select
                                                options={createOptionListForSelectTag(CampaignType, "name", "value")}
                                                value={createCampaignData?.type}
                                                onChange={(selectedValue) => {
                                                    if (createCampaignData?.type?.value !== selectedValue?.value) {
                                                        handleOnChange({target: {name: "type", value: selectedValue}})
                                                    }
                                                }}
                                                isClearable={true}/>
                                        </div>
                                        <div className="form_content_outer mb-3">

                                            {
                                                createCampaignData?.type === null &&
                                                <>
                                                    <label>*Select Reflection/Report </label>
                                                    <Select isDisabled={true}/>
                                                </>

                                            }
                                            {
                                                createCampaignData?.type?.value === "REFLECTION" &&
                                                <>
                                                    <label>*Select Reflection </label>
                                                    <Select
                                                        options={createOptionListForSelectTag(surveysApi?.data, "name", "surveyId")}
                                                        value={createCampaignData?.relationalSelectedItem}
                                                        onChange={(selectedValue) => {
                                                            if (createCampaignData?.type?.value !== selectedValue?.value) {
                                                                handleOnChange({
                                                                    target: {
                                                                        name: "relationalSelectedItem",
                                                                        value: selectedValue
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                        isDisabled={surveysApi?.loading}
                                                        isClearable={true}/>
                                                </>

                                            }
                                            {
                                                createCampaignData?.type?.value === "REPORT" &&
                                                <>
                                                    <label>*Select Report </label>
                                                    <Select
                                                        isDisabled={reportsApi?.loading}
                                                        options={[...createOptionListForSelectTag(DefaultReports, "name", "value"), ...createOptionListForSelectTag(reportsApi?.data, "reportName", "id")]}
                                                        value={createCampaignData?.relationalSelectedItem}
                                                        onChange={(selectedValue) => {
                                                            if (createCampaignData?.type?.value !== selectedValue?.value) {
                                                                handleOnChange({
                                                                    target: {
                                                                        name: "relationalSelectedItem",
                                                                        value: selectedValue
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                        isClearable={true}/>
                                                </>
                                            }
                                        </div>
                                        <h3 className="cmn_sub_heading">Scheduling</h3>
                                        <div className="form_content_outer mb-3">
                                            <div className="d-flex schedule_wrapper_box">
                                                <div className="flex-grow-1">
                                                    <label>*Select Date and Time </label>
                                                    <div className="d-flex gap-2 datetime_wrapper">
                                                        <input
                                                            min={new Date().toISOString().slice(0, 16)}
                                                            value={createCampaignData?.scheduledOn}
                                                            className={"form-control"}
                                                            type="datetime-local"
                                                            name="scheduledOn"
                                                            onChange={handleOnChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="form_content_outer mb-3">
                                            <label>*Select time zone </label>
                                            <Select
                                                className="members-filter-role react-select-container"
                                                classNamePrefix="react-select"
                                                options={options}
                                                value={createCampaignData?.selectedTimeZone}
                                                onChange={(selectedValue) => {
                                                    if (createCampaignData?.selectedTimeZone?.value !== selectedValue?.value) {
                                                        handleOnChange({
                                                            target: {
                                                                name: "selectedTimeZone",
                                                                value: selectedValue
                                                            }
                                                        })
                                                    }
                                                }}/>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12 col-md-12">
                                    <div className="cmn_campaign_Wrapper">
                                        <h3 className="cmn_sub_heading mediumFontSize">Message</h3>
                                        <div className="form_content_outer mb-3">
                                            <label>*Recipients </label>
                                            <CreatableSelect
                                                components={{
                                                    DropdownIndicator: null,
                                                }}
                                                inputValue={recipientStateValue}
                                                autoFocus={createCampaignData.recipients.length !== 0}
                                                isClearable
                                                isMulti
                                                menuIsOpen={false}
                                                onChange={(value) => {
                                                    handleOnChange({target: {name: "recipients", value: value}})
                                                }}
                                                onInputChange={(value) => {
                                                    setRecipientStateValue(value)
                                                }}
                                                onKeyDown={handleKeyDown}
                                                onMenuClose={saveValue}
                                                value={createCampaignData.recipients}
                                                isDisabled={false}
                                            />
                                        </div>
                                        <div className="form_content_outer mb-3">
                                            <label>*Subject </label>
                                            <input
                                                className='form-control'
                                                type="text"
                                                name={"subject"}
                                                onChange={handleOnChange}
                                                value={createCampaignData.subject}
                                            />
                                        </div>
                                        <div className="custom-quill-editor ">
                                            <ReactQuill
                                                value={createCampaignData.message}
                                                onChange={(value) => {
                                                    handleOnChange({target: {name: "message", value: value}})
                                                }}
                                                placeholder="Write your message here..."
                                                className="quill-editor"
                                                modules={modules}
                                                formats={formats}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="next_btnwrapper text-end mt-3">
                                <button
                                    className="ms-2 modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize "
                                    style={{background: orgPreferenceColor}}
                                    disabled={createCampaignApi?.loading}
                                    onClick={handleCreateCampaign}
                                >Create
                                    {
                                        createCampaignApi?.loading && <TailSpinLoader/>
                                    }
                                </button>
                            </div>
                        </section>


                    </div>
                </div>
            </div>
        </section>
    );
}
export default CreateCampaign