import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {formatMessage, getAxiosConfig, getSendGridAuthConfig, showToast} from "../../helpers/utils";
import {CreatedSuccessfully, DeletedSuccessfully} from "../../helpers/constants";


export const validateSendGridApiKey = createAsyncThunk('apiKey/validateSendGridApiKey', async (apiKey, thunkAPI) => {
    return await axios.get(`https://api.sendgrid.com/v3/scopes`,  getSendGridAuthConfig(apiKey)).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

export const createApiKey = createAsyncThunk('apiKey/createApiKey', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/api-key`, data, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})
export const getApiKeysByOrgId = createAsyncThunk('apiKey/getApiKeysByOrgId', async (orgId, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api-key/orgId/${orgId}`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})
export const deleteApiKeyById = createAsyncThunk('apiKey/deleteApiKeyById', async (apiKeyId, thunkAPI) => {
    return await axios.delete(`${process.env.REACT_APP_BASE_URL}/api-key/${apiKeyId}`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})


const apiKeySlice = createSlice({
    name: "apiKey",
    initialState: {
        createApiKeyReducer: {loading: false},
        getApiKeysByOrgIdReducer: {loading: false},
        deleteApiKeyByIdReducer: {loading: false},
        validateSendGridApiKeyReducer: {loading: false},
    },
    reducers: {},
    extraReducers: {
        // Create Campaign
        [createApiKey.pending]: (state) => {
            state.createApiKeyReducer = {loading: true};
        },
        [createApiKey.fulfilled]: (state, action) => {
            showToast(formatMessage(CreatedSuccessfully, ["Api Key"]), "success")
            state.createApiKeyReducer = {loading: false, data: action.payload.data};
        },
        [createApiKey.rejected]: (state, action) => {
            state.createApiKeyReducer = {loading: false};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText, "error")

        },
        // Get ApiKeys By OrgId
        [getApiKeysByOrgId.pending]: (state) => {
            state.getApiKeysByOrgIdReducer = {loading: true};
        },
        [getApiKeysByOrgId.fulfilled]: (state, action) => {
            state.getApiKeysByOrgIdReducer = {loading: false, data: action.payload};
        },
        [getApiKeysByOrgId.rejected]: (state, action) => {
            state.getApiKeysByOrgIdReducer = {loading: false};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText, "error")

        },
        // Delete Api Key By Id
        [deleteApiKeyById.pending]: (state) => {
            state.deleteApiKeyByIdReducer = {loading: true};
        },
        [deleteApiKeyById.fulfilled]: (state, action) => {
            showToast(formatMessage(DeletedSuccessfully, ["Api Key"]), "success")
            state.deleteApiKeyByIdReducer = {loading: false, data: action.payload};
        },
        [deleteApiKeyById.rejected]: (state, action) => {
            state.deleteApiKeyByIdReducer = {loading: false};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText, "error")
        },
        // Validate Send Grid Api Key
        [validateSendGridApiKey.pending]: (state) => {
            state.validateSendGridApiKeyReducer = {loading: true};
        },
        [validateSendGridApiKey.fulfilled]: (state, action) => {
            state.validateSendGridApiKeyReducer = {loading: false, data: action.payload};
        },
        [validateSendGridApiKey.rejected]: (state, action) => {
            state.validateSendGridApiKeyReducer = {loading: false};
            showToast("Invalid Api Key", "error")
        },

    }
})

export const {} = apiKeySlice.actions

export default apiKeySlice.reducer;
