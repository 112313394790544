import Modal from 'react-bootstrap/Modal';
import './AddMemberModal.css'
import {toast} from "react-toastify";
import {
    emailRegExp,
    generateFakeEmail,
    getDataFromLocalStorage, handleApiResponse,
    isNullOrEmpty,
    isUnauthorized,
    showToast
} from "../../../helpers/utils";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    createUser,
     getAllUsersWithoutPagination,
    resetGetAllUsersWithoutPaginationReducer
} from "../../../reduxStore/usersSlices/userSlice";
import {TailSpinLoader} from "../../layout/Loader";
import {hasPermission, Permissions, roleHasAccess, Roles} from "../../../helpers/accessControl"
import {
    AssignMenteeRequired,
    EmailRequired,
    FirstNameRequired, FirstNameTooLong,
    InvalidEmail, LastNameTooLong, RoleRequired,
} from "../../../helpers/constants";
import {toString} from "../../../helpers/utils";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {getAllRoles} from "../../../reduxStore/rolesSlices/rolesSlices";
import Skeleton from "react-loading-skeleton";
import jwtDecode from "jwt-decode";
import CreatableSelect from "react-select/creatable";
import {IoIosClose} from "react-icons/io";

const components = {
    DropdownIndicator: null,
};

const tagOption = (label) => ({
    label: label,
    value: label,
});
const AddMemberModal = ({show, setShow, triggerApi,subOrgId}) => {

    const handleClose = () => setShow(false);
    const isSystemOrgAdmin = ((roleHasAccess([Roles.ORG_ADMIN])) && jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === "SYSTEM")

    const {pathname} = useLocation();
    const {orgId} = useParams();
    const roles = useSelector(state => state.roles)
    const navigate = useNavigate();
    const users = useSelector(state => state.users)
    const dispatch = useDispatch();
    const [email, setEmail] = useState("")
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [isRoleMentor, setIsRoleMentor] = useState(roleHasAccess([Roles.MENTOR]))
    const [role, setRole] = useState(isRoleMentor ? Roles.MENTEE : "")
    const [tagValue, setTagValue] = useState('');
    const [tags, setTags] = useState([])
    const [assignMenteeTo, setAssignMenteeTo] = useState(pathname.startsWith("/organizations")?"":jwtDecode(getDataFromLocalStorage("token")).userid)




    function handleSubmit(e) {
        e.preventDefault()
        let message;
        // Validate Form

        if (isNullOrEmpty(firstName)) {
            message = FirstNameRequired
        } else if (firstName.length > 25) {
            message = FirstNameTooLong
        } else if (!isNullOrEmpty(lastName) && lastName.length > 25) {
            message = LastNameTooLong
        } else if (isNullOrEmpty(role)) {
            message = RoleRequired
        } else if (role === Roles.MENTEE && isNullOrEmpty(assignMenteeTo)) {
            message = AssignMenteeRequired
        } else if ( isNullOrEmpty(email)) {
            message = EmailRequired
        } else if (!emailRegExp.test(email)) {
            message = InvalidEmail
        }


        if (message) {
            showToast(message,"error")

        } else {

            dispatch(createUser({
                firstName: firstName,
                lastName: lastName,
                email: email,
                roles: [role],
                tags:tags.length > 0 ? tags.map(c=> c.value) : [],
                orgId:subOrgId !== undefined ? subOrgId : jwtDecode(getDataFromLocalStorage("token")).orgId,
                ...(role === Roles.MENTEE ? {mentorIds: [assignMenteeTo]} : {}),
            }))
                .then((res) => {
                    if (isUnauthorized(res)) {
                        navigate("/")
                    }
                    const onSuccess=()=>{
                        dispatch(resetGetAllUsersWithoutPaginationReducer())
                        triggerApi(1)
                        setShow(false)
                    }
                    handleApiResponse(res,onSuccess)
                })
        }


    }

    useEffect(() => {
        if(role && role===Roles.MENTEE){
            if(email===null || email===undefined || email==="" || email.endsWith("fake.com") ){
                setEmail(generateFakeEmail(firstName,lastName))
            }
        }
    }, [role,firstName,lastName]);

    // GET roles to load dynamically in drop down list
    useEffect(() => {
        dispatch(getAllRoles(pathname.startsWith("/organizations")?orgId:"")).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
            if (res.meta.requestStatus === "fulfilled") {
                if (res.payload.data.length === 1 && res.payload.data[0].name === Roles.MENTEE) {
                    setRole(res.payload.data[0].name)
                }
            }
        })

    }, [])


    // GET mentors list to load dynamically in drop down list
    useEffect(() => {

        if (role === Roles.MENTEE  && roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) && hasPermission([Permissions.USER_READ], true)) {
            dispatch(getAllUsersWithoutPagination({
                roles: [Roles.MENTOR,Roles.MANAGER,Roles.ORG_ADMIN,Roles.SUB_ORG_ADMIN],
                orgId: subOrgId !== undefined ? subOrgId : jwtDecode(getDataFromLocalStorage("token")).orgId

            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }

    }, [role])

    const handleInputChange = (inputValue) => {
        setTagValue(inputValue);
    };

    const handleKeyDown = (event) => {
        if (!tagValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                saveValue();
                event.preventDefault();
        }
    };
    const handleChange = (value) => {
        setTags(value)
    };

    function saveValue() {
        if (!tagValue) return;
        if (tags !== undefined && !tags.some(item => item.value === tagValue)) {
            setTags([...tags, tagOption(tagValue)]);
            setTagValue("")
        }
        else{
            toast.info("Tag already exist please try another one!")
        }
    }
    return (
        <>
            <Modal className='mt-4' show={show} centered>
                <Modal.Header className={"d-flex"}>
                    <Modal.Title className='add_member_heading mediumFontSize'>{ isRoleMentor ? " Add Mentee" : (isSystemOrgAdmin && subOrgId === undefined ? " Invite Team Member " : " Add Member")}</Modal.Title>
                    <IoIosClose className={"close_btn"} onClick={handleClose} size={24}/>
                </Modal.Header>

                <form onSubmit={handleSubmit}>

                    <Modal.Body className="modal_outer">


                        <div className="reset_form">

                            <div className="form-group mt-1">
                                <label className='userInfo_label mediumFontSize' form="inputAddress">First
                                    Name*</label>
                                <input required={true} type="text" value={firstName} onChange={(e) => {
                                    setFirstName(e.target.value)
                                }} className="form-control userInfo_input" id="inputAddress"/>
                            </div>
                            <div className="form-group mt-1">
                                <label className='userInfo_label mediumFontSize' form="inputAddress">Last
                                    Name</label>
                                <input type="text" value={lastName} onChange={(e) => {
                                    setLastName(e.target.value)
                                }} className="form-control userInfo_input" id="inputAddress"/>
                            </div>

                            <div className={"form-group mt-1"}>

                                <label className={"userInfo_label mb-2"} htmlFor="exampleFormControlSelect1"> Tags</label>


                                <CreatableSelect
                                    components={components}
                                    inputValue={tagValue}
                                    isClearable
                                    isMulti
                                    menuIsOpen={false}
                                    placeholder={""}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    onInputChange={handleInputChange}
                                    onMenuClose={saveValue}
                                    value={tags}
                                    isDisabled={false}
                                />

                            </div>


                            {
                                !isRoleMentor && <div className="form-group mt-3 mb-2">
                                    <label className={"userInfo_label"} htmlFor="exampleFormControlSelect1"> Select
                                        Role* </label>
                                    {
                                        roles.getAllRolesDataReducer?.loading ? <div className={"roles-loading"}>
                                                <Skeleton height={40}/>
                                            </div> :
                                            <select required={true} value={role} onChange={(e) => {
                                                setRole(e.target.value)
                                                setEmail("")
                                            }} className="form-control select_role mt-2" id="exampleFormControlSelect1">
                                                <option value="">-----Select Role-----</option>
                                                {
                                                    roles?.getAllRolesDataReducer?.data && roles?.getAllRolesDataReducer?.data.length > 0 && roles.getAllRolesDataReducer?.data?.filter(role => role.name !== Roles.SUB_ORG_ADMIN && role.name!==Roles.ANONYMOUS_USER)?.map((role, index) => {
                                                            return (<option value={role.name}
                                                                            key={index}>{toString(role.name)}</option>)
                                                        }
                                                    )
                                                }
                                            </select>
                                    }


                                </div>
                            }

                            {
                                role === "" && <></>
                            }
                            {
                                role === Roles.MENTEE && roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) && hasPermission([Permissions.USER_READ], true) && <>
                                    <div className="form-group mt-3 mb-2">
                                        <label className={"userInfo_label"} htmlFor="exampleFormControlSelect1"> Assign
                                            Mentor* </label>
                                        {
                                            users?.getAllUsersWithoutPaginationReducer?.loading ?
                                                <div className={"roles-loading"}>
                                                    <Skeleton height={40}/>
                                                </div> :
                                                <select required={true} value={assignMenteeTo} onChange={(e) => {
                                                    setAssignMenteeTo(e.target.value)

                                                }} className="form-control select_role mt-2" id="exampleFormControlSelect1">
                                                    {
                                                        !pathname.startsWith("/organizations")  && <option
                                                                                             value={""}>----Select Mentor/Manager----
                                                        </option>
                                                    }
                                                    {
                                                        pathname.startsWith("/organizations") && <option value={""}>----Select Mentor/Manager----</option>
                                                    }
                                                    {
                                                        users?.getAllUsersWithoutPaginationReducer?.data && users?.getAllUsersWithoutPaginationReducer?.data?.length > 0 && users?.getAllUsersWithoutPaginationReducer?.data?.map((member, index) => {
                                                                return (<option value={member.uid}
                                                                                key={index}>{toString(member.firstName + " " + member.lastName)}</option>)
                                                            }
                                                        )
                                                    }
                                                </select>
                                        }


                                    </div>


                                </>
                            }
                            {
                                roles.getAllRolesDataReducer?.data?.filter(role => role.name !== Roles.SUB_ORG_ADMIN )?.map(role => role.name).includes(role) && <>
                                    <div className="form-group mt-1">
                                        <label className='userInfo_label mediumFontSize'
                                               form="inputAddress">Email *</label>
                                        <input required={true} type="email" value={email}
                                               onChange={(e) => {
                                                   setEmail(e.target.value)
                                               }} className="form-control userInfo_input" id="inputAddress"/>
                                    </div>
                                </>
                            }
                        </div>


                    </Modal.Body>

                    <Modal.Footer className="modal_footer">
                        <div className={"add-member-modal-btns"}>
                            <button style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""} } type="submit" disabled={users.createUserReducer?.loading === true}
                                    className="ms-2 modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize ">{isRoleMentor ? " Add Mentee" : (isSystemOrgAdmin && subOrgId === undefined  ? " Invite Team Member ":"Add Member")}
                                {users.createUserReducer?.loading && (
                                    <TailSpinLoader/>)}

                            </button>
                            <button className="modal_cancel_btn cmn_modal_btn mediumFontSize"
                                    onClick={handleClose}>Cancel
                            </button>

                        </div>

                    </Modal.Footer>
                </form>
            </Modal>


        </>
    )


}
export default AddMemberModal;



