import Modal from 'react-bootstrap/Modal';
import close_square from '../../images/croosIcon.png'
import {useSelector} from "react-redux";
import {
    formatDate,
    toString,
} from "../../helpers/utils";
import DataTable from "react-data-table-component";
import {PulsePlans} from "../../helpers/constants";
const ViewHistoryModal = ({show, setShow}) => {
    const handleClose = () => setShow(false);
    const users=useSelector(state => state.users)
    const columns = [
        {
            name: "Plan Name",
            selector: row => row.planName,
            sortable: true,
            style: {
                fontFamily: 'General Sans Medium',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            },
            cell: row => <div className={"wrap-text"}>{toString(row.planName)}</div>

        },

        {
            name: "Status",
            selector: row => toString(row?.subscriptionStatus),
            sortable: true,
            style: {
                opacity: ".7"
            },
            width: "120px"
        },
        {
            name: "Interval",
            selector: row =>  PulsePlans[row?.planName].value===PulsePlans.INDIVIDUAL.value? "---" :  formatDate(row.createdDate) +" to " + formatDate(row.expiredDate),
            sortable: true,
            style: {
                opacity: ".7"
            },
            width: "210px"
        }

        ,
        {
            name: "Amount",
            selector: row => PulsePlans[row?.planName].value===PulsePlans.INDIVIDUAL.value? "Free" : "$ "+row?.amount?.toFixed(2),
            sortable: true,
            style: {
                opacity: ".7"
            },
            width: "100px"
        }
        ]



    return (
        <>
            <Modal className='mt-4 modal-lg' show={show} centered>
                <Modal.Header>
                    <Modal.Title
                        className='add_member_heading mediumFontSize'>Plan History</Modal.Title>
                    <div className="closeImg" onClick={handleClose}>
                        <img src={close_square} alt="" width={"15"}/>
                    </div>


                </Modal.Header>

                <form >

                    <Modal.Body className="modal_outer">
                        <DataTable className={"table_design1"}
                                   noDataComponent={<div className={"lightFontSize cmn_fontStyle mt-2 mb-2"}>No
                                       Plan Purchased</div>}
                                   persistTableHead={true} responsive={true}
                                   progressPending={users.getSubscriptionDataReducer?.loading}
                                   columns={columns}
                                   data={users.getSubscriptionDataReducer?.data}
                        ></DataTable>




                    </Modal.Body>

                    <Modal.Footer className="modal_footer">
                        <div className={"add-member-modal-btns"}>
                            <button className="modal_cancel_btn cmn_modal_btn mediumFontSize" onClick={handleClose}>Close
                            </button>

                        </div>

                    </Modal.Footer>
                </form>
            </Modal>


        </>
    )


}
export default ViewHistoryModal;