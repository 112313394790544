import "./CustomReport.css"
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffect, useMemo} from "react";
import {
    deleteCustomReport,
    getAllCustomReportsData,
    getIdCustomReport, resetGetIdCustomReportReducer,
} from "../../reduxStore/CustomReportSlices/customReport";
import {
    computeBackDate,
    createOptionListForSelectTag,
    createOptionListForSelectTagWithRole,
    EmptyBarChart,
    formatDate,
    generateDateFor,
    getBackgroundColorOnResult,
    getBlobForMemberAttemptSummaryGraph,
    getBlobForReflectionSummaryGraph,
    getCombinedReportResult,
    getDataFromLocalStorage,
    getLabelColorOnDisabledReflection,
    isArrayEmpty,
    isUnauthorized,
    resourceToFile,
    setDateFormat,
    tableCustomStyling,
    TableSkeletonLoading,
    toString,
    trim
} from "../../helpers/utils";
import ReportItem from "./ReportItem";
import Skeleton from "react-loading-skeleton";
import {useState} from "react";
import React from "react";
import {hasPermission, Permissions, roleHasAccess, Roles} from "../../helpers/accessControl";
import {
    getAllSurveysWithoutPagination, shareSurveyAttemptReportAction
} from "../../reduxStore/surveySlices/surveySlices";
import jwtDecode from "jwt-decode";
import CustomReportModal from "../reports/Modal/CustomReportModal";
import ConfirmationModel from "../commons/ConfirmationModel";
import {Bar} from "react-chartjs-2";
import {
    getAggregateReflectionAttemptReport,
    getSurveyAttemptReportByMentee
} from "../../reduxStore/reportsSlice/reportsSlice";
import Graphs from "../reports/Graphs";
import {Loader} from "../layout/Loader";
import html2canvas from "html2canvas";
import ShareReport from "../reports/Modal/ShareReport";
import UserInfo from "../layout/UserInfo";
import AssignCustomReport from "./modal/AssignCustomReport";
import DataTable from "react-data-table-component";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {ToggleButton} from "../commons/ToggleButton";

const CustomReport = ({isSubOrg, subOrgId}) => {


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const customReport = useSelector(state => state.customReport);
    const surveysWithoutPaginationData = useSelector(state => state.survey.getAllSurveysWithoutPaginationDataReducer);
    const allUsersWithoutPaginationData = useSelector(state => state.users.getAllUsersWithoutPaginationReducer);

    const aggregateReflectionAttemptReportData = useSelector(state => state.reports.getAggregateReflectionAttemptReportReducer);
    const getSurveyAttemptReportByMenteeData = useSelector(state => state.reports.getSurveyAttemptReportByMenteeDataReducer)

    const [baseSearchQuery, setBaseSearchQuery] = useState({
        createdDateRange: {
            startDate: computeBackDate(6), endDate: new Date()
        },
    });
    const [surveyDropDownList, setSurveyDropDownList] = useState([]);
    const [userDropDownList, setUserDropDownList] = useState([]);
    const [reports, setReports] = useState([]);
    const [customReportModal, setCustomReportModal] = useState(false)
    const [showActionDropDown, setShowActionDropDown] = useState(false)
    const [reportDetails, setReportDetails] = useState(null)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [reportId, setReportId] = useState(null);
    const [customReportId, setCustomReportId] = useState(null);
    const [deleteReportId, setDeleteReportId] = useState(null);
    const [showReportModal, setShowReportModal] = useState(false)
    const [reportShare, isReportShare] = useState(false)
    const [email, setEmail] = useState([])
    const [reportBlob, setReportBlob] = useState([])
    const [graphicalView, isGraphicalView] = useState(true)

    const [showAssignCustomReportModal, setShowAssignCustomReportModal] = useState(false);
    const [chartData, setChartData] = useState({
        labels: "",
        datasets: [
            {
                label: '',
                data: [],
                backgroundColor: "",

            },
        ]
    })

    const chartOptions = {
        maintainAspectRatio: false,
        scales: {
            x: {
                ticks: {
                    color: getLabelColorOnDisabledReflection(aggregateReflectionAttemptReportData?.data, "surveyName", surveysWithoutPaginationData?.data, "name"),
                    callback: function (value) {
                        return trim(chartData.labels[value], 24);

                    }
                }
            },
            y: {
                ticks: {
                    color: "black"
                }
            }

        },
        plugins: {
            responsive: true,
            legend: {
                display: false,
            },

        },


    };

    useEffect(() => {
        const currentDate = new Date();
        let searchQueryToBeSubmitted = {
            ...baseSearchQuery,
            orgId: isSubOrg ? subOrgId : null
        }
        if (baseSearchQuery?.createdDateRange) {
            searchQueryToBeSubmitted.createdDateRange =
                {
                    "startDate": setDateFormat(generateDateFor(30)),
                    "endDate": setDateFormat(currentDate)
                }
        }
        dispatch(getAggregateReflectionAttemptReport(searchQueryToBeSubmitted)).then((res) => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
        })
        dispatch(getSurveyAttemptReportByMentee(searchQueryToBeSubmitted)).then(res => {
            if (isUnauthorized(res)) {
                navigate("/");
            }
        });

    }, [])

    useEffect(() => {
        if (aggregateReflectionAttemptReportData?.data !== null && aggregateReflectionAttemptReportData?.data !== undefined) {
            const chartLabels = aggregateReflectionAttemptReportData?.data?.map((data) => trim(data.surveyName, 10))
            const chartData = aggregateReflectionAttemptReportData?.data?.map((data) => data.aggregateReflectionResult)
            const backgroundColor = aggregateReflectionAttemptReportData.data?.map((data) => getBackgroundColorOnResult(data.aggregateReflectionResult))
            setChartData({
                labels: chartLabels,
                datasets: [{...chartData.datasets, data: chartData, backgroundColor: backgroundColor}]
            })
        }
    }, [aggregateReflectionAttemptReportData])

    useEffect(() => {
        dispatch(getAllCustomReportsData({orgId: subOrgId})).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
        })
        dispatch(getAllSurveysWithoutPagination({orgLevelId: isSubOrg ? subOrgId : getDataFromLocalStorage("loggedInUserInfo")?.orgId})).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
        })

    }, [])

    useEffect(() => {
        dispatch(resetGetIdCustomReportReducer())
        if (reportId !== null) {
            dispatch((getIdCustomReport(reportId))).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (res.meta.requestStatus === "fulfilled") {
                    setReportDetails(res?.payload)
                    setReportId(null)

                }
            })
        }
    }, [reportId])

    useEffect(() => {
        if (surveysWithoutPaginationData.data !== null && surveysWithoutPaginationData.data !== undefined) {
            setSurveyDropDownList(createOptionListForSelectTag(surveysWithoutPaginationData?.data, "name", "surveyId"))
        }
    }, [surveysWithoutPaginationData])

    useEffect(() => {
        if (allUsersWithoutPaginationData.data !== null && allUsersWithoutPaginationData.data !== undefined) {
            setUserDropDownList(createOptionListForSelectTagWithRole(allUsersWithoutPaginationData?.data, "firstName", "uid"))
        }
    }, [allUsersWithoutPaginationData])

    useEffect(() => {

        if (reportShare && reportBlob?.length>0) {
            dispatch(shareSurveyAttemptReportAction({
                email: email.map(c => c?.value),
                files: reportBlob,
                startDate: setDateFormat(generateDateFor(30)),
                endDate: setDateFormat(new Date())
            })).then((res) => {
                if (res?.payload?.data === 'Success') {
                    setEmail([]);
                    setShowReportModal(false)
                    isReportShare(false)
                    setReportBlob([])
                }

            })
        }
    }, [reportShare]);

    const handleDeleteReport = () => {
        if (deleteReportId !== null && deleteReportId !== "") {
            dispatch(deleteCustomReport(deleteReportId)).then((response) => {
                if (isUnauthorized(response)) {
                    navigate("/")
                }
                if (response.meta.requestStatus === "fulfilled") {
                    setShowConfirmationModal(false)
                    setDeleteReportId(null)
                    dispatch(getAllCustomReportsData({orgId: subOrgId})).then(res => {
                        if (isUnauthorized(res)) {
                            navigate("/")
                        }
                    })
                }
            });

        }
    }

    const prepareReports = (customReportId, graphId) => {
        if(graphId==="MemberAttemptSummaryGraph"){
            const blob =getBlobForMemberAttemptSummaryGraph(getSurveyAttemptReportByMenteeData?.data);
            reportBlob.push({blob: blob, name: "MemberAttemptSummaryGraph.csv"})
        }
        if(graphId==="ReflectionSummaryGraph"){
            const blob =getBlobForReflectionSummaryGraph(aggregateReflectionAttemptReportData?.data);
            reportBlob.push({blob: blob, name: "ReflectionSummaryGraph.csv"})
        }

        const chartElementKey = document.getElementById(graphId)
        let promises = [];
        if (chartElementKey) {
            promises.push(
                html2canvas(chartElementKey).then((canvas) => {
                    return new Promise((resolve) => {
                        canvas.toBlob((blob) => {
                            let newName = graphId + '.png';
                            resolve({blob: blob, name: newName, reportId: customReportId});
                        });
                    });
                })
            );
        }
        Promise.all(promises).then((results) => {
            results.forEach(result => {
                reportBlob.push(result);
            });
        });
    };

    const columns = useMemo(() =>
            [
                {
                    name: <span className={"bulk_delete_Main_header"}> Name </span>,
                    selector: row => row?.reportName,
                    cell: row => {
                        return (
                            <span className={"bulk_delete_Main_header " + (isSubOrg ? "" : "cursor cursor-pointer ")}
                                  onClick={() => {
                                      if (!isSubOrg) navigate("/report/" + row.id)
                                  }}>{row.reportName}</span>
                        );
                    },
                },
                {
                    name: "Created date",
                    selector: row => formatDate(row?.createdAt),
                },
                {
                    name: "Owner",
                    cell: row => {
                        const names = row?.assignedToData?.map(c => c?.firstName + " " + c?.lastName)?.join(", ")
                        return (
                            <div data-tooltip-id="assignData" data-tooltip-content={names}>
                                {isArrayEmpty(names) ? "---" : trim(names, 60)}
                                <ReactTooltip className={"assignData"} place={"bottom"} id="assignData"/>
                            </div>
                        );
                    },
                    style: {opacity: ".7"},
                },
                {
                    name: "Result",
                    selector: row => row?.reportResult?.length === 0 ? "N/A" : getCombinedReportResult(row?.reportResult),
                },
                {
                    name: "Chart type",
                    selector: row => toString(row?.reportType),
                },
                {
                    name: "Action",
                    selector: row => row?.reportName,
                    cell: row => {
                        return (
                            <>
                                {row.userId === getDataFromLocalStorage("loggedInUserInfo").userId &&
                                    <i className="fa fa-ellipsis-h cursor" onClick={() => {
                                        if (!showActionDropDown) {
                                            setShowActionDropDown(true)
                                            setCustomReportId(row?.id)
                                        } else {
                                            setShowActionDropDown(false)
                                            setCustomReportId(null)
                                        }
                                    }}/>}
                                {showActionDropDown && row?.id === customReportId &&
                                    <div className={"graph-outer-Pie-dropDown_tabular"}>
                                        <ul className={"custom_dropdown"}
                                            style={{listStyleType: 'none', padding: 0, margin: 0}}>
                                            <li>
                                                <button onClick={() => {
                                                    setReportId(row.id)
                                                    setCustomReportModal(true)
                                                    setShowActionDropDown(false)
                                                }}>Update Report
                                                </button>
                                            </li>
                                            <li>
                                                <button onClick={() => {
                                                    setShowConfirmationModal(true)
                                                    setDeleteReportId(row.id)
                                                    setShowActionDropDown(false)
                                                }}>Delete Report
                                                </button>
                                            </li>
                                            {roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER, Roles.SUB_ORG_ADMIN]) && <li>
                                                <button onClick={() => {
                                                    setReportId(row.id)
                                                    setShowAssignCustomReportModal(true)
                                                    setShowActionDropDown(false)
                                                }}>Assign Report
                                                </button>
                                            </li>
                                            }
                                        </ul>
                                    </div>

                                }
                            </>

                        );
                    },
                }]
        , [showActionDropDown, customReportId]);


    return (
        <>
            <section>
                {
                    !isSubOrg &&
                    <>

                        <div className="cmn_header">
                            <h2 className="mediumFontSize  org-heading m-0">Reports</h2> <UserInfo/>
                        </div>
                    </>
                }

                <div className={"row main_content mt-3"}>
                    {
                        !isSubOrg &&
                        <>
                            <div className={"col-xl-12"}>
                                <div className={"reportData"}>
                                    <button onClick={() => {
                                        setCustomReportModal(true)
                                        setReportDetails(null)
                                    }}
                                            style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                            className={"mediumFontSize cmn_background_color"}>Create Report
                                    </button>

                                </div>


                            </div>
                        </>
                    }


                    {isSubOrg &&
                        (((jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'SYSTEM' && roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER])) && hasPermission([Permissions.REPORT_CREATE, Permissions.REPORT_WRITE], true)) || (jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'CUSTOMER' && roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER])) && hasPermission([Permissions.REPORT_CREATE, Permissions.REPORT_WRITE], true)) &&

                        <>
                            <div className={"col-xl-12"}>
                                <div className={"reportData"}>
                                    <button onClick={() => {
                                        setCustomReportModal(true)
                                        setReportDetails(null)
                                    }}
                                            style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                            className={"mediumFontSize cmn_background_color"}>Create Report
                                    </button>

                                </div>


                            </div>
                        </>

                    }


                    <div className={"row ms-2 p-0 mb-2 mt-2"}>
                        <div className={"col-xl-6 pe-1"}>
                            {
                                aggregateReflectionAttemptReportData?.loading ? (
                                    <div className={"div-chart position-static"}>
                                        <Loader/>
                                    </div>
                                ) : aggregateReflectionAttemptReportData?.data?.length === 0 ? (
                                    <EmptyBarChart/>
                                ) : (
                                    <div className={"div-chart d-block"}>

                                        <div className={"d-flex Reflection-graph "}>

                                            <h6>Reflection Summary Graph</h6>

                                            <i className={"fas   cursor "} onClick={() => {
                                                setShowReportModal(true)
                                                prepareReports(null, "ReflectionSummaryGraph")
                                            }
                                            }>&#xf1e0;</i>

                                        </div>

                                        <div className={"bar-height"}>

                                            <Bar id={"ReflectionSummaryGraph"} height="350" data={chartData}
                                                 options={chartOptions}/>
                                        </div>
                                    </div>
                                )
                            }


                        </div>
                        <div className={"col-xl-6 pe-1"}>
                            <div className={"div-chart d-block"}>

                                <div className={"d-flex Reflection-graph"}>
                                    <h6>Member Attempt Summary Graph</h6>
                                    <i className={"fas   cursor "} onClick={() => {
                                        setShowReportModal(true)
                                        prepareReports(null, "MemberAttemptSummaryGraph")
                                    }}>&#xf1e0;</i>
                                </div>


                                <div>
                                    <Graphs height="350" data={getSurveyAttemptReportByMenteeData?.data}
                                            isDataLoading={getSurveyAttemptReportByMenteeData?.loading}
                                            reportType={"USER_ATTEMPT_REPORT"} graphId={'MemberAttemptSummaryGraph'}/>
                                </div>

                            </div>

                        </div>
                    </div>


                    <div className={"row ms-2 p-0 mb-2"}>
                        {
                            Array.isArray(customReport?.getAllCustomReportsDataReducer?.data) && customReport?.getAllCustomReportsDataReducer?.data?.length > 0 &&
                            <div className={"report_toggle_btn d-flex"}>
                                <ToggleButton toggleView={graphicalView} isToggle={isGraphicalView}/>
                            </div>
                        }


                        {customReport?.getAllCustomReportsDataReducer?.loading ? (
                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                {Array.from({length: 2}).map((_, index) => (
                                    <div key={index}>
                                        <Skeleton
                                            width={550}
                                            height={350}
                                            style={{
                                                backgroundColor: 'white',
                                                display: 'flex',
                                                margin: '10px',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>

                        ) : (customReport?.getAllCustomReportsDataReducer?.data?.length > 0
                            && <>
                                {graphicalView &&
                                    customReport?.getAllCustomReportsDataReducer?.data?.map((cur, index) => {
                                        return (
                                            <>
                                                <div className={"col-xl-6  pe-1 mt-2"}>
                                                    <div key={index} className={"chart-div-color"}>
                                                        <ReportItem report={cur} customReportId={cur.id}
                                                                    setDeleteReportId={setDeleteReportId}
                                                                    setReportId={setReportId}
                                                                    setShowConfirmationModal={setShowConfirmationModal}
                                                                    setCustomReportModal={setCustomReportModal}
                                                                    setShowAssignCustomReportModal={setShowAssignCustomReportModal}
                                                                    reportName={cur.reportName} reports={reports}
                                                                    setReports={setReports}/>
                                                    </div>
                                                </div>
                                            </>

                                        );
                                    })
                                }
                                {!graphicalView && <DataTable
                                    className={"mt-3 table_design1 half_scroll pe-0 ps-0 mb-3"}
                                    persistTableHead={true} responsive={true}
                                    customStyles={tableCustomStyling}
                                    progressPending={customReport?.getAllCustomReportsDataReducer?.loading}
                                    columns={columns}
                                    data={customReport?.getAllCustomReportsDataReducer?.data}
                                    noDataComponent={<div className={"no-member-text"}>No Result Found</div>}
                                    progressComponent={<TableSkeletonLoading columns={5} rows={5}/>}
                                />
                                }
                            </>
                        )}

                    </div>
                </div>

            </section>
            {
                customReportModal && <CustomReportModal show={customReportModal} setShow={setCustomReportModal}
                                                        surveyDropDownList={surveyDropDownList}
                                                        userDropDownList={userDropDownList}
                                                        reportDetails={reportDetails}
                                                        setReportDetails={setReportDetails}
                                                        isSubOrg={isSubOrg}
                                                        isTeam={false}
                                                        isSubOrgCustomReport={isSubOrg && (((jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'SYSTEM' || roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER])) && hasPermission([Permissions.REPORT_CREATE, Permissions.REPORT_WRITE], true)) || (jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'CUSTOMER' || roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER])) && hasPermission([Permissions.REPORT_CREATE, Permissions.REPORT_WRITE], true))}
                                                        subOrgId={subOrgId}/>
            }


            {
                showConfirmationModal &&
                <ConfirmationModel title={"Delete Report"}
                                   show={showConfirmationModal}
                                   isLoading={customReport?.deleteCustomReportReducer?.loading}
                                   btnText={"Delete"}
                                   action={handleDeleteReport}
                                   bodyMessage={"Are you sure you want to proceed with the deletion?"}
                                   setShow={setShowConfirmationModal}
                />
            }
            {
                showReportModal &&
                <ShareReport show={showReportModal} setShow={setShowReportModal} email={email} setEmail={setEmail}
                             title={"Share Reports"} reportShare={reportShare} isReportShare={isReportShare}/>
            }
            {
                showAssignCustomReportModal && <AssignCustomReport show={showAssignCustomReportModal}
                                                                   isSubOrg={isSubOrg}
                                                                   subOrgId={subOrgId}
                                                                   isTeam={false}
                                                                   setShow={setShowAssignCustomReportModal}
                                                                   reportToUpdate={reportDetails}/>
            }

        </>
    )

}
export default CustomReport;
