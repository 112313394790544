import filter_icon from "../../images/filter_icon.svg";

import React, {useEffect, useState} from "react";
import {
    applyValidDateRange,
    createOptionListForSelectTag,
    formatAndComputeAutoDateRange, formatDate,
    getDataFromLocalStorage,
    handleApiResponse,
    isUnauthorized,
    tableCustomStyling,
    TableSkeletonLoading, trim
} from "../../helpers/utils";
import DataTable from "react-data-table-component";
import {MdOutlineEdit} from "react-icons/md";
import {RiDeleteBinLine} from "react-icons/ri";
import "./strategy.css"
import DatePicker from "react-datepicker";
import Pagination from "../commons/Pagination";
import UserInfo from "../layout/UserInfo";
import CreateStrategyModal from "./modal/CreateStrategyModal";
import search_icon from "../../images/search_icon.svg";
import {SearchFilterModal} from "../commons/SearchFilterModal";
import Select from "react-select";
import {Roles} from "../../helpers/accessControl";
import jwtDecode from "jwt-decode";
import {getAllOrgAsList} from "../../reduxStore/orgSlices/orgSlices";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import image from "../../images/CrossIcon2.svg";
import {deleteStrategy, searchStrategy} from "../../reduxStore/strategySlice/strategySlice";
import ConfirmationModel from "../commons/ConfirmationModel";
import ViewStrategyModal from "./modal/ViewStrategyModal";
import {FaEye} from "react-icons/fa"

const Strategy = ({isSubOrg, subOrgId}) => {

    const token = jwtDecode(getDataFromLocalStorage("token"))
    const isOrgLevelSys = token.orgLevel === "SYSTEM"
    const isRoleOrgAdmin = token.roles.includes(Roles.ORG_ADMIN) || token.roles.includes(Roles.SUB_ORG_ADMIN)
    const isRoleManager = token.roles.includes(Roles.MANAGER)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [timer, setTimer] = useState(null)
    const [strategyToDeleteId, setStrategyToDeleteId] = useState(null)
    const [strategyToView, setStrategyToView] = useState(null)
    const [triggerApi, setTriggerApi] = useState(true)
    const [triggerApiOnNameChange, setTriggerApiOnNameChange] = useState(false)
    const [modals, setModals] = useState({
        showDeleteStrategyConfirmationModal: false,
        showCreateStrategyModal: false,
        showViewStrategyModal: false,
        showSearchFilterModal: false,
    })
    const [searchQuery, setSearchQuery] = useState({
        name: null,
        org: {
            label: "Organization...", value: isSubOrg ? subOrgId : token?.orgId
        },
        aim: "",
        goal: "",
        createdDateRange: null
    })
    const [sortCriteria, setSortCriteria] = useState({
        sortBy: "createdAt",
        sortOrder: "desc",
    })
    const [pageNumber, setPageNumber] = useState(1)

    const handleSearchQueryChange = (e) => {
        const {name, value} = e.target
        setSearchQuery({
            ...searchQuery,
            [name]: value
        })
    }

    const OrgListApi = useSelector(state => state.org.getAllOrgAsListReducer);
    const strategyApi = useSelector(state => state.strategy.searchStrategyReducer)
    const deleteStrategyApi = useSelector(state => state.strategy.deleteStrategyReducer)

    const columns = [
        {
            name: <div className={"d-flex"}>
                <span
                    className={"ms-2 bulk_delete_Main_header"}> {"Name " + (isOrgLevelSys && (isRoleOrgAdmin || isRoleManager) && !isSubOrg ? "( Organization )" : "")} </span>
            </div>,
            sortable: true,
            sortField: 'name',
            cell: (row) =>
                (
                    <div className={"d-flex"}>
                        <div className={"display_profile_data wrap-text ms-2"}>
                            <div
                                title={row.name + (isOrgLevelSys && (isRoleOrgAdmin || isRoleManager) && !isSubOrg ? " ( " + row.orgInfo.orgName + " )" : "")}>
                                {trim(row.name + (isOrgLevelSys && (isRoleOrgAdmin || isRoleManager) && !isSubOrg ? " ( " + row.orgInfo.orgName + " )" : ""), 30)}
                            </div>
                        </div>
                    </div>

                ),
            style: {
                fontFamily: 'General Sans Medium',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            },
            width: strategyApi?.loading ? "25%" : "30%"
        },
        {
            name: 'Created By',
            cell: row => <div>{trim(row.createdByUserInfo.firstName + " " + row.createdByUserInfo.lastName, 45)}</div>,
        },
        {
            name: "Created On",
            sortField: 'createdAt',
            sortable: true,
            cell: row => <div>{row.createdAt === "" ? "----" : (formatDate(row.createdAt))}</div>,
        },
        {
            name: 'Assigned To',
            cell: row => <div
                title={row?.surveysInfo?.map(cur => cur?.name).join(",")}>{trim(row?.surveysInfo?.map(cur => cur?.name).join(",") || "---", 40)}</div>,
        },
        {
            name: 'Actions',
            cell: row => (
                <div className={"d-flex gap-2 strategy_action_wrapper "}>

                    {
                        // If user is sys admin or sys manger or strategy is created by user then edit or delete will be visible
                        ((isOrgLevelSys && (isRoleOrgAdmin || isRoleManager)) || row.createdBy === token.userid) ?
                            <>
                                <button
                                    onClick={() => {
                                        navigate(`/strategy/${row.strategyId}`)
                                    }}
                                    className={`border-none bg-none`}
                                ><MdOutlineEdit/>
                                </button>
                                <button
                                    onClick={() => {
                                        setStrategyToDeleteId(row.strategyId)
                                        setModals({
                                            ...modals,
                                            showDeleteStrategyConfirmationModal: true
                                        })
                                    }}
                                    className={`border-none bg-none`}
                                ><RiDeleteBinLine/>
                                </button>
                            </>
                            :
                            <button
                                className={`border-none bg-none`}
                                onClick={() => {
                                    setStrategyToView(row)
                                    setModals({
                                        ...modals,
                                        showViewStrategyModal: true
                                    })
                                }}
                            ><FaEye/></button>
                    }


                </div>
            ),
        },
    ];
    let searchFiltersTags = [
        {
            shouldShow: isOrgLevelSys && (isRoleOrgAdmin || isRoleManager) && !isSubOrg,
            tagView: <Select
                className="members-filter-role react-select-container" classNamePrefix="react-select"
                isDisabled={OrgListApi?.loading}
                value={searchQuery?.org}
                options={createOptionListForSelectTag(OrgListApi?.data, "name", "orgId")}
                onChange={(selectedOrg) => {
                    if (searchQuery?.org?.value !== selectedOrg?.value) {
                        handleSearchQueryChange({target: {name: "org", value: selectedOrg}})
                    }
                }}
                isClearable={true}
                placeholder={"Organization..."}/>
        },
        {
            tagView: <>
                <div className="filter_input_wrapper">
                    <input
                        type={"text"}
                        className={"ms-0 form-control search-box-input-goal"}
                        placeholder={"Strategy Name..."}
                        onChange={handleSearchQueryChange}
                        value={searchQuery.name}
                        name={"name"}/>
                    <div className={"cross-btn-outer"}>
                        <img
                            src={image}
                            className={"survey-search-clr-btn cursor-pointer"}
                            onClick={() => {
                                handleSearchQueryChange({target: {name: "name", value: ""}})
                            }}/>
                    </div>
                </div>
            </>
        },
        {
            tagView: <>
                <div className="filter_input_wrapper">
                    <input
                        type={"text"}
                        className={"ms-0 form-control search-box-input-goal"}
                        placeholder={"Goal..."}
                        onChange={handleSearchQueryChange}
                        value={searchQuery.goal}
                        name={"goal"}/>
                    <div className={"cross-btn-outer"}>
                        <img
                            src={image}
                            className={"survey-search-clr-btn cursor-pointer"}
                            onClick={() => {
                                handleSearchQueryChange({target: {name: "goal", value: ""}})
                            }}/>
                    </div>
                </div>
            </>
        },
        {
            tagView: <div className={"members-date-picker-outer "}>
                <DatePicker placeholderText="From: dd-mm-yyyy" dateFormat={"dd-MM-yyyy"}
                            selected={searchQuery?.createdDateRange?.startDate || ""}
                            className={"search-by-survey-name survey-name-search member_search "}
                            maxDate={searchQuery?.createdDateRange?.endDate || new Date()}
                            onChange={(date) => {
                                handleSearchQueryChange({
                                    target: {
                                        name: "createdDateRange",
                                        value: applyValidDateRange({
                                            startDate: date,
                                            endDate: searchQuery?.createdDateRange?.endDate || null
                                        })
                                    }
                                })
                            }}
                />
                <DatePicker placeholderText="To: dd-mm-yyyy" dateFormat={"dd-MM-yyyy"}
                            selected={searchQuery?.createdDateRange?.endDate || ""}
                            minDate={searchQuery?.createdDateRange?.startDate || ""}
                            className={"search-by-survey-name survey-name-search user-join-toDate member_search "}
                            maxDate={new Date()}
                            onChange={(date) => {
                                handleSearchQueryChange({
                                    target: {
                                        name: "createdDateRange",
                                        value: applyValidDateRange({
                                            startDate: searchQuery?.createdDateRange?.startDate || null,
                                            endDate: date
                                        })
                                    }
                                })
                            }}
                />

            </div>
        }
    ]

    useEffect(() => {
        if (isOrgLevelSys && (isRoleOrgAdmin || isRoleManager)) {
            dispatch(getAllOrgAsList()).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (!isSubOrg) {
                    const onSuccess = () => {
                        const orgInfo = res.payload.data?.filter(org => org.orgId === token?.orgId)[0]
                        setSearchQuery({...searchQuery, org: {label: orgInfo.name, value: orgInfo.orgId}})
                    }
                    handleApiResponse(res, onSuccess)
                }
            })
        }
    }, [])
    useEffect(() => {
        if (triggerApi) {
            const searchQueryToSubmit = {
                ...searchQuery,
                ...sortCriteria,
                orgId: searchQuery?.org?.value,
                page: pageNumber,
                pageSize: 10,
            }
            if (searchQuery?.createdDateRange) {
                searchQueryToSubmit.createdDateRange = formatAndComputeAutoDateRange(searchQuery?.createdDateRange?.startDate || "", searchQuery?.createdDateRange?.endDate || "", "yyyy-MM-dd")
            }
            dispatch(searchStrategy(searchQueryToSubmit)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                setTriggerApi(false)
            })
        }
    }, [triggerApi]);
    useEffect(() => {
        if (searchQuery.name != null && triggerApiOnNameChange) {
            if (timer) {
                clearTimeout(timer);
            }
            setTimer(setTimeout(() => {
                setTriggerApi(true);
            }, 1000));
        }
    }, [searchQuery.name]);

    const setPageNumberAndTriggerApi = (page) => {
        setPageNumber(page)
        setTriggerApi(true)
    }
    const handleSort = (column, sortDirection,) => {
        setSortCriteria({
            sortBy: column.sortField,
            sortOrder: sortDirection,
        })
        setTriggerApi(true)
    };
    const handleDeleteStrategy = () => {
        if (strategyToDeleteId) {
            dispatch(deleteStrategy(strategyToDeleteId)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                handleApiResponse(res, () => {
                    setStrategyToDeleteId(null)
                    setTriggerApi(true)
                    setModals({
                        ...modals,
                        showDeleteStrategyConfirmationModal: false
                    })
                })
            })
        }
    };

    return (
        <section className={"strategy_wrapper"}>
            {
                !isSubOrg && <div className="cmn_header">
                    <h2 className="mediumFontSize m-0">Strategy</h2> <UserInfo/>
                </div>
            }


            <div className={`row main_content ${isSubOrg ? " sub-org-members-outer" : " box_design1"}`}>
                <div className={"col-md-12  px-1"}>
                    <div className="d-flex col-12">
                        <div className="reflection_search_wrapper ">
                            <img src={search_icon} alt=""/>
                            <input
                                onFocus={() => {
                                    setTriggerApiOnNameChange(true)
                                }}
                                onBlur={() => {
                                    setTriggerApiOnNameChange(false)
                                }}
                                name={"name"}
                                type={"text"}
                                className={"form-control members-name-filter-input"}
                                placeholder={"Name..."}
                                value={searchQuery.name}
                                onChange={handleSearchQueryChange}
                            />
                        </div>


                        <div className={"reflection_filters ms-auto mx-2"}>
                            <button
                                type={"button"}
                                onClick={() => {
                                    setModals({
                                        ...modals,
                                        showSearchFilterModal: true
                                    })
                                }}>
                                <img src={filter_icon} alt=""/>Filter
                            </button>
                        </div>

                        {
                            (isRoleOrgAdmin || (isRoleManager && isOrgLevelSys)) &&
                            <div className={"members-btn"}>
                                <button
                                    style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                    className={'cmn_background_color  mediumFontSize create-strategy-btn '}
                                    onClick={() => {
                                        setModals({
                                            ...modals,
                                            showCreateStrategyModal: true
                                        })
                                    }}>
                                    Create Strategy
                                </button>
                            </div>
                        }
                    </div>


                </div>

                <div className={"colored_table pe-1 p-0"}>
                    <DataTable
                        className={strategyApi?.data?.totalPages > 1 ? " table_design2 " : " table_design1 "}
                        persistTableHead={true} responsive={true}
                        customStyles={tableCustomStyling}
                        progressPending={strategyApi.loading}
                        columns={columns}
                        data={strategyApi?.data?.data}
                        noDataComponent={<div className={"no-member-text"}>No Strategy Found</div>}
                        progressComponent={<TableSkeletonLoading columns={5} rows={6}/>}
                        onSort={handleSort}
                        sortServer={true}

                    />
                    {
                        !strategyApi?.loading && strategyApi?.data && strategyApi?.data?.totalPages > 1 &&
                        <Pagination totalPages={strategyApi?.data?.totalPages}
                                    pageNumber={pageNumber}
                                    setPageNumber={setPageNumberAndTriggerApi}
                        />
                    }
                </div>

            </div>
            {
                modals.showSearchFilterModal &&
                <SearchFilterModal title={"Strategy Filters"}
                                   show={modals.showSearchFilterModal}
                                   filtersTag={searchFiltersTags}
                                   applyFilter={triggerApi}
                                   setApplyFilter={setTriggerApi}
                                   resetFilters={() => {
                                       setSearchQuery({
                                           name: "",
                                           org: isOrgLevelSys && (isRoleOrgAdmin || isRoleManager) && !isSubOrg ? null : {
                                               label: "Organization...", value: isSubOrg ? subOrgId : token?.orgId
                                           },
                                           aim: "",
                                           goal: "",
                                           createdDateRange: null
                                       });
                                   }}
                                   handleClose={() => {
                                       setModals({
                                           ...modals,
                                           showSearchFilterModal: false
                                       })
                                   }}
                                   setShow={(value) => {
                                       setModals({
                                           ...modals,
                                           showSearchFilterModal: value
                                       })
                                   }}

                />
            }

            {
                modals.showViewStrategyModal &&
                <ViewStrategyModal
                    strategyData={strategyToView}
                    show={modals.showViewStrategyModal}
                    setShow={(value) => {
                        setModals({
                            ...modals,
                            showViewStrategyModal: value
                        })
                    }}/>
            }
            {
                modals.showCreateStrategyModal &&
                <CreateStrategyModal
                    isSubOrg={isSubOrg}
                    subOrgId={subOrgId}
                    triggerSearchStrategy={setPageNumberAndTriggerApi}
                    show={modals.showCreateStrategyModal}
                    setShow={(value) => {
                        setModals({
                            ...modals,
                            showCreateStrategyModal: value
                        })
                    }}/>
            }
            {
                modals.showDeleteStrategyConfirmationModal &&
                <ConfirmationModel title={"Delete Strategy"}
                                   btnText={"Delete"}
                                   action={handleDeleteStrategy}
                                   show={modals.showDeleteStrategyConfirmationModal}
                                   bodyMessage={"Are you sure you want to delete this strategy? All the reflections will be unassigned once it is deleted. Are you sure you wish to proceed?"}
                                   setShow={(value) => {
                                       setModals({
                                           ...modals,
                                           showDeleteStrategyConfirmationModal: value
                                       })
                                   }}
                                   isLoading={deleteStrategyApi?.loading}
                />
            }
        </section>

    )
}
export default Strategy