import "../attempts/Attempts.css"
import UserInfo from "../layout/UserInfo";
import React, {useEffect, useMemo, useState} from "react";
import filter_icon from "../../images/filter_icon.svg";
import {SearchFilterModal} from "../commons/SearchFilterModal";
import { hasPermission, Permissions, roleHasAccess, Roles} from "../../helpers/accessControl";
import Select from "react-select";
import {
    applyValidDateRange,
    createOptionListForSelectTag,
    formatAndComputeAutoDateRange,
    formatDate, getAuthorityLevelFor,
    getDataFromLocalStorage, getRolesWithAuthorityLevel,
    isUnauthorized,
    tableCustomStyling,
    TableSkeletonLoading,
    trim
} from "../../helpers/utils";
import DatePicker from "react-datepicker";
import jwtDecode from "jwt-decode";
import {getAllOrgAsList} from "../../reduxStore/orgSlices/orgSlices";
import {
    getAllUsersWithoutPagination
} from "../../reduxStore/usersSlices/userSlice";
import {useDispatch, useSelector} from "react-redux";
import {NavLink, useNavigate} from "react-router-dom";
import {getAllSurveyAttempts, getAllSurveysWithoutPagination} from "../../reduxStore/surveySlices/surveySlices";
import {Marks} from "../../helpers/constants";
import DataTable from "react-data-table-component";
import Pagination from "../commons/Pagination";

const Attempts = ({isSubOrg, subOrgId}) => {

    const token = jwtDecode(getDataFromLocalStorage("token"))
    const isOrgLevelSystem = token?.orgLevel === "SYSTEM"
    const isSysAdminOrSysManager = token?.orgLevel === "SYSTEM" && (token?.roles?.includes(Roles.MANAGER) || token?.roles?.includes(Roles.ORG_ADMIN))

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [pageNumber, setPageNumber] = useState(1)
    const [sortBy, setSortBy] = useState("createdAt");
    const [sortOrder, setSortOrder] = useState("desc");
    const [applySearch, setApplySearch] = useState(true)
    const [showSearchFilterModal, setShowSearchFilterModal] = useState(false)
    const [searchQuery, setSearchQuery] = useState({
        org: {
            label: "Organization...", value: isSubOrg ? subOrgId : token?.orgId
        },
        surveyorId: null,
        surveyeeId: null,
        createdDateRange: null,
        survey: null,
        surveyAttemptStatus: ["COMPLETED"],
    })


    const getAllOrgAsListData = useSelector(state => state.org.getAllOrgAsListReducer);
    const getAllUsersWithoutPaginationData = useSelector(state => state.users.getAllUsersWithoutPaginationReducer);
    const getAllSurveysWithoutPaginationData = useSelector(state => state.survey.getAllSurveysWithoutPaginationDataReducer);
    const getAllSurveyAttemptsData = useSelector(state => state.survey.getAllSurveyAttemptsReducer);


    const handleSort = (column, sortDirection) => {
        setSortBy(column.sortField)
        setSortOrder(sortDirection)
        setApplySearch(true)
    };

    const setPageNumberAndApplySearch = (page) => {
        setPageNumber(page)
        setApplySearch(true)
    }

    useEffect(() => {

        if (applySearch) {
            const searchQueryToSubmit = {
                ...searchQuery,
                orgId: searchQuery?.org?.value,
                surveyorIds: searchQuery?.surveyorId?.map(surveyor => surveyor.value) || [],
                surveyeeIds: searchQuery?.surveyeeId?.map(surveyee => surveyee.value) || [],
                surveyIds: searchQuery?.survey?.map(c => c.value) || [],
                attemptResult: searchQuery?.attemptResult?.map(c => c.value) || [],
                sortOrder: sortOrder,
                sortBy: sortBy,
                page: pageNumber,
                pageSize: 10,
            }
            if (searchQuery?.createdDateRange) {
                searchQueryToSubmit.createdDateRange = formatAndComputeAutoDateRange(searchQuery?.createdDateRange?.startDate || "", searchQuery?.createdDateRange?.endDate || "", "yyyy-MM-dd")
            }
            dispatch(getAllSurveyAttempts(searchQueryToSubmit)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                setApplySearch(false)
            });
        }

    }, [applySearch])

    useEffect(() => {
        if (isSysAdminOrSysManager && !isSubOrg) {
            dispatch(getAllOrgAsList()).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                } else if (res.meta.requestStatus === "fulfilled") {
                    const orgInfo = res.payload.data?.filter(org => org.orgId === token?.orgId)[0]
                    setSearchQuery({...searchQuery, org: {label: orgInfo.name, value: orgInfo.orgId}})
                }
            })
        }
    }, [])

    useEffect(() => {

        if (hasPermission([Permissions.USER_READ], true) && ((isSysAdminOrSysManager && isSubOrg) || (isSysAdminOrSysManager && !isSubOrg && getAllOrgAsListData?.data !== undefined) || (!isSysAdminOrSysManager))) {
            // If Org is Sys it should show all the roles if not and role is admin or manager it should show all except sys else proceed with user role
            const roleToGetAuthorityLevelFor = isSysAdminOrSysManager ? Roles.SYS_ADMIN : token?.roles[0]?.includes(Roles.ORG_ADMIN) || token?.roles[0]?.includes(Roles.MANAGER) ? Roles.ORG_ADMIN : token?.roles[0]
            const rolesToGet = getRolesWithAuthorityLevel("<=", getAuthorityLevelFor(roleToGetAuthorityLevelFor), [Roles.ANONYMOUS_USER, Roles.ANONYMOUS_USER])
            dispatch(getAllUsersWithoutPagination({
                roles: rolesToGet,
                ...(isOrgLevelSystem || isSubOrg ? {orgId: searchQuery?.org?.value} : {})
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }
    }, [searchQuery.org]);

    useEffect(() => {
        if (hasPermission([Permissions.REFLECTION_READ], true) && ((isSysAdminOrSysManager && isSubOrg) || (isSysAdminOrSysManager && !isSubOrg && getAllOrgAsListData?.data !== undefined) || (!isSysAdminOrSysManager))) {
            dispatch(getAllSurveysWithoutPagination({
                ...(isOrgLevelSystem || isSubOrg ? {orgLevelId: searchQuery?.org?.value} : {})
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }
    }, [searchQuery.org]);

    useEffect(() => {
        setSearchQuery({
            ...searchQuery,
            surveyorId: null,
            surveyeeId: null,
            survey: null,
        })
    }, [searchQuery.org]);

    let searchFiltersTags = useMemo(() => [
        {
            shouldShow: isSysAdminOrSysManager && !isSubOrg,
            tagView: <Select
                className="members-filter-role react-select-container" classNamePrefix="react-select"
                isDisabled={getAllUsersWithoutPaginationData?.loading || getAllOrgAsListData?.loading}
                value={searchQuery?.org}
                options={createOptionListForSelectTag(getAllOrgAsListData?.data, "name", "orgId")}
                onChange={(selectedOrg) => {
                    if (searchQuery?.org?.value !== selectedOrg?.value) {
                        setSearchQuery({...searchQuery, org: selectedOrg})
                    }
                }}
                isClearable={true}
                placeholder={"Organization..."}/>
        },
        {
            shouldShow: hasPermission([Permissions.USER_READ], true) && !roleHasAccess([Roles.MENTOR]),
            tagView: <Select
                className="members-filter-role react-select-container"
                classNamePrefix="react-select"
                isDisabled={getAllUsersWithoutPaginationData?.loading || getAllOrgAsListData?.loading}
                isMulti
                isClearable={true}
                value={searchQuery.surveyorId}
                options={createOptionListForSelectTag(getAllUsersWithoutPaginationData?.data?.filter(user => user?.roleObjects?.[0]?.name !== Roles.MENTEE), "firstName", "uid")}
                onChange={(selectedValue) => {
                    setSearchQuery({
                        ...searchQuery,
                        surveyorId: selectedValue?.length === 0 ? null : selectedValue
                    })
                }}
                placeholder={"Surveyor..."}/>

        },
        {
            shouldShow: hasPermission([Permissions.USER_READ], true),
            tagView: <Select
                className="members-filter-role react-select-container"
                classNamePrefix="react-select"
                isDisabled={getAllUsersWithoutPaginationData?.loading || getAllOrgAsListData?.loading}
                isMulti
                isClearable={true}
                value={searchQuery?.surveyeeId}
                options={createOptionListForSelectTag(getAllUsersWithoutPaginationData?.data, "firstName", "uid")}
                onChange={(selectedValue) => {
                    setSearchQuery({
                        ...searchQuery,
                        surveyeeId: selectedValue?.length === 0 ? null : selectedValue
                    })
                }}
                placeholder={"Surveyee..."}/>

        },
        {
            shouldShow: hasPermission([Permissions.REFLECTION_READ], true),
            tagView: <Select
                className="members-filter-role react-select-container"
                classNamePrefix="react-select"
                isDisabled={getAllSurveysWithoutPaginationData?.loading || getAllOrgAsListData?.loading}
                isMulti
                isClearable={true}
                value={searchQuery?.survey}
                options={createOptionListForSelectTag(getAllSurveysWithoutPaginationData?.data, "name", "surveyId")}
                onChange={(selectedValue) => {
                    setSearchQuery({
                        ...searchQuery,
                        survey: selectedValue?.length === 0 ? null : selectedValue
                    })
                }}
                placeholder={"Reflection..."}/>

        },
        {
            tagView: <Select
                className="members-filter-role react-select-container"
                classNamePrefix="react-select"
                isMulti
                isClearable={true}
                value={searchQuery.attemptResult}
                options={createOptionListForSelectTag(Object.keys(Marks)?.map(c => {
                    return {name: Marks[c], value: c}
                }), "name", "value")}
                onChange={(selectedValue) => {
                    setSearchQuery({
                        ...searchQuery,
                        attemptResult: selectedValue?.length === 0 ? null : selectedValue
                    })
                }}
                placeholder={"Attempt Result..."}/>

        },
        {
            tagView: <div className={"members-date-picker-outer "}>
                <DatePicker placeholderText="From: dd-mm-yyyy" dateFormat={"dd-MM-yyyy"}
                            selected={searchQuery?.createdDateRange?.startDate || ""}
                            className={"search-by-survey-name survey-name-search member_search "}
                            maxDate={searchQuery?.createdDateRange?.endDate || new Date()}
                            onChange={(date) => {
                                setSearchQuery({
                                    ...searchQuery,
                                    createdDateRange: applyValidDateRange({
                                        startDate: date,
                                        endDate: searchQuery?.createdDateRange?.endDate || null
                                    })
                                })
                            }}
                />
                <DatePicker placeholderText="To: dd-mm-yyyy" dateFormat={"dd-MM-yyyy"}
                            selected={searchQuery?.createdDateRange?.endDate || ""}
                            minDate={searchQuery?.createdDateRange?.startDate || ""}
                            className={"search-by-survey-name survey-name-search user-join-toDate member_search "}
                            maxDate={new Date()}
                            onChange={(date) => {
                                setSearchQuery({
                                    ...searchQuery,
                                    createdDateRange: applyValidDateRange({
                                        startDate: searchQuery?.createdDateRange?.startDate || null,
                                        endDate: date
                                    })
                                })
                            }}
                />

            </div>
        }

    ], [searchQuery, getAllOrgAsListData, getAllUsersWithoutPaginationData, getAllSurveysWithoutPaginationData])

    const columns = [
        {

            name: <div className={"d-flex"}>
                <span
                    className={"ms-2 bulk_delete_Main_header"}> {"Reflection name " + (isSysAdminOrSysManager && !isSubOrg ? "( Organization )" : "")} </span>
            </div>,
            selector: row => row.surveyName,
            sortable: true,
            sortField: 'surveyName',
            cell: (row) =>
                (
                    <div className={"d-flex"}>
                        <div className={"display_profile_data wrap-text ms-2"}>
                            <NavLink
                                title={row.surveyName + (isSysAdminOrSysManager && !isSubOrg ? " ( " + row.orgInfo.orgName + " )" : "")}
                                to={`/members/reflectionResult/${row.surveyId}/${row.id}`}>
                                {trim(row.surveyName + (isSysAdminOrSysManager && !isSubOrg ? " ( " + row.orgInfo.orgName + " )" : ""), 40)}
                            </NavLink>

                        </div>
                    </div>

                ),
            style: {
                fontFamily: 'General Sans Medium',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            },
            width: getAllSurveyAttemptsData?.loading ? "" : "30%"
        },

        {
            name: "Surveyor",
            selector: row => (row?.surveyorInfo === null || row?.surveyorInfo === undefined) ? "N/A" : row?.surveyorInfo?.firstName + " " + row?.surveyorInfo?.lastName,
        },
        {
            name: "Surveyee",
            selector: row => (row?.surveyeeInfo === null || row?.surveyeeInfo === undefined) ? "N/A" : row?.surveyeeInfo?.firstName + " " + row?.surveyeeInfo?.lastName,
        },
        {
            name: "Attempt Date",
            sortField: 'createdAt',
            sortable: true,
            cell: row =>
                <div>{(row.createdAt === "" || row.createdAt === undefined) ? "----" : (formatDate(row.createdAt))}</div>,
        },
        {
            name: "Result",
            sortField: 'surveyAttemptResult.overallRiskLevel',
            sortable: true,
            selector: row => Marks[row?.surveyAttemptResult?.overallRiskLevel],
            cell: row => (
                row?.surveyAttemptResult?.overallRiskLevel === undefined ?
                    <span className={"text-center"}>{"N/A"}</span>
                    : <>
                        {
                            row?.surveyAttemptResult?.overallRiskLevel === "HIGH_RISK" &&
                            <div className={"show_status"}>
                            <span
                                className={"survey-risk-status bg-light-red"}>{Marks[row?.surveyAttemptResult?.overallRiskLevel]}</span>
                            </div>
                        }
                        {
                            row?.surveyAttemptResult?.overallRiskLevel === "LOW_RISK" &&
                            <div className={"show_status"}>
                            <span
                                className={"survey-risk-status bg-light-orange"}>{Marks[row?.surveyAttemptResult?.overallRiskLevel]}</span>
                            </div>
                        }
                        {
                            row?.surveyAttemptResult?.overallRiskLevel === "GOOD" &&
                            <div className={"show_status"}>
                            <span
                                className={"survey-risk-status bg-light-green"}>{Marks[row?.surveyAttemptResult?.overallRiskLevel]}</span>
                            </div>
                        }
                    </>
            )
        },
    ]

    return (
        <>
            <section>
                {
                    !isSubOrg && <div className="cmn_header">
                        <h2 className="mediumFontSize m-0">Attempts</h2> <UserInfo/>
                    </div>
                }

                <div className={"row main_content box_design1"}>
                    <div className={"col-md-12  px-1"}>
                        <div className="d-flex col-12">
                            <div className={"reflection_filters ms-auto"}>
                                <button type={"button"} onClick={() => {
                                    setShowSearchFilterModal(true)
                                }
                                }><img src={filter_icon} alt=""/>Filter
                                </button>
                            </div>
                        </div>


                    </div>

                    <div className={"colored_table pe-1 p-0"}>
                        <DataTable
                            className={getAllSurveyAttemptsData?.data?.totalPages > 1 ? " table_design2 " : " table_design1 "}
                            persistTableHead={true} responsive={true}
                            customStyles={tableCustomStyling}
                            progressPending={getAllSurveyAttemptsData?.loading}
                            columns={columns}
                            data={getAllSurveyAttemptsData?.data?.data}
                            noDataComponent={<div className={"no-member-text"}>No Data Found</div>}
                            progressComponent={<TableSkeletonLoading columns={5} rows={5}/>}
                            onSort={handleSort}
                            sortServer={true}

                        />

                        {
                            !getAllSurveyAttemptsData?.loading && getAllSurveyAttemptsData?.data && getAllSurveyAttemptsData?.data?.totalPages > 1 &&
                            <Pagination className={""} totalPages={getAllSurveyAttemptsData?.data?.totalPages}
                                        pageNumber={pageNumber}
                                        setPageNumber={setPageNumberAndApplySearch}/>
                        }

                    </div>

                </div>


            </section>
            {
                showSearchFilterModal &&
                <SearchFilterModal title={"Attempts Filters"}
                                   show={showSearchFilterModal}
                                   filtersTag={searchFiltersTags}
                                   applyFilter={applySearch}
                                   setApplyFilter={setApplySearch}
                                   resetFilters={() => {
                                       setSearchQuery({
                                           org: isSysAdminOrSysManager && !isSubOrg ? null : {
                                               label: "Organization...", value: isSubOrg ? subOrgId : token?.orgId
                                           },
                                           surveyorId: null,
                                           surveyeeId: null,
                                           createdDateRange: null,
                                           survey: null,
                                           attemptResult: null
                                       });
                                   }}
                                   handleClose={() => {
                                       setShowSearchFilterModal(false);
                                   }}
                                   setShow={setShowSearchFilterModal}

                />
            }
        </>
    );
}
export default Attempts