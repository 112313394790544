import {useLocation, useNavigate} from "react-router-dom";
import "./Organizations.css"
import {hasPermission, Permissions, roleHasAccess, Roles} from "../../../helpers/accessControl";
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {EnableOrDisableOrg, getAllOrg} from "../../../reduxStore/orgSlices/orgSlices";
import {
    applyValidDateRange,
    formatAndComputeAutoDateRange,
    formatDate,
    isUnauthorized,
    tableCustomStyling,
    TableSkeletonLoading,
    trim, toString, getDataFromLocalStorage
} from "../../../helpers/utils";
import DataTable from "react-data-table-component";
import Pagination from "../../commons/Pagination";
import NoDataFound from "../../../images/NoDataFound.png"
import DatePicker from "react-datepicker";
import {setShowCreateSubOrganizationBtn} from "../../../reduxStore/globalKeysSlice/globalKeysSlice";
import ConfirmationModel from "../../commons/ConfirmationModel";
import Select from "react-select";
import jwtDecode from "jwt-decode";
import search_icon from "../../../images/search_icon.svg";
import filter_icon from "../../../images/filter_icon.svg";
import {SearchFilterModal} from "../../commons/SearchFilterModal";
import UserInfo from "../../layout/UserInfo";

const Organizations = () => {
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const [isCreateSubOrg, setIsCreateSubOrg] = useState(pathname === "/subOrganizations")
    const org = useSelector(state => state.org)
    const dispatch = useDispatch();
    const [sortBy, setSortBy] = useState("createdAt");
    const [sortOrder, setSortOrder] = useState("desc");
    const [pageNumber, setPageNumber] = useState(1);
    const [applySearch, setApplySearch] = useState(false)
    const [applyFilter, setApplyFilter] = useState(false)
    const [timer, setTimer] = useState(null)
    const [showDisableOrgConfirmationBox, setShowDisableOrgConfirmationBox] = useState(false)
    const [showEnableOrgConfirmationBox, setShowEnableOrgConfirmationBox] = useState(false)
    const [selectedOrgToEnableDisable, setSelectedOrgToEnableDisable] = useState("")
    const showCreateSubOrganizationBtn = useSelector(state => state.globalKeys.showCreateSubOrganizationBtn)

    const [showSearchFilterModal, setShowSearchFilterModal] = useState(false)

    const [searchQuery, setSearchQuery] = useState({
        orgName: null,
        createdDateRange: null,
        enable:""

    })

    useEffect( ()=>{
        org.getAllOrgDataReducer?.data?.totalItems &&  pageNumber* org.getAllOrgDataReducer?.data?.pageSize > org.getAllOrgDataReducer?.data?.totalItems &&   setPageNumber(Math.ceil(org.getAllOrgDataReducer?.data?.totalItems/org.getAllOrgDataReducer?.data?.pageSize))
    },[org.getAllOrgDataReducer?.data])

    const callApiFunction = () => {
            const searchQueryToBeSubmitted = {...searchQuery ,
                ...(!isCreateSubOrg ? {enable: searchQuery?.enable?.value} : {}),
            }
            if (searchQuery?.createdDateRange) {
                searchQueryToBeSubmitted.createdDateRange = formatAndComputeAutoDateRange(searchQuery?.createdDateRange?.startDate || "", searchQuery?.createdDateRange?.endDate || "", "yyyy-MM-dd")
            }

            dispatch(getAllOrg({
                ...searchQueryToBeSubmitted,
                sortOrder: sortOrder,
                sortBy: sortBy,
                page: pageNumber,
                pageSize: 10
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                } else if (res.meta.requestStatus === "fulfilled" && showCreateSubOrganizationBtn === null) {
                    dispatch(setShowCreateSubOrganizationBtn(res.payload?.data?.items?.length === 0 ? true : false))
                }
                setApplyFilter(false)
            })
    };
    useEffect(() => {
        if(applyFilter){
            callApiFunction()
        }
    }, [searchQuery,applyFilter])

    useEffect(() => {

        if(searchQuery.orgName !== null){
            if (timer) {
                clearTimeout(timer)
            }
            setTimer(setTimeout(() => {
                callApiFunction()
            }, 1000))
        }
    }, [searchQuery.orgName])


    useEffect(() => {
        callApiFunction();
        setApplySearch(true)
        return () => {
            dispatch(setShowCreateSubOrganizationBtn(null))
        }
    }, [sortBy, sortOrder, pageNumber])

    const handleSort = (column, sortDirection,) => {
        setSortBy(column.sortField)
        setSortOrder(sortDirection)
    };
    const conditionalRowStyle = [
        {
            when: row => row.enable === false,
            style: {
                backgroundColor: '#d3cfcf36',
            },
        },
    ]

    //TODO need to work on grid spaces

    const columns = [
        {
            name: "Org Name",
            selector: row => row.name,
            sortable: true,
            sortField: 'name',
            cell: (row) =>
                (
                    <>
                        <div className={"display_profile_data wrap-text cursor-pointer "} onClick={() => {
                            isCreateSubOrg ? navigate(`/subOrganizations/${row.orgId}`) : navigate(`/organizations/${row.orgId}`)
                        }}>
                            <span title={row.name} className={"org-name-heading wrap-text font-wt-600"}>{trim(row.name, 30)}</span>
                        </div>
                    </>

                ),
            style: {
                fontFamily: 'General Sans Medium',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            }
        },
        {
            name: "Owner Name",
            selector: row => row.ownerDto.firstName,
            sortField: 'ownerDto.firstName',
            sortable: true,
            cell: (row) =>
                (
                    <>
                        <div className={"display_profile_data wrap-text"}>
                            <span title={row.ownerDto.firstName + " " + row.ownerDto.lastName}
                                  className={"org-name-heading wrap-text"}>{trim(row.ownerDto.firstName + " " + row.ownerDto.lastName, 30)}</span>
                        </div>
                    </>

                ),
            style: {opacity: ".7",}
        },


        {
            name: "Owner Email",
            selector: row => row.ownerDto.email,
            sortField: 'ownerDto.email',
            sortable: true,
            cell: (row) =>
                (
                    <>
                        <div className={"display_profile_data wrap-text"}>
                            <span title={row.ownerDto.email}
                                  className={"org-name-heading wrap-text"}>{row.ownerDto.email}</span>
                        </div>
                    </>

                ),
            style: {opacity: ".7",}
        },
        {name: "Address", selector: row => row.address, sortField: 'address', sortable: true, style: {opacity: ".7",}},
        {
            name: "Joined On",
            selector: row => row.createdAt,
            sortable: true,
            sortField: 'createdAt',
            cell: row => <>{formatDate(row.createdAt)}</>,
            width: org?.getAllOrgDataReducer?.loading ? "" : "140px",
            style: {opacity: ".7",}
        },
       {
            name: "Action",
            cell: row => (
                row.enable ?
                    <div className={"  cursor-pointer"} onClick={() => {
                        setSelectedOrgToEnableDisable(row.orgId)
                        setShowDisableOrgConfirmationBox(true)
                    }}>
                        <span className={" org-status mx-0  bg-light-red " }>Disable</span>
                    </div> :
                    <div className={"  cursor-pointer"} onClick={() => {
                        setSelectedOrgToEnableDisable(row.orgId)
                        setShowEnableOrgConfirmationBox(true)
                    }}>
                        <span className={" org-status mx-0 bg-light-green" }>Enable</span>

                    </div>
            ),
            width: org?.getAllOrgDataReducer?.loading ? "" : "100px"
        }

    ].filter(Boolean)

    const EnableDisableOrg = (status, callback) => {
        dispatch(EnableOrDisableOrg({
            id: selectedOrgToEnableDisable,
            status: status
        })).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            } else if (res.meta.requestStatus === "fulfilled") {
                callApiFunction();
                callback();
            }
        })
    }

    let searchFiltersTags=useMemo(()=>[
        {shouldShow:true,
            tagView: <div className={"members-date-picker-outer"}>
                <DatePicker placeholderText="From: dd-mm-yyyy"
                            dateFormat={"dd-MM-yyyy"}
                            selected={searchQuery?.createdDateRange?.startDate || ""}
                            className={"search-by-survey-name survey-name-search  member_search"}
                            maxDate={searchQuery?.createdDateRange?.endDate || new Date()}
                            onChange={(date) => {
                                setSearchQuery({
                                    ...searchQuery,
                                    createdDateRange: applyValidDateRange({
                                        startDate: date,
                                        endDate: searchQuery?.createdDateRange?.endDate || null
                                    })
                                })
                            }}
                />
                <DatePicker placeholderText="To: dd-mm-yyyy"
                            dateFormat={"dd-MM-yyyy"}
                            selected={searchQuery?.createdDateRange?.endDate || ""}
                            minDate={searchQuery?.createdDateRange?.startDate || ""}
                            className={"search-by-survey-name survey-name-search user-join-toDate  member_search "}
                            maxDate={new Date()}
                            onChange={(date) => {
                                setSearchQuery({
                                    ...searchQuery, createdDateRange: applyValidDateRange({
                                        startDate: searchQuery?.createdDateRange?.startDate || null,
                                        endDate: date
                                    })
                                })
                            }}/>
            </div>

        },
        {
            shouldShow: !isCreateSubOrg,
            tagView:<Select
                className="org-active-inactive-filter react-select-container"
                classNamePrefix="react-select"
                value={searchQuery?.enable}
                options={[{value:true,label:"Active"},{value:false,label:"In Active"}]}
                onChange={(selectedOrg) => {
                    setSearchQuery({...searchQuery, enable: selectedOrg})
                }}
                isClearable={true}
                placeholder={"Active/In Active..."}/>
        }
    ],[searchQuery,isCreateSubOrg])



    return (
            (
                <>
                    <section>
                        <div className="cmn_header">
                            <h2 className="mediumFontSize  org-heading m-0">{isCreateSubOrg ? showCreateSubOrganizationBtn ? "" : "Sub-Organizations" : "Organizations"}</h2> <UserInfo/>
                        </div>
                        <div className="row main_content box_design1">
                            <div className="col-md-12 p-0">

                                {
                                    isCreateSubOrg && showCreateSubOrganizationBtn ?

                                        <>
                                            <div className={"no-data-img-outer text-center mt-3 mb-3"}>
                                                <img className={"no-data-img "} src={NoDataFound}/>
                                            </div>
                                            {
                                                hasPermission([Permissions.ORG_CREATE], true) &&
                                                <div className={"text-center mt-1"}>
                                                    <CreateSubOrgButton/></div>
                                            }

                                        </>


                                        : <>

                                            <div className="d-flex col-12 gap-">
                                                <div className="reflection_search_wrapper ">
                                                    <img src={search_icon} alt=""/>
                                                    <input type={"text"}
                                                           className={"form-control members-name-filter-input"}
                                                           placeholder={"Name..."} value={searchQuery.orgName}
                                                           onChange={(e) => {
                                                               setSearchQuery({...searchQuery, orgName: e.target.value})
                                                           }}/>
                                                </div>


                                                <div className={"reflection_filters ms-auto"}>
                                                    {/*<button type={"button"}><img src={sort_icon} alt=""/>Sort</button>*/}
                                                    <button type={"button"} onClick={()=>{
                                                        setShowSearchFilterModal(true)
                                                    }
                                                    }><img src={filter_icon} alt=""/>Filter</button>
                                                </div>

                                                <div className={"ms-2  text-end search-box-add-btn-outer"}>

                                                    {hasPermission([Permissions.ORG_CREATE], true) && isCreateSubOrg && !showCreateSubOrganizationBtn &&
                                                        <CreateSubOrgButton />
                                                    }

                                                    {roleHasAccess([Roles.ORG_ADMIN,Roles.MANAGER]) && jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === "SYSTEM" && hasPermission([Permissions.ORG_CREATE], true) && !isCreateSubOrg &&
                                                        <CreateOrgButton/>
                                                    }
                                                </div>
                                            </div>

                                            <DataTable
                                                className={"mt-3  " + (org.getAllOrgDataReducer?.data?.totalPage > 1 ? "table_design2" : "table_design1")}
                                                persistTableHead={true}
                                                responsive={true}
                                                customStyles={tableCustomStyling}
                                                progressPending={org?.getAllOrgDataReducer?.loading}
                                                columns={columns} data={org?.getAllOrgDataReducer?.data?.items}
                                                onSort={handleSort} sortServer={true}
                                                conditionalRowStyles={conditionalRowStyle}
                                                noDataComponent={<div className={"no-member-text"}>No Organization
                                                    Found</div>}
                                                progressComponent={<div className={"org-table-loading w-100"}>
                                                    <TableSkeletonLoading columns={isCreateSubOrg ? 4 : 5} rows={5}/>
                                                </div>}/>
                                            {!org.getAllOrgDataReducer?.loading && org.getAllOrgDataReducer?.data && org.getAllOrgDataReducer?.data?.totalPage > 1 &&
                                                <Pagination totalPages={org.getAllOrgDataReducer?.data?.totalPage}
                                                            pageNumber={pageNumber}
                                                            setPageNumber={setPageNumber}/>}
                                        </>
                                }


                            </div>

                        </div>
                        {
                            showEnableOrgConfirmationBox &&
                            <ConfirmationModel title={"Enable Organization"}
                                               btnText={"Enable"}
                                               action={() => {
                                                   EnableDisableOrg("true", () => {
                                                       setShowEnableOrgConfirmationBox(false)
                                                   })
                                               }}
                                               show={showEnableOrgConfirmationBox}
                                               bodyMessage={"Are you sure you want to enable this organization? This action will restore full access and activities for the organization's members."}
                                               setShow={setShowEnableOrgConfirmationBox}
                                               isLoading={org?.enableOrDisableOrgReducer?.loading}
                                               showUserAcknowledgementCheckBox={true}
                                               userAcknowledgementCheckBoxText={"Yes, I understand"}
                            />
                        }
                        {
                            showDisableOrgConfirmationBox &&
                            <ConfirmationModel title={"Disable Organization"}
                                               btnText={"Disable"}
                                               action={() => {
                                                   EnableDisableOrg("false", () => {
                                                       setShowDisableOrgConfirmationBox(false)
                                                   })
                                               }}
                                               show={showDisableOrgConfirmationBox}
                                               bodyMessage={"Are you sure you want to disable this organization? This action will disable all associated sub-organizations and suspend all activities and access for the organization's members."}
                                               setShow={setShowDisableOrgConfirmationBox}
                                               isLoading={org?.enableOrDisableOrgReducer?.loading}
                                               showUserAcknowledgementCheckBox={true}
                                               userAcknowledgementCheckBoxText={"Yes, I understand"}
                            />
                        }

                        {
                            showSearchFilterModal &&
                            <SearchFilterModal title={"Organization Filters"}
                                               show={showSearchFilterModal}
                                               filtersTag={searchFiltersTags}
                                               applyFilter={applyFilter}
                                               setApplyFilter={setApplyFilter}
                                               resetFilters={()=>setSearchQuery({
                                                   orgName: "",
                                                   createdDateRange: null,
                                                   enable:""

                                               })}
                                               handleClose={()=>{
                                                   setShowSearchFilterModal(false);}}
                                               setShow={setShowSearchFilterModal}

                            />
                        }



                    </section>


                </>
            ))
}

const CreateSubOrgButton = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className={"create-sub-org-btn-outer"}>
                <button style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""} } onClick={() => {
                    navigate("/subOrganizations/create")
                }}
                        className={"cmn_background_color survey_addnew_btn mediumFontSize create-sub-org-btn"}>Create
                    Sub-Organization
                </button>
            </div>
        </>
    );
}
const CreateOrgButton = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className={"create-sub-org-btn-outer"}>
                <button style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""} } onClick={() => {
                    navigate("/organizations/create")
                }}
                        className={"cmn_background_color survey_addnew_btn mediumFontSize create-sub-org-btn"}>Create
                    Organization
                </button>
            </div>
        </>
    );
}
export default Organizations;
