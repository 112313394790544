import {IoIosClose} from "react-icons/io";
import Modal from 'react-bootstrap/Modal';
import React from "react";


const ViewStrategyModal = ({strategyData, show, setShow}) => {
    const handleClose = () => setShow(false);
    return (
        <>
            <Modal size={"xl"} modal-lg  className='mt-4 ' show={show} centered>
                <Modal.Header className={"d-flex"}>
                    <Modal.Title className='add_member_heading mediumFontSize'>{strategyData?.name}</Modal.Title>
                    <IoIosClose className={"close_btn"} onClick={handleClose} size={24}/>
                </Modal.Header>

                <form>

                    <Modal.Body className="modal_outer modalWrapperBox">
                        <div className="reset_form">
                            <div className="form-group mt-1">
                                <label className='userInfo_label mediumFontSize mb-2'
                                       form="inputAddress">Organization</label>
                                <h3>{strategyData?.orgInfo?.orgName} </h3>
                            </div>
                            <div className="form-group mt-1">
                                <label className='userInfo_label mediumFontSize mb-2'
                                       form="inputAddress">Assigned Reflections</label>
                                <h3>{strategyData?.surveysInfo?.map(cur=>cur?.name).join(",") || "Currently strategy is not assigned to any reflection"} </h3>
                            </div>
                            <div className="form-group mt-1">
                                <label className='userInfo_label mediumFontSize mb-2'
                                       form="inputAddress">Description</label>
                                <pre className={"view-description"}>{strategyData?.description}</pre>
                            </div>
                            <div className={"form-group mt-1"}>

                                <label className={"userInfo_label mb-2"}
                                       htmlFor="exampleFormControlSelect1"> Goals</label>
                                <div className={"survey-tag-outer w-100 survey-tag-outer1"}>
                                    {
                                        strategyData?.goals?.map((goal, index) => {
                                            return (
                                                <div key={index} className={"survey-tags"}>
                                                    <div className={"tag-text"} title={goal}>{goal}</div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>

                            </div>

                        </div>


                    </Modal.Body>
                </form>
                <Modal.Footer className="modal_footer">
                    <div className={"add-member-modal-btns"}>
                        <button
                            className="modal_cancel_btn cmn_modal_btn mediumFontSize"
                            onClick={handleClose}>Close
                        </button>
                    </div>

                </Modal.Footer>

            </Modal>
        </>
    );
}
export default ViewStrategyModal



