import './Header.css'
import PulseIcon from '../../images/PulseIcon.svg'
import {
    getDataFromLocalStorage,
     getRandomColor,
    isUnauthorized, showToast
} from "../../helpers/utils";

import React, {useEffect, useState} from "react";
import "../layout/AddReflectionWindow.css"
import {useDispatch, useSelector} from "react-redux";
import {setLoggedInUserName} from "../../reduxStore/globalKeysSlice/globalKeysSlice";
import {GiHamburgerMenu} from "react-icons/gi";
import {ImCross} from "react-icons/im";
import {toggleSidebar} from "../../reduxStore/globalKeysSlice/globalKeysSlice";
import {useNavigate} from "react-router-dom";
import jwtDecode from "jwt-decode";
import {EXPONENT_SURVEY, NoReflectionToAttempt} from "../../helpers/constants";
import {
    getAllSurveysWithoutPaginationForAddReflectionBtn
} from "../../reduxStore/surveySlices/surveySlices";
import {getOrganisationPreferenceByUserId} from "../../reduxStore/orgSlices/orgSlices";


const Header = () => {
    const [showAddReflectionWindow, setShowAddReflectionWindow] = useState(false)
    const [profilePlaceHolderColor,setProfilePlaceHolderColor]=useState(getRandomColor())
    const [showUserProfileWindow, setShowUserProfileWindow] = useState(false)
    const [data, setData] = useState(null)
    const [colorName, setColorName] = useState(null)
    const hideSidebar = useSelector(state => state.globalKeys.toggleSidebar)
    const fullName = useSelector(state => state.globalKeys.loggedInUserFullName)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSurveyAPIDispatchedInHeader, setIsSurveyAPIDispatchedInHeader] = useState(false)
    const [updateLocalStorage, setUpdateLocalStorage] = useState(false)
    const survey = useSelector(state => state.survey.getAllSurveysWithoutPaginationForAddReflectionBtnReducer)
    const [userCurrentPlan, setUserCurrentPlan] = useState(jwtDecode(getDataFromLocalStorage("token")).planName)

    const org = useSelector(state => state.org)


    useEffect(() => {
        dispatch(setLoggedInUserName((getDataFromLocalStorage("loggedInUserInfo")?.lastName === "" || getDataFromLocalStorage("loggedInUserInfo")?.lastName === undefined) ? getDataFromLocalStorage("loggedInUserInfo").firstName : getDataFromLocalStorage("loggedInUserInfo").firstName + " " + getDataFromLocalStorage("loggedInUserInfo").lastName))
        setUpdateLocalStorage(true)

        dispatch(getOrganisationPreferenceByUserId(getDataFromLocalStorage("loggedInUserInfo").orgId))
    }, [])

    const redirectUserToAttemptDefaultReflection = () => {
        dispatch(getAllSurveysWithoutPaginationForAddReflectionBtn()).then(res => {
            setIsSurveyAPIDispatchedInHeader(false)
            if (isUnauthorized(res)) {
                navigate("/")
            } else if (res.meta.requestStatus === "fulfilled") {
                const defaultReflection = res.payload?.data?.filter((survey) => survey.name === EXPONENT_SURVEY)
                if (res.payload?.data.length === 0 || defaultReflection?.length === 0) {
                    showToast(NoReflectionToAttempt,"error")
                } else {
                    navigate(`/members/answerReflection/${jwtDecode(getDataFromLocalStorage("token")).userid}/${defaultReflection[0]?.surveyId}`)
                }

            }
        })

    }

    useEffect( ()=>{
        if(org?.getOrganisationPreferenceByUserIdReducer?.data !== undefined){
            setData(org?.createOrganisationPreferenceReducer?.data?.logo)
            setColorName(org?.createOrganisationPreferenceReducer?.data?.colorName)
        }

    },[org?.getOrganisationPreferenceByUserIdReducer?.data])
    //
    useEffect( ()=>{
        if( data != null){
            localStorage.setItem("logo",data)
        }
        if(colorName != null){
            localStorage.setItem("color",colorName)
        }

    },[data,colorName])

    return (
        <>
            <div className="main-header">
                <div className={"hamburger-icon-outer"}>
                    {
                        hideSidebar ? <ImCross onClick={() => {
                                dispatch(toggleSidebar(false))
                            }} className={"close-hamburger"}/>

                            : <GiHamburgerMenu onClick={() => {
                                dispatch(toggleSidebar(true))
                            }} className={"hamburger-icon"}/>
                    }


                </div>
                <div className="image_display d-flex gap-2 align-items-center">
                { localStorage.getItem("logo") === "" ||localStorage.getItem("logo") === null || localStorage.getItem("logo") === "null"  || localStorage.getItem("logo") === undefined ||localStorage.getItem("logo") === "undefined"?

                    <img className={"logo ms-2"} src={ PulseIcon} alt="nothing"/>
                    :
                    <>
                        <img className={"custom-logo"} src={"data:image/png;base64,"+localStorage.getItem("logo")} alt="nothing"/>
                        <span> <strong>{jwtDecode(getDataFromLocalStorage("token")).orgName}</strong></span>
                    </>
                }
                </div>



                {/*<div className="user_info">*/}
                {/*    {*/}
                {/*        !(roleHasAccess([Roles.SYS_ADMIN])) && hasPermission([Permissions.USER_READ, Permissions.REFLECTION_READ, Permissions.ATTEMPT_REFLECTION_READ, Permissions.ATTEMPT_REFLECTION_CREATE], true) &&*/}
                {/*        <div className={"add-reflection-btn-outer"}>*/}
                {/*            <button*/}
                {/*                disabled={userCurrentPlan === PulsePlans.INDIVIDUAL.value && survey.loading && isSurveyAPIDispatchedInHeader}*/}
                {/*                id={'addReflectionModalButton'} style={{background : localStorage.getItem("color") === null || localStorage.getItem("color") === 'undefined' ? "":localStorage.getItem("color")} } className={"add-reflection-btn cmn_background_color"}*/}
                {/*                onClick={() => {*/}
                {/*                    if (userCurrentPlan === PulsePlans.INDIVIDUAL.value) {*/}
                {/*                        setIsSurveyAPIDispatchedInHeader(true)*/}
                {/*                        redirectUserToAttemptDefaultReflection()*/}
                {/*                    } else {*/}
                {/*                        setShowAddReflectionWindow(!showAddReflectionWindow)*/}
                {/*                    }*/}

                {/*                }}>Add Reflection*/}
                {/*                {*/}
                {/*                    userCurrentPlan === PulsePlans.INDIVIDUAL.value && survey.loading && isSurveyAPIDispatchedInHeader &&*/}
                {/*                    <TailSpinLoader/>*/}
                {/*                }*/}
                {/*            </button>*/}
                {/*        </div>*/}
                {/*    }*/}
                {/*    <div className={"me-4 "}>*/}
                {/*        <ProfilePicPlaceHolder backColor={profilePlaceHolderColor} id={"userProfileWindowButton"} onClick={() => {*/}
                {/*            setShowUserProfileWindow(true)*/}

                {/*        }} className={" cursor-pointer"} letters={getInitials(fullName)}/>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*{*/}
                {/*    showAddReflectionWindow && <AddReflectionWindow setShow={setShowAddReflectionWindow}/>*/}
                {/*}*/}
                {/*{*/}
                {/*    showUserProfileWindow && <UserProfileWindow profilePicBackGroundColor={profilePlaceHolderColor} setShow={setShowUserProfileWindow}/>*/}
                {/*}*/}


            </div>


        </>
    )
}

export default Header;