import {hasPermission, Permissions} from "../../../helpers/accessControl";
import ErrorPage from "../../errorPage/ErrorPage";
import {AccessDenied, AccessDeniedMsg, Survey} from "../../../helpers/constants";
import AddAndUpdateSurvey from "../addAndUpdateSurvey/AddAndUpdateSurvey";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getSurveyById} from "../../../reduxStore/surveySlices/surveySlices";
import {Loader} from "../../layout/Loader";
import {isUnauthorized} from "../../../helpers/utils";

const UpdateSurvey = () => {
    const {surveyId} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [surveyData, setSurveyData] = useState({});
    const survey = useSelector(state => state.survey)
    const [error, setError] = useState({hasError: false, value: "", description: "", detailDesc: ""});
    useEffect(() => {
        if (hasPermission([Permissions.REFLECTION_READ], true)) {
            dispatch(getSurveyById(surveyId)).then((res) => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (res.meta.requestStatus === "fulfilled") {
                    setSurveyData(res.payload.data)
                }
                if (res.meta.requestStatus === "rejected" && (res.payload.errorCode.value === 400 || res.payload.errorCode.value === 404)) {
                    setError({
                        hasError: true,
                        value: res.payload.errorCode.value,
                        description: res.payload.errorCode.description,
                        detailDesc: res.payload.detailDesc
                    })
                }
            })
        }
    }, [])
    return !hasPermission([Permissions.REFLECTION_READ], true) ?
        <ErrorPage value={403} description={AccessDenied} detailDesc={AccessDeniedMsg}/> :
        (survey?.getSurveyByIdDataReducer?.loading || (Object.keys(surveyData).length === 0 && !error.hasError)) ?
            <Loader/> : (error.hasError) ?
                <ErrorPage value={error.value} detailDesc={error.detailDesc} description={error.description}/> :
                (<>
                    <AddAndUpdateSurvey data={surveyData} formType={surveyData.status===Survey.SURVEY_STATUS_DRAFT?"Update":"Edit"}/>
                </>)
}
export default UpdateSurvey