import React from 'react';
import {Bar, Bubble, Doughnut, Line, Pie} from "react-chartjs-2";
import {getDataFromLocalStorage, getDynamicColor, showXContent, showYContent} from "../../helpers/utils";
import {useLocation, useNavigate} from "react-router-dom";
import {hasPermission, Permissions, roleHasAccess, Roles} from "../../helpers/accessControl";
import Dropdown from "react-bootstrap/Dropdown";
import "./CustomReport.css"

const ReportItem = ({
                        report,
                        customReportId,
                        setDeleteReportId,
                        setReportId,
                        setShowConfirmationModal,
                        setCustomReportModal,
                        reportName,
                        setShowAssignCustomReportModal
                    }) => {


    const navigate = useNavigate();
    const {reportType, reportResult, responseVariable, reportDescription} = report;
    const labels = reportResult?.map(result => result?.surveyName);
    const backgroundColors = getDynamicColor(labels);
    const counts = reportResult?.map(result => result?.avgCount);
    const pathname=useLocation().pathname
    const isSubOrg = pathname?.startsWith("/organizations") || pathname?.startsWith("/subOrganizations")

    if (report?.reportResult?.length === 0) {
        return (
            <>
                <div className={"report-details "}>
                    <div className={"graph-outer-Pie-report-name"}>
                        <h6>{reportName}</h6>
                        <p>{reportDescription || ""}</p>
                    </div>

                    {
                        customReportId !== undefined && report.userId === getDataFromLocalStorage("loggedInUserInfo").userId &&
                        <Dropdown className="report_dropdown graph-outer-Pie-icon">
                            <Dropdown.Toggle
                                className={"edit-button"}
                                variant="success"
                                id="edit_dropdown_wrapper">
                                <div className={"graph-outer-Pie-icon"}>
                                    <i className="fa fa-ellipsis-v"/>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                    hasPermission([Permissions.REPORT_WRITE], true) &&
                                    <Dropdown.Item href="#/action-2" onClick={() => {
                                        setReportId(customReportId)
                                        setCustomReportModal(true)
                                    }}>Update Report</Dropdown.Item>
                                }
                                {
                                    hasPermission([Permissions.REPORT_DELETE], true) &&
                                    <Dropdown.Item href="#/action-2" onClick={() => {
                                        setShowConfirmationModal(true)
                                        setDeleteReportId(customReportId)
                                    }}>Delete Report</Dropdown.Item>
                                }
                                {
                                    roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER, Roles.SUB_ORG_ADMIN]) && report.userId === getDataFromLocalStorage("loggedInUserInfo").userId &&
                                    <Dropdown.Item href="#/action-2" onClick={() => {
                                        setReportId(customReportId)
                                        setShowAssignCustomReportModal(true)
                                    }}>Assign Report</Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    }

                </div>
                <div className={"graph-outer"}>
                    <DatNotAvailable message={"Data Not Available"}
                                     className={"mediumFontSize report-data-not-available-text"}></DatNotAvailable>
                </div>
            </>

        )
    }
    else if (reportType === 'PIE_CHART') {
        const data = {
            labels: labels,
            datasets: [
                {
                    data: counts,
                    backgroundColor: backgroundColors,
                    hoverOffset: 4,
                }
            ]
        };

        const options = {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem) {
                            const label = reportResult?.[tooltipItem.dataIndex]?.surveyName;
                            const count = reportResult?.[tooltipItem.dataIndex]?.count;
                            const riskLevel = reportResult?.[tooltipItem.dataIndex]?.riskLevel;
                            return `Reflection: ${label.length > 8 ? label.substring(0, 8) + ' ...' : label}, Attempt Count: ${count}, Overall Result: ${riskLevel}`;
                        }
                    },
                    bodyFont: {
                        size: 10,
                    }
                },
                legend: {
                    position: 'bottom',
                    width: 1500,
                },
            },
        };

        return (

            <>
                <div className={"report-details "}>
                    <div className={"graph-outer-Pie-report-name"}>
                        <h6>{reportName}</h6>
                        <p>{reportDescription || ""}</p>
                    </div>

                    {
                        customReportId !== undefined && report.userId === getDataFromLocalStorage("loggedInUserInfo").userId &&
                        <Dropdown className="report_dropdown graph-outer-Pie-icon">
                            <Dropdown.Toggle
                                className={"edit-button"}
                                variant="success"
                                id="edit_dropdown_wrapper">
                                <div className={"graph-outer-Pie-icon"}>
                                    <i className="fa fa-ellipsis-v"/>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                    hasPermission([Permissions.REPORT_WRITE], true) &&
                                    <Dropdown.Item href="#/action-2" onClick={() => {
                                        setReportId(customReportId)
                                        setCustomReportModal(true)
                                    }}>Update Report</Dropdown.Item>
                                }
                                {
                                    hasPermission([Permissions.REPORT_DELETE], true) &&
                                    <Dropdown.Item href="#/action-2" onClick={() => {
                                        setShowConfirmationModal(true)
                                        setDeleteReportId(customReportId)
                                    }}>Delete Report</Dropdown.Item>
                                }
                                {
                                    roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER, Roles.SUB_ORG_ADMIN]) && report.userId === getDataFromLocalStorage("loggedInUserInfo").userId &&
                                    <Dropdown.Item href="#/action-2" onClick={() => {
                                        setReportId(customReportId)
                                        setShowAssignCustomReportModal(true)
                                    }}>Assign Report</Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    }

                </div>


                <div className={"graph-outer"}>

                    {customReportId !== undefined ?
                        <span className={isSubOrg ? "" : "cursor"} onClick={() => {
                            if (!isSubOrg) {
                                navigate("/report/" + customReportId)
                            }

                        }}>
                        <Pie id={"pieId"} className={"reflection-summary-download-class"} width={350} data={data}
                             options={options}/>
                    </span>
                        :
                        <Pie id={"pieId"} className={"reflection-summary-download-class"} width={350} data={data}
                             options={options}/>
                    }
                </div>
            </>

        )
    }
    else if (reportType === 'LINE_CHART') {
        const options = {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem) {
                            const label = reportResult?.[tooltipItem.dataIndex]?.surveyName;
                            const count = reportResult?.[tooltipItem.dataIndex]?.count;
                            const riskLevel = reportResult?.[tooltipItem.dataIndex]?.riskLevel;
                            return ` Reflection: ${label}, Attempt Count: ${count}, Overall Result: ${riskLevel}`;
                        }
                    },
                    bodyFont: {
                        size: 10,
                    }
                },
                legend: {
                    display: false
                }
            }
        };
        const data = {
            labels: labels.map(label => label.length > 8 ? label.substring(0, 8) + '...' : label),
            datasets: [
                {
                    label: '',
                    data: counts,
                    borderColor: backgroundColors,
                    backgroundColor: backgroundColors,
                    pointBackgroundColor: backgroundColors,
                    pointBorderColor: backgroundColors,
                    pointHoverBackgroundColor: backgroundColors,
                    pointHoverBorderColor: backgroundColors
                }
            ]
        };
        return (

            <>
                <div className={"report-details "}>
                    <div className={"graph-outer-Pie-report-name"}>
                        <h6>{reportName}</h6>
                        <p>{reportDescription || ""}</p>
                    </div>

                    {
                        customReportId !== undefined && report.userId === getDataFromLocalStorage("loggedInUserInfo").userId &&
                        <Dropdown className="report_dropdown graph-outer-Pie-icon">
                            <Dropdown.Toggle
                                className={"edit-button"}
                                variant="success"
                                id="edit_dropdown_wrapper">
                                <div className={"graph-outer-Pie-icon"}>
                                    <i className="fa fa-ellipsis-v"/>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                    hasPermission([Permissions.REPORT_WRITE], true) &&
                                    <Dropdown.Item href="#/action-2" onClick={() => {
                                        setReportId(customReportId)
                                        setCustomReportModal(true)
                                    }}>Update Report</Dropdown.Item>
                                }
                                {
                                    hasPermission([Permissions.REPORT_DELETE], true) &&
                                    <Dropdown.Item href="#/action-2" onClick={() => {
                                        setShowConfirmationModal(true)
                                        setDeleteReportId(customReportId)
                                    }}>Delete Report</Dropdown.Item>
                                }
                                {
                                    roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER, Roles.SUB_ORG_ADMIN]) && report.userId === getDataFromLocalStorage("loggedInUserInfo").userId &&
                                    <Dropdown.Item href="#/action-2" onClick={() => {
                                        setReportId(customReportId)
                                        setShowAssignCustomReportModal(true)
                                    }}>Assign Report</Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </div>
                <div className={"graph-outer"}>
                    <div className={"graph-outer-bar-y mediumFontSize"}>
                        {showYContent(responseVariable)}
                    </div>


                    {customReportId !== undefined ?
                        <span className={isSubOrg?"w-100":"w-100 cursor"} onClick={() => {
                            if(!isSubOrg){
                                navigate("/report/" + customReportId)
                            }
                        }}>

                    <Line id={"LineId"}
                          data={data}
                          options={options}
                          style={{
                              display: 'block',
                              marginLeft: '10px',
                              padding: '15px',
                              minWidth: '450px'
                          }}
                    />
                    </span>
                        :
                        <Line id={"LineId"}
                              data={data}
                              options={options}
                              style={{
                                  display: 'block',
                                  marginLeft: '10px',
                                  padding: '15px',
                                  minWidth: '450px'
                              }}
                        />
                    }
                    <div className={" graph-outer-bar-x mediumFontSize"}>
                        {showXContent(responseVariable)}
                    </div>


                </div>
            </>

        );
    }
    else if (reportType === 'DONUT_CHART') {
        const data = {
            labels: labels,
            datasets: [
                {
                    data: counts,
                    backgroundColor: backgroundColors,
                    hoverOffset: 4
                }
            ]
        };
        const options = {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem) {
                            const label = reportResult?.[tooltipItem.dataIndex]?.surveyName;
                            const count = reportResult?.[tooltipItem.dataIndex]?.count;
                            const riskLevel = reportResult?.[tooltipItem.dataIndex]?.riskLevel;
                            return `Reflection: ${label.length > 8 ? label.substring(0, 8) + ' ...' : label}, Attempt Count: ${count}, Overall Result: ${riskLevel}`;

                        }
                    },
                    bodyFont: {
                        size: 10,
                    }
                },
                legend: {
                    position: 'bottom',
                    maxWidth: 800,
                },
            },
        };

        return (

            <>
                <div className={"report-details "}>
                    <div className={"graph-outer-Pie-report-name"}>
                        <h6>{reportName}</h6>
                        <p>{reportDescription || ""}</p>
                    </div>
                    {
                        customReportId !== undefined && report.userId === getDataFromLocalStorage("loggedInUserInfo").userId &&
                        <Dropdown className="report_dropdown graph-outer-Pie-icon">
                            <Dropdown.Toggle
                                className={"edit-button"}
                                variant="success"
                                id="edit_dropdown_wrapper">
                                <div className={"graph-outer-Pie-icon"}>
                                    <i className="fa fa-ellipsis-v"/>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                    hasPermission([Permissions.REPORT_WRITE], true) &&
                                    <Dropdown.Item href="#/action-2" onClick={() => {
                                        setReportId(customReportId)
                                        setCustomReportModal(true)
                                    }}>Update Report</Dropdown.Item>
                                }
                                {
                                    hasPermission([Permissions.REPORT_DELETE], true) &&
                                    <Dropdown.Item href="#/action-2" onClick={() => {
                                        setShowConfirmationModal(true)
                                        setDeleteReportId(customReportId)
                                    }}>Delete Report</Dropdown.Item>
                                }
                                {
                                    roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER, Roles.SUB_ORG_ADMIN]) && report.userId === getDataFromLocalStorage("loggedInUserInfo").userId &&
                                    <Dropdown.Item href="#/action-2" onClick={() => {
                                        setReportId(customReportId)
                                        setShowAssignCustomReportModal(true)
                                    }}>Assign Report</Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </div>

                <div className={"graph-outer"}>
                    {customReportId !== undefined ?
                        <span className={isSubOrg?"":"cursor"} onClick={() => {
                            if(!isSubOrg){
                                navigate("/report/" + customReportId)
                            }
                        }}>
                        <Doughnut id={'doughnutId'} width={350} data={data} options={options}/>

                    </span>
                        :
                        <Doughnut id={'doughnutId'} width={350} data={data} options={options}/>
                    }
                </div>

            </>

        );

    }
    else if (reportType === "BUBBLE_CHART") {

        const data = {
            datasets: [
                {
                    label: 'Bubble Chart',
                    data: labels.map((label, index) => ({
                        x: index,
                        y: counts[index],
                        r: counts[index] * 0.20,
                        backgroundColor: backgroundColors,
                        hoverBackgroundColor: backgroundColors,
                    })),
                },
            ],
        };

        const options = {
            scales: {
                y: {
                    beginAtZero: true,
                },
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem) {
                            const label = reportResult?.[tooltipItem.dataIndex]?.surveyName;
                            const count = reportResult?.[tooltipItem.dataIndex]?.count;
                            const riskLevel = reportResult?.[tooltipItem.dataIndex]?.riskLevel;
                            return ` Reflection: ${label}, Attempt Count: ${count}, Overall Result: ${riskLevel}`;
                        }
                    },
                    bodyFont: {
                        size: 10,
                    }
                },
                legend: {
                    display: false,
                },
            },
        };


        return (

            <>

                <div className={"report-details"}>
                    <div className={"graph-outer-Pie-report-name"}>
                        <h6>{reportName}</h6>
                        <p>{reportDescription || ""}</p>
                    </div>

                    {
                        customReportId !== undefined && report.userId === getDataFromLocalStorage("loggedInUserInfo").userId &&
                        <Dropdown className="report_dropdown graph-outer-Pie-icon">
                            <Dropdown.Toggle
                                className={"edit-button"}
                                variant="success"
                                id="edit_dropdown_wrapper">
                                <div className={"graph-outer-Pie-icon"}>
                                    <i className="fa fa-ellipsis-v"/>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                    hasPermission([Permissions.REPORT_WRITE], true) &&
                                    <Dropdown.Item href="#/action-2" onClick={() => {
                                        setReportId(customReportId)
                                        setCustomReportModal(true)
                                    }}>Update Report</Dropdown.Item>
                                }
                                {
                                    hasPermission([Permissions.REPORT_DELETE], true) &&
                                    <Dropdown.Item href="#/action-2" onClick={() => {
                                        setShowConfirmationModal(true)
                                        setDeleteReportId(customReportId)
                                    }}>Delete Report</Dropdown.Item>
                                }
                                {
                                    roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER, Roles.SUB_ORG_ADMIN]) && report.userId === getDataFromLocalStorage("loggedInUserInfo").userId &&
                                    <Dropdown.Item href="#/action-2" onClick={() => {
                                        setReportId(customReportId)
                                        setShowAssignCustomReportModal(true)
                                    }}>Assign Report</Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </div>

                <div className={"graph-outer"}>

                    <div className={"graph-outer-bar-y mediumFontSize"}>
                        {showYContent(responseVariable)}
                    </div>


                    {customReportId !== undefined ?
                        <span className={isSubOrg? "w-100" :"cursor w-100"} onClick={() => {
                            if(!isSubOrg){
                                navigate("/report/" + customReportId)
                            }
                        }}>
                            <Bubble id={'BubbleId'} data={data} options={options}
                                    style={{display: 'block', marginLeft: '10px', padding: '15px'}}/>
                        </span>
                        :
                        <Bubble id={'BubbleId'} data={data} options={options}
                                style={{display: 'block', marginLeft: '10px', padding: '15px'}}/>
                    }
                    <div className={" graph-outer-bar-x mediumFontSize"}>
                        {showXContent(responseVariable)}
                    </div>

                </div>

            </>

        );
    }
    else if (reportType === "BAR_CHART") {
        const data = {
            labels: labels,
            datasets: [
                {
                    data: counts,
                    backgroundColor: backgroundColors,
                    hoverOffset: 4,
                },
            ],
        };

        const options = {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem) {
                            const label = reportResult?.[tooltipItem.dataIndex]?.surveyName;
                            const count = reportResult?.[tooltipItem.dataIndex]?.count;
                            const riskLevel = reportResult?.[tooltipItem.dataIndex]?.riskLevel;
                            return ` Reflection: ${label}, Attempt Count: ${count}, Overall Result: ${riskLevel}`;
                        }
                    },
                    bodyFont: {
                        size: 10,
                    }
                },
                legend: {
                    display: false,
                },
            },
        };
        return (

            <>
                <div className={"report-details "}>
                    <div className={"graph-outer-Pie-report-name"}>
                        <h6>{reportName}</h6>
                        <p>{reportDescription || ""}</p>
                    </div>

                    {
                        customReportId !== undefined && report.userId === getDataFromLocalStorage("loggedInUserInfo").userId &&
                        <Dropdown className="report_dropdown graph-outer-Pie-icon">
                            <Dropdown.Toggle
                                className={"edit-button"}
                                variant="success"
                                id="edit_dropdown_wrapper">
                                <div className={"graph-outer-Pie-icon"}>
                                    <i className="fa fa-ellipsis-v"/>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                    hasPermission([Permissions.REPORT_WRITE], true) &&
                                    <Dropdown.Item href="#/action-2" onClick={() => {
                                        setReportId(customReportId)
                                        setCustomReportModal(true)
                                    }}>Update Report</Dropdown.Item>
                                }
                                {
                                    hasPermission([Permissions.REPORT_DELETE], true) &&
                                    <Dropdown.Item href="#/action-2" onClick={() => {
                                        setShowConfirmationModal(true)
                                        setDeleteReportId(customReportId)
                                    }}>Delete Report</Dropdown.Item>
                                }
                                {
                                    roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER, Roles.SUB_ORG_ADMIN]) && report.userId === getDataFromLocalStorage("loggedInUserInfo").userId &&
                                    <Dropdown.Item href="#/action-2" onClick={() => {
                                        setReportId(customReportId)
                                        setShowAssignCustomReportModal(true)
                                    }}>Assign Report</Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    }

                </div>

                <div className={"graph-outer"}>
                    <div className={"graph-outer-bar-y mediumFontSize"}>
                        {showYContent(responseVariable)}
                    </div>

                    {customReportId !== undefined ?
                        <span className={isSubOrg?"w-100":"cursor w-100"} onClick={() => {
                            if(!isSubOrg){
                                navigate("/report/" + customReportId)
                            }
                        }}>
                        <Bar id={"BarId"} height={300} width={590} data={data} options={options}
                             style={{display: 'block', marginLeft: '10px', padding: '15px'}}/>
                        </span>
                        :
                        <Bar id={"BarId"} height={300} width={590} data={data} options={options}
                             style={{display: 'block', marginLeft: '10px', padding: '15px'}}/>}

                    <div className={" graph-outer-bar-x mediumFontSize"}>
                        {showXContent(responseVariable)}
                    </div>


                </div>
            </>

        );
    }
};

export default ReportItem;

const DatNotAvailable = ({className = "", message = ""}) => {
    return (
        <div className={" " + className}>{message}</div>
    );
}