import UserInfo from "../layout/UserInfo";
import {HiPencil} from "react-icons/hi";
import {
    createOptionListForSelectTag,
    handleApiResponse,
    isNullOrEmpty,
    isUnauthorized, isValidCreateStrategyData,
} from "../../helpers/utils";
import {Loader, TailSpinLoader} from "../layout/Loader";
import {Col, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getStrategyById, updateStrategy} from "../../reduxStore/strategySlice/strategySlice";
import {getPublishSurveys} from "../../reduxStore/surveySlices/surveySlices";
import {resetReducers} from "../../reduxStore/globalKeysSlice/globalKeysSlice";
import AssignStrategyToReflectionModal from "./modal/AssignStrategyToReflectionModal";

const EditStrategy = () => {

    const {strategyId} = useParams()
    const orgPreferenceColor = localStorage.getItem("color") !== null && localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : "#fc6620"

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showAssignStrategyToReflectionModal, setShowAssignStrategyToReflectionModal] = useState(false)
    const [currentFocusOn, setCurrentFocusOn] = useState("")
    const [strategyData, setStrategyData] = useState(null)
    const [currentGoal, setCurrentGoal] = useState("")
    const [editStrategyTitle, setEditStrategyTitle] = useState(false)

    const surveysApi = useSelector(state => state.survey.getPublishSurveysReducer)
    const strategyByIdApi = useSelector(state => state.strategy.getStrategyByIdReducer)
    const updateStrategyApi = useSelector(state => state.strategy.updateStrategyReducer)

    useEffect(() => {
        if (strategyId) {
            dispatch(getStrategyById(strategyId)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }
        return () => {
            dispatch(resetReducers({sliceNames: ["getPublishSurveysReducer"]}))
            dispatch(resetReducers({sliceNames: ["getStrategyByIdReducer"]}))
        }
    }, [strategyId]);

    useEffect(() => {
        if (!strategyByIdApi?.loading && strategyByIdApi.data !== undefined && strategyByIdApi.data !== null) {
            dispatch(getPublishSurveys({
                orgId: strategyByIdApi?.data?.orgId,
                status: ["DRAFT", "PUBLISHED"]
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                handleApiResponse(res, () => {
                    setStrategyData({
                        ...strategyByIdApi?.data,
                        surveys: createOptionListForSelectTag(res.payload?.data?.filter(cur => strategyByIdApi?.data?.surveyIds?.includes(cur?.surveyId)) || [], "name", "surveyId")
                    })
                })
            })
        }
    }, [strategyByIdApi]);

    const handleStrategyChange = (e) => {
        const {name, value} = e.target
        setStrategyData({
            ...strategyData,
            [name]: value
        })
    }
    const removeFromArray = (removeFrom, index) => {
        const updatedArray = [...strategyData?.[removeFrom]]
        updatedArray.splice(index, 1);
        setStrategyData({...strategyData, [removeFrom]: updatedArray})
    }
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            const {_, value} = e.target
            if (!isNullOrEmpty(value.trim()) && !strategyData?.goals.includes(value.trim())) {
                setStrategyData({
                    ...strategyData,
                    goals: [...strategyData?.goals, value.trim()]
                })
            }
            setCurrentGoal("")
        }
    };
    const handleStrategySubmit = () => {
        const updatedStrategyData = {
            ...strategyData,
            surveyIds: strategyData?.surveys?.map(cur => cur?.value) || []
        }
        if (isValidCreateStrategyData(updatedStrategyData)) {
            dispatch(updateStrategy(updatedStrategyData)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                handleApiResponse(res, () => {
                    navigate(-1)
                })
            })
        }
    }
    const handleFocus = (e) => {
        const {name, _} = e.target
        setCurrentFocusOn(name)
    }
    const handleBlur = () => {
        setCurrentFocusOn("")
    }


    return surveysApi?.loading || surveysApi.data === undefined || surveysApi.data === null || strategyByIdApi?.loading || strategyByIdApi.data === undefined || strategyByIdApi.data === null ?
        <Loader/> :
        (
            <section className={"reflection_wrapper"}>
                <div className="cmn_header">
                    <h2 className="mediumFontSize  org-heading m-0">Edit Strategy </h2> <UserInfo/>
                </div>
                <div className='save-survey-outer main_content '>
                    <div className={"reflection-name-share-btn-outer pt-3"}>
                        <div
                            className={"survey-name-share-btn d-flex align-items-center justify-content-space-between "}>
                            {
                                editStrategyTitle ?
                                    <div className={"survey-name-input w-50 me-1 gray_bg_color on_focus"}>
                                        <input
                                            name={"name"}
                                            value={strategyData?.name}
                                            onChange={handleStrategyChange}
                                            style={{
                                                border: currentFocusOn === "name" ? `1px solid ${orgPreferenceColor}` : "",
                                            }}
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}
                                            className={"form-control survey-name-input edit-strategy-input"}
                                            type="text"
                                            placeholder="Enter Strategy Name"
                                        />
                                    </div>
                                    :
                                    <div className={"survey-title-container d-flex align-items-center"}>
                                        <h3 className={` mediumFontSize `}
                                            title={strategyData?.name}>{strategyData?.name}</h3>
                                        <HiPencil
                                            className={"edit-survey-title-btn "}
                                            onClick={() => {
                                                setEditStrategyTitle(true)
                                            }}/>
                                    </div>

                            }

                            <div
                                className={"share-reflection-lvl-btn d-flex align-items-center justify-content-center"}>
                                <button
                                    style={{background: orgPreferenceColor}}
                                    disabled={updateStrategyApi?.loading}
                                    className='modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize submit-survey-btn '
                                    onClick={handleStrategySubmit}
                                >Save
                                    {
                                        updateStrategyApi?.loading &&
                                        <TailSpinLoader/>
                                    }
                                </button>
                            </div>

                        </div>
                    </div>


                </div>

                <div className={"hr mt-3"}></div>
                <div>
                    <div className={"strategy-description strategy-detail-outer mt-2"}>
                        <div className={"mediumFontSize font-weight-500 font-size-15 mb-1"}>Description</div>
                        <textarea
                            style={{
                                border: currentFocusOn === "description" ? `1px solid ${orgPreferenceColor}` : ""
                            }}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            className={"form-control gray_bg_color on_focus edit-strategy-input edit-strategy-description-input"}
                            value={strategyData?.description}
                            name={"description"}
                            onChange={handleStrategyChange}
                        />
                    </div>
                    <Row className={"strategy-detail-outer"}>
                        <Col lg={6} md={6} className={"py-0 px-1 strategy-goals-outer"}>

                            <div className={"strategy-goals mt-2"}>
                                <div className={"mediumFontSize font-weight-500 font-size-15 mb-1"}>Goals</div>
                                <div className={"strategy-goal-outer  w-100 me-1 "}
                                     style={{
                                         border: currentFocusOn === "goals" ? `1px solid ${orgPreferenceColor}` : "",
                                     }}
                                >
                                    {
                                        strategyData?.goals?.length === 0 ?
                                            <div
                                                onClick={() => {
                                                    handleFocus({target: {name: "goals"}})
                                                }}
                                                className={"border-radius-8 font-size-14 font-weight-500 mediumFontSize text-center  on_focus p-2 mb-2"}>
                                                No Goals Defined</div>
                                            :
                                            strategyData?.goals?.map((goal, index) => {
                                                return (
                                                    <div key={index}
                                                         onClick={() => {
                                                             handleFocus({target: {name: "goals"}})
                                                         }}
                                                         onBlur={handleBlur}
                                                         className={"strategy-goal d-flex align-items-center justify-content-space-between mb-2"}>
                                                        <div className={"tag-text word-break-all"}
                                                             title={goal}>{goal}
                                                        </div>
                                                        <div className={"tag-cross"}
                                                             onClick={() => {
                                                                 removeFromArray("goals", index)
                                                             }}>
                                                            <i className={"fa fa-times remove-tag-icon"}/>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                    }
                                    <div className={"add-tag-outer strategy-goal-input-outer mb-2 border-radius-8"}>
                                        <i className={"fa fa-plus add-tag-icon text-dark "}/>
                                        <input name={"goals"}
                                               value={currentGoal}
                                               onChange={(e) => {
                                                   setCurrentGoal(e.target.value)
                                               }}
                                               onFocus={handleFocus}
                                               onKeyDown={handleKeyDown}
                                               onBlur={(e) => {
                                                   handleKeyDown({...e, key: "Enter"})
                                                   handleBlur()
                                               }}
                                               placeholder={"Add Strategy Goals"}
                                               className={"tags-input-field border-radius-8 gray_bg_color on_focus box-shadow-unset-on-focus"}
                                               type={"text"}

                                        />
                                    </div>
                                </div>
                            </div>

                        </Col>
                        <Col lg={6} md={6} className={"py-0 px-1"}>

                            <div className={"strategy-description mt-2"}>
                                <div className={"assigned-reflection-txt-btn-outer "}>
                                    <div className={"mediumFontSize font-weight-500 font-size-15 mb-1 "}>Assigned
                                        Reflections
                                    </div>
                                    <button
                                        style={{background: orgPreferenceColor}}
                                        className='mb-1 mt-2 modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize submit-survey-btn '
                                        onClick={() => {
                                            setShowAssignStrategyToReflectionModal(true)
                                        }}
                                    > Assign Reflections
                                    </button>
                                </div>

                                <div className={"strategy-goal-outer  w-100 me-1 "}
                                     style={{
                                         border: currentFocusOn === "surveys" ? `1px solid ${orgPreferenceColor}` : "",
                                     }}
                                >
                                    {
                                        strategyData?.surveys?.length === 0 ?
                                            <div
                                                onClick={() => {
                                                    handleFocus({target: {name: "surveys"}})
                                                }}
                                                className={"border-radius-8 font-size-14 font-weight-500 mediumFontSize text-center  on_focus p-2 mb-2"}>
                                                No Reflections Assigned</div>
                                            :
                                            strategyData?.surveys?.map((survey, index) => {
                                                return (
                                                    <div key={index}
                                                         onClick={() => {
                                                             handleFocus({target: {name: "surveys"}})
                                                         }}
                                                         className={"strategy-goal d-flex align-items-center justify-content-space-between mb-2"}>
                                                        <div className={"tag-text word-break-all"}
                                                             title={survey?.label}>{survey?.label}
                                                        </div>
                                                        <div className={"tag-cross"}
                                                             onClick={() => {
                                                                 removeFromArray("surveys", index)
                                                             }}>
                                                            <i className={"fa fa-times remove-tag-icon"}/>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                    }
                                    {/*<div className={"text-center"}>*/}
                                    {/*    <button*/}
                                    {/*        style={{background: orgPreferenceColor}}*/}
                                    {/*        className='mb-2 modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize submit-survey-btn '*/}
                                    {/*        onClick={() => {*/}
                                    {/*            setShowAssignStrategyToReflectionModal(true)*/}
                                    {/*            handleFocus({target: {name: "surveys"}})*/}
                                    {/*        }}*/}
                                    {/*    > Assign Reflections*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}
                                </div>

                            </div>

                        </Col>
                    </Row>
                </div>
                {
                    showAssignStrategyToReflectionModal &&
                    <AssignStrategyToReflectionModal
                        strategyData={strategyData}
                        setStrategyData={setStrategyData}
                        show={showAssignStrategyToReflectionModal}
                        setShow={setShowAssignStrategyToReflectionModal}
                    />
                }
            </section>
        );
}
export default EditStrategy