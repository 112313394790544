import leftArrow from "../../images/arrow-left.png";
import {Link} from "react-router-dom";
import rightArrow from "../../images/arrow-right.png";

const Pagination = ({className="",pageNumber, setPageNumber, totalPages}) => {

    function changePage(event) {
        const clickedPageNumber = Number(event.target.textContent);
        setPageNumber(clickedPageNumber);
    }

    return (
        <>
            <div className={"pagination_row pagination-border "+className}>


                <div className={"arrow_img_prev " + (pageNumber === 1 ? "disable" : "")} onClick={() => {
                    if (pageNumber > 1) {
                        setPageNumber(pageNumber - 1)
                    }
                }}>
                    <img src={leftArrow} alt="nothing" width={"20"}/>
                    <span className='mediumFontSize'>Prev</span>
                </div>
                <div className="buttons">
                    <ul>
                        {
                            totalPages <= 7 && [...Array(totalPages)].map((x, index) => {
                                return (
                                    <li className={"cursor " + (pageNumber === index + 1 ? "active" : "")}
                                        key={index}
                                        onClick={changePage}><Link className="page-link  " to="#">{index + 1}</Link></li>
                                );
                            })
                        }
                        {
                            totalPages === 8 ? pageNumber <= 4 ? (
                                <>
                                    {
                                        [...Array(5)].map((x, index) => {
                                            return (
                                                <li className={"cursor " + (pageNumber === index + 1 ? "active" : "")}
                                                    key={index}
                                                    onClick={changePage}><Link className="page-link  "
                                                                               to="#">{index + 1}</Link>
                                                </li>
                                            );
                                        })
                                    }
                                    <li className={"cursor "}><Link className="page-link  " to="#">...</Link></li>
                                    <li className={"cursor " + (pageNumber === totalPages - 1 ? "active" : "")}
                                        onClick={changePage}><Link className="page-link  "
                                                                   to="#">{totalPages - 1}</Link></li>
                                    <li className={"cursor " + (pageNumber === totalPages ? "active" : "")}
                                        onClick={changePage}><Link className="page-link  " to="#">{totalPages}</Link>
                                    </li>


                                </>


                            ) : <>

                                <li className={"cursor " + (pageNumber === 1 ? "active" : "")} onClick={changePage}>
                                    <Link className="page-link  " to="#">1</Link></li>
                                <li className={"cursor " + (pageNumber === 2 ? "active" : "")} onClick={changePage}>
                                    <Link className="page-link  " to="#">2</Link></li>
                                <li className={"cursor "}><Link className="page-link  " to="#">...</Link></li>
                                {
                                    [...Array(5)].map((x, index) => {
                                        return (
                                            <li className={"cursor " + (pageNumber === index + 4 ? "active" : "")}
                                                key={index}
                                                onClick={changePage}><Link className="page-link  "
                                                                           to="#">{index + 4}</Link>
                                            </li>
                                        );
                                    })
                                }
                            </> : <></>

                        }
                        {
                            totalPages >= 9 ? pageNumber <= 4 ? <>
                                    {
                                        [...Array(5)].map((x, index) => {
                                            return (
                                                <li className={"cursor " + (pageNumber === index + 1 ? "active" : "")}
                                                    key={index}
                                                    onClick={changePage}><Link className="page-link  "
                                                                               to="#">{index + 1}</Link>
                                                </li>
                                            );
                                        })
                                    }
                                    <li className={"cursor "}><Link className="page-link  " to="#">...</Link></li>
                                    <li className={"cursor " + (pageNumber === totalPages - 1 ? "active" : "")}
                                        onClick={changePage}><Link className="page-link  " to="#">{totalPages - 1}</Link>
                                    </li>
                                    <li className={"cursor " + (pageNumber === totalPages ? "active" : "")}
                                        onClick={changePage}><Link className="page-link  " to="#">{totalPages}</Link></li>

                                </> : pageNumber >= totalPages - 3 ? <>
                                    <li className={"cursor " + (pageNumber === 1 ? "active" : "")} onClick={changePage}>
                                        <Link className="page-link  " to="#">1</Link></li>
                                    <li className={"cursor " + (pageNumber === 2 ? "active" : "")} onClick={changePage}>
                                        <Link className="page-link  " to="#">2</Link></li>
                                    <li className={"cursor "}><Link className="page-link  " to="#">...</Link></li>
                                    {
                                        [...Array(5)].map((x, index) => {
                                            return (
                                                <li className={"cursor " + (pageNumber === totalPages -4+index ? "active" : "")}
                                                    key={index}
                                                    onClick={changePage}><Link className="page-link  "
                                                                               to="#">{ totalPages -4+index}</Link>
                                                </li>
                                            );
                                        })
                                    }


                                </> :
                                    <>
                                        <li className={"cursor " + (pageNumber === 1 ? "active" : "")} onClick={changePage}>
                                            <Link className="page-link  " to="#">1</Link></li>
                                        <li className={"cursor " + (pageNumber === 2 ? "active" : "")} onClick={changePage}>
                                            <Link className="page-link  " to="#">2</Link></li>
                                        <li className={"cursor "}><Link className="page-link  " to="#">...</Link></li>
                                        {
                                            [...Array(3)].map((x, index) => {
                                                return (
                                                    <li className={"cursor " + (pageNumber === (pageNumber + index -1) ? "active" : "")}
                                                        key={index}
                                                        onClick={changePage}><Link className="page-link  "
                                                                                   to="#">{ pageNumber + index -1}</Link>
                                                    </li>
                                                );
                                            })
                                        }
                                        <li className={"cursor "}><Link className="page-link  " to="#">...</Link></li>
                                        <li className={"cursor " + (pageNumber === 1 ? "active" : "")} onClick={changePage}>
                                            <Link className="page-link  " to="#">{totalPages-1}</Link></li>
                                        <li className={"cursor " + (pageNumber === 2 ? "active" : "")} onClick={changePage}>
                                            <Link className="page-link  " to="#">{totalPages}</Link></li>

                                    </>




                                : <></>

                        }

                    </ul>
                </div>
                <div className={"page-link arrow_img_next " + (pageNumber === totalPages ? "disable" : "")}
                     onClick={() => {
                         if (pageNumber < totalPages) {
                             setPageNumber(pageNumber + 1)
                         }

                     }}>
                    <span className='mediumFontSize'>Next</span>
                    <img src={rightArrow} alt="nothing" width={"20"}/>
                </div>
            </div>

        </>
    );

}
export default Pagination;