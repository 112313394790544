import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {convertStringToObject, formatMessage, getAxiosConfig, showToast} from "../../helpers/utils";
import {CreatedSuccessfully, UpdatedSuccessfully} from "../../helpers/constants";

export const createCampaign = createAsyncThunk('campaign/createCampaign', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/campaign`, data, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

export const updateCampaign = createAsyncThunk('campaign/updateCampaign', async (data, thunkAPI) => {
    return await axios.put(`${process.env.REACT_APP_BASE_URL}/campaign/${data.campaignId}`, data, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

export const findCampaignById = createAsyncThunk('campaign/findCampaignById', async (campaignId, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/campaign/${campaignId}`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

export const searchCampaign = createAsyncThunk('campaign/searchCampaign', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/campaign/search`, data, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

export const deleteCampaign = createAsyncThunk('campaign/deleteCampaign', async (id, thunkAPI) => {
    return await axios.delete(`${process.env.REACT_APP_BASE_URL}/campaign/${id}`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})


const campaignSlice = createSlice({
    name: "campaign",
    initialState: {
        createCampaignReducer: {loading: false},
        updateCampaignReducer: {loading: false},
        searchCampaignReducer: {loading: false},
        deleteCampaignReducer: {loading: false},
        findCampaignByIdReducer: {loading: false},
    },
    reducers: {},
    extraReducers: {
        // Create Campaign
        [createCampaign.pending]: (state) => {
            state.createCampaignReducer = {loading: true};
        },
        [createCampaign.fulfilled]: (state, action) => {
            showToast(formatMessage(CreatedSuccessfully, ["Campaign"]), "success")
            state.createCampaignReducer = {loading: false, data: action.payload.data};
        },
        [createCampaign.rejected]: (state, action) => {
            state.createCampaignReducer = {loading: false};
            if (action?.payload?.detailDesc?.includes("Exist_Fail") || action?.payload?.detailDesc?.includes("High_Authority_Fail")) {
                const errorObject = convertStringToObject(action?.payload?.detailDesc)
                let message;
                if (errorObject.hasOwnProperty("Exist_Fail") && errorObject.hasOwnProperty("High_Authority_Fail")) {
                    message =
                        <span className="black-icon">● {errorObject.Exist_Fail}<br/>● {errorObject.High_Authority_Fail}</span>;
                } else if (errorObject.hasOwnProperty("Exist_Fail")) {
                    message = errorObject.Exist_Fail
                } else if (errorObject.hasOwnProperty("High_Authority_Fail")) {
                    message = errorObject.High_Authority_Fail
                }
                showToast(message, "error")
            } else {
                showToast(action?.payload?.detailDesc || action?.payload?.statusText, "error")
            }

        },
        // Update Campaign
        [updateCampaign.pending]: (state) => {
            state.updateCampaignReducer = {loading: true};
        },
        [updateCampaign.fulfilled]: (state, action) => {
            showToast(formatMessage(UpdatedSuccessfully, ["Campaign"]), "success")
            state.updateCampaignReducer = {loading: false, data: action.payload.data};
        },
        [updateCampaign.rejected]: (state, action) => {
            state.updateCampaignReducer = {loading: false};
            if (action?.payload?.detailDesc?.includes("Exist_Fail") || action?.payload?.detailDesc?.includes("High_Authority_Fail")) {
                const errorObject = convertStringToObject(action?.payload?.detailDesc)
                let message;
                if (errorObject.hasOwnProperty("Exist_Fail") && errorObject.hasOwnProperty("High_Authority_Fail")) {
                    message =
                        <span className="black-icon">● {errorObject.Exist_Fail}<br/>● {errorObject.High_Authority_Fail}</span>;
                } else if (errorObject.hasOwnProperty("Exist_Fail")) {
                    message = errorObject.Exist_Fail
                } else if (errorObject.hasOwnProperty("High_Authority_Fail")) {
                    message = errorObject.High_Authority_Fail
                }
                showToast(message, "error")
            } else {
                showToast(action?.payload?.detailDesc || action?.payload?.statusText, "error")
            }

        },
        // Find Campaign By Id
        [findCampaignById.pending]: (state) => {
            state.findCampaignByIdReducer = {loading: true};
        },
        [findCampaignById.fulfilled]: (state, action) => {
            state.findCampaignByIdReducer = {loading: false, data: action.payload.data};
        },
        [findCampaignById.rejected]: (state, action) => {
            state.findCampaignByIdReducer = {loading: false};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText, "error")

        },
        // Search Campaign
        [searchCampaign.pending]: (state) => {
            state.searchCampaignReducer = {loading: true};
        },
        [searchCampaign.fulfilled]: (state, action) => {
            state.searchCampaignReducer = {loading: false, data: action.payload.data};
        },
        [searchCampaign.rejected]: (state, action) => {
            state.searchCampaignReducer = {loading: false};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText, "error")
        },

        // Delete Campaign
        [deleteCampaign.pending]: (state) => {
            state.deleteCampaignReducer = {loading: true};
        },
        [deleteCampaign.fulfilled]: (state, action) => {
            state.deleteCampaignReducer = {loading: false, data: action.payload.data};
        },
        [deleteCampaign.rejected]: (state, action) => {
            state.deleteCampaignReducer = {loading: false};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText, "error")
        },
    }
})

export const {} = campaignSlice.actions

export default campaignSlice.reducer;
