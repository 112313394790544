import Modal from "react-bootstrap/Modal";
import {
    CustomReportCriteria,
    ReportFiled,
    ReportTypeEnum,
    Roles,
    UserFields
} from "../../../helpers/accessControl";
import {useDispatch, useSelector} from "react-redux";
import {
    applyValidDateRange,
    computeBackDate, convertIntoDate, convertInToString,
    filterReflectionFields, getDataFromLocalStorage, getFilterOperators,
    getFilterOperatorsReports, isUnauthorized, showValueField, validateReportObject
} from "../../../helpers/utils";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {FaPlusCircle, FaTrash} from "react-icons/fa";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {toast} from "react-toastify";
import {
    createCustomReport,
    getAllCustomReportsData,
    resetGetIdCustomReportReducer, updateCustomReport
} from "../../../reduxStore/CustomReportSlices/customReport";
import {TailSpinLoader} from "../../layout/Loader";
import {IoIosClose} from "react-icons/io";
import jwtDecode from "jwt-decode";
import {getAllUsersWithoutPagination} from "../../../reduxStore/usersSlices/userSlice";


const CustomReportModal = ({
                               show,
                               setShow,
                               isSubOrg,
                               surveyDropDownList,
                               userDropDownList,
                               reportDetails,
                               setReportDetails,
                               isSubOrgCustomReport,
                               subOrgId,
                               isTeam,
                               teamId
                           }) => {


    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userListByByOrgIdData = useSelector(state => state?.users?.getAllUsersByOrgIdReducer);
    const createCustomReportData = useSelector(state => state?.customReport?.createCustomReportReducer);
    const updateCustomReportData = useSelector(state => state?.customReport?.updateCustomReportReducer);

    const handleClose = () => {
        setShow(false)
    };
    const [reportName, setReportName] = useState(null)
    const [customReportId, setCustomReportId] = useState(null)
    const [anonymousUserSelection, setAnonymousUserSelection] = useState({
        isAnonymousUserCheckBoxSelected: false,
        disableAnonymousUserCheckBox: false
    })
    const [reportType, setReportType] = useState("")
    const [reportFilters, setReportFilters] = useState([{reportOperand: '', reportCondition: "", operandValue: []}])
    const [selectOperand, setSelectOperand] = useState([{selectOperand: "", reportCriteria: reportFilters}])
    const [reportDescription, setReportDescription] = useState("")
    const [reportVariables, setReportVariables] = useState({labelX: "", titleX: "", labelY: "", titleY: ""})
    const [selectedReportPeriod, setSelectedReportPeriod] = useState({value: "WEEKLY", label: "Weekly"})
    const [assignedTo, setAssignedTo] = useState([])
    const [selectUser, setUserSelect] = useState(null)
    const [reportPeriod, setReportPeriod] = useState([{value: "WEEKLY", label: "Weekly"}, {
        value: "MONTHLY",
        label: "Monthly"
    }, {value: "YEARLY", label: "Yearly"}, {value: "CUSTOM", label: "Custom"}])
    const [reportSearchQuery, setReportSearchQuery] = useState({
        createdDateRange: {
            period: selectedReportPeriod?.value,
            startDate: computeBackDate(6),
            endDate: new Date()
        }
    });

    useEffect(() => {
        dispatch(resetGetIdCustomReportReducer())
        dispatch(getAllUsersWithoutPagination({
            roles: [Roles.SYS_ADMIN, Roles.ORG_ADMIN, Roles.SUB_ORG_ADMIN, Roles.MANAGER, Roles.MENTOR, Roles.REFLECTION_WRITER, Roles.REPORT_WRITER, Roles.MENTEE],
            orgId: isSubOrg ? subOrgId : jwtDecode(getDataFromLocalStorage("token")).orgId
        }))
    }, [])

    const handleNewFilter = (parentIndex) => {
        let data = [...selectOperand]
        data[parentIndex] = {
            ...data[parentIndex],
            reportCriteria: [...data[parentIndex]?.reportCriteria, {
                reportOperand: '',
                reportCondition: "",
                operandValue: []
            }]
        }
        setSelectOperand([...data])
    };

    const handleAddNewOperand = () => {
        setSelectOperand([...selectOperand, {
            selectOperand: "",
            reportCriteria: reportFilters
        }])
    };

    useEffect(() => {

        if (reportDetails !== null && reportDetails !== undefined) {
            console.log("reportDetails====>",reportDetails)
            setCustomReportId(reportDetails?.data?.id)
            setReportName(reportDetails?.data?.reportName)
            setReportDescription(reportDetails?.data?.reportDescription)
            setAnonymousUserSelection({
                ...anonymousUserSelection,
                isAnonymousUserCheckBoxSelected: reportDetails?.data?.anonymousUserIncluded,
                disableAnonymousUserCheckBox: false
            })
            setReportType(reportDetails?.data?.reportType || "BAR_CHART")
            setReportVariables({
                labelX: reportDetails?.data?.reportVariables?.labelX,
                titleX: reportDetails?.data?.reportVariables?.titleX,
                labelY: reportDetails?.data?.reportVariables?.labelY,
                titleY: reportDetails?.data?.reportVariables?.titleY
            })
            setSelectOperand(reportDetails?.data?.reportOperation)
            setSelectedReportPeriod(reportPeriod?.find(cur => cur?.value === reportDetails?.data?.reportPeriod?.period))
            setReportSearchQuery({
                createdDateRange: {
                    period: reportPeriod?.find(cur => cur?.value === reportDetails?.data?.reportPeriod?.period)?.value,
                    startDate: convertIntoDate(reportDetails?.data?.reportPeriod?.startDate),
                    endDate: convertIntoDate(reportDetails?.data?.reportPeriod?.endDate)
                }
            })
            setAssignedTo(reportDetails?.data?.assignedTo)
        }

    }, [reportDetails])

    const handleValueChange = (e, childIndex, parentIndex) => {
        const {name, value} = e.target;
        const updatedUserCriteria = selectOperand[parentIndex]?.reportCriteria.map((filter, index) => {
            if (index === childIndex) {
                return {
                    ...filter,
                    [name]: name === 'value' ? (value ? [value] : []) : value,
                    reportCondition: (name === 'reportOperand') ? "" : name === 'reportCondition' ? value : filter.reportCondition,
                    operandValue: (name === 'reportOperand' || name === 'reportCondition') ? [] : name === 'operandValue' ? [value] : filter.value
                };
            }
            return filter;
        });
        setSelectOperand(prevUserCriteria => {
            const updatedSelectOperand = [...prevUserCriteria];
            updatedSelectOperand[parentIndex] = {
                ...updatedSelectOperand[parentIndex],
                reportCriteria: updatedUserCriteria
            };
            return updatedSelectOperand;
        });
    };

    const handleDeleteOperand = (index) => {
        const updatedSelectOperand = selectOperand?.filter((cur, ind) => {
            return ind !== index
        })
        setSelectOperand(updatedSelectOperand)
    };

    function deleteAction(actionIndex, parentIndex) {
        let latestList = selectOperand[parentIndex]?.reportCriteria.filter((el, i) => {
            return i !== actionIndex;
        })
        setSelectOperand(prevUserCriteria => {
            const updatedSelectOperand = [...prevUserCriteria];
            updatedSelectOperand[parentIndex] = {
                ...updatedSelectOperand[parentIndex],
                reportCriteria: latestList
            };
            return updatedSelectOperand;
        });

    }

    useEffect(() => {
        if (reportDetails && Array.isArray(userListByByOrgIdData?.data?.items) && isSubOrgCustomReport) {
            const user = userListByByOrgIdData?.data?.items.find(c => c?.uid === reportDetails?.data?.userId)
            user && setUserSelect({label: user?.firstName, value: user.uid});
        }
    }, [reportDetails, userListByByOrgIdData]);

    useEffect(() => {
        if (selectOperand !== null && selectOperand !== undefined && Array.isArray(selectOperand) && selectOperand?.length > 0) {
            const isRolesCriteriaPresent = selectOperand?.filter(operand => operand?.selectOperand === "Users")?.some(operand =>
                operand?.reportCriteria?.some(c => c?.reportOperand === "roles")
            )
            if (isRolesCriteriaPresent) {
                setAnonymousUserSelection({
                    ...anonymousUserSelection,
                    isAnonymousUserCheckBoxSelected: true,
                    disableAnonymousUserCheckBox: true
                })
            } else {
                setAnonymousUserSelection({
                    ...anonymousUserSelection,
                    disableAnonymousUserCheckBox: false
                })
            }
        }
    }, [selectOperand])


    function handleCustomReport(e) {
        e.preventDefault()
        const customReportJson = {
            id: customReportId,
            reportName: reportName,
            orgId: isSubOrg ? subOrgId : null,
            userId: getDataFromLocalStorage("loggedInUserInfo").userId,
            reportDescription: reportDescription,
            reportType: reportType,
            reportVariables: reportVariables,
            reportOperation: selectOperand,
            reportPeriod: {
                period: reportSearchQuery?.createdDateRange?.period,
                startDate: convertInToString(reportSearchQuery?.createdDateRange?.startDate),
                endDate: convertInToString(reportSearchQuery?.createdDateRange?.endDate)
            },
            teamId: isTeam ? teamId : null,
            anonymousUserIncluded: anonymousUserSelection.isAnonymousUserCheckBoxSelected,
            assignedTo: assignedTo
        }
        const isValid = validateReportObject(customReportJson)
        if (typeof isValid === "object") {
            return toast.error(isValid.errorMessage, {toastId: "toast"})
        } else {
            if (reportDetails !== null && reportDetails !== undefined) {
                dispatch(updateCustomReport(customReportJson)).then(res => {
                    if (isUnauthorized(res)) {
                        navigate("/")
                    }
                    if (res.meta.requestStatus === "fulfilled") {
                        setShow(false)
                        setReportDetails(null)
                        dispatch(getAllCustomReportsData({orgId: subOrgId,teamId:teamId})).then(res => {
                            if (isUnauthorized(res)) {
                                navigate("/")
                            }
                        })
                    }
                })

            } else {
                dispatch(createCustomReport(customReportJson)).then(res => {
                    if (isUnauthorized(res)) {
                        navigate("/")
                    }
                    if (res.meta.requestStatus === "fulfilled") {
                        setShow(false)
                        dispatch(getAllCustomReportsData({orgId: subOrgId,teamId:teamId})).then(res => {
                            if (isUnauthorized(res)) {
                                navigate("/")
                            }
                        })
                    }
                })
            }
        }

    }

    return (
        <>
            <Modal className='mt-4' show={show} size={"xl"}>
                <Modal.Header className={"d-flex"}>
                    <Modal.Title
                        className='add_member_heading mediumFontSize'>{reportDetails === null ? "Create Report" : " Update Report"}</Modal.Title>
                    <IoIosClose className={"close_btn"} onClick={handleClose} size={24}/>
                </Modal.Header>

                <form onSubmit={handleCustomReport}>

                    <Modal.Body className="modal_outer">

                        <Modal.Body className="modal_outer">

                            <div className="reset_form">


                                {/*{isSubOrgCustomReport &&*/}
                                {/*    <div className={"row mb-2 custom-report  "}>*/}
                                {/*        <div className={"form-group"}>*/}
                                {/*            <label>Select Member</label>*/}
                                {/*        </div>*/}
                                {/*        <div>*/}
                                {/*        {(userListByByOrgIdData?.loading) ?*/}
                                {/*                    <div className={"roles-loading"}>*/}
                                {/*                        <Skeleton height={40}/>*/}
                                {/*                    </div> :*/}
                                {/*                    <>*/}
                                {/*                        <Select*/}
                                {/*                            className="members-filter-role react-select-container mt-2"*/}
                                {/*                            classNamePrefix="react-select"*/}
                                {/*                            isDisabled={userListByByOrgIdData?.loading || userListByByOrgIdData?.data?.items===undefined}*/}
                                {/*                            value={selectUser}*/}
                                {/*                            isClearable={true}*/}
                                {/*                            required={true}*/}
                                {/*                            options={createOptionListForSelectTag(userListByByOrgIdData?.data?.items, "firstName", "uid")}*/}
                                {/*                            onChange={(selectData) => {*/}
                                {/*                                setUserSelect(selectData)*/}
                                {/*                            }}*/}
                                {/*                            placeholder={"Members..."}/>*/}
                                {/*                    </>*/}
                                {/*            }*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*}*/}

                                <div className={"row custom-report mediumFontSize"}>
                                    <div className={"col-xl-6"}>
                                        <div className="form-group mt-1 report-title">
                                            <label className={"mb-2"}>Report Title</label>
                                            <input placeholder={"Enter title"} value={reportName}
                                                   className={"form-control"} onChange={(e) => {
                                                setReportName(e.target.value)

                                            }}/>
                                        </div>
                                    </div>
                                    <div className={"col-xl-6"}>
                                        <div className="form-group mt-1 report-description">
                                            <label className={"mb-2"}>Report Description</label>
                                            <textarea value={reportDescription} className={"form-control"}
                                                      onChange={(e) => {
                                                          setReportDescription(e.target.value)
                                                      }} placeholder={"Enter Description"} rows={2}/>

                                        </div>
                                    </div>

                                </div>
                                <div className={"report-criteria row mt-3"}>
                                    <div className={"col-xl-7"}>
                                        <label className={"mb-2 text-dark"}>Report Criteria</label>
                                    </div>
                                    <div className={"col-xl-5"}>
                                        <label className={" mb-1 text-dark"}>Prepare Graph And Date Range</label>
                                    </div>
                                </div>
                                <div className={"border-customize"}/>
                                <div className={"row"}>
                                    <div className={"col-xl-7 custom-border"}>
                                        {
                                            selectOperand?.length > 0 && selectOperand?.map((data, ind) => {

                                                return (
                                                    <>
                                                        <div className={"selected-card"}>
                                                            <div className={"graph-select"}>
                                                                <div className="form-group mt-1">
                                                                    <label className={"mb-2"}>Select Interest</label>
                                                                    <select className={"form-select"}
                                                                            value={selectOperand[ind].selectOperand}
                                                                            onChange={(e) => {
                                                                                setSelectOperand((prevSelectOperand) =>
                                                                                    prevSelectOperand.map((item, index) => {
                                                                                        if (index === ind) {
                                                                                            return {
                                                                                                ...item,
                                                                                                selectOperand: e.target.value,
                                                                                                reportCriteria: reportFilters
                                                                                            };
                                                                                        }
                                                                                        return item;
                                                                                    })
                                                                                );
                                                                            }}>
                                                                        <option value={''} selected>Select Interest</option>
                                                                        {CustomReportCriteria.length > 0 && CustomReportCriteria?.map((cur) => {
                                                                            return (
                                                                                <option
                                                                                    value={cur.value}>{cur.name}</option>
                                                                            )
                                                                        })}

                                                                    </select>

                                                                    {
                                                                        selectOperand.length > 1 &&
                                                                        <div className={"add-operand-delete"}
                                                                             onClick={() => {
                                                                                 handleDeleteOperand(ind)
                                                                             }
                                                                             }>
                                                                            <FaTrash/>
                                                                        </div>
                                                                    }
                                                                </div>


                                                            </div>

                                                            {selectOperand[ind].selectOperand === "Attempt Reflection" &&

                                                                <>
                                                                    {selectOperand[ind].reportCriteria?.map((reportCur, index) => {
                                                                        return (
                                                                            <>
                                                                                <div className={"report-interest mt-3"}>
                                                                                    <select className={"form-select"}
                                                                                            value={reportCur?.reportOperand}
                                                                                            name={"reportOperand"}
                                                                                            onChange={(e) => {
                                                                                                handleValueChange(e, index, ind)
                                                                                            }}>
                                                                                        <option value={''} selected> Select
                                                                                            Filter
                                                                                        </option>
                                                                                        {ReportFiled.length > 0 && ReportFiled.map((cur) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <option
                                                                                                        value={cur.value}> {cur.name}</option>
                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                        }
                                                                                    </select>

                                                                                    {reportCur.reportOperand !== "status" && reportCur.reportOperand !== "RefSelection" && (
                                                                                        <select
                                                                                            className={"form-select ms-2"}
                                                                                            name={"reportCondition"}
                                                                                            value={reportCur.reportCondition}
                                                                                            onChange={(e) => {
                                                                                                handleValueChange(e, index, ind);
                                                                                            }}
                                                                                        >
                                                                                            <option value={''}
                                                                                                    selected> Select
                                                                                                Condition
                                                                                            </option>
                                                                                            {getFilterOperatorsReports(reportCur?.reportOperand)?.length > 0 && getFilterOperatorsReports(reportCur?.reportOperand)?.map((cur) => (
                                                                                                <option key={cur?.value}
                                                                                                        value={cur?.value}>{cur?.name}</option>
                                                                                            ))}
                                                                                        </select>
                                                                                    )}


                                                                                    {showValueField(reportCur.reportOperand, reportCur.reportCondition, reportCur, index, reportFilters, setReportFilters, surveyDropDownList, userDropDownList, selectOperand, setSelectOperand, ind)}

                                                                                    {
                                                                                        selectOperand[ind].reportCriteria?.length - 1 === index &&
                                                                                        <div className={"report_add_btn"}>
                                                                                            <FaPlusCircle onClick={() => {
                                                                                                handleNewFilter(ind)
                                                                                            }}/>
                                                                                        </div>
                                                                                    }

                                                                                    {selectOperand[ind].reportCriteria?.length > 1 &&
                                                                                        <div className={"filter-data"}
                                                                                             onClick={() => {
                                                                                                 deleteAction(index, ind)
                                                                                             }}>
                                                                                            <FaTrash/>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })}

                                                                </>

                                                            }
                                                            {selectOperand[ind].selectOperand === "Users" &&
                                                                <>
                                                                    {selectOperand[ind].reportCriteria?.map((reportCur, index) => {
                                                                        return (
                                                                            <>
                                                                                <div className={"report-interest mt-3"}>
                                                                                    <select className={"form-select"}
                                                                                            value={reportCur?.reportOperand}
                                                                                            name={"reportOperand"}
                                                                                            onChange={(e) => {
                                                                                                handleValueChange(e, index, ind)
                                                                                            }}>
                                                                                        <option value={''} selected> Select
                                                                                            Filter
                                                                                        </option>
                                                                                        {UserFields.length > 0 && UserFields.map((cur) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <option
                                                                                                        value={cur.value}> {cur.name}</option>
                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                        }
                                                                                    </select>

                                                                                    {
                                                                                        reportCur.reportOperand !== "roles" && reportCur.reportOperand !== "UserSelection" &&
                                                                                        <select
                                                                                            className={"form-select ms-2"}
                                                                                            name={"reportCondition"}
                                                                                            value={reportCur.reportCondition}
                                                                                            onChange={(e) => {
                                                                                                handleValueChange(e, index, ind)
                                                                                            }}>
                                                                                            <option value={''}
                                                                                                    selected> Select
                                                                                                Condition
                                                                                            </option>
                                                                                            {
                                                                                                getFilterOperators(reportCur.reportOperand)?.length > 0 && getFilterOperators(reportCur.reportOperand)?.map((cur) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <option
                                                                                                                value={cur.value}>{cur.name}</option>
                                                                                                        </>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </select>
                                                                                    }

                                                                                    {showValueField(reportCur.reportOperand, reportCur.reportCondition, reportCur, index, reportFilters, setReportFilters, surveyDropDownList, userDropDownList, selectOperand, setSelectOperand, ind)}

                                                                                    {
                                                                                        selectOperand[ind].reportCriteria?.length - 1 === index &&
                                                                                        <div className={"report_add_btn"}>
                                                                                            <FaPlusCircle onClick={() => {
                                                                                                handleNewFilter(ind)
                                                                                            }}/>
                                                                                        </div>
                                                                                    }

                                                                                    {selectOperand[ind].reportCriteria?.length > 1 &&
                                                                                        <div className={"filter-data"}
                                                                                             onClick={() => {
                                                                                                 deleteAction(index, ind)
                                                                                             }}>
                                                                                            <FaTrash/>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })}

                                                                </>

                                                            }
                                                        </div>
                                                        {
                                                            selectOperand.length - 1 === ind &&
                                                            <>
                                                                <div className={"add-new-operand"}>
                                                                    <button
                                                                        className={"cmn_background_color mediumFontSize"}
                                                                        style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined || localStorage.getItem("color") !== "undefined" ? localStorage.getItem("color") : ""}}
                                                                        onClick={handleAddNewOperand}>Add Condition
                                                                    </button>
                                                                    {
                                                                        !anonymousUserSelection.disableAnonymousUserCheckBox && !isTeam &&
                                                                        <div className={"d-flex"}>
                                                                            <div
                                                                                className={"mediumFontSize me-2 " + (anonymousUserSelection.disableAnonymousUserCheckBox ? " opacity7" : "")}>Include
                                                                                Anonymous Users
                                                                            </div>
                                                                            <input type="checkbox"
                                                                                   disabled={anonymousUserSelection.disableAnonymousUserCheckBox}
                                                                                   checked={anonymousUserSelection.isAnonymousUserCheckBoxSelected}
                                                                                   onChange={() => {
                                                                                       setAnonymousUserSelection({
                                                                                           ...anonymousUserSelection,
                                                                                           isAnonymousUserCheckBoxSelected: !anonymousUserSelection.isAnonymousUserCheckBoxSelected
                                                                                       })
                                                                                   }}/>
                                                                        </div>
                                                                    }

                                                                </div>

                                                            </>
                                                        }
                                                    </>
                                                )

                                            })}


                                    </div>

                                    <div className={"col-xl-5"}>


                                        <div className={"selected-card"}>
                                            <div className={"graph-select"}>
                                                <div className="form-group mt-1">
                                                    <label className={"mb-2"}>Report Type</label>
                                                    <select value={reportType} className={"form-select"}
                                                            onChange={(e) => {
                                                                setReportType(e.target.value)
                                                            }}>
                                                        <option value={""} selected>Select Report type</option>
                                                        {ReportTypeEnum.length > 0 && ReportTypeEnum.map((cur) => {
                                                            return (
                                                                <option value={cur.value}>{cur.name}</option>
                                                            )
                                                        })}

                                                    </select>
                                                </div>
                                            </div>

                                            {reportType !== "" && (reportType === "LINE_CHART" || reportType === "BAR_CHART" || reportType === "BUBBLE_CHART") &&
                                                <>
                                                    <div className={"row"}>
                                                        <div className={"col-xl-12"}>
                                                            <div className={"reports-x-y-fields"}>
                                                                <div className={"x-field-data"}>
                                                                    <select value={reportVariables?.labelX}
                                                                            className={"form-select"}
                                                                            onChange={(e) => {
                                                                                setReportVariables(prevState => ({
                                                                                    ...prevState,
                                                                                    labelX: e.target.value
                                                                                }));
                                                                            }}>
                                                                        <option value={""} selected>Select
                                                                            X-axis
                                                                            field
                                                                        </option>
                                                                        {ReportFiled.length > 0 && ReportFiled?.filter(cur => cur.name !== "Select Reflections").map((cur) => {
                                                                            return (
                                                                                <option
                                                                                    value={cur.value}>{cur.name}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>

                                                                <div className={"x-title"}>
                                                                    <input value={reportVariables?.titleX}
                                                                           className={"form-control"}
                                                                           onChange={(e) => {
                                                                               setReportVariables(prevState => ({
                                                                                   ...prevState,
                                                                                   titleX: e.target.value
                                                                               }));
                                                                           }} placeholder={"Enter title"}/>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className={"col-xl-12"}>
                                                            <div className={"reports-x-y-fields"}>
                                                                <div className={"y-field-data"}>
                                                                    <select value={reportVariables?.labelY}
                                                                            className={"form-select"}
                                                                            onChange={(e) => {
                                                                                setReportVariables(prevState => ({
                                                                                    ...prevState,
                                                                                    labelY: e.target.value
                                                                                }));
                                                                            }}>
                                                                        <option value={""} selected>Select
                                                                            Y-axis
                                                                            field
                                                                        </option>
                                                                        {filterReflectionFields(reportVariables?.labelX)?.length > 0 && filterReflectionFields(reportVariables?.labelX)?.map((cur) => {
                                                                            return (
                                                                                <option
                                                                                    value={cur.value}>{cur.name}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                                <div className={"y-title"}>
                                                                    <input value={reportVariables?.titleY}
                                                                           className={"form-control"}
                                                                           onChange={(e) => {
                                                                               setReportVariables(prevState => ({
                                                                                   ...prevState,
                                                                                   titleY: e.target.value
                                                                               }));
                                                                           }} placeholder={"Enter title"}/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </>
                                            }
                                        </div>

                                        {
                                            reportType !== "" &&
                                            <>
                                                <div className={"selected-card"}>
                                                    <div className={"select-period"}>
                                                        <Select
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            options={reportPeriod}
                                                            value={selectedReportPeriod}
                                                            onChange={(selectedReportPeriod) => {
                                                                setSelectedReportPeriod(selectedReportPeriod)
                                                                if (selectedReportPeriod.value !== "CUSTOM") {
                                                                    const period = selectedReportPeriod.value
                                                                    setReportSearchQuery({
                                                                        ...reportSearchQuery,
                                                                        createdDateRange: {
                                                                            period: selectedReportPeriod.value,
                                                                            startDate: computeBackDate(period === "WEEKLY" ? 6 : period === "MONTHLY" ? 30 : 364),
                                                                            endDate: new Date()
                                                                        }
                                                                    })
                                                                }
                                                            }}
                                                        />

                                                    </div>

                                                    <div className={"date-range-class"}>
                                                        <div className={"fromDate"}>
                                                            <DatePicker placeholderText="From: dd-mm-yyyy"
                                                                        dateFormat={"dd-MM-yyyy"}
                                                                        readOnly={selectedReportPeriod?.value !== "CUSTOM"}
                                                                        selected={reportSearchQuery?.createdDateRange?.startDate || ""}
                                                                        className={"search-by-survey-name survey-name-search " + (selectedReportPeriod?.value !== "CUSTOM" ? " disable-datepicker" : "")}
                                                                        maxDate={reportSearchQuery?.createdDateRange?.endDate || new Date()}
                                                                        onChange={(date) => {
                                                                            if (selectedReportPeriod?.value === "CUSTOM") {
                                                                                setReportSearchQuery({
                                                                                    ...reportSearchQuery,
                                                                                    createdDateRange: applyValidDateRange({
                                                                                        period: selectedReportPeriod?.value,
                                                                                        startDate: date,
                                                                                        endDate: reportSearchQuery?.createdDateRange?.endDate || null
                                                                                    })
                                                                                })
                                                                            }
                                                                        }}

                                                            />

                                                        </div>
                                                        <div className={"toDate"}>
                                                            <DatePicker placeholderText="From: dd-mm-yyyy"
                                                                        dateFormat={"dd-MM-yyyy"}
                                                                        selected={reportSearchQuery?.createdDateRange?.endDate || ""}
                                                                        minDate={reportSearchQuery?.createdDateRange?.startDate || ""}
                                                                        readOnly={selectedReportPeriod?.value !== "CUSTOM"}
                                                                        className={"search-by-survey-name survey-name-search user-join-toDate  " + (selectedReportPeriod?.value !== "CUSTOM" ? " disable-datepicker" : "")}
                                                                        maxDate={new Date()}
                                                                        onChange={(date) => {
                                                                            if (selectedReportPeriod?.value === "CUSTOM") {
                                                                                setReportSearchQuery({
                                                                                    ...reportSearchQuery,
                                                                                    createdDateRange: applyValidDateRange({
                                                                                        period: selectedReportPeriod?.value,
                                                                                        startDate: reportSearchQuery?.createdDateRange?.startDate || null,
                                                                                        endDate: date
                                                                                    })
                                                                                })
                                                                            }
                                                                        }}

                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                        }

                                    </div>
                                </div>


                            </div>

                            {
                                isTeam &&
                                <div className={"team-report-info"}>*This report is only generated for the attempts made
                                    within the team</div>
                            }
                        </Modal.Body>


                    </Modal.Body>


                    <Modal.Footer className="modal_footer">
                        <button className="modal_cancel_btn cmn_modal_btn mediumFontSize"
                                onClick={handleClose}>Cancel
                        </button>
                        <button
                            style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined || localStorage.getItem("color") !== "undefined" ? localStorage.getItem("color") : ""}}
                            type={"submit"}
                            className="modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize">
                            {reportDetails === null ? " Submit " : " Update "}
                            {
                                (createCustomReportData?.loading || updateCustomReportData?.loading) &&
                                <TailSpinLoader/>
                            }
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )

}
export default CustomReportModal;
