import "../layout/UserProfileWindow.css"
import React, {useEffect, useRef} from "react";
import {encryptData, getDataFromLocalStorage, getInitials, isUnauthorized, toString} from "../../helpers/utils";
import {useDispatch, useSelector} from "react-redux";
import ProfilePicPlaceHolder from "../commons/ProfilePicPlaceHolder";
import jwtDecode from "jwt-decode";
import {refreshToken} from "../../reduxStore/usersSlices/userSlice";
import {useNavigate} from "react-router-dom";
import {MdOutlineLogout} from "react-icons/md";
import {toast} from "react-toastify";

const UserProfileWindow = ({profilePicBackGroundColor, setShow}) => {
    const divRef1 = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentRole = jwtDecode(getDataFromLocalStorage("token"))?.roles[0]
    const fullName = useSelector(state => state.globalKeys.loggedInUserFullName)
    const userInfo = getDataFromLocalStorage("loggedInUserInfo")
    const refreshTokenData = useSelector(state => state.users.refreshTokenReducer)
    useEffect(() => {
        const handleClickOutside = event => {
            if ((divRef1.current && !divRef1.current.contains(event.target)) &&
                event.target.id !== "userProfileWindowButton") {
                // Action to perform when clicked outside of the div
                setShow(false)

            }
        };

        // Add event listener to the document when the component mounts
        document.addEventListener('click', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    const handleRoleChange = (switchTo) => {
        dispatch(refreshToken({
            userId: jwtDecode(getDataFromLocalStorage("token")).userid,
            roleName: switchTo
        })).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            } else if (res.meta.requestStatus === "fulfilled") {
                localStorage.removeItem("token")
                localStorage.setItem('token', encryptData(res.payload.data));
                navigate("/")
            }

        })

    }
    function logout() {
        localStorage.clear();
        toast.dismiss();
        navigate("/")
    }
    return (
        <>
            {
                <div ref={divRef1} className={"add-reflection-window-outer user-profile-window-outer text-center"}>
                    <h6 className={"mediumFontSize user-profile-email"}>{userInfo.email}  </h6>
                    <div className={"me-4 user-profile-window-profile-placeholder-outer mt-2"}>
                        <ProfilePicPlaceHolder backColor={profilePicBackGroundColor} letters={getInitials(fullName)}/>
                    </div>
                    <h5 className={"mediumFontSize hi-user-txt mb-3 mt-1"}>Hi, {userInfo.firstName} !</h5>
                    {/*{*/}
                    {/*    getDataFromLocalStorage("loggedInUserInfo")?.roles?.map(role => role.name).includes(Roles.PULSE_MANAGER)*/}
                    {/*    && <button disabled={refreshTokenData?.loading} onClick={() => {*/}
                    {/*        handleRoleChange(currentRole === Roles.PULSE_MANAGER ? otherRole :Roles.PULSE_MANAGER)*/}
                    {/*    }} className={" cmn_background_color switch-role-btn  mt-1"}>Switch*/}
                    {/*        To {currentRole === Roles.PULSE_MANAGER ? toString(otherRole) : toString(Roles.PULSE_MANAGER)}*/}

                    {/*        {*/}
                    {/*            refreshTokenData?.loading && <TailSpinLoader></TailSpinLoader>*/}
                    {/*        }*/}
                    {/*    </button>*/}
                    {/*}*/}
                    <button onClick={logout} className={"log-out-button mt-1"}> <MdOutlineLogout className={"logout-icon me-1"}/> <span>Logout</span></button>


                </div>

            }
        </>
    );
}
export default UserProfileWindow