import {Link} from "react-router-dom";
import './SignUp.css'
import logo from '../../../images/Union.png'
import success from '../../../images/success_logo.png'

const SignedUpSuccessfully = () => {
    return (
        <>
            <div className={"container_box pt-5 pb-5"}>

                <div className="signup_success cmn_outer m-auto">
                    <div className={'success_image'}>
                        <img src={success} alt=""/>
                    </div>
                    <div className="log_image">
                        <img src={logo} alt=""/>
                    </div>
                    <div className='success_content'>
                        <h3>Sign Up Successfully!</h3>
                        <p>We've just sent a link to the email address you provided during registration. Kindly check your inbox to set up a password for your new account</p>
                        <Link className="cmn_btn cmn_background_color mediumFontSize cmn_btn d-inline-block"
                              to={"/"}>Okay</Link>
                    </div>
                </div>
            </div>

        </>
    );
}
export default SignedUpSuccessfully;