import UserInfo from "../layout/UserInfo";
import React, {useEffect, useState} from "react";
import "./Campaigns.css"
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import Select from 'react-select';
import {useNavigate, useParams} from "react-router-dom";
import {
    createOptionListForSelectTag, formatDateTimeForSelectedTimeZoneId,
    handleApiResponse,
    isUnauthorized,
    isValidCreateCampaignQuery, showToast, toString
} from "../../helpers/utils";
import {useDispatch, useSelector} from "react-redux";
import {allTimezones, useTimezoneSelect} from "react-timezone-select";
import {getPublishSurveys, getSurveysByCriteria} from "../../reduxStore/surveySlices/surveySlices";
import {

    getCustomReportsByOrgIdAndCreator
} from "../../reduxStore/CustomReportSlices/customReport";
import {findCampaignById, updateCampaign} from "../../reduxStore/campaignSlices/campaignSlice";
import {
    CampaignDeliveryMethod,
    CampaignStatus,
    CampaignType,
    DefaultReports
} from "../../helpers/constants";
import CreatableSelect from "react-select/creatable";
import {resetReducers} from "../../reduxStore/globalKeysSlice/globalKeysSlice";
import {Loader, TailSpinLoader} from "../layout/Loader";

const EditCampaign = () => {
    const {campaignId} = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const defaultReports = DefaultReports?.map(cur => cur.name)
    const orgPreferenceColor = localStorage.getItem("color") !== null && localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : "#fc6620"


    const tagOption = (label) => ({
        label: label,
        value: label,
    });
    const labelStyle = "altName"
    const timezones = {
        ...allTimezones
    }
    const {options, parseTimezone} = useTimezoneSelect({labelStyle, timezones})
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'], // Only include bold, italic, and underline
            [{'size': ['small', false, 'large', 'huge']}],
        ],
    };
    const formats = ['bold', 'italic', 'underline'];

    const updateCampaignApi = useSelector(state => state.campaign.updateCampaignReducer)
    const campaignByIdApi = useSelector(state => state.campaign.findCampaignByIdReducer)
    const surveysApi = useSelector(state => state.survey.getSurveysByCriteriaReducer)
    const reportsApi = useSelector(state => state.customReport.getCustomReportsByOrgIdAndCreatorReducer)

    const [updateCampaignData, setUpdateCampaignData] = useState({
        name: "",
        type: null,
        relationalSelectedItem: null,
        orgId: null,
        deliveryMethod: null,
        recipients: [],
        message: "",
        subject: "",
        scheduledOn: "",
        status: null,
        selectedTimeZone: null
    })
    const [recipientStateValue, setRecipientStateValue] = useState('');

    useEffect(() => {
        if (campaignId) {
            dispatch(findCampaignById(campaignId)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }
        return () => {
            dispatch(resetReducers({sliceNames: ["findCampaignByIdReducer"]}))
        }
    }, [campaignId])
    useEffect(() => {
        if (!campaignByIdApi?.loading && campaignByIdApi?.data !== null && campaignByIdApi?.data !== undefined) {
            const deliveryMethod = CampaignDeliveryMethod?.filter(cur => cur.value === campaignByIdApi.data.deliveryMethod[0])[0]
            setUpdateCampaignData({
                ...updateCampaignData,
                name: campaignByIdApi.data.name,
                type: {label: toString(campaignByIdApi.data.type), value: campaignByIdApi.data.type},
                relationalSelectedItem: null,
                orgId: campaignByIdApi.data.orgId,
                deliveryMethod: {label: deliveryMethod.name, value: deliveryMethod.value},
                scheduledOn: new Date(campaignByIdApi.data.scheduledOn).toLocaleString("sv-SE", {
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    hour12: false,
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                }).replace(" ", "T"),
                selectedTimeZone: parseTimezone(campaignByIdApi.data.selectedTimeZone),
                recipients: campaignByIdApi.data.recipients?.map((cur) => {
                    return {label: cur, value: cur}
                }),
                status: {label: toString(campaignByIdApi.data.status), value: campaignByIdApi.data.status},
                message: campaignByIdApi.data.message,
                subject: campaignByIdApi.data.subject,

            })
        }
    }, [campaignByIdApi]);
    useEffect(() => {
        if(updateCampaignData?.type && updateCampaignData?.type?.value === "REFLECTION"){
            dispatch(getSurveysByCriteria({
                orgId: updateCampaignData?.orgId,
                status: ["PUBLISHED"],
                isSysReflectionRequired:true,
                isAssignedReflectionRequired:false,
            })).then((res)=>{
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                const onSuccess = () => {
                    const surveyInfo = res.payload.data?.filter(survey => survey?.surveyId === campaignByIdApi?.data?.relationalId)?.[0] || null
                    setUpdateCampaignData({
                        ...updateCampaignData,
                        relationalSelectedItem: surveyInfo ? {label: surveyInfo.name, value: surveyInfo.surveyId} : null
                    })
                }
                updateCampaignData.relationalSelectedItem === null && handleApiResponse(res, onSuccess)
            })

        }
    }, [updateCampaignData.type]);

    useEffect(() => {
        if (updateCampaignData?.type && updateCampaignData?.type?.value === "REPORT") {
            const query = {
                orgId: updateCampaignData?.orgId,
                createdBy: campaignByIdApi?.data?.createdBy,
            }
            dispatch(getCustomReportsByOrgIdAndCreator(query)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                const onSuccess = () => {
                    if (campaignByIdApi?.data?.relationalId == null && defaultReports.includes(campaignByIdApi?.data?.relationalName)) {
                        let relationalSelectedItem = {
                            label: campaignByIdApi?.data?.relationalName,
                            value: DefaultReports?.filter(cur => cur.name === campaignByIdApi?.data?.relationalName)[0]?.value
                        }
                        setUpdateCampaignData({...updateCampaignData, relationalSelectedItem: relationalSelectedItem})
                    } else {
                        const reportInfo = res.payload.data?.filter(report => report?.id === campaignByIdApi?.data?.relationalId)?.[0] || null
                        setUpdateCampaignData({
                            ...updateCampaignData,
                            relationalSelectedItem: reportInfo ? {
                                label: reportInfo.reportName,
                                value: reportInfo.id
                            } : null
                        })
                    }
                }
                updateCampaignData.relationalSelectedItem === null && handleApiResponse(res, onSuccess)
            })
        }
    }, [updateCampaignData.type]);


    const handleKeyDown = (event) => {
        if (!recipientStateValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                saveValue();
                event.preventDefault();
        }
    };

    function saveValue() {
        if (!recipientStateValue) return;
        const emails = recipientStateValue.split(/[ ,]+/).filter(Boolean);
        const existingEmailsSet = new Set(updateCampaignData.recipients.map(item => item.value));
        const newEmails = emails.filter(email => {
            if (existingEmailsSet.has(email)) {
                showToast(`Email already exists, please try unique one!`, "info")
                return false;
            }
            return true;
        }).map(tagOption);

        if (newEmails.length > 0) {
            setUpdateCampaignData({
                ...updateCampaignData,
                recipients: [...updateCampaignData.recipients, ...newEmails]
            })
        }
        setRecipientStateValue('');
    }

    const handleOnChange = (e) => {
        const {name, value} = e.target
        setUpdateCampaignData({
            ...updateCampaignData,
            [name]: value,
            // If type is de-selected it should empty the relationalSelectedItem field too
            ...(name === "type" ? {"relationalSelectedItem": null} : {})
        })
    }
    const handleUpdateCampaign = () => {
        let queryToSubmit = {
            ...updateCampaignData,
            campaignId: campaignByIdApi.data.campaignId,
            type: updateCampaignData?.type?.value,
            status: updateCampaignData?.status?.value,
            deliveryMethod: [updateCampaignData?.deliveryMethod?.value],
            relationalId: defaultReports.includes(updateCampaignData?.relationalSelectedItem?.label) ? null : updateCampaignData?.relationalSelectedItem?.value,
            relationalName: updateCampaignData?.relationalSelectedItem?.label,
            selectedTimeZone: updateCampaignData?.selectedTimeZone?.value,
            recipients: updateCampaignData?.recipients?.map(cur => cur?.value) || [],
        }
        if (isValidCreateCampaignQuery(queryToSubmit)) {
            queryToSubmit = {
                ...queryToSubmit,
                scheduledOn: formatDateTimeForSelectedTimeZoneId(queryToSubmit.scheduledOn.split("T")[0], queryToSubmit.scheduledOn.split("T")[1], queryToSubmit.selectedTimeZone)
            }
            dispatch(updateCampaign(queryToSubmit)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                handleApiResponse(res, () => {
                    navigate(-1)
                })
            })
        }
    }

    return campaignByIdApi?.loading || campaignByIdApi?.data === null || campaignByIdApi?.data === undefined ?
        <Loader/> :
        <section>
            <div className="cmn_header">
                <h2 className="mediumFontSize  org-heading m-0">Edit Campaign</h2>
                <UserInfo/>
            </div>
            <div className="row main_content">
                <div className={"col-md-12 p-0"}>
                    <div className={"roles_outer"}>
                        <section className=" create_campaign_inner_content pt-3 pb-3  sub-org-container-box">
                            <div className="row">
                                <div className="col-lg-6 col-sm-12 col-md-12">
                                    <div className="cmn_campaign_Wrapper">
                                        <h3 className="cmn_sub_heading mediumFontSize">Campaign Configuration</h3>
                                        <div className="form_content_outer mb-3">
                                            <label>*Campaign Name</label>
                                            <input
                                                name={"name"}
                                                className='form-control'
                                                value={updateCampaignData?.name}
                                                onChange={handleOnChange}
                                                type="text"
                                            />
                                        </div>
                                        <div className="form_content_outer mb-3">
                                            <label>*Provider</label>
                                            <Select
                                                options={createOptionListForSelectTag(CampaignDeliveryMethod, "name", "value")}
                                                value={updateCampaignData?.deliveryMethod}
                                                onChange={(selectedValue) => {
                                                    if (updateCampaignData?.deliveryMethod?.value !== selectedValue?.value) {
                                                        handleOnChange({
                                                            target: {
                                                                name: "deliveryMethod",
                                                                value: selectedValue
                                                            }
                                                        })
                                                    }
                                                }}/>
                                        </div>
                                        <div className="form_content_outer mb-3">
                                            <label>*Campaign Type</label>
                                            <Select
                                                options={createOptionListForSelectTag(CampaignType, "name", "value")}
                                                value={updateCampaignData?.type}
                                                onChange={(selectedValue) => {
                                                    if (updateCampaignData?.type?.value !== selectedValue?.value) {
                                                        handleOnChange({target: {name: "type", value: selectedValue}})
                                                    }
                                                }}
                                                isClearable={true}/>
                                        </div>
                                        <div className="form_content_outer mb-3">
                                            {
                                                updateCampaignData?.type === null &&
                                                <>
                                                    <label>*Select Reflection/Report </label>
                                                    <Select isDisabled={true}/>
                                                </>
                                            }
                                            {
                                                updateCampaignData?.type?.value === "REFLECTION" &&
                                                <>
                                                    <label>*Select Reflection </label>
                                                    <Select
                                                        options={createOptionListForSelectTag(surveysApi?.data, "name", "surveyId")}
                                                        value={updateCampaignData?.relationalSelectedItem}
                                                        onChange={(selectedValue) => {
                                                            if (updateCampaignData?.type?.value !== selectedValue?.value) {
                                                                handleOnChange({
                                                                    target: {
                                                                        name: "relationalSelectedItem",
                                                                        value: selectedValue
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                        isDisabled={surveysApi?.loading}
                                                        isClearable={true}/>
                                                </>

                                            }
                                            {
                                                updateCampaignData?.type?.value === "REPORT" &&
                                                <>
                                                    <label>*Select Report </label>
                                                    <Select
                                                        isDisabled={reportsApi?.loading}
                                                        options={[...createOptionListForSelectTag(DefaultReports, "name", "value"), ...createOptionListForSelectTag(reportsApi?.data, "reportName", "id")]}
                                                        value={updateCampaignData?.relationalSelectedItem}
                                                        onChange={(selectedValue) => {
                                                            if (updateCampaignData?.type?.value !== selectedValue?.value) {
                                                                handleOnChange({
                                                                    target: {
                                                                        name: "relationalSelectedItem",
                                                                        value: selectedValue
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                        isClearable={true}/>
                                                </>
                                            }
                                        </div>
                                        <h3 className="cmn_sub_heading">Scheduling</h3>
                                        <div className="form_content_outer mb-3">
                                            <div className="d-flex schedule_wrapper_box">
                                                <div className="flex-grow-1">
                                                    <label>*Select Date and Time </label>
                                                    <div className="d-flex gap-2 datetime_wrapper">
                                                        <input
                                                            min={new Date().toISOString().slice(0, 16)}
                                                            value={updateCampaignData?.scheduledOn}
                                                            className={"form-control"}
                                                            type="datetime-local"
                                                            name="scheduledOn"
                                                            onChange={handleOnChange}
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="form_content_outer mb-3">
                                            <label>*Select time zone </label>
                                            <Select
                                                className="members-filter-role react-select-container"
                                                classNamePrefix="react-select"
                                                options={options}
                                                value={updateCampaignData?.selectedTimeZone}
                                                onChange={(selectedValue) => {
                                                    if (updateCampaignData?.selectedTimeZone?.value !== selectedValue?.value) {
                                                        handleOnChange({
                                                            target: {
                                                                name: "selectedTimeZone",
                                                                value: selectedValue
                                                            }
                                                        })
                                                    }
                                                }}/>

                                        </div>
                                        <div className="form_content_outer mb-3">
                                            <label>*Campaign Status</label>
                                            <Select
                                                options={createOptionListForSelectTag(CampaignStatus, "name", "value")}
                                                value={updateCampaignData?.status}
                                                onChange={(selectedValue) => {
                                                    if (updateCampaignData?.type?.value !== selectedValue?.value) {
                                                        handleOnChange({target: {name: "status", value: selectedValue}})
                                                    }
                                                }}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12 col-md-12">
                                    <div className="cmn_campaign_Wrapper">
                                        <h3 className="cmn_sub_heading mediumFontSize">Message</h3>
                                        <div className="form_content_outer mb-3">
                                            <label>*Recipients </label>
                                            <CreatableSelect
                                                components={{
                                                    DropdownIndicator: null,
                                                }}
                                                inputValue={recipientStateValue}
                                                autoFocus={updateCampaignData?.recipients?.length !== 0}
                                                isClearable
                                                isMulti
                                                menuIsOpen={false}
                                                onChange={(value) => {
                                                    handleOnChange({target: {name: "recipients", value: value}})
                                                }}
                                                onInputChange={(value) => {
                                                    setRecipientStateValue(value)
                                                }}
                                                onKeyDown={handleKeyDown}
                                                onMenuClose={saveValue}
                                                value={updateCampaignData?.recipients}
                                                isDisabled={false}
                                            />
                                        </div>
                                        <div className="form_content_outer mb-3">
                                            <label>*Subject </label>
                                            <input
                                                className='form-control'
                                                type="text"
                                                name={"subject"}
                                                onChange={handleOnChange}
                                                value={updateCampaignData?.subject}
                                            />
                                        </div>
                                        <div className="custom-quill-editor ">
                                            <ReactQuill
                                                value={updateCampaignData?.message}
                                                onChange={(value) => {
                                                    handleOnChange({target: {name: "message", value: value}})
                                                }}
                                                placeholder="Write your message here..."
                                                className="quill-editor"
                                                modules={modules}
                                                formats={formats}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="next_btnwrapper text-end mt-3">
                                <button
                                    className="ms-2 modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize "
                                    style={{background: orgPreferenceColor}}
                                    disabled={updateCampaignApi?.loading}
                                    onClick={handleUpdateCampaign}>Save
                                    {
                                        updateCampaignApi?.loading && <TailSpinLoader/>
                                    }
                                </button>
                            </div>
                        </section>


                    </div>
                </div>
            </div>
        </section>

}
export default EditCampaign