import {hasPermission, Permissions} from "../../helpers/accessControl";
import {deleteNoteById, searchNotes} from "../../reduxStore/notesSlices/notesSlice";
import {formatDate, getDataFromLocalStorage, isUnauthorized, trim} from "../../helpers/utils";
import Skeleton from "react-loading-skeleton";
import {useEffect, useState} from "react";
import AddOrUpdateNoteModal from "../dashboard/modal/AddOrUpdateNoteModal";
import ConfirmationModel from "./ConfirmationModel";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Pagination from "./Pagination";
import {hexToRgb} from "../../helpers/constants";
import {FiTrash2} from "react-icons/fi";
import {GoPencil} from "react-icons/go";

function ThoughtsSection({isDashBoard = false, memberId,orgId}) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [pageNumber, setPageNumber] = useState(1);
    const notes = useSelector(state => state.notes)
    const [searchKeyword, setSearchKeyword] = useState("")
    const [showUpdateNoteModal, setShowUpdateNoteModal] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [showAddNoteModal, setShowAddNoteModal] = useState(false)
    const [noteId, setNoteId] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [timer, setTimer] = useState(null)
    const [applySearch,setApplySearch]=useState(false)


    const handleDeleteNote = () => {
        if (noteId != null && noteId !== "") {

            dispatch(deleteNoteById({noteId, setNoteId, setShowConfirmationModal})).then((response) => {
                if (isUnauthorized(response)) {
                    navigate("/")
                }
                if (response.meta.requestStatus === "fulfilled") {
                    setSearchKeyword("")
                    if (notes?.getNotesDataReducer?.data?.items?.length === 1) {
                        setPageNumber(pageNumber - 1)
                    } else {
                        dispatch(isDashBoard === true ? searchNotes({
                            page: pageNumber,
                            note: "",
                            pageSize: 10
                        }) : searchNotes({
                            page: pageNumber,
                            memberId: memberId
                        })).then((res) => {
                            if (isUnauthorized(res)) {
                                navigate("/")
                            }
                        });

                    }
                }
            });

        }
    }

    const handleShowText = (index) => {
        // Create a copy of selectedItems array
        const updatedSelectedItems = [...selectedItems];
        // Update the state value for the clicked index to true
        // updatedSelectedItems[index] = true;
        updatedSelectedItems[index] = !updatedSelectedItems[index];
        setSelectedItems(updatedSelectedItems);
    };


    useEffect(() => {
        if(applySearch){
            if (timer) {
                clearTimeout(timer)
            }
            setTimer(setTimeout(() => {
                callApiFunction()
            }, 1000))
        }

    }, [searchKeyword])


    useEffect(() => {
        callApiFunction();
        setApplySearch(true)
    }, [pageNumber])

    const callApiFunction = () => {
        if(hasPermission([Permissions.NOTE_READ],true)){
            dispatch(isDashBoard === true ? searchNotes({
                page: pageNumber,
                note: searchKeyword,
                pageSize: 10
            }) : searchNotes({
                page: pageNumber,
                memberId: memberId,
                note: searchKeyword
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }


    };

    return (
        <>

            {hasPermission([Permissions.NOTE_READ], true) &&
                <div className={isDashBoard === true ? "col-md-12 col-lg-12 col-sm-12 ps-0 p-0" : "col-12 ps-0 p-0"}>

                    <div className={isDashBoard === true ? "box_design2" : "note_section box_design2"}>

                        <div className={"d-flex thought-heading align-items-center " + (isDashBoard ? "mt-2 mb-3" : "")}>
                            {
                                isDashBoard ? <h4 className='mediumFontSize mb-0 custom_font'>Thoughts</h4> :
                                    <h5 className='mediumFontSize mb-3'>Thoughts</h5>
                            }


                            <div className="add_new_button notes_cta">
                                <form>
                                    <div className={"search_list"}>
                                        <input type="text" placeholder={"Search"}
                                               className={isDashBoard === true ? "form-control" : "search_section form-control"}
                                               value={searchKeyword} onChange={(e) => {
                                            setSearchKeyword(e.target.value);
                                        }}/>
                                    </div>
                                </form>
                                {
                                    hasPermission([Permissions.NOTE_CREATE], true) &&
                                    <button style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""} } className='cmn_background_color' onClick={(e) => {
                                        // it should not open add new note modal if update note model is open
                                        if (!showUpdateNoteModal && !showConfirmationModal) {
                                            e.preventDefault();
                                            setShowAddNoteModal(true)
                                        }
                                    }}>
                                        Add Thought
                                    </button>
                                }

                            </div>


                        </div>


                        <div className="card mt-1">
                            {notes.getNotesDataReducer?.loading || notes?.getSearchNotesDataReducer?.loading ? (<>
                                    {[...Array(isDashBoard ? 8 : 4)].map((x, index) => {
                                        return (
                                            <div key={index} className={"card-header"}>
                                                <div className="user_details">
                                                    <div className={"user_nameAnd_email"}>
                                                        <h6><Skeleton width={250} height={40}/>
                                                        </h6>
                                                        <span className={'show_more'}><Skeleton
                                                            width={200}/></span>
                                                        <p></p>
                                                    </div>
                                                    <div className="d-flex">
                                                        <i className='fa'><Skeleton width={20}/></i>
                                                        <i className="fa"><Skeleton width={20}/></i>

                                                    </div>
                                                </div>
                                            </div>

                                        )
                                    })}
                                </>


                            ) : (
                                // In case of Search if length is 0 show "No Result Found"

                                notes?.getNotesDataReducer?.data?.items?.length === 0 ?
                                    <h6 className={" m-2 no-thought-text"} style={{color:localStorage.getItem("color"),backgroundColor:hexToRgb(localStorage.getItem("color"),0.1),background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? hexToRgb(localStorage.getItem("color"),0.1):""}}>No thought to display</h6> : (

                                        notes.getNotesDataReducer?.data?.items?.map((note, index) => {
                                            return (
                                                <div key={index} className={"card-header thoughts-main"}>

                                                    <div
                                                        className={"thought-name-and-date"}>{note.userData.userId === getDataFromLocalStorage("loggedInUserInfo")?.userId ? 'Self' : trim(note.userData.firstName, 50)}</div>

                                                    <div className="user_details">

                                                        <div className={"user_nameAnd_email"}>
                                                            <h6>{(note.note.length > 120 && !selectedItems[index]) ? note.note.substring(0, 120) + "..." : note.note}</h6>
                                                            {note.note.length > 120 &&
                                                                <span className={'show_more'}
                                                                      onClick={() => handleShowText(index)}>{!selectedItems[index] ? 'show more' : "show less"}</span>}


                                                        </div>
                                                        <div className="d-flex thoughts-Icon">
                                                            {
                                                                hasPermission([Permissions.NOTE_WRITE], true) &&

                                                                <GoPencil className='edit-note  me-2'    onClick={(e) => {
                                                                    if (!showAddNoteModal && !showConfirmationModal) {
                                                                        e.preventDefault();
                                                                        setNoteId(note.noteId);
                                                                        setShowUpdateNoteModal(true)
                                                                    }
                                                                }}/>

                                                            }
                                                            {
                                                                hasPermission([Permissions.NOTE_DELETE], true) &&

                                                                <FiTrash2  className={" delete-note "}  onClick={() => {
                                                                    if (!showAddNoteModal && !showUpdateNoteModal) {
                                                                        setNoteId(note.noteId);
                                                                        setShowConfirmationModal(true)
                                                                    }

                                                                }} />

                                                            }


                                                        </div>
                                                    </div>
                                                    <div className={"thought-name-and-date-outer mt-1"}>
                                                        <div
                                                            className={"thought-name-and-date"}>{formatDate(note.createdAt)}</div>
                                                    </div>

                                                </div>
                                            );

                                        })))}


                        </div>
                        {
                            !notes.getSearchNotesDataReducer?.loading && notes?.getNotesDataReducer?.data && notes.getNotesDataReducer?.data?.totalPage > 1 &&
                            <Pagination totalPages={notes?.getNotesDataReducer?.data?.totalPage} pageNumber={pageNumber}
                                        setPageNumber={setPageNumber}/>
                        }
                    </div>


                </div>
            }

            {
                showAddNoteModal &&
                <AddOrUpdateNoteModal pageNumber={pageNumber} setPageNumber={setPageNumber} type={"Add"}
                                      show={showAddNoteModal}
                                      setShow={setShowAddNoteModal}

                                      setKeyword={setSearchKeyword} memberId={memberId} isDashBoard={isDashBoard}orgId={orgId}/>
            }
            {
                showUpdateNoteModal &&
                <AddOrUpdateNoteModal pageNumber={pageNumber} setPageNumber={setPageNumber} noteId={noteId}
                                      type={"Update"}
                                      show={showUpdateNoteModal}
                                      setKeyword={setSearchKeyword}
                                      setShow={setShowUpdateNoteModal} memberId={memberId} isDashBoard={isDashBoard}/>
            }
            {
                showConfirmationModal &&
                <ConfirmationModel isLoading={notes?.deleteNoteByIdDataReducer?.loading} title={"Delete Thought"}
                                   btnText={"Delete"}
                                   setKeyword={setSearchKeyword} bodyMessage={"Are you sure you want to delete?"}
                                   action={handleDeleteNote} show={showConfirmationModal}
                                   setShow={setShowConfirmationModal}
                                   showUserAcknowledgementCheckBox={false}/>
            }
        </>
    )
}

export default ThoughtsSection;