import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {
    exportReportDetails,
    getReportDetails,
    getReportDetailsBySurveyIdAndSurveyeeId
} from "../../reduxStore/CustomReportSlices/customReport";
import {isUnauthorized, resourceToFile, tableCustomStyling, TableSkeletonLoading} from "../../helpers/utils";
import Skeleton from "react-loading-skeleton";
import DataTable from "react-data-table-component";
import {TailSpinLoader} from "../layout/Loader";
import {shareSurveyAttemptReportAction} from "../../reduxStore/surveySlices/surveySlices";
import ShareReport from "../reports/Modal/ShareReport";
import {Marks} from "../../helpers/constants";
import html2canvas from "html2canvas";
import ReportItem from "./ReportItem";


const ReportDetails = () => {

    const {reportId} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const customReport = useSelector(state => state.customReport)
    const [email, setEmail] = useState([])
    const [reportBlob, setReportBlob] = useState([])

    const [showReportModal, setShowReportModal] = useState(false)
    const [reportShare, isReportShare] = useState(false)

    useEffect(() => {
        if (reportId !== null) {
            dispatch(getReportDetails(reportId)).then((res) => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
            dispatch(getReportDetailsBySurveyIdAndSurveyeeId(reportId)).then((res) => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })

        }
    }, [])

    function handleExport(e) {
        e.preventDefault()
        if (reportId !== null) {
            dispatch(exportReportDetails(reportId)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                resourceToFile(res?.payload, "customReport", ".csv")
            })
        }

    }

    const prepareReports = (customReportId, graphId) => {
        // Export report details as CSV
        dispatch(exportReportDetails(reportId)).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
            let blob = resourceToFile(res?.payload, "customReportCsv", ".csv", true)
            reportBlob.push({blob: blob, name: "customReport.csv"}) // Push the CSV blob into reportBlob array
        })

        // Capture chart/graph as image
        const chartElementKey = document.getElementById(graphId)
        let promises = [];
        if (chartElementKey) {
            promises.push(
                html2canvas(chartElementKey).then((canvas) => {
                    return new Promise((resolve) => {
                        canvas.toBlob((blob) => {
                            let newName = customReport?.getReportDetailsReducer?.data?.reportName + '.png';
                            resolve({blob: blob, name: newName, reportId: customReportId});
                        });
                    });
                })
            );
        }
        Promise.all(promises).then((results) => {
            results.forEach(result => {
                reportBlob.push(result);
            });
        });

    };
    useEffect(() => {
        if (reportShare && reportBlob.length === 2) {
            dispatch(shareSurveyAttemptReportAction({
                email: email.map(c => c?.value),
                files: reportBlob,
                startDate: customReport?.getReportDetailsReducer?.data?.reportPeriod?.startDate.substring(0, 10),
                endDate: customReport?.getReportDetailsReducer?.data?.reportPeriod?.endDate.substring(0, 10)
            })).then((res) => {
                if (res?.payload?.data === 'Success') {
                    setEmail([]);
                    setReportBlob([])
                    setShowReportModal(false)
                    isReportShare(false)

                }

            })
        }
    }, [reportShare]);

    const columns = [
        {
            name: "Survey Name",
            selector: row => row?.surveyName,
            style: {
                fontFamily: 'General Sans Medium',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            }
        },
        {
            name: "User Name",
            selector: row => row?.userName,
            style: {
                fontFamily: 'General Sans Medium',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            }
        },

        {
            name: "Attempted Count",
            selector: row => row?.count,
            style: {
                fontFamily: 'General Sans Medium',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            }
        },
        {
            name: "Overall Result",
            selector: row => Marks[row.riskLevel],
            cell: row => <span title={row?.avgCount}
                               className={" survey-risk-status mx-0 " + (row.riskLevel === "HIGH_RISK" ? "bg-light-red" : row.riskLevel === "LOW_RISK" ? "bg-light-orange" : "bg-light-green")}>
                    {Marks[row.riskLevel]}</span>,
            style: {
                fontFamily: 'General Sans Medium',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            }
        }
    ].filter(Boolean)

    console.log("customReport?.getReportDetailsReducer=====>",customReport?.getReportDetailsReducer)
    console.log("customReport?.getReportDetailsBySurveyIdAndSurveyeeIdReducer=====>",customReport?.getReportDetailsBySurveyIdAndSurveyeeIdReducer)

    return (
        <>
            <section>
                <div className={"row main_content mt-3"}>
                    <div className={"col-xl-6"}>
                        <h2 className={"mediumFontSize"}> {customReport?.getReportDetailsReducer?.data?.reportName ||
                            <Skeleton width={70}/>}</h2>

                    </div>
                    <div className={"col-xl-6"}>
                        <div className={"share-report"}>
                            <button
                                style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                className={"mediumFontSize cmn_background_color"} onClick={(e) => handleExport(e)}><i
                                className="fa fa-file-export me-1"/>Export
                                {!showReportModal && customReport?.exportReportDetailsReducer?.loading && (
                                    <TailSpinLoader/>)}
                            </button>

                            <button
                                style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                className={"mediumFontSize cmn_background_color ms-2"} onClick={() => {

                                prepareReports(null, 'data-report')
                                setShowReportModal(true)
                            }}><i className={"fas  cursor "}>&#xf1e0;</i> Share
                            </button>

                        </div>

                    </div>

                    <div id="data-report">
                        <div className={"display-chart m-auto mt-2 mb-2"}>
                            {
                                customReport?.getReportDetailsReducer?.data !== undefined &&
                                <>
                                    <ReportItem report={customReport?.getReportDetailsReducer?.data}
                                                reportName={customReport?.getReportDetailsReducer?.data?.reportName}/>
                                </>
                            }


                        </div>
                    </div>

                    <div className={"p-2 mt-2"}>

                        <DataTable
                            className={"table_design1 "}
                            persistTableHead={true} responsive={true}
                            customStyles={tableCustomStyling}
                            progressPending={customReport?.getReportDetailsReducer?.loading || customReport?.getReportDetailsBySurveyIdAndSurveyeeIdReducer?.loading}
                            columns={columns}
                            data={customReport?.getReportDetailsBySurveyIdAndSurveyeeIdReducer?.data?.reportResult}
                            noDataComponent={<div className={"no-member-text"}>No Reports Found</div>}
                            progressComponent={<TableSkeletonLoading columns={4} rows={4}/>}
                            sortServer={true}

                        />
                    </div>


                </div>
            </section>

            {showReportModal &&
                <ShareReport show={showReportModal} setShow={setShowReportModal} email={email} setEmail={setEmail}
                             title={"Share Reports"} reportShare={reportShare} isReportShare={isReportShare}/>}

        </>
    )

}
export default ReportDetails;