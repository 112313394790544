import Modal from "react-bootstrap/Modal";
import {useEffect, useState} from "react";
import {Roles} from "../../../helpers/accessControl";
import {getDataFromLocalStorage, isUnauthorized, validateObject} from "../../../helpers/utils";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getAllUsersWithoutPagination} from "../../../reduxStore/usersSlices/userSlice";
import jwtDecode from "jwt-decode";
import {
    createCriteria,
    getAllCriteria,
    getCriteria,
    resetGetTriggerByIdReducer,
    updateCriteria
} from "../../../reduxStore/customEventSlices/customEvent";
import {TailSpinLoader} from "../../layout/Loader";
import PrepareCriteria from "./PrepareCriteria";
import PrepareActions from "./PrepareActions";
import {IoIosClose} from "react-icons/io";

export const CreateTriggerModal = ({show, setShow, eventId, setEventId, fromType}) => {
    const handleClose = () => {
        setShow(false)
        setEventId(null)
    }
    const [selectedValue, setSelectedValue] = useState(0)
    const [operatorType, setOperatorType] = useState("AND");
    const [operandName, setOperandName] = useState(null);
    const [operandDescription, setOperandDescription] = useState(null);
    const [enable, setEnable] = useState(false);
    const [userCriteria, setUserCriteria] = useState([{operandField: "", conditionType: "", operandValue: []}])
    const [actions, setActions] = useState([{actionType: "", actionEvents: []}])
    const [selectOperand, setSelectOperand] = useState([{selectOperand: "", criteria: userCriteria}])
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const customCriteria = useSelector(state => state.customCriteria);

    useEffect(() => {
        dispatch(resetGetTriggerByIdReducer())
        dispatch(getAllUsersWithoutPagination({roles: [Roles.MENTOR,Roles.MANAGER], orgId: jwtDecode(getDataFromLocalStorage("token")).orgId,})).then(res => {
            return res
            if (isUnauthorized(res)) {
                navigate("/")
            }
        })
        if (eventId !== null) {
            dispatch(getCriteria(eventId)).then(response => {
                if (response?.payload?.data !== null) {
                    setOperandDescription(response?.payload?.data?.operandDescription)
                    setOperandName(response?.payload?.data?.operandName)
                    setOperatorType(response?.payload?.data?.operandCondition)
                    setSelectOperand(response?.payload?.data?.operations)
                    setActions(response?.payload?.data?.action)
                    setEnable(response?.payload?.data?.enable)
                }
                if (isUnauthorized(response)) {
                    navigate("/")
                }
            })
        }

    }, [])


    function handleSubmit(e) {
        e.preventDefault()
        const submit = {
            id: eventId,
            operandName: operandName,
            operandDescription: operandDescription,
            operations: selectOperand,
            operandCondition: operatorType,
            action: actions,
            enable: fromType === "Submit" ? true: enable
        }

        const isValid = validateObject(submit)
        if (typeof isValid === "object") {
            return toast.error(isValid.errorMessage, {toastId: "toast"})
        } else {
            if (fromType === "Submit") {
                dispatch(createCriteria(submit)).then(res => {
                    if (isUnauthorized(res)) {
                        navigate("/")
                    }
                    if (res.meta.requestStatus === "fulfilled") {
                        setShow(false)
                        getTriggerDetails()
                    }
                })
            } else {
                dispatch(updateCriteria(submit)).then(res => {
                    if (isUnauthorized(res)) {navigate("/")}
                    if (res.meta.requestStatus === "fulfilled") {
                        setShow(false)
                        getTriggerDetails()
                    }
                })
            }
        }
    }
    function getTriggerDetails(){
        const data = {page: 1, sortBy: "createdAt", sortOrder: "desc", pageSize: 10}
        dispatch(getAllCriteria(data)).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
        });
    }

    return (
        <>
            <Modal className='mt-4' show={show} size={"xl"}
            >
                <Modal.Header className={"d-flex"}>
                    <Modal.Title
                        className='add_member_heading mediumFontSize'>{fromType === "Submit" ? "Create Trigger" : " Update Trigger"}</Modal.Title>

                    <IoIosClose className={"close_btn"} onClick={handleClose} size={24}/>
                </Modal.Header>

                <form onSubmit={handleSubmit}>

                    <Modal.Body className="modal_outer">

                        <Modal.Body className="modal_outer custom-modal-body">

                            <div className={"custom-card-color"}>

                                <div className={"operand-selection pt-0 "}>
                                    <div className={"ps-0 operand-name pt-0"}>
                                        <label className={"mb-1"}>*Trigger Name</label>
                                        <input className={"form-control "} value={operandName} onChange={(e) => {
                                            setOperandName(e.target.value)
                                        }} placeholder={"Enter  name"}/>

                                    </div>
                                    <div className={"operand-description pt-0"}>
                                        <label className={"mb-1"}>Trigger Description</label>
                                        <textarea value={operandDescription} className={"form-control"}
                                                  onChange={(e) => {
                                                      setOperandDescription(e.target.value)
                                                  }} placeholder={"Enter Description"} rows={2}/>
                                    </div>
                                </div>
                                <div className={"row m-0"}>
                                    <div className={"col-xl-7 ps-0"}>
                                        <div className={"first-header"}>
                                            <h5 className={"mediumFontSize"}> Prepare Criteria</h5>

                                            <div className={"op-label"}>
                                                <label>Condition</label>
                                                <select className={"form-select"} value={operatorType}
                                                        onChange={(e) => {
                                                            setOperatorType(e.target.value)
                                                        }}>
                                                    <option value={"AND"} selected>AND</option>
                                                    <option value={"OR"}>OR</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-xl-5"}>
                                        <div className={"frequency"}>
                                            <h5 className={"mediumFontSize"}> Prepare Action</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className={"row m-0 mediumFontSize custom-card-color "}>
                                    <PrepareCriteria selectOperand={selectOperand} setSelectOperand={setSelectOperand}
                                                     userCriteria={userCriteria} setSelectedValue={setSelectedValue}/>
                                    <div className={"col-xl-5 p-0"}>
                                        <div className={"show-border-down-side"}/>
                                        <PrepareActions actions={actions} setActions={setActions}
                                                        setSelectedValue={setSelectedValue}
                                                        selectOperand={selectOperand}/>
                                    </div>
                                </div>
                            </div>

                        </Modal.Body>
                    </Modal.Body>
                    <Modal.Footer className="modal_footer">
                        <button className="modal_cancel_btn cmn_modal_btn mediumFontSize"
                                onClick={handleClose}>Cancel
                        </button>
                        <button
                            disabled={customCriteria?.createCriteriaReducer?.loading === true || customCriteria?.updateCriteriaCriteriaByIdReducer?.loading === true}
                            style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined || localStorage.getItem("color") !== "undefined" ? localStorage.getItem("color") : ""}}
                            type="submit"
                            className="modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize">
                            {fromType}
                            {(customCriteria?.createCriteriaReducer?.loading || customCriteria?.updateCriteriaCriteriaByIdReducer?.loading) && (
                                <TailSpinLoader/>)}

                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}