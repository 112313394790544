import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    QuestionType,
    Survey,
    PulsePlans,
    defaultQuestionForReflection,
    SingleQuestionInSectionRequired
} from "../../../helpers/constants";
import {
    getDataFromLocalStorage,
    isNullOrEmpty,
    isUnauthorized,
    validatePublishSurvey,
    validateSurveySubmit,
    trim,
    groupQuestionsBySegmentIndex,
    showToast,
    moveArrayElements,
    removeItemFromArrayAtIndex,
    addItemAtIndex, formatMessage, handleApiResponse,
} from "../../../helpers/utils";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import {
    createSurvey,
    publishSurvey,
    shareSurvey,
    updateSurveyById
} from "../../../reduxStore/surveySlices/surveySlices";
import {Col, Row} from "react-bootstrap";
import {TailSpinLoader} from "../../layout/Loader";
import {HiPencil} from "react-icons/hi";
import {hasPermission, Permissions, Roles, roleHasAccess} from "../../../helpers/accessControl";
import jwtDecode from "jwt-decode";
import ConfirmationModel from "../../commons/ConfirmationModel";
import {Tooltip as ReactTooltip} from "react-tooltip";
import ShareReport from "../../reports/Modal/ShareReport";
import DraggableQuestions from "../DraggableQuestions";
import {FaTrash} from "react-icons/fa";
import {ImPencil} from "react-icons/im";
import SectionUpdate from "../Modal/SectionUpdate";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import UserInfo from "../../layout/UserInfo";
import AssignStrategyModal from "../AssignStrategyModal";
import {
    assignStrategyToSurvey,
    deleteStrategy,
    getAssignedStrategy, unassignStrategy
} from "../../../reduxStore/strategySlice/strategySlice";
import {IoIosArrowForward} from "react-icons/io";
import {resetReducers} from "../../../reduxStore/globalKeysSlice/globalKeysSlice";

const AddAndUpdateSurvey = ({data = null, formType}) => {

    const token = jwtDecode(getDataFromLocalStorage("token"))
    const isOrgLevelSys = token.orgLevel === "SYSTEM"
    const isRoleOrgAdmin = token.roles.includes(Roles.ORG_ADMIN) || token.roles.includes(Roles.SUB_ORG_ADMIN)
    const isRoleManager = token.roles.includes(Roles.MANAGER)

    const [tags, setTags] = useState([]);
    const [email, setEmail] = useState("");
    const [parentId, setParentId] = useState(null);
    const [showModalDeleteSection, setShowModalDeleteSection] = useState(false);
    const [showAssignStrategyModal, setShowAssignStrategyModal] = useState(false);
    const [showUnassignStrategyModal, setShowUnassignStrategyModal] = useState(false);
    const [triggerGetAssignedStrategyApi, setTriggerGetAssignedStrategyApi] = useState(false);

    const [editModal, setEditModal] = useState(false);
    const [removedId, setRemovedId] = useState(null);
    const [reports, setReports] = useState([]);
    // Set Tags for Survey
    const [surveyTags, setSurveyTags] = useState("");
    const [enableReflectionEditing, setEnableReflectionEditing] = useState([]);
    const [selectedMembersToSendEmailTo, setSelectedMembersToSendEmailTo] = useState([]);
    const [newAddedQuestion, setNewAddedQuestion] = useState({
        sectionIndex: null,
        show: false,
        question: null,
        unSavedTags: ""
    });

    const [editSurveyTitle, setEditSurveyTitle] = useState(false);
    const [sectionToggle, setSectionToggle] = useState(false);
    const surveyData = useSelector(state => state.survey)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showPublishSurveyConfirmationModal, setShowPublishSurveyConfirmationModal] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showStrategy, setShowStrategy] = useState(false)
    const [segmentType, setSegmentType] = useState("LINEAR")
    const [reportShare, isReportShare] = useState(false)
    const [reflectionSegments, setReflectionSegments] = useState([]);
    const [activeQuestionIndex, setActiveQuestionIndex] = useState({
        rowIndex: formType === "Add" ? 0 : null,
        columnIndex: null
    })
    const [survey, setSurvey] = useState({
        name: "",
        strategy: "",
        planLevel: "",
        status: Survey.SURVEY_STATUS_DRAFT,
        description: "",
        tags: [],
        segmentType: '',
        visibility: "TENANCY",
        segments: [],
        questions: [],
    });
    const [questionsList, setQuestionsList] = useState([]);
    const [currentFocusInfo, setCurrentFocusInfo] = useState({
        isFocused: false,
        className: ""
    });

    const assignStrategyApi = useSelector(state => state.strategy.assignStrategyToSurveyReducer)
    const unassignStrategyApi = useSelector(state => state.strategy.unassignStrategyReducer)
    const assignedStrategyApi = useSelector(state => state.strategy.getAssignedStrategyReducer)

    useEffect(() => {
        if (segmentType === "LINEAR") {
            setActiveQuestionIndex({
                ...activeQuestionIndex,
                rowIndex: activeQuestionIndex.columnIndex,
                columnIndex: null,
            })
            if (tags?.length > 0) {
                setTags(tags.flat(1))
            }

        }
        if (segmentType === "TABULAR") {
            setActiveQuestionIndex({
                ...activeQuestionIndex,
                rowIndex: 0,
                columnIndex: activeQuestionIndex.rowIndex,
            })
            if (tags?.length > 0) {
                setTags([tags])
            }
        }
    }, [segmentType]);
    useEffect(() => {
        if (formType === "Update" || formType === "Edit") {
            setSurvey(data)
            setEditSurveyTitle(false)
            setSegmentType(data?.segmentType)
            setReflectionSegments(groupQuestionsBySegmentIndex(data?.segments, data.questions))
            setQuestionsList(data.questions)
            setEnableReflectionEditing(Array(data.questions.length).fill(false))
        }
    }, []);
    useEffect(() => {
        if (reportShare) {
            dispatch(shareSurvey({
                email: email.map(c => c?.value).concat(selectedMembersToSendEmailTo?.map(c => c?.value)),
                surveyId: data.surveyId
            })).then((res) => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (res.meta.requestStatus === "fulfilled") {
                    isReportShare(false)
                    navigate("/reflections")
                }
            })
        }

    }, [reportShare])
    // Get Assigned Strategy To The Survey
    useEffect(() => {
        if (  triggerGetAssignedStrategyApi) {
            setTriggerGetAssignedStrategyApi(false)
            dispatch(getAssignedStrategy(data.surveyId)).then((res) => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }
        return ()=>{
            dispatch(resetReducers({sliceNames: ["getAssignedStrategyReducer"]}))
        }
    }, [triggerGetAssignedStrategyApi])
    useEffect(() => {
        setTriggerGetAssignedStrategyApi((formType === "Update" || formType === "Edit") && (isRoleOrgAdmin || (isRoleManager && isOrgLevelSys)))
    }, []);

    const removeSurveyTags = (index) => {
        const updatedArray = [...survey.tags]
        updatedArray.splice(index, 1);
        setSurvey({...survey, tags: updatedArray})
    }

    const handlePublishSurvey = () => {
        // const updatedSurveyData = {...survey, questions: questionsList, name: survey.name.trim()};

        const updatedSurveyData = {
            ...survey,
            planLevel: survey.planLevel ? survey.planLevel : null,
            segmentType: segmentType,
            segments: reflectionSegments,
            questions: segmentType === "LINEAR" ? questionsList : reflectionSegments?.map(cur => cur.questionsList).flat(),
            name: survey.name.trim()
        };
        dispatch(publishSurvey(updatedSurveyData)).then((res) => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
            if (res.meta.requestStatus === "fulfilled") {
                navigate("/reflections")
            }
        })
    }
    const handlePublishSurveyValidation = () => {
        if (formType === "Update" || formType === "Edit") {
            let message = validatePublishSurvey(survey, segmentType, questionsList, reflectionSegments, roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) && token?.orgLevel === "SYSTEM" && getDataFromLocalStorage("loggedInUserInfo").orgId === data.orgId)
            if (message) {
                showToast(message, "error")
            } else {
                setShowPublishSurveyConfirmationModal(true)
            }
        }
    }

    const handleTagChange = (value, index) => {
        const updatedTags = [...tags]
        updatedTags[index] = value;
        setTags(updatedTags)
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Enter") {
            if (index === -1) {
                if (!isNullOrEmpty(e.target.value.trim()) && !survey[e.target.name].includes(e.target.value.trim())) {
                    setSurvey({...survey, [e.target.name]: [...survey[e.target.name], e.target.value.trim()]});
                }
                setSurveyTags("")
            } else {
                const updatedQuestionsList = [...questionsList];
                const question = {...updatedQuestionsList[index]};

                // Check if the value already exists in the target name array
                if (!isNullOrEmpty(e.target.value.trim()) && !question[e.target.name].includes(e.target.value.trim())) {
                    question[e.target.name] = [...question[e.target.name], e.target.value.trim()];
                }
                updatedQuestionsList[2] = question;
                setQuestionsList(updatedQuestionsList);
                handleTagChange("", index)
            }


        }
    };

    const [isToggled, setIsToggled] = useState(Array.from({length: 5}, () => false));

    const toggle = (index, segmentIndex) => {
        setNewAddedQuestion({
            ...newAddedQuestion,
            show: false
        })
        // Segment Type Tabular
        if (segmentIndex !== undefined) {
            setParentId(segmentIndex)
            const updatedReflectionSegments = reflectionSegments.map((ref, refIndex) => {
                const filteredQuestions = ref.questionsList.map((cur, at) => {
                    if (refIndex === activeQuestionIndex?.rowIndex && at === activeQuestionIndex?.columnIndex && tags?.[activeQuestionIndex?.rowIndex]?.[activeQuestionIndex?.columnIndex] !== null && tags?.[activeQuestionIndex?.rowIndex]?.[activeQuestionIndex?.columnIndex] !== undefined && tags?.[activeQuestionIndex?.rowIndex]?.[activeQuestionIndex?.columnIndex] !== "") {
                        // Check if the value already exists in the target name array
                        if (tags?.[activeQuestionIndex?.rowIndex]?.[activeQuestionIndex?.columnIndex] !== null && tags?.[activeQuestionIndex?.rowIndex]?.[activeQuestionIndex?.columnIndex] !== undefined && tags?.[activeQuestionIndex?.rowIndex]?.[activeQuestionIndex?.columnIndex] !== "" && !cur?.tags?.includes(tags?.[activeQuestionIndex?.rowIndex]?.[activeQuestionIndex?.columnIndex])) {
                            cur.tags = [...cur.tags, tags?.[activeQuestionIndex?.rowIndex]?.[activeQuestionIndex?.columnIndex]?.trim()];
                        }
                        let updatedTags = tags?.map(row => [...row]);
                        updatedTags[activeQuestionIndex?.rowIndex][activeQuestionIndex?.columnIndex] = ""
                        setTags(updatedTags)
                    }
                    if (segmentIndex !== activeQuestionIndex?.rowIndex || index !== activeQuestionIndex?.columnIndex) {
                        setActiveQuestionIndex({
                            ...activeQuestionIndex,
                            rowIndex: segmentIndex,
                            columnIndex: index
                        })
                    }
                    if (at === index && segmentIndex === refIndex) {
                        return {...cur, isShow: true};
                    }
                    return {...cur, isShow: false};
                });
                return {
                    ...ref,
                    questionsList: filteredQuestions
                };

                return ref;
            });
            setReflectionSegments(updatedReflectionSegments);
        } else {
            // Segment Type Linear
            if (index !== activeQuestionIndex?.rowIndex) {
                setActiveQuestionIndex({
                    ...activeQuestionIndex,
                    rowIndex: index,
                    columnIndex: null
                })
            }
            const filteredQuestions = questionsList.map((cur, at) => {
                if (at === activeQuestionIndex?.rowIndex && tags?.[activeQuestionIndex?.rowIndex] !== null && tags?.[activeQuestionIndex?.rowIndex] !== undefined && tags?.[activeQuestionIndex?.rowIndex] !== "") {
                    // Check if the value already exists in the target name array
                    if (!isNullOrEmpty(tags[activeQuestionIndex?.rowIndex].trim()) && !cur?.tags?.includes(tags[activeQuestionIndex?.rowIndex].trim())) {
                        cur.tags = [...cur.tags, tags[activeQuestionIndex?.rowIndex].trim()];
                    }
                    handleTagChange("", activeQuestionIndex?.rowIndex)
                }
                if (at === index) {
                    return {...cur, isShow: true}
                }
                return {...cur, isShow: false};
            });
            setQuestionsList(filteredQuestions);
        }

        setIsToggled(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };

    const handleSelectedParentSection = (queInd, segInd, questionsList) => {
        setParentId(null);
        setQuestionsList(questionsList)
        setSectionToggle(false)
        setRemovedId(null)
    }

    const addSection = () => {
        const emptySections = reflectionSegments
            ?.map((cur, index) => ({section: cur, index}))
            .filter(({section}) => section.questionsList.length === 0);
        if (emptySections.length > 0) {
            showToast(formatMessage(SingleQuestionInSectionRequired, [emptySections[0].section.segmentName || `Section ${emptySections[0].index + 1}`]), "INFO")
            return;
        }
        setNewAddedQuestion({
            ...newAddedQuestion,
            sectionIndex: reflectionSegments.length,
            show: true,
            question: {
                ...defaultQuestionForReflection,
                ...newAddedQuestion.question,
                segmentIndex:reflectionSegments.length
            }
        })
        setActiveQuestionIndex({
            rowIndex: null,
            columnIndex: null
        })
        let updatedReflectionSegments = [...reflectionSegments]?.map((cur, index) => {
            return {
                ...cur,
                isShow: false,
                questionsList: cur.questionsList.map((question) => {
                    return {...question, isShow: false};
                })
            };
        });
        setReflectionSegments([...updatedReflectionSegments, {
            segmentName: '',
            segmentDescription: "",
            questionsList: [],
            isShow: false
        }])
    }
    const removeSection = () => {
        if (removedId !== null) {
            const filteredSegments = reflectionSegments.filter((cur, at) => at !== removedId)
            if (filteredSegments.length > 1) {
                setNewAddedQuestion({
                    ...newAddedQuestion,
                    sectionIndex: 0
                })
                setReflectionSegments(filteredSegments)
                setShowModalDeleteSection(false)
                setRemovedId(null)
            } else {
                filteredSegments?.map((cur) => {
                    setQuestionsList(cur.questionsList)
                })
                setNewAddedQuestion({
                    ...newAddedQuestion,
                    sectionIndex: null
                })
                setSegmentType("LINEAR")
                setParentId(null);
                setShowModalDeleteSection(false)
                setSectionToggle(false)
                setRemovedId(null)
            }
        }
    }


    const callBack = useCallback((index, sectionToggle, segmentType, questionsList) => {

        if (sectionToggle) {
            setNewAddedQuestion({
                ...newAddedQuestion,
                sectionIndex: 1,
                show: true,
                question: {
                    ...defaultQuestionForReflection,
                    ...newAddedQuestion.question,
                    segmentIndex:1
                }
            })
            setActiveQuestionIndex({
                rowIndex: null,
                columnIndex: null
            })
            setReflectionSegments([
                {
                    segmentName: '',
                    segmentDescription: "",
                    questionsList: questionsList?.map(cur => ({...cur, isShow: false})),
                    isShow: false
                },
                {segmentName: '', segmentDescription: "", questionsList: [], isShow: false}
            ]);
            setParentId(index);
            setSegmentType(segmentType)
        } else {
            setParentId(null);
            setReflectionSegments([]);
            setQuestionsList(questionsList)
            setSegmentType(segmentType)
        }
        setSectionToggle(sectionToggle)
    }, [parentId, reflectionSegments, sectionToggle]);

    const handleSurveyChange = (e) => {
        setSurvey({...survey, [e.target.name]: e.target.value === "" ? null : e.target.value})
    };
    const checkBtnPermission = (Id) => {
        if(formType === "Add"){
            return hasPermission([Permissions.REFLECTION_CREATE], true)
        }
        if(formType === "Update"){
            return hasPermission([Permissions.REFLECTION_WRITE], true) && (data.orgId === token?.orgId || (isOrgLevelSys && (isRoleOrgAdmin || isRoleManager)))
        }
        if(formType === "Edit"){
            return data.enable && hasPermission([Permissions.REFLECTION_WRITE], true) && (data.orgId === token?.orgId || (isOrgLevelSys && (isRoleOrgAdmin || isRoleManager))) && typeof Id === "undefined"
        }
        return false;
    };

    const handleSurveySubmit = () => {
        let message = validateSurveySubmit(survey, segmentType, questionsList, reflectionSegments)
        if (message) {
            showToast(message, "error")
        } else {
            const updatedSurveyData = {
                ...survey,
                planLevel: survey.planLevel ? survey.planLevel : null,
                segmentType: segmentType,
                segments: reflectionSegments,
                questions: segmentType === "LINEAR" ? questionsList : reflectionSegments?.map(cur => cur.questionsList).flat(),
                name: survey.name.trim()
            };
            dispatch(formType === "Add" ? createSurvey(updatedSurveyData) : updateSurveyById(updatedSurveyData)).then((res) => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (res.meta.requestStatus === "fulfilled") {
                    formType === "Add" ? navigate(`/reflections/${res.payload.data}`) : navigate(`/reflections/${data.surveyId}`)
                }
            })

        }
    }

    const onDragEnd = (result) => {
        const {source, destination} = result;

        if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
            return;
        }
        const sourceDroppableIndex = parseInt(source.droppableId.split("-")[1]);
        const destinationDroppableIndex = destination.droppableId ? parseInt(destination.droppableId.split("-")[1]) : 0;
        if (destinationDroppableIndex !== null) {
            if (source.droppableId === destination.droppableId) {

                if (segmentType === "TABULAR") {
                    let updatedTags = tags?.map(row => [...row]);
                    let movedTag = updatedTags[destinationDroppableIndex] || []
                    moveArrayElements([...movedTag], source.index, destination.index)
                    updatedTags[destinationDroppableIndex] = moveArrayElements([...movedTag], source.index, destination.index)
                    setTags(updatedTags)

                    const updatedQuestions = reflectionSegments[sourceDroppableIndex]?.questionsList ? [...reflectionSegments[sourceDroppableIndex].questionsList] : [];

                    const [removed] = updatedQuestions.splice(source.index, 1);
                    updatedQuestions.splice(destination.index, 0, removed);

                    const updatedSegments = [...reflectionSegments];
                    updatedSegments[sourceDroppableIndex] = {
                        ...updatedSegments[sourceDroppableIndex],
                        questionsList: updatedQuestions
                    };
                    if (activeQuestionIndex.rowIndex === sourceDroppableIndex && activeQuestionIndex.columnIndex === source.index) {
                        setActiveQuestionIndex({
                            ...activeQuestionIndex,
                            rowIndex: destinationDroppableIndex,
                            columnIndex: destination.index,
                        })
                    } else {
                        const originalElement = reflectionSegments[activeQuestionIndex?.rowIndex]?.questionsList[activeQuestionIndex?.columnIndex];
                        const newIndex = updatedQuestions.findIndex(
                            (element) => element?.questionText === originalElement?.questionText && element?.type === originalElement?.type
                        );
                        setActiveQuestionIndex({
                            ...activeQuestionIndex,
                            rowIndex: destinationDroppableIndex,
                            columnIndex: newIndex,
                        })
                        // setParentId(destinationDroppableIndex)
                    }

                    setReflectionSegments(updatedSegments);

                } else {

                    setTags(moveArrayElements([...tags], result.source.index, result.destination.index))

                    const updatedCharacters = [...questionsList];
                    const [movedCharacter] = updatedCharacters.splice(result.source.index, 1);
                    updatedCharacters.splice(result.destination.index, 0, movedCharacter);

                    if (activeQuestionIndex.rowIndex === result.source.index) {
                        setActiveQuestionIndex({
                            ...activeQuestionIndex,
                            rowIndex: result.destination.index,
                            columnIndex: null,
                        })
                    } else {
                        const originalElement = {
                            ...questionsList[activeQuestionIndex.rowIndex]
                        };
                        const newIndex = updatedCharacters.findIndex(element => element.questionText === originalElement.questionText && element.type === originalElement.type);
                        setActiveQuestionIndex({
                            ...activeQuestionIndex,
                            rowIndex: newIndex,
                            columnIndex: null,
                        })
                    }
                    setQuestionsList(updatedCharacters);
                }
            } else {

                let updatedTags = tags?.map(row => [...row]);
                let removeFromArray = updatedTags[sourceDroppableIndex] || []
                let insertIntoArray = updatedTags[destinationDroppableIndex] || []
                const updatedRemovedArray = removeItemFromArrayAtIndex(removeFromArray, source.index)
                const updatedInsertIntoArray = addItemAtIndex(insertIntoArray, destination.index, updatedTags?.[sourceDroppableIndex]?.[source.index])
                updatedTags[sourceDroppableIndex] = updatedRemovedArray
                updatedTags[destinationDroppableIndex] = updatedInsertIntoArray
                setTags(updatedTags)


                const sourceQuestions = [...reflectionSegments[sourceDroppableIndex].questionsList];
                const destQuestions = [...reflectionSegments[destinationDroppableIndex].questionsList];

                const [removed] = sourceQuestions.splice(source.index, 1);
                destQuestions.splice(destination.index, 0, {...removed,segmentIndex:destinationDroppableIndex});

                const updatedSegments = [...reflectionSegments];
                updatedSegments[sourceDroppableIndex] = {
                    ...updatedSegments[sourceDroppableIndex],
                    questionsList: sourceQuestions
                };
                updatedSegments[destinationDroppableIndex] = {
                    ...updatedSegments[destinationDroppableIndex],
                    questionsList: destQuestions
                };
                // If The Dragged Question is Active
                if (activeQuestionIndex.rowIndex === sourceDroppableIndex && activeQuestionIndex.columnIndex === source.index) {
                    setActiveQuestionIndex({
                        ...activeQuestionIndex,
                        rowIndex: destinationDroppableIndex,
                        columnIndex: destination.index,
                    })
                } else {
                    const originalElement = reflectionSegments[activeQuestionIndex?.rowIndex]?.questionsList[activeQuestionIndex?.columnIndex];
                    const index = updatedSegments[activeQuestionIndex?.rowIndex]?.questionsList?.findIndex(element => element?.questionText === originalElement?.questionText && element?.type === originalElement?.type);
                    setActiveQuestionIndex({
                        ...activeQuestionIndex,
                        rowIndex: activeQuestionIndex?.rowIndex,
                        columnIndex: index,
                    })
                }
                setParentId(destinationDroppableIndex)
                setReflectionSegments(updatedSegments);

            }
        }
    };
    const handleFocus = (className) => {
        setCurrentFocusInfo({
            isFocused: true,
            className: className
        })
    };
    const handleBlur = () => {
        setCurrentFocusInfo({
            isFocused: false,
            className: ""
        })
    };

    const handleUnassignStrategy = () => {
        dispatch(unassignStrategy({strategyId:assignedStrategyApi?.data?.strategyId,surveyId:data?.surveyId})).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
            handleApiResponse(res,()=>{
                setShowUnassignStrategyModal(false);
                setTriggerGetAssignedStrategyApi(true)
            })
        })

    };

    return (
        <section className={"reflection_wrapper"}>
            <div className="cmn_header">
                <h2 className="mediumFontSize  org-heading m-0">{formType + ' Reflection'}  </h2> <UserInfo/>
            </div>
            <div className='save-survey-outer main_content '>
                <div className={"reflection-name-share-btn-outer pt-3"}>
                    <div className={"survey-name-share-btn d-flex align-items-center justify-content-space-between "}>

                        {
                            formType === "Add" ?
                                <div className={"survey-name-input  w-50 me-1 "}>
                                    <input name={"name"}

                                           value={survey.name}
                                           onChange={(e) => {
                                               if (hasPermission([Permissions.REFLECTION_CREATE], true)) {
                                                   handleSurveyChange(e)
                                               }
                                           }}
                                           style={{
                                               border: currentFocusInfo.isFocused && currentFocusInfo.className === "survey-name-input" ? `1px solid ${localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}` : "",
                                           }}
                                           onFocus={() => {
                                               handleFocus("survey-name-input")
                                           }}
                                           onBlur={handleBlur}
                                           className={"form-control survey-name-input gray_bg_color on_focus box-shadow-unset-on-focus"}
                                           type="text"
                                           disabled={!hasPermission([Permissions.REFLECTION_CREATE], true)}
                                           placeholder="Enter Reflection Name"
                                    />
                                </div>
                                :
                                !editSurveyTitle ?
                                    <div className={"survey-title-container d-flex align-items-center"}>
                                        <h3 className={` mediumFontSize `}
                                            title={survey.name}>{survey.name}</h3>
                                        {
                                            hasPermission([Permissions.REFLECTION_WRITE], true) &&
                                            <HiPencil
                                                className={"edit-survey-title-btn " + (checkBtnPermission() ? "" : "disable-edit-reflection-name-btn")}
                                                onClick={() => {
                                                    if (checkBtnPermission()) {
                                                        setEditSurveyTitle(true)
                                                    }

                                                }}/>
                                        }
                                    </div>
                                    :
                                    <div className={"survey-name-input w-50 me-1"}>
                                        <input
                                            name={"name"}
                                            value={survey.name}
                                            onChange={(e) => {
                                                if (checkBtnPermission() === true) {
                                                    handleSurveyChange(e)
                                                }
                                            }}
                                            style={{
                                                border: currentFocusInfo.isFocused && currentFocusInfo.className === "survey-name-input" ? `1px solid ${localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}` : "",
                                            }}
                                            onFocus={() => {
                                                handleFocus("survey-name-input")
                                            }}
                                            onBlur={handleBlur}
                                            disabled={checkBtnPermission() === false}
                                            className={"form-control survey-name-input"}
                                            type="text"
                                            placeholder="Enter Reflection Name"
                                        />
                                    </div>

                        }
                        <div className={"share-reflection-lvl-btn d-flex align-items-center justify-content-center"}>
                            {
                                (roleHasAccess([Roles.ORG_ADMIN]) && token?.orgLevel === "SYSTEM" && (formType === "Add" || ((formType === "Update" || formType === "Edit") && getDataFromLocalStorage("loggedInUserInfo").orgId === data.orgId))) &&
                                <select
                                    onChange={handleSurveyChange}
                                    name={"planLevel"}
                                    value={survey.planLevel}
                                    className={"form-select reflection-lvl-dropdown  me-2"}>
                                    <option value={""}>Select Plan Level</option>
                                    {
                                        Object.keys(PulsePlans).map((plan, index) => {
                                            return (
                                                <option key={index}
                                                        value={PulsePlans[plan].ordinal}>{PulsePlans[plan].value}</option>
                                            );
                                        })
                                    }
                                </select>
                            }
                            {
                                assignedStrategyApi?.data===null && (isRoleOrgAdmin || (isRoleManager && isOrgLevelSys)) && (formType === "Edit" || formType === "Update") &&
                                <button
                                    style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                    disabled={assignStrategyApi?.loading}
                                    className='modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize submit-survey-btn '
                                    onClick={() => {
                                        setShowAssignStrategyModal(true)
                                    }}>Assign Strategy
                                </button>
                            }
                            {
                                checkBtnPermission() === true && survey.status !== Survey.SURVEY_STATUS_PUBLISHED &&
                                <button
                                    style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                    disabled={(surveyData?.createSurveyDataReducer?.loading || surveyData?.updateSurveyByIdDataReducer?.loading) === true}
                                    className='modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize submit-survey-btn '
                                    onClick={handleSurveySubmit}>Save
                                    {
                                        (surveyData?.createSurveyDataReducer?.loading || surveyData?.updateSurveyByIdDataReducer?.loading) &&
                                        <TailSpinLoader/>
                                    }
                                </button>
                            }
                            {
                                checkBtnPermission() && <>
                                    <button
                                        style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                        disabled={(surveyData?.publishSurveyDataReducer?.loading) === true}
                                        data-tooltip-id="PublishReflectionBTN"
                                        data-tooltip-content={formType === "Add" ? "Please submit reflection first then publish" : ""}
                                        className={'modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize publish-survey-btn me-2 ' + (formType === "Add" ? "disable-btn" : "")}
                                        onClick={handlePublishSurveyValidation}>
                                        {survey.status === Survey.SURVEY_STATUS_PUBLISHED ? "Save" : "Publish"}
                                    </button>
                                    <ReactTooltip className={"ToolTip"} place={"bottom"} id="PublishReflectionBTN"/>
                                </>
                            }
                            {
                                checkBtnPermission() && survey.status === "PUBLISHED" &&
                                <button
                                    style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                    className={'modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize publish-survey-btn'}
                                    onClick={() => setShowModal(true)}
                                >
                                    Share
                                </button>

                            }
                        </div>

                    </div>
                </div>

                <div className={"survey-tag-outer w-100 mt-2 me-1 gray_bg_color survey-tag-outer1"}
                     style={{
                         border: currentFocusInfo.isFocused && currentFocusInfo.className === "survey-tag-outer1" ? `1px solid ${localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}` : "",
                     }}
                     onFocus={() => {
                         handleFocus("survey-tag-outer1")
                     }}
                     onBlur={handleBlur}
                >
                    {
                        survey.tags?.map((tag, index) => {
                            return (
                                <div key={index} className={"survey-tags"}>
                                    <div className={"tag-text"}
                                         title={tag}>{trim(tag, 12)}
                                    </div>
                                    <div className={"tag-cross"} name={"tags"}
                                         onClick={() => {
                                             if (checkBtnPermission() === true) {
                                                 removeSurveyTags(index)
                                             }
                                         }}>
                                        <i className={"fa fa-times remove-tag-icon"}/>
                                    </div>
                                </div>
                            );
                        })
                    }
                    <div className={"add-tag-outer"}>
                        <i
                            style={{color: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                            className={"fa fa-plus add-tag-icon text-dark"}/>
                        <input name={"tags"}
                               value={surveyTags}
                               onChange={(e) => {
                                   if (checkBtnPermission() === true) {
                                       setSurveyTags(e.target.value)
                                   }
                               }}
                               onKeyDown={(e) => {
                                   if (checkBtnPermission() === true) {
                                       handleKeyDown(e, -1)
                                   }
                               }}
                               onBlur={(e) => {
                                   handleKeyDown({...e, key: "Enter"}, -1)
                               }}
                               disabled={checkBtnPermission() === false}
                               placeholder={"Add Reflection Tag"}
                               className={"tags-input-field gray_bg_color on_focus box-shadow-unset-on-focus"}
                               type={"text"}

                        />
                    </div>
                </div>
                {
                    !assignedStrategyApi?.loading && assignedStrategyApi?.data !== null && assignedStrategyApi?.data !== undefined &&
                    <div className={"strategy-outer mt-2"}>
                        <div
                            className={"strategy-txt cursor-pointer " + (showStrategy ? " strategy-txt-border-radius" : "")}
                            style={{background: localStorage.getItem("color") !== null && localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : "#fc6620"}}
                            onClick={() => {
                                setShowStrategy(!showStrategy)
                            }}>
                            <IoIosArrowForward className={showStrategy ? " rotate-90deg me-1" : " mb-1  "}/>
                            {assignedStrategyApi?.data?.name}
                        </div>
                        {
                            showStrategy &&
                            <div className={"strategy-detail"}>
                                    <div className="form-group mt-1">
                                        <div className='mediumFontSize font-weight-500 font-size-15'>Description</div>
                                        <pre className={"view-description mediumFontSize font-size-14 opacity-1"}>{assignedStrategyApi?.data?.description}</pre>
                                    </div>
                                    <div className={"form-group mt-1"}>
                                        <div className={"mediumFontSize font-weight-500 font-size-15"}> Goals</div>
                                        <div className={"survey-tag-outer w-100 survey-tag-outer1"}>
                                            {
                                                assignedStrategyApi?.data?.goals?.length > 0 ?
                                                    <>
                                                        {
                                                            assignedStrategyApi?.data?.goals?.map((goal, index) => {
                                                                return (
                                                                    <div key={index} className={"survey-tags"}>
                                                                        <div className={"tag-text mediumFontSize"}
                                                                             title={goal}>{goal}</div>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <div className={"p-1 mediumFontSize font-size-14"}>No Goals Defined</div>
                                            }

                                        </div>

                                    </div>
                                <div className={"unassign-strategy-btn-outer mt-2 text-end mb-2"}>
                                    <button
                                        style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                        className={'modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize publish-survey-btn  '}
                                        onClick={() => setShowUnassignStrategyModal(true)}
                                    >
                                        Unassign
                                    </button>
                                </div>
                            </div>
                        }

                    </div>
                }
            </div>

            <div className={"mx-2"}>
                <>
                    <Row className={"m-0"}>
                        <Col lg={6} md={4} className={"pe-0"}>
                            <div className="w-100 mt-2">
                                <div
                                    className={`question_tabs_wrapper question_tab_container p-3 ${questionsList?.length === 0 ? " display-grid align-items-center" : " "}`}>
                                    {
                                        questionsList?.length === 0 ?
                                            <h6 className={"mediumFontSize text-center "}>Currently no questions added to the list</h6>
                                            :
                                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                                                <DragDropContext onDragEnd={onDragEnd}>
                                                    {
                                                        segmentType === "LINEAR" ?
                                                            <Droppable droppableId="linear-1">
                                                                {(provided) => (
                                                                    <Nav variant="pills" className="flex-column"
                                                                         ref={provided.innerRef} {...provided.droppableProps}>
                                                                        {questionsList.map((ref, index) => (
                                                                            <Draggable key={index}
                                                                                       draggableId={`linear-question-${index}`}
                                                                                       index={index}>
                                                                                {
                                                                                    (provided) =>
                                                                                        <div
                                                                                            ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                            <Nav.Item>
                                                                                                <Nav.Link
                                                                                                    className={ref.isShow ? !localStorage.getItem("color") ? "" : "active-link" : ""}
                                                                                                    style={{background: (localStorage.getItem("color") && ref.isShow) ? localStorage.getItem("color") : ""}}
                                                                                                    onClick={() => toggle(index)}>
                                                                                                    <span
                                                                                                        className='question_count'>{index + 1}</span>
                                                                                                    <span
                                                                                                        className={"question_name"}>{ref.questionText}</span>
                                                                                                </Nav.Link>
                                                                                            </Nav.Item>
                                                                                        </div>
                                                                                }
                                                                            </Draggable>
                                                                        ))}
                                                                        {provided.placeholder}
                                                                        {
                                                                            !sectionToggle &&
                                                                            <div
                                                                                className={"add-section justify-content-end"}>
                                                                                <button
                                                                                    style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                                                                    className={"modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize publish-survey-btn me-2 mt-3"}
                                                                                    onClick={() => callBack(0, true, "TABULAR", questionsList)}>
                                                                                    Add Section
                                                                                </button>
                                                                            </div>
                                                                        }
                                                                    </Nav>
                                                                )}
                                                            </Droppable>
                                                            :
                                                            reflectionSegments.map((refSegment, segmentIndex) => (
                                                                <Droppable key={segmentIndex}
                                                                           droppableId={`segment-${segmentIndex}`}>
                                                                    {
                                                                        (provided) => (
                                                                            <div
                                                                                ref={provided.innerRef} {...provided.droppableProps}>
                                                                                <div
                                                                                    className={"position-relative mb-3 "}>
                                                                                    <div
                                                                                        className={`section_title_outer ${segmentIndex === 0 ? "mt-0" : "mt-5"}`}>
                                                                                        <div
                                                                                            className={"add_section_container"}>
                                                                                            {/*<span className={`mediumFontSize font-weight-500 font-size-15 ${parentId === segmentIndex ? " sectionTitle" : ""}`}>{reflectionSegments[segmentIndex]?.segmentName || "Section " + (segmentIndex + 1)}</span>*/}
                                                                                            <span
                                                                                                className={`mediumFontSize font-weight-500 font-size-15`}>{reflectionSegments[segmentIndex]?.segmentName || "Section " + (segmentIndex + 1)}</span>
                                                                                            <div
                                                                                                className={"segment_container"}>
                                                                                                <div
                                                                                                    className={"edit-icon cmn-icon-outer"}>
                                                                                                    <ImPencil
                                                                                                        onClick={() => {
                                                                                                            setEditModal(true);
                                                                                                            setRemovedId(segmentIndex);
                                                                                                        }}/>
                                                                                                </div>
                                                                                                {
                                                                                                    reflectionSegments.length > 1 &&
                                                                                                    <div
                                                                                                        className={"delete_icon cmn-icon-outer"}>
                                                                                                        <FaTrash
                                                                                                            onClick={() => {
                                                                                                                setShowModalDeleteSection(true);
                                                                                                                setRemovedId(segmentIndex);
                                                                                                            }}/>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            refSegment.questionsList.length === 0 ?
                                                                                                <div
                                                                                                    className={"mediumFontSize text-center font-size-14 m-2"}>Currently,
                                                                                                    no questions are
                                                                                                    added</div>
                                                                                                :
                                                                                                refSegment.questionsList.map((ref, index) => (
                                                                                                    <Draggable
                                                                                                        key={`question-${segmentIndex}-${index}`}
                                                                                                        draggableId={`question-${segmentIndex}-${index}`}
                                                                                                        index={index}>
                                                                                                        {(provided) => (
                                                                                                            <div
                                                                                                                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                                                <Nav.Item>
                                                                                                                    <Nav.Link
                                                                                                                        className={ref.isShow ? !localStorage.getItem("color") ? "" : "active-link" : ""}
                                                                                                                        style={{background: (localStorage.getItem("color") && ref.isShow) ? localStorage.getItem("color") : ""}}
                                                                                                                        onClick={() => toggle(index, segmentIndex)}>
                                                                                                                <span
                                                                                                                    className='question_count'>{index + 1}</span>
                                                                                                                        <span
                                                                                                                            className={"question_name"}>{ref.questionText}</span>
                                                                                                                    </Nav.Link>
                                                                                                                </Nav.Item>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </Draggable>
                                                                                                ))
                                                                                        }

                                                                                        {provided.placeholder}
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    reflectionSegments?.length - 1 === segmentIndex &&
                                                                                    <div
                                                                                        className={"add-section justify-content-end"}>
                                                                                        <button
                                                                                            className={"modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize publish-survey-btn me-2 mt-3"}
                                                                                            onClick={addSection}
                                                                                            style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}>
                                                                                            Add Section
                                                                                        </button>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    }
                                                                </Droppable>
                                                            ))
                                                    }
                                                </DragDropContext>
                                            </Tab.Container>
                                    }

                                </div>

                            </div>

                        </Col>
                        <Col lg={6} md={8} className={"pe-1"}>

                            <div className='SurveyName_container'>
                                <div className={"details_wrapper details_container_outer"}>
                                    <DraggableQuestions  tags={tags} setTags={setTags}
                                                        data={data} questionsList={questionsList}
                                                        setQuestionsList={setQuestionsList} formType={formType}
                                                        segmentType={segmentType} setSegmentType={setSegmentType}
                                                        parentId={parentId} reflectionSegments={reflectionSegments}
                                                        setReflectionSegments={setReflectionSegments}
                                                        activeQuestionIndex={activeQuestionIndex}
                                                        setActiveQuestionIndex={setActiveQuestionIndex}
                                                        handleSelectedParentSection={handleSelectedParentSection}
                                                        newAddedQuestion={newAddedQuestion}
                                                        setNewAddedQuestion={setNewAddedQuestion}
                                    />
                                </div>


                            </div>

                        </Col>
                    </Row>
                </>
            </div>
            {
                showAssignStrategyModal &&
                <AssignStrategyModal
                    show={showAssignStrategyModal}
                    setShow={setShowAssignStrategyModal}
                    surveyId={data.surveyId}
                    setTriggerGetAssignedStrategyApi={setTriggerGetAssignedStrategyApi}
                />
            }
            {
                showPublishSurveyConfirmationModal &&
                <ConfirmationModel
                    title={survey.status === Survey.SURVEY_STATUS_PUBLISHED ? "Update Reflection" : "Publish Reflection"}
                    btnText={survey.status === Survey.SURVEY_STATUS_PUBLISHED ? "Update" : "Publish"}
                    action={handlePublishSurvey} show={showPublishSurveyConfirmationModal}
                    bodyMessage={survey.status === Survey.SURVEY_STATUS_PUBLISHED ? "Before proceeding, please confirm that you want to update your reflection. Once updated, all changes will be visible to all the active users." : "Before proceeding, please confirm that you want to save your reflection. Once published, all changes will be visible to all the active users."}
                    setShow={setShowPublishSurveyConfirmationModal}
                    isLoading={surveyData?.publishSurveyDataReducer?.loading}
                    showUserAcknowledgementCheckBox={true}
                    userAcknowledgementCheckBoxText={"Yes, I understand"}
                />
            }
            {
                showModalDeleteSection &&
                <ConfirmationModel title={"Delete Section"}
                                   btnText={"Delete"}
                                   action={removeSection} show={showModalDeleteSection}
                                   bodyMessage={"Are you sure you want to delete this section and all its questions?"}
                                   setShow={setShowModalDeleteSection}
                                   isLoading={surveyData?.publishSurveyDataReducer?.loading}
                />
            }
            {
                editModal &&
                <SectionUpdate show={editModal} setShow={setEditModal} indexId={removedId}
                               reflectionSegments={reflectionSegments} setReflectionSegments={setReflectionSegments}/>
            }

            {
                showModal &&
                <ShareReport show={showModal} setShow={setShowModal} setReports={setReports} title={"Share Reflection"}
                             reportShare={reportShare} isReportShare={isReportShare} setEmail={setEmail} email={email}
                             selectedMembersToSendEmailTo={selectedMembersToSendEmailTo}
                             setSelectedMembersToSendEmailTo={setSelectedMembersToSendEmailTo}/>

            }
            {
                showUnassignStrategyModal &&
                <ConfirmationModel title={"Unassign Strategy"}
                                   btnText={"Unassign"}
                                   action={handleUnassignStrategy}
                                   show={showUnassignStrategyModal}
                                   bodyMessage={"Are you sure you want to unassign strategy?"}
                                   setShow={setShowUnassignStrategyModal}
                                   isLoading={unassignStrategyApi?.loading}
                />
            }

        </section>
    );
}
export default AddAndUpdateSurvey