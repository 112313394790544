import {hasPermission, Permissions, roleHasAccess, Roles as SysRole} from "../../helpers/accessControl";
import './RoleDetails.css'
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Loader, TailSpinLoader} from "../layout/Loader";
import {useEffect, useState} from "react";
import {getRoleById, updateRole} from "../../reduxStore/rolesSlices/rolesSlices";
import {getAllPermissions} from "../../reduxStore/permissionsSlices/permissionsSlice";
import {getDataFromLocalStorage, isUnauthorized, toString} from "../../helpers/utils";
import ErrorPage from "../errorPage/ErrorPage";
import Form from "react-bootstrap/Form";
import {AccessDenied, AccessDeniedMsg} from "../../helpers/constants";
import jwtDecode from "jwt-decode";

const RolesDetail = () => {
    const {roleId} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [permissionLabels, setPermissionLabels] = useState([]);
    const [roleData, setRoleData] = useState("");
    const [allPermissions, setAllPermissions] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const roles = useSelector(state => state.roles)
    const permissions = useSelector(state => state.permissions)
    const [error, setError] = useState({hasError: false, value: "", description: "", detailDesc: ""});
    const handleOnUpdate = () => {
        // Get Permission I'd of the selected Roles
        const permissionsId = allPermissions.filter(permission => selectedPermissions.includes(permission.name)).map(permission => permission.permissionId)
        dispatch(updateRole({
            roleId: roleId,
            name: roles?.getRoleByIdDataReducer?.data?.name,
            authorityLevel: roles?.getRoleByIdDataReducer?.data?.authorityLevel,
            permissionsIds: permissionsId
        })).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
            if (res.meta.requestStatus === "fulfilled") {
                navigate("/roles")
            }
        })

    }
    useEffect(() => {
        if (roleHasAccess([SysRole.SYS_ADMIN, SysRole.ORG_ADMIN, SysRole.SUB_ORG_ADMIN]) && hasPermission([Permissions.ROLE_READ, Permissions.ROLE_WRITE, Permissions.PERM_READ], true)) {

            dispatch(getRoleById(roleId)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (res.meta.requestStatus === "fulfilled") {
                    setRoleData(res.payload.data)
                    setSelectedPermissions(res.payload.data.permissionData.map(permission => permission.name))
                } else if (res.meta.requestStatus === "rejected") {
                    setError({
                        hasError: true,
                        value: res.payload.errorCode.value,
                        description: res.payload.errorCode.description,
                        detailDesc: res.payload.detailDesc
                    })
                }
            })

            dispatch(getAllPermissions()).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (res.meta.requestStatus === "fulfilled") {
                    setAllPermissions(res.payload.data)
                    setPermissionLabels(getPermissionLabels(res.payload.data))
                }
            })
        }
    }, [])


    const handleCheckboxChange = (permissionName) => {
        if (selectedPermissions.includes(permissionName)) {
            setSelectedPermissions(selectedPermissions.filter(permission => permission !== permissionName));
        } else {
            setSelectedPermissions([...selectedPermissions, permissionName]);
        }
    };

    function getPermissionLabels(permissions) {
        const permissionHeadings = [];
        permissions.forEach(permission => {
            const lastUnderscoreIndex = permission.name.lastIndexOf('_');
            const name = lastUnderscoreIndex !== -1 ? permission.name.substring(0, lastUnderscoreIndex) : permission.name;
            if (!permissionHeadings.includes(name)) {
                permissionHeadings.push(name);
            }
        });
        return permissionHeadings;
    }

    return !(roleHasAccess([SysRole.SYS_ADMIN, SysRole.ORG_ADMIN, SysRole.SUB_ORG_ADMIN]) && hasPermission([Permissions.ROLE_READ, Permissions.ROLE_WRITE, Permissions.PERM_READ], true)) ?
        <ErrorPage value={403} description={AccessDenied} detailDesc={AccessDeniedMsg}/> :


        ((roles?.getRoleByIdDataReducer?.loading || permissions?.getAllPermissionsDataReducer?.loading || Object.keys(roleData).length === 0 || !permissionLabels.length > 0) ?
            error.hasError ? <ErrorPage value={error.value} detailDesc={error.detailDesc}
                                        description={error.description}/> : <Loader/> :

            <section>
                <div className="row main_content box_design1 update_role_container">
                    <div className="member-detail-buttons mt-2 mb-4">
                        <button  style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""} } onClick={() => {
                            navigate(-1)
                        }} className="modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize ">
                            <i className="fa fa-solid fa-arrow-left"/>&nbsp;&nbsp; Go Back
                        </button>
                    </div>
                    <div className={"update-role-heading mb-1"}>Update Role</div>
                    <div className={"update_role_outer"}>

                        <div className={"role-name row mb-3 mt-4"}>
                            <div className={"col-md-3 roleName_heading"}>
                                Role Name
                            </div>
                            <div className={"col-md-8 "}>
                                <Form.Control disabled={true} value={toString(roleData.name)} required={true}
                                              type="text"/>
                            </div>
                        </div>
                        <div className={"permission-table row"}>
                            <div className={"col-md-3 roleName_heading"}>
                                Permissions
                            </div>
                            <div className={"col-md-8"}>
                                <div className="table-responsive">
                                    <table className="permission_table w-100">
                                        <thead>
                                        <tr>
                                            <th></th>
                                            <th>Read</th>
                                            <th>Create</th>
                                            <th>Write</th>
                                            <th>Delete</th>

                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            permissionLabels.map((permissionLabel, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{toString(permissionLabel)}</td>
                                                        <td>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox"
                                                                       value=""
                                                                       id="flexCheckChecked"
                                                                       onChange={() => handleCheckboxChange(permissionLabel + '_READ')}
                                                                       checked={selectedPermissions.includes(permissionLabel + '_READ')}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox"
                                                                       value=""
                                                                       id="flexCheckChecked"
                                                                       onChange={() => handleCheckboxChange(permissionLabel + '_CREATE')}
                                                                       checked={selectedPermissions.includes(permissionLabel + '_CREATE')}/>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox"
                                                                       value=""
                                                                       id="flexCheckChecked"
                                                                       onChange={() => handleCheckboxChange(permissionLabel + '_WRITE')}
                                                                       checked={selectedPermissions.includes(permissionLabel + '_WRITE')}/>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox"
                                                                       value=""
                                                                       id="flexCheckChecked"
                                                                       onChange={() => handleCheckboxChange(permissionLabel + '_DELETE')}
                                                                       checked={selectedPermissions.includes(permissionLabel + '_DELETE')}/>
                                                            </div>
                                                        </td>


                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>

                                    </table>
                                    <div className="btn_containerBox mt-3">
                                        <button className="modal_cancel_btn cmn_modal_btn mediumFontSize cancel_btn "
                                                onClick={() => {
                                                    navigate("/roles")
                                                }}>Cancel
                                        </button>
                                        <button  style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""} }
                                            disabled={(roles?.updateRoleDataReducer?.loading || jwtDecode(getDataFromLocalStorage("token"))?.roles[0] === roleData.name)}
                                            className=" modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize update_btn"
                                            onClick={handleOnUpdate}>Update
                                            {
                                                roles?.updateRoleDataReducer?.loading &&
                                                <TailSpinLoader/>
                                            }

                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>)
}
export default RolesDetail;