import search_icon from "../../images/search_icon.svg";
import {
    applyValidDateRange,
     formatAndComputeAutoDateRange, formatDate,
    getDataFromLocalStorage, handleApiResponse,
    isUnauthorized,
    tableCustomStyling,
    TableSkeletonLoading,
    trim,
} from "../../helpers/utils";
import filter_icon from "../../images/filter_icon.svg";
import {hasPermission, Permissions, Roles} from "../../helpers/accessControl";
import DataTable from "react-data-table-component";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import jwtDecode from "jwt-decode";
import {RiUserSharedLine} from "react-icons/ri";
import Pagination from "../commons/Pagination";
import {SearchFilterModal} from "../commons/SearchFilterModal";
import DatePicker from "react-datepicker";
import ConfirmationModel from "../commons/ConfirmationModel";
import AssignReflectionModal from "./modal/AssignReflectionModal";
import {getAssignedReflections, unAssignSurveyByOrgAdmin} from "../../reduxStore/surveySlices/surveySlices";
import {Survey} from "../../helpers/constants";
import image from "../../images/CrossIcon2.svg";

const AssignedReflections = ({teamId}) => {

    const token = jwtDecode(getDataFromLocalStorage("token"))
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [searchQuery, setSearchQuery] = useState({
        surveyName: null,
        tag: "",
        goal: "",
        createdDateRange: null
    })
    const [triggerApi, setTriggerApi] = useState(false)
    const [handleUnassignReflection, setHandleUnassignReflection] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [sortCriteria, setSortCriteria] = useState({
        sortBy: "createdAt",
        sortOrder: "desc",
    })
    const [reflectionToUnassign, setReflectionToUnassign] = useState(null)
    const [timer, setTimer] = useState(null)
    const [showSearchFilterModal, setShowSearchFilterModal] = useState(false)
    const [showUnassignReflectionConfirmationModal, setShowUnassignReflectionConfirmationModal] = useState(false)

    const [showAssignReflectionModal, setShowAssignReflectionModal] = useState(false)

    const getTeamByIdData = useSelector(state => state.team.getTeamByIdReducer)
    const getAssignedReflectionsData = useSelector(state => state.survey.getAssignedReflectionsReducer)

    const columns = [
        {
            name: <div className={"d-flex"}><span className={"ms-2 bulk_delete_Main_header"}> Name </span></div>,
            sortable: true,
            sortField: 'name',
            cell: (row) =>
                (
                    <div className={"d-flex"}>
                        <div className={"display_profile_data wrap-text ms-2"}>
                            <span
                                title={row.name}
                                className={"ps-2 wrap-text font-wt-500"}>
                                {trim(row.name, 45)}
                            </span>

                        </div>
                    </div>

                ),
            style: {
                fontFamily: 'General Sans Medium',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            },
            width: getAssignedReflectionsData?.loading ? "" : "30%"
        },

        {
            name: "Created Date",
            sortField: 'createdAt',
            sortable: true,
            cell: row => <div>{formatDate(row.createdAt)}</div>
        },

        {
            name: "Owner",
            selector: row => row.owner
        },

        {
            name: "Status",
            cell: row => (
                <div
                    className={row.status === Survey.SURVEY_STATUS_DRAFT ? "status-container inviteStatus_disable text-end" : "status-container inviteStatus text-end"}>
                    <span
                        className="badge badge-primary ">{row.status === Survey.SURVEY_STATUS_DRAFT ? "Draft" : "Published"}</span>
                </div>
            )
        },

        {
            name: "Action",
            cell: row => (
                <div>
                    <RiUserSharedLine
                        title={"Unassign Reflection"}
                        onClick={() => {
                            if (getTeamByIdData?.data?.createdBy === token?.userid) {
                                setReflectionToUnassign(row)
                                setShowUnassignReflectionConfirmationModal(true)
                            }
                        }}
                        color={getTeamByIdData?.data?.createdBy === token?.userid ? "red" : ""}
                        size={18}
                        className={getTeamByIdData?.data?.createdBy === token?.userid ? " ms-1 cursor cursor-pointer" : " ms-1  opacity5"}/>
                </div>
            ),
        }

    ]
    let searchFiltersTags = [
        {
            tagView: <div className="filter_input_wrapper">
                <input type={"text"}
                       className={"ms-0 form-control search-box-input-goal"}
                       placeholder={"Goal..."}
                       onChange={(e) => {
                           setSearchQuery({
                               ...searchQuery,
                               goal: e.target.value
                           })
                       }}
                       value={searchQuery.goal}/>
                <div className={"cross-btn-outer"}>
                    <img src={image}
                         className={"survey-search-clr-btn cursor-pointer"}
                         onClick={() => {
                             setSearchQuery({
                                 ...searchQuery,
                                 goal: ""
                             })
                         }}/>
                </div>
            </div>

        },
        {
            tagView: <div className={"filter_input_wrapper "}>
                <input type={"text"} className={"ms-0 form-control search-box-input-tag"}
                       value={searchQuery.tag}
                       onChange={(e) => {
                           setSearchQuery({...searchQuery, tag: e.target.value})
                       }}
                       placeholder={"Tag..."}/>
                <div className={"cross-btn-outer"}>
                    <img src={image}
                         className={"survey-search-clr-btn cursor-pointer"}
                         onClick={() => {
                             setSearchQuery({
                                 ...searchQuery,
                                 tag: ""
                             })
                         }}/>
                </div>
            </div>

        },
        {
            tagView: <div className={"members-date-picker-outer "}>
                <DatePicker placeholderText="From: dd-mm-yyyy" dateFormat={"dd-MM-yyyy"}
                            selected={searchQuery?.createdDateRange?.startDate || ""}
                            className={"search-by-survey-name survey-name-search member_search "}
                            maxDate={searchQuery?.createdDateRange?.endDate || new Date()}
                            onChange={(date) => {
                                setSearchQuery({
                                    ...searchQuery,
                                    createdDateRange: applyValidDateRange({
                                        startDate: date,
                                        endDate: searchQuery?.createdDateRange?.endDate || null
                                    })
                                })
                            }}
                />
                <DatePicker placeholderText="To: dd-mm-yyyy" dateFormat={"dd-MM-yyyy"}
                            selected={searchQuery?.createdDateRange?.endDate || ""}
                            minDate={searchQuery?.createdDateRange?.startDate || ""}
                            className={"search-by-survey-name survey-name-search user-join-toDate member_search "}
                            maxDate={new Date()}
                            onChange={(date) => {
                                setSearchQuery({
                                    ...searchQuery,
                                    createdDateRange: applyValidDateRange({
                                        startDate: searchQuery?.createdDateRange?.startDate || null,
                                        endDate: date
                                    })
                                })
                            }}
                />

            </div>
        }
    ]

    useEffect(() => {
        if (teamId !== null && teamId !== undefined) {
            setTriggerApi(true)
        }
    }, [teamId]);

    useEffect(() => {
        if (triggerApi && hasPermission([Permissions.REFLECTION_READ], true)) {
            const searchQueryToSubmit = {
                ...searchQuery,
                ...sortCriteria,
                page: pageNumber,
                teamId: teamId
            }
            if (searchQuery?.createdDateRange) {
                searchQueryToSubmit.createdDateRange = formatAndComputeAutoDateRange(searchQuery?.createdDateRange?.startDate || "", searchQuery?.createdDateRange?.endDate || "", "yyyy-MM-dd")
            }
            dispatch(getAssignedReflections(searchQueryToSubmit)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                setTriggerApi(false)
            })
        }
    }, [triggerApi]);

    useEffect(() => {
        if (searchQuery.surveyName !== null) {
            if (timer) {
                clearTimeout(timer);
            }
            setTimer(setTimeout(() => {
                setTriggerApi(true);
            }, 1000));
        }
    }, [searchQuery.surveyName]);

    useEffect(() => {
        if (handleUnassignReflection) {
            dispatch(unAssignSurveyByOrgAdmin({
                surveyId: reflectionToUnassign?.surveyId,
                subOrgId: teamId
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                const onSuccess = () => {
                    setTriggerApi(true)
                    handleCloseConfirmationBox();
                }
                handleApiResponse(res, onSuccess, handleCloseConfirmationBox)

            })
        }
    }, [handleUnassignReflection]);

    const setPageNumberAndTriggerApi = (page) => {
        setPageNumber(page)
        setTriggerApi(true)
    }
    const handleSort = (column, sortDirection,) => {
        setSortCriteria({
            ...sortCriteria,
            sortBy: column.sortField,
            sortOrder: sortDirection,
        })
        setTriggerApi(true)
    };
    const handleCloseConfirmationBox = () => {
        setReflectionToUnassign(null)
        setHandleUnassignReflection(false)
        setShowUnassignReflectionConfirmationModal(false)
    }

    return (
        <>
            <div className={"row main_content  sub-org-members-outer"}>
                <div className={"col-md-12   px-0"}>


                    <div className="d-flex col-12">
                        <div className="reflection_search_wrapper ">
                            <img src={search_icon} alt=""/>
                            <input type={"text"} className={"form-control members-name-filter-input"}
                                   placeholder={"Name..."}
                                   value={searchQuery.surveyName}
                                   onChange={(e) => {
                                       setSearchQuery({...searchQuery, surveyName: e.target.value})
                                   }}
                            />
                        </div>


                        <div className={"reflection_filters ms-auto"}>
                            <button type={"button"} onClick={() => {
                                setShowSearchFilterModal(true)
                            }}>
                                <img src={filter_icon} alt=""/>Filter
                            </button>
                        </div>

                        <div className={"members-btn"}>

                            {
                                token?.userid === getTeamByIdData?.data?.createdBy && <button
                                    style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                    className="cmn_background_color survey_addnew_btn mediumFontSize create-sub-org-btn ms-2"
                                    onClick={() => setShowAssignReflectionModal(true)}
                                >Assign Reflection
                                </button>
                            }


                        </div>
                    </div>


                </div>

                <div className={"colored_table p-0"}>
                    <DataTable
                        className={getAssignedReflectionsData?.data?.totalPages > 1 ? " table_design2 " : " table_design1 "}
                        persistTableHead={true} responsive={true}
                        customStyles={tableCustomStyling}
                        progressPending={getAssignedReflectionsData?.loading}
                        columns={columns}
                        data={getAssignedReflectionsData?.data?.data}
                        noDataComponent={<div className={"no-member-text"}>No Assigned Reflection Found for the
                            Team</div>}
                        progressComponent={<TableSkeletonLoading columns={5} rows={5}/>}
                        onSort={handleSort}
                        sortServer={true}
                    />

                    {
                        !getAssignedReflectionsData?.loading && getAssignedReflectionsData?.data && getAssignedReflectionsData?.data?.totalPages > 1 &&
                        <Pagination className={"pagination-border"}
                                    totalPages={getAssignedReflectionsData?.data?.totalPages} pageNumber={pageNumber}
                                    setPageNumber={setPageNumberAndTriggerApi}/>
                    }

                </div>

            </div>
            {
                showSearchFilterModal &&
                <SearchFilterModal title={"Attempts Filters"}
                                   show={showSearchFilterModal}
                                   filtersTag={searchFiltersTags}
                                   applyFilter={triggerApi}
                                   setApplyFilter={setTriggerApi}
                                   resetFilters={() => {
                                       setSearchQuery({
                                           surveyName: null,
                                           tag: "",
                                           goal: "",
                                           createdDateRange: null
                                       });
                                   }}
                                   handleClose={() => {
                                       setShowSearchFilterModal(false);
                                   }}
                                   setShow={setShowSearchFilterModal}
                />
            }
            {
                showAssignReflectionModal &&
                <AssignReflectionModal
                    show={showAssignReflectionModal}
                    setShow={setShowAssignReflectionModal}
                    teamId={teamId}
                    callback={() => {
                        setTriggerApi(true)
                    }}
                />
            }
            {
                showUnassignReflectionConfirmationModal &&
                <ConfirmationModel title={"Unassign Reflection"}
                                   btnText={"Unassign"}
                                   action={() => {
                                       setHandleUnassignReflection(true)
                                   }}
                                   show={showUnassignReflectionConfirmationModal}
                                   bodyMessage={"Are you sure you want to unassign this reflection?"}
                                   setShow={handleCloseConfirmationBox}
                                   isLoading={false}
                                   showUserAcknowledgementCheckBox={false}
                />
            }
        </>
    );
}
export default AssignedReflections