import Modal from "react-bootstrap/Modal";
import {hasPermission, Permissions, roleHasAccess, Roles} from "../../../helpers/accessControl";
import Skeleton from "react-loading-skeleton";
import React, {useEffect, useState} from "react"
import {
    createOptionListForSelectTagWithRole,
    getDataFromLocalStorage,
    isUnauthorized, showToast
} from "../../../helpers/utils";
import {
    getAllUsers,
    getAllUsersWithoutPagination,
    getUserById,
    updateUserById
} from "../../../reduxStore/usersSlices/userSlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import jwtDecode from "jwt-decode";
import {TailSpinLoader} from "../../layout/Loader";
import Select from "react-select";
import {IoIosClose} from "react-icons/io";


const AssignMember = ({show, setShow ,userId,userData, setUserData, query,setQuery, isSubOrg, subOrgId}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const allUsersWithoutPaginationData = useSelector(state => state.users.getAllUsersWithoutPaginationReducer);
    const users = useSelector(state => state.users)

    const handleClose = () => setShow(false);
    const [userDetail, setUserDetail] = useState(null);
    const [roles, setRoles] = useState(null);
    const [assignMember, setAssignMember] = useState([])

    useEffect( ()=>{
        if (hasPermission([Permissions.USER_READ], true) && userDetail !== null) {

            if(userDetail.roles[0].name === "MENTEE"){
                setRoles([Roles.MENTOR,Roles.MANAGER,Roles.ORG_ADMIN,Roles.SUB_ORG_ADMIN])
            }else if(userDetail.roles[0].name === "MENTOR"){
                setRoles([Roles.MANAGER,Roles.ORG_ADMIN,Roles.SUB_ORG_ADMIN,Roles.MENTOR])
            }else if(userDetail.roles[0].name === "REPORT_WRITER"){
                setRoles([Roles.MENTOR,Roles.MANAGER,Roles.ORG_ADMIN,Roles.REFLECTION_WRITER,Roles.SUB_ORG_ADMIN])
            }else if(userDetail.roles[0].name === "REFLECTION_WRITER"){
                setRoles([Roles.MENTOR,Roles.MANAGER,Roles.ORG_ADMIN,Roles.SUB_ORG_ADMIN])
            }else if(userDetail.roles[0].name === "MANAGER"){
                setRoles([Roles.ORG_ADMIN,Roles.SUB_ORG_ADMIN])
            }
        }

    },[userDetail])

    useEffect( () =>{
        if(roles !== null){
            dispatch(getAllUsersWithoutPagination({
                roles:roles,
                subOrgId:subOrgId,
                orgId:jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === "SYSTEM" ? userDetail?.orgId:null
            })).then(res => {

                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }

    },[roles])

    useEffect( ()=>{

        if(allUsersWithoutPaginationData?.data !== null &&  allUsersWithoutPaginationData?.data !== undefined ){
          const data =  allUsersWithoutPaginationData?.data?.filter(member => userDetail?.mentorIds?.map(c=> c).includes(member.uid))
            if(data.length > 0){
                setAssignMember(createOptionListForSelectTagWithRole(data, "firstName", "uid"))
            }
        }
    },[allUsersWithoutPaginationData])

    useEffect( ()=>{

        if(userId !== null && userId !== undefined){
            dispatch(getUserById(userId))
                .then((response) => {
                    if (isUnauthorized(response)) {
                        navigate("/")
                    }
                    if (response.meta.requestStatus === "fulfilled") {
                        setUserDetail(response.payload.data)

                    }
                    else if (response.meta.requestStatus === "rejected") {
                        if (response?.payload?.errorCode?.value === 400) {
                            showToast(response.payload.detailDesc,"error")
                        }

                    }
                })
        }

    },[userId])

    const handleSubmit = (e) => {
        e.preventDefault();
            dispatch(updateUserById({
                userId: userId,
                firstName: userDetail.firstName,
                lastName: userDetail.lastName,
                orgId: jwtDecode(getDataFromLocalStorage("token"))?.orgId,
                enable: jwtDecode(getDataFromLocalStorage("token"))?.userid === userId,
                roles: [userDetail?.roles[0].name],
                tags:userDetail?.tags,
                mentorIds: assignMember.length === 0 ? userDetail?.mentorIds: assignMember.map( data => data?.value)
            })).then((res) => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (res.meta.requestStatus === "fulfilled") {
                    setShow(false)

                    if(query !== null){
                        const searchQueryToBeSubmitted = {...query, orgId:  query?.orgId?.value === undefined ? null :query?.orgId?.value,   ...(query?.mentorId ? {mentorId:query?.mentorId.value} :{}) , ...(query?.assignTo ? {mentorId:query?.assignTo.value} :{})}
                        dispatch(getAllUsers({
                            ...searchQueryToBeSubmitted,
                            sortOrder: "desc",
                            sortBy: "createdAt",
                            page: 1,
                            subOrgId: subOrgId !== null ? subOrgId:"",
                            pageSize: 10,
                        })).then(res => {
                            if (isUnauthorized(res)) {
                                navigate("/")
                            }
                        });
                    }
                    else{
                        dispatch(getUserById(userId))
                            .then((response) => {
                                if (isUnauthorized(response)) {
                                    navigate("/")
                                }
                                if (response.meta.requestStatus === "fulfilled") {
                                    setUserData(response.payload.data)
                                }
                                else if (response.meta.requestStatus === "rejected") {
                                    if (response?.payload?.errorCode?.value === 400) {
                                        showToast(response.payload.detailDesc,"error")
                                    }

                                }
                            })
                    }

                }
            })
    }


    return(

        <>
            <Modal show={show} centered>
                <Modal.Header className={"d-flex"}>
                    <Modal.Title className='add_member_heading mediumFontSize'>Assign Member</Modal.Title>
                    <IoIosClose className={"close_btn"} onClick={handleClose} size={24}/>
                </Modal.Header>

                <form onSubmit={handleSubmit}>
                <Modal.Body className="modal_outer">



                    { roleHasAccess([Roles.MENTOR,Roles.ORG_ADMIN, Roles.MANAGER,Roles.SUB_ORG_ADMIN,Roles.SYS_ADMIN]) &&
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="userInfo_label mediumFontSize ms-2"> Assigned
                                To</label>
                            {
                                ( allUsersWithoutPaginationData?.loading) ?
                                    <div className={"roles-loading"}>
                                        <Skeleton height={40}/>
                                    </div> :

                                    <>

                                        <Select
                                            className="members-filter-role react-select-container mt-2"
                                            classNamePrefix="react-select"
                                            isDisabled={allUsersWithoutPaginationData?.loading}
                                            isMulti
                                            value={assignMember}
                                            options={createOptionListForSelectTagWithRole(allUsersWithoutPaginationData?.data, "firstName", "uid")}
                                            onChange={(selectData) => {
                                                setAssignMember(Array.isArray(selectData) ? selectData : [selectData])

                                            }}
                                            placeholder={"Mentor/Manager..."}/>

                                    </>

                            }
                        </div>
                    }

                </Modal.Body>
                <Modal.Footer className="modal_footer">

                    <button className="modal_cancel_btn cmn_modal_btn mediumFontSize" onClick={handleClose}>Cancel
                    </button>
                    {
                        hasPermission([Permissions.REFLECTION_READ,Permissions.ATTEMPT_REFLECTION_READ, Permissions.ATTEMPT_REFLECTION_CREATE], true) &&
                        <button style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""} } type="submit" disabled={users?.updateUserByIdReducer?.loading === true} className= {allUsersWithoutPaginationData.loading === true ?"disable-btn ms-2 modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize " : "ms-2 modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize "}> Assign Member

                            {users?.updateUserByIdReducer?.loading && (
                                <TailSpinLoader/>)}

                        </button>
                    }

                </Modal.Footer>
            </form>
            </Modal>
        </>
    )

}

export default AssignMember;