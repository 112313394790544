import {useNavigate, useParams} from "react-router-dom";
import {Card} from "react-bootstrap";
import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    attemptSurveyCompletePublicly,
    getSurveyById
} from "../../reduxStore/surveySlices/surveySlices";
import {getQueryParam, groupQuestionsBySegmentIndex, isUnauthorized, showToast, trim} from "../../helpers/utils";
import {AnswerRequiredQuestions, InvalidAnswerForNumberQuestion, QuestionType} from "../../helpers/constants";
import ResultModal from "./modal/ResultModal";
import logo from "../../images/PulseIcon.svg";
import {TailSpinLoader} from "../layout/Loader";
import {CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {FiTrash2} from "react-icons/fi";
import {IoIosArrowForward} from "react-icons/io";


const PublicSurveyAnswer = () => {

    const {token} = useParams();
    const [surveyId, setSurveyId] = useState(null)
    const [tokenId, setTokenId] = useState(null)
    const [data, setData] = useState({
        surveyId: null,
        surveyorId: null,
        surveyeeEmail: null,
    })
    const [currentSection, setCurrentSection] = useState(0);
    const [surveyData, setSurveyData] = useState(null);
    const [segmentType, setSegmentType] = useState(null);
    const [refData, setRefData] = useState([]);
    const [disableSaveAsDraftButton, setDisableSaveAsDraftButton] = useState(true)
    const [disableSubmitButton, setDisableSubmitButton] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const survey = useSelector(state => state.survey)
    const resultData = useSelector(state => state.survey.attemptSurveyCompletePubliclyDataReducer)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [answerSurveyData, setAnswerSurveyData] = useState({
        id: "",
        surveyName: "",
        surveyId: "", surveyeeId: "", attemptQuestions: [{
            questionId: "", questionType: "", selectedOption: {optionId: "", optionText: "", optionValue: ""},
            answerText: "",
            answerNumber: null
        }]
    })

    useEffect(() => {
        if (token !== null && token !== undefined) {
            const decryptedToken = atob(token).trim()
            setData({
                ...data,
                surveyId: getQueryParam(decryptedToken, "surveyId"),
                surveyorId: getQueryParam(decryptedToken, "surveyorId"),
                surveyeeEmail: getQueryParam(decryptedToken, "surveyeeEmail"),
            })
        }
    }, [token]);

    useEffect(() => {
        if (data?.surveyId !== null) {
            dispatch(getSurveyById(data?.surveyId)).then((res) => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (res.meta.requestStatus === "fulfilled") {
                    setSurveyData(res.payload.data)
                    setSegmentType(res.payload.data?.segmentType)
                }
            })
        }
    }, [data])

    useEffect(() => {
        if (segmentType !== null && segmentType === "TABULAR") {
            setRefData(groupQuestionsBySegmentIndex(surveyData?.segments, surveyData?.questions))
        }
    }, [segmentType])

    const handleAttemptQuestions = (index, question, answer) => {
        let attemptQuestions = {
            questionId: question.questionId,
            questionType: question.type,
            selectedOption: question.type === QuestionType.OPTION ? {
                optionId: answer.optionId, optionText: answer.optionText, optionValue: answer.optionValue
            } : null,
            answerText: question.type === QuestionType.TEXT ? answer : null,
            answerNumber: question.type === QuestionType.NUMBER ? answer === "" ? null : parseInt(answer) : null
        }
        const updatedAttemptQuestions = [...answerSurveyData.attemptQuestions];
        updatedAttemptQuestions[index] = attemptQuestions;

        setAnswerSurveyData({
            ...answerSurveyData, attemptQuestions: updatedAttemptQuestions,
        });
    }

    const eliminateNotAttemptedQuestions = (answerSurveyData = {}) => {
        const updatedAttemptQuestions = answerSurveyData.attemptQuestions.filter(attemptQuestion =>
            (attemptQuestion !== undefined && attemptQuestion !== null) && attemptQuestion.questionId.trim() !== "" && (attemptQuestion.questionType === QuestionType.TEXT ? attemptQuestion.answerText.trim() !== "" : true) && (attemptQuestion.questionType === QuestionType.NUMBER ? attemptQuestion.answerNumber !== null && typeof attemptQuestion.answerNumber === 'number' : true))
        return {...answerSurveyData, attemptQuestions: updatedAttemptQuestions};
    }

    const handleNextSection = () => {
        if (currentSection < refData?.length - 1) {
            setCurrentSection(currentSection + 1);
        }
    };

    const handlePrevSection = () => {
        if (currentSection > 0) {
            setCurrentSection(currentSection - 1);
        }
    };

    const handleSubmitAttemptSurvey = () => {

        let message = checkSurveyAnswersValidity(surveyData.questions, answerSurveyData.attemptQuestions)
        if (message) {
            showToast(message, "error")
        } else {
            const requiredQuestions = surveyData.questions.filter(question => question.isRequired);
            const areAllRequiredQuestionsAnswered = requiredQuestions.every(requiredQuestion => answerSurveyData.attemptQuestions.some(attemptQuestion => attemptQuestion?.questionId === requiredQuestion.questionId && (requiredQuestion.type === QuestionType.TEXT ? attemptQuestion.answerText.trim() !== "" : true) && (requiredQuestion.type === QuestionType.NUMBER ? attemptQuestion.answerNumber !== null : true)));
            if (!areAllRequiredQuestionsAnswered) {
                showToast(AnswerRequiredQuestions, "error")
            } else {

                const updatedAnswerSurveyData = eliminateNotAttemptedQuestions(answerSurveyData)
                dispatch(attemptSurveyCompletePublicly({
                    ...updatedAnswerSurveyData,
                    surveyName: surveyData.name,
                    id: "",
                    surveyId: data?.surveyId,
                    surveyeeId: "",
                    surveyorId: data?.surveyorId,
                    surveyeeEmail: data?.surveyeeEmail
                })).then(res => {
                    if (isUnauthorized(res)) {
                        navigate("/")
                    } else if (res.meta.requestStatus === "fulfilled") {
                        setShowModal(true)

                    }
                })
            }
        }
    }

    const clearData = () => {
        setAnswerSurveyData({
            id: "",
            surveyName: "",
            surveyId: "", surveyeeId: "", attemptQuestions: [{
                questionId: "", questionType: "", selectedOption: {optionId: "", optionText: "", optionValue: ""},
                answerText: "",
                answerNumber: ""
            }]
        });
    }

    const checkSurveyAnswersValidity = (questions, attemptQuestions) => {
        let message;
        const attemptedNumberQuestions = attemptQuestions.filter(attemptQuestion => attemptQuestion?.questionType === QuestionType.NUMBER)
        outerLoop: for (let index = 0; index < questions.length; index++) {
            for (const attemptQuestion of attemptedNumberQuestions) {
                if (questions[index].type === QuestionType.NUMBER && questions[index].questionId === attemptQuestion.questionId && attemptQuestion.answerNumber !== null && !(attemptQuestion.answerNumber >= questions[index].number.min && attemptQuestion.answerNumber <= questions[index].number.max)) {
                    message = InvalidAnswerForNumberQuestion + ` ${index + 1}`;
                    break outerLoop;
                }
            }
        }
        return message
    }

    return (
        <div className={"cmn_bg pb-5"}>

            <div className={"header-attempt"}>
                <img width={100} src={logo} alt=""/>

                <div className={""}
                     title={surveyData !== null && surveyData.name}>{trim(surveyData !== null && surveyData.name, 50)}</div>

                <div className={" clear_btn"}>
                    <button onClick={clearData}><FiTrash2 className={"me-1"}/> Clear Form</button>
                </div>
            </div>

            <div className="attempt_wrapper">

                <div className={"outer-border"}>
                    <div className={"options-list-heading"}>
                        {segmentType !== "TABULAR"
                            &&
                            <>
                                <ul className="inner_item">
                                    {surveyData !== null && surveyData.questions.map((question, index) => {
                                        return (<li key={index}>
                                            <Card className='public-survey'>
                                                <Card.Body>
                                                    {question.isRequired && <div
                                                        className={"required-text-outer text-center"}>Required</div>}
                                                    <div className="question-container">
                                                        <div className={"question-numbering"}>{index + 1 + ". "}</div>
                                                        <div className={"question "}>{question.questionText}</div>

                                                    </div>
                                                    {
                                                        question.type === QuestionType.NUMBER &&
                                                        <div className={"options-list-container mt-2"}>
                                                            <div className={"number-data pe-0"}>

                                                                <input type={"number"}
                                                                       className={"form-control number-answer-input"}
                                                                       min={question.number.min}
                                                                       max={question.number.max}
                                                                       onWheel={(e) => {
                                                                           document.activeElement.blur();
                                                                       }}
                                                                       onKeyPress={(e) => {
                                                                           const isValidKey = /^\d*$/.test(e.key);
                                                                           if (!isValidKey) {
                                                                               e.preventDefault();
                                                                           }
                                                                       }}
                                                                       onKeyDown={(e) => {
                                                                           if (e.key === 'e' || e.key === 'E' || e.key === '.' || e.key === "-") {
                                                                               e.preventDefault();
                                                                           }
                                                                       }}
                                                                       value={answerSurveyData.attemptQuestions[index]?.answerNumber || ""}
                                                                       placeholder={"Answer here..."} onChange={(e) => {
                                                                    handleAttemptQuestions(index, question, e.target.value)

                                                                }}/>
                                                                <div className={"range-div"}>
                                                                    <span> {"Rate between " +question.number.min + " to " + question.number.max}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        question.type === QuestionType.TEXT &&
                                                        <div className={"options-list-container mt-2"}>
                                                            <div className={"text-question-data"}>
                                                         <textarea placeholder={"Answer here"} onChange={(e) => {
                                                             handleAttemptQuestions(index, question, e.target.value)

                                                         }}
                                                                   value={answerSurveyData.attemptQuestions[index]?.answerText || ""}
                                                                   rows={3} className={"form-control"}/>
                                                            </div>

                                                        </div>
                                                    }
                                                    {
                                                        question.type === QuestionType.OPTION &&
                                                        <div className={"mt-2 option-data"}>
                                                            {question.options.map((option, optionIndex) => {
                                                                return (<div key={optionIndex}
                                                                             className={"attempt-option-select-container mt-2 "}>
                                                                    <div className={"option-text-container me-0"}>
                                                                        <div
                                                                            className={"answer-question-option-heading "}>

                                                                            <input type={"radio"}
                                                                                   checked={answerSurveyData.attemptQuestions[index] ? option.optionId === answerSurveyData.attemptQuestions[index].selectedOption.optionId : false}
                                                                                   onChange={() => {
                                                                                       if (disableSaveAsDraftButton) {
                                                                                           setDisableSaveAsDraftButton(false)
                                                                                       }
                                                                                       if (disableSubmitButton) {
                                                                                           setDisableSubmitButton(false)
                                                                                       }
                                                                                       handleAttemptQuestions(index, question, option)
                                                                                   }}
                                                                                   name={"answer" + (index)}/>


                                                                            <div
                                                                                className={"options"}>{option.optionText}</div>
                                                                        </div>

                                                                    </div>


                                                                </div>);
                                                            })}
                                                        </div>
                                                    }

                                                </Card.Body>
                                            </Card>
                                        </li>);
                                    })}
                                </ul>
                            </>

                        }
                        {
                            segmentType === "TABULAR"
                            &&
                            <>
                                <div className={"section-name text-center mb-1"}>{refData[currentSection]?.segmentName || "Section "+(currentSection+1)}</div>
                                <div className={"horizontal-line section-name-desc-divider"}></div>
                                <div className={"section-desc mb-4 text-center mt-1"}>{refData[currentSection]?.segmentDescription}</div>
                                <ul className="inner_item">
                                    {surveyData !== null && surveyData.questions.map((question, index) => {

                                        if (question.segmentIndex === refData[currentSection]?.index) {
                                            return (<li key={index}>
                                                <Card className='public-survey'>
                                                    <div>
                                                        {question.isRequired && <div
                                                            className={"required-text-outer text-center"}>Required</div>}
                                                        <div className="question-container">
                                                            <div
                                                                className={"question-numbering"}>{index + 1 + ". "}</div>
                                                            <div className={"question "}>{question.questionText}</div>

                                                        </div>
                                                        {
                                                            question.type === QuestionType.NUMBER &&
                                                            <div className={"options-list-container mt-2 "}>

                                                                <div className={"number-data pe-0"}>

                                                                    <input type={"number"}
                                                                           pattern="[0-9]*" inputMode={"numeric"}
                                                                           className={"form-control number-answer-input"}
                                                                           min={question.number.min}
                                                                           max={question.number.max}
                                                                           onWheel={(e) => {
                                                                               document.activeElement.blur();
                                                                           }}
                                                                           onKeyPress={(e) => {
                                                                               const isValidKey = /^\d*$/.test(e.key);
                                                                               if (!isValidKey) {
                                                                                   e.preventDefault();
                                                                               }
                                                                           }}
                                                                           onKeyDown={(e) => {
                                                                               if (e.key === 'e' || e.key === 'E' || e.key === '.' || e.key === "-") {
                                                                                   e.preventDefault();
                                                                               }
                                                                           }}
                                                                           value={answerSurveyData.attemptQuestions[index]?.answerNumber || ""}
                                                                           placeholder={"Answer here..."}
                                                                           onChange={(e) => {
                                                                               handleAttemptQuestions(index, question, e.target.value)

                                                                           }}/>
                                                                    <div className={"range-div"}>
                                                                        <span> {"Rate between " +question.number.min + " to " + question.number.max}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            question.type === QuestionType.TEXT &&

                                                            <div className={"options-list-container mt-2"}>
                                                                <div className={"text-question-data"}>
                                                                                     <textarea
                                                                                         placeholder={"Answer here"}
                                                                                         onChange={(e) => {
                                                                                             const numericValue = e.target.value.replace(/\D/g, '');

                                                                                             handleAttemptQuestions(index, question, e.target.value)
                                                                                         }}
                                                                                         value={answerSurveyData.attemptQuestions[index]?.answerText || ""}
                                                                                         rows={3}
                                                                                         className={"form-control"}/>
                                                                </div>

                                                            </div>

                                                        }
                                                        {
                                                            question.type === QuestionType.OPTION &&
                                                            <div className={"mt-2 option-data"}>
                                                                {question.options.map((option, optionIndex) => {
                                                                    return (<div key={optionIndex}
                                                                                 className={"attempt-option-select-container mt-2 "}>
                                                                        <div className={"option-text-container me-0"}>
                                                                            <div
                                                                                className={"answer-question-option-heading "}>

                                                                                <input type={"radio"}
                                                                                       inputMode="numeric"
                                                                                       checked={answerSurveyData.attemptQuestions[index] ? option.optionId === answerSurveyData.attemptQuestions[index].selectedOption.optionId : false}
                                                                                       onChange={() => {
                                                                                           if (disableSaveAsDraftButton) {
                                                                                               setDisableSaveAsDraftButton(false)
                                                                                           }
                                                                                           if (disableSubmitButton) {
                                                                                               setDisableSubmitButton(false)
                                                                                           }
                                                                                           handleAttemptQuestions(index, question, option)
                                                                                       }}
                                                                                       name={"answer" + (index)}/>


                                                                                <div
                                                                                    className={"options"}>{option.optionText}</div>
                                                                            </div>

                                                                        </div>


                                                                    </div>);
                                                                })}
                                                            </div>
                                                        }

                                                    </div>
                                                </Card>
                                            </li>);
                                        }

                                    })}
                                </ul>
                            </>

                        }


                    </div>
                </div>
                <div className={"section-custom "}>

                    <div className={'next-button'}>
                        {segmentType !== "LINEAR" &&

                            <div className={"custom-circular-bar"}>
                                <div style={{width: 40, height: 40}}>

                                    <CircularProgressbar value={(currentSection + 1) * (100 / refData?.length)}/>
                                </div>
                                <span className={"ms-2"}> {currentSection + 1} / {refData?.length}</span>
                            </div>
                        }

                        <div className={"ms-auto"}>
                            {currentSection !== 0 &&
                                <button className={"me-2"} onClick={handlePrevSection}>Previous</button>
                            }

                            {refData.length > 1 && refData.length !== (currentSection + 1) &&
                                <button className={"cmn_background_color"} onClick={handleNextSection}>Next</button>
                            }

                            {(refData.length === (currentSection + 1) || segmentType === "LINEAR") &&
                                <button
                                    data-tooltip-id="SubmitReflectionButton"
                                    onClick={handleSubmitAttemptSurvey}
                                    disabled={survey.attemptSurveyCompletePubliclyDataReducer?.loading}
                                    className={"modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize margin-left "}>
                                    Submit
                                    {survey.attemptSurveyCompletePubliclyDataReducer?.loading && <TailSpinLoader/>}

                                </button>
                            }


                        </div>


                    </div>


                </div>
            </div>

            {
                showModal &&
                <ResultModal show={showModal} setShow={setShowModal} data={resultData} segmentType={segmentType}
                             setCurrentSection={setCurrentSection} resetForm={clearData}/>
            }

        </div>
    )

}

export default PublicSurveyAnswer;