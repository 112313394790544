import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getAxiosConfig, serialize, showToast} from "../../helpers/utils";

export const getAllRoles = createAsyncThunk('roles/getAllRoles', async (subOrgId = null, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/role?${serialize({subOrgId: subOrgId})}`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})
export const getRoleById = createAsyncThunk('roles/getRoleById', async (roleId, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/role/${roleId}`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})
export const updateRole = createAsyncThunk('roles/updateRole', async (data, thunkAPI) => {
    return await axios.put(`${process.env.REACT_APP_BASE_URL}/role/${data.roleId}`, {
        name: data.name,
        authorityLevel: data.authorityLevel,
        permission: data.permissionsIds
    }, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

const rolesSlice = createSlice({
    name: "roles",
    initialState: {
        getAllRolesDataReducer: {loading: false},
        getRoleByIdDataReducer: {loading: false},
        updateRoleDataReducer: {loading: false}
    },
    extraReducers: {

        // Get All Roles
        [getAllRoles.pending]: (state) => {
            state.getAllRolesDataReducer = {loading: true}
        },
        [getAllRoles.fulfilled]: (state, action) => {
            state.getAllRolesDataReducer = {loading: false, data: action.payload.data}
        },
        [getAllRoles.rejected]: (state, action) => {
            state.getAllRolesDataReducer = {loading: false}
            showToast(action?.payload?.detailDesc || action?.payload?.statusText, "error")
        },
        // Get Role By Id
        [getRoleById.pending]: (state) => {
            state.getRoleByIdDataReducer = {loading: true}
        },
        [getRoleById.fulfilled]: (state, action) => {
            state.getRoleByIdDataReducer = {loading: false, data: action.payload.data}
        },
        [getRoleById.rejected]: (state, action) => {
            state.getRoleByIdDataReducer = {loading: false}
            if (!(action.payload.errorCode.value === 400 || action.payload.errorCode.value === 404)) {
                showToast(action?.payload?.detailDesc || action?.payload?.statusText, "error")
            }
        },
        // Update Role
        [updateRole.pending]: (state) => {
            state.updateRoleDataReducer = {loading: true};
        },
        [updateRole.fulfilled]: (state, action) => {
            state.updateRoleDataReducer = {loading: false, data: action.payload.data};
            showToast(action.payload.data || action?.payload?.statusText, "success")
        },
        [updateRole.rejected]: (state, action) => {
            state.updateRoleDataReducer = {loading: false};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText, "error")
        }

    }

})
export const {} = rolesSlice.actions

export default rolesSlice.reducer;