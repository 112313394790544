import {createAction, createSlice} from "@reduxjs/toolkit";


export const resetReducers = createAction('resetReducers');

const globalKeysSlice = createSlice({
    name:"globalKeys",
    initialState: {
        loggedInUserFullName: "",
        toggleSidebar:false,
        showCreateSubOrganizationBtn:null,

    },
    reducers: {
        toggleSidebar: (state, action) => {
            state.toggleSidebar = action.payload
        },
        setLoggedInUserName: (state, action) => {
            state.loggedInUserFullName=action.payload

        },
        setShowCreateSubOrganizationBtn: (state, action) => {
            state.showCreateSubOrganizationBtn=action.payload

        },
    },
})
export const {toggleSidebar,setLoggedInUserName,setShowCreateSubOrganizationBtn} = globalKeysSlice.actions
export default globalKeysSlice.reducer;