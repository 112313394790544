import logo from "../../images/PulseIcon.svg";
import UserInfo from "../layout/UserInfo";
import AnswerSurvey from "../members/AnswerSurvey";

const AnswerSurveyMentee = () => {
    return (
        <div className={"cmn_bg pb-5"}>
            <div className={"header-assigned-reflection"}>
                <img width={100} src={logo} alt=""/>
                <h5 className={"mediumFontSize reflection_heading"}>Reflections</h5>
                <UserInfo showAddReflectionBox={false}/>
            </div>
            <div className=" assigned-reflections-wrapper">
                <div className={"  answer-assigned-reflections-outer mt-3"}>
                    <div className={"options-list-heading "}>
                        <AnswerSurvey/>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AnswerSurveyMentee