import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState} from "react";
import {hasPermission, Permissions} from "../../../helpers/accessControl";
import {useDispatch, useSelector} from "react-redux";
import {getUserReportsBySurveyIdAndRiskLevel} from "../../../reduxStore/reportsSlice/reportsSlice";
import {
    isUnauthorized,
    tableCustomStyling,
    TableSkeletonLoading,
    trim
} from "../../../helpers/utils";
import {useNavigate} from "react-router-dom";
import close_square from "../../../images/croosIcon.png";
import DataTable from "react-data-table-component";
import Pagination from "../../commons/Pagination";
import ProfilePicPlaceHolder from "../../commons/ProfilePicPlaceHolder";

const MembersListModal = ({show, setShow,data,orgId}) => {

    console.log("orgId  ==>", orgId)


    const handleClose = () => setShow(false);
    const navigate=useNavigate();
    const dispatch = useDispatch();
    const [pageNumber, setPageNumber] = useState(1)


    const [sortBy,setSortBy]=useState("createdAt");
    const [sortOrder,setSortOrder]=useState("desc");
    const handleSort =  (column, sortDirection,) => {
        setSortBy(column.sortField)
        setSortOrder(sortDirection)
    };

    const reports = useSelector(state => state.reports)
    const columns = [
        {
            name: "Name",
            selector: row => row.firstName,
            sortField: 'firstName',
            sortable: true,
            cell: (row) =>
                (
                    <>
                        <div className={"display_profile_data wrap-text"}>
                            <ProfilePicPlaceHolder
                                letters={(row.lastName === "" || row.lastName === undefined) ? row.firstName.substring(0, 2) : row.firstName[0] + row.lastName[0]}/>
                            <span title={row.firstName+" "+row.lastName}
                                  className={"org-name-heading wrap-text"}>{trim(row.firstName+" "+row.lastName, 50)}</span>
                        </div>
                    </>

                ),
            style: {
                fontFamily: 'General Sans Medium',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            }
        },
        {name: "Email",  sortField: 'email',selector: row => row.email, sortable: true, style: {opacity: ".7",}},
    ]
    useEffect(() => {
        if (hasPermission([Permissions.REPORT_READ], true)) {
            dispatch(getUserReportsBySurveyIdAndRiskLevel({...data,page:pageNumber,sortOrder:sortOrder,sortBy:sortBy,orgId:orgId?.value})).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }
    }, [pageNumber,sortBy,sortOrder])
    return (
        <>
            <Modal className='mt-4' show={show} centered>
                <Modal.Header>
                    <Modal.Title className='mediumFontSize'>Members</Modal.Title>
                    <div className="closeImg" onClick={handleClose}>
                        <img src={close_square} alt="" width={"15"}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="modal_outer">
                    <DataTable className={"members-report-table "+(reports?.getUserReportsBySurveyIdAndRiskLevelDataReducer?.data?.totalPages > 1?" table_design2":" table_design1")}
                               persistTableHead={true} responsive={true}
                               noDataComponent={<div className={"lightFontSize cmn_fontStyle mt-2 mb-2"}>No Member Found</div>}
                               customStyles={tableCustomStyling}
                               columns={columns}
                               progressPending={reports?.getUserReportsBySurveyIdAndRiskLevelDataReducer?.loading}
                               progressComponent={<div
                                   className={"w-100 members-report-table "}>
                                   <TableSkeletonLoading columns={2} rows={5}/></div>}
                               data={reports?.getUserReportsBySurveyIdAndRiskLevelDataReducer?.data?.data}
                               onSort={handleSort} sortServer={true}
                    />
                    {
                        !reports?.getUserReportsBySurveyIdAndRiskLevelDataReducer?.loading && reports?.getUserReportsBySurveyIdAndRiskLevelDataReducer?.data && reports?.getUserReportsBySurveyIdAndRiskLevelDataReducer?.data?.totalPages > 1 &&
                        <Pagination totalPages={ reports?.getUserReportsBySurveyIdAndRiskLevelDataReducer?.data?.totalPages} pageNumber={pageNumber}
                                    setPageNumber={setPageNumber}/>
                    }
                </Modal.Body>

                <Modal.Footer className="modal_footer">
                    <button className="modal_cancel_btn cmn_modal_btn mediumFontSize"
                            onClick={handleClose}>Cancel
                    </button>
                </Modal.Footer>
            </Modal>
        </>

    );
}
export default MembersListModal;