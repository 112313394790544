import {NavLink, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from "react"
import './Member.css';
import {
    bulkUserDelete,
    deleteUserById, exportMembers,
    getAllUsers,
    getAllUsersWithoutPagination, getUserById,
} from '../../reduxStore/usersSlices/userSlice'
import AddMemberModal from "./modal/AddMemberModal";
import {useDispatch, useSelector} from "react-redux";
import Pagination from "../commons/Pagination";
import {hasPermission, Permissions, roleHasAccess, Roles} from "../../helpers/accessControl";
import {
    applyValidDateRange, BulkDeleteIcon,
    createOptionListForSelectTag,
    createOptionListForSelectTagWithRole,
    createSelectedValuesForSelectTag,
    formatAndComputeAutoDateRange,
    formatDate,
    getDataFromLocalStorage,
    getFirstFiveElements, handleApiResponse,
    isUnauthorized,
    resourceToFile, showToast,
    tableCustomStyling,
    TableSkeletonLoading,
} from "../../helpers/utils";
import ErrorPage from "../errorPage/ErrorPage";
import {AccessDenied, AccessDeniedMsg} from "../../helpers/constants";
import {toString} from "../../helpers/utils";
import DataTable from 'react-data-table-component';
import {getInitials} from "../../helpers/utils";
import ProfilePicPlaceHolder from "../commons/ProfilePicPlaceHolder";
import Select from "react-select";
import DatePicker from "react-datepicker";
import {getAllRoles} from "../../reduxStore/rolesSlices/rolesSlices";
import {getAllOrgAsList} from "../../reduxStore/orgSlices/orgSlices";
import jwtDecode from "jwt-decode";
import ConfirmationModel from "../commons/ConfirmationModel";
import AssignMember from "./modal/AssignMember";
import CreatableSelect from "react-select/creatable";
import {toast} from "react-toastify";
import ImportUsersModal from "./modal/ImportUsersModal";
import {TailSpinLoader} from "../layout/Loader";
import UpdateUserModal from "./modal/UpdateUserModal";
import {Tooltip as ReactTooltip} from "react-tooltip";
import search_icon from "../../images/search_icon.svg";
import filter_icon from "../../images/filter_icon.svg";
import {SearchFilterModal} from "../commons/SearchFilterModal";
import UserInfo from "../layout/UserInfo";
import {FiTrash2} from "react-icons/fi";
import {GoArrowSwitch, GoPencil} from "react-icons/go";
import {MdOutlineAssignmentInd,} from "react-icons/md";
import MoveMemberAndOrgModal from "./modal/MoveMemberAndOrgModal";


const components = {
    DropdownIndicator: null,
};

const tagOption = (label) => ({
    label: label,
    value: label,
});
const Member = ({isSubOrg = false, subOrgId, isChildSubOrg = true}) => {

    const token = jwtDecode(getDataFromLocalStorage("token"))
    const isSystemOrgAdmin = ((roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER])) && jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === "SYSTEM")
    const isRoleMentor = ((roleHasAccess([Roles.MENTOR])))

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showAddMemberModal, setShowAddMemberModal] = useState(false);
    const [membersList, setMembersList] = useState({})
    const [selectCheckBoxItems, setSelectCheckBoxItems] = useState([]);
    const [showSearchFilterModal, setShowSearchFilterModal] = useState(false)
    const [applyFilter, setApplyFilter] = useState(true)
    const [showBulkDeleteConfirmationModal, setShowBulkDeleteConfirmationModal] = useState(false);
    const [importMemberUsers, setImportMemberUsers] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showUpdateUserModal, setShowUpdateUserModal] = useState(false);
    const [userDetail, setUserDetail] = useState(null);
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [showUpdateUserOrgModal, setUpdateUserOrganization] = useState(false);
    const [userId, setUserId] = useState(null);
    const [updateUserId, setUpdateUserId] = useState(null);
    const [timer, setTimer] = useState(null)
    const [tagValue, setTagValue] = useState('');
    const [tags, setTags] = useState([])
    const [pageNumber, setPageNumber] = useState(1);
    const [sortCriteria, setSortCriteria] = useState({
        sortBy: "createdAt",
        sortOrder: "desc",
    });
    const [searchQuery, setSearchQuery] = useState({
        firstName: null,
        email: "",
        role: [],
        subOrgId: isSubOrg ? subOrgId : null,
        tags: [],
        mentorId: null,
        createdDateRange: null,
        ...(isSystemOrgAdmin && !isSubOrg ? {
            orgId: {
                label: "Organization...", value: token?.orgId
            }
        } : {}),
    })

    const users = useSelector(state => state.users);
    const getAllOrgAsListData = useSelector(state => state.org.getAllOrgAsListReducer);
    const getAllRolesData = useSelector(state => state.roles.getAllRolesDataReducer)
    const allUsersWithoutPaginationData = useSelector(state => state.users.getAllUsersWithoutPaginationReducer);
    const deleteUserByIdData = useSelector(state => state.users.deleteUserByIdReducer)
    const bulkDeleteUser = useSelector(state => state.users.bulkUserDeleteReducer)

    useEffect(() => {
        if (applyFilter && hasPermission([Permissions.USER_READ], true)) {
            setSelectCheckBoxItems([])
            const searchQueryToBeSubmitted = {...searchQuery, ...(isSystemOrgAdmin && !isSubOrg ? {orgId: searchQuery?.orgId?.value} : {}), ...(searchQuery?.mentorId ? {mentorId: searchQuery?.mentorId.value} : {})}
            if (searchQuery?.createdDateRange) {
                searchQueryToBeSubmitted.createdDateRange = formatAndComputeAutoDateRange(searchQuery?.createdDateRange?.startDate || "", searchQuery?.createdDateRange?.endDate || "", "yyyy-MM-dd")
            }
            dispatch(getAllUsers({
                ...searchQueryToBeSubmitted,
                ...sortCriteria,
                page: pageNumber,
                subOrgId: subOrgId,
                pageSize: 10,
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                setApplyFilter(false)
            });
        }
    }, [applyFilter]);
    useEffect(() => {
        if (searchQuery.firstName !== null) {
            if (timer) {
                clearTimeout(timer);
            }
            setTimer(setTimeout(() => {
                setApplyFilter(true);
            }, 1000));
        }
    }, [searchQuery.firstName]);
    useEffect(() => {
        if (hasPermission([Permissions.ROLE_READ], true) && !isRoleMentor) {
            dispatch(getAllRoles(isSubOrg ? subOrgId : null)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }
    }, [])
    useEffect(() => {
        if (isSystemOrgAdmin && !isSubOrg) {
            dispatch(getAllOrgAsList()).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                const onSuccess = () => {
                    const orgInfo = res.payload.data?.filter(org => org.orgId === token?.orgId)[0]
                    setSearchQuery({...searchQuery, orgId: {label: orgInfo.name, value: orgInfo.orgId}})
                }
                handleApiResponse(res, onSuccess, null)
            })
        }
    }, [])
    useEffect(() => {
        const orgId = searchQuery?.orgId?.value
        const isMembersPresentForOrgId = ((orgId === null || orgId === undefined) && (membersList["all"] !== null && membersList["all"] !== undefined)) || (membersList[orgId] !== undefined && membersList[orgId] !== null)
        if (!isMembersPresentForOrgId && hasPermission([Permissions.USER_READ], true)) {
            dispatch(getAllUsersWithoutPagination({
                roles: [Roles.MENTOR, Roles.MANAGER],
                subOrgId: orgId
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                const onSuccess = () => {
                    setSearchQuery({
                        ...searchQuery,
                        mentorId: null
                    })
                    if (searchQuery?.orgId?.value === null || searchQuery?.orgId?.value === undefined) {
                        setMembersList({
                            ...membersList,
                            "all": res.payload.data
                        })
                    } else {
                        setMembersList({
                            ...membersList,
                            [searchQuery?.orgId?.value]: res.payload.data
                        })
                    }
                }
                handleApiResponse(res, onSuccess())
            })
        }

    }, [searchQuery.orgId]);
    useEffect(() => {

        if (updateUserId !== null) {
            dispatch(getUserById(updateUserId))
                .then((response) => {
                    if (isUnauthorized(response)) {
                        navigate("/")
                    }
                    if (response.meta.requestStatus === "fulfilled") {
                        setUserDetail(response.payload.data)

                    } else if (response.meta.requestStatus === "rejected") {
                        if (response?.payload?.errorCode?.value === 400) {
                            showToast(response.payload.detailDesc, "error")
                        }

                    }
                })
        }


    }, [updateUserId])
    useEffect(() => {
        setSearchQuery({...searchQuery, tags: tags?.map(c => c?.value) || []})
    }, [tags])

    const handleSort = (column, sortDirection,) => {
        setSortCriteria({
            ...sortCriteria,
            sortBy: column.sortField,
            sortOrder: sortDirection
        })
        setApplyFilter(true)
    };
    const setPageNumberAndTriggerApi = (page) => {
        setPageNumber(page)
        setApplyFilter(true)
    }
    const handleKeyDown = (event) => {
        if (!tagValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                saveValue();
                event.preventDefault();
        }
    };
    const handleDeleteUser = (isBulkDelete = false) => {
        if (userId !== null && userId !== "" && !isBulkDelete) {
            dispatch(deleteUserById({userId})).then((response) => {
                if (isUnauthorized(response)) {
                    navigate("/")
                }
                if (response.meta.requestStatus === "fulfilled") {
                    setShowConfirmationModal(false)
                    setApplyFilter(true)
                }
            });
        } else if (isBulkDelete && selectCheckBoxItems.length > 0) {
            dispatch(bulkUserDelete({selectCheckBoxItems, setSelectCheckBoxItems})).then((response) => {
                if (isUnauthorized(response)) {
                    navigate("/")
                }
                if (response.meta.requestStatus === "fulfilled") {
                    setShowBulkDeleteConfirmationModal(false)
                    setApplyFilter(true)
                }
            });

        }
    }
    const handleExport = () => {
        const searchQueryToBeSubmittedData = {...searchQuery, ...(isSystemOrgAdmin && !isSubOrg ? {orgId: searchQuery?.orgId?.value} : {}), ...(searchQuery?.mentorId ? {mentorId: searchQuery?.mentorId.value} : {})}
        if (searchQuery?.createdDateRange) {
            searchQueryToBeSubmittedData.createdDateRange = formatAndComputeAutoDateRange(searchQuery?.createdDateRange?.startDate || "", searchQuery?.createdDateRange?.endDate || "", "yyyy-MM-dd")
        }
        dispatch(exportMembers({...searchQueryToBeSubmittedData, subOrgId: subOrgId})).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
            resourceToFile(res?.payload, "exportMember", ".csv")

        })
    }
    function saveValue() {
        if (!tagValue) return;
        if (tags !== undefined && !tags.some(item => item.value === tagValue)) {
            setTags([...tags, tagOption(tagValue)]);
            setTagValue("")
        } else {
            toast.info("Tag already exit please try another one!")
        }
    }

    const columns = [
        {

            name: <div className={"d-flex"}>
                {
                    hasPermission([Permissions.USER_DELETE], true) && !searchQuery?.role?.includes("ANONYMOUS_USER") &&
                    <input type={"checkbox"}
                           checked={Array.isArray(users.getAllUsersReducer?.data?.items) && users.getAllUsersReducer?.data?.items.length > 0 && selectCheckBoxItems.length > 0 && users.getAllUsersReducer?.data?.items?.map(c => c.uid).every(id => selectCheckBoxItems.includes(id))}
                           onClick={(e) => {
                               e.stopPropagation();
                               if (users.getAllUsersReducer?.data?.items) {
                                   const memberIds = users?.getAllUsersReducer?.data?.items?.filter(c => isSubOrg ? hasPermission([Permissions.USER_DELETE], true) : roleHasAccess([Roles.MENTOR]) ? roleHasAccess([Roles.MENTOR]) && !c?.roles.map(role => role.roleName).includes("MENTOR") : c !== null).map(c => c.uid);
                                   if (memberIds.every(id => selectCheckBoxItems.includes(id))) {
                                       setSelectCheckBoxItems([]);
                                   } else {
                                       setSelectCheckBoxItems(memberIds);
                                   }
                               }
                           }}
                    />
                }
                <span
                    className={"ms-2 bulk_delete_Main_header"}> {"Name " + (isSystemOrgAdmin && !isSubOrg ? "( Organization )" : "")} </span>
            </div>,
            selector: row => row.firstName,
            sortable: true,
            sortField: 'firstName',
            cell: (row) =>
                (
                    <div className={"d-flex"}>
                        {
                            hasPermission([Permissions.USER_DELETE], true) && !row.roles?.map(role => role?.roleName)?.includes("ANONYMOUS_USER") &&
                            <input
                                type={"checkbox"}
                                disabled={isSubOrg ? !hasPermission([Permissions.USER_DELETE], true) : roleHasAccess([Roles.MENTOR]) && row.roles?.map(role => role.roleName).includes("MENTOR")}
                                checked={Array.isArray(selectCheckBoxItems) && selectCheckBoxItems.length > 0 && selectCheckBoxItems.includes(row?.uid)}
                                onClick={() => {
                                    if (!selectCheckBoxItems.includes(row?.uid)) {
                                        setSelectCheckBoxItems([...selectCheckBoxItems, row?.uid]);
                                    } else {
                                        const updatedItems = selectCheckBoxItems.filter(item => item !== row?.uid);
                                        setSelectCheckBoxItems(updatedItems);
                                    }
                                }}
                            />
                        }
                        <div className={"display_profile_data wrap-text ms-2"}>
                            <ProfilePicPlaceHolder letters={getInitials(row.firstName + " " + row.lastName)}/>
                            {hasPermission([Permissions.USER_READ, Permissions.ATTEMPT_REFLECTION_READ, Permissions.NOTE_READ]) && !row.roles?.map(role => role?.roleName)?.includes("ANONYMOUS_USER") && !isSubOrg ?
                                <NavLink
                                    title={row.firstName + " " + row.lastName + (isSystemOrgAdmin && !isSubOrg ? " ( " + row.orgName + " )" : "")}
                                    to={`/members/${row.uid}`}>
                                    {row.firstName + " " + row.lastName + (isSystemOrgAdmin && !isSubOrg ? " ( " + row.orgName + " )" : "")}

                                </NavLink> :
                                <span title={row.firstName + " " + row.lastName}
                                      className={"ps-2 wrap-text font-wt-500"}>
                                {row.firstName + " " + row.lastName}
                            </span>}
                        </div>
                    </div>

                ),
            style: {
                fontFamily: 'General Sans Medium',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            },
            width: users.getAllUsersReducer?.loading ? "25%" : "30%"
        },
        {
            name: "Email",
            selector: row => row.email,
            width: users.getAllUsersReducer?.loading ? "" : "20%"
        },
        {
            name: "Role",
            selector: row => row.roles?.map(role => role.roleName)?.map(role => toString(role))?.join(" / "),
            style: {opacity: ".7",},
            width: users.getAllUsersReducer?.loading ? "" : "12%"
        },
        {
            name: "Last Attempt Date",
            selector: row => row.latestSurveyAttemptCreatedAt === "" ? "----" : (formatDate(row.latestSurveyAttemptCreatedAt)),
            sortField: 'createdAt',
            sortable: true,
            cell: row =>
                <div>{row.latestSurveyAttemptCreatedAt === "" ? "----" : (formatDate(row.latestSurveyAttemptCreatedAt))}</div>,
            width: users.getAllUsersReducer?.loading ? "" : "12%"
        },
        {
            name: "Mentor/Manager",
            sortField: 'assignTo',
            cell: row => {
                return (
                    <div data-tooltip-id="assignData" data-tooltip-content={row?.assignTo?.map(c => c).join(",")}>
                        {getFirstFiveElements(row?.assignTo)}
                        <ReactTooltip className={"assignData"} place={"bottom"} id="assignData"/>
                    </div>
                );
            },
            style: {opacity: ".7"},
        },
        (isSubOrg &&
            {
                name: "Action",
                cell: row => (

                    <>
                        <div>
                            {row.roles?.map(role => role.roleName).includes("ORG_ADMIN") || row.roles?.map(role => role.roleName).includes("SUB_ORG_ADMIN") || row.roles?.map(role => role.roleName).includes("ANONYMOUS_USER") ?

                                <>
                                    <GoPencil size={18} className=" ms-1 assign-icon-disable"/>
                                </>
                                :
                                <>
                                    {
                                        !roleHasAccess([Roles.MENTOR]) &&
                                        <GoPencil size={18} className={""} onClick={() => {
                                            setUpdateUserId(row.uid)
                                            setShowUpdateUserModal(true)
                                        }}/>
                                    }

                                    {
                                        roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) && (jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'CUSTOMER' || jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'SYSTEM') &&
                                        <GoArrowSwitch size={18} className={"ms-3 me-2"} title={"Move Member"}
                                                       onClick={() => {
                                                           setUpdateUserOrganization(true)
                                                           setUserId(row.uid)
                                                       }}/>

                                    }

                                </>

                            }


                            {
                                row.roles?.map(role => role.roleName).includes("ANONYMOUS_USER") ?
                                    <FiTrash2 size={18} className="ms-1 assign-icon-disable"/> :
                                    hasPermission([Permissions.USER_DELETE], true) &&
                                    <FiTrash2 size={18} className="ms-1 " onClick={() => {
                                        setShowConfirmationModal(true)
                                        setUserId(row.uid)
                                    }}
                                    />
                            }


                        </div>
                    </>),
                width: users.getAllUsersReducer?.loading ? "" : "13%"
            }
        ),
        (!isSubOrg &&
            {
                name: "Action",
                cell: row => (

                    <>
                        <div>
                            {
                                row.roles?.map(role => role.roleName).includes("ORG_ADMIN") || row.roles?.map(role => role.roleName).includes("SUB_ORG_ADMIN") || row.roles?.map(role => role.roleName).includes("ANONYMOUS_USER") ?
                                    <>
                                        <MdOutlineAssignmentInd size={18} className={" ms-1 assign-icon-disable"}/>
                                    </>
                                    :
                                    <>

                                        {
                                            !roleHasAccess([Roles.MENTOR]) &&
                                            <MdOutlineAssignmentInd size={19} className={""} title={"Assign Member"}
                                                                    onClick={() => {
                                                                        setShowAssignModal(true)
                                                                        setUserId(row.uid)
                                                                    }}/>

                                        }
                                        {
                                            roleHasAccess([Roles.ORG_ADMIN, Roles.MANAGER]) && (jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'CUSTOMER' || jwtDecode(getDataFromLocalStorage("token"))?.orgLevel === 'SYSTEM') &&
                                            <GoArrowSwitch size={18} className={"ms-3 me-2"} title={"Move Member"}
                                                           onClick={() => {
                                                               setUpdateUserOrganization(true)
                                                               setUserId(row.uid)
                                                           }}/>
                                        }

                                    </>

                            }
                            {
                                ((roleHasAccess([Roles.MENTOR]) && row.roles?.map(role => role.roleName).includes("MENTOR")) || (row.roles?.map(role => role.roleName).includes("ANONYMOUS_USER"))) ?
                                    <FiTrash2 size={18} className=" ms-1 assign-icon-disable"/>

                                    :
                                    <>
                                        {
                                            hasPermission([Permissions.USER_DELETE]) &&
                                            <FiTrash2 size={18} className="ms-1" title={"Delete Member"}
                                                      onClick={() => {
                                                          setShowConfirmationModal(true)
                                                          setUserId(row.uid)
                                                      }}
                                            />
                                        }
                                    </>
                            }
                        </div>
                    </>),
                width: users.getAllUsersReducer?.loading ? "" : "13%"
            }
        )
    ].filter(Boolean)
    let searchFiltersTags = [
        {
            shouldShow: !isRoleMentor && hasPermission([Permissions.ROLE_READ], true),
            tagView: <Select
                className="members-filter-role react-select-container"
                classNamePrefix="react-select"
                isDisabled={getAllRolesData?.loading}
                isMulti
                isClearable={true}
                value={createSelectedValuesForSelectTag(getAllRolesData?.data, searchQuery?.role || [], "name", "name")}
                options={createOptionListForSelectTag(getAllRolesData?.data, "name", "name")}
                onChange={(selectedOptions) => {
                    if (selectedOptions !== null && Array.isArray(selectedOptions)) {
                        const roleIds = selectedOptions.map(item => item.value);
                        setSearchQuery({...searchQuery, role: roleIds.length === 0 ? [] : roleIds})
                    }
                }}
                placeholder={"Role..."}/>

        },

        {
            shouldShow: isSystemOrgAdmin && !isSubOrg,
            tagView: <Select className="members-filter-role react-select-container" classNamePrefix="react-select"
                             isDisabled={getAllOrgAsListData?.loading}
                             value={searchQuery?.orgId}
                             options={createOptionListForSelectTag(getAllOrgAsListData?.data, "name", "orgId")}
                             onChange={(selectedOrg) => {
                                 if (searchQuery?.orgId?.value !== selectedOrg?.value) {
                                     setSearchQuery({...searchQuery, orgId: selectedOrg, mentorId: null})
                                 }
                             }}
                             isClearable={true}
                             placeholder={"Organization..."}/>
        },
        {
            shouldShow: !isRoleMentor && hasPermission([Permissions.USER_READ], true),
            tagView: <Select
                className="members-filter-role react-select-container"
                classNamePrefix="react-select"
                isDisabled={allUsersWithoutPaginationData?.loading}
                options={createOptionListForSelectTagWithRole(membersList[searchQuery?.orgId?.value || "all"], "firstName", "uid")}
                value={searchQuery?.mentorId}
                onChange={(selectedMentor) => {
                    if (searchQuery?.mentorId?.value !== selectedMentor?.value) {
                        setSearchQuery({...searchQuery, mentorId: selectedMentor})
                    }
                }}
                isClearable={true}
                placeholder={"Mentor/Manager..."}/>
        },
        {
            shouldShow: !isRoleMentor,
            tagView: <CreatableSelect components={components}
                                      inputValue={tagValue}
                                      isClearable
                                      isMulti
                                      menuIsOpen={false}
                                      placeholder={"Enter Tags"}
                                      onChange={(value) => {
                                          setTags(value)
                                      }}
                                      onKeyDown={handleKeyDown}
                                      onInputChange={(inputValue) => {
                                          setTagValue(inputValue);
                                      }}
                                      onMenuClose={saveValue}
                                      value={tags}
                                      isDisabled={false}
            />
        },
        {
            tagView: <div className={"members-date-picker-outer "}>
                <DatePicker placeholderText="From: dd-mm-yyyy" dateFormat={"dd-MM-yyyy"}
                            selected={searchQuery?.createdDateRange?.startDate || ""}
                            className={"search-by-survey-name survey-name-search member_search "}
                            maxDate={searchQuery?.createdDateRange?.endDate || new Date()}
                            onChange={(date) => {
                                setSearchQuery({
                                    ...searchQuery,
                                    createdDateRange: applyValidDateRange({
                                        startDate: date,
                                        endDate: searchQuery?.createdDateRange?.endDate || null
                                    })
                                })


                            }}
                />
                <DatePicker placeholderText="To: dd-mm-yyyy" dateFormat={"dd-MM-yyyy"}
                            selected={searchQuery?.createdDateRange?.endDate || ""}
                            minDate={searchQuery?.createdDateRange?.startDate || ""}
                            className={"search-by-survey-name survey-name-search user-join-toDate member_search "}
                            maxDate={new Date()}
                            onChange={(date) => {
                                setSearchQuery({
                                    ...searchQuery,
                                    createdDateRange: applyValidDateRange({
                                        startDate: searchQuery?.createdDateRange?.startDate || null,
                                        endDate: date
                                    })
                                })
                            }}
                />

            </div>
        }

    ]

    return !hasPermission([Permissions.USER_READ], true) ?
        <ErrorPage value={403} description={AccessDenied} detailDesc={AccessDeniedMsg}/> : <>
            <section>
                {
                    !isSubOrg &&
                    <div className="cmn_header">
                        <h2 className="mediumFontSize m-0">Members</h2> <UserInfo/>
                    </div>
                }
                <div className={"row main_content " + (isSubOrg ? " sub-org-members-outer" : "  box_design1")}>
                    <div className={"col-md-12  " + (isSubOrg ? " px-0" : " px-1")}>
                        <div className="d-flex col-12">
                            <div className="reflection_search_wrapper ">
                                <img src={search_icon} alt=""/>
                                <input type={"text"} className={"form-control members-name-filter-input"}
                                       placeholder={"Name..."} value={searchQuery.firstName} onChange={(e) => {
                                    setSearchQuery({...searchQuery, firstName: e.target.value})

                                }}/>
                            </div>
                            <div className={"reflection_filters ms-auto"}>
                                {
                                    Array.isArray(selectCheckBoxItems) && selectCheckBoxItems.length > 0 && (
                                        <BulkDeleteIcon selectCheckBoxItems={selectCheckBoxItems}
                                                        setShowBulkDeleteConfirmationModal={setShowBulkDeleteConfirmationModal}/>
                                    )
                                }
                                <button type={"button"}
                                        onClick={() => {
                                            setShowSearchFilterModal(true)
                                        }}>
                                    <img src={filter_icon} alt=""/>Filter
                                </button>
                            </div>

                            <div className={"members-btn"}>
                                {
                                    hasPermission([Permissions.USER_CREATE, Permissions.ROLE_READ], true) &&
                                    <button
                                        style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                        className="cmn_background_color survey_addnew_btn mediumFontSize create-sub-org-btn ms-2"
                                        onClick={() => setShowAddMemberModal(true)
                                        }> {roleHasAccess([Roles.MENTOR]) ? "Add Mentee" : (isSystemOrgAdmin && subOrgId === undefined ? "Invite Team Member" : " Add Member")}
                                    </button>
                                }

                                {
                                    (!isSystemOrgAdmin || isSubOrg) && hasPermission([Permissions.USER_READ], true) &&
                                    <button
                                        style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                        onClick={() => setImportMemberUsers(true)} title={"Import"}
                                        className={"cmn_background_color survey_addnew_btn mediumFontSize create-sub-org-btn ms-2"}>
                                        <i className="fa fa-file-import me-1"/>Import</button>

                                }

                                {
                                    (!isSystemOrgAdmin || isSubOrg) && hasPermission([Permissions.USER_READ], true) &&
                                    <button
                                        style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                        onClick={handleExport} disabled={users?.exportMembersReducer?.loading === true}
                                        title={"Export"}
                                        className={"cmn_background_color survey_addnew_btn mediumFontSize create-sub-org-btn ms-2"}>
                                        <i className="fa fa-file-export"/>Export
                                        {users?.exportMembersReducer?.loading && (
                                            <TailSpinLoader/>)}
                                    </button>
                                }

                                {
                                    !isSubOrg && isSystemOrgAdmin && hasPermission([Permissions.USER_READ], true) &&
                                    <button
                                        style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                        onClick={handleExport} disabled={users?.exportMembersReducer?.loading === true}
                                        title={"Export"}
                                        className={"cmn_background_color survey_addnew_btn mediumFontSize create-sub-org-btn ms-2"}>
                                        <i className="fa fa-file-export"/>Export
                                        {
                                            users?.exportMembersReducer?.loading &&
                                            <TailSpinLoader/>
                                        }
                                    </button>
                                }
                            </div>
                        </div>


                    </div>

                    <div className={"colored_table pe-1 p-0"}>
                        <DataTable
                            className={"" + (users.getAllUsersReducer?.data?.totalPage > 1 ? " table_design2 " : " table_design1 ")}
                            persistTableHead={true} responsive={true}
                            customStyles={tableCustomStyling}
                            progressPending={users.getAllUsersReducer?.loading}
                            columns={columns} data={users.getAllUsersReducer?.data?.items}
                            noDataComponent={<div className={"no-member-text"}>No Member Found</div>}
                            progressComponent={<TableSkeletonLoading columns={5} rows={5}/>}
                            onSort={handleSort} sortServer={true}
                        />

                        {
                            !getAllOrgAsListData?.loading && !users.getAllUsersReducer?.loading && users.getAllUsersReducer?.data && users.getAllUsersReducer?.data?.totalPage > 1 &&
                            <Pagination className={isSubOrg ? "pagination-border" : ""}
                                        totalPages={users.getAllUsersReducer?.data?.totalPage} pageNumber={pageNumber}
                                        setPageNumber={setPageNumberAndTriggerApi}/>
                        }
                    </div>
                </div>
            </section>
            {
                showConfirmationModal &&
                <ConfirmationModel title={"Delete User"}
                                   btnText={"Delete"}
                                   isLoading={deleteUserByIdData?.loading}
                                   action={handleDeleteUser} show={showConfirmationModal}
                                   bodyMessage={"Are you sure you want to proceed with the user deletion, which will also result in the removal of all users, associated reflections and reflection attempts?"}
                                   setShow={setShowConfirmationModal}
                                   showUserAcknowledgementCheckBox={true}
                                   userAcknowledgementCheckBoxText={"Yes, I understand"}
                />
            }
            {
                showAddMemberModal &&
                <AddMemberModal show={showAddMemberModal}
                                setShow={setShowAddMemberModal}
                                triggerApi={setPageNumberAndTriggerApi}
                                subOrgId={subOrgId}/>
            }
            {
                importMemberUsers &&
                <ImportUsersModal show={importMemberUsers} setShow={setImportMemberUsers}/>
            }

            {
                showAssignModal &&
                <AssignMember show={showAssignModal}
                              setShow={setShowAssignModal} userId={userId} userData={null} setUserData={null}
                              query={searchQuery} setQuery={setSearchQuery} isSubOrg={isSubOrg} subOrgId={subOrgId}
                />
            }

            {
                showUpdateUserOrgModal &&
                <MoveMemberAndOrgModal show={showUpdateUserOrgModal}
                                       setShow={setUpdateUserOrganization} rowId={userId} userData={null}
                                       setUserData={null} query={searchQuery} setQuery={setSearchQuery}
                                       isSubOrg={isSubOrg} subOrgId={subOrgId}

                />
            }

            {
                showUpdateUserModal && userDetail !== null &&
                <UpdateUserModal show={showUpdateUserModal} setShow={setShowUpdateUserModal} userDetails={userDetail}
                                 setUserDetails={setUserDetail}
                                 data={() => {
                                     setApplyFilter(true)
                                 }}
                                 isSubOrg={isSubOrg}
                                 subOrgId={subOrgId}/>
            }

            {
                showBulkDeleteConfirmationModal &&
                <ConfirmationModel title={"Delete User"}
                                   btnText={"Delete"}
                                   action={() => handleDeleteUser(true)} show={showBulkDeleteConfirmationModal}
                                   bodyMessage={"Are you sure you want to proceed with the selected users deletion, which will also result in the removal of all users, associated reflections and reflection attempts?"}
                                   setShow={setShowBulkDeleteConfirmationModal}
                                   isLoading={bulkDeleteUser?.loading || false}
                                   showUserAcknowledgementCheckBox={true}
                                   userAcknowledgementCheckBoxText={"Yes, I understand"}
                />
            }

            {
                showSearchFilterModal &&
                <SearchFilterModal title={"Member Filters"}
                                   show={showSearchFilterModal}
                                   filtersTag={searchFiltersTags}
                                   applyFilter={applyFilter}
                                   setApplyFilter={setApplyFilter}
                                   resetFilters={() => {
                                       setSearchQuery({
                                           firstName: "",
                                           email: "",
                                           role: [],
                                           tags: [],
                                           mentorId: null,
                                           createdDateRange: null,
                                           ...(isSystemOrgAdmin && !isSubOrg ? {orgId: ""} : {})
                                       });
                                       setTags([])
                                   }}
                                   handleClose={() => {
                                       setShowSearchFilterModal(false);
                                   }}
                                   setShow={setShowSearchFilterModal}

                />
            }
        </>

}
export default Member

