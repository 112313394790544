import jwtDecode from "jwt-decode";
import {
    createOptionListForSelectTag, createOptionListForSelectTagWithRole,
    formatDate, getCombinedReportResult,
    getDataFromLocalStorage, handleApiResponse,
    isUnauthorized,
    tableCustomStyling,
    TableSkeletonLoading, toString, trim
} from "../../helpers/utils";
import {hasPermission, Permissions, Roles} from "../../helpers/accessControl";
import {TailSpinLoader} from "../layout/Loader";
import {ToggleButton} from "../commons/ToggleButton";
import ReportItem from "../customReport/ReportItem";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import CustomReportModal from "../reports/Modal/CustomReportModal";
import {getAllAssignedReflections} from "../../reduxStore/surveySlices/surveySlices";
import {getAllTeamMembers} from "../../reduxStore/teamSlices/teamSlice";
import {
    deleteCustomReport,
    getAllCustomReportsData, getIdCustomReport,
} from "../../reduxStore/CustomReportSlices/customReport";
import ConfirmationModel from "../commons/ConfirmationModel";
import {resetReducers} from "../../reduxStore/globalKeysSlice/globalKeysSlice";
import AssignCustomReport from "../customReport/modal/AssignCustomReport";
import DataTable from "react-data-table-component";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {CiEdit} from "react-icons/ci";
import {FiTrash2} from "react-icons/fi";

const TeamReport = ({teamId}) => {

    const token = jwtDecode(getDataFromLocalStorage("token"))

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [triggerApi, setTriggerApi] = useState(false)
    const [showCreateReportModal, setShowCreateReportModal] = useState(false)
    const [reportToDeleteId, setReportToDeleteId] = useState(null)
    const [reportToUpdateId, setReportToUpdateId] = useState(null)
    const [reportToUpdateData, setReportToUpdateData] = useState(null)
    const [showAssignReportModal, setShowAssignReportModal] = useState(false)
    const [showDeleteReportConfirmationModal, setShowDeleteReportConfirmationModal] = useState(false)
    const [graphicalView, isGraphicalView] = useState(true)

    const getTeamByIdData = useSelector(state => state.team.getTeamByIdReducer)
    const getAllAssignedReflectionsData = useSelector(state => state.survey.getAllAssignedReflectionsReducer)
    const getAllTeamMembersData = useSelector(state => state.team.getAllTeamMembersReducer)
    const getAllCustomReports = useSelector(state => state.customReport.getAllCustomReportsDataReducer)
    const deleteCustomReportData = useSelector(state => state.customReport.deleteCustomReportReducer)

    useEffect(() => {
        if (teamId !== null && teamId !== undefined) {
            setTriggerApi(true)
        }
    }, [teamId]);
    useEffect(() => {
        if (teamId !== null && teamId !== undefined && hasPermission([Permissions.REFLECTION_READ], true)) {
            dispatch(getAllAssignedReflections(teamId)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }
    }, [teamId]);
    useEffect(() => {
        if (teamId !== null && teamId !== undefined && hasPermission([Permissions.USER_READ], true)) {
            dispatch(getAllTeamMembers(teamId)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            });
        }
    }, [teamId]);
    useEffect(() => {
        if (triggerApi && hasPermission([Permissions.REPORT_READ], true)) {
            dispatch(getAllCustomReportsData({teamId: teamId})).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                setTriggerApi(false)
            })
        }
    }, [triggerApi]);
    useEffect(() => {
        if (reportToUpdateId && hasPermission([Permissions.REPORT_READ], true)) {
            dispatch(resetReducers({sliceNames: ["getIdCustomReportReducer"]}))
            dispatch((getIdCustomReport(reportToUpdateId))).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                handleApiResponse(res, () => {
                    setReportToUpdateId(null)
                    setReportToUpdateData(res?.payload)
                })
            })
        }
    }, [reportToUpdateId]);

    const handleDeleteReport = () => {
        if (reportToDeleteId !== null && reportToDeleteId !== "" && reportToDeleteId !== undefined) {
            dispatch(deleteCustomReport(reportToDeleteId)).then((response) => {
                if (isUnauthorized(response)) {
                    navigate("/")
                }
                const onSuccess = () => {
                    setTriggerApi(true)
                    setReportToDeleteId(null)
                    setShowDeleteReportConfirmationModal(false)
                }
                handleApiResponse(response, onSuccess)
            });
        }
    }

    const columns = [
        {
            name: <span className={"bulk_delete_Main_header"}> Name </span>,
            selector: row => row?.reportName,
            cell: row => {
                return (
                    <span className={"bulk_delete_Main_header cursor cursor-pointer "}
                          onClick={() => {
                              navigate("/report/" + row.id)
                          }}>{row.reportName}</span>
                );
            },
        },
        {
            name: "Created date",
            selector: row => formatDate(row?.createdAt),
        },
        {
            name: "Owner",
            cell: row => {
                return (
                    <div data-tooltip-id="assignData"
                         data-tooltip-content={row?.createdByInfo?.firstName + " " + row?.createdByInfo?.lastName}>
                        {trim(row?.createdByInfo?.firstName + " " + row?.createdByInfo?.lastName, 60)}
                        <ReactTooltip className={"assignData"} place={"bottom"} id="assignData"/>
                    </div>
                );
            },
            style: {opacity: ".7"},
        },
        {
            name: "Result",
            selector: row => row?.reportResult?.length === 0 ? "N/A" : getCombinedReportResult(row?.reportResult),
        },
        {
            name: "Chart type",
            selector: row => toString(row?.reportType),
        },
        {
            name: "Action",
            cell: row => {
                return (
                    <>
                        <div>
                            <CiEdit
                                onClick={() => {
                                    if (row.userId === token?.userid) {
                                        setReportToUpdateId(row.id)
                                        setShowCreateReportModal(true)
                                    }
                                }}
                                title={"Update Report"}
                                size={18}
                                className={row.userId === token?.userid ? " ms-1 cursor cursor-pointer" : " ms-1  opacity5"}/>
                            <i className={" mt-1 mb-1 ps-1 fas fa-user-check assign-icon " + (row.userId === token?.userid ? " cursor cursor-pointer" : "opacity5")}
                               onClick={() => {
                                   if (row.userId === token?.userid) {
                                       setReportToUpdateId(row.id)
                                       setShowAssignReportModal(true)
                                   }
                               }}
                               title={"Assign Report"}
                            />
                            <FiTrash2 size={16}
                                      title={"Delete Report"}
                                      onClick={() => {
                                          if (row.userId === token?.userid) {
                                              setShowDeleteReportConfirmationModal(true)
                                              setReportToDeleteId(row.id)
                                          }
                                      }}
                                      className={row.userId === token?.userid ? " mb-1 ms-1 cursor cursor-pointer" : "mb-1 ms-1  opacity5"}/>
                        </div>
                    </>

                );
            },
        }
    ]

    return (
        <>
            <div className={"row main_content  sub-org-members-outer"}>
                <div className={"col-xl-12"}>
                    <div className={"reportData"}>
                        <button
                            onClick={() => {
                                if (getTeamByIdData?.data?.createdBy === token?.userid || getTeamByIdData?.data?.managedBy?.includes(token?.userid)) {
                                    setShowCreateReportModal(true)
                                }
                            }}
                            disabled={!(getTeamByIdData?.data?.createdBy === token?.userid || getTeamByIdData?.data?.managedBy?.includes(token?.userid))}
                            style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                            className={"mediumFontSize cmn_background_color" + ((getTeamByIdData?.data?.createdBy === token?.userid || getTeamByIdData?.data?.managedBy?.includes(token?.userid)) ? "" : " opacity6")}>Create
                            Report
                        </button>
                    </div>
                </div>
                {
                    Array.isArray(getAllCustomReports?.data) && getAllCustomReports?.data?.length > 0 &&
                    <div className={"report_toggle_btn d-flex toggle-view-report"}>
                        <ToggleButton toggleView={graphicalView} isToggle={isGraphicalView}/>
                    </div>
                }

                <div className={"row pe-1 p-0"}>

                    {
                        getAllCustomReports?.loading  ? <div className={" team-report-loader"}><TailSpinLoader/></div> :
                            getAllCustomReports?.data && getAllCustomReports?.data?.length === 0 ?
                                <div className={"no-report-available"}>No Report Available</div> :
                                graphicalView ?
                                    getAllCustomReports?.data?.map((cur, index) => {
                                        return (
                                            <>
                                                <div className={"col-xl-6  pe-1 mt-2"}>
                                                    <div key={index} className={"chart-div-color"}>
                                                        <ReportItem
                                                            report={cur}
                                                            customReportId={cur.id}
                                                            reportName={cur.reportName}
                                                            setDeleteReportId={setReportToDeleteId}
                                                            setShowConfirmationModal={setShowDeleteReportConfirmationModal}
                                                            setReportId={setReportToUpdateId}
                                                            setCustomReportModal={setShowCreateReportModal}
                                                            setShowAssignCustomReportModal={setShowAssignReportModal}
                                                        />
                                                    </div>
                                                </div>
                                            </>

                                        );
                                    }) :
                                    <div className={"colored_table p-0"}>
                                        <DataTable
                                            className={"table_design1 team-report-table"}
                                            persistTableHead={true} responsive={true}
                                            customStyles={tableCustomStyling}
                                            progressPending={getAllCustomReports?.loading}
                                            columns={columns}
                                            data={getAllCustomReports?.data}
                                            noDataComponent={<div className={"no-member-text"}>No Result Found</div>}
                                            progressComponent={<TableSkeletonLoading columns={6} rows={5}/>}
                                        />
                                    </div>
                    }
                </div>

            </div>
            {
                showCreateReportModal &&
                <CustomReportModal
                    show={showCreateReportModal}
                    setShow={setShowCreateReportModal}
                    surveyDropDownList={createOptionListForSelectTag(getAllAssignedReflectionsData?.data, "name", "surveyId")}
                    userDropDownList={createOptionListForSelectTagWithRole(getAllTeamMembersData?.data, "firstName", "uid")}
                    isTeam={true}
                    teamId={teamId}
                    reportDetails={reportToUpdateData}
                    setReportDetails={setReportToUpdateData}
                    isSubOrg={false}
                    isSubOrgCustomReport={false}
                />
            }
            {
                showDeleteReportConfirmationModal &&
                <ConfirmationModel title={"Delete Report"}
                                   show={showDeleteReportConfirmationModal}
                                   isLoading={deleteCustomReportData?.loading}
                                   btnText={"Delete"}
                                   action={handleDeleteReport}
                                   bodyMessage={"Are you sure you want to proceed with the deletion?"}
                                   setShow={setShowDeleteReportConfirmationModal}
                />
            }
            {
                showAssignReportModal &&
                <AssignCustomReport
                    show={showAssignReportModal}
                    isSubOrg={false}
                    setShow={setShowAssignReportModal}
                    reportToUpdate={reportToUpdateData}
                    isTeam={true}
                    teamId={teamId}
                />
            }


        </>
    );
}
export default TeamReport