import logo from "../../../images/Union.png";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {isUnauthorized} from "../../../helpers/utils";
import {TailSpinLoader} from "../../layout/Loader";
import {updateRoleToPulseManager} from "../../../reduxStore/usersSlices/userSlice";


const UpdateOrgLevel = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {encodedString} = useParams();
    const updateRoleToPulseManagerData = useSelector(state => state.users.updateRoleToPulseManagerReducer)
    const [userId, setUserId] = useState(atob(encodedString).split('&')[0].split('=')[1].trim())
    const [roleId, setRoleId] = useState(atob(encodedString).split('&')[1].split('=')[1].trim())
    const [isAccepted,setIsAccepted]=useState(null)





    useEffect(()=>{
        if(isAccepted!==null){
            dispatch(updateRoleToPulseManager({
                userId: userId,
                roleId: roleId,
                isAccepted: isAccepted,
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                } else if (res.meta.requestStatus === "fulfilled") {
                    localStorage.clear();
                    navigate("/")
                }
            })
        }
    },[isAccepted])
    return (
        <>
            <div className={"container_box pt-5 pb-5"}>

                <div className="signup_success cmn_outer update-org-level-outer m-auto">
                    <div className={'update-org-level-pulse-logo-outer '}>
                        <img className={"update-org-level-pulse-logo"} src={logo} alt=""/>
                    </div>
                    <div className='success_content'>
                        <h3>Promote To Manager!</h3>
                        <p>You've been invited to promote to the Manager. Select 'Accept' to confirm the promotion or
                            'Decline' to reject the invitation.</p>
                        <p>Please confirm your choice.</p>
                        <button onClick={() => {setIsAccepted(true)}}
                                disabled={updateRoleToPulseManagerData?.loading}
                                className="cmn_btn cmn_background_color mediumFontSize cmn_btn d-inline-block me-1">Accept {
                            updateRoleToPulseManagerData?.loading && isAccepted && <TailSpinLoader/>
                        }
                        </button>
                        <button onClick={() => {setIsAccepted(false)}}
                                disabled={updateRoleToPulseManagerData?.loading}
                                className="cmn_form_btn mediumFontSize ms-1">Decline
                            {
                                updateRoleToPulseManagerData?.loading && !isAccepted &&  <TailSpinLoader/>
                            }
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UpdateOrgLevel