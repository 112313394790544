import "../dashboard/ReflecrtionMetrics.css"
import defaultImg from '../../images/default.png'
import {FaArrowDown, FaArrowUp, FaGreaterThan} from "react-icons/fa";
import {useEffect, useState} from "react";


import {
    Chart as ChartJs,
    Tooltip,
    Title,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Filler
} from "chart.js";

import {Line} from "react-chartjs-2";
import Skeleton from "react-loading-skeleton";
import {Marks} from "../../helpers/constants";
import ProfilePicPlaceHolder from "../commons/ProfilePicPlaceHolder";
import MembersListModal from "./modal/MembersListModal";
import {convertToStartEndDateTime, getInitials} from "../../helpers/utils";

ChartJs.register(
    Tooltip, LineElement, Title, CategoryScale, LinearScale, PointElement, Filler
)


const DashBoardMetrics = ({isLoading, data, selectedReflectionId,orgId}) => {
    const [showMembersList, setShowMembersList] = useState(false)
    const [options, setOptions] = useState({
        plugins: {
            legend: {
                display: false,
            },},
        scales: {
            x: {
                display: false, // Hide the x-axis
            },
            y: {
                display: false, // Hide the y-axis
            },
        }
    })
    const [percentage, setPercentage] = useState(null)
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: "",
                data: [],
                borderColor: "#e2e2e2",
                backgroundColor: "#e2e2e2",
                tension: 0.2,
                borderWidth: 2,
                pointRadius: 0,
                fill: true
            },
        ],
    })

    const getLabels=(currentWeekData)=>{
        return currentWeekData?.map(data=> data.day);
    }
    const getCurrentWeekCount=(currentWeekData)=>{
        return currentWeekData?.reduce((sum,data)=> sum+data.count,0)
    }

    const getData=(currentWeekData)=>{
        return currentWeekData?.map(data=> data.count)
    }
    useEffect(() => {
        if (data !== undefined) {
            let per = calculatePercentage(data.lastWeekAttemptCount, getCurrentWeekCount(data.currentWeekAttemptsPerDay));
            const setGraphColors = data.riskLevel === "GOOD" ? per : per * -1
            setChartData({
                ...chartData,
                labels: getLabels(data.currentWeekAttemptsPerDay),
                datasets: [{
                    ...chartData.datasets[0],
                    data: getData(data.currentWeekAttemptsPerDay),
                    borderColor: setGraphColors <= 0 ?  localStorage.getItem('color') !== null ? localStorage.getItem('color'): "#ff7001" : "#ff0000",
                    backgroundColor: setGraphColors * 100 <= 0 ? "#ff700129" : "#f82d2d5c",
                }]
            })
            setPercentage(per);
        }

    }, [data])
    const calculatePercentage = (lastWeekAttemptCount, currentWeekAttemptCount) => {
        if (lastWeekAttemptCount === 0 && currentWeekAttemptCount === 0) {
            return 0;
        }
        if (lastWeekAttemptCount === 0) {
            return -100;
        }
        return parseFloat((((lastWeekAttemptCount - currentWeekAttemptCount) / lastWeekAttemptCount) * 100)?.toFixed(2))
    }

    return (



        isLoading ?

            <div className={"reflection-metrics-tile good-tile"}>
                <div className={"metrics-heading"}><Skeleton width={80}/></div>


                <div className={"metrics-data-outer "}>
                    <div className={"data-outer"}>
                        <div className={"data"}>
                            <Skeleton width={60} height={30}/>
                        </div>

                        <div className={"compare-text mt-1"}><Skeleton width={120}/></div>
                    </div>
                    <div className={"chart-outer-skeleton"}>
                        <Skeleton width={120} height={60}/>
                    </div>
                </div>
                <div className={"see-all-outer"}>
                    <div className={"images d-flex"}>
                        <Skeleton height={30} width={30} circle={true}/>
                        <Skeleton height={30} width={30} circle={true}/>
                        <Skeleton height={30} width={30} circle={true}/>
                    </div>
                    <div className={"see-all-btn"}><Skeleton width={60} height={15}/>
                    </div>
                </div>
            </div> :
            <>
                {

                    data !== undefined ?
                        <div className={"reflection-metrics-tile high-risk-tile"}>
                            <div
                                className={"metrics-heading"}>{Marks[data.riskLevel]}
                            </div>
                            <div className={"metrics-data-outer "}>
                                <div className={"data-outer"}>
                                    <div className={"data"}>
                                        <div className={"total-reflections me-3"}>{getCurrentWeekCount(data.currentWeekAttemptsPerDay)}</div>
                                        {
                                            data.riskLevel === "GOOD" && <>
                                                {
                                                    percentage <= 0 ? <div className={"metrics-percentage-high-risk"}>
                                                            <FaArrowUp className={"up-arrow me-1"} fill={"#0d870d"}/>
                                                            {Math.abs(percentage)}%
                                                        </div>
                                                        : <div className={"metrics-percentage-high-risk text-red"}>
                                                            <FaArrowDown
                                                                className={"down-arrow me-1"}
                                                                fill={"#f82d2d"}/> {Math.abs(percentage)}%
                                                        </div>
                                                }
                                            </>

                                        }
                                        {
                                            data.riskLevel !== "GOOD" && <>
                                                {
                                                    percentage <= 0 ?
                                                        <div className={"metrics-percentage-high-risk text-red"}>
                                                            <FaArrowUp className={"down-arrow me-1"} fill={"#f82d2d"}/>
                                                            {Math.abs(percentage)}%
                                                        </div>
                                                        :
                                                        <div className={"metrics-percentage-high-risk "}>
                                                            <FaArrowDown
                                                                className={"up-arrow me-1"}
                                                                fill={" #0d870d"}/> {Math.abs(percentage)}%
                                                        </div>
                                                }
                                            </>
                                        }
                                    </div>

                                    <div className={"compare-text"}>Compare to last week</div>
                                </div>
                                <div className={"chart-outer"}>
                                    <Line className={"chart "} data={chartData} options={options}/>

                                </div>
                            </div>
                            <div className={"see-all-outer"}>
                                {
                                    data?.mentees?.length > 0 ? <>
                                            <div className={"images"}>
                                                {
                                                    data.mentees.map((user, index) => {
                                                        return (
                                                            <div key={index} title={user.firstName + " " + user.lastName}>
                                                                <ProfilePicPlaceHolder
                                                                    letters={getInitials(user.firstName+" "+user.lastName)}/>
                                                            </div>
                                                        );
                                                    })
                                                }

                                            </div>
                                            <div className={"see-all-btn"} onClick={() => {
                                                setShowMembersList(true)
                                            }}>See all <FaGreaterThan className={"see-all-btn-icon ms-2"}/>
                                            </div>
                                        </> :
                                        <>
                                            <div className={"images disable-images"}>
                                                <img src={defaultImg} alt=""/>
                                                <img src={defaultImg} alt=""/>
                                                <img src={defaultImg} alt=""/>
                                                <img src={defaultImg} alt=""/>
                                                <img src={defaultImg} alt=""/>
                                            </div>
                                            <div className={"see-all-btn disable-see-all-btn"}>See all <FaGreaterThan
                                                className={"see-all-btn-icon ms-2"}/>
                                            </div>

                                        </>
                                }
                            </div>
                        </div> :

                        <>
                            <div className={"reflection-metrics-tile high-risk-tile"}>
                                <div
                                    className={"metrics-heading"}>{Marks[""]}
                                </div>
                                <div className={"metrics-data-outer-custom mediumFontSize "}>
                                    <div className={"data-outer"}>
                                        <div className={"data-padding"}><h5>Not Data Found</h5></div>

                                    </div>
                                </div>
                                <div className={"see-all-outer"}>

                                            <>
                                                <div className={"images disable-images"}>
                                                    <img src={defaultImg} alt=""/>
                                                    <img src={defaultImg} alt=""/>
                                                    <img src={defaultImg} alt=""/>
                                                    <img src={defaultImg} alt=""/>
                                                    <img src={defaultImg} alt=""/>
                                                </div>
                                                <div className={"see-all-btn disable-see-all-btn"}>See all <FaGreaterThan
                                                    className={"see-all-btn-icon ms-2"}/>
                                                </div>

                                            </>

                                </div>
                            </div>
                        </>
                }
                {
                    showMembersList && <MembersListModal show={showMembersList} setShow={setShowMembersList} orgId={orgId} data={{
                        surveyId: selectedReflectionId,
                        riskLevel: data.riskLevel,
                        page: "",
                        startOfWeek:convertToStartEndDateTime(data?.currentWeekAttemptsPerDay[0].date,true),
                        endOfWeek:convertToStartEndDateTime(data?.currentWeekAttemptsPerDay[6].date,false)

                    }}/>
                }
            </>


    )
        ;
}
export default DashBoardMetrics;
