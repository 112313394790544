import React from "react";

export const ToggleButton=({toggleView,isToggle})=>{

    return(
        <>
            <label className="toggle-switch">
                <input
                    type="checkbox"
                    title={toggleView ? "switch to tabular view" : "switch to graphical view"}
                    checked={toggleView}
                    onClick={() => isToggle(!toggleView)}
                />
                <span className="slider"/>
            </label>
            <span className="toggle-switch-text my-2">{toggleView ? 'Graphical View' : 'Tabular View'}</span>
        </>
    )

}
