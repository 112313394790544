import Modal from "react-bootstrap/Modal";
import {IoIosClose} from "react-icons/io";
import {TailSpinLoader} from "../../layout/Loader";
import React, {useEffect, useState} from "react";
import {hasPermission, Permissions, Roles} from "../../../helpers/accessControl";
import {
    createOptionListForSelectTagWithMultipleLabels, eliminateDuplicateObjects,
    getAuthorityLevelFor, getDataFromLocalStorage,
    getRolesWithAuthorityLevel, handleApiResponse,
    isUnauthorized, showToast, validateCreateTeamObject
} from "../../../helpers/utils";
import {getAllUsersWithoutPagination} from "../../../reduxStore/usersSlices/userSlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Select from "react-select";
import {createTeam} from "../../../reduxStore/teamSlices/teamSlice";
import jwtDecode from "jwt-decode";

const CreateTeamModal = ({setTriggerApi,show, setShow}) => {

    const token = jwtDecode(getDataFromLocalStorage("token"))
    const isOrgLevelSys = token.orgLevel === "SYSTEM"

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [createTeamData, setCreateTeamData] = useState({
        teamName: "",
        description: "",
        managedBy: [],
        membersIds: []
    })
    const getAllUsersWithoutPaginationData = useSelector(state => state.users.getAllUsersWithoutPaginationReducer);
    const createTeamReducer = useSelector(state => state.team.createTeamReducer);

    const handleClose = () => setShow(false);

    const handleSubmit = (e) => {

        e.preventDefault();
        const message = validateCreateTeamObject(createTeamData)
        if (message && false) {
            showToast(message, "error")
        } else {
            const dataToSubmit = {
                ...createTeamData,
                managedBy: createTeamData?.managedBy?.map(cur => cur.value),
                membersIds: createTeamData?.membersIds?.map(cur => cur.value),
            }
            dispatch(createTeam(dataToSubmit)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                const onSuccess=()=>{
                    setTriggerApi(true)
                    handleClose();
                }
                handleApiResponse(res, onSuccess)
            })

        }


    }
    const handleChange = (e) => {
        setCreateTeamData({
            ...createTeamData,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        if (hasPermission([Permissions.USER_READ], true)) {
            const roleToGetAuthorityLevelFor = Roles.ORG_ADMIN
            const rolesToGet = getRolesWithAuthorityLevel("<=", getAuthorityLevelFor(roleToGetAuthorityLevelFor), [Roles.ANONYMOUS_USER])
            dispatch(getAllUsersWithoutPagination({
                roles: rolesToGet,
                ...(isOrgLevelSys ? {orgId: token?.orgId} : {})
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }

    }, []);


    return (
        <>
            <Modal className='mt-4' show={show} centered>
                <Modal.Header className={"d-flex"}>
                    <Modal.Title className='add_member_heading mediumFontSize'>Create Team</Modal.Title>
                    <IoIosClose className={"close_btn"} onClick={handleClose} size={24}/>
                </Modal.Header>

                <form onSubmit={handleSubmit}>

                    <Modal.Body className="modal_outer">


                        <div className="reset_form">

                            <div className="form-group mt-1">
                                <label className='userInfo_label mediumFontSize' form="inputAddress">Name*</label>
                                <input required={true} type="text"
                                       value={createTeamData?.teamName}
                                       name={"teamName"}
                                       onChange={handleChange}
                                       className="form-control userInfo_input" id="inputAddress"/>
                            </div>
                            <div className="form-group mt-1">
                                <label className='userInfo_label mediumFontSize' form="inputAddress">Description</label>
                                <input type="text"
                                       name={"description"}
                                       value={createTeamData?.description}
                                       onChange={handleChange}
                                       className="form-control userInfo_input" id="inputAddress"/>
                            </div>

                            <div className={"form-group mt-1"}>

                                <label className={"userInfo_label mb-2"}
                                       htmlFor="exampleFormControlSelect1"> Leaders* </label>


                                <Select
                                    className="members-filter-role react-select-container"
                                    classNamePrefix="react-select"
                                    isDisabled={getAllUsersWithoutPaginationData?.loading}
                                    isMulti
                                    isClearable={true}
                                    value={createTeamData?.managedBy}
                                    options={createOptionListForSelectTagWithMultipleLabels(getAllUsersWithoutPaginationData?.data?.filter(user => user.roleObjects[0]?.name !== Roles.MENTEE), ["firstName", "lastName"], "uid")}
                                    onChange={(selectedValue) => {
                                        setCreateTeamData({
                                            ...createTeamData,
                                            managedBy: selectedValue?.length === 0 ? [] : selectedValue,
                                            membersIds: eliminateDuplicateObjects(createTeamData?.membersIds, selectedValue, "value")
                                        })
                                    }}
                                    placeholder={"Select..."}/>

                            </div>
                            <div className={"form-group mt-1"}>

                                <label className={"userInfo_label mb-2"}
                                       htmlFor="exampleFormControlSelect1">Team Members* </label>


                                <Select
                                    className="members-filter-role react-select-container"
                                    classNamePrefix="react-select"
                                    isDisabled={getAllUsersWithoutPaginationData?.loading || createTeamData?.managedBy?.length === 0}
                                    isMulti
                                    isClearable={true}
                                    value={createTeamData?.membersIds}
                                    // Exclude Managed By members from members list
                                    options={createOptionListForSelectTagWithMultipleLabels(getAllUsersWithoutPaginationData?.data?.filter(user => !createTeamData?.managedBy?.map(cur => cur.value)?.includes(user.uid)), ["firstName", "lastName"], "uid")}
                                    onChange={(selectedValue) => {
                                        setCreateTeamData({
                                            ...createTeamData,
                                            membersIds: selectedValue?.length === 0 ? [] : selectedValue
                                        })
                                    }}
                                    placeholder={"Select..."}/>

                            </div>


                        </div>


                    </Modal.Body>

                    <Modal.Footer className="modal_footer">
                        <div className={"add-member-modal-btns"}>
                            <button
                                style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                type="submit" disabled={createTeamReducer?.loading}
                                className="ms-2 modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize ">Create
                                {
                                    createTeamReducer?.loading && <TailSpinLoader/>
                                }

                            </button>
                            <button className="modal_cancel_btn cmn_modal_btn mediumFontSize"
                                    disabled={createTeamReducer?.loading}
                                    onClick={handleClose}>Cancel
                            </button>

                        </div>

                    </Modal.Footer>
                </form>
            </Modal>


        </>
    )
}
export default CreateTeamModal