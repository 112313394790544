import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {hasPermission, Permissions, roleHasAccess, Roles} from "../../helpers/accessControl";
import {getMembersOverallResult} from "../../reduxStore/surveySlices/surveySlices";
import {getInitials, isUnauthorized, tableCustomStyling, TableSkeletonLoading} from "../../helpers/utils";
import ProfilePicPlaceHolder from "./ProfilePicPlaceHolder";
import {Marks} from "../../helpers/constants";
import DataTable from "react-data-table-component";
import Pagination from "./Pagination";

const MembersVsAttempts = ({isSubOrg = false, subOrgId,orgId=null}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const survey = useSelector(state => state.survey)
    const [pageNumber, setPageNumber] = useState(1);
    const [sortBy, setSortBy] = useState("createdAt");
    const [sortOrder, setSortOrder] = useState("desc");
    useEffect(() => {
        if (hasPermission([Permissions.USER_READ,Permissions.REPORT_READ], true)) {
            dispatch(getMembersOverallResult({
                page: pageNumber,
                pageSize: 10,
                sortBy: sortBy,
                sortOrder: sortOrder,
                orgId:orgId?.value,
                ...(isSubOrg ? { subOrgId: subOrgId } : {}),
            })).then((res) => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }
    }, [pageNumber, sortBy, sortOrder,orgId]);
    const columns = [{
        name: <div className={"d-flex"}>
            <span className={"ms-2 bulk_delete_Main_header"}> Name   </span>
        </div>,
        selector: row => row.firstName,
        sortField: 'firstName',
        sortable: true,
        cell: row => (isSubOrg ? <div className={"display_profile_data wrap-text"}>
                    <div
                        className={"placeholder-outer "}>
                        <ProfilePicPlaceHolder
                            letters={getInitials(row.firstName + " " + row.lastName)}/>
                    </div>

                    <div className={"member-name-email-outer ps-2 wrap-text font-wt-600 "}>
                        <div title={row.firstName + " " + row.lastName}
                             className={"member-name  wrap-text "}>{row.firstName + " " + row.lastName}</div>
                    </div>
                </div> :
                <div className={"display_profile_data wrap-text font-wt-600 "}>
                    <div
                        className={"placeholder-outer " + (hasPermission([Permissions.USER_READ, Permissions.ATTEMPT_REFLECTION_READ, Permissions.NOTE_READ]) ? "cursor-pointer" : "")}
                        onClick={() => {
                            if (hasPermission([Permissions.USER_READ, Permissions.ATTEMPT_REFLECTION_READ, Permissions.NOTE_READ])) {
                                navigate(`/members/${row.userId}`)
                            }
                        }}>
                        <ProfilePicPlaceHolder
                            letters={getInitials(row.firstName + " " + row.lastName)}/>
                    </div>

                    <div className={"member-name-email-outer ps-2 wrap-text font-wt-600 "}>
                        <div title={row.firstName + " " + row.lastName}
                             onClick={() => {
                                 if (hasPermission([Permissions.USER_READ, Permissions.ATTEMPT_REFLECTION_READ, Permissions.NOTE_READ])) {
                                     navigate(`/members/${row.userId}`)
                                 }
                             }}
                             className={"member-name  wrap-text font-wt-600 " + (hasPermission([Permissions.USER_READ, Permissions.ATTEMPT_REFLECTION_READ, Permissions.NOTE_READ]) ? "cursor-pointer" : "")}>{row.firstName + " " + row.lastName}</div>
                    </div>
                </div>

        ),

    },

        {
            name: "Email", selector: row => row.email,
            width: survey.getMembersOverallResultReducer?.loading ? "" : ""

        },
        {
        name: "Attempt Count", selector: row => row.count,
        width: survey.getMembersOverallResultReducer?.loading ? "" : ""

    },
        {
        name: "Overall Result",
        selector: row => Marks[row.avgRiskValue],
        cell: row => <span
            className={" survey-risk-status mx-0 " + (row.avgRiskValue === "HIGH_RISK" ? "bg-light-red" : row.avgRiskValue === "LOW_RISK" ? "bg-light-orange" : "bg-light-green")}>
                    {Marks[row.avgRiskValue]}</span>,
        width: survey.getMembersOverallResultReducer?.loading ? "" : "160px"


    },].filter(Boolean)


    const handleSort = (column, sortDirection,) => {
        setSortBy(column.sortField)
        setSortOrder(sortDirection)
    };
    return (
        <>
            <div className={ isSubOrg ? "m-2":""}>
                <DataTable
                    className={survey?.getMembersOverallResultReducer?.data?.totalPages > 1 ?" table_design2":" table_design1"}
                    noDataComponent={<div className={"lightFontSize cmn_fontStyle mt-2 mb-2"}>No
                        Members Found</div>}
                    persistTableHead={true} responsive={true}
                    progressPending={survey.getMembersOverallResultReducer?.loading}
                    progressComponent={<div
                        className={"overall-result-skeleton-loaderss  w-100"}>
                        <TableSkeletonLoading columns={isSubOrg?4:3} rows={5}/></div>}
                    customStyles={tableCustomStyling}
                    columns={columns}
                    data={survey.getMembersOverallResultReducer?.data?.data}
                    onSort={handleSort} sortServer={true}
                />
                {!survey?.getMembersOverallResultReducer?.loading && survey?.getMembersOverallResultReducer?.data && survey?.getMembersOverallResultReducer?.data?.totalPages > 1 &&
                    <Pagination totalPages={survey?.getMembersOverallResultReducer?.data?.totalPages}
                                pageNumber={survey?.getMembersOverallResultReducer?.data?.pageNumber}
                                setPageNumber={setPageNumber}/>}
            </div>

        </>
    )
}
export default MembersVsAttempts