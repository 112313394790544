import Modal from "react-bootstrap/Modal";
import React, {useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {TailSpinLoader} from "../../layout/Loader";
import {isUnauthorized, resourceToFile} from "../../../helpers/utils";
import {getAllUsers, sampleCSVFile, usersImports} from "../../../reduxStore/usersSlices/userSlice";
import {IoIosClose} from "react-icons/io";


const ImportUserModal = ({show, setShow}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const users = useSelector(state => state.users)

    const handleClose = () => {
        setShow(false);
        setIsCsv(null)
    }
    const [file, setFile] = useState(null);
    const [isCsv, setIsCsv] = useState(null);

    const handleFileChange = (e) => {

        if(e.target.files[0] !== undefined){
            const selectedFile = e.target.files[0];
            const fileName = selectedFile.name;
            const fileExtension = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
            if(fileExtension === "xlsx" || fileExtension === "csv"){
                setFile(selectedFile);
            }else{
                return toast.error("Only xlsx and csv files are allowed!", {toastId: "add-user-error"})

            }
        }else{
            setFile(null)
        }
    };

    function handleSubmit(e) {
        e.preventDefault()
        if(file == null){
           return toast.error("File can not be null!", {toastId: "add-user-error"})

        }else{
            dispatch(usersImports(file)).then((res) => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (res.meta.requestStatus === "fulfilled") {
                    setShow(false)
                    dispatch(getAllUsers({
                        sortOrder: "desc",
                        sortBy: "createdAt",
                        page: 1,
                        pageSize: 10,
                    })).then(res => {
                        if (isUnauthorized(res)) {
                            navigate("/")
                        }
                    });

                }
            })
        }

    }

    function handleSampleFile (e,data){
        e.preventDefault()
        dispatch(sampleCSVFile(data)).then((response) => {
            if (isUnauthorized(response)) {
                navigate("/")
            }
            if (response.meta.requestStatus === "fulfilled") {
                resourceToFile(response?.payload?.data, "userData",  data=== true ? ".csv": ".xlsx")
            }
        });
    }




    return(

        <>
            <Modal show={show} centered>
                <Modal.Header className={"d-flex"}>
                    <Modal.Title
                        className='add_member_heading mediumFontSize'>Import Users</Modal.Title>

                    <IoIosClose className={"close_btn"} onClick={handleClose} size={24}/>

                </Modal.Header>

                <form onSubmit={handleSubmit}>
                    <Modal.Body className="modal_outer">

                        <div className="reset_form mediumFontSize">

                        <div className="form-group mt-1">
                            <label className='userInfo_label mediumFontSize' form="inputAddress">File*</label>
                            <input type={"file"} accept={".xlsx , .csv"} onChange={handleFileChange} className={"form-control userInfo_input"}/>

                        </div>
                        </div>

                    </Modal.Body>

                    <div className={"choose-file-button"}>

                            <button style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""} } className=" cmn_background_color mediumFontSize" disabled={users?.sampleCSVFileReducer?.loading === true} onClick={(e)=> {
                                handleSampleFile(e, true)
                                setIsCsv(true)
                            }}>Sample csv file
                                {users?.sampleCSVFileReducer?.loading && isCsv && (
                                    <TailSpinLoader/>)}
                            </button>
                            <button style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""} } className="cmn_background_color mediumFontSize ms-2" disabled={users?.sampleCSVFileReducer?.loading === true} onClick={(e)=> {
                                handleSampleFile(e, false)
                                setIsCsv(false)
                            }}>Sample excel file
                                {users?.sampleCSVFileReducer?.loading && !isCsv &&(
                                    <TailSpinLoader/>)}
                            </button>


                    </div>
                    <Modal.Footer className="modal_footer">



                        <button className="modal_cancel_btn cmn_modal_btn mediumFontSize" onClick={handleClose}>Cancel
                        </button>
                            <button style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""} } type="submit" disabled={users?.userImportReducer?.loading === true}  className= {"modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize "}> Import
                                {users?.userImportReducer?.loading && (
                                    <TailSpinLoader/>)}
                            </button>




                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )

}

export default ImportUserModal;