import Modal from 'react-bootstrap/Modal';
import {TailSpinLoader} from "../../layout/Loader";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    createNote,
    getNoteById,
    searchNotes,
    updateNoteById
} from "../../../reduxStore/notesSlices/notesSlice";
import {getDataFromLocalStorage, isNullOrEmpty, isUnauthorized, showToast} from "../../../helpers/utils";
import {useNavigate} from "react-router-dom";
import {getAllUsersWithoutPagination} from "../../../reduxStore/usersSlices/userSlice";
import {hasPermission, Permissions, Roles} from "../../../helpers/accessControl";
import Skeleton from "react-loading-skeleton";
import {IoIosClose} from "react-icons/io";
import {EnterThought} from "../../../helpers/constants";

const AddOrUpdateNoteModal = ({
                                  type,
                                  show,
                                  setShow,
                                  setKeyword,
                                  noteId = null,
                                  memberId,
                                  isDashBoard = false,
                                  pageNumber,
                                  setPageNumber,
                                  orgId,
                                  isTeam=false
                              }) => {


    const handleClose = () => setShow(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const notes = useSelector(state => state.notes)
    const users = useSelector(state => state.users)
    const [note, setNote] = useState("");
    const [selectedMember, setSelectedMember] = useState("Self");
    const getAllUsersWithoutPaginationData = useSelector(state => state.users.getAllUsersWithoutPaginationReducer)


    useEffect(() => {
        if (type === "Update" && noteId != null) {
            dispatch(getNoteById(noteId)).then((res) => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                if (res.meta.requestStatus === "fulfilled") {
                    setNote(res.payload.data)
                }
            })
        }
    }, []);
    useEffect(() => {
        if (type === "Add" && isDashBoard && hasPermission([Permissions.USER_READ], true)) {
            dispatch(getAllUsersWithoutPagination({
                roles: [Roles.MENTEE],
                orgId: orgId?.value
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }
    }, []);


    const handleSubmit = (e) => {
        e.preventDefault();
        if (isNullOrEmpty(note.note)) {
            showToast(EnterThought, "error")
        } else {

            if (type === "Add") {
                dispatch(createNote({
                    orgId: getDataFromLocalStorage("loggedInUserInfo").orgId,
                    note: note.note,
                    memberId: selectedMember === "Self" ? memberId : selectedMember
                })).then((res) => {
                    if (isUnauthorized(res)) {
                        navigate("/")
                    }
                    if(!isTeam){
                        if (res.meta.requestStatus === "fulfilled") {
                            if (pageNumber === 1) {
                                dispatch(isDashBoard === true ? searchNotes({
                                    page: 1,
                                    note: "",
                                    pageSize: 10
                                }) : searchNotes({page: 1, memberId: memberId})).then(res => {
                                    if (isUnauthorized(res)) {
                                        navigate("/")
                                    }
                                })
                            } else {
                                setPageNumber(1)
                            }
                            setKeyword("")
                        }
                    }
                    setShow(false)

                });
            } else {
                dispatch(updateNoteById({
                    noteId: noteId,
                    userId: getDataFromLocalStorage("loggedInUserInfo").userId,
                    orgId: getDataFromLocalStorage("loggedInUserInfo").orgId,
                    note: note.note,
                    memberId: note.userData.userId
                })).then((res) => {
                    if (isUnauthorized(res)) {
                        navigate("/")
                    }
                    if (res.meta.requestStatus === "fulfilled") {
                        dispatch(isDashBoard === true ? searchNotes({
                            page: pageNumber,
                            note: "",
                            pageSize: 10
                        }) : searchNotes({page: pageNumber, memberId: memberId})).then(res => {
                            if (isUnauthorized(res)) {
                                navigate("/")
                            }
                        })
                        setShow(false)
                        setKeyword("")
                    }
                });
            }
        }

    }

    return (
        type === "Add" || (type === "Update" && !notes?.getNoteByIdDataReducer?.loading && notes?.getNoteByIdDataReducer?.data) ?
            <>
                <Modal className='mt-4' show={show} centered>
                    <Modal.Header>
                        <Modal.Title className='mediumFontSize'>{type} Thought</Modal.Title>
                        <IoIosClose onClick={handleClose} size={24}/>


                    </Modal.Header>

                    <form onSubmit={handleSubmit}>
                        <Modal.Body className="modal_outer">

                            <div className="reset-form">
                                {
                                    (type === "Add" && isDashBoard && hasPermission([Permissions.USER_READ], true)) &&
                                    <div className={"create-thought-for-outer mb-2"}>
                                        <div className={"create-thought-for-text mediumFontSize mb-1 pe-2 mt-2"}>Select
                                            Mentee
                                        </div>
                                        {
                                            users.getAllUsersWithoutPaginationReducer?.loading ?
                                                <Skeleton height={35}/> :
                                                <select value={selectedMember} onChange={(e) => {
                                                    setSelectedMember(e.target.value)
                                                }} className={"create-thought-for-select form-select"}>
                                                    <option selected value="Self">Self</option>
                                                    {
                                                        getAllUsersWithoutPaginationData?.data?.map((memberData, index) => {
                                                            return (
                                                                <option key={index}
                                                                        value={memberData.uid}>{memberData.firstName + " " + memberData.lastName}</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                        }

                                    </div>
                                }
                                <div className="form-group mt-1">
                                    <div className={type === "Add" ? "mt-3" : ""}>
                                        <div className={"create-thought-for-text mediumFontSize mb-1 pe-2"}>Enter
                                            Thought
                                        </div>
                                        <textarea className={"form-control"}
                                                  placeholder={"Type your thought here ........"}
                                                  value={note.note}
                                                  onChange={(e) => {
                                                      setNote({...note, note: e.target.value})
                                                  }} required={true} id="note" name="w3review" rows="4" cols="50">
                                        </textarea>
                                    </div>

                                </div>
                            </div>


                        </Modal.Body>

                        <Modal.Footer className="modal_footer">
                            <button className="modal_cancel_btn cmn_modal_btn mediumFontSize"
                                    onClick={handleClose}>Cancel
                            </button>
                            <button
                                style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                type="submit"
                                disabled={(notes?.createNoteReducer?.loading || notes?.updateNoteByIdDataReducer?.loading) === true}
                                className="modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize">{type} Thought
                                {/* eslint-disable-next-line no-mixed-operators */}
                                {(notes?.createNoteReducer?.loading || notes?.updateNoteByIdDataReducer?.loading) &&
                                    <TailSpinLoader/>}

                            </button>
                        </Modal.Footer>
                    </form>
                </Modal>


            </> : "")


}
export default AddOrUpdateNoteModal;



