import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getAxiosConfig, showToast} from "../../helpers/utils";


export const getAllPermissions = createAsyncThunk('permissions/getAllPermissions',async (roleId, thunkAPI)=>{
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/permission`,getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });})

const permissionsSlice = createSlice({
    name: "permissions",
    initialState: {
        getAllPermissionsDataReducer: {loading:false},

    },
    extraReducers: {
        // Get All Permissions
        [getAllPermissions.pending]: (state) => {
            state.getAllPermissionsDataReducer={loading:true};
        },
        [getAllPermissions.fulfilled]: (state,action) => {
            state.getAllPermissionsDataReducer={loading:false,data:action.payload.data};
        },
        [getAllPermissions.rejected]: (state,action) => {
            state.getAllPermissionsDataReducer={loading:false};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText,"error")
        },
    }

})
export const {} = permissionsSlice.actions

export default permissionsSlice.reducer;