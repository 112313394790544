import Modal from "react-bootstrap/Modal";
import close_square from "../../../images/croosIcon.png";
import Form from "react-bootstrap/Form";

const SectionUpdate = ({show, setShow, indexId, reflectionSegments, setReflectionSegments}) =>{

    const handleClose = () => {
        setShow(false)
    };

    function handleSubmit(e) {
        e.preventDefault();
         setShow(false)

    }
     return(


        <>
            <Modal className='mt-4' show={show} centered>
                <Modal.Header>
                    <Modal.Title
                        className='add_member_heading mediumFontSize'>Section Details</Modal.Title>
                    <div className="closeImg" onClick={handleClose}>
                        <img src={close_square} alt="" width={"15"}/>
                    </div>

                </Modal.Header>

                <form onSubmit={handleSubmit}>

                    <Modal.Body className="modal_outer">

                        <Modal.Body className="modal_outer">

                            <div className="section-data">

                                <div className="form-group mt-1">

                                    <Form.Group className='userInfo_label mediumFontSize mb-2'>
                                        <Form.Label className='regularFontSize'>Section Name</Form.Label>

                                        <input value={reflectionSegments[indexId]?.segmentName} onChange={ (e)=>{

                                            const updatedData = reflectionSegments.map((item, ind) => {
                                                if (ind === indexId) {
                                                    return {
                                                        ...item,
                                                        segmentName: e.target.value,
                                                    };
                                                }
                                                return item;
                                            });
                                            setReflectionSegments(updatedData) }} className="form-control" placeholder={"Enter Section Name"}/>

                                    </Form.Group>

                                    <Form.Group className='userInfo_label mediumFontSize'>
                                        <Form.Label className='regularFontSize'>Section Description</Form.Label>

                                        <textarea rows={2}  value={reflectionSegments[indexId]?.segmentDescription} onChange={ (e)=>{

                                            const updatedData = reflectionSegments.map((item, ind) => {
                                                if (ind === indexId) {
                                                    return {
                                                        ...item,
                                                        segmentDescription: e.target.value,
                                                    };
                                                }
                                                return item;
                                            });
                                            setReflectionSegments(updatedData) }} className="form-control" placeholder={"Enter description "} />


                                    </Form.Group>
                                </div>

                            </div>

                        </Modal.Body>


                    </Modal.Body>

                    <Modal.Footer className="modal_footer">
                        <button className="modal_cancel_btn cmn_modal_btn mediumFontSize"
                                onClick={handleClose}>Cancel
                        </button>
                        <button style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ||  localStorage.getItem("color") !== "undefined" ? localStorage.getItem("color"):""} } type="submit"
                                className="modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize"
                        >Submit

                        </button>
                    </Modal.Footer>
                </form>


            </Modal>
        </>

     )
}
export default  SectionUpdate;