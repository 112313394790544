import usericon from '../../images/default.png'
import right_arrow from '../../images/arrow-right.png'
import './ResultDetails.css'

const ResultDetails = () => {

    const disArray = "85" * Math.PI * 2;
    const disoffset = disArray - (disArray * "60") / 100;

    return (
        <>


            <section>


                <div className='row main_content'>

                    <div className='col-md-12 main_results_container'>

                        <div className='pathways_container'>
                            <div className='spiritual'>
                                <h2 className='mediumFontSize'>Spiritual Pathways</h2>
                                <h3>12.09.2023</h3>

                            </div>
                            <div className='next_btn_container'>
                                <button className='next_btn mediumFontSize '>Next</button>
                                <img src={right_arrow} className='right_arrow'/>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-xl-4 col-sm-12 col-md-12 mb-2">
                                <div className='risk_container'>

                                    <h3 className='risk_heading cmn_risk_heading mediumFontSize '>High risk</h3>

                                    <div className='circle-custom'>
                                        <svg width={"200px"}
                                             height={"200px"}
                                             viewBox={"0 0 200, 200"}>

                                            <defs>
                                                <linearGradient id="grd">
                                                    <stop offset={"40%"} stop-color={"#DB4C2D"}/>
                                                    <stop offset={"100%"} stop-color={"#FCAD80"}/>

                                                </linearGradient>
                                            </defs>
                                            <circle cx={"200" / 2} cy={"200" / 2} strokeWidth={"20px"} r={"85"}
                                                    className='circle-background'>

                                            </circle>

                                            <circle cx={"200" / 2} cy={"200" / 2} strokeWidth={"20px"} r={"85"}
                                                    className='circle-progress' style={
                                                {strokeDasharray: disArray, strokeDashoffset: disoffset}}
                                                    transform={`rotate(-120 ${200 / 2} , ${200 / 2}) `}
                                                    stroke='url(#grd)'
                                            >

                                            </circle>
                                            <text x={"100px"} y={"100px"} dy={"0.3em"} textAnchor='middle'
                                                  className='circle-text'>
                                            </text>
                                        </svg>
                                        <div className="custom_text">
                                            <h5 className='mediumFontSize'>2 Member</h5>
                                            <p className='mediumFontSize'>have more then 80% negative answer in
                                                survey</p>
                                        </div>

                                    </div>

                                    <div className='user_outer_survey mt-3'>
                                        <ul>
                                            <li className='user_container'>
                                                <img src={usericon} className='userimg'/>
                                                <div className='user_name cmn_userstyle'>
                                                    <h2>Kelly Casper</h2>
                                                    <h3>kelly.casper@hotmail.com</h3>
                                                </div>
                                            </li>
                                            <li className='user_container'>
                                                <img src={usericon} className='userimg'/>
                                                <div className='user_name cmn_userstyle'>
                                                    <h2>Kelly Casper</h2>
                                                    <h3>kelly.casper@hotmail.com</h3>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4 col-sm-12 col-md-12 mb-2">
                                <div className='risk_container'>

                                    <h3 className='low_risk_heading cmn_risk_heading mediumFontSize'>Low risk</h3>
                                    <div className='circle-custom'>
                                        <svg width={"200px"}
                                             height={"200px"}
                                             viewBox={"0 0 200, 200"}>

                                            <defs>
                                                <linearGradient id="grd">
                                                    <stop offset={"40%"} stop-color={"#DB4C2D"}/>
                                                    <stop offset={"100%"} stop-color={"#FCAD80"}/>

                                                </linearGradient>
                                            </defs>
                                            <circle cx={"200" / 2} cy={"200" / 2} strokeWidth={"20px"} r={"85"}
                                                    className='circle-background'>

                                            </circle>

                                            <circle cx={"200" / 2} cy={"200" / 2} strokeWidth={"20px"} r={"85"}
                                                    className='circle-progress' style={
                                                {strokeDasharray: disArray, strokeDashoffset: disoffset}}
                                                    transform={`rotate(-120 ${200 / 2} , ${200 / 2}) `}
                                                    stroke='url(#grd)'
                                            >

                                            </circle>
                                            <text x={"100px"} y={"100px"} dy={"0.3em"} textAnchor='middle'
                                                  className='circle-text'>
                                            </text>
                                        </svg>
                                        <div className="custom_text">
                                            <h5 className='mediumFontSize'>4 Member</h5>
                                            <p className='mediumFontSize'>have more then 80% negative answer in
                                                survey</p>
                                        </div>

                                    </div>
                                    <div className='user_outer_survey mt-3'>
                                        <ul>
                                            <li className='user_container'>
                                                <img src={usericon} className='userimg'/>
                                                <div className='user_name cmn_userstyle'>
                                                    <h2>Kelly Casper</h2>
                                                    <h3>kelly.casper@hotmail.com</h3>
                                                </div>
                                            </li>
                                            <li className='user_container'>
                                                <img src={usericon} className='userimg'/>
                                                <div className='user_name cmn_userstyle'>
                                                    <h2>Kelly Casper</h2>
                                                    <h3>kelly.casper@hotmail.com</h3>
                                                </div>
                                            </li>
                                            <li className='user_container'>
                                                <img src={usericon} className='userimg'/>
                                                <div className='user_name cmn_userstyle'>
                                                    <h2>Kelly Casper</h2>
                                                    <h3>kelly.casper@hotmail.com</h3>
                                                </div>
                                            </li>
                                            <li className='user_container'>
                                                <img src={usericon} className='userimg'/>
                                                <div className='user_name cmn_userstyle'>
                                                    <h2>Kelly Casper</h2>
                                                    <h3>kelly.casper@hotmail.com</h3>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4 col-sm-12 col-md-12 mb-2">
                                <div className='risk_container'>

                                    <h3 className='good_risk_heading cmn_risk_heading mediumFontSize '>Good</h3>
                                    <div className='circle-custom'>
                                        <svg width={"200px"}
                                             height={"200px"}
                                             viewBox={"0 0 200, 200"}>

                                            <defs>
                                                <linearGradient id="grd">
                                                    <stop offset={"40%"} stop-color={"#DB4C2D"}/>
                                                    <stop offset={"100%"} stop-color={"#FCAD80"}/>

                                                </linearGradient>
                                            </defs>
                                            <circle cx={"200" / 2} cy={"200" / 2} strokeWidth={"20px"} r={"85"}
                                                    className='circle-background'>

                                            </circle>

                                            <circle cx={"200" / 2} cy={"200" / 2} strokeWidth={"20px"} r={"85"}
                                                    className='circle-progress' style={
                                                {strokeDasharray: disArray, strokeDashoffset: disoffset}}
                                                    transform={`rotate(-120 ${200 / 2} , ${200 / 2}) `}
                                                    stroke='url(#grd)'
                                            >

                                            </circle>
                                            <text x={"100px"} y={"100px"} dy={"0.3em"} textAnchor='middle'
                                                  className='circle-text'>
                                            </text>
                                        </svg>
                                        <div className="custom_text">
                                            <h5 className='mediumFontSize'>1 Member</h5>
                                            <p className='mediumFontSize'>have more then 80% negative answer in
                                                survey</p>
                                        </div>

                                    </div>
                                    <div className='user_outer_survey mt-3'>
                                        <ul>
                                            <li className='user_container'>
                                                <img src={usericon} className='userimg'/>
                                                <div className='user_name cmn_userstyle'>
                                                    <h2>Jody Beer</h2>
                                                    <h3>kelly.casper@hotmail.com</h3>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </section>

        </>
    )
}
export default ResultDetails