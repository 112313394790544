import Modal from 'react-bootstrap/Modal';
import {TailSpinLoader} from "../layout/Loader";
import {useState} from "react";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {IoIosClose} from "react-icons/io";

const ConfirmationModel = ({
                               title,
                               btnText,
                               bodyMessage,
                               action,
                               show,
                               setShow,
                               isLoading,
                               showUserAcknowledgementCheckBox = false,
                               userAcknowledgementCheckBoxText,
                           }) => {

    const handleClose = () => setShow(false);
    const [isAgree, setIsAgree] = useState(false)

    return (
        <>
            <Modal className='mt-4' show={show} centered>
                <Modal.Header className={"d-flex"}>
                    <Modal.Title className='mediumFontSize'>{title}</Modal.Title>
                    <IoIosClose className={"close_btn"} onClick={handleClose} size={24}/>
                </Modal.Header>


                <Modal.Body className="modal_outer">


                    <div className="reset_form">
                        <div className="form-group mt-1">
                            {bodyMessage}
                        </div>
                        {
                            showUserAcknowledgementCheckBox &&
                            <div className={"confirmation-box mt-2"}>
                                <input className={"reflection-disable"} type={"checkbox"}
                                       style={{accentColor: "black"}} onChange={() => {
                                    setIsAgree(!isAgree)
                                }}/>
                                <div className={"i-agree-text ps-2"}>{userAcknowledgementCheckBoxText}</div>
                            </div>
                        }
                    </div>


                </Modal.Body>

                <Modal.Footer className="modal_footer">
                    <button className="modal_cancel_btn cmn_modal_btn mediumFontSize" onClick={handleClose}>Cancel
                    </button>
                    {!showUserAcknowledgementCheckBox ? <button style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""} } type="submit" disabled={isLoading === true}
                                                  className="modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize"
                                                  onClick={action}>{btnText}
                        {
                            isLoading && <TailSpinLoader/>
                        }

                    </button> : <>
                        <button style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""} } type="submit" disabled={isLoading}
                                data-tooltip-id="ConfirmationBTN"
                                data-tooltip-content={isAgree?"":"Please check the confirmation box to continue"}
                                        className={isAgree ? "modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize" : "modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize disable-btn"}
                                        onClick={() => {
                                            if (isAgree) {
                                                action()
                                            }
                                        }}>{btnText}
                        {
                            isLoading && <TailSpinLoader/>
                        }

                    </button>
                        <ReactTooltip className={"ToolTip"} place={"bottom"}  id="ConfirmationBTN"/>
                    </>
                    }
                </Modal.Footer>

            </Modal>
        </>
    )
}
export default ConfirmationModel;