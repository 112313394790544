import Modal from "react-bootstrap/Modal";
import {IoIosClose} from "react-icons/io";
import Select from "react-select";
import {createOptionListForSelectTag,  isUnauthorized} from "../../../helpers/utils";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {hasPermission, Permissions} from "../../../helpers/accessControl";
import {useNavigate} from "react-router-dom";
import {getAllAssignedReflections} from "../../../reduxStore/surveySlices/surveySlices";

const AttemptReflectionModal = ({show, setShow,selectedMember}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [selectedReflection, setSelectedReflection] = useState(null)

    const getTeamByIdData = useSelector(state => state.team.getTeamByIdReducer)
    const getAllAssignedReflectionsData = useSelector(state => state.survey.getAllAssignedReflectionsReducer)

    useEffect(() => {
        if (hasPermission([Permissions.REFLECTION_READ], true)) {
            dispatch(getAllAssignedReflections(getTeamByIdData?.data?.uid)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }
    }, []);

    const handleClose = () => {
        setShow(false);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        navigate(`/teams/${getTeamByIdData?.data?.uid}/answerReflection/${selectedMember}/${selectedReflection?.value}`)
    }

    return (
        <>
            <Modal className='mt-4' show={show} centered>
                <Modal.Header className={"d-flex"}>
                    <Modal.Title className='add_member_heading mediumFontSize'>Attempt Reflection</Modal.Title>
                    <IoIosClose className={"close_btn"} onClick={handleClose} size={24}/>
                </Modal.Header>
                <form onSubmit={handleSubmit}>
                    <Modal.Body className="modal_outer">
                        <div className="reset_form">
                            <div className={"form-group mt-1"}>
                                <label className={"userInfo_label mb-2"}
                                       htmlFor="exampleFormControlSelect1"> Select Reflection </label>
                                <Select
                                    className="members-filter-role react-select-container"
                                    classNamePrefix="react-select"
                                    isDisabled={getAllAssignedReflectionsData?.loading}
                                    isClearable={true}
                                    value={selectedReflection}
                                    options={createOptionListForSelectTag(getAllAssignedReflectionsData?.data, "name", "surveyId")}
                                    onChange={(selectedValue) => {
                                        setSelectedReflection(selectedValue)
                                    }}
                                    placeholder={"Select..."}/>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modal_footer">
                        <div className={"add-member-modal-btns"}>
                            <button
                                style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                type="submit"
                                disabled={selectedReflection === null}
                                className={"ms-2 modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize "+(selectedReflection === null ?"opacity6":"")}>Attempt
                            </button>
                            <button className="modal_cancel_btn cmn_modal_btn mediumFontSize"
                                    onClick={handleClose}>Cancel
                            </button>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}
export default AttemptReflectionModal