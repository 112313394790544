import React, {useRef, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {getDataFromLocalStorage, handleApiResponse, isNullOrEmpty, isUnauthorized} from "../../helpers/utils";
import {hasPermission, Permissions, Roles} from "../../helpers/accessControl";
import Skeleton from "react-loading-skeleton";
import {
    getAllUsersWithoutPaginationAddReflectionBtn
} from "../../reduxStore/usersSlices/userSlice";
import {
    assignSurveyAttemptToMentee,
    getAllAttemptSurveyByUserId,
    getAllSurveysWithoutPaginationForAddReflectionBtn
} from "../../reduxStore/surveySlices/surveySlices";
import {useDispatch, useSelector} from "react-redux";
import jwtDecode from "jwt-decode";
import {PulsePlans, SurveyAttemptStatus} from "../../helpers/constants";
import {toString} from "../../helpers/utils";
import {TailSpinLoader} from "./Loader";
import {getAllOrgAsList} from "../../reduxStore/orgSlices/orgSlices";

const AddReflectionWindow = ({setShow}) => {

    const {userId} = useParams();
    const token = jwtDecode(getDataFromLocalStorage("token"))
    const isSysAdminOrSysManager = token?.orgLevel === "SYSTEM" && (token?.roles?.includes(Roles.MANAGER) || token?.roles?.includes(Roles.ORG_ADMIN))
    const color = localStorage.getItem("color")

    const divRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [selectedOrgId, setSelectedOrgId] = useState(token?.orgId);
    const [selectedMemberId, setSelectedMemberId] = useState("");
    const [selectedMember, setSelectedMember] = useState(null);
    const [assignReflectionToMentee, setAssignReflectionToMentee] = useState(null);
    const [selectedSurvey, setSelectedSurvey] = useState("");
    const [surveyData, setSurveyData] = useState(null);
    const [planName, setPlanName] = useState(jwtDecode(getDataFromLocalStorage("token")).planName)

    const orgApi = useSelector(state => state.org.getAllOrgAsListReducer);
    const users = useSelector(state => state.users)
    const survey = useSelector(state => state.survey)


    useEffect(() => {
        if (selectedMemberId !== null && selectedMemberId !== undefined && selectedMemberId) {
            const selectedMember = users?.getAllUsersWithoutPaginationAddReflectionBtnReducer?.data?.filter(c => c.uid === selectedMemberId)?.[0]
            setSelectedMember(selectedMember)
        }
    }, [selectedMemberId])

    useEffect(() => {
        if (assignReflectionToMentee !== null) {
            setAssignReflectionToMentee(null)
            dispatch(assignSurveyAttemptToMentee({
                surveyId: selectedSurvey,
                surveyeeId: selectedMemberId,
                surveyAttemptStatus: SurveyAttemptStatus.ASSIGNED
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                } else if (res.meta.requestStatus === "fulfilled") {
                    setShow(false)
                    if (window.location.pathname.startsWith("/members/") && userId !== undefined && hasPermission([Permissions.ATTEMPT_REFLECTION_READ])) {
                        dispatch(getAllAttemptSurveyByUserId({
                            userId: userId, pageNumber: 1, sortBy: "createdAt", sortOrder: "desc"
                        })).then(res => {
                            if (isUnauthorized(res)) {
                                navigate("/")
                            }
                        })
                    }

                }
            })
        }
    }, [assignReflectionToMentee]);

    useEffect(() => {
        if (isSysAdminOrSysManager) {
            dispatch(getAllOrgAsList()).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                handleApiResponse(res, () => {
                    const orgInfo = res.payload.data?.filter(org => org.orgId === token?.orgId)[0]
                    setSelectedOrgId(orgInfo.orgId)
                })
            })
        }
    }, [])

    useEffect(() => {
        if (hasPermission([Permissions.USER_READ, Permissions.REFLECTION_READ], true) && !isNullOrEmpty(selectedOrgId)) {
            dispatch(getAllUsersWithoutPaginationAddReflectionBtn(
                {
                    roles: Object.keys(Roles)?.filter(role => role !== Roles.SYS_ADMIN && role !== Roles.ORG_ADMIN && role !== Roles.SUB_ORG_ADMIN && role !== Roles.ANONYMOUS_USER),
                    ...(isSysAdminOrSysManager ? {orgId: selectedOrgId} : {})
                }
            )).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
            dispatch(getAllSurveysWithoutPaginationForAddReflectionBtn({
                ...(isSysAdminOrSysManager ? {orgLevelId:selectedOrgId} : {})
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                } else if (res.meta.requestStatus === "fulfilled") {
                    planName === PulsePlans.INDIVIDUAL.value ? setSelectedSurvey(res.payload.data[0].surveyId) : setSurveyData(res.payload.data)
                }
            })
        }

    }, [selectedOrgId]);

    useEffect(() => {
        // Function to handle clicks outside of the div
        const handleClickOutside = event => {
            if ((divRef.current && !divRef.current.contains(event.target)) &&
                event.target.id !== "addReflectionModalButton") {
                // Action to perform when clicked outside of the div
                setShow(false)
            }
        };

        // Add event listener to the document when the component mounts
        document.addEventListener('click', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div ref={divRef} className={"add-reflection-window-outer "}>
                <h2 className={"mediumFontSize add-reflection-txt mb-3"}>Add Reflection</h2>
                {
                    isSysAdminOrSysManager && <>
                        <div className={"choose-mentee-txt mb-2 mt-3"}>Choose Organization</div>
                        {
                            orgApi?.loading ?
                                <div><Skeleton height={40}/></div>
                                :
                                <select value={selectedOrgId}
                                        onChange={(e) => {
                                            setSelectedOrgId(e.target.value)
                                        }}
                                        className="form-select add-reflection-window-select"
                                        aria-label="Default select example">
                                    <option value="">Select</option>
                                    {
                                        orgApi?.data?.map((orgData, index) => {
                                            return (
                                                <option key={index} value={orgData?.orgId}>{orgData?.name}</option>
                                            );
                                        })
                                    }
                                </select>
                        }
                    </>
                }

                <div className={"choose-mentee-txt mb-2 mt-3"}>Choose member</div>

                {
                    users?.getAllUsersWithoutPaginationAddReflectionBtnReducer?.loading ?
                        <div><Skeleton height={40}/></div>
                        :
                        <select value={selectedMemberId}
                                onChange={(e) => {
                                    setSelectedMemberId(e.target.value)
                                }}
                                className="form-select add-reflection-window-select"
                                aria-label="Default select example">
                            <option value="">Select</option>
                            {
                                users?.getAllUsersWithoutPaginationAddReflectionBtnReducer?.data?.map((memberData, index) => {
                                    return (
                                        <option key={index}
                                                value={memberData.uid}>{(memberData.firstName + " " + memberData.lastName) + " (" + (memberData?.roleObjects?.map(role => toString(role.name)).join(" / ")) + ")"}</option>
                                    );
                                })
                            }
                        </select>
                }
                {
                    planName !== PulsePlans.INDIVIDUAL.value && <>
                        <div className={"mb-2 mt-3 choose-mentee-txt"}>Choose the reflection</div>
                        {
                            survey?.getAllSurveysWithoutPaginationForAddReflectionBtnReducer?.loading ?
                                <div><Skeleton height={40}/></div> :
                                <select value={selectedSurvey} onChange={(e) => {
                                    setSelectedSurvey(e.target.value)
                                }} className="form-select add-reflection-window-select"
                                        aria-label="Default select example">
                                    <option value="">Select</option>
                                    {
                                        surveyData?.map((survey, index) => {
                                            return (
                                                <option key={index} value={survey.surveyId}>{survey.name}</option>
                                            );
                                        })
                                    }
                                </select>

                        }
                    </>
                }
                <button
                    style={{background: color !== null && color !== undefined ? color : ""}}
                    onClick={() => {
                        if (!isNullOrEmpty(selectedMemberId) && !isNullOrEmpty(selectedSurvey)) {
                            navigate(`/members/answerReflection/${selectedMemberId}/${selectedSurvey}`)
                            setShow(false)
                        }
                    }}
                    disabled={users?.getAllUsersWithoutPaginationAddReflectionBtnReducer?.loading || survey?.getAllSurveysWithoutPaginationForAddReflectionBtnReducer?.loading}
                    className={"cmn_background_color  add-reflection-submit-btn w-100 mt-4"}
                >Attempt now
                </button>
                <button
                    disabled={selectedMember === null || selectedMember?.roleObjects[0]?.name !== Roles.MENTEE || survey.assignSurveyAttemptToMenteeDataReducer?.loading}
                    style={{
                        background: color !== null && color !== undefined ? color : "",
                        opacity: selectedMember?.roleObjects[0]?.name !== Roles.MENTEE ? ".7" : "1"
                    }}
                    onClick={() => {
                        if (selectedMember !== null && selectedMember?.roleObjects[0]?.name === Roles.MENTEE && !survey.assignSurveyAttemptToMenteeDataReducer?.loading) {
                            setAssignReflectionToMentee(new Date().getMilliseconds())
                        }
                    }} className={"cmn_background_color  add-reflection-submit-btn w-100 mt-2"}>Assign to
                    mentee {
                        survey.assignSurveyAttemptToMenteeDataReducer?.loading &&
                        <TailSpinLoader></TailSpinLoader>
                    }
                </button>
            </div>
        </>

    );
}
export default AddReflectionWindow;