export const EmailRequired = "Email is required!"
export const InvalidEmail = "Not a valid email!"
export const IncludesAnInvalidEmail = "Includes an invalid email!"
export const EmailAndPasswordRequired = "Please enter email and password!"
export const RoleRequired = "Role is required!"
export const AssignMenteeRequired = "Please assign mentor!"
export const EmailAndRoleRequired = "Email and role is required!"
export const ConfirmPassword = "Please confirm your password!"
export const PasswordMisMatched = "Password does not match with confirm password!"
export const CurrentPasswordRequired = "Enter current password!"
export const PasswordRequired = "Password is required!"
export const WeakPassword = "Password must contains at least 8 characters!"
export const FirstNameRequired = "First name is required!"
export const FirstNameTooLong = "First name must be less than 25 characters! "
export const LastNameTooLong = "Last name must be less than 25 characters! "
export const arrayEmpty = "Mentor/Manager can not be empty! "

export const PhoneNumberRequired = "Phone number is required!"
export const InvalidPhoneNumber = "Phone number is invalid!"
export const OrgNameRequired = "Organization name is required!"
export const OrgLevelRequired = "orgLevel is required!"
export const AddressRequired = "Address is required!"
export const CityRequired = "City is required!"
export const StateRequired = "State is required!"
export const CountryRequired = "Country is required!"
export const PostalCodeRequired = "Postal code is required!"
export const InvalidPostalCode = "Postal code must follow ZIP+4 format!"
export const SubOrgCreatedSuccessfully = "Sub organization created successfully!"
export const createOrganizationPreference = "Organisation Preference has been created "
export const exceptThisSymbols = ["e", "E", "+", "-", "."];

export const AccessDenied = "Access Denied"
export const ReflectionNameRequired = 'Reflection name is required!'
export const ProvideQuestionFor = "Please provide question number {0}"
export const ProvideQuestionForSegment = "Please provide question number {0} in section {1}"
export const ReflectionCreatedSuccessfully = "Reflection created successfully!"
export const AccessDeniedMsg = "You do not have permission to access this page!"
export const OptionsRequired = "Please provide required options for {0} question"
export const OptionValueRequired = "Please provide options weight for {0} question"
export const OptionWeightSame = "Options weight cannot be same in {0} question"
export const OneQuestionAtLeastRequired = "Reflection must contain at least one question"
export const SameQuestionsOption = "Option text can't be same for {0} question "
export const AttemptSurveySaveAsDraft = "Reflection is saved to drafts!"
export const AttemptSurveyAssignedToMentee = "Reflection has been assigned to mentee successfully"
export const AnswerRequiredQuestions = "All the required questions are mandatory to answer!"
export const AttemptSurveyShareReport = "The report has been successfully shared via email!";
export const ThoughtCreatedSuccessfully = "Thought Created Successfully!"
export const TriggerCreatedSuccessfully = "Trigger has been created Successfully!"
export const ReportCreatedSuccessfully = "Report created Successfully!"

export const SurveyMustContainRequiredQuestion = "Reflection must contain at least one required question!"
export const MaxValueSmallerThenMinValue = "Max value cannot be less then Min value for {0} question "
export const SameMaxValueAndMinValue = "Max value and Min value cannot be same for {0} question "
export const SelectRange = "Please select a valid range for {0} question"
export const InvalidAnswerForNumberQuestion = "Please select a valid answer within given range for question"
export const OutOfRange = "You may provide a maximum of 10 options for a question."

export const DisabledModifyReflectionBTNText = "You do not have the necessary permissions to modify this reflection. Please contact your organizational administrator to assist."
export const DisabledSaveAsDraftAttemptReflectionBTNText = "Please save as a draft before attempting to submit it. Once reflection is submitted, it cannot be edited."
export const DisabledSubmitAttemptReflectionBTNText = "Please complete reflection to submit. Once submitted, no changes can be made therefore."
export const ReflectionLevelRequired = "Please select reflection level!"
export const NoReflectionToAttempt = "No reflection found to attempt!"
export const SubscriptionAlreadyExists = "Oops! It looks like you already have an active subscription. No need to double up on the goodness – you're all set to enjoy our premium content!"
export const SomethingWentWrong = "Something went wrong"
export const LinkExpired = "Link has been expired!"
export const AttemptBeforeSavingToDraft = "Please attempt reflection before saving as draft!"
export const EnterThought = "Please enter thought!"
export const IsRequired = "{0} is required!"
export const PleaseSelect = "Please select {0}!"
export const CreatedSuccessfully = "{0} created successfully!"
export const UpdatedSuccessfully = "{0} updated successfully!"
export const isInvalid = "{0} is invalid!"
export const DeletedSuccessfully = "{0} deleted successfully!"
export const SingleQuestionInSectionRequired = " Please provide at least one question in {0}!"
export const AddedSuccessfully = "{0} added successfully!"
export const SelectAtLeastOne = "Please select at least one team {0}!"
export const UpdateTeamErrorSelectAtLeastOne = "Error removing, A team must have at least one {0}!"
export const UnassignReflectionConfirmationMsg = "Are you sure you want to unassign this reflection? This action will remove the assigned status for the selected reflection and make it available for reassignment."
export const OptionValueWeightError = "Weight should be a value greater than 0 and less than or equal to 100. Please review and adjust your choices."
export const CampaignNotEditable = "The campaign is currently running and cannot be edited at this time."
export const ConnectApiKeyThenEdit = "Please connect the API key first, and then proceed to edit the campaign."

export const ProvideStrategyDescription = "Please provide description for Strategy."

export const EXPONENT_SURVEY = "Discipleship Pulse"

export const menteeRoutes = ["/mentee/reflections", "/mentee/attempt", "/mentee/reflectionResult"]

export const Marks = Object.freeze({
    HIGH_RISK: "Stagnating",
    LOW_RISK: "Growing",
    GOOD: "Maturing"
})
export const SurveyAttemptStatus = Object.freeze({
    DRAFT: "DRAFT",
    ASSIGNED: "ASSIGNED",
    COMPLETED: "COMPLETED",
})
export const Survey = Object.freeze({
    SURVEY_STATUS_DRAFT: "DRAFT",
    SURVEY_STATUS_PUBLISHED: "PUBLISHED",
    ATTEMPT_SURVEY_STATUS_COMPLETED: "COMPLETED"
})
export const QuestionType = Object.freeze({
    OPTION: "OPTION",
    TEXT: "TEXT",
    RANGE: "RANGE",
    NUMBER: "NUMBER"
})
export const PulsePlans = Object.freeze({
    INDIVIDUAL: {value: "Individual", ordinal: 0},
    PULSE_PRO: {value: "Pulse Pro", ordinal: 1},
    PULSE_PLUS: {value: "Pulse Plus", ordinal: 2}
})

export const orgLevels = Object.freeze({
    SYS: {value: "System", name: "SYSTEM"},
    CUSTOMER: {value: "Customer", name: "CUSTOMER"}
})
export const ReportType = Object.freeze({
    REFLECTION_ATTEMPT_METRICS: "Reflection Attempt Metrics",
    USER_ATTEMPT_METRICS: "User Attempt Metrics"
    // SUBSCRIPTION_STATUS_METRICS : "Subscription Status Metrics"
})
export const SubscriptionPlans = Object.freeze({
    UNSUBSCRIBED: "UNSUBSCRIBED",
    SUBSCRIBED: "SUBSCRIBED",
    EXPIRED: "EXPIRED",
    TRIAL: "TRIAL",
    CANCELED: "CANCELED"
})

export const MarksNumbers = Object.freeze({
    GOOD: 100,
    PASS_PERCENTAGE: 34,
    GOOD_PERCENTAGE: 67
})

export const DefaultReports = [
    {name: "Reflection Summary Report", value: "0"},
    {name: "Member Attempt Summary Report", value: "1"},
]

// Campaign
export const CampaignType = [
    {name: "Reflection", value: "REFLECTION"},
    {name: "Report", value: "REPORT"},
]
export const CampaignDeliveryMethod = [
    {name: "SendGrid", value: "SENDGRID"},
    // {name: "Twilio", value: "TWILIO"},
]
export const CampaignStatus = [
    {name: "Active", value: "ACTIVE"},
    {name: "Paused", value: "PAUSED"},
]
export const CampaignRecurrence = [
    {name: "None", value: "NONE"},
    {name: "Daily", value: "DAILY"},
    {name: "Weekly", value: "WEEKLY"},
    {name: "Monthly", value: "MONTHLY"},
    {name: "Quarterly", value: "QUARTERLY"},
    {name: "Annually", value: "ANNUALLY"},
]

export const hexToRgb = (hex, alpha) => {
    hex = hex?.replace(/^#/, '');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    alpha = typeof alpha === 'number' && alpha >= 0 && alpha <= 1 ? alpha : 1;

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export let defaultQuestionForReflection = {
    isTextLimitExceeded: false,
    questionText: "",
    isRequired: true,
    isShow: false,
    type: QuestionType.OPTION,
    sortOrder: 0,
    segmentIndex: null,
    options: [{isTextLimitExceeded: false, optionText: "", optionValue: null}, {
        optionText: "",
        optionValue: null
    }, {
        optionText: "",
        optionValue: null
    }],
    number: {
        min: null,
        max: null,
        isMinLowestRank: true
    },
    tags: [],
    goals: []
};
