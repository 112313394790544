import { Modal ,Button, Form} from "react-bootstrap"
import userIcon from '../../../images/default.png'
import close_icon from '../../../images/croosIcon.png'
import './SelectmemberModal.css'
const SelectMemberModal =({show, setShow})=>{
    const handleClose = () => setShow(false);
    return(<>
    <Modal className="mt-4" show={show} centered>
        <Modal.Header>
          <Modal.Title className="select_member_heading mediumFontSize ">Select Member</Modal.Title>
          <div className="closeImg" onClick={handleClose}>
                        <img src={close_icon} alt="" width={"15"} />
                    </div>

        </Modal.Header>
        <Modal.Body>
            <div className="selectMember_outer">
                <div className="member_Container">
                {/* <Form.Group className="selectMember_Form">
                <Form.Check>
                </Form.Check>
                <div className="">
                <h2 className="cmn_small_fontstyle mediumFontSize all_heading">All</h2>
                
                </div>
                <div className="Image_Container">
                <img src={down_arrow} />
                </div>
            </Form.Group> */}
            <Form.Group className="selectMember_Form">
                {/* <Form.Check>
                </Form.Check> */}
                <div className="Image_Container">
                <img src={userIcon} height="32" width="32"/>
                </div>
                <div className="userEmail">
                <h2 className="cmn_fontStyle mediumFontSize">Kelly Casper</h2>
                <p className="cmn__fontStyle mailto:mediumfontsize">mariocoss@hotmail.com</p>
                </div>
            </Form.Group>
            <Form.Group className="selectMember_Form">
                {/* <Form.Check>
                </Form.Check> */}
                <div className="Image_Container">
                <img src={userIcon} height="32" width="32"/>
                </div>
                <div className="userEmail">
                <h2 className="cmn_fontStyle mediumFontSize">Kelly Casper</h2>
                <p className="cmn_fontStyle mailto:mediumfontsize">mariocoss@hotmail.com</p>
                </div>
            </Form.Group>
            <Form.Group className="selectMember_Form">
                {/* <Form.Check>
                </Form.Check> */}
                <div className="Image_Container">
                <img src={userIcon} height="32" width="32"/>
                </div>
                <div className="userEmail">
                <h2 className="cmn_fontStyle mediumFontSize">Kelly Casper</h2>
                <p className="cmn_fontStyle mailto:mediumfontsize">mariocoss@hotmail.com</p>
                </div>
            </Form.Group>
            <Form.Group className="selectMember_Form">
               
                <div className="Image_Container">
                <img src={userIcon} height="32" width="32"/>
                </div>
                <div className="userEmail">
                <h2 className="cmn_fontStyle mediumFontSize">Kelly Casper</h2>
                <p className="cmn_fontStyle mailto:mediumfontsize">mariocoss@hotmail.com</p>
                </div>
            </Form.Group>
                </div>
            </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <button className="select_member_cancelBtn boldFontSize cmn_small_fontstyle" onClick={handleClose}>
           Cancel
          </button>
          <button   className="select_member_continueBtn boldFontSize cmn_small_fontstyle"onClick={handleClose}>
           Continue
          </button>
        </Modal.Footer> */}
      </Modal>
    </>)
}
export default SelectMemberModal;