import {ActionEnum} from "../../../helpers/accessControl";
import {FaPlusCircle, FaTrash} from "react-icons/fa";
import {
    createOptionListForSelectTagWithRole,
    showRecommendedCheckBox
} from "../../../helpers/utils";
import Select from "react-select";
import {useSelector} from "react-redux";

const PrepareActions = ({actions, setActions,setSelectedValue,selectOperand}) => {

    const users = useSelector(state => state.users);
    const handleAddAction = () => {
        setActions([...actions,
            {actionType: "", actionEvents: []}
        ])
    }
    const handleDeleteAction = (actionIndex) => {
        const updatedAction = actions?.filter((cur, ind) => {
            return ind !== actionIndex
        })
        setActions(updatedAction)
    }
    const handleActionsChange = (actionIndex, e) => {
        const {name, checked, value} = e.target;
        setActions(prevActions => {
            const updatedActions = prevActions.map((action, index) => {
                if (index !== actionIndex) return action;
                const updatedActionEvents = checked
                    ? [...action.actionEvents, {
                        actionLabel: name,
                        actionValue: value === 'on' || value === '' ? '' : value
                    }]
                    : action.actionEvents.filter(item => item.actionLabel !== name);
                setSelectedValue(new Date().getTime())

                return {...action, actionEvents: updatedActionEvents};
            });

            return updatedActions;

        })
    };

    const handleMentorAction = (actionIndex, data) => {
        setActions(prevActions => {
            const updatedActions = prevActions.map((action, index) => {
                if (index !== actionIndex) return action;
                const existingEventIndex = action.actionEvents.findIndex(event => event.actionLabel === data?.label);

                if (existingEventIndex !== -1) {
                    const updatedActionEvents = [...action.actionEvents];
                    updatedActionEvents[existingEventIndex] = {actionLabel: data?.label, actionValue: data?.value};
                    return {...action, actionEvents: updatedActionEvents};
                } else {
                    const newEvent = {actionLabel: data?.label, actionValue: data?.value};
                    return {...action, actionEvents: [newEvent]};
                }
            });

            return updatedActions;
        });
    };

     return (
         <>
             {actions?.length > 0 && actions?.map((actionData, actionIndex) => {

                 return (
                     <>
                         <div className={"select-action-card"}>

                             <div className={"prepare-action mediumFontSize"}>

                                 <label className={"mb-2"}>Select Action</label>
                                 <select value={actionData.actionType}
                                         className={"form-select mb-2"} name={"operand"}
                                         onChange={(e) => {
                                             setSelectedValue(new Date().getTime())
                                             const updatedSelectAction = [...actions]; // Create a shallow copy of the actions array
                                             updatedSelectAction[actionIndex] = {
                                                 ...updatedSelectAction[actionIndex],
                                                 actionType: e.target.value
                                             };
                                             setActions(updatedSelectAction);

                                         }}>
                                     <option value={""} selected>Select Action</option>
                                     {ActionEnum?.length > 0 && ActionEnum?.map((cur) => {
                                         return <>
                                             <option value={cur.value}>{cur.name}</option>
                                         </>
                                     })
                                     }
                                 </select>

                                 {actions.length - 1 === actionIndex &&
                                     <div className={"add-action"}>
                                         <FaPlusCircle onClick={() => {
                                             handleAddAction()
                                         }}/>
                                     </div>
                                 }

                                 {actions.length > 1 &&
                                     <div className={"add-action-delete"}>
                                         <FaTrash onClick={() => {
                                             handleDeleteAction(actionIndex)
                                         }}/>
                                     </div>
                                 }
                             </div>


                             {
                                 actions[actionIndex].actionType === "SEND_EMAIL" &&
                                 <>
                                     <div className={"email-check-action"}>
                                         <div className="form-check">
                                             <input className="form-check-input"
                                                    checked={actionData?.actionEvents?.find(cur => cur.actionLabel === "Email Me")}
                                                    type="checkbox" value={"Email Me"}
                                                    name="Email Me"
                                                    onChange={(e) => {
                                                        handleActionsChange(actionIndex, e)
                                                    }} id="flexRadioDefault1"/>
                                             <label className="form-check-label"
                                                    htmlFor="flexRadioDefault1">
                                                 Send me an email
                                             </label>
                                         </div>

                                         {showRecommendedCheckBox(selectOperand) === true &&

                                             <div className="form-check">
                                                 <input className="form-check-input"
                                                        checked={actionData?.actionEvents?.find(cur => cur.actionLabel === "Email with Market Recommended")}
                                                        type="checkbox"
                                                        value={"Email with Market Recommended"}
                                                        name="Email with Market Recommended"
                                                        id="flexRadioDefault1"
                                                        onChange={(e) => {
                                                            handleActionsChange(actionIndex, e)
                                                        }}/>
                                                 <label className="form-check-label"
                                                        htmlFor="flexRadioDefault1">
                                                     Send Market place Recommendations with
                                                     email
                                                 </label>
                                             </div>
                                         }
                                         <div className="form-check">
                                             <input className="form-check-input"
                                                    type="checkbox"
                                                    checked={actionData?.actionEvents?.find(cur => cur.actionLabel && cur.actionLabel === "Custom Email")}
                                                    name="Custom Email" onChange={(e) => {
                                                 handleActionsChange(actionIndex, e)
                                             }}/>
                                             <label className="form-check-label"
                                                    htmlFor="flexRadioDefault1">
                                                 Send email to
                                             </label>
                                         </div>
                                         {
                                             actions[actionIndex]?.actionEvents.find(cur => cur.actionLabel === "Custom Email") &&
                                             <>
                                                 <div
                                                     className={"send-custom-email mediumFontSize"}>
                                                     <input name="actionValue" type={"email"}
                                                            value={actionData?.actionEvents?.find(cur => cur.actionLabel === "Custom Email")?.actionValue}
                                                            className={"form-control"}

                                                            onChange={(e) => {

                                                                setActions(prevActions => {
                                                                    const updatedActions = prevActions.map((action, index) => {
                                                                        if (index !== actionIndex) return action; // Leave other actions unchanged

                                                                        // Update actionValue for the given actionLabel
                                                                        const updatedActionEvents = action.actionEvents.map(event =>
                                                                            event.actionLabel === "Custom Email" ? {
                                                                                ...event,
                                                                                actionValue: e.target.value
                                                                            } : event
                                                                        );

                                                                        return {
                                                                            ...action,
                                                                            actionEvents: updatedActionEvents
                                                                        };
                                                                    });

                                                                    return updatedActions;
                                                                });
                                                            }}

                                                            placeholder={"Enter Email"}/>
                                                 </div>
                                             </>

                                         }
                                     </div>
                                 </>
                             }

                             {
                                 actions[actionIndex].actionType === "ASSIGNED_MENTOR" &&
                                 <>
                                     <div className={"assigned-mentor-select"}>
                                         <Select
                                             className="members-filter-role react-select-container mt-2"
                                             classNamePrefix="react-select"
                                             name={"actionValue"}
                                             disabled={users?.getAllUsersWithoutPaginationReducer?.loading}
                                             value={actionData?.actionEvents?.map(cur => ({
                                                 label: cur.actionLabel,
                                                 value: cur.actionValue
                                             }))}
                                             isClear
                                             options={createOptionListForSelectTagWithRole(users?.getAllUsersWithoutPaginationReducer?.data, "firstName", "uid")}
                                             onChange={(mentorIndex) => {
                                                 handleMentorAction(actionIndex, mentorIndex)
                                             }}
                                             placeholder={"Select Manager/Mentor ..."}/>
                                     </div>
                                 </>

                             }

                         </div>
                     </>
                 )
             })
             }
         </>
     )

}
 export default PrepareActions;