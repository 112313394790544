import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {formatMessage, getAxiosConfig, showToast} from "../../helpers/utils";
import {CreatedSuccessfully, UpdatedSuccessfully} from "../../helpers/constants";

export const createStrategy = createAsyncThunk('strategy/createStrategy', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/strategy`, data,getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

export const searchStrategy = createAsyncThunk('strategy/searchStrategy', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/strategy/search`, data,getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

export const updateStrategy = createAsyncThunk('strategy/deleteStrategy', async (data, thunkAPI) => {
    return await axios.put(`${process.env.REACT_APP_BASE_URL}/strategy/${data?.strategyId}`,data,getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

export const deleteStrategy = createAsyncThunk('strategy/deleteStrategy', async (id, thunkAPI) => {
    return await axios.delete(`${process.env.REACT_APP_BASE_URL}/strategy/${id}`,getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

export const assignStrategyToSurvey = createAsyncThunk('strategy/assignStrategyToSurvey', async (data, thunkAPI) => {
    return await axios.put(`${process.env.REACT_APP_BASE_URL}/strategy/assign/${data?.strategyId}?surveyId=${data?.surveyId}`,{},getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

export const unassignStrategy = createAsyncThunk('strategy/unassignStrategy', async (data, thunkAPI) => {
    return await axios.put(`${process.env.REACT_APP_BASE_URL}/strategy/unassign/${data?.strategyId}?surveyId=${data?.surveyId}`,{},getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

export const getAllStrategies = createAsyncThunk('strategy/getAllStrategies', async (data, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/strategy`,getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

export const getAssignedStrategy = createAsyncThunk('strategy/getAssignedStrategy', async (surveyId, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/strategy/assigned-strategy/${surveyId}`,getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

export const getStrategyById = createAsyncThunk('strategy/getStrategyById', async (strategyId, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/strategy/${strategyId}`,getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

const strategySlice = createSlice({
    name: "strategy",
    initialState: {
        createStrategyReducer: {loading: false},
        searchStrategyReducer: {loading: false},
        updateStrategyReducer: {loading: false},
        deleteStrategyReducer: {loading: false},
        assignStrategyToSurveyReducer: {loading: false},
        unassignStrategyReducer: {loading: false},
        getAllStrategiesReducer: {loading: false},
        getAssignedStrategyReducer: {loading: false},
        getStrategyByIdReducer: {loading: false},
    },
    reducers: {
    },
    extraReducers: {
        // Create Strategy
        [createStrategy.pending]: (state) => {
            state.createStrategyReducer = {loading: true};
        },
        [createStrategy.fulfilled]: (state, action) => {
            showToast(formatMessage(CreatedSuccessfully,["Strategy"]),"success")
            state.createStrategyReducer = {loading: false, data: action.payload.data};
        },
        [createStrategy.rejected]: (state, action) => {
            state.createStrategyReducer = {loading: false};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText,"error")
        },

        // Search Strategy
        [searchStrategy.pending]: (state) => {
            state.searchStrategyReducer = {loading: true};
        },
        [searchStrategy.fulfilled]: (state, action) => {
            state.searchStrategyReducer = {loading: false, data: action.payload.data};
        },
        [searchStrategy.rejected]: (state, action) => {
            state.searchStrategyReducer = {loading: false};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText,"error")
        },
        // Delete Strategy
        [deleteStrategy.pending]: (state) => {
            state.deleteStrategyReducer = {loading: true};
        },
        [deleteStrategy.fulfilled]: (state, action) => {
            state.deleteStrategyReducer = {loading: false, data: action.payload.data};
        },
        [deleteStrategy.rejected]: (state, action) => {
            state.deleteStrategyReducer = {loading: false};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText,"error")
        },
        // Update Strategy
        [updateStrategy.pending]: (state) => {
            state.updateStrategyReducer = {loading: true};
        },
        [updateStrategy.fulfilled]: (state, action) => {
            showToast(formatMessage(UpdatedSuccessfully,["Strategy"]),"success")
            state.updateStrategyReducer = {loading: false, data: action.payload.data};
        },
        [updateStrategy.rejected]: (state, action) => {
            state.updateStrategyReducer = {loading: false};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText,"error")
        },

        // Assign Strategy To Survey
        [assignStrategyToSurvey.pending]: (state) => {
            state.assignStrategyToSurveyReducer = {loading: true};
        },
        [assignStrategyToSurvey.fulfilled]: (state, action) => {
            showToast(action.payload.data,"success")
            state.assignStrategyToSurveyReducer = {loading: false, data: action.payload.data};
        },
        [assignStrategyToSurvey.rejected]: (state, action) => {
            state.assignStrategyToSurveyReducer = {loading: false};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText,"error")
        },

        // Unassign Strategy
        [unassignStrategy.pending]: (state) => {
            state.unassignStrategyReducer = {loading: true};
        },
        [unassignStrategy.fulfilled]: (state, action) => {
            showToast(action.payload.data,"success")
            state.unassignStrategyReducer = {loading: false, data: action.payload.data};
        },
        [unassignStrategy.rejected]: (state, action) => {
            state.unassignStrategyReducer = {loading: false};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText,"error")
        },

        // Get All Strategies
        [getAllStrategies.pending]: (state) => {
            state.getAllStrategiesReducer = {loading: true};
        },
        [getAllStrategies.fulfilled]: (state, action) => {
            state.getAllStrategiesReducer = {loading: false, data: action.payload.data};
        },
        [getAllStrategies.rejected]: (state, action) => {
            state.getAllStrategiesReducer = {loading: false};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText,"error")
        },
        // Get Assigned Strategy
        [getAssignedStrategy.pending]: (state) => {
            state.getAssignedStrategyReducer = {loading: true};
        },
        [getAssignedStrategy.fulfilled]: (state, action) => {
            state.getAssignedStrategyReducer = {loading: false, data: action.payload.data || null};
        },
        [getAssignedStrategy.rejected]: (state, action) => {
            state.getAssignedStrategyReducer = {loading: false};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText,"error")
        },

        // Get Strategy By Id
        [getStrategyById.pending]: (state) => {
            state.getStrategyByIdReducer = {loading: true};
        },
        [getStrategyById.fulfilled]: (state, action) => {
            state.getStrategyByIdReducer = {loading: false, data: action.payload.data};
        },
        [getStrategyById.rejected]: (state, action) => {
            state.getStrategyByIdReducer = {loading: false};
            showToast(action?.payload?.detailDesc || action?.payload?.statusText,"error")
        },



    }
})

export const {} = strategySlice.actions

export default strategySlice.reducer;
