import {FaPlusCircle, FaTrash} from "react-icons/fa";
import {AttemptSurveyOperation, ReflectionStatus, Roles, RolesData, UserFields} from "../../../helpers/accessControl";
import Select from "react-select";
import {createOptionListForSelectTag, getFilterOperators, getInputType} from "../../../helpers/utils";
import {Tooltip as ReactTooltip} from "react-tooltip";

const PrepareCriteria = ({selectOperand,setSelectOperand,userCriteria,setSelectedValue})=>{

    const handleDeleteOperand = (index) => {
        const updatedSelectOperand = selectOperand?.filter((cur, ind) => {
            return ind !== index
        })
        setSelectOperand(updatedSelectOperand)
    };


    const handleAddNewOperand = () => {
        setSelectOperand([...selectOperand, {
            selectOperand: "",
            criteria: userCriteria
        }])
    };


    function deleteAction(actionIndex, parentIndex) {

        let latestList = selectOperand[parentIndex]?.criteria.filter((el, i) => {
            return i !== actionIndex;
        })
        setSelectOperand(prevUserCriteria => {
            const updatedSelectOperand = [...prevUserCriteria];
            updatedSelectOperand[parentIndex] = {
                ...updatedSelectOperand[parentIndex],
                criteria: latestList
            };
            return updatedSelectOperand;
        });

    }

    const handleAddOperation = (parentIndex) => {

        let data = [...selectOperand]
        data[parentIndex] = {
            ...data[parentIndex], criteria: [...data[parentIndex]?.criteria, {
                operandField: "",
                conditionType: "",
                operandValue: []
            }]
        }

        setSelectOperand([...data])
    };
    const handleChangeAction = (childIndex, parentIndex, e) => {
        const { name, value } = e.target;
        const updatedUserCriteria = selectOperand[parentIndex]?.criteria.map((criteria, index) => {

            if (index === childIndex) {
                return {
                    ...criteria,
                    [name]: name === 'operandValue' ? (value ? [value] : []) : value,
                    conditionType: (name === 'operandField') ? "" : name==='conditionType' ?  value :  criteria.conditionType,
                    operandValue: (name === 'operandField') ? [] : name==='operandValue' ?  [value] :  criteria.operandValue
                };
            }
            return criteria;
        });
        setSelectOperand(prevUserCriteria => {
            const updatedSelectOperand = [...prevUserCriteria];
            updatedSelectOperand[parentIndex] = {
                ...updatedSelectOperand[parentIndex],
                criteria: updatedUserCriteria
            };
            return updatedSelectOperand;
        });
    };

    const handleRolesAction = (actionIndex, parentIndex, data) => {
        if (data !== undefined) {

            const updatedUserCriteria = selectOperand[parentIndex]?.criteria.map((criteria, index) => {
                if (index === actionIndex) {
                    return {
                        ...criteria,
                        operandValue: data.map(cur => cur.value)
                    };
                }
                return criteria;
            });

            setSelectOperand(prevUserCriteria => {
                const updatedSelectOperand = [...prevUserCriteria];
                updatedSelectOperand[parentIndex] = {
                    ...updatedSelectOperand[parentIndex],
                    criteria: updatedUserCriteria
                };
                return updatedSelectOperand;
            });
        }
    };


    return(
            <div className={"col-xl-7 p-0 custom-border"}>
                <div className={"show-border-down-side"}/>
                { selectOperand?.length > 0 && selectOperand?.map((dd, ind) => {
                    return (
                        <>
                            <div className={"operand-card"}>
                                <div className={"selection-data mediumFontSize"}>
                                    <div className={"operand-data"}>
                                        <label className={"mb-2 ms-2"}>Select Interest</label>
                                        <select value={selectOperand[ind].selectOperand}
                                                className={"form-select w-100"} onChange={(e) => {
                                            const updatedSelectOperand = selectOperand.map((item, index) => {
                                                if (index === ind) {
                                                    return {
                                                        ...item,
                                                        selectOperand: e.target.value,
                                                        criteria: userCriteria
                                                    };
                                                }
                                                return item;
                                            });
                                            setSelectOperand(updatedSelectOperand);

                                        }}>
                                            <option value={""} selected>--Select Interest--
                                            </option>
                                            <option value={"User"}>Users</option>
                                            <option value={"Attempt Reflection"}>Attempt
                                                Reflection
                                            </option>
                                        </select>

                                        {
                                            selectOperand.length > 1 &&
                                            <div className={"add-operand-delete"}
                                                 onClick={() => {
                                                     handleDeleteOperand(ind)
                                                 }
                                                 }>
                                                <FaTrash/>
                                            </div>
                                        }


                                    </div>

                                    {selectOperand[ind].selectOperand === "User" &&
                                        <>
                                            {selectOperand[ind].criteria?.map((data, index) => {
                                                return (
                                                    <>
                                                        <div key={index}
                                                             className={" criteria-field  mt-2"}>

                                                            <div className={"custom-margin"}>
                                                                <select
                                                                    value={data?.operandField}
                                                                    className={"form-select mt-2"}
                                                                    name={"operandField"}
                                                                    onChange={(e) => {
                                                                        handleChangeAction(index, ind, e)
                                                                    }}>
                                                                    <option value={""}
                                                                            selected>Select
                                                                        Filter
                                                                    </option>
                                                                    {UserFields?.length > 0 && UserFields.map((cur) => {
                                                                        return <>
                                                                            <option
                                                                                value={cur.value}>{cur.name}</option>
                                                                        </>
                                                                    })
                                                                    }
                                                                </select>
                                                            </div>

                                                            {
                                                                (data?.operandField !== null || data?.operandField !== "") && data?.operandField === "roles" &&
                                                                <>
                                                                    <div
                                                                        className={"custom-margin"}>

                                                                        <Select
                                                                            className="members-filter-role react-select-container mt-2"
                                                                            classNamePrefix="react-select"
                                                                            name={"operandValue"}
                                                                            value={data?.operandValue?.map(value => ({
                                                                                label: value,
                                                                                value
                                                                            }))}
                                                                            isMulti
                                                                            options={createOptionListForSelectTag(RolesData?.filter(role=>role?.name!==Roles.ANONYMOUS_USER), "name", "value")}
                                                                            onChange={(data) => {
                                                                                handleRolesAction(index, ind, data)
                                                                            }}
                                                                            placeholder={"Select Role ..."}/>
                                                                    </div>

                                                                </>


                                                            }

                                                            {data?.operandField !== "" && (data?.operandField !== "roles" && data?.operandField !== "tags") &&
                                                                <>
                                                                    <div
                                                                        className={"custom-margin"}>

                                                                        <select
                                                                            value={data?.conditionType}
                                                                            className={"form-select mt-2"}
                                                                            name={"conditionType"}
                                                                            onChange={(e) => {
                                                                                handleChangeAction(index, ind, e)
                                                                            }}>
                                                                            <option value={""}
                                                                                    selected>Select
                                                                                Condition
                                                                            </option>
                                                                            {getFilterOperators(data?.operandField)?.map((cur) => {
                                                                                return <>
                                                                                    <option
                                                                                        value={cur.value}>{cur.name}</option>
                                                                                </>
                                                                            })
                                                                            }
                                                                        </select>
                                                                    </div>

                                                                </>

                                                            }
                                                            {
                                                                data?.operandField !== "" && (data?.operandField !== "roles" && data?.operandField !== "tags") &&
                                                                <>
                                                                    <div
                                                                        className={"custom-margin"}>

                                                                        <div
                                                                            className={"type-data"}>

                                                                            <input
                                                                                type={getInputType(data?.operandField)}
                                                                                onChange={(e) => {
                                                                                    handleChangeAction(index, ind, e)
                                                                                }}
                                                                                placeholder={"Enter value"}
                                                                                value={data?.operandValue}
                                                                                name={"operandValue"}
                                                                                className={"form-control "}/>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {
                                                                selectOperand[ind].criteria?.length - 1 === index &&
                                                                <div className={"add-data"}
                                                                     data-tooltip-id="DeleteReflectionBTN"
                                                                     data-tooltip-content={"Add New Filter"}>
                                                                    <FaPlusCircle
                                                                        onClick={() => {
                                                                            setSelectedValue(new Date().getTime())
                                                                            handleAddOperation(ind)
                                                                        }}/>

                                                                    <ReactTooltip
                                                                        className={"ToolTip"}
                                                                        place={"bottom"}
                                                                        id="DeleteReflectionBTN"/>
                                                                </div>
                                                            }


                                                            {selectOperand[ind].criteria?.length > 1 &&
                                                                <div className={"filter-data"}
                                                                     onClick={() => {
                                                                         setSelectedValue(new Date().getTime())
                                                                         deleteAction(index, ind)
                                                                     }}>
                                                                    <FaTrash/>
                                                                </div>
                                                            }
                                                        </div>
                                                    </>
                                                )

                                            })}


                                        </>

                                    }


                                    {selectOperand[ind].selectOperand === "Attempt Reflection" &&
                                        <>

                                            {selectOperand[ind].criteria?.map((attemptRef, refIndex) => {
                                                return (
                                                    <>
                                                        <div key={refIndex}
                                                             className={" criteria-field  mt-2"}>

                                                            <div
                                                                className={"custom-margin mt-2"}>

                                                                <select
                                                                    value={attemptRef?.operandField}
                                                                    className={"form-select mb-2"}
                                                                    name={"operandField"}
                                                                    onChange={(e) => {
                                                                        handleChangeAction(refIndex, ind, e)
                                                                    }}>
                                                                    <option value={""}
                                                                            selected>Select
                                                                        operation
                                                                    </option>
                                                                    {AttemptSurveyOperation?.length > 0 && AttemptSurveyOperation.map((cur) => {
                                                                        return <>
                                                                            <option
                                                                                value={cur.value}>{cur.name}</option>
                                                                        </>
                                                                    })

                                                                    }
                                                                </select>
                                                            </div>


                                                            {
                                                                (attemptRef?.operandField !== null || attemptRef?.operandField !== "") && attemptRef?.operandField !== "status" &&
                                                                <>
                                                                    <div
                                                                        className={"custom-margin mt-2"}>
                                                                        <select
                                                                            value={attemptRef?.conditionType}
                                                                            className={"form-select mb-2"}
                                                                            name={"conditionType"}
                                                                            onChange={(e) => {
                                                                                handleChangeAction(refIndex, ind, e)
                                                                            }}>
                                                                            <option value={""}
                                                                                    selected>Select
                                                                                Condition
                                                                            </option>
                                                                            {getFilterOperators(attemptRef?.operandField)?.map((cur) => {
                                                                                return <>
                                                                                    <option
                                                                                        value={cur.value}>{cur.name}</option>
                                                                                </>
                                                                            })
                                                                            }
                                                                        </select>
                                                                    </div>

                                                                </>


                                                            }

                                                            {
                                                                (attemptRef?.operandField !== null || attemptRef?.operandField !== "") && attemptRef?.operandField === "status" &&
                                                                <>
                                                                    <div
                                                                        className={"custom-margin"}>

                                                                        <Select
                                                                            className="members-filter-role react-select-container mt-2"
                                                                            classNamePrefix="react-select"
                                                                            name={"operandValue"}
                                                                            value={
                                                                                attemptRef?.operandValue?.map(value => {
                                                                                    let label;
                                                                                    switch (value) {
                                                                                        case 'HIGH_RISK':
                                                                                            label = 'Stagnating';
                                                                                            break;
                                                                                        case 'LOW_RISK':
                                                                                            label = 'Growing';
                                                                                            break;
                                                                                        case 'GOOD':
                                                                                            label = 'Maturing';
                                                                                            break;
                                                                                        default:
                                                                                            label = value;
                                                                                    }
                                                                                    return {
                                                                                        label,
                                                                                        value
                                                                                    };
                                                                                })
                                                                            }
                                                                            isMulti
                                                                            options={createOptionListForSelectTag(ReflectionStatus, "name", "value")}
                                                                            onChange={(e) => {
                                                                                handleRolesAction(refIndex, ind, e)
                                                                            }}
                                                                            placeholder={"Status ..."}/>
                                                                    </div>

                                                                </>


                                                            }

                                                            {
                                                                (attemptRef?.operandField !== null || attemptRef?.operandField !== "") && attemptRef?.operandField !== "status" &&
                                                                <>
                                                                    <div
                                                                        className={"custom-margin"}>
                                                                        <div
                                                                            className={"type-data"}>
                                                                            <input
                                                                                type={getInputType(attemptRef?.operandField)}
                                                                                onChange={(e) => {
                                                                                    handleChangeAction(refIndex, ind, e)
                                                                                }}
                                                                                placeholder={"Enter value"}
                                                                                value={attemptRef?.operandValue}
                                                                                name={"operandValue"}
                                                                                className={"form-control"}/>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }


                                                            {
                                                                selectOperand[ind].criteria?.length - 1 === refIndex &&
                                                                <div className={"add-data"}
                                                                     data-tooltip-id="DeleteReflectionBTN"
                                                                     data-tooltip-content={"Add New Filter"}>
                                                                    <FaPlusCircle
                                                                        onClick={() => {
                                                                            setSelectedValue(new Date().getTime())
                                                                            handleAddOperation(ind)
                                                                        }}/>

                                                                    <ReactTooltip
                                                                        className={"ToolTip"}
                                                                        place={"bottom"}
                                                                        id="DeleteReflectionBTN"/>
                                                                </div>
                                                            }


                                                            {selectOperand[ind].criteria?.length > 1 &&
                                                                <div className={"filter-data"}
                                                                     onClick={() => {
                                                                         setSelectedValue(new Date().getTime())
                                                                         deleteAction(refIndex, ind)
                                                                     }}>
                                                                    <FaTrash/>
                                                                </div>
                                                            }
                                                        </div>
                                                    </>
                                                )

                                            })}

                                        </>
                                    }
                                </div>

                            </div>
                            {selectOperand.length - 1 === ind &&
                                <>
                                    <div className={"add-new-operand"}>
                                        <button
                                            className={"cmn_background_color mediumFontSize"}
                                            style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined || localStorage.getItem("color") !== "undefined" ? localStorage.getItem("color") : ""}}
                                            onClick={handleAddNewOperand}>Add Condition
                                        </button>
                                    </div>
                                </>
                            }
                        </>
                    )})}
            </div>

    )


}
export default PrepareCriteria;