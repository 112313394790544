import user_icon from '../../images/default.png'
import maximise_icon from '../../images/maximize-4.png'
import './SavedReflection.css'

const SavedReflection = () => {
    return (
        <>
            <section>
                <div className='row main_content'>
                    <div className='col-lg-12 p-0'>
                        <div className='savedreflection_outer'>
                            <div className='userimage'>

                                <img src={user_icon}/>

                                <div className='user_detail'>
                                    <h2 className='mediumFontSize'>Kelly Casper</h2>
                                    <p className='regularFontSize cmn_small_fontstyle'>Kellycasper@hotmail.com</p>
                                </div>
                            </div>
                            <div>

                                <button className='cmn_background_color fill_survey_btn mediumFontSize'>Fill Reflection
                                </button>
                            </div>
                        </div>
                        <div className='individual_saved_reflection'>
                            <div className='reflection_container'>
                                <h2 className='saved_reflection_heading mediumFontSize'>Saved Reflections</h2>
                                <img src={maximise_icon} className='maximise_arrow_img'/>

                            </div>
                            <div className='user_paragraph'>
                                <p className='cmn_fontStyle regularFontSize'>
                                    During this challeges i was impressed by my mentee's determation to continue working
                                    towards their goals.We workes toether to
                                    find ways to accomodate their health.
                                    During this challeges i was impressed by my mentee's determation to continue working
                                    towards their goals.We workes toether to
                                    find ways to accomodate their health.
                                </p>
                                <h5 className='cmn_fontStyle regularFontSize'>10.08.23</h5>
                            </div>
                            <div className='user_paragraph'>
                                <p className='cmn_fontStyle regularFontSize'>
                                    During this challeges i was impressed by my mentee's determation to continue working
                                    towards their goals.We workes toether to
                                    find ways to accomodate their health.
                                    During this challeges i was impressed by my mentee's determation to continue working
                                    towards their goals.We workes toether to
                                    find ways to accomodate their health.
                                </p>
                                <h5 className='cmn_fontStyle regularFontSize'>10.08.23</h5>
                            </div>
                            <div className='user_paragraph'>
                                <p className='cmn_fontStyle regularFontSize'>
                                    During this challeges i was impressed by my mentee's determation to continue working
                                    towards their goals.We workes toether to
                                    find ways to accomodate their health.
                                    During this challeges i was impressed by my mentee's determation to continue working
                                    towards their goals.
                                </p>
                                <h5 className='cmn_fontStyle regularFontSize'>10.08.23</h5>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}
export default SavedReflection;