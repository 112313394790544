import { useState} from 'react'
import '../style.css'
import logo from '../../../images/PulseIcon.svg'
import Form from 'react-bootstrap/Form'
import {Link, NavLink} from 'react-router-dom'
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {loginUser} from "../../../reduxStore/usersSlices/userSlice";
import {getLoggedInUserData} from "../../../reduxStore/usersSlices/userSlice";
import {useNavigate} from 'react-router-dom';
import {encryptData, isNullOrEmpty, isUnauthorized, showToast} from "../../../helpers/utils";
import {TailSpinLoader} from "../../layout/Loader";
import {
    EmailAndPasswordRequired,
    EmailRequired,
    PasswordRequired,
    SubscriptionPlans
} from "../../../helpers/constants";
import jwtDecode from "jwt-decode";
import {Roles} from "../../../helpers/accessControl";

const Login = () => {
    const navigate = useNavigate();
    const users = useSelector(state => state.users);
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false);


    // email validation
    const dispatch = useDispatch();



    // Check Whether Username Or Password is Empty or Not
    function submitHandle(e) {
        e.preventDefault();
        const data = {
            email: email,
            password: password,
            zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        let message = "";
        // Form Validation
        if (isNullOrEmpty(email) && isNullOrEmpty(password)) {
            message = EmailAndPasswordRequired;
        } else if (isNullOrEmpty(email)) {
            message = EmailRequired;
        } else if (isNullOrEmpty(password)) {
            message = PasswordRequired;
        }
        if (message) {
            showToast(message,"error")
        } else {
            // Logging user and getting userinfo and then redirecting it to admin page
            dispatch(loginUser(data))
                .then((res) => {
                    if (res.meta.requestStatus === "fulfilled") {
                        localStorage.setItem('token', encryptData(res.payload.data));
                        dispatch(getLoggedInUserData(data.email))
                            .then(response => {
                                if (isUnauthorized(response)) {
                                    navigate("/")
                                }
                                if (response.meta.requestStatus === "fulfilled") {
                                    if(response?.payload?.data?.orgPreferences?.length > 0){
                                        localStorage.setItem("logo",response?.payload?.data?.orgPreferences[0].logo)
                                        localStorage.setItem("color",response?.payload?.data?.orgPreferences[0].colorName)
                                    }
                                    toast.dismiss();
                                    if (jwtDecode(res.payload.data).subscriptionStatus === SubscriptionPlans.SUBSCRIBED) {
                                        navigate(response?.payload?.data?.roles[0]?.name===Roles?.MENTEE?"/mentee/reflections":"/"+response?.payload?.data?.orgName+"/admin")
                                    } else {
                                        navigate("/plans")
                                    }
                                }
                            })
                    }
                })
        }
    }
    return (
        <>
            <div className='container_box'>
                <div className="cmn_outer m-auto">
                    <div className="text-center">

                        <img src={logo} width="100px" height="24px" className='' alt={""}/>

                    </div> <h2 className="cmn_heading mediumFontSize">Log in</h2>
                    <Form method={"Post"} onSubmit={submitHandle}>
                        <div className='cmn_form_outer pt-1'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className='regularFontSize'>Email* </Form.Label>
                                <Form.Control required={true} value={email} onChange={(e) => {
                                    setEmail(e.target.value)
                                }} type="email" placeholder="name@example.com"/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label className='regularFontSize'>Password*</Form.Label>
                                <div className='password_container'>
                                    <Form.Control required={true} value={password} onChange={(e) => {
                                        setPassword(e.target.value)
                                    }} type={showPassword ? "text" : "password"} placeholder="*************"/>
                                    <div className='password_icon_outer' onClick={() => {
                                        isNullOrEmpty(password) ? setShowPassword(showPassword) : setShowPassword(!showPassword)
                                    }}>
                                        {showPassword ? <i className={"fa fa-eye mt-1"}></i>
                                            :
                                            <i className={"fa fa-eye-slash mt-1"}></i>
                                        }
                                    </div>
                                </div>
                                <div className="text-end">
                                    <NavLink to="/forgetPassword" className='forgetpass  mediumFontSize d-inline-block'>Forgot
                                        password</NavLink>
                                </div>
                            </Form.Group>


                        </div>
                        <div className='btn_outer'>
                            {/*-----------Disable Login Button When Login Api is Called-----------------------------------------*/}
                            <button type={"submit"} className="cmn_btn cmn_background_color mediumFontSize"
                                    disabled={(users?.loginUserReducer?.loading || users?.getLoggedInUserDataReducer?.loading)}>Log in

                                {/*--------------------Showing Loader When Api is Called---------------------------------------*/}
                                {(users?.loginUserReducer?.loading || users?.getLoggedInUserDataReducer?.loading) && (
                                    <TailSpinLoader/>)}
                            </button>
                            {/*<Link to="/signup">*/}
                            {/*    <button className="cmn_form_btn mediumFontSize">Sign up</button>*/}
                            {/*</Link>*/}

                        </div>
                    </Form>
                </div>
            </div>

        </>
    )
}
export default Login
