import Modal from "react-bootstrap/Modal";

import {IoIosClose} from "react-icons/io";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createOptionListForSelectTag, handleApiResponse, isUnauthorized} from "../../helpers/utils";
import {assignStrategyToSurvey, getAllStrategies} from "../../reduxStore/strategySlice/strategySlice";
import {useNavigate} from "react-router-dom";
import {TailSpinLoader} from "../layout/Loader";
import Select from "react-select";

const AssignStrategyModal = ({show, setShow, surveyId,setTriggerGetAssignedStrategyApi}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedOption, setSelectedOption] = useState(null)

    const strategiesApi = useSelector(state => state.strategy.getAllStrategiesReducer)
    const assignStrategyApi = useSelector(state => state.strategy.assignStrategyToSurveyReducer)

    console.log("assignStrategyApi====>",assignStrategyApi)

    const handleClose = () => setShow(false);
    const handleAssignStrategy = () => {
        if (selectedOption) {
            dispatch(assignStrategyToSurvey({strategyId:selectedOption.value,surveyId:surveyId})).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                handleApiResponse(res,()=>{
                    setShow(false);
                    setTriggerGetAssignedStrategyApi(true)
                })
            })

        }
    }
    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption)
    }

    useEffect(() => {
        dispatch(getAllStrategies()).then(res => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
        })
    }, []);

    return (
        <>
            <Modal className={"modal-lg"} show={show} centered>
                <Modal.Header className={"d-flex"}>
                    <Modal.Title className='mediumFontSize'>Assign Strategy</Modal.Title>
                    <IoIosClose className={"close_btn"} onClick={handleClose} size={24}/>
                </Modal.Header>

                <Modal.Body className="modal_outer">
                    <label className={"select-strategy-label"}>Select Strategy</label>
                    <Select
                        value={selectedOption}
                        isClearable={true}
                        className="members-filter-role react-select-container"
                        classNamePrefix="react-select"
                        isDisabled={strategiesApi?.loading}
                        options={createOptionListForSelectTag(strategiesApi?.data, "name", "strategyId")}
                        onChange={handleSelectChange}
                        placeholder={"Select Strategy ..."}/>
                </Modal.Body>
                <Modal.Footer className="modal_footer">
                    <button
                        disabled={selectedOption===null || assignStrategyApi?.loading}
                        className="modal_cancel_btn cmn_modal_btn mediumFontSize"
                        onClick={handleClose}
                    >Cancel
                    </button>
                    <button
                        disabled={selectedOption===null || assignStrategyApi?.loading}
                        onClick={handleAssignStrategy}
                        style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                        className={`modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize ${(selectedOption===null || assignStrategyApi?.loading ) ?" disable-btn":""} `}
                    >Assign
                        {
                            assignStrategyApi?.loading  && <TailSpinLoader/>
                        }
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default AssignStrategyModal;