import Modal from 'react-bootstrap/Modal';
import close_square from "../../images/croosIcon.png";
import React, { useState} from "react";
import Plans from "../plans/Plans";
import jwtDecode from "jwt-decode";
import {getDataFromLocalStorage} from "../../helpers/utils";
import {PulsePlans} from "../../helpers/constants";

const UpgradePlanModal = ({show, setShow}) => {
    const handleClose = () => setShow(false);
    const [userCurrentPlan,setUserCurrentPlan]=useState(jwtDecode(getDataFromLocalStorage("token")).planName)
    return (
        <>
            <Modal className={"upgrade-plan-modal mt-4 "+(userCurrentPlan===PulsePlans.INDIVIDUAL.value?"modal-xl":"modal-xl-2")} show={show} centered>
                <Modal.Header>
                    <Modal.Title
                        className='add_member_heading mediumFontSize'>Upgrade Plan</Modal.Title>
                    <div className="closeImg" onClick={handleClose}>
                        <img src={close_square} alt="" width={"15"}/>
                    </div>


                </Modal.Header>
                <Modal.Body className={"upgrade-modal-body"}>
                    <Plans upgradePlan={true} userCurrentPlan={userCurrentPlan}></Plans>
                </Modal.Body>

                {/*<Modal.Footer className="modal_footer">*/}
                {/*    <div className={"add-member-modal-btns"}>*/}
                {/*        <button className="modal_cancel_btn cmn_modal_btn mediumFontSize" onClick={handleClose}>Close*/}
                {/*        </button>*/}

                {/*    </div>*/}

                {/*</Modal.Footer>*/}
            </Modal>
        </>
    )
        ;
}

export default UpgradePlanModal;