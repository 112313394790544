
export const Loader = () => {
    return (
        <>

            <div className="my__loader">
                <div className="d-flex flex-column justify-content-center align-items-center container w-100 h-100">
                    <div className='loader'>
                    </div>
                    {/*<div className='text-white'>Loading...</div>*/}
                </div>
            </div>
        </>
    )
}

export const TailSpinLoader=()=>{
    return(
    <>
    <span className="spinner-border spinner-border-sm ms-1" role="status"
          aria-hidden="true"></span>
    </>
    );
}