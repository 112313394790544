import {useState} from 'react'
import '../style.css'
import leftAroow from '../../../images/arrow-left.png'
import logo from '../../../images/Union.png'
import Form from 'react-bootstrap/Form'
import {useNavigate, useParams} from "react-router-dom";
import {isNullOrEmpty, showToast} from "../../../helpers/utils";
import password_icon from "../../../images/eyes.png";
import {createPassword} from "../../../reduxStore/usersSlices/userSlice";
import {useDispatch, useSelector} from "react-redux";
import {TailSpinLoader} from "../../layout/Loader";
import {ConfirmPassword, PasswordMisMatched, PasswordRequired} from "../../../helpers/constants";

const CreatePassword = () => {

    const {encodedString} = useParams();
    const user = useSelector(state => state.users)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userId, setUserId] = useState(atob(encodedString).split('&')[0].split('=')[1].trim())

    const handleSubmit = (e) => {
        e.preventDefault();

        let message;
        // Validate Form
        if (isNullOrEmpty(password)) {
            message = PasswordRequired
        } else if (isNullOrEmpty(confirmPassword)) {
            message = ConfirmPassword
        } else if (password !== confirmPassword) {
            message = PasswordMisMatched
        }
        if (message) {
            showToast(message,"error")

        } else if (password.length > 7) {
            dispatch(createPassword({
                userId: userId,
                password: password
            }))
                .then(res => {
                    if (res.meta.requestStatus === "fulfilled") {
                        navigate("/")
                    }
                })
        }


    }

    const [password, setPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState({})


    return (

        <>
            <div className='container_box'>
                <div className="cmn_outer">
                    <Form onSubmit={handleSubmit}>
                        <div className="">
                            <img src={leftAroow}  onClick={() => {
                                navigate("/")
                            }} height="25" width="25" alt={""}/>
                            <img src={logo} width="100" height="24" className='ms-2 pulse_img' alt={""}/>
                            <h2 className="cmn_heading mediumFontSize">Create password</h2>
                        </div>
                        <div className="cmn_form_outer pt-1">

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className='regularFontSize'>New Password*</Form.Label>
                                <div className='password_container'>
                                    <Form.Control required={true} type={showPassword ? "text" : "password"}
                                                  value={password}
                                                  name={"password"}
                                                  placeholder='**********' onChange={(e) => {
                                        if (e.target.value.length <= 7 && e.target.value.length > 0) {
                                            setError({password: "Weak Password, must contain at least 8 Characters"})
                                        } else {
                                            setError({password: ""})
                                        }
                                        setPassword(e.target.value)
                                    }}/>
                                    {/*-----------------------Icon to Show Password------------------------ */}
                                    <div className='password_icon_outer' onClick={() => {
                                        isNullOrEmpty(password) ? setShowPassword(showPassword) : setShowPassword(!showPassword)
                                    }}>
                                        {showPassword ? <img src={password_icon} alt={""}/>
                                            :
                                            <i className={"fa fa-eye-slash mt-1"}></i>
                                        }
                                    </div>
                                </div>
                                <p style={{color: "red", fontSize: "13px"}}>{error.password}</p>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className='regularFontSize'>Confirm Password*</Form.Label>
                                <div className='password_container'>
                                    <Form.Control required={true} type={showConfirmPassword ? "text" : "password"}
                                                  placeholder='**********' name={"confirmPassword"}
                                                  value={confirmPassword} onChange={(e) => {
                                        setConfirmPassword(e.target.value)
                                    }}/>
                                    {/*-----------------------Icon to Show Confirm Password------------------------ */}
                                    <div className='password_icon_outer' onClick={() => {
                                        isNullOrEmpty(confirmPassword) ? setShowConfirmPassword(showConfirmPassword) : setShowConfirmPassword(!showConfirmPassword)
                                    }}>
                                        {showConfirmPassword ? <img src={password_icon} alt={""}/>
                                            :
                                            <i className={"fa fa-eye-slash mt-1"}></i>
                                        }
                                    </div>
                                </div>
                                <p style={{color: "red", fontSize: "13px"}}>{error.confirmPassword}</p>
                            </Form.Group>


                        </div>
                        <div className='btn_outer'>
                            <button type="submit" className=" cmn_btn cmn_background_color mediumFontSize">Submit
                                {/*Showing Loading When Sign Up Button Is Clicked*/}
                                {user?.createPasswordReducer?.loading && (
                                    <TailSpinLoader/>)}
                            </button>
                            <button className=" cmn_form_btn  mediumFontSize" onClick={() => {
                                navigate("/")
                            }}>Cancel
                            </button>

                        </div>
                    </Form>
                </div>
            </div>
        </>
    )
}
export default CreatePassword