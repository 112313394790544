import filter_icon from "../../images/filter_icon.svg";
import DataTable from "react-data-table-component";
import {
    applyValidDateRange,
    createOptionListForSelectTag, createOptionListForSelectTagWithMultipleLabels,
    formatAndComputeAutoDateRange, formatDate,
    getDataFromLocalStorage,
    isUnauthorized,
    tableCustomStyling,
    TableSkeletonLoading, trim
} from "../../helpers/utils";
import Pagination from "../commons/Pagination";
import {SearchFilterModal} from "../commons/SearchFilterModal";
import React, {useEffect, useState} from "react";
import jwtDecode from "jwt-decode";
import {useDispatch, useSelector} from "react-redux";
import {NavLink, useNavigate} from "react-router-dom";
import {hasPermission, Permissions, roleHasAccess, Roles} from "../../helpers/accessControl";
import {getAllAssignedReflections, getAllSurveyAttempts} from "../../reduxStore/surveySlices/surveySlices";
import {Marks} from "../../helpers/constants";
import Select from "react-select";
import DatePicker from "react-datepicker";
import {getAllTeamMembers} from "../../reduxStore/teamSlices/teamSlice";

const TeamAttempts = ({teamId}) => {

    const token = jwtDecode(getDataFromLocalStorage("token"))

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [searchQuery, setSearchQuery] = useState({
        orgId: token?.orgId,
        teamId: teamId,
        surveyorId: null,
        surveyeeId: null,
        createdDateRange: null,
        survey: null,
        attemptResult: null,
        surveyAttemptStatus: ["DRAFT", "COMPLETED"],
    })
    const [triggerApi, setTriggerApi] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [sortCriteria, setSortCriteria] = useState({
        sortBy: "createdAt",
        sortOrder: "desc"
    });
    const [showSearchFilterModal, setShowSearchFilterModal] = useState(false)

    const getAllTeamMembersData = useSelector(state => state.team.getAllTeamMembersReducer)
    const getAllSurveyAttemptsData = useSelector(state => state.survey.getAllSurveyAttemptsReducer);
    const getAllAssignedReflectionsData = useSelector(state => state.survey.getAllAssignedReflectionsReducer)

    const columns = [
        {
            name: <div className={"d-flex"}><span className={"ms-2 bulk_delete_Main_header"}> Member name </span></div>,
            cell: (row) =>
                (
                    <div className={"d-flex"}>
                        <div className={"display_profile_data wrap-text ms-2"}>
                            <NavLink
                                title={ (row?.surveyeeInfo === null || row?.surveyeeInfo === undefined) ? "N/A" : row?.surveyeeInfo?.firstName + " " + row?.surveyeeInfo?.lastName}
                                to={row?.surveyAttemptStatus === "DRAFT" ? `/teams/${teamId}/answerReflection/${row?.surveyeeInfo?.id}/${row.surveyId}` : `/teams/${teamId}/reflectionResult/${row.surveyId}/${row.id}`}>
                                {trim( (row?.surveyeeInfo === null || row?.surveyeeInfo === undefined) ? "N/A" : row?.surveyeeInfo?.firstName + " " + row?.surveyeeInfo?.lastName, 40)}
                            </NavLink>

                        </div>
                    </div>
                ),
            style: {
                fontFamily: 'General Sans Medium',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                color: "#171919",
            },
            width: getAllSurveyAttemptsData?.loading ? "" : "25%"
        },
        {
            name: "Surveyor",
            selector: row => (row?.surveyorInfo === null || row?.surveyorInfo === undefined) ? "N/A" : row?.surveyorInfo?.firstName + " " + row?.surveyorInfo?.lastName,
        },
        {
            name: "Reflection",
            sortable: true,
            sortField: 'surveyName',
            selector: row =>row.surveyName,
        },
        {
            name: "Attempt Date",
            sortField: 'createdAt',
            sortable: true,
            cell: row =>
                <div>{(row.createdAt === "" || row.createdAt === undefined) ? "----" : (formatDate(row.createdAt))}</div>,
        },
        {
            name: "Result",
            sortField: 'surveyAttemptResult.overallRiskLevel',
            sortable: true,
            selector: row => Marks[row?.surveyAttemptResult?.overallRiskLevel],
            cell: row => (
                row?.surveyAttemptResult?.overallRiskLevel === undefined ?
                    <span className={"text-center"}>{"N/A"}</span>
                    : <>
                        {
                            row?.surveyAttemptResult?.overallRiskLevel === "HIGH_RISK" &&
                            <div className={"show_status"}>
                            <span
                                className={"survey-risk-status bg-light-red"}>{Marks[row?.surveyAttemptResult?.overallRiskLevel]}</span>
                            </div>
                        }
                        {
                            row?.surveyAttemptResult?.overallRiskLevel === "LOW_RISK" &&
                            <div className={"show_status"}>
                            <span
                                className={"survey-risk-status bg-light-orange"}>{Marks[row?.surveyAttemptResult?.overallRiskLevel]}</span>
                            </div>
                        }
                        {
                            row?.surveyAttemptResult?.overallRiskLevel === "GOOD" &&
                            <div className={"show_status"}>
                            <span
                                className={"survey-risk-status bg-light-green"}>{Marks[row?.surveyAttemptResult?.overallRiskLevel]}</span>
                            </div>
                        }
                    </>
            )
        },
    ]
    let searchFiltersTags = [
        {
            shouldShow: hasPermission([Permissions.USER_READ], true),
            tagView: <Select
                className="members-filter-role react-select-container"
                classNamePrefix="react-select"
                isDisabled={getAllTeamMembersData?.loading}
                isMulti
                isClearable={true}
                value={searchQuery.surveyorId}
                options={createOptionListForSelectTagWithMultipleLabels(getAllTeamMembersData?.data?.filter(user => user?.roleObjects?.[0]?.name !== Roles.MENTEE), ["firstName", "lastName"], "uid")}
                onChange={(selectedValue) => {
                    setSearchQuery({
                        ...searchQuery,
                        surveyorId: selectedValue?.length === 0 ? null : selectedValue
                    })
                }}
                placeholder={"Surveyor..."}/>

        },
        {
            shouldShow: hasPermission([Permissions.USER_READ], true),
            tagView: <Select
                className="members-filter-role react-select-container"
                classNamePrefix="react-select"
                isDisabled={getAllTeamMembersData?.loading}
                isMulti
                isClearable={true}
                value={searchQuery?.surveyeeId}
                options={createOptionListForSelectTagWithMultipleLabels(getAllTeamMembersData?.data, ["firstName", "lastName"], "uid")}
                onChange={(selectedValue) => {
                    setSearchQuery({
                        ...searchQuery,
                        surveyeeId: selectedValue?.length === 0 ? null : selectedValue
                    })
                }}
                placeholder={"Surveyee..."}/>

        },
        {
            shouldShow: hasPermission([Permissions.REFLECTION_READ], true),
            tagView: <Select
                className="members-filter-role react-select-container"
                classNamePrefix="react-select"
                isDisabled={getAllAssignedReflectionsData?.loading}
                isMulti
                isClearable={true}
                value={searchQuery?.survey}
                options={createOptionListForSelectTag(getAllAssignedReflectionsData?.data, "name", "surveyId")}
                onChange={(selectedValue) => {
                    setSearchQuery({
                        ...searchQuery,
                        survey: selectedValue?.length === 0 ? null : selectedValue
                    })
                }}
                placeholder={"Reflection..."}/>

        },
        {
            tagView: <Select
                className="members-filter-role react-select-container"
                classNamePrefix="react-select"
                isMulti
                isClearable={true}
                value={searchQuery.attemptResult}
                options={createOptionListForSelectTag(Object.keys(Marks)?.map(c => {
                    return {name: Marks[c], value: c}
                }), "name", "value")}
                onChange={(selectedValue) => {
                    setSearchQuery({
                        ...searchQuery,
                        attemptResult: selectedValue?.length === 0 ? null : selectedValue
                    })
                }}
                placeholder={"Attempt Result..."}/>

        },
        {
            tagView: <div className={"members-date-picker-outer "}>
                <DatePicker placeholderText="From: dd-mm-yyyy" dateFormat={"dd-MM-yyyy"}
                            selected={searchQuery?.createdDateRange?.startDate || ""}
                            className={"search-by-survey-name survey-name-search member_search "}
                            maxDate={searchQuery?.createdDateRange?.endDate || new Date()}
                            onChange={(date) => {
                                setSearchQuery({
                                    ...searchQuery,
                                    createdDateRange: applyValidDateRange({
                                        startDate: date,
                                        endDate: searchQuery?.createdDateRange?.endDate || null
                                    })
                                })
                            }}
                />
                <DatePicker placeholderText="To: dd-mm-yyyy" dateFormat={"dd-MM-yyyy"}
                            selected={searchQuery?.createdDateRange?.endDate || ""}
                            minDate={searchQuery?.createdDateRange?.startDate || ""}
                            className={"search-by-survey-name survey-name-search user-join-toDate member_search "}
                            maxDate={new Date()}
                            onChange={(date) => {
                                setSearchQuery({
                                    ...searchQuery,
                                    createdDateRange: applyValidDateRange({
                                        startDate: searchQuery?.createdDateRange?.startDate || null,
                                        endDate: date
                                    })
                                })
                            }}
                />

            </div>
        }
    ]

    useEffect(() => {
        if (teamId !== null && teamId !== undefined) {
            setTriggerApi(true)
        }
    }, [teamId]);
    useEffect(() => {
        if (teamId !== null && teamId !== undefined) {
            dispatch(getAllTeamMembers(teamId)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            });
        }
    }, [teamId]);
    useEffect(() => {
        if (teamId !== null && teamId !== undefined && hasPermission([Permissions.REFLECTION_READ], true)) {
            dispatch(getAllAssignedReflections(teamId)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }
    }, [teamId]);
    useEffect(() => {
        if (triggerApi && hasPermission([Permissions.ATTEMPT_REFLECTION_READ], true)) {
            const searchQueryToSubmit = {
                ...searchQuery,
                ...sortCriteria,
                surveyorIds: searchQuery?.surveyorId?.map(surveyor => surveyor.value) || [],
                surveyeeIds: searchQuery?.surveyeeId?.map(surveyee => surveyee.value) || [],
                surveyIds: searchQuery?.survey?.map(c => c.value) || [],
                attemptResult: searchQuery?.attemptResult?.map(c => c.value) || [],
                page: pageNumber,
                pageSize: 10,
            }
            if (searchQuery?.createdDateRange) {
                searchQueryToSubmit.createdDateRange = formatAndComputeAutoDateRange(searchQuery?.createdDateRange?.startDate || "", searchQuery?.createdDateRange?.endDate || "", "yyyy-MM-dd")
            }
            dispatch(getAllSurveyAttempts(searchQueryToSubmit)).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                setTriggerApi(false)
            });
        }
    }, [triggerApi]);

    const handleSort = (column, sortDirection,) => {
        setSortCriteria({
            ...sortCriteria,
            sortBy: column.sortField,
            sortOrder: sortDirection,
        })
        setTriggerApi(true)
    };
    const setPageNumberAndApplySearch = (page) => {
        setPageNumber(page)
        setTriggerApi(true)
    }

    return (
        <>
            <div className={"row main_content  sub-org-members-outer"}>
                <div className={"col-md-12   px-0"}>
                    <div className="d-flex col-12">
                        <div className={"reflection_filters ms-auto"}>
                            <button type={"button"} onClick={() => {
                                setShowSearchFilterModal(true)
                            }}>
                                <img src={filter_icon} alt=""/>Filter
                            </button>
                        </div>
                    </div>


                </div>

                <div className={"colored_table p-0"}>
                    <DataTable
                        className={getAllSurveyAttemptsData?.data?.totalPages > 1 ? " table_design2 " : " table_design1 "}
                        persistTableHead={true} responsive={true}
                        customStyles={tableCustomStyling}
                        progressPending={getAllSurveyAttemptsData?.loading}
                        columns={columns}
                        data={getAllSurveyAttemptsData?.data?.data}
                        noDataComponent={<div className={"no-member-text"}>No Data Found</div>}
                        progressComponent={<TableSkeletonLoading columns={5} rows={5}/>}
                        onSort={handleSort}
                        sortServer={true}

                    />

                    {
                        !getAllSurveyAttemptsData?.loading && getAllSurveyAttemptsData?.data && getAllSurveyAttemptsData?.data?.totalPages > 1 &&
                        <Pagination className={""} totalPages={getAllSurveyAttemptsData?.data?.totalPages}
                                    pageNumber={pageNumber}
                                    setPageNumber={setPageNumberAndApplySearch}/>
                    }

                </div>

            </div>
            {
                showSearchFilterModal &&
                <SearchFilterModal title={"Attempts Filters"}
                                   show={showSearchFilterModal}
                                   filtersTag={searchFiltersTags}
                                   applyFilter={triggerApi}
                                   setApplyFilter={setTriggerApi}
                                   resetFilters={() => {
                                       setSearchQuery({
                                           orgId: token?.orgId,
                                           teamId: teamId,
                                           surveyorId: null,
                                           surveyeeId: null,
                                           createdDateRange: null,
                                           survey: null,
                                           attemptResult: null,
                                           surveyAttemptStatus: ["DRAFT", "COMPLETED"],
                                       });
                                   }}
                                   handleClose={() => {
                                       setShowSearchFilterModal(false);
                                   }}
                                   setShow={setShowSearchFilterModal}

                />
            }
        </>
    );
}
export default TeamAttempts