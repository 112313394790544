import {useNavigate, useParams} from "react-router-dom";
import "../members/SurveyResult.css"
import {Chart as ChartJs, Tooltip, ArcElement, Title} from "chart.js";
import {Doughnut} from "react-chartjs-2";
import {useEffect, useState} from "react";
import {hasPermission, Permissions} from "../../helpers/accessControl";
import {AccessDenied, AccessDeniedMsg, hexToRgb, Marks, QuestionType} from "../../helpers/constants";
import ErrorPage from "../errorPage/ErrorPage";
import {useDispatch, useSelector} from "react-redux";
import {getSurveyAttemptById, getSurveyById} from "../../reduxStore/surveySlices/surveySlices";
import {
    calculatePercentage,
    compareAndSaveAttemptedQuestionData,
    formatDate, groupQuestionsBySegmentIndex,
    isUnauthorized
} from "../../helpers/utils";
import {Loader} from "../layout/Loader";

ChartJs.register(
    Tooltip, ArcElement, Title
)

const SurveyResultDetail = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {surveyId} = useParams();

    const survey = useSelector(state => state.survey);
    const [surveyData, setSurveyData] = useState({});
    const [segmentType, setSegmentType] = useState(null)
    const [refCollection, setRefCollection] = useState([])
    const [attemptSurveyData, setAttemptSurveyData] = useState({});
    const {attemptSurveyId} = useParams();
    const [attemptQuestionDetail, setAttemptQuestionDetail] = useState(null);
    const [chartPercentage, setChartPercentage] = useState([]);
    const [error, setError] = useState({hasError: false, value: "", description: "", detailDesc: ""});
    const [showDetails, setShowDetails] = useState(false);
    const [chartData, setChartData] = useState({
        labels: ['Stagnating', 'Growing', 'Maturing', 'Not Answered'],
        datasets: [
            {
                data: [],
                backgroundColor: ['red', '#ff7100', 'green', '#c0b7b780'],
            },
        ],
    })

    const chartOptions={
        cutout:"80%",
        plugins: {
            legend: {
                display: false,
            },},
    }

    useEffect(() => {
        if (Object.keys(surveyData).length > 0 && Object.keys(attemptSurveyData).length > 0) {
            setChartData({
                ...chartData,
                datasets: [
                    {
                        ...chartData.datasets[0],
                        data: [attemptSurveyData.surveyAttemptResult?.highRiskAnswersCount, attemptSurveyData.surveyAttemptResult?.lowRiskAnswersCount, attemptSurveyData.surveyAttemptResult?.goodAnswersCount, surveyData.questions.length - attemptSurveyData.attemptQuestions.length],
                    },],
            });
            const attemptedQuestions = compareAndSaveAttemptedQuestionData(attemptSurveyData.attemptQuestions, surveyData.questions)
            setAttemptQuestionDetail(attemptedQuestions)
            setShowDetails(true)
            const answerPercentage = calculatePercentage([attemptSurveyData.surveyAttemptResult?.highRiskAnswersCount === undefined ? 0 : attemptSurveyData.surveyAttemptResult?.highRiskAnswersCount, attemptSurveyData.surveyAttemptResult?.lowRiskAnswersCount === undefined ? 0 : attemptSurveyData.surveyAttemptResult?.lowRiskAnswersCount, attemptSurveyData.surveyAttemptResult?.goodAnswersCount === undefined ? 0 : attemptSurveyData.surveyAttemptResult?.goodAnswersCount, surveyData.questions.length - attemptSurveyData.attemptQuestions.length])
            setChartPercentage(answerPercentage)
        }
    }, [surveyData, attemptSurveyData]);

    useEffect(() => {
        if (hasPermission([Permissions.REFLECTION_READ], true)) {
            dispatch(getSurveyById(surveyId)).then((res) => {
                if (isUnauthorized(res)) {
                    navigate("/")
                } else if (res.meta.requestStatus === "fulfilled") {
                    setSurveyData(res.payload.data)
                    setSegmentType(res.payload?.data?.segmentType)
                } else {
                    setError({
                        hasError: true,
                        value: res.payload.errorCode.value,
                        description: res.payload.errorCode.description,
                        detailDesc: res.payload.detailDesc
                    })
                }
            })
            dispatch(getSurveyAttemptById(attemptSurveyId)).then((res) => {
                if (isUnauthorized(res)) {
                    navigate("/")
                } else if (res.meta.requestStatus === "fulfilled") {
                    setAttemptSurveyData(res.payload.data);
                } else {
                    setError({
                        hasError: true,
                        value: res.payload.errorCode.value,
                        description: res.payload.errorCode.description,
                        detailDesc: res.payload.detailDesc
                    })
                }
            })
        }
    }, []);

    useEffect( ()=>{
        if(segmentType !== null && segmentType === "TABULAR"){
            setRefCollection(groupQuestionsBySegmentIndex(surveyData?.segments,surveyData?.questions))
        }
    },[segmentType])


    return !hasPermission([Permissions.REFLECTION_READ], true) ?
        <ErrorPage value={403} description={AccessDenied} detailDesc={AccessDeniedMsg}></ErrorPage> :

        (survey?.getSurveyAttemptByIdDataReducer?.loading || survey?.getSurveyAttemptByIdDataReducer?.loading || Object.keys(surveyData).length === 0 || Object.keys(attemptSurveyData).length === 0) ?
            ((error.hasError) ?
                    <ErrorPage value={error.value} detailDesc={error.detailDesc} description={error.description}/> :
                    <Loader/>
            )
            :

            (
                <div className="row main_content box_design1">
                    <div className="member-detail-buttons mt-2">
                        <button style={{background : localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color"):""} } onClick={() => {
                            navigate(-1)
                        }} className="modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize ">
                            <i className="fa fa-solid fa-arrow-left"></i>&nbsp;&nbsp; Go Back
                        </button>
                    </div>
                    <div className={"row survey-result-outer"}>
                        {

                            attemptSurveyData.surveyAttemptResult !== undefined && <>
                                <div className={"col-lg-5 p-0"}>
                                    <div>
                                        <div className={"survey-details-outer mt-4"}>
                                            <div className={"survey-detail-heading mb-2"}>Reflection Details</div>
                                            <div className={"survey-details"}>
                                                <div className={"detail-outer mb-1"}>
                                                    <div
                                                        className={"mediumFontSize detail-heading reflection_heading text-start"}>Reflection
                                                        Name :
                                                    </div>
                                                    <div title={attemptSurveyData.surveyName}
                                                         className={"detail-text"}>{attemptSurveyData.surveyName}</div>
                                                </div>
                                                <div className={"detail-outer mb-1"}>
                                                    <div className={"mediumFontSize detail-heading"}>Status :</div>
                                                    <div
                                                        className={"detail-text"}> {attemptSurveyData.surveyAttemptStatus}</div>
                                                </div>
                                                <div className={"detail-outer mb-1"}>
                                                    <div className={"mediumFontSize detail-heading"}>Attempted On :</div>
                                                    <div
                                                        className={"regularFontSize detail-text"}>{formatDate(attemptSurveyData.createdAt)}</div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div>
                                        <div className={"survey-details-outer mt-2"}>
                                            <div
                                                className={"survey-answer-detail-heading mb-2 " + (attemptSurveyData.surveyAttemptResult.overallRiskLevel === "HIGH_RISK" ? "bg-light-red" : attemptSurveyData.surveyAttemptResult.overallRiskLevel === "LOW_RISK" ? "bg-light-orange" : "bg-light-green")}>OverAll
                                                Result
                                                : {Marks[attemptSurveyData.surveyAttemptResult.overallRiskLevel]}
                                            </div>
                                            <div className={"survey-details"}>
                                                <div className={"detail-outer mb-1"}>
                                                    <div className={"mediumFontSize detail-heading"}>Total Questions :</div>
                                                    <div className={"detail-text"}>{surveyData.questions.length}</div>
                                                </div>
                                                <div className={"detail-outer mb-1"}>
                                                    <div
                                                        className={"mediumFontSize detail-heading"}>{Marks["GOOD"]} Attempts
                                                        :
                                                    </div>
                                                    <div
                                                        className={"detail-text"}> {attemptSurveyData.surveyAttemptResult.goodAnswersCount === undefined ? 0 : attemptSurveyData.surveyAttemptResult.goodAnswersCount}</div>
                                                </div>
                                                <div className={"detail-outer mb-1"}>
                                                    <div
                                                        className={"mediumFontSize detail-heading"}>{Marks["LOW_RISK"]} Attempts
                                                        :
                                                    </div>
                                                    <div
                                                        className={"regularFontSize detail-text"}> {attemptSurveyData.surveyAttemptResult.lowRiskAnswersCount === undefined ? 0 : attemptSurveyData.surveyAttemptResult.lowRiskAnswersCount}</div>
                                                </div>
                                                <div className={"detail-outer mb-1"}>
                                                    <div
                                                        className={"mediumFontSize detail-heading"}>{Marks["HIGH_RISK"]} Attempts
                                                        :
                                                    </div>
                                                    <div
                                                        className={"regularFontSize detail-text"}> {attemptSurveyData.surveyAttemptResult.highRiskAnswersCount === undefined ? 0 : attemptSurveyData.surveyAttemptResult.highRiskAnswersCount}</div>
                                                </div>
                                                <div className={"detail-outer mb-1"}>
                                                    <div className={"mediumFontSize detail-heading"}>Unattempted :</div>
                                                    <div
                                                        className={"regularFontSize detail-text"}> {surveyData.questions.length - attemptSurveyData.attemptQuestions.length}</div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                <div className={"col-lg-7 p-0 ps-1 "}>
                                    <div className={'pie-chart-outer mt-4'}>
                                        <div className={"survey-detail-heading mb-2"}>Result</div>
                                        <div className={"pie-chart-container"}>
                                            <div className={"pie-chart"}>
                                                <div
                                                    className={"result mediumFontSize " + (attemptSurveyData.surveyAttemptResult.overallRiskLevel === "HIGH_RISK" ? "text-color-high-risk" : attemptSurveyData.surveyAttemptResult.overallRiskLevel === "LOW_RISK" ? "text-color-low-risk" : "text-color-good")}

                                                >
                                                    {Marks[attemptSurveyData.surveyAttemptResult.overallRiskLevel]}</div>
                                                <Doughnut data={chartData} options={chartOptions}></Doughnut>
                                            </div>
                                            <div className={"pie-chart-details"}>
                                                <div className={"risk mb-4"}>
                                                    <div className={"risk-bar bg-red"}></div>
                                                    <div className={"high-risk-text"}>
                                                        <div className={" text"}>{Marks["HIGH_RISK"]}</div>
                                                        <div className={" percentage"}>{chartPercentage[0]}%</div>
                                                    </div>
                                                </div>
                                                <div className={"risk mb-4"}>
                                                    <div className={"risk-bar bg-orange"}></div>
                                                    <div className={"risk-text"}>
                                                        <div className={" text"}>{Marks["LOW_RISK"]}</div>
                                                        <div className={" percentage"}>{chartPercentage[1]}%</div>
                                                    </div>
                                                </div>
                                                <div className={"risk mb-4"}>
                                                    <div className={"risk-bar bg-green"}></div>
                                                    <div className={"risk-text"}>
                                                        <div className={" text"}>{Marks["GOOD"]}</div>
                                                        <div className={" percentage"}>{chartPercentage[2]}%</div>
                                                    </div>
                                                </div>
                                                <div className={"risk mb-4"}>
                                                    <div className={"risk-bar bg-grey"}></div>
                                                    <div className={"risk-text"}>
                                                        <div className={" text"}>Not Answered</div>
                                                        <div className={" percentage"}>{chartPercentage[3]}%</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </>

                        }


                        <div className={"question-details  mt-3"} onClick={() => {
                            setShowDetails(!showDetails)
                        }}>
                            <div className={"question-details-heading mediumFontSize"}>Question Details</div>
                            {
                                showDetails ? <i className="fa fa-angle-up" aria-hidden="true"/> :
                                    <i className="fa fa-angle-down" aria-hidden="true"/>
                            }
                        </div>
                        {

                            showDetails && segmentType === "LINEAR" &&

                            <div className={"answer-details"}>


                                {attemptQuestionDetail?.map((attemptQuestion, index) => {
                                        return (
                                            <>
                                                <div className={"answer-details-outer"} key={index}>
                                                    <div className={"answer-details-question mb-2"}>
                                                        <div className={"question-number"}>{index + 1}.</div>
                                                        <div
                                                            className={"question-text"}>{attemptQuestion.question.questionText}
                                                        </div>
                                                    </div>
                                                    {
                                                        attemptQuestion.question.type === QuestionType.NUMBER &&
                                                        <div className={"text-response-answer-result-outer number-answer-container"}>
                                                            <div className={"text-response-answer-result w-100 "+(attemptQuestion.attemptQuestion===undefined?"disable-text":"")} >{attemptQuestion.attemptQuestion===undefined?"Not Attempted":attemptQuestion.attemptQuestion.answerNumber}</div>
                                                            <div className={"question-range text-start"}>{`[ Min : ${attemptQuestion.question.number.min} - Max : ${attemptQuestion.question.number.max} ]`}</div>
                                                        </div>

                                                    }
                                                    {
                                                        attemptQuestion.question.type === QuestionType.TEXT &&
                                                            <>

                                                                { (localStorage.getItem("color") !== undefined || localStorage.getItem("color") !== null || localStorage.getItem("color") !== 'undefined') ?
                                                                    <>
                                                                        <div style={{ background: hexToRgb(localStorage.getItem("color") ,0.1)}}>
                                                                            <p className={"text-response-answer-result w-100 "+(attemptQuestion.attemptQuestion===undefined?"disable-text":"")} >{attemptQuestion.attemptQuestion===undefined?"Not Attempted":attemptQuestion.attemptQuestion.answerText}</p>
                                                                        </div></>
                                                                    :
                                                                    <>
                                                                        <div className={"text-response-answer-result-outer"}>
                                                                            <p className={"text-response-answer-result w-100 "+(attemptQuestion.attemptQuestion===undefined?"disable-text":"")} >{attemptQuestion.attemptQuestion===undefined?"Not Attempted":attemptQuestion.attemptQuestion.answerText}</p>
                                                                        </div>

                                                                    </>


                                                                }
                                                                </>

                                                    }

                                                    {
                                                        attemptQuestion.question.type === QuestionType.OPTION &&
                                                        <div className={"answer-details-options"}>
                                                            {
                                                                attemptQuestion.question.options.map((option, index) => {
                                                                    return (
                                                                        <>

                                                                            {
                                                                                (attemptQuestion?.attemptQuestion?.selectedOption?.optionId === option.optionId) &&
                                                                                (localStorage.getItem("color") !== undefined || localStorage.getItem("color") !== null || localStorage.getItem("color") !== 'undefined') ?

                                                                                    <>
                                                                                        <div
                                                                                            key={index}
                                                                                            className={"answer-details-option-outer mb-1 "}
                                                                                            style={{ background: hexToRgb(localStorage.getItem("color"),0.1)}}
                                                                                        >
                                                                                            <div  className={"option-container d-flex"}>
                                                                                                <div className={"option-text-numbering me-3"}>{"Option " + (index + 1)}</div>
                                                                                                <div className={"answer-details-option-text me-3"}>{option.optionText}</div>
                                                                                            </div>

                                                                                            <div className={"answer-details-option-value"}>
                                                                                                <div className={"option-value-text"}>{option.optionValue}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>

                                                                                    :

                                                                                    <div
                                                                                        key={index}
                                                                                        className={"answer-details-option-outer mb-1 " + (attemptQuestion?.attemptQuestion?.selectedOption?.optionId === option.optionId ? "selected-option" : "")}
                                                                                    >
                                                                                        <div className={"option-container d-flex"}>
                                                                                            <div className={"option-text-numbering me-3"}>{"Option " + (index + 1)}</div>
                                                                                            <div className={"answer-details-option-text me-3"}>{option.optionText}</div>
                                                                                        </div>

                                                                                        <div className={"answer-details-option-value"}>
                                                                                            <div className={"option-value-text"}>{option.optionValue}</div>
                                                                                        </div>
                                                                                    </div>
                                                                            }


                                                                        </>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    }


                                                </div>
                                                <hr/>
                                            </>
                                        );
                                    })
                                }
                            </div>
                        }

                        {

                            showDetails && segmentType === "TABULAR" &&

                            <>
                                { refCollection.length > 0  && refCollection.map( (refData, refIndex)=>{

                                     return (

                                         <>
                                             <div className={"section-result mediumFontSize"}>
                                                 <h5>{refIndex?.segmentName || 'Section ' + (refIndex +1)} </h5>
                                                 {attemptQuestionDetail?.map((attemptQuestion, index) => {
                                                     if(attemptQuestion?.question?.segmentIndex === refIndex )
                                                     {
                                                         return (
                                                             <>
                                                                 <div className={"answer-details-outer mt-3"} key={index}>
                                                                     <div className={"answer-details-question mb-2"}>
                                                                         <div className={"question-number"}>{index + 1}.</div>
                                                                         <div
                                                                             className={"question-text"}>{attemptQuestion.question.questionText}
                                                                         </div>
                                                                     </div>
                                                                     {
                                                                         attemptQuestion.question.type === QuestionType.NUMBER &&
                                                                         <div className={"text-response-answer-result-outer number-answer-container"}>
                                                                             <div className={"text-response-answer-result w-100 "+(attemptQuestion.attemptQuestion===undefined?"disable-text":"")} >{attemptQuestion.attemptQuestion===undefined?"Not Attempted":attemptQuestion.attemptQuestion.answerNumber}</div>
                                                                             <div className={"question-range text-start"}>{`[ Min : ${attemptQuestion.question.number.min} - Max : ${attemptQuestion.question.number.max} ]`}</div>
                                                                         </div>

                                                                     }
                                                                     {
                                                                         attemptQuestion.question.type === QuestionType.TEXT &&
                                                                         <>

                                                                             { (localStorage.getItem("color") !== undefined || localStorage.getItem("color") !== null || localStorage.getItem("color") !== 'undefined') ?
                                                                                 <>
                                                                                     <div style={{ background: hexToRgb(localStorage.getItem("color") ,0.1)}}>
                                                                                         <p className={"text-response-answer-result w-100 "+(attemptQuestion.attemptQuestion===undefined?"disable-text":"")} >{attemptQuestion.attemptQuestion===undefined?"Not Attempted":attemptQuestion.attemptQuestion.answerText}</p>
                                                                                     </div></>
                                                                                 :
                                                                                 <>
                                                                                     <div className={"text-response-answer-result-outer"}>
                                                                                         <p className={"text-response-answer-result w-100 "+(attemptQuestion.attemptQuestion===undefined?"disable-text":"")} >{attemptQuestion.attemptQuestion===undefined?"Not Attempted":attemptQuestion.attemptQuestion.answerText}</p>
                                                                                     </div>

                                                                                 </>


                                                                             }
                                                                         </>

                                                                     }

                                                                     {
                                                                         attemptQuestion.question.type === QuestionType.OPTION &&
                                                                         <div className={"answer-details-options"}>
                                                                             {
                                                                                 attemptQuestion.question.options.map((option, index) => {
                                                                                     return (
                                                                                         <>

                                                                                             {
                                                                                                 (attemptQuestion?.attemptQuestion?.selectedOption?.optionId === option.optionId) &&
                                                                                                 (localStorage.getItem("color") !== undefined || localStorage.getItem("color") !== null || localStorage.getItem("color") !== 'undefined') ?

                                                                                                     <>
                                                                                                         <div
                                                                                                             key={index}
                                                                                                             className={"answer-details-option-outer mb-1 "}
                                                                                                             style={{ background: hexToRgb(localStorage.getItem("color"),0.1)}}
                                                                                                         >
                                                                                                             <div  className={"option-container d-flex"}>
                                                                                                                 <div className={"option-text-numbering me-3"}>{"Option " + (index + 1)}</div>
                                                                                                                 <div className={"answer-details-option-text me-3"}>{option.optionText}</div>
                                                                                                             </div>

                                                                                                             <div className={"answer-details-option-value"}>
                                                                                                                 <div className={"option-value-text"}>{option.optionValue}</div>
                                                                                                             </div>
                                                                                                         </div>
                                                                                                     </>

                                                                                                     :

                                                                                                     <div
                                                                                                         key={index}
                                                                                                         className={"answer-details-option-outer mb-1 " + (attemptQuestion?.attemptQuestion?.selectedOption?.optionId === option.optionId ? "selected-option" : "")}
                                                                                                     >
                                                                                                         <div className={"option-container d-flex"}>
                                                                                                             <div className={"option-text-numbering me-3"}>{"Option " + (index + 1)}</div>
                                                                                                             <div className={"answer-details-option-text me-3"}>{option.optionText}</div>
                                                                                                         </div>

                                                                                                         <div className={"answer-details-option-value"}>
                                                                                                             <div className={"option-value-text"}>{option.optionValue}</div>
                                                                                                         </div>
                                                                                                     </div>
                                                                                             }


                                                                                         </>
                                                                                     );
                                                                                 })
                                                                             }
                                                                         </div>
                                                                     }


                                                                 </div>
                                                                 <hr/>
                                                             </>
                                                         );
                                                     }

                                                 })
                                                 }

                                             </div>

                                         </>
                                     )
                                })
                                }

                                </>




                        }

                </div>
                </div>

            )
        ;
}
export default SurveyResultDetail;