import Modal from "react-bootstrap/Modal";
import {IoIosClose} from "react-icons/io";
import {TailSpinLoader} from "../../layout/Loader";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {
    createOptionListForSelectTag,
    getDataFromLocalStorage,
    handleApiResponse,
    isNullOrEmpty,
    isUnauthorized,
    isValidCreateStrategyData,
    trim
} from "../../../helpers/utils";
import {createStrategy} from "../../../reduxStore/strategySlice/strategySlice";
import {useNavigate} from "react-router-dom";
import jwtDecode from "jwt-decode";
import {Roles} from "../../../helpers/accessControl";
import Select from "react-select";

const CreateStrategyModal = ({isSubOrg,subOrgId,show, setShow, triggerSearchStrategy}) => {

    const token = jwtDecode(getDataFromLocalStorage("token"))
    const isOrgLevelSys = token.orgLevel === "SYSTEM"
    const isRoleOrgAdmin = token.roles.includes(Roles.ORG_ADMIN) || token.roles.includes(Roles.SUB_ORG_ADMIN)
    const isRoleManager = token.roles.includes(Roles.MANAGER)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [createStrategyData, setCreateStrategyData] = useState({
        org: {
            label: "Organization...", value: token?.orgId
        },
        name: "",
        description: "",
        goals: [],
    })
    const [currentGoal, setCurrentGoal] = useState("")
    const createStrategyApi = useSelector(state => state.strategy.createStrategyReducer)
    const orgListApi = useSelector(state => state.org.getAllOrgAsListReducer);

    const handleClose = () => setShow(false);
    const removeGoal = (index) => {
        const updatedGoals = [...createStrategyData.goals]
        updatedGoals.splice(index, 1);
        setCreateStrategyData({...createStrategyData, goals: updatedGoals})
    }
    const handleOnChange = (e) => {
        const {name, value} = e.target
        setCreateStrategyData({
            ...createStrategyData,
            [name]: value
        })
    }
    const handleSubmit = () => {
        if (isValidCreateStrategyData(createStrategyData)) {
            dispatch(createStrategy({
                ...createStrategyData,
                orgId:createStrategyData?.org?.value
            })).then(res => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
                handleApiResponse(res, () => {
                    setShow(false)
                    triggerSearchStrategy(1)
                })
            })
        }

    }
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            const {_, value} = e.target
            if (!isNullOrEmpty(value.trim()) && !createStrategyData?.goals.includes(value.trim())) {
                setCreateStrategyData({
                    ...createStrategyData,
                    goals: [...createStrategyData?.goals, value.trim()]
                })
            }
            setCurrentGoal("")
        }
    };

    useEffect(() => {
        if (isOrgLevelSys &&( isRoleOrgAdmin || isRoleManager) && !orgListApi.loading && orgListApi.data !==undefined && !isSubOrg) {
            const orgInfo = orgListApi.data?.filter(org => org.orgId === token?.orgId)[0]
            setCreateStrategyData({...createStrategyData, org: {label: orgInfo.name, value: orgInfo.orgId}})
        }
    }, [orgListApi])

    // If Strategy is created under strategy tab inside sub org grid
    useEffect(() => {
        if (isSubOrg && subOrgId) {
            setCreateStrategyData({...createStrategyData, org: {label: "", value: subOrgId}})
        }
    }, [subOrgId])

    return (
        <>
            <Modal className='mt-4' show={show} centered>
                <Modal.Header className={"d-flex"}>
                    <Modal.Title className='add_member_heading mediumFontSize'>Create Strategy</Modal.Title>
                    <IoIosClose className={"close_btn"} onClick={handleClose} size={24}/>
                </Modal.Header>

                <form>

                    <Modal.Body className="modal_outer">
                        <div className="reset_form">
                            <div className="form-group mt-1">
                                <label className='userInfo_label mediumFontSize' form="inputAddress">Name*</label>
                                <input
                                    required={true}
                                    type="text"
                                    name={"name"}
                                    value={createStrategyData.name}
                                    onChange={handleOnChange}
                                    placeholder={"Strategy name"}
                                    className="form-control userInfo_input"
                                    id="inputAddress"/>
                            </div>
                            <div className="form-group mt-1">
                                <label className='userInfo_label mediumFontSize'
                                       form="inputAddress">Description*</label>
                                <textarea
                                    name={"description"}
                                    value={createStrategyData.description}
                                    onChange={handleOnChange}
                                    placeholder={"Detailed description or steps to achieve aim"}
                                    className="form-control userInfo_input strategy_description_input"
                                    id="inputAddress"/>
                            </div>
                            <div className={"form-group mt-1"}>

                                <label className={"userInfo_label mb-2"}
                                       htmlFor="exampleFormControlSelect1"> Goals</label>

                                <div className={"survey-tag-outer w-100 survey-tag-outer1"}>
                                    {
                                        createStrategyData?.goals?.map((goal, index) => {
                                            return (
                                                <div key={index} className={"survey-tags"}>
                                                    <div className={"tag-text"} title={goal}>{trim(goal, 12)}
                                                    </div>
                                                    <div className={"tag-cross"} name={"goals"}
                                                         onClick={() => {
                                                             removeGoal(index)
                                                         }}>
                                                        <i className={"fa fa-times remove-tag-icon"}/>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                    <div className={"add-tag-outer"}>
                                        <i
                                            style={{color: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                                            className={"fa fa-plus add-tag-icon text-dark"}/>
                                        <input name={"goal"}
                                               value={currentGoal}
                                               onChange={(e) => {
                                                   setCurrentGoal(e.target.value)
                                               }}
                                               onKeyDown={handleKeyDown}
                                               onBlur={(e) => {
                                                   handleKeyDown({...e, key: "Enter"})
                                               }}
                                               placeholder={"Add Strategy Goals"}
                                               className={"form-control strategy-goals-input-field"}
                                               type={"text"}

                                        />
                                    </div>
                                </div>

                            </div>
                            {
                                isOrgLevelSys &&( isRoleOrgAdmin || isRoleManager) && !isSubOrg &&
                                <div className="form-group mt-1">
                                    <label className='userInfo_label mediumFontSize' form="inputAddress">Organization*</label>
                                    <Select
                                        className="members-filter-role react-select-container" classNamePrefix="react-select"
                                        isDisabled={orgListApi?.loading}
                                        value={createStrategyData?.org}
                                        options={createOptionListForSelectTag(orgListApi?.data, "name", "orgId")}
                                        onChange={(selectedOrg) => {
                                            if (createStrategyData?.org?.value !== selectedOrg?.value) {
                                                handleOnChange({target:{name:"org",value:selectedOrg}})
                                            }
                                        }}
                                        isClearable={false}
                                        placeholder={"Organization..."}/>
                                </div>
                            }

                        </div>


                    </Modal.Body>
                </form>
                <Modal.Footer className="modal_footer">
                    <div className={"add-member-modal-btns"}>
                        <button
                            style={{background: localStorage.getItem("color") !== null || localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : ""}}
                            type="submit"
                            onClick={handleSubmit}
                            disabled={createStrategyApi?.loading || orgListApi?.loading}
                            className="ms-2 modal_reset_btn cmn_background_color cmn_modal_btn mediumFontSize ">Create
                            {
                            createStrategyApi?.loading && <TailSpinLoader/>
                            }
                        </button>
                        <button className="modal_cancel_btn cmn_modal_btn mediumFontSize"
                                disabled={createStrategyApi?.loading} onClick={handleClose}>Cancel
                        </button>
                    </div>

                </Modal.Footer>

            </Modal>
        </>
    );
}
export default CreateStrategyModal