import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getAxiosConfig, serialize, showToast} from "../../helpers/utils";
import {toast} from "react-toastify";
import {TriggerCreatedSuccessfully} from "../../helpers/constants";

export const createCriteria = createAsyncThunk('createCriteria', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/trigger`, data, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    })
})

export const getCriteria = createAsyncThunk('criteria/getCriteria', async (triggerId, thunkAPI) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/trigger/${triggerId}`, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
})


export const deleteCriteria = createAsyncThunk('criteria/deleteCriteria', async (triggerId, thunkAPI) => {
    return await axios.delete(`${process.env.REACT_APP_BASE_URL}/trigger/delete/${triggerId}`, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
})

export const upsertCriteria = createAsyncThunk('criteria/upsertCriteria', async (data, thunkAPI) => {
    const serializeData = serialize({id:data?.id ,enable: data?.enable.toString()})
    return await axios.put(`${process.env.REACT_APP_BASE_URL}/trigger/upsert?${serializeData}`,{},getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
})

export const updateCriteria = createAsyncThunk('criteria/updateCriteria', async (data, thunkAPI) => {
    return await axios.put(`${process.env.REACT_APP_BASE_URL}/trigger/update/${data?.id}`,data, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
})



export const getAllCriteria = createAsyncThunk('/getAllCriteria', async (data, thunkAPI) => {
    const serializedData = serialize({page: data?.page, pageSize: data?.pageSize,sortOrder:data?.sortOrder,sortBy:data?.sortBy});
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/trigger/search?${serializedData}`,  getAxiosConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    });
})

const criteria = createSlice({
    name: "criteria",
    initialState: {
        createCriteriaReducer: {loading:false},
        getSearchCriteriaDataReducer: {loading:false},
        getCriteriaByIdReducer: {loading: false},
        deleteCriteriaByIdReducer: {loading: false},
        upsertCriteriaByIdReducer: {loading: false},
        updateCriteriaCriteriaByIdReducer: {loading: false},
    },

    reducers: {

        resetGetTriggerByIdReducer: (state) => {
            state.getCriteriaByIdReducer = {loading: false, data: null}
        }

    },
    extraReducers: {
        [createCriteria.pending]: (state) => {
            state.createCriteriaReducer = {loading:true};
        },
        [createCriteria.fulfilled]: (state, action) => {
            state.createCriteriaReducer = {loading:false , data:action.payload.data };
            showToast(TriggerCreatedSuccessfully,"success");
        },
        [createCriteria.rejected]: (state, action) => {
            state.createCriteriaReducer = {loading:false};
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText,"error");
        },

        [getAllCriteria.pending]: (state) => {
            state.getSearchCriteriaDataReducer = {loading:true};
        },
        [getAllCriteria.fulfilled]: (state, action) => {
            state.getSearchCriteriaDataReducer = {loading:false , data:action.payload.data };
        },
        [getAllCriteria.rejected]: (state, action) => {
            state.getSearchCriteriaDataReducer = {loading:false};
            showToast( action?.payload?.data?.detailDesc || action?.payload?.statusText,"error");
        },


        // Get Criteria By Id
        [getCriteria.pending]: (state) => {
            state.getCriteriaByIdReducer = {loading: true}
        },
        [getCriteria.fulfilled]: (state, action) => {
            state.getCriteriaByIdReducer = {loading: false, data: action.payload.data}
        },
        [getCriteria.rejected]: (state, action) => {
            state.getCriteriaByIdReducer = {loading: false}
            if (!(action.payload.errorCode.value === 400 || action.payload.errorCode.value === 404)) {
                showToast(action.payload.detailDesc || action?.payload?.statusText,"error");
            }
        },

        // delete Criteria
        [deleteCriteria.pending]: (state) => {
            state.deleteCriteriaByIdReducer = {loading: true}
        },
        [deleteCriteria.fulfilled]: (state, action) => {
            state.deleteCriteriaByIdReducer = {loading: false, data: action.payload.data}
            showToast(action.payload.data,"success");

        },
        [deleteCriteria.rejected]: (state, action) => {
            state.deleteCriteriaByIdReducer = {loading: false}
            if (!(action.payload.errorCode.value === 400 || action.payload.errorCode.value === 404)) {
                showToast(action.payload.detailDesc || action?.payload?.statusText,"error");
            }
        },


        // upsert criteria

        [upsertCriteria.pending]: (state) => {
            state.upsertCriteriaByIdReducer = {loading: true}
        },
        [upsertCriteria.fulfilled]: (state, action) => {
            state.upsertCriteriaByIdReducer = {loading: false, data: action.payload.data}
            showToast(action.payload.data,"success");

        },
        [upsertCriteria.rejected]: (state, action) => {
            state.upsertCriteriaByIdReducer = {loading: false}
            if (!(action.payload.errorCode.value === 400 || action.payload.errorCode.value === 404)) {
                showToast(action.payload.detailDesc || action?.payload?.statusText,"error");
            }
        },

        // update Criteria
        [updateCriteria.pending]: (state) => {
            state.updateCriteriaCriteriaByIdReducer = {loading: true}
        },
        [updateCriteria.fulfilled]: (state, action) => {
            state.updateCriteriaCriteriaByIdReducer = {loading: false, data: action.payload.data}
            showToast(action.payload.data,"success");

        },
        [updateCriteria.rejected]: (state, action) => {
            state.updateCriteriaCriteriaByIdReducer = {loading: false}
            if (!(action.payload.errorCode.value === 400 || action.payload.errorCode.value === 404)) {
                showToast(action.payload.detailDesc || action?.payload?.statusText,"error");
            }
        },
    }

})

export const {resetGetTriggerByIdReducer} = criteria.actions
export default criteria.reducer;