import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getAxiosConfig, getStipeConfig, showToast} from "../../helpers/utils";
import {toast} from "react-toastify";
import qs from "qs";
import {SubscriptionAlreadyExists} from "../../helpers/constants";


export const stripeFetchAllProducts = createAsyncThunk('stripeSlices/stripeFetchAllProducts', async (data, thunkAPI) => {
    return await axios.get('https://api.stripe.com/v1/products?active=true', getStipeConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})
export const createStripeSession = createAsyncThunk('stripe/createStripeSession', async (data, thunkAPI) => {
    const formattedData = qs.stringify(data);
    return await axios.post(`https://api.stripe.com/v1/checkout/sessions`, formattedData, getStipeConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})
export const getStripeSessionBySessionId = createAsyncThunk('stripe/getStripeSessionBySessionId', async (sessionId, thunkAPI) => {

    return await axios.get(`https://api.stripe.com/v1/checkout/sessions/${sessionId}`, getStipeConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})
export const getSubscriptionBySubscriptionId = createAsyncThunk('stripe/getSubscriptionBySubscriptionId', async (subscriptionId, thunkAPI) => {
    return await axios.get(`https://api.stripe.com/v1/subscriptions/${subscriptionId}`, getStipeConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

export const stripeFetchAllPrices = createAsyncThunk('stripeSlices/stripeFetchAllPrices', async (data, thunkAPI) => {
    return await axios.get('https://api.stripe.com/v1/prices?active=true&limit=20', getStipeConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})
export const getStripeSubscriptionPaymentInvoiceByInvoiceId = createAsyncThunk('stripeSlices/getStripeSubscriptionPaymentInvoiceByInvoiceId', async (invoiceId, thunkAPI) => {
    return await axios.get(`https://api.stripe.com/v1/invoices/${invoiceId}`, getStipeConfig()).then((res) => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
    });
})

export const userSubscriptionData = createAsyncThunk('stripeSlices/userSubscriptionData', async (data, thunkAPI) => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/user-subscription`,
        headers: getAxiosConfig().headers,
        data: data
    };
    return await axios.request(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return thunkAPI.rejectWithValue(error.response);
        });

})
export const upgradeUserSubscription = createAsyncThunk('stripeSlices/upgradeUserSubscription', async (data, thunkAPI) => {
    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/user-subscription`,
        headers: getAxiosConfig().headers,
        data: data
    };
    return await axios.request(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return thunkAPI.rejectWithValue(error.response);
        });

})


const stripeSlices = createSlice({
    name: "stripe",
    initialState: {
        isUserSubscription: false,
        getAllPricesDataReducer: {loading: false},
        userSubscriptionResultReducer: {loading:false},
        getAllProductDataReducer: {loading:false},
        createStripeSessionDataReducer: {loading: false},
        getStripeSessionBySessionIdDataReducer: {loading:false},
        getSubscriptionBySubscriptionIdDataReducer: {loading:false},
        getStripeSubscriptionPaymentInvoiceByInvoiceIdDataReducer: {loading:false},
        upgradeUserSubscriptionReducer:{loading:false},
    },
    reducers:{
        stripeResetAllReducer:(state)=>{
            state.getAllPricesDataReducer= {loading: false};
            state.userSubscriptionResultReducer= {loading:false};
            state.getAllProductDataReducer= {loading:false};
            state.createStripeSessionDataReducer= {loading: false};
            state.getStripeSessionBySessionIdDataReducer= {loading:false};
            state.getSubscriptionBySubscriptionIdDataReducer= {loading:false};
            state.getStripeSubscriptionPaymentInvoiceByInvoiceIdDataReducer= {loading:false};
            state.upgradeUserSubscriptionReducer={loading:false}
        }
    },

    extraReducers: {

        // products
        [stripeFetchAllProducts.pending]: (state) => {
            state.getAllProductDataReducer = {loading: true}
        },
        [stripeFetchAllProducts.fulfilled]: (state, action) => {
            state.getAllProductDataReducer = {loading: false, data: action.payload.data}
        },
        [stripeFetchAllProducts.rejected]: (state, action) => {
            state.getAllProductDataReducer = {loading: false}
            showToast(action.payload.error.message, "error")
        },

        // pricing
        [stripeFetchAllPrices.pending]: (state) => {
            state.getAllPricesDataReducer = {loading: true}
        },
        [stripeFetchAllPrices.fulfilled]: (state, action) => {
            state.getAllPricesDataReducer = {loading: false, data: action.payload.data}
        },
        [stripeFetchAllPrices.rejected]: (state, action) => {
            state.getAllPricesDataReducer = {loading: false}
            showToast(action.payload.error.message, "error")
        },

        // store user-Subscription
        [userSubscriptionData.pending]: (state) => {
            state.userSubscriptionResultReducer = {loading: true};
        },
        [userSubscriptionData.fulfilled]: (state, action) => {
            state.userSubscriptionResultReducer = {loading: false, data: action.payload};
        },
        [userSubscriptionData.rejected]: (state, action) => {
            if(!(action.payload.status===400 && action.payload.data.detailDesc===SubscriptionAlreadyExists)){
                showToast(action.payload.detailDesc, "error")
            }
            state.userSubscriptionResultReducer = {loading: false};

        },
        // create stripe session
        [createStripeSession.pending]: (state) => {
            state.createStripeSessionDataReducer = {loading: true};
        },
        [createStripeSession.fulfilled]: (state, action) => {
            state.createStripeSessionDataReducer = {loading: false, data: action.payload};
            window.location.href = action.payload.url;
        },
        [createStripeSession.rejected]: (state, action) => {
            state.createStripeSessionDataReducer = {loading: false};
            showToast(action.payload.error.message, "error")
        },

        // Get Stripe Session  By Session Id
        [getStripeSessionBySessionId.pending]: (state) => {
            state.getStripeSessionBySessionIdDataReducer = {loading:true};
        },
        [getStripeSessionBySessionId.fulfilled]: (state, action) => {
            state.getStripeSessionBySessionIdDataReducer = {loading:false,data:action.payload};
        },
        [getStripeSessionBySessionId.rejected]: (state, action) => {
            state.getStripeSessionBySessionIdDataReducer = {loading:false};
            showToast(action.payload.error.message, "error")
        },

        // Get Stripe Subscription  By Subscription Id
        [getSubscriptionBySubscriptionId.pending]: (state) => {
            state.getStripeSessionBySessionIdDataReducer = {loading:true};
        },
        [getSubscriptionBySubscriptionId.fulfilled]: (state, action) => {
            state.getStripeSessionBySessionIdDataReducer = {loading:false , data:action.payload};
        },
        [getSubscriptionBySubscriptionId.rejected]: (state, action) => {
            state.getStripeSessionBySessionIdDataReducer = {loading:false};
            showToast(action.payload.error.message, "error")
        },

        // Get Stripe Subscription  By Subscription Id
        [getStripeSubscriptionPaymentInvoiceByInvoiceId.pending]: (state) => {
            state.getStripeSubscriptionPaymentInvoiceByInvoiceIdDataReducer = {loading:true};
        },
        [getStripeSubscriptionPaymentInvoiceByInvoiceId.fulfilled]: (state, action) => {
            state.getStripeSubscriptionPaymentInvoiceByInvoiceIdDataReducer = {loading:false , data:action.payload};
        },
        [getStripeSubscriptionPaymentInvoiceByInvoiceId.rejected]: (state, action) => {
            state.getStripeSubscriptionPaymentInvoiceByInvoiceIdDataReducer = {loading:false};
            showToast(action.payload.error.message, "error")
        },

        // Upgrade User Subscription
        [upgradeUserSubscription.pending]: (state) => {
            state.upgradeUserSubscriptionReducer = {loading:true};
        },
        [upgradeUserSubscription.fulfilled]: (state, action) => {
            state.upgradeUserSubscriptionReducer = {loading:false , data:action.payload.data};
        },
        [upgradeUserSubscription.rejected]: (state, action) => {
            if(!(action.payload.status===400 && action.payload.data.detailDesc===SubscriptionAlreadyExists)){
                state.upgradeUserSubscriptionReducer = {loading:false};
                showToast(action.payload.data.detailDesc  || action?.payload?.statusText, "error")
            }
            state.getStripeSubscriptionPaymentInvoiceByInvoiceIdDataReducer = {loading:false};
        },


    }

})
export const {stripeResetAllReducer} = stripeSlices.actions

export default stripeSlices.reducer;
