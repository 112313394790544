import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import axios from "axios";
import {getAxiosConfig, resourceToFile, serialize, showToast} from "../../helpers/utils";


export const getReportBySurveyId = createAsyncThunk("reports/getReportBySurveyId", async (data, thunkAPI) => {
    const serializedData = serialize(data)
    console.log(serializedData)
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/survey-attempt/report/dashboard/weekly-attempted-count?${serializedData}`, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch((error) => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
});

export const getUserReportsBySurveyIdAndRiskLevel = createAsyncThunk("reports/getUserReportsBySurveyIdAndRiskLevel", async (data, thunkAPI) => {
    const serializedData = serialize(data)
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/survey-attempt/dashboard/weekly-attempted-mentees?${serializedData}`,data, getAxiosConfig()).then((res) => {
        return res.data;
    }).catch((error) => {
        return thunkAPI.rejectWithValue(error.response.data);
    })
});

export const getSurveyAttemptReportBySurveyId = createAsyncThunk('reports/getSurveyAttemptReportBySurveyId', async (data, thunkAPI) => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/survey-attempt/report/reflection-vs-attempts`,
        headers: getAxiosConfig().headers,
        data: data
    };
    return await axios.request(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return thunkAPI.rejectWithValue(error.response);
        });
})
export const getSurveyAttemptReportByMentee = createAsyncThunk('reports/getSurveyAttemptReportByMentee', async (data, thunkAPI) => {

    const serializedData = serialize({subOrgId: data?.subOrgId, orgId: data?.orgId});
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/survey-attempt/report/mentee-vs-reflections?${serializedData}`,
        headers: getAxiosConfig().headers,
        data: data
    };
    return await axios.request(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return thunkAPI.rejectWithValue(error.response);
        });
})


export const getPaginatedActiveSubscription = createAsyncThunk('reports/getPaginatedActiveSubscription', async (data, thunkAPI) => {
    const serializedData = serialize(data)
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/user-subscription/active?${serializedData}`, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    })
})


export const exportSurveyAttemptReportByMentee = createAsyncThunk('reports/exportSurveyAttemptReportByMentee', async (data, thunkAPI) => {
    const serializedData = serialize({subOrgId: data?.subOrgId, orgId: data?.orgId.value});
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/survey-attempt/export/mentee-vs-reflections?${serializedData}`,
        headers: getAxiosConfig().headers,
        data: data
    };
    return await axios.request(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return thunkAPI.rejectWithValue(error.response);
        });
})

export const exportSurveyAttemptReportBySurveyId = createAsyncThunk('reports/exportSurveyAttemptReportBySurveyId', async (data, thunkAPI) => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/survey-attempt/export/reflection-vs-attempts`,
        headers: getAxiosConfig().headers,
        data: data
    };
    return await axios.request(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return thunkAPI.rejectWithValue(error.response);
        });
})
export const getAggregateReflectionAttemptReport = createAsyncThunk('reports/getAggregateReflectionAttemptReport', async (data, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/survey-attempt/report/aggregate-reflection-attempt?${serialize({subOrgId: data?.subOrgId, orgId: data?.orgId})}`,data, getAxiosConfig()).then(res => {
        return res.data;
    }).catch(error => {
        return thunkAPI.rejectWithValue(error.response);
    })
})
export const getMemberVsReflectionAttemptReport = createAsyncThunk('reports/getMemberVsReflectionAttemptReport', async (data, thunkAPI) => {
    let obj = Object.keys(data).reduce((result, key) => {
        const value = data[key];

        if (value === null || value === undefined || (Array.isArray(value) && (value.includes(null) || value.includes(undefined)))) {
            return result;
        } else {
            result[key] = value;
            return result;
        }
    }, {});

    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/survey-attempt/report/aggregate-reflection-attempt-vs-mentee?${serialize({subOrgId: data?.subOrgId})}`, obj, getAxiosConfig());
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response);
    }
});

export const getMemberVsReflectionYearlyAttemptReport = createAsyncThunk('/survey-attempt/reports/getMemberVsReflectionYearlyAttemptReport', async (userId, thunkAPI) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/survey-attempt/report/yearly-reflection-attempt-vs-mentee?${serialize({menteeId: userId})}`, getAxiosConfig());

        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response);
    }
});



const reportsSlice = createSlice({
    name: "reports",
    initialState: {
        getReportBySurveyIdDataReducer: {loading:false},
        getUserReportsBySurveyIdAndRiskLevelDataReducer: {loading:false},
        getSurveyAttemptReportBySurveyIdDataReducer: {loading:false},
        getSurveyAttemptReportByMenteeDataReducer: {loading:false},
        exportSurveyAttemptReportBySurveyIdDataReducer: {loading:false},
        exportSurveyAttemptReportByMenteeDataReducer: {loading:false},
        getPaginatedActiveSubscriptionDataReducer: {loading:false},
        getAggregateReflectionAttemptReportReducer: {loading:false},
        getMemberVsReflectionAttemptReportReducer:{loading:false},
        getMemberVsReflectionYearlyAttemptReportReducer:{loading:false},

    },
    reducers:{
        resetGetMemberVsReflectionAttemptReportReducer: (state) => {
            state.getMemberVsReflectionAttemptReportReducer = {loading: false, data: null}
        },
    },
    extraReducers: {
        // Get Report By Survey Id
        [getReportBySurveyId.pending]: (state) => {
            state.getReportBySurveyIdDataReducer = {loading:true}
        },
        [getReportBySurveyId.fulfilled]: (state, action) => {
            state.getReportBySurveyIdDataReducer = {loading:false,data:action.payload.data}
        },
        [getReportBySurveyId.rejected]: (state, action) => {
            state.getReportBySurveyIdDataReducer = {loading:false}
            showToast(action?.payload?.detailDesc || action?.payload?.statusText,"error")
        },
        // Get Report By Survey Id
        [getUserReportsBySurveyIdAndRiskLevel.pending]: (state) => {
            state.getUserReportsBySurveyIdAndRiskLevelDataReducer = {loading:true}
        },
        [getUserReportsBySurveyIdAndRiskLevel.fulfilled]: (state, action) => {
            state.getUserReportsBySurveyIdAndRiskLevelDataReducer ={loading:false,data:action.payload.data}
        },
        [getUserReportsBySurveyIdAndRiskLevel.rejected]: (state, action) => {
            state.getUserReportsBySurveyIdAndRiskLevelDataReducer ={loading:true}
            showToast(action?.payload?.detailDesc || action?.payload?.statusText,"error")
        },

        // Get Survey Attempt Reports By Survey Id
        [getSurveyAttemptReportBySurveyId.pending]: (state) => {
            state.getSurveyAttemptReportBySurveyIdDataReducer = {loading:true};
        },
        [getSurveyAttemptReportBySurveyId.fulfilled]: (state, action) => {
            state.getSurveyAttemptReportBySurveyIdDataReducer = {loading:false , data:action.payload.data};
        },
        [getSurveyAttemptReportBySurveyId.rejected]: (state, action) => {
            state.getSurveyAttemptReportBySurveyIdDataReducer = {loading:false};
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText ,"error")
        },

        // Get Survey Attempt Reports By Mentee
        [getSurveyAttemptReportByMentee.pending]: (state) => {
            state.getSurveyAttemptReportByMenteeDataReducer = {loading:true};
        },
        [getSurveyAttemptReportByMentee.fulfilled]: (state, action) => {
            // state.getSurveyAttemptReportByMenteeData = action.payload.data
            state.getSurveyAttemptReportByMenteeDataReducer = {loading:false,data:action.payload.data};
        },
        [getSurveyAttemptReportByMentee.rejected]: (state, action) => {
            state.getSurveyAttemptReportByMenteeDataReducer = {loading:false};
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText ,"error")
        },

        // Get Subscription Grid Report for sysAdmin
        [getPaginatedActiveSubscription.pending]: (state) => {
            state.getPaginatedActiveSubscriptionDataReducer = {loading: true};
        },
        [getPaginatedActiveSubscription.fulfilled]: (state, action) => {
            state.getPaginatedActiveSubscriptionDataReducer = {loading: false, data: action.payload.data};
        },
        [getPaginatedActiveSubscription.rejected]: (state, action) => {
            state.getPaginatedActiveSubscriptionDataReducer =  {loading: true};
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText ,"error")
        },


        // Export Survey Attempt Reports By Mentee
        [exportSurveyAttemptReportByMentee.pending]: (state) => {
            state.exportSurveyAttemptReportByMenteeDataReducer = {loading:true};
        },
        [exportSurveyAttemptReportByMentee.fulfilled]: (state, action) => {
            state.exportSurveyAttemptReportByMenteeDataReducer = {loading:false,data:action.payload};
        },
        [exportSurveyAttemptReportByMentee.rejected]: (state, action) => {
            state.exportSurveyAttemptReportByMenteeDataReducer = {loading:false};
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText,"error")
        },

        // Export Survey Attempt Reports By Survey Id
        [exportSurveyAttemptReportBySurveyId.pending]: (state) => {
            state.exportSurveyAttemptReportBySurveyIdDataReducer = {loading:true};
        },
        [exportSurveyAttemptReportBySurveyId.fulfilled]: (state, action) => {
            resourceToFile(action.payload, "reflectionAttemptReportCsv", ".csv")
            state.exportSurveyAttemptReportBySurveyIdDataReducer = {loading:false,data:action.payload};
        },
        [exportSurveyAttemptReportBySurveyId.rejected]: (state, action) => {
            state.exportSurveyAttemptReportBySurveyIdDataReducer = {loading:false};
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText,"error")
        },

        // Get Reflection Summary report
        [getAggregateReflectionAttemptReport.pending]: (state) => {
            state.getAggregateReflectionAttemptReportReducer = {loading:true};
        },
        [getAggregateReflectionAttemptReport.fulfilled]: (state, action) => {
            state.getAggregateReflectionAttemptReportReducer = {loading:false,data:action.payload.data};
        },
        [getAggregateReflectionAttemptReport.rejected]: (state, action) => {
            state.getAggregateReflectionAttemptReportReducer = {loading:false};
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText,"error")
        },

        // Get User vs Reflection Attempts  report
        [getMemberVsReflectionAttemptReport.pending]: (state) => {
            state.getMemberVsReflectionAttemptReportReducer = {loading:true};
        },
        [getMemberVsReflectionAttemptReport.fulfilled]: (state, action) => {
            state.getMemberVsReflectionAttemptReportReducer = {loading:false,data:action.payload.data};
        },
        [getMemberVsReflectionAttemptReport.rejected]: (state, action) => {
            state.getMemberVsReflectionAttemptReportReducer = {loading:false};
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText,"error")
        },


        [getMemberVsReflectionYearlyAttemptReport.pending]: (state) => {
            state.getMemberVsReflectionYearlyAttemptReportReducer = {loading:true};
        },
        [getMemberVsReflectionYearlyAttemptReport.fulfilled]: (state, action) => {
            state.getMemberVsReflectionYearlyAttemptReportReducer = {loading:false,data:action.payload.data};
        },
        [getMemberVsReflectionYearlyAttemptReport.rejected]: (state, action) => {
            state.getMemberVsReflectionYearlyAttemptReportReducer = {loading:false};
            showToast(action?.payload?.data?.detailDesc || action?.payload?.statusText,"error")
        },



    }

})
export const {resetGetMemberVsReflectionAttemptReportReducer} = reportsSlice.actions;
export default reportsSlice.reducer;