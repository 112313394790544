
import {Form} from 'react-bootstrap'
import './ServeyName.css'
import edit_icon from '../../../images/edit.png'
const SurveyName=()=>{
    return(
        <>

            <div className='main_content'>
                <div className="surveyName_outer">
                    <div className='surveyname_wrapper'>
                        <div className='survey_name_Heading' >

                            <h2 className='mediumFontSize'>Survey Name</h2>
                            <p className='regularFontSize'>Review survey answers</p>
                        </div>
                        <div>
                            <button className='surveyName_delete_btn mediumFontSize cmn_surveyName_btn'>Delete</button>
                            <button className='surveyName_delete_btn mediumFontSize cmn_surveyName_btn'>Cancel</button>
                            <button className='surveyName_save_btn mediumFontSize cmn_surveyName_btn cmn_background_color' >Save</button>
                        </div>
                    </div>
                    <div className="userFeedback_outer">
                        <Form>
                            <div className='satisfied_wrapper'>
                                <div className="report_outer">
                                    <h2 className='satisfied_heading mediumFontSize '>How satisfied are you with your current work/life balance? </h2>
                                    <div className="edit_img_outer">
                                        <img src={edit_icon} className="edit_icon"/>
                                    </div>

                                </div>


                                <Form.Group className='satisfied_text' >
                                    <input type="radio"/>
                                    <Form.Label className='label_satisfied mediumFontSize'>Very Satisfied</Form.Label>

                                </Form.Group>
                                <Form.Group className='satisfied_text' >
                                    <input type="radio"/>
                                    <Form.Label className='label_satisfied mediumFontSize'>Somewhat satisfied</Form.Label>

                                </Form.Group>
                                <Form.Group className='satisfied_text' >
                                    <input type="radio"/>
                                    <Form.Label className='label_satisfied mediumFontSize'>Neither satisfied nor dissatisfied</Form.Label>

                                </Form.Group>
                                <Form.Group className='satisfied_text' >
                                    <input type="radio"/>
                                    <Form.Label className='label_satisfied mediumFontSize'>Very Satisfied</Form.Label>

                                </Form.Group>
                            </div>
                            <div className='satisfied_wrapper'>
                                <h2 className='satisfied_heading mediumFontSize'>Which are the followimg area  would you like help with ? </h2>

                                <Form.Group className='satisfied_text' >
                                    <Form.Check type="checkbox"/>
                                    <Form.Label className='label_satisfied mediumFontSize'>Very Satisfied</Form.Label>

                                </Form.Group>
                                <Form.Group className='satisfied_text' >
                                    <Form.Check type="checkbox"/>
                                    <Form.Label className='label_satisfied mediumFontSize'>Somewhat satisfied</Form.Label>

                                </Form.Group>
                                <Form.Group className='satisfied_text' >
                                    <Form.Check type="checkbox"/>
                                    <Form.Label className='label_satisfied mediumFontSize'>Neither satisfied nor dissatisfied</Form.Label>

                                </Form.Group>
                                <Form.Group className='satisfied_text' >
                                    <Form.Check type="checkbox"/>
                                    <Form.Label className='label_satisfied mediumFontSize'>Very Satisfied</Form.Label>

                                </Form.Group>
                            </div>
                            <div className='satisfied_wrapper'>
                                <h2 className='satisfied_heading mediumFontSize'>How frequently do you experience stress related your work ? </h2>
                                <Form.Group  className='experience_Form' >
                                    <Form.Control type="text" placeholder='Write your answer...' className='user_feedback mediumFontSize'></Form.Control>


                                </Form.Group>
                            </div>
                        </Form>
                    </div>
                </div>

            </div>
        </>
    )
}
export default SurveyName